import { UserData, UserPost } from "../model/user";
import API from "../utils/axios";

export class UserService {
    constructor() { }
    static getUser(id: string): Promise<any> {
        return API.get(`/getUsers?_id=${id}`);
    }

    static createUser(data: UserPost): Promise<UserData> {
        return API.post(`/createUsers`, data);
    }

    static loginUser(data: any): Promise<UserPost[]> {
        data.type = 'WEB';
        return API.post(`/login`, data);
    }

    static updateUsers(data: any): Promise<UserData> {
        return API.put(`/updateUsers`, data);
    }
    static checkUserClubsPostsByUserID(id: string): Promise<any> {
        return API.get(`/checkUserClubsPostsByUserID/${id}`);
    }

    static sendOtp(data: any): Promise<any> {
        return API.post(`/sendOtp`, data);
    }

    static verifyOtp(data: any): Promise<any> {
        data.type = 'WEB';
        console.log(data, 'verifyOTP');
        return API.post(`/verifyOtp`, data);
    }
    // for redirect
    static redirect(data: any): Promise<any> {
        data.type = 'WEB';
        console.log(data, 'redirect');
        return API.post(`/redirect`, data);
    }

    static checkMobileNumberExistsWithAccount(data: any): Promise<any> {
        return API.post(`/checkMobileNumberExistsWithAccount`, data);
    }

    static checkMobileNumberExists(data: any): Promise<any> {
        return API.post(`/checkMobileNumberExists`, data);
    }

    static getBannerInfo(data: any): Promise<any> {
        return API.get(`/getBannerInfo?title=${data}`);
    }

    static verifyUserToken(): Promise<any> {
        return API.get(`/verifyUserToken`);
    }
    static faqData(): Promise<any> {
        return API.get(`/faq`);
    }
    static getInfluencersData(): Promise<any> {
        return API.get(`/influencer`) ;
    }
    static getAllInfluencersData(): Promise<any> {
        return API.get(`/getallinfluencersdata`) ;
    }
    static getUsersData(): Promise<any>{
        return API.get(`/getusersdata`) ;
    }
    static support(data: any): Promise<any> {
        return API.post(`/support`, data);
    }
    static deleteUser(data: any): Promise<any> {
        return API.delete(`/removeUsers/${data}`, data);
    }
    static claimRequest(data: any): Promise<any> {
        return API.post(`/claim`, data);
    }
    static claimRequestStatus(user_id: string): Promise<any> {
        return API.get(`/claimStatus/`, { params: { user_id } });
    }
    static updateClaimSuccessful(data: any): Promise<any> {
        return API.post(`/updateclaimsuccessful`, data);
    }
    static updateClaimCancel(data: any): Promise<any> {
        return API.post(`/updateclaimcancel`, data);
    }
    static AdminPaymentDetails(): Promise<any> {
        return API.get(`/adminpaymentdetails`);
    }
    static updateVerifyUsers(data: any): Promise<UserData> {
        return API.put(`/updateverifyusers`, data);
    }
}

