import { TextField } from "@mui/material";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Button, Dropdown, DropdownButton, Modal } from "react-bootstrap";
import { isMobile } from "react-device-detect";
import { connect, useDispatch } from "react-redux";
import { Link, NavLink, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { LivestreamService } from "../../../../app/service/livestream";
import Capture from "../../../../assets/img/Capture1.png";
import { LivestreamUploader } from "../../../../components/livestream-uploader";
import { TooltipWrapper } from "../../../../components/tooltip";
import { BASE_URL, STREAMING_HOST } from "../../../../config/constant";
import { Loading } from "../../../../redux/actions";
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import { FacebookIcon, TwitterIcon } from "react-share";
import MobileViewModal from "../../../../components/MobileViewModal";

interface Props {
  userId: string;
  UserReducer?: any;
  loading?: (data: any) => void;
  LoginUserIdReducer?: any;
}

const MyLivestreamsScheduledPage: React.FC<Props> = (props: Props) => {
  const [goLiveLiveStream, setGoLiveLiveStream] = useState<any>();
  const [canShowUpload, setCanShowUpload] = useState(false);
  let userId = sessionStorage.getItem("isLogin") || "";
  const [livestream, setLivestream] = useState<any[] | []>([]);
  const [showUserAlertPopup, setShowUserAlertPopup] = useState(false);
  const handleCloseUserAlertPopup = () => setShowUserAlertPopup(false);
  const handleShowUserAlertPopup = () => setShowUserAlertPopup(true);
  const [selectedLivestreanType, setSelectedLivestreanType] = useState("web");
  const dispatch = useDispatch();

  const [showUserLivestreamPopup, setShowUserLivestreamPopup] = useState(false);
  const handleCloseUserLivestreamPopup = () =>
    setShowUserLivestreamPopup(false);
  const handleShowUserLivestreamPopup = () => setShowUserLivestreamPopup(true);
  const [selectedLivestream, setSelectedLivestream] = useState<any>();
  const history = useHistory();
  const club_id = sessionStorage.getItem("clubId") || "";
  const [modalState, setModalState] = useState<
    "modal-one" | "modal-two" | "close"
  >("close");
  const [gustEmail, setGustEmail] = React.useState("");
  const [gustName, setGustName] = React.useState("");
  const [gustPhone, setGustPhone] = React.useState("");
  const [gustNameError, setGustNameError] = React.useState("");
  const [gustEmailError, setGustEmailError] = React.useState("");
  const [gustPhoneError, setGustPhoneError] = React.useState("");
  const [gustList, setGustList] = useState<any[] | []>([]);
  const [oldGustList, setOldGustList] = useState<any[] | []>([]);
  const [show, setShow] = useState(false);
  const [showIsMobileGoLive, setShowIsMobileGoLive] = useState(false);
  const handleCloseShowIsMobileGoLive = () => setShowIsMobileGoLive(false);
  const handleShowShowIsMobileGoLive = () => setShowIsMobileGoLive(true);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  useEffect(() => {
    localStorage.setItem('thiredPartyData', '');
    getlivestream();
  }, []);
  const getlivestream = () => {
    if (props.loading) {
      dispatch(props.loading(true));
    }
    LivestreamService.getLiveStreamByCreatorID(userId).then((res) => {
      if (res) {
        const data = res.sort((a: any, b: any) =>
          a.schedule_From > b.schedule_From
            ? 1
            : b.schedule_From > a.schedule_From
            ? -1
            : 0
        );
        setLivestream([...data]);
        if (props.loading) {
          dispatch(props.loading(false));
        }
      }
    });
  };
  const startStreaming = (data: any) => {
    localStorage.setItem('thiredPartyData', '');
    handleCloseUserLivestreamPopup();
    setGoLiveLiveStream(data);
    setCanShowUpload(true);
    LivestreamService.goLiveStream(selectedLivestream).then((res) => {
      if (res) {
        if (isMobile) {
          history.push(
            `/golivestream/${selectedLivestream?._id}/${selectedLivestream?.club_id}`
          );
        } else {
          window.open(
            `${window.location.origin}/#/golivestream/${selectedLivestream?._id}/${selectedLivestream?.club_id}`,
            "_blank"
          );
        }
      }
    });
  };

  const onThairedParty = () => {
    const data = { server: `rtmp://${STREAMING_HOST}:1935/live`, stream_key: `${selectedLivestream?.collectionName}~${selectedLivestream?._id}` }
    localStorage.setItem('thiredPartyData', JSON.stringify(data));
    window.open(
      `${window.location.origin}/#/golivestream/${selectedLivestream?._id}/${selectedLivestream?.club_id}`,
      "_blank"
    );
  }

  const onCancleEvent = (data: any) => {
    LivestreamService.cancelLiveStream(data).then((res) => {
      if (res) {
        getlivestream();
      }
    });
  };
  const endLivestream = () => {
    setCanShowUpload(false);
    LivestreamService.completeLiveStream(goLiveLiveStream).then((res) => {
      if (res) {
        getlivestream();
      }
    });
    handleShowUserAlertPopup();
  };
  const onChangeLivestream = (event: any) => {
    setSelectedLivestreanType(event);
  };
  const onGolive = (data: any) => {
    setSelectedLivestream(data);
    if (isMobile) {
      handleShowShowIsMobileGoLive();
    } else {
      handleShowUserLivestreamPopup();
    }
  };

  const handleShowModalTwo = (data: any) => {
    console.log("data", data);
    let gustData: any = [];
    setSelectedLivestream(data);
    gustData = data.guest_list;
    setOldGustList([...gustData]);
    setGustList([...gustData]);
    setModalState("modal-two");
  };

  const onChangeGuestEmail = (event: any) => {
    setGustEmailError("");
    if (event.target.value) {
      const re = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,6})+$/;
      if (!event.target.value.trim()) {
        setGustEmail("");
        setGustEmailError("Enter only alphabets");
      } else if (re.test(event.target.value.trim())) {
        setGustEmail(event.target.value);
      } else {
        setGustEmail(event.target.value);
        setGustEmailError("Please enter proper guest email id");
      }
    } else {
      setGustEmail("");
      setGustEmailError("Please enter guest email id");
    }
  };

  const onChangeGuestPhone = (event: any) => {
    setGustPhoneError("");
    if (event.target.value && event.target.value.length <= 10) {
      const re = /^[5-9]{1}[0-9]{9}$/;
      if (re.test(event.target.value.trim())) {
        setGustPhone(event.target.value.trim());
      } else {
        setGustPhone(event.target.value.trim());
        setGustPhoneError("Please enter proper phone number");
      }
    } else if (!(event.target.value.length >= 10)) {
      setGustPhone("");
      setGustPhoneError("Please enter phone number");
    }
  };

  const onChangeGuestName = (event: any) => {
    setGustNameError("");
    const reg = /^[A-Za-z]+(?:[ _-][A-Za-z]+)*$/;
    if (event) {
      if (!reg.test(event.target.value.trim())) {
        if (!event.target.value.trim()) {
          setGustName("");
          setGustNameError("Enter only alphabets");
        } else {
          setGustName(event.target.value);
          setGustNameError("Enter only alphabets");
        }
      } else {
        setGustName(event.target.value);
      }
    } else {
      setGustName("");
      setGustNameError("Please enter name");
    }
  };

  const onAddGustList = () => {
    const re = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,6})+$/;
    const gustData: any = gustList;
    setGustNameError("");
    setGustEmailError("");
   if(!re.test(gustEmail)){
    setGustEmailError("not a valid email");

   }else if (gustName && gustEmail && gustPhone) {
    
      const isEmailExist = gustData.find(
        (data: any) => data.email === gustEmail
      );
      if (!isEmailExist) {
        gustData.push({ name: gustName, email: gustEmail, phone: gustPhone });
        setGustList([...gustData]);
        setGustName("");
        setGustEmail("");
        setGustPhone("");
      } else {
        setGustEmailError("Email id already exist");
      }
    } else {
      if (!gustName) {
        setGustNameError("Please enter guest name");
      }
      if (!gustEmail) {
        setGustEmailError("Please enter guest email id");
      }
      if (!gustPhone) {
        setGustPhoneError("Please enter phone number");
      }
    }
  };
  const onDeleteGustList = (index: number) => {
    const gustData: any = gustList;
    gustData.splice(index, 1);
    setGustList([...gustData]);
  };

  const onSendTickets = () => {
    if (props.loading) {
      dispatch(props.loading(true));
    }
    const selectedLivestreamData: any = selectedLivestream;
    selectedLivestreamData.guest_list = gustList;
    LivestreamService.updateLiveStream(selectedLivestreamData).then((res) => {
      createTicket();
    });
  };

  const createTicket = () => {
    const existingGusts: any[] = oldGustList;
    let newGustList: any = [];
    if (existingGusts.length > 0) {
      newGustList = gustList.filter(
        ({ email: id1 }) => !existingGusts.some(({ email: id2 }) => id2 === id1)
      );
    } else {
      newGustList = gustList;
    }
    const data = {
      guest_list: newGustList,
      live_stream_id: selectedLivestream?._id,
      club_id: selectedLivestream?.club_id,
    };
    LivestreamService.createTickets(data).then((res) => {
      setModalState("close");
      toast.success("Guest list added successfully");
      getlivestream();
      setGustList([]);
      if (props.loading) {
        dispatch(props.loading(false));
      }
    });
  };

  const [copied, setCopied] = useState(false);

  const copy = (data: any) => {
    console.log(data);
    LivestreamService.getlivestreamById(data?._id).then((res) => {
      console.log(res[0]?._id);
      const el = document.createElement("input");
      navigator.clipboard.writeText(
        `${window.location.origin}/#/livestreams/livestream-detail/${res[0]?._id}`
      );
      setCopied(true);
      toast("Link Copied");
    });
  };
  return (
    <>
      {/* {canShowUpload && (
        <LivestreamUploader
          streamId={goLiveLiveStream?._id}
          club_id={club_id}
          onEndStreming={endLivestream}
        ></LivestreamUploader>
      )} */}
      <div className="tab-content" id="pills-tabContent">
        <div
          className="tab-pane active"
          id="details"
          role="tabpanel"
          aria-labelledby="details-tab"
        >
          <div className="livestream-table-view table-responsive">
            <table className="table ">
              <thead>
                <tr>
                  <th scope="col"></th>
                  <th scope="col" style={{ minWidth: "150px" }}>
                    Title
                  </th>
                  <th scope="col" style={{ minWidth: "250px" }}>
                    Schedule
                  </th>

                  <th scope="col" style={{ minWidth: "120px" }}>
                    Ticket Price
                  </th>
                  <th scope="col" style={{ minWidth: "70px" }}>
                    Sold
                  </th>
                  <th scope="col" style={{ minWidth: "130px" }}>
                    Revenue
                  </th>
                  <th scope="col" style={{ minWidth: "100px" }}>
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {/* Card Subscription */}
                {livestream.map((data: any, index: number) => {
                  return (
                    <tr key={index}>
                      <th scope="row">
                        <img
                          src={data?.thumbnail ? data?.thumbnail : Capture}
                          alt="draft"
                          style={{ height: "40px", width: "40px" }}
                        />
                      </th>
                      <td>{data?.title}</td>
                      <td>
                        {moment(data?.schedule_From)
                          .utc()
                          .format("dddd, MMM DD YYYY")}
                        , <br />
                        {moment(data?.schedule_From)
                          .utc()
                          .format(" hh:mm A")} -{" "}
                        {moment(data?.schedule_To).utc().format(" hh:mm A")}
                      </td>
                      <td>INR {data?.price}</td>
                      <td>{data?.tickets}</td>
                      <td>
                        INR {data?.tickets ? data?.tickets * data.price : 0}
                      </td>
                      <td className="d-flex align-items-center  action-icons">
                        {!moment().isAfter(data?.schedule_To) ? (
                          <>
                            <button
                              className="pointer"
                              onClick={
                                !isMobile ? () => onGolive(data) : handleShow
                              }
                            >
                              <TooltipWrapper
                                placement="top"
                                tooltipText="Go Live"
                                iconName="bi bi-cast"
                              />
                            </button>
                          </>
                        ) : (
                          <button
                            disabled
                            onClick={
                              !isMobile ? () => onGolive(data) : handleShow
                            }
                            className="border-0 bg-transparent"
                          >
                            <TooltipWrapper
                              placement="top"
                              tooltipText="Go Live"
                              iconName="bi bi-cast"
                            />
                          </button>
                        )}
                        <MobileViewModal
                          show={show}
                          handleClose={handleClose}
                        />

                        <button
                          onClick={() => handleShowModalTwo(data)}
                          className="border-0 bg-transparent guest-icon"
                        >
                          <TooltipWrapper
                            placement="top"
                            tooltipText="Guest List"
                            iconName="bi bi-person-plus"
                          />
                        </button>

                        <Dropdown className="share-dropdown btn-share">
                          <Dropdown.Toggle className="" id="share-dropdown">
                            <TooltipWrapper
                              placement="top"
                              tooltipText="Share"
                              iconName="bi bi-share"
                            />
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            <Dropdown.Item>
                              <FacebookShareButton
                                url={`https://www.clanworld.ai/#/livestreams/livestream-detail/${data?._id}`}
                                hashtag="Influencer Marketing, Livestream"
                                className="Demo__some-network__share-button"
                              >
                                <FacebookIcon size={24} round /> Facebook
                              </FacebookShareButton>
                            </Dropdown.Item>
                            <Dropdown.Item>
                              <TwitterShareButton
                                url={`https://www.clanworld.ai/#/livestreams/livestream-detail/${data?._id}`}
                                hashtags={[
                                  "Influencer Marketing",
                                  "Livestream",
                                ]}
                                className="Demo__some-network__share-button"
                              >
                                <TwitterIcon size={24} round /> Twitter
                              </TwitterShareButton>
                            </Dropdown.Item>
                            <Dropdown.Item>
                              <WhatsappShareButton
                                url={`${window.location.origin}/#/livestreams/livestream-detail/${data?._id}`}
                                className="Demo__some-network__share-button"
                              >
                                <WhatsappIcon size={24} round /> Whatsapp
                              </WhatsappShareButton>
                            </Dropdown.Item>
                            <Dropdown.Item>
                              <a
                                href="javascript:void(0)"
                                onClick={() => copy(data)}
                                style={{ textDecoration: "none" }}
                              >
                                <i
                                  className="bi bi-clipboard"
                                  style={{
                                    width: "24px",
                                    marginRight: "10px",
                                    marginLeft: "4px",
                                    fontSize: "14px",
                                    textAlign: "center",
                                  }}
                                ></i>
                                Copy URL
                              </a>
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                        <button
                          type="button"
                          className="border-0 bg-transparent text-danger"
                          onClick={() => onCancleEvent(data)}
                        >
                          <TooltipWrapper
                            placement="top"
                            tooltipText="Cancel"
                            iconName="bi bi-x"
                          />
                        </button>
                      </td>
                    </tr>
                  );
                })}
                {livestream.length <= 0 && (
                  <tr className="text-center mb-3">
                    <td colSpan={8}>No livestream data</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <Modal
        show={showUserAlertPopup}
        onHide={handleCloseUserAlertPopup}
        centered
        dialogClassName="sign-up-modal login-modal"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="auth-container">
            <div className="auth-sec auth-right-sec">
              <div className="auth-info">
                <h2>Info</h2>
              </div>
              <div>Your livestream completed successfully.</div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>

      <Modal
        show={showUserLivestreamPopup}
        onHide={handleCloseUserLivestreamPopup}
        centered
        dialogClassName="sign-up-modal login-modal"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="auth-container">
            <div className="auth-sec auth-right-sec">
              <div className="auth-info">
                {/* <h2>Alert</h2> */}
              </div>
              <div className="row">
                <h4>Start livestream using</h4>
                <div>
                  <input
                    type="radio"
                    name="isLivestreamType"
                    id="isLivestreamType"
                    value={selectedLivestreanType}
                    checked={selectedLivestreanType === "web"}
                    onChange={() => onChangeLivestream("web")}
                  />{" "}
                  <span className="me-2"> Web livestream</span>
                  <div>
                  <input
                    type="radio"
                    name="isLivestreamType"
                    id="isLivestreamType"
                    value={selectedLivestreanType}
                    checked={selectedLivestreanType === "3rdparty"}
                    onChange={() => onChangeLivestream("3rdparty")}
                  />
                  <span> 3rd party software</span>
                  </div>
 
                  {/* {selectedLivestreanType === "3rdparty" && (
                    <>
                      <div className="mt-2">
                        <label>Server</label>{" "}
                        <i
                          className="bi bi-clipboard ms-2 pointer"
                          title="Copy to clipboard"
                          onClick={() => {
                            navigator.clipboard.writeText(
                              `rtmp://${STREAMING_HOST}:1935/live`
                            );
                          }}
                        ></i>
                        <input
                          type="text"
                          className="form-control mt-2"
                          readOnly
                          value={`rtmp://${STREAMING_HOST}:1935/live`}
                        />
                      </div>
                      <div className="mt-2">
                        <label>Stream Key</label>{" "}
                        <i
                          className="bi bi-clipboard ms-2 pointer"
                          title="Copy to clipboard"
                          onClick={() => {
                            navigator.clipboard.writeText(
                              `${selectedLivestream?.collectionName}~${selectedLivestream?._id}`
                            );
                          }}
                        ></i>
                        <input
                          type="text"
                          className="form-control mt-2"
                          readOnly
                          value={`${selectedLivestream?.collectionName}~${selectedLivestream?._id}`}
                        />
                      </div>
                    </>
                  )} */}
                  <div className="mt-3">
                    {selectedLivestreanType === "web" && (
                      <button
                        style={{ padding: "0 12px", fontSize: "20px" }}
                        className="btn btn-outline-black"
                        onClick={startStreaming}
                        // to={`/golivestream/${selectedLivestream?._id}/${selectedLivestream?.club_id}`}
                      >
                        Go Live
                      </button>
                    )}
                    {selectedLivestreanType === "3rdparty" && <button
                      style={{ padding: "0 12px", fontSize: "20px" }}
                      className="btn btn-outline-black"
                      onClick={onThairedParty}
                    >
                      Ok
                    </button>}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>

      <Modal size="lg" show={modalState === "modal-two"}>
        <Modal.Body>
          <div className="d-flex mb-5 mt-2 align-items-center">
            <h3 className="mb-0">Add Guest List (upto 25)</h3>
            <button
              className="ms-auto btn p-0"
              onClick={() => setModalState("close")}
            >
              <i className="bi bi-x-lg me-0"></i>
            </button>
          </div>

          <div className="row mb-3 flex-column flex-lg-row">
            <div className="col mb-3 mb-lg-0">
              <TextField
                fullWidth
                id="outlined-basic"
                label="Name"
                variant="outlined"
                value={gustName}
                onChange={(event) => onChangeGuestName(event)}
              />
              {gustNameError && (
                <p
                  style={{ color: "red", fontSize: "14px", marginBottom: "0" }}
                >
                  {gustNameError}
                </p>
              )}
            </div>
            <div className="col mb-3 mb-lg-0">
              <TextField
                fullWidth
                type="email"
                id="outlined-basic"
                label="Email id"
                variant="outlined"
                value={gustEmail}
                onChange={(event) => onChangeGuestEmail(event)}
              />
              {gustEmailError && (
                <p
                  style={{ color: "red", fontSize: "14px", marginBottom: "0" }}
                >
                  {gustEmailError}
                </p>
              )}
            </div>
            <div className="col  input-w-inner-input mb-3 mb-lg-0">
              <div className="d-flex flex-column">
                <TextField
                  className="country-code"
                  id="outlined-basic"
                  variant="standard"
                  value={"+91"}
                  disabled
                />
                <TextField
                  fullWidth
                  id="outlined-basic"
                  className="phone-number"
                  label="Phone"
                  variant="outlined"
                  value={gustPhone}
                  type="number"
                  onChange={(event) => onChangeGuestPhone(event)}
                />
                {gustPhoneError && (
                  <p
                    style={{
                      color: "red",
                      marginBottom: "0",
                      fontSize: "14px",
                      display: "block",
                    }}
                  >
                    {gustPhoneError}
                  </p>
                )}
              </div>
            </div>
            <div className="col-md-2 d-flex">
              {gustList.length <= 24 && (
                <button
                  className="btn btn-outline-black w-100"
                  style={{ height: "50px" }}
                  onClick={onAddGustList}
                >
                  Add
                </button>
              )}
            </div>
          </div>
          <div className="col-md-12 border p-4 rounded-3 guest-list">
            <ul>
              {/* {oldGustList.map(
                (data: any, index: number) => {
                  return (
                    <li key={index}>
                      <span className="guest-list-mail">{data?.name}</span>
                      <span className="guest-list-mail">{data?.email}</span>
                      <span className="guest-list-unused">
                        {data?.phone}
                      </span>
                      <span className="guest-list-actions"></span>
                    </li>
                  );
                }
              )} */}
              {gustList.map((data: any, index: number) => {
                return (
                  <li key={index}>
                    <span className="guest-list-mail">{data?.name}</span>
                    <span className="guest-list-mail">{data?.email}</span>
                    <span className="guest-list-unused">{data?.phone}</span>
                    <span className="guest-list-actions">
                      {/* <button type="button" className="btn px-2">
                      <i className="bi bi-link-45deg"></i>
                    </button>
                    <button type="button" className="btn px-2">
                      <i className="bi bi-send"></i>
                    </button> */}
                      <button
                        type="button"
                        className="btn px-2"
                        onClick={() => onDeleteGustList(index)}
                      >
                        <i className="bi bi-trash3 text-danger"></i>
                      </button>
                    </span>
                  </li>
                );
              })}
            </ul>
            {gustList.length > 0 && (
              <button
                className="btn btn-outline-black mx-auto"
                onClick={onSendTickets}
              >
                Send Tickets
              </button>
            )}
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={showIsMobileGoLive}
        onHide={handleCloseShowIsMobileGoLive}
        centered
        dialogClassName="sign-up-modal login-modal"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="auth-container">
            <div className="auth-sec auth-right-sec">
              <div className="auth-info">
                <h2>Alert</h2>
              </div>
              <div className="row"></div>
              Please download mobile app and start stream.
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </>
  );
};
const mapStateToProps = (state: any) => {
  return {
    UserReducer: state.UserReducer,
    LoginUserIdReducer: state.LoginUserIdReducer,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    loading: (data: any) => dispatch(Loading(data)),
  };
};

const connectedNavBar = connect(
  mapStateToProps,
  mapDispatchToProps
)(MyLivestreamsScheduledPage);
export { connectedNavBar as MyLivestreamsScheduledPage };
