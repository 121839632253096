import { Switch, Route } from "react-router";
import { PurchasedTicketAttendedPage } from "../pages/dashboard/purchased-tickets/purchased-ticket-attended";
import { PurchasedTicketCancelledPage } from "../pages/dashboard/purchased-tickets/purchased-ticket-cancelled";
import { PurchasedTicketRecordingsPage } from "../pages/dashboard/purchased-tickets/purchased-ticket-recordings";
import { PurchasedTicketUpcomingPage } from "../pages/dashboard/purchased-tickets/purchased-ticket-scheduled";

const PurchasedTicketRoutes = () => {
    return (
        <Switch>
            <Route exact path="/dashboard/purchasedtickets/upcoming" component={PurchasedTicketUpcomingPage}></Route>
            <Route exact path="/dashboard/purchasedtickets/attended" component={PurchasedTicketAttendedPage}></Route>
            <Route exact path="/dashboard/purchasedtickets/cancelled" component={PurchasedTicketCancelledPage}></Route>
            <Route exact path="/dashboard/purchasedtickets/recordings" component={PurchasedTicketRecordingsPage}></Route>
        </Switch>
    );
}

export default PurchasedTicketRoutes;