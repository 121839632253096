export const HomePageData = {
  introBanner: [
    {
      "_id": 1,
      "name": "Deepika Padukone",
      "livestreamDate": "25 Feb, 2022",
      "livestreamDescription": "Livestream Title Goes Here",
      "livestreamImage": "https://s3.ap-south-1.amazonaws.com/clanworld.ai/static/media/deepika.7f5fdcf4.jpg",
      "url": "#"
    },
    {
      "_id": 2,
      "name": "Anunay Sood",
      "livestreamDate": "20 Feb, 2022",
      "livestreamDescription": "Livestream Title Goes Here",
      "livestreamImage": "https://s3.ap-south-1.amazonaws.com/clanworld.ai/static/media/anunay-sood.jpeg",
      "url": "#"
    },
    {
      "_id": 3,
      "name": "Kareena Kapoor",
      "livestreamDate": "20 Apr, 2022",
      "livestreamDescription": "Livestream Title Goes Here",
      "livestreamImage": "https://s3.ap-south-1.amazonaws.com/clanworld.irida.in/static/media/mobile-banner-kareena.jpg",
      "url": "#"
    },
    {
      "_id": 4,
      "name": "CarryMinati",
      "livestreamDate": "30 Apr, 2022",
      "livestreamDescription": "Livestream Title Goes Here",
      "livestreamImage": "https://s3.ap-south-1.amazonaws.com/clanworld.irida.in/static/media/carryminati.jpg",
      "url": "#"
    },
  ],
  intro: {
    title: "Clan Clubs",
    subtitle:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed sed risus non turpis egestas sollicitudin sed ac nulla. Aenean aliquam rhoncus felis, ac feugiat elit. Sed tempus magna consectetur quam pretium, eu suscipit mauris sagittis.",
  },
  howItWork: {
    title: "How does it work",
    subtitle:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed sed risus non turpis egestas sollicitudin sed ac nulla. Aenean aliquam rhoncus felis, ac feugiat elit. Sed tempus magna consectetur quam pretium, eu suscipit mauris sagittis. Sed efficitur euismod lacus non elementum. Pellentesque tempus viverra molestie. Nulla ut tortor et lectus fringilla pellentesque nec vel sem.",
  },
  featuredClubs: {
    sectionTitle: "Featured Clubs",
    Clubs: [
      {
        _id: 1,
        name: "Ballet Is Life",
        total_subscribers: "231",
        total_posts: "32",
        channelName: "chandrayeechattopadhyay",
        img: require("../assets/img/featured-img.png"),
        about: "test",
      },
      {
        _id: 2,
        name: "Ballet Is Life1",
        total_subscribers: "231",
        total_posts: "32",
        channelName: "chandrayeechattopadhyay",
        img: require("../assets/img/featured-img.png"),
        about: "test",
      },
      {
        _id: 3,
        name: "Ballet Is Life",
        total_subscribers: "231",
        total_posts: "32",
        channelName: "chandrayeechattopadhyay",
        img: require("../assets/img/featured-img.png"),
        className: "grid-h grid-v",
        about: "test",
      },
      {
        _id: 4,
        name: "Ballet Is Life",
        total_subscribers: "231",
        total_posts: "32",
        channelName: "chandrayeechattopadhyay",
        img: require("../assets/img/featured-img.png"),
        className: "grid-h grid-v",
        about: "test",
      },
      {
        _id: 5,
        name: "Ballet Is Life",
        total_subscribers: "231",
        total_posts: "32",
        channelName: "chandrayeechattopadhyay",
        img: require("../assets/img/featured-img.png"),
        about: "test",
      },
      {
        _id: 6,
        name: "Ballet Is Life",
        total_subscribers: "231",
        total_posts: "32",
        channelName: "chandrayeechattopadhyay",
        img: "../assets/img/featured-img.png",
        about: "test",
      },
      {
        _id: 7,
        name: "Ballet Is Life",
        total_subscribers: "231",
        total_posts: "32",
        channelName: "chandrayeechattopadhyay",
        img: require("../assets/img/featured-img.png"),
        about: "test",
      },
      {
        _id: 8,
        name: "Ballet Is Life",
        total_subscribers: "231",
        total_posts: "32",
        channelName: "chandrayeechattopadhyay",
        img: require("../assets/img/featured-img.png"),
        className: "grid-h grid-v",
        about: "test",
      },
      {
        _id: 9,
        name: "Ballet Is Life",
        total_subscribers: "231",
        total_posts: "32",
        channelName: "chandrayeechattopadhyay",
        img: require("../assets/img/featured-img.png"),
        about: "test",
      },
      {
        _id: 10,
        name: "Ballet Is Life",
        total_subscribers: "231",
        total_posts: "32",
        channelName: "chandrayeechattopadhyay",
        img: require("../assets/img/featured-img.png"),
        about: "test",
      },
      {
        _id: 11,
        name: "Ballet Is Life",
        total_subscribers: "231",
        total_posts: "32",
        channelName: "chandrayeechattopadhyay",
        img: require("../assets/img/featured-img.png"),
        about: "test",
      },
    ],
  },
  clubBenefits: {
    title: "Club Benefits",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed sed risus non turpis egestas sollicitudin sed ac nulla. Aenean aliquam rhoncus felis, ac feugiat elit. Sed tempus magna consectetur quam pretium, eu suscipit mauris sagittis. Sed efficitur euismod lacus non elementum. Pellentesque tempus viverra molestie. Nulla ut tortor et lectus fringilla pellentesque nec vel sem.",
  },
  diveIn: [
    {
      id: 1,
      title: "Basics of my life",
      channelName: "@anushkasharma",
      sliderImage: "images/slider-img.png",
      sliderTime: "Aug 17, 2:00 pm IST",
    },
    {
      id: 2,
      title: "Travel Secrets",
      channelName: "@shenaztreasury",
      sliderImage: "images/slider-img1.png",
      sliderTime: "Aug 28, 2:00 pm IST",
    },
    {
      id: 3,
      title: "Photography Skills",
      channelName: "@ananaysood",
      sliderImage: "images/slider-img2.png",
      sliderTime: "Aug 04, 5:00 pm IST",
    },
    {
      id: 4,
      title: "Basics of my life",
      channelName: "@anushkasharma",
      sliderImage: "images/slider-img.png",
      sliderTime: "Aug 17, 2:00 pm IST",
    },
    {
      id: 5,
      title: "Travel Secrets",
      channelName: "@shenaztreasury",
      sliderImage: "images/slider-img1.png",
      sliderTime: "Aug 28, 2:00 pm IST",
    },
    {
      id: 6,
      title: "Photography Skills",
      channelName: "@ananaysood",
      sliderImage: "images/slider-img2.png",
      sliderTime: "Aug 04, 5:00 pm IST",
    },
    {
      id: 7,
      title: "Basics of my life",
      channelName: "@anushkasharma",
      sliderImage: "images/slider-img.png",
      sliderTime: "Aug 17, 2:00 pm IST",
    },
    {
      id: 8,
      title: "Travel Secrets",
      channelName: "@shenaztreasury",
      sliderImage: "images/slider-img1.png",
      sliderTime: "Aug 28, 2:00 pm IST",
    },
    {
      id: 9,
      title: "Photography Skills",
      channelName: "@ananaysood",
      sliderImage: "images/slider-img2.png",
      sliderTime: "Aug 04, 5:00 pm IST",
    },
    {
      id: 10,
      title: "Basics of my life",
      channelName: "@anushkasharma",
      sliderImage: "images/slider-img.png",
      sliderTime: "Aug 17, 2:00 pm IST",
    },
    {
      id: 11,
      title: "Travel Secrets",
      channelName: "@shenaztreasury",
      sliderImage: "images/slider-img1.png",
      sliderTime: "Aug 28, 2:00 pm IST",
    },
    {
      id: 12,
      title: "Photography Skills",
      channelName: "@ananaysood",
      sliderImage: "images/slider-img2.png",
      sliderTime: "Aug 04, 5:00 pm IST",
    },
  ],
};

export const FooterData = {
  location: {
    title: "Locations",
    locationInfo: [
      {
        id: 1,
        title: "DE",
        address:
          "43A, Ground Floor, Phase III, Okhla Industrial Area, South Delhi, Delhi 110020",
      },
      {
        id: 2,
        title: "BE",
        address:
          "5th Floor, 46/1, Cobalt Building, Church Street, Bangalore, Bengaluru Urban,Karnataka 560001",
      },
      {
        id: 3,
        title: "MU",
        address:
          "Unit no. 401, Shivraj heights, 14th Road, Khar west, Mumbai city, Maharashtra - 400052",
      },
    ],
  },

  company: {
    title: "Company",
    footerLinks: [
      {
        id: 1,
        title: "About",
        url: "/about",
      },

      {
        id: 4,
        title: "FAQs",
        url: "/faq",
      },

      {
        id: 5,
        title: "Get In Touch",
        url: "/support",
      }
    ],
  },
  policies: {
    title: "Policies",
    footerLinks: [
      {
        id: 1,
        title: "Privacy Policy",
        url: "/privacy-policy",
      },
      {
        id: 2,
        title: "Refund Policy",
        url: "/refund-policy",
      },

      {
        id: 3,
        title: "Terms & Conditions",
        url: "/terms-conditions",
      },
    ],
  },
  contact: {
    title: "Contact",
    email: "info@clanconnect.ai",
    tel: "011-41193911",
    socialLinks: [
      {
        id: 1,
        iconName: "bi bi-linkedin  size",
        url: "#",
      },
      {
        id: 2,
        iconName: "bi bi-instagram size",
        url: "#",
      },
      {
        id: 3,
        iconName: "bi bi-twitter size",
        url: "#",
      },
    ],
  },
};

export const UploadData = {
  video: {
    id: 1,
    fileSize: "500 MB",
    formats:
      ".mov, .mkv, .mpg, .mp2, .mpeg, .mpe, .mpv, .m2v, .mp4, .m4p, .m4v, .avi, .wmv, .flv, .3gp, .webm",
  },
  image: {
    id: 2,
    fileSize: "10 MB",
    recommendedDimension: "1920 w x 500 h",
    formats: ".jpg, .jpeg, .png, .gif",
  },
  audio: {
    id: 3,
    formats: ".m4a, .flac, .mp3, .wav, .wma, .aac, .mpeg",
  },
};
