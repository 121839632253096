import React from "react";
import { Link, NavLink } from "react-router-dom";

type Props = {};

const TermsConditions = (props: Props) => {
  return (
    <div className="container pt-5">
      <h3 className="pb-3">Terms &amp; Conditions</h3>
      <div className="content-info">
        <p>
          These terms and conditions <strong>(“Terms”)</strong> govern your use
          of the Clanworld website &nbsp;
          <Link to="www.clanworld.ai">www.clanworld.ai</Link> (
          <strong>“Website”</strong>), and the Clanworld mobile application (
          <strong>“App”</strong>), (Website and App are hereinafter collectively
          referred to as <strong>“Platform”</strong>). The company Irida
          Interactive Private Limited having its registered office at 93, 3rd
          Floor, Okhla Phase III, Industrial Estate, New Delhi, South Delhi,
          110020, India (hereinafter referred to as{" "}
          <strong>“we”, “our”, “us” and “Company”</strong>) is the sole and
          exclusive owner of the brand Clanworld and the Platform, which is
          owned and registered solely and exclusively in its name. By visiting,
          using, or interacting with the Platform in any manner whatsoever and
          in any capacity whatsoever, you accept these terms and conditions in
          full. If you do not agree to these terms, then you shall leave the
          Platform and stop using any Services (<em>defined hereinafter</em>)
          rendered therein.
        </p>
        <p>
          This document is published in accordance with the provisions of Rule 3
          of the Information Technology (Intermediary Guidelines and Digital
          Media Ethics Code) Rules, 2021 which mandates an intermediary to
          publish rules and regulations, privacy policy, and the terms and
          conditions for access or usage of its website or application.
        </p>
        <p>
          The Company often updates its Platform and Services (
          <strong>defined hereinafter</strong>), due to the same, the Company
          may change/modify/alter/update/add or discontinue these Terms
          periodically. It shall be your responsibility to check these Terms
          regularly for any changes. You are requested to check this page
          regularly to ensure that you are familiar with the updated Terms. By
          using the Platform in any manner or capacity whatsoever, it shall be
          deemed that you have read and understood the updated Terms and
          consented thereto.
        </p>
        <p>
          Notwithstanding anything contained herein, if you breach the Terms or
          privacy policy or any other rules or policies of the Company, then the
          Company reserve the right to take any legal or other appropriate
          action including but not limited to denying or revoking access to the
          Platform, or referral to the appropriate authorities.
        </p>
        <strong>Definition:</strong>
        <p>
          <strong>“Agreement”</strong> shall mean these Terms, the privacy
          policy, refund policy and any other policies as may be framed by us
          from time to time.
        </p>
        <p>
          <strong>
            “you”, “yourself”, “yourselves”, “audience”, and “You”
          </strong>{" "}
          shall mean any person who creates an account on the Platform to avail
          the Services (<em>defined hereinafter</em>). It also includes any
          person making a purchase on payment of ticket fees towards access of
          any Live Stream (<em>defined hereinafter</em>) event on the Platform,
          and who meets the eligibility criteria as set out below.
        </p>
        <p>
          <strong>“Celebrity”</strong> shall mean any person who is engaged with
          the Company for the purpose of performing live stream, interacting
          with the audience on Live Stream through comments etc., on the
          Platform. Celebrity includes but it is not limited to actors,
          influencers, sports person, stand-up comedians etc.
        </p>
        <p>
          <strong>“Live Stream”</strong> means transmitting or receiving a live
          video and audio coverage of an event/performance by a Celebrity over
          the internet through the Platform.
        </p>
        <p>
          <strong>“User”</strong> shall mean and include both the audience and
          Celebrity.
        </p>
        <p>
          <strong>“Service”</strong> shall include but is not limited to
          services such as broadcasting/publishing Live Stream for the
          consumption of the audience through the App installed on mobile
          device, tablet, computer, etc., or through the Website
          www.clanworld.ai when opened on internet. In case of Celebrity,
          services include support, and use of Platform as a means to
          broadcast/publish their Live Stream for monetary gains or publicity,
          as discussed in detail hereinbelow.
        </p>
        <p>
          <strong>“Ticket Fee”</strong> shall mean the fee payable by audience
          for attending a Live Stream.
        </p>
        <strong>Eligibility</strong>
        <p>
          Minors, un-discharged insolvents, and those who are incompetent to
          contract under the Indian Contract Act, 1872, or the corresponding
          legislation in your country, will not be able to access the Platform
          or use the Services. Any minor who intends to use or contribute to the
          Platform must do so through his or her legal guardian or parents, as
          required by law. You have to pay a Ticket Fee for availing the
          Services available on the Platform.
        </p>
        <p>
          You shall be responsible for keeping the confidentiality of any name
          or access you use while using the Platform, as well as for all
          activities that occur under your name or access. Furthermore, You
          or/and anyone who uses your login information will be financially
          responsible for all use of the Platform and purchases made, if any.
        </p>
        <strong>Availability of Services</strong>
        <p>
          By using the Platform, User acknowledges that while we take every
          reasonable action to make sure that the Services remain fully
          functional and up-to-date, interruptions do still happen, for many
          reasons at times due to incomprehensible reason, including for routine
          maintenance, as may be required for effective functioning of the
          Platform. User understands and acknowledges that the Services could be
          interrupted, suspended, or terminated due to any reason whatsoever,
          without any fault of ours and therefore we shall not be held liable in
          any manner whatsoever, for any loss to anyone due to such
          interruption, suspension, or termination of Services without any fault
          of ours.
        </p>
        <strong>Services and content</strong>
        <p>
          The Platform, inter alia, provides a Service to the audience to access
          exclusive Live Streams of the Celebrity through the Platform. Each of
          the Services offered by the Company shall be governed by these Terms
          and any other additional terms specific to each of such Service
          including as published on the Platform.
        </p>
        <p>
          User understand and agree that we are not responsible or liable for
          any content, data, text, information, usernames, graphics, images,
          photographs, profiles, audio, video, items, products, listings, links
          or information posted by any audience, Celebrity or third parties on
          the Platform.
        </p>
        <p>
          You fully understand that any content uploaded, live streamed,
          submitted, or offered for publication by the Celebrity via the
          Services, shall be the content of the Celebrity. The Company does not
          review or modify any content, except as stated here otherwise. The
          Company does not endorse any content submitted via the Platform by any
          Celebrity, or any opinion, recommendation, or advice expressed
          therein, and the Company expressly disclaims any and all liability in
          connection with the content.
        </p>
        <strong>Account</strong>
        <p>
          To register an account with us, the User will be required to fill an
          online form on the Platform, by providing certain essential details (
          <strong>“Form”</strong>) as may be provided therein. We expect that
          You would complete the Form with fairness and honesty while furnishing
          the information, as required to create an account on the Platform.
        </p>
        <p>
          Every Form filled by the User shall undergo a review process by our
          team and We reserve the right to seek such additional details as may
          be required by the Company. After filling the Form, you will be
          required to pay the Ticket Fee to avail the Services. Further, if at
          any point of time we come to know or have reasons to believe that any
          information provided by the User is untrue, inaccurate, or incomplete
          then we have the full right to suspend or terminate the User account
          and forfeit the amount paid by the User towards our Services and
          refuse to provide our Service to the User thereafter without any
          notice.
        </p>
        <p>
          The User is responsible to keep their account details up to date at
          all times. The User shall be responsible for all activities that occur
          under the User’s account. The User will not sell or otherwise transfer
          the account details to another person or entity.
        </p>
        <strong>Cancellation By the account on the Platform:</strong>
        <p>
          The User may deregister their account in full, at any time by sending
          an email requesting for cancellation/deregistration of the account to
          the given email address support@clanworld.ai. The User hereby agree to
          be personally liable for any and all charges incurred by the User of
          their name until they terminate the account with the Platform as
          provided herein. You acknowledge and agree that if you cancel the
          account on the Platform, the access associated with that account will
          be removed permanently from our database and if the User wish to
          create an account again in future, the User will have to fill the Form
          again, as all the previous data and information collected by us will
          be removed.
        </p>
        <strong>Ticket Fees</strong>
        <p>
          You are required to pay a Ticket Fee to the Company for availing the
          Services on the Platform. You are required to pay a 1 (one) time
          ticket fee for accessing the exclusive 1 (one) live stream available
          on the Platform as per schedule mentioned on the ticket. The ticket
          fee for different live streaming will be displayed on the Platform
          depending upon the several factors of the live stream. With the 1
          (one) time payment of the ticket fee, You can only access 1 (one) live
          stream. Further, you will be responsible for all charges, fees,
          duties, taxes, and assessment arising out of your use of the Platform.
        </p>
        <strong>Rights and Responsibilities</strong>
        <p>
          Upon the Users continued compliance with these Terms, they shall have
          a non-exclusive and a limited license to use any Service purchased on
          the Platform and the content comprised therein, for permitted uses and
          for permitted duration. The User agrees that it will not Page 4 of 2
          redistribute, transmit, assign, sell, broadcast, rent, share, lend,
          modify, adapt, edit, license, or otherwise transfer or use the
          Services and/or the content, in whole or in part. For the avoidance of
          doubt, the license to use the Services and/or the content does not
          include the right to create a derivative work, to make copies other
          than for your own personal use, or to use the Services and/or the
          content in any commercial manner, except as agreed otherwise. If you
          breach any of the provisions herein, your license to use the Services
          and/or the content is terminated with immediate effect and without
          further notice. The grant and continuation of the above licence is
          conditional upon your compliance with these Terms. You shall not
          remove or modify any copyright and/or other intellectual property
          notices or watermarks from any Services. Without limiting any other
          rights or remedies available to the Company, any attempt to circumvent
          the Company may result in termination of your account.
        </p>
        <p>
          The content produced by the Celebrity will be their sole
          responsibility, the Company or the Platform shall not be held
          responsible for any content posted by any Celebrity.
        </p>
        <p>
          The Company may, from time to time monitor and review any information
          transmitted or received and reserve the right to censor, edit, remove,
          or prohibit the transmission or receipt of any information that
          Company deems inappropriate or in violation of these Terms. During
          monitoring, the information may be examined, recorded, or copied, and
          your use of the Platform constitutes your consent to such monitoring
          and review. The Company takes no responsibility and assumes no
          liability for any content posted or submitted by any Celebrity, or for
          any response to such content.
        </p>
        <strong>Prohibitions</strong>
        <p>
          Users agree not to host, display, upload, modify, publish, transmit,
          store, use, update or share any information that:
        </p>
        <ul>
          <li>
            belongs to another person and to which the User does not have any
            right;
          </li>
          <li>
            is defamatory, obscene, pornographic, paedophilic, invasive of
            another’s privacy, including bodily privacy, insulting, or harassing
            on the basis of gender, libellous, racially or ethnically
            objectionable, relating or encouraging money laundering or gambling,
            or otherwise inconsistent with or contrary to the laws in force;
          </li>
          <li>is harmful to child;</li>
          <li>
            infringes any patent, trademark, copyright, or other proprietary
            rights;
          </li>
          <li>violates any law for the time being in force;</li>
          <li>
            deceives or misleads the addressee about the origin of the message
            or knowingly and intentionally communicates any information which is
            patently false or misleading in nature but may reasonably be
            perceived as a fact;
          </li>
          <li>impersonates another person;</li>
          <li>
            threatens the unity, integrity, defence, security or sovereignty of
            India, friendly relations with foreign States, or public order, or
            causes incitement to the commission of any cognisable offence or
            prevents investigation of any offence or is insulting other nation;
          </li>
          <li>
            contains software virus or any other computer code, file or program
            designed to interrupt, destroy, or limit the functionality of any
            computer resource;
          </li>
          <li>
            is patently false and untrue, and is written or published in any
            form, with the intent to mislead or harass a person, entity, or
            agency for financial gain or to cause any injury to any person;
          </li>
          <li>violates any content policies of the Company;</li>
          <li>
            threatens public health or safety and promotion of cigarettes or any
            other tobacco products or consumption of intoxicant including
            alcohol and Electronic Nicotine Delivery System (ENDS) &amp; like
            products that enable nicotine delivery except for the purpose &amp;
            in the manner and to the extent, as may be approved under the Drugs
            and Cosmetics Act, 1940 and Rules made thereunder;
          </li>
          <li>
            Send any unsolicited or unauthorized advertising, promotional
            materials, email, junk mail, spam, chain letters or other form of
            solicitation or use automated programs to start chats;
          </li>
          <li>
            transmit any trojan horses, worms, time bombs, or other computer
            programming routines or any code of a destructive nature that may
            damage, interfere, or attempt to interfere with, intercept, the
            normal operation of the Platform, or take any action that imposes an
            unreasonable load on our computer equipment, or that infringes upon
            the rights of a third party.
          </li>
          <li>
            use any &quot;deep-link&quot;, &quot;page-scrape&quot;,
            &quot;robot&quot;, &quot;spider&quot; or other automatic device,
            program, algorithm or methodology, or any similar or equivalent
            manual process, to access, acquire, copy or monitor any portion of
            the Platform, Services, or any content, or in any way reproduce or
            circumvent the navigational structure or presentation of the
            Platform, Services, to obtain or attempt to obtain any materials,
            documents or information through any means not purposely made
            available through the Platform.
          </li>
          <li>
            not engage in advertising to, or solicitation of, the Celebrities or
            the Platform to buy or sell any services, including, but not limited
            to, services related to that being displayed on the Platform or
            related to us.
          </li>
        </ul>
        <div
          style={{
            border: "1px solid #eee",
            padding: "10px",
            textTransform: "uppercase",
            fontWeight: "bold",
            marginBottom: "20px",
          }}
        >
          not engage in advertising to, or solicitation of, the Celebrities or
          the Platform to buy or sell any services, including, but not limited
          to, services related to that being displayed on the Platform or
          related to us.
        </div>
        <strong>Links to third party sites</strong>
        <p>
          The Platform may contain links to other websites and online resources,
          including to payment gateways. A link to a third party’s website does
          not mean that we endorse it or that we are affiliated with it. We do
          not assume or accept any liability or responsibility for any of the
          content that may be contained in any third-party sites or apps, links
          for which may be active or available on the Platform. User should
          always read the terms and conditions and privacy policy of a
          third-party website before using it.
        </p>
        <strong>Intellectual Property Right</strong>
        <p>
          We solely own rights in the name and mark of Clanworld and the logo
          that appears on the Platform (the <strong>“Logo”</strong>). Users are
          not permitted to, and agree not to, use these marks in any way
          (including part of any other trademark, Company name or domain name),
          in connection with any product or service.
        </p>
        <p>
          We own and have the authorized licensee of all intellectual property
          rights (including copyright) in and to the Platform including
          intellectual property rights in the content hosted, published,
          displayed, uploaded on the Platform by us but does not include the
          content posted by the Celebrity, Celebrities are the exclusive owner
          of their content posted on the Platform in any way. Copyright laws and
          treaties throughout the world protect these works and all rights in
          and to them are reserved by us for our content. No information,
          content, or material from by us may be copied, reproduced,
          republished, uploaded, posted, transmitted, or distributed in any way
          without our express written permission. You are not allowed to screen
          record or record through any camera or through any other way any
          content or live streaming available on the Platform.
        </p>
        <strong>Term and Termination</strong>
        <p>
          This Agreement begins when the User creates an account with us and
          will continue till, they remove their account with us. If the User
          breaches any of the term under this Agreement, prohibitions provided
          herein above or violation of any other law, Company may, at its
          option:
        </p>
        <ol style={{ listStyleType: "lower-alpha" }}>
          <li>
            terminate this Agreement immediately, with or without advance
            written notice;
          </li>
          <li>
            suspend, delete, or limit access to your account (and other accounts
            you control) or any content within it; and
          </li>
          <li>
            to the extent permitted by applicable law, retain any amounts
            payable to you (which you forfeit). If Company deletes your account
            for breach, you may only re-register at the sole discretion of the
            Company.
          </li>
        </ol>
        <strong>YouTube</strong>
        <p>
          We use YouTube API Services. Users are agreeing to be bound by
          YouTube’s Terms of Service (ToS){" "}
          <Link target="_blank" to="https://www.youtube.com/t/terms">
            https://www.youtube.com/t/terms.
          </Link>{" "}
          The User can revoke the Platform’s access to their data via Google, go
          to{" "}
          <Link target="_blank" to="https://myaccount.google.com/permissions">
            https://myaccount.google.com/permissions
          </Link>
          .
        </p>
        <strong>Dispute Resolution</strong>
        <p>
          If User considers there to be a dispute between User and Company or
          the Services provided by Company, please contact us at
          support@clanworld.ai. These Terms, privacy policy and refund policy
          that are available on the Platform shall be governed by and construed
          in accordance with the laws of India. Users agree to submit to the
          exclusive jurisdiction of the courts of India to resolve any dispute
          arising out of the same. We shall not be held liable for any dispute
          arising out of any communication led through/on our Platform between
          any two Users using the Platform. User’s use of the Platform is not
          permitted in any jurisdiction that does not give effect to all
          provisions of the Terms, including without limitation, this section.
        </p>
        <strong>Breach of these Terms</strong>
        <p>
          User’s account is non-transferrable and may not be sold, traded,
          combined, or otherwise shared with any other person. If any User
          violate any of these Terms, we may terminate the account in our sole
          discretion and without limitation, either with or without notice.
          Should we terminate User account, we will forfeit all the information
          provided by User through the use of Platform and User may not be
          entitled to re-enrol or re-join or create a new account unless invited
          to re-join or to a new account by us.
        </p>
        <p>
          Upon suspension or termination of User’s account with us, we reserve
          the right to remove or delete User’s information that is available
          with us, including but not limited to login, account information. The
          restriction, suspension or termination of User’s account or access to
          the Platform pursuant to this section will be without prejudice to any
          rights which we may have against User in respect of breach of these
          terms and conditions. Further, if required by applicable law or by a
          court order or by other enforcement authorities and/ or agencies or if
          we in our sole discretion consider the disclosure of such information
          necessary or appropriate, we will disclose your identity and other
          details, as available by us.
        </p>
        <strong>Indemnification</strong>
        <p>
          Users agree to indemnify and hold us, our directors, representatives,
          employees, and contractors, harmless from any claim or demand,
          including reasonable solicitors’ fees, arising out of User’s use of
          the Platform, the breach of these Terms by User, or the infringement
          by User of any intellectual property or other right of any other
          person or entity.
        </p>
        <strong>Audience’s Warranties</strong>
        <p>
          By creating an account with us and accessing information as part of
          the Services, the User warrant and agree that they:
        </p>
        <ol style={{ listStyleType: "lower-alpha" }}>
          <li>
            possess the authority to create a binding legal obligation, on
            behalf of themselves personally;
          </li>
          <li>
            that all the information that they provide to us is only about
            themself and that all of such information is accurate, true,
            current, and complete;
          </li>

          <li>
            their of the Service will always comply with the Terms as specified
            herein; and
          </li>
          <li>
            they will remain responsible for all uses of their accounts,
            safeguard account’s name, and supervise the use of their account.
          </li>
        </ol>
        <strong>Severability</strong>
        <p>
          If any provision of these Terms is unenforceable as determined by a
          court of competent jurisdiction under applicable law, then such
          provision shall be struck out and all remaining provisions shall
          remain in full force and effect. If we delay or fail to act in respect
          of any breach by you of these terms and conditions, this will not
          operate as a waiver of our right to act in relation to subsequent or
          similar breaches by you.
        </p>
        <strong>Grievance redressal mechanism</strong>
        <p>
          Any User of the Platform can submit a complaint to the{" "}
          <strong>
            <a href="mailto:support@clanworld.ai">support@clanworld.ai</a>
          </strong>{" "}
          . (<strong>“Grievance Officer”</strong>) for any grievance against the
          violation of the prohibitions against any User or otherwise as
          mentioned under these Terms.
        </p>
        <p>
          The User shall submit the complaint to Grievance Officer physically or
          through e-mail. The complaint by the User shall contain all the
          necessary information regarding a violation that shall include name of
          the User, description of the content, information regarding the
          violation that User did and other relevant information. The Grievance
          Officer shall acknowledge the complaint within 24 (twenty-four) hours
          from receiving the complaint and the complaint of User shall be
          resolved under 15 (fifteen days) days from acknowledging the
          complaint. The Company will take a necessary action against the
          content and User against whom the complaint has been registered.
        </p>
        <strong>Force Majeure</strong>
        <p>
          Under no circumstances will We be held liable for any delay or failure
          in performance due in whole or in part to any acts of nature, labor
          disputes, strikes, acts of God, floods, lightning, severe weather,
          shortages of materials, rationing, lockdown, pandemic or epidemic,
          inducement of any virus, trojan or other disruptive mechanisms, any
          event of hacking or illegal usage of the Platform, utility or
          communication failures, earthquakes, war, revolution, acts of
          terrorism, civil commotion, acts of public enemies, blockade, embargo
          or any law, order, proclamation, regulation, ordinance, demand or
          requirement having legal effect of any government or any judicial
          authority or representative of any such government, or any other act
          whatsoever, whether similar or dissimilar to those referred to in this
          clause beyond our reasonable control. If Force Majeure event takes
          place that affects the performance of our obligations under these
          Terms, our obligations under these terms shall be suspended for the
          duration of Force Majeure event.
        </p>
        <strong>Miscellaneous</strong>
        <p>
          Waiver by User of any breach or default or failure to exercise any
          right allowed hereunder constitutes a waiver of any prior, or
          subsequent breach, or default, and a waiver or forfeiture of any
          similar or future rights. The section headings used herein are for
          convenience only and shall be of no legal force or effect. If a court
          of competent jurisdiction holds any provision of these terms of use
          invalid, such invalidity shall not affect the enforceability of any
          other provisions contained herein and the remaining portions shall
          continue to be in full force and effect. These Terms, other agreement
          executed between the User and us, and any other related terms
          constitute the sole and entire agreement between User and us with
          respect to the Service and supersede all prior and contemporaneous
          understandings, representations, and warranties, both written and oral
          with respect to the Services.
        </p>
        <strong>Contact us</strong>
        <p>
          If you need to contact us for anything, you must write to us at{" "}
          <a href="mailto:support@clanworld.ai">support@clanworld.ai</a>
        </p>
      </div>
    </div>
  );
};

export default TermsConditions;
