import React, { useState, useEffect } from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import aceLogo from "../assets/img/ace.svg";
import LiveIcon from "../assets/img/goLive1.svg";

type Props = {
  data: any;
  className?: string;
};

const CardLivestream = (props: Props) => {
  const [livestreamImage, setLivestreamImage] = useState("");
  const [isImageLarge, setIsImageLarge] = useState(false);

  var time =
    moment(props.data?.schedule_To).utc().valueOf() - moment().valueOf();
  var diffTime = new Date(time);
  var needTime = diffTime.toLocaleTimeString();
  var endsInSec = needTime.slice(6, 8);
  var endsInMin = needTime.slice(3, 5);
  //
  // console.log(diffTime);
  // console.log(Date.parse(props.data?.schedule_To));

  const [minutes, setMinutes] = useState<any>(endsInMin);
  const [seconds, setSeconds] = useState<any>(endsInSec);
  var startTime = moment(props.data?.schedule_From).utc().format();
  var finishTime = moment(props.data?.schedule_To).utc().format();
  var current = moment().format().slice(0, 19).concat("Z");

  var milliSeconds = moment(props.data?.schedule_From).valueOf();

  // console.log(time);
  // console.log(props.data);

  const reloading: any = () => {
    if (current === startTime) {
      window.location.reload();
      console.log("timer-console");
    }
  };
  setTimeout(reloading, milliSeconds);
  useEffect(() => {
    let myInterval = setInterval(() => {
      if (current >= startTime) {
        localStorage.setItem("minutes", endsInMin);
        localStorage.setItem("seconds", endsInSec.toLocaleString());
      }
      if (minutes <= 0 && seconds <= 0) {
        localStorage.removeItem("minutes");
        localStorage.removeItem("seconds");
      }
      if (seconds > 0) {
        setSeconds(seconds - 1);
        localStorage.setItem("seconds", seconds);
        localStorage.setItem("minutes", minutes);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          localStorage.removeItem("minutes");
          localStorage.removeItem("seconds");
          clearInterval(myInterval);
        }
        if (current === props.data.schedule_from) {
          window.location.reload();
          console.log("timer");
        } else {
          setMinutes(minutes - 1);
          localStorage.setItem("minutes", minutes);

          setSeconds(59);
        }
      }
    }, 1000);
    return () => {
      clearInterval(myInterval);
    };
  }, [minutes, seconds]);

  // const result = +props.data.schedule_From - Date.now();

  const img = new Image();
  img.src = props?.data?.thumbnail;

  img.onload = () => {
    const actualwidth = img.width;
    const actualheight = img.height;
    // console.log(actualwidth, actualheight);
    if (actualwidth > 700) {
      setLivestreamImage(img.src);
      setIsImageLarge(true);
    }
  };
  const scheduleFrom = moment(props.data?.schedule_From)
    .utc()
    .format("hh:mm A");
  const scheduleTill = moment(props.data?.schedule_To).utc().format("hh:mm A");
  return (
    <>
      {/* {console.log(props.data)} */}
      <Link
        to={`/livestreams/livestream-detail/${props.data?._id}`}
        className={["card-livestream", props.className].join(" ")}
      >
        {props.data?.isNftEnabled && (
          <div className="card-livestream-ribbon card-livestream-ribbon-top-left">
            <span>
              <img src={aceLogo} alt="Ace" /> NFT
            </span>
          </div>
        )}
        {current >= startTime && current <= finishTime && (
          <div className="card-livestream-live-sec">
            <div className="card-livestream-liveIcon">
              <img src={LiveIcon} alt="live icon" />
            </div>
            <span className="card-livestream-live-status-text">
              Ends in
              {minutes === 0 && seconds === 0 ? null : (
                <span>
                  {" "}
                  {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
                </span>
              )}
            </span>
          </div>
        )}

        <span
          className="card-livestream-bgimg"
          style={{
            backgroundImage: props.data?.thumbnail
              ? `url("${props.data?.thumbnail}")`
              : "linear-gradient(to right, #DDEFBB, #FFEEEE)",
            backgroundPosition: "center center",
            backgroundSize: "cover",
            filter: isImageLarge ? "blur(0px)" : "blur(3px)",
          }}
        ></span>
        {!isImageLarge && (
          <img
            className="card-livestream-img"
            src={props.data?.thumbnail}
            alt={props.data?.title}
          />
        )}

        <div className="card-livestream-content">
          <h3 className="card-livestream-title">
            <a
              href="javascript:void(0)"
              onClick={() => {}}
              title={props.data?.title}
            >
              {props.data?.title}
            </a>
          </h3>
          <Link
            to={`/livestreams/more-livestream/${props?.data?.user_id}/ls`}
            className="position-relative text-white link-underline"
          >
            @{props?.data?.user_info[0]?.name}
          </Link>
          <span className="card-livestream-date">
            {props.data &&
              moment(props?.data?.schedule_From)
                .utc()
                .format("DD-MM-YYYY | hh:mm A")}{" "}
            IST
          </span>
          <Link
            to={`/livestreams/livestream-detail/${props.data?._id}`}
            className="d-none d-lg-flex btn btn-outline-black btn-rounded"
          >
            View Details
          </Link>
        </div>
      </Link>
      {/* Mobile View */}
      {/* <Link
        className="d-flex d-md-none card-livestream"
        to={`/livestreams/livestream-detail/${props?.data?._id}`}
      >
        <span
          className="card-livestream-bgimg"
          style={{
            backgroundImage: props?.data?.thumbnail
              ? `url("${props?.data?.thumbnail}")`
              : "linear-gradient(to right, #DDEFBB, #FFEEEE)",
            backgroundPosition: "center center",
            backgroundSize: "cover",
            filter: isImageLarge ? "blur(0px)" : "blur(3px)",
          }}
        ></span>
        {!isImageLarge && (
          <img
            className="card-livestream-img"
            src={props?.data?.thumbnail}
            alt={props?.data?.title}
          />
        )}

        <div className="card-livestream-content">
          <h3 className="card-livestream-title">
            <a
              href="javascript:void(0)"
              onClick={() => {}}
              title={props?.data?.title}
            >
              {props?.data?.title}
            </a>
          </h3>
          <span className="dive-in-channel position-relative">
            @{props?.data?.user_info[0].name}
          </span>
          <span className="card-livestream-date">
            {moment(props?.data?.schedule_From)
              .utc()
              .local()
              .format("DD-MM-YYYY hh:mm A")}{" "}
            IST
          </span>
        </div>
      </Link> */}
    </>
  );
};

export default CardLivestream;
