import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import { NftService } from "../../../app/service/nft.service";
import { Loading } from "../../../redux/actions";
import NFTRoutes from '../../../routes/nft-routes'

interface Props {
  userId: string;
  UserReducer?: any;
  loading?: (data: any) => void;
  LoginUserIdReducer?: any;
}

const MyNFTPage: React.FC<Props> = (props: Props) => {
  const dispatch = useDispatch();

  // const MyNFTPage = (props: Props) => {
  let userId = sessionStorage.getItem("isLogin") || "";
  const [nftReports, setNftReports] = useState<any>({});

  useEffect(() => {
    getNftReportDetails();
  }, []);

  const getNftReportDetails = () => {
    if (props.loading) {
      dispatch(props.loading(true));
    }
    if (userId) {
      NftService.getNftReportDetails(userId).then((res) => {
        setNftReports(res);
        if (props.loading) {
          dispatch(props.loading(false));
        }
      });
    } else {
      if (props.loading) {
        dispatch(props.loading(false));
      }
    }
  };
  return (
    <div className="main-content my-nft">
      <div className="container-fluid">
        <div className="main-content-info-sec mb-5">
          <div className="d-flex flex-wrap mb-3">
            <h3 className="col-auto me-auto mb-md-0">NFTs Selling</h3>
            <Link
              className="btn btn-outline-black d-inline-flex col-auto"
              to="/nft/create-nft"
            >
              Create
            </Link>
          </div>
          <div className="main-content-info">
            <div className="main-content-info-card">
              <div className="main-content-info-card-content">
                <i className="bi bi-camera-video"></i>
                <div className="main-content-info-card-desc">
                  <span>Total NFTs created</span>
                  <strong>{nftReports?.total_created}</strong>
                </div>
              </div>
            </div>
            <div className="main-content-info-card">
              <div className="main-content-info-card-content">
                <i className="bi bi-person"></i>
                <div className="main-content-info-card-desc">
                  <span>Total Sold</span>
                  <strong>{nftReports?.total_sold}</strong>
                </div>
              </div>
            </div>
            <div className="main-content-info-card">
              <div className="main-content-info-card-content">
                <i className="bi bi-server"></i>
                <div className="main-content-info-card-desc">
                  <span>Total Earnings</span>
                  <strong>{nftReports?.total_revenue} ETH</strong>
                </div>
              </div>
            </div>
          </div>
        </div>
        <h3 className="mb-4">All NFTs</h3>
        <div className="main-content__top">
          <div className="custom-tab mb-1">
            <ul className="nav nav-pills">
              <li className="nav-item" role="presentation">
                <NavLink
                  className="nav-link"
                  id="scheduled-tab"
                  to="/dashboard/mynft/selling"
                >
                  Selling
                </NavLink>
              </li>
              <li className="nav-item" role="presentation">
                <NavLink
                  className="nav-link"
                  id="completed-tab"
                  to="/dashboard/mynft/owning"
                >
                  Owning
                </NavLink>
              </li>
            </ul>
          </div>
          {/* <div className="main-content__top__right">
            <DropdownButton
              id="dropdown-basic-button"
              title="Schedule Livestream"
            >
              <Dropdown.Item href="/#/dashboard/mylivestreams-public/1">
                Public{" "}
              </Dropdown.Item>
              <Dropdown.Item href="/#/dashboard/mylivestreams-private/1">
                Private
              </Dropdown.Item>
            </DropdownButton>
          </div> */}
        </div>
        <div className="tab-content" id="pills-tabContent">
          <div
            className="tab-pane active"
            id="details"
            role="tabpanel"
            aria-labelledby="details-tab"
          >
            <NFTRoutes></NFTRoutes>
          </div>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state: any) => {
  return {
    UserReducer: state.UserReducer,
    LoginUserIdReducer: state.LoginUserIdReducer,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    loading: (data: any) => dispatch(Loading(data)),
  };
};

const connectedNavBar = connect(mapStateToProps, mapDispatchToProps)(MyNFTPage);
export { connectedNavBar as MyNFTPage };
