import React from "react";
import { Modal } from "react-bootstrap";
import googlePlay from "../assets/img/google-play.png";
import appleStore from "../assets/img/apple-store.png";
import { Link } from "react-router-dom";
import { isMobile, osName, isIOS, isAndroid } from "react-device-detect";

interface Props {
  show?: any;
  handleClose?: any;
}
const MobileViewModal: React.FC<Props> = (props: Props) => {
  return (
    <Modal
      dialogClassName="sign-up-modal app-store"
      show={props.show}
      onHide={props.handleClose}
    >
      <Modal.Header closeButton>
        <Modal.Title></Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="auth-container">
          <div className=" auth-right-sec">
            <h4 className="pt-5 text-center">
              Please download our app to watch Livestream
            </h4>
            <div className="btn-app-store">
              {isMobile && isIOS && (
                <Link to="#">
                  <img src={appleStore} alt="Apple Store" />
                </Link>
              )}
              {isMobile && isAndroid && (
                <Link to="#">
                  <img src={googlePlay} alt="Google Play" />
                </Link>
              )}
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        {/* <button onClick={props.handleClose}>
              Close
            </button>
            <button onClick={props.handleClose}>
              Save Changes
            </button> */}
      </Modal.Footer>
    </Modal>
  );
};

export default MobileViewModal;
