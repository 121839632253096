import React, { Fragment, useRef, useEffect, useState } from "react";
import AppFooter from "../../components/footer";
import dummyImg from "../../assets/img/dummy-img.svg";
import { NavLink, useHistory, useParams } from "react-router-dom";
import { ClubsPost } from "../../app/model/clubs";
import moment from "moment";
import ScrollToTop from "../../components/scroll-to-top";
import PlaceholderImage from "../../assets/img/image.jpg";
import { User } from "../../app/model/user";
import { ClubService } from "../../app/service/club.service";
import { AppHeader } from "../../components/header";
import { toast } from "react-toastify";
import Slider from "react-slick";
// import io from "socket.io-client";
// import Peer from 'peerjs';
import LiveIcon from "../../assets/img/goLive1.svg";
import {
  BASE_URL,
  HOST,
  NMS_STREAM_PORT,
  STREAMING_HOST,
  WEB_STREAM_PORT,
  STREAMING_URL,
} from "./../../config/constant";
import { Modal } from "react-bootstrap";
import { LivestreamService } from "../../app/service/livestream";
import LiveImage from "../../assets/img/liveImage.png";
import img from "../../assets/img/slider-img.png";
import { reFreshChat } from "../../config/constant";
import VideoPlayer from "../../components/video";
// import Peer from "peerjs";
import { ReactFlvPlayer } from "react-flv-player";
import { TextField } from "@mui/material";
import OvenPlayer from "ovenplayer";
import Hls from "hls.js";

interface Props {
  UserReducerState: any;
}

export const LivestreamPage: React.FC<Props> = (props) => {
  const hls = new Hls({
    autoStartLoad: true,
    startPosition: -1,
    debug: false,
    // capLevelOnFPSDrop: false,
    // capLevelToPlayerSize: false,
    // defaultAudioCodec: undefined,
    initialLiveManifestSize: 1,
    maxBufferLength: 30,
    maxMaxBufferLength: 600,
    backBufferLength: Infinity,
    // maxBufferSize: 60  1000  1000,
    maxBufferHole: 0.5,
    highBufferWatchdogPeriod: 2,
    nudgeOffset: 0.1,
    nudgeMaxRetry: 3,
    maxFragLookUpTolerance: 0.25,
    liveSyncDurationCount: 3,
    liveMaxLatencyDurationCount: Infinity,
    liveDurationInfinity: false,
    enableWorker: true,
    enableSoftwareAES: true,
    manifestLoadingTimeOut: 40000,
    manifestLoadingMaxRetry: 4,
    manifestLoadingRetryDelay: 5000,
    manifestLoadingMaxRetryTimeout: 64000,
    startLevel: undefined,
    levelLoadingTimeOut: 20000,
    levelLoadingMaxRetry: 4,
    levelLoadingRetryDelay: 1000,
    levelLoadingMaxRetryTimeout: 64000,
    fragLoadingTimeOut: 20000,
    fragLoadingMaxRetry: 6,
    fragLoadingRetryDelay: 1000,
    fragLoadingMaxRetryTimeout: 64000,
    startFragPrefetch: false,
    testBandwidth: true,
    progressive: false,
    lowLatencyMode: true,
    fpsDroppedMonitoringPeriod: 5000,
    fpsDroppedMonitoringThreshold: 0.2,
    appendErrorMaxRetry: 5,
    // loader: customLoader,
    // fLoader: customFragmentLoader,
    // pLoader: customPlaylistLoader,
    // xhrSetup: XMLHttpRequestSetupCallback,
    // fetchSetup: FetchSetupCallback,
    // abrController: AbrController,
    // bufferController: BufferController,
    // capLevelController: CapLevelController,
    // fpsController: FPSController,
    // timelineController: TimelineController,
    // enableWebVTT: true,
    // enableIMSC1: true,
    // enableCEA708Captions: true,
    // stretchShortVideoTrack: false,
    // maxAudioFramesDrift: 1,
    // forceKeyFrameOnDiscontinuity: true,
    // abrEwmaFastLive: 3.0,
    // abrEwmaSlowLive: 9.0,
    // abrEwmaFastVoD: 3.0,
    // abrEwmaSlowVoD: 9.0,
    // abrEwmaDefaultEstimate: 500000,
    // abrBandWidthFactor: 0.95,
    // abrBandWidthUpFactor: 0.7,
    // abrMaxWithRealBitrate: false,
    // maxStarvationDelay: 4,
    // maxLoadingDelay: 4,
    // minAutoBitrate: 0,
    // emeEnabled: false,
    // widevineLicenseUrl: undefined,
    // licenseXhrSetup: undefined,
    // drmSystemOptions: {},
    // requestMediaKeySystemAccessFunc: requestMediaKeySystemAccess,
    cmcd: undefined,
  });
  // const peerConfig = {
  //   iceServers: [
  //     {
  //       urls: ["stun:stun.l.google.com:19302"],
  //     },
  //   ],
  // };
  // let peerConnection: any;

  let streamStartedLocal = false;
  // let peerConnectionId = "";
  let { id, club_id } = useParams<{ id: string; club_id: string }>();
  const [livestream, setLivestream] = useState<any[] | []>([]);
  const [clubOtherPosts, setClubOtherPosts] = useState<ClubsPost[] | []>([]);
  const [clubUser, setClubUser] = useState<User[] | []>([]);
  const [isCommentDisabled, setIsCommentDisabled] = useState(false);
  const [selectedComments, setSelectedComments] = useState(-1);
  const [updatedLivestream, setUpdatedLivestream] = useState<any[]>([]);

  const [subSubComments, setSubSubComments] = useState<any[]>([]);
  const [selectedSubComments, setSelectedSubComments] = useState(-1);
  let userId =
    sessionStorage.getItem("isLogin") || localStorage.getItem("isLogin") || "";
  const [postComments, setPostComments] = useState<any[]>([]);
  const [mainComments, setMainComments] = useState<string>();
  const loginUserImage = sessionStorage.getItem("loginUserImage");
  const commentText = React.useRef<HTMLTextAreaElement>(null);
  const [streamStarted, setStreamStarted] = useState(false);
  const [intervalRef, setIntervalRef] = useState<any | null>(null);

  const [likes, setLike] = useState<any[]>([]);

  /**
   * Live stream code here
   */
  // let socket: any = null;
  // const [peerId, setPeerId] = useState('');
  const [currentSocket, setCurrentSocket] = useState<any | null>(null);
  const videoPlayerRef = useRef<any | null>(null);
  const [showUserAlertPopup, setShowUserAlertPopup] = useState(false);
  const handleCloseUserAlertPopup = () => setShowUserAlertPopup(false);
  const handleShowUserAlertPopup = () => setShowUserAlertPopup(true);
  const [ticketNumber, setTicketNumber] = React.useState("");
  const [ticketNumberError, setTicketNumberError] = React.useState("");

  const [showTicketPopup, setShowTicketPopup] = useState(false);
  const handleCloseTicketPopup = () => setShowTicketPopup(false);
  const handleShowTicketPopup = () => setShowTicketPopup(true);
  const [isTicketVerified, setIsTicketVerified] = React.useState(false);
  const [ticketData, setTicketData] = useState<any[] | []>([]);

  const history = useHistory();
  const [videoOptions, setVideoOptions] = useState({
    sources: [
      {
        src: "",
        type: "",
      },
    ],
  });
  const chatContainer = React.useRef<HTMLDivElement>(null);

  useEffect((): any => {
    // commentText.current?.focus();
    getlivestream();
    getComments();
    console.log(
      "userId",
      userId,
      sessionStorage.getItem("isLogin"),
      localStorage.getItem("isLogin"),
      ""
    );
    sessionStorage.setItem("isLogin", userId);
    return () => {
      if (intervalRef) {
        clearTimeout(intervalRef);
      }
    };
    // createLiveStream();
  }, []);

  const initializeHLSPlayer = (currentStream: any) => {
    let videoURL = "";
    if (currentStream) {
      if (currentStream.stream_type === "rtmp") {
        videoURL = `${STREAMING_URL}/${currentStream?.collectionName}~${currentStream?._id}/playlist.m3u8`;
      } else {
        videoURL = `${STREAMING_URL}/${id}/playlist.m3u8`;
      }
    }

    // setTimeout(() => {
    hls.loadSource(videoURL);
    hls.attachMedia(videoPlayerRef.current);
    if (videoPlayerRef && videoPlayerRef.current) {
      videoPlayerRef.current.play();
    }
    hls.on(Hls.Events.ERROR, (eventName: any, data: any) => {
      // hls.loadSource(videoURL);
      // hls.attachMedia(videoPlayerRef.current);
      // Check if the stream is completed
      console.log("Stopped Playing", data);
      if (data.fatal) {
        switch (data.type) {
          case Hls.ErrorTypes.NETWORK_ERROR:
            // try to recover network error
            console.error("fatal network error encountered, try to recover");
            hls.startLoad();
            break;
          case Hls.ErrorTypes.MEDIA_ERROR:
            console.error("fatal media error encountered, try to recover");
            hls.recoverMediaError();
            setTimeout(() => {
              if (videoPlayerRef && videoPlayerRef.current) {
                videoPlayerRef.current.play();
              }
            }, 500);
            break;
        }
      } else {
        setTimeout(() => {
          if (videoPlayerRef && videoPlayerRef.current) {
            videoPlayerRef.current.play();
          }
        }, 100);
      }
      LivestreamService.getlivestreamById(id).then((res) => {
        if (res?.length > 0) {
          if (res[0].status === "COMPLETED") {
            setShowUserAlertPopup(true);
          } else if (
            res[0].status === "LIVE" &&
            data.type === Hls.ErrorTypes.NETWORK_ERROR
          ) {
            setTimeout(() => {
              hls.loadSource(videoURL);
              if (videoPlayerRef && videoPlayerRef.current) {
                videoPlayerRef.current.play();
              }
            }, 5000);
          }
        }
      });
    });
    // }, 500);

    // hls.on(Hls.Events.MANIFEST_PARSED, (eventName: any, data: any) => {
    //   // hls.loadSource(videoURL);
    //   // hls.attachMedia(videoPlayerRef.current);
    //   // Check if the stream is completed
    //   console.log("Loaded Playing");
    //   if(!streamStartedLocal){
    //     streamStartedLocal=(true);
    //     if (videoPlayerRef && videoPlayerRef.current) {
    //       videoPlayerRef.current.play();
    //     }
    //   }
    // });
  };

  const scrollToMyRef = () => {
    if (chatContainer.current) {
      const scroll =
        chatContainer.current.scrollHeight - chatContainer.current.clientHeight;
      chatContainer.current.scrollTo(0, scroll);
    }
  };

  const timer = () =>
    setIntervalRef(
      setTimeout(() => {
        getComments();
      }, reFreshChat)
    );

  const getComments = () => {
    LivestreamService.getLivestreamPrivate(id).then((res) => {
      if (res?.length > 0) {
        setPostComments([...res[0]?.comments]);
        setUpdatedLivestream([...res]);
        timer();
        scrollToMyRef();
        isCommentAvailable(res);
        setLike(res[0]?.likes);
        let videoURL = "";
        if (res[0]) {
          if (res[0].stream_type === "rtmp") {
            videoURL = `${STREAMING_URL}/${res[0]?.collectionName}~${res[0]?._id}/playlist.m3u8`;
          } else {
            videoURL = `${STREAMING_URL}/${id}/playlist.m3u8`;
          }
        }
      }
    });
  };

  const getlivestream = () => {
    // console.log("livestream 123", livestream);

    LivestreamService.getlivestreamById(id).then((res) => {
      if (res?.length > 0) {
        // console.log("res", res);

        setLivestream([...res]);
        setPostComments([...res[0]?.comments]);
        setClubUser([...res[0]?.user_info]);
        // createLiveStream();
        initializeHLSPlayer(res[0]);
        if (res[0]?.status === "LIVE") {
          const watchingData = {
            stream_id: res[0]?._id,
            collectionName: res[0]?.collectionName,
          };
          LivestreamService.updateWatchingCount(watchingData).then((res) => {});
          const ticketData = {
            user_id: userId,
            live_stream_id: res[0]?._id,
            collectionName: res[0]?.collectionName,
          };
          LivestreamService.getTicket(ticketData).then((res) => {
            setTicketData(res);
          });
          // handleShowTicketPopup();
        } else if (res[0]?.status === "COMPLETED") {
          // history.push("/dashboard/purchasedtickets/attended");
        }
      }
    });
  };

  const onShowTicketPopup = () => {
    setIsTicketVerified(true);
    handleShowTicketPopup();
  };

  // const createLiveStream = () => {
  //   const socket = io(`${BASE_URL}`);
  //   socket.on("connect", () => {
  //     socket.emit("peer-join-stream", { streamId: id });
  //     socket.emit("peer-accept-me", { streamId: id });
  //     socket.on("peer-streaming-ready", (data: any) => {
  //       preparePeerConnection(socket);
  //     });
  //     socket.on("livestream-ended", () => {
  //       setShowUserAlertPopup(true);
  //     });
  //   });
  // };

  // const preparePeerConnection = (socket: any) => {
  //   const peer = new Peer({
  //     host: HOST,
  //     // port: 7799,
  //     path: "/peerjs",
  //     secure: true,
  //     config: {
  //       iceServers: [
  //         { urls: "stun:stun01.sipphone.com" },
  //         { urls: "stun:stun.ekiga.net" },
  //         { urls: "stun:stun.fwdnet.net" },
  //         { urls: "stun:stun.ideasip.com" },
  //         { urls: "stun:stun.iptel.org" },
  //         { urls: "stun:stun.rixtelecom.se" },
  //         { urls: "stun:stun.schlund.de" },
  //         { urls: "stun:stun.l.google.com:19302" },
  //         { urls: "stun:stun1.l.google.com:19302" },
  //         { urls: "stun:stun2.l.google.com:19302" },
  //         { urls: "stun:stun3.l.google.com:19302" },
  //         { urls: "stun:stun4.l.google.com:19302" },
  //         { urls: "stun:stunserver.org" },
  //         { urls: "stun:stun.softjoys.com" },
  //         { urls: "stun:stun.voiparound.com" },
  //         { urls: "stun:stun.voipbuster.com" },
  //         { urls: "stun:stun.voipstunt.com" },
  //         { urls: "stun:stun.voxgratia.org" },
  //         { urls: "stun:stun.xten.com" },
  //       ],
  //     },
  //   });
  //   peer.on("open", (peerId: string) => {
  //     socket.emit("peer-call-me", { streamId: id, peerId });
  //   });
  //   peer.on("call", (call) => {
  //     call.answer();
  //     call.on("stream", (stream) => {
  //       // console.log("livestream", livestream);

  //       if (!isTicketVerified) {
  //         getlivestream();
  //       }
  //       if (videoPlayerRef && videoPlayerRef.current) {
  //         videoPlayerRef.current.srcObject = stream;
  //       } else {
  //         console.error("Player not ready");
  //       }
  //     });
  //   });
  // };

  // const createLiveStream1 = () => {
  //   const newSocket = io(`${BASE_URL}`);
  //   newSocket.on("connect", () => {
  //     const peer = new Peer();
  //     peer.on("open", (peerId: string) => {
  //       peerConnectionId = peerId;
  //       // setPeerId(peerId);
  //       /**
  //        * Socket
  //        */
  //       newSocket.emit("peer-join-stream", id, { peerId });
  //     });
  //     peer.on("call", (call) => {
  //       // Answer the call, providing our mediaStream
  //       call.answer();
  //       call.on("stream", (stream: any) => {
  //         if (videoPlayerRef && videoPlayerRef.current) {
  //           videoPlayerRef.current.srcObject = stream;
  //         } else {
  //           console.error("Player not ready");
  //         }
  //       });
  //     });
  //   });

  //   // newSocket.emit('join-stream', { streamId: id });
  //   newSocket.on("evntstart", (data) => {
  //     // newSocket.emit('join-stream', { streamId: id });
  //     newSocket.emit("peer-join-stream", id, { peerId: peerConnectionId });
  //   });

  //   newSocket.on("livestream-ended", (data) => {
  //     setShowUserAlertPopup(true);
  //   });

  //   window.onunload = window.onbeforeunload = () => {
  //     newSocket.close();
  //   };
  // };
  // const joinAStream = () => {
  // }

  useEffect(() => {
    document.body.classList.add("innerpage");
    // getSelectedClubPost(id, club_id);
    return function cleanup() {
      document.body.classList.remove("innerpage");
    };
  }, []);

  const commentsDays = (date: any) => {
    const now = moment();
    const expiration = moment(date);
    const diff = expiration.diff(now);
    const diffDuration = moment.duration(diff);
    return `${
      diffDuration.days()
        ? `${diffDuration.days().toString().replace("-", "")} days`
        : ""
    } ${
      diffDuration.hours()
        ? `${diffDuration.hours().toString().replace("-", "")} hours`
        : ""
    }  ${
      diffDuration.minutes()
        ? `${diffDuration.minutes().toString().replace("-", "")} minutes ago`
        : ""
    } `;
  };
  const onPostComment = (event: any) => {
    setMainComments(event.target.value);
    if (userId) {
      if (event.key === "Enter" && !event.shiftKey) {
        if (event.target.value) {
          const data = {
            collectionName: livestream[0]?.collectionName,
            _id: livestream[0]?._id,
            club_id: livestream[0]?.club_id,
            user_id: userId,
            comments: event.target.value,
          };
          LivestreamService.liveStreamcomments(data).then((res) => {
            if (res) {
              getComments();
              event.preventDefault();
              setMainComments("");
            }
          });
        }
      }
    } else {
      toast.error("Please Login");
    }
  };
  const onCloseLivestream = () => {
    if (intervalRef) {
      clearTimeout(intervalRef);
    }
    history.push("/livestream-listing");
  };
  const onChangeTicketNumber = (event: any) => {
    setTicketNumberError("");
    if (event.target.value) {
      setTicketNumber(event.target.value.toLowerCase());
    } else {
      setTicketNumber("");
      setTicketNumberError("Please enter your ticket number");
    }
  };
  const onSendTicket = () => {
    if (ticketNumber) {
      const data = {
        user_id: userId,
        live_stream_id: livestream[0]?._id,
        ticket_number: ticketNumber.toLowerCase(),
        collectionName: livestream[0]?.collectionName,
      };
      LivestreamService.verifyTicket(data).then((res) => {
        // console.log('res', res);

        if (res?.code === 400) {
          // toast.error(res.message);
          setTicketNumberError(res.message);
        } else {
          // console.log('res', res, data);
          setIsTicketVerified(res);
          handleCloseTicketPopup();
          if (videoPlayerRef && videoPlayerRef.current) {
            videoPlayerRef.current.play();
          }
        }
      });
    } else {
      setTicketNumberError("Please enter your ticket number");
    }
  };

  const handleVideoplayerError = (event: any) => {
    console.log("videplayer error ", event);
  };
  const el = useRef(null);

  const onLike = (data: any) => {
    const newData = {
      user_id: userId,
      stream_id: data?._id,
      collectionName: data?.collectionName,
    };
    console.log(newData);
    LivestreamService.updateLsLikes(newData).then((res) => {
      getComments();
    });
  };

  const isCommentAvailable = (res: any) => {
    const isExist = res[0]?.blocked_users.find(
      (data: any) => data?.user_id === userId
    );
    if (isExist) {
      setIsCommentDisabled(true);
    } else {
      setIsCommentDisabled(false);
    }
  };

  const onBack = () => {
    // 👇️ replace set to true
    history.push("/#", { replace: true });
  };
  return (
    <div>
      <AppHeader />
      <ScrollToTop />
      <main className="main-content-sec static-page">
        {/* <div id="ome_player" style={{width: '300px', height: '300px', background:'black'}}></div> */}
        

          <div className="container ">
          <div className="main-content-back2">
          {/* <div className="btn-back">
            <span className="pointer" onClick={onBack}>
              <i className="bi bi-arrow-left"></i>
            </span>
          </div> */}
            <div className="row post-detail">
              <article className="col-lg-8">
                <div className="post-card" style={{ position: "relative" }}>
                  {/* {(livestream[0]?.stream_type == 'rtmp') ?
                    <ReactFlvPlayer
                      url={`${BASE_URL}:${RTMP_PORT}/live/${livestream[0]?.collectionName}~${livestream[0]?._id}.flv`}
                      heigh="800px"
                      width="800px"
                      isMuted={true}
                    /> : <video className="mw-100 w-100" controls ref={videoPlayerRef}></video>
                  } */}
                  {/* <div id="ome_player" style={{ width: '300px', height: '300px', background: 'black' }}></div> */}
                  {/* onError={(event) => handleVideoplayerError(event)} */}
                  <video
                    className="mw-100 w-100"
                    controls
                    ref={videoPlayerRef}
                    style={{ height: "500px" }}
                  ></video>
                  <div className="post-card__img text-center">
                    {/* <img src={LiveImage} alt="Live" style={{
                      width: '50px', position: 'absolute', top: '20px',
                      right: '20px', zIndex: 2
                    }} /> */}
                    {livestream[0]?.status === "LIVE" && (
                      <span
                        style={{
                          color: "red",
                          fontSize: "20px",
                          fontWeight: "bold",
                          position: "absolute",
                          top: "10px",
                          right: "10px",
                          zIndex: 2,
                        }}
                      >
                        <img style={{maxWidth:"121px",left:0}} src={LiveIcon} alt="live icon" />
                      </span>
                    )}
                    {/* <VideoPlayer options={videoOptions} /> */}
                    {/* {livestream[0]?.status === 'LIVE' && <video className="mw-100 w-100" controls ref={videoPlayerRef}>
                      Your browser does not support HTML video.
                    </video>} */}
                    {/* {livestream[0]?.status === 'SCHEDULED' || livestream[0]?.status === 'COMPLETED' ? <video muted={false} className="mw-100 w-100" poster={livestream[0]?.video_url} controls ref={videoPlayerRef} controlsList="nodownload">
                      <source src={livestream[0]?.video_url} type="video/mp4" />
                      Your browser does not support HTML video.
                    </video> : livestream[0]?.status === 'LIVE' ? <video muted={false} className="mw-100 w-100" controls ref={videoPlayerRef}>
                      Your browser does not support HTML video.
                    </video> : <img src={img} alt={livestream[0]?.title} />
                    } */}
                    {livestream[0]?.type === "3" && (
                      <div className="text-post">{livestream[0]?.content}</div>
                    )}
                  </div>
                  <div className="post-card__body">
                    <h3 className="post-card__title">{livestream[0]?.title}</h3>
                    <p className="post-card__desc">
                      {livestream[0]?.description}
                    </p>
                  </div>
                  <div className="post-card__footer">
                    <ul className="post-card__footer-left">
                      <li>
                        <i
                          onClick={() => onLike(livestream[0])}
                          className={`bi bi-heart pointer ${
                            livestream[0]?.isLikes ? "clr-red" : ""
                          } `}
                        ></i>
                        {likes?.length
                          ? likes?.length
                          : 0}
                      </li>
                      <li>
                        <i className="bi bi-chat"></i>
                        {postComments?.length ? postComments?.length : 0}
                      </li>
                    </ul>
                    <div className="post-card__footer-right">
                      <small className="post-card__postedInfo">
                        {moment(livestream[0]?.published_dt).format("lll")} IST
                      </small>
                    </div>
                  </div>
                  <div className="text-center">
                    {livestream[0]?.status === "SCHEDULED" && (
                      <h5 style={{ color: "red" }}>
                        Host has not yet started the live session
                      </h5>
                    )}
                    {livestream[0]?.status === "COMPLETED" && (
                      <h5>Livestream completed.</h5>
                    )}
                    {/* <button onClick={handleShowUserAlertPopup}>popup</button> */}
                  </div>
                </div>
              </article>
              <aside className="col-lg-4">
                <div className="comment-container ratio ratio-16x9">
                  <div
                    className="w-100"
                    style={{
                      background: "white",
                      height: "100%",
                      padding: "10px 10px 0",
                      borderRadius: "8px",
                    }}
                  >
                    {updatedLivestream[0]?.isComments && (
                      <div ref={chatContainer} className="chat">
                        {postComments.map((comment, index) => {
                          return (
                            <div key={index} className="commented-info">
                              <div className="commented-info-left">
                                <img
                                  src={
                                    comment?.user_info[0]?.image
                                      ? comment?.user_info[0]?.image
                                      : dummyImg
                                  }
                                  alt="dummy image"
                                />
                              </div>
                              <div className="commented-info-right">
                                <div className="comment">
                                  <div className="comment-name">
                                    <a>{comment?.user_info[0]?.name}</a>
                                    <span className="comment-date">
                                      {commentsDays(comment?.created_at)}
                                    </span>
                                  </div>
                                  <p className="comment-desc">
                                    {comment?.comments}
                                  </p>
                                  {selectedComments === index && (
                                    <div>
                                      {comment?.replies?.length > 0 ? (
                                        comment?.replies?.map(
                                          (
                                            subComment: any,
                                            subIndex: number
                                          ) => {
                                            return (
                                              <div
                                                key={subIndex}
                                                className="commented-info"
                                              >
                                                <div className="commented-info-left">
                                                  <img
                                                    src={
                                                      subComment?.user_info[0]
                                                        ?.image
                                                        ? subComment
                                                            ?.user_info[0]
                                                            ?.image
                                                        : dummyImg
                                                    }
                                                    alt="dummy image"
                                                  />
                                                </div>
                                                <div className="commented-info-right">
                                                  <div className="comment">
                                                    <div className="comment-name">
                                                      <a>
                                                        {
                                                          subComment
                                                            ?.user_info[0]?.name
                                                        }
                                                      </a>
                                                      <span className="comment-date">
                                                        {commentsDays(
                                                          subComment?.created_at
                                                        )}
                                                      </span>
                                                    </div>
                                                    <p className="comment-desc">
                                                      {subComment?.comments}
                                                    </p>
                                                    {selectedSubComments ===
                                                      subIndex &&
                                                      subComment.replies.map(
                                                        (
                                                          subSubComment: any,
                                                          subSubIndex: number
                                                        ) => {
                                                          return (
                                                            <div
                                                              key={subIndex}
                                                              className="commented-info"
                                                            >
                                                              <div className="commented-info-left">
                                                                <img
                                                                  src={
                                                                    subComment
                                                                      ?.user_info[0]
                                                                      ?.image
                                                                      ? subComment
                                                                          ?.user_info[0]
                                                                          ?.image
                                                                      : dummyImg
                                                                  }
                                                                  alt="dummy image"
                                                                />
                                                              </div>
                                                              <div className="commented-info-right">
                                                                <div className="comment">
                                                                  <div className="comment-name">
                                                                    {" "}
                                                                    <a href="">
                                                                      {
                                                                        subSubComment?.name
                                                                      }
                                                                    </a>
                                                                    <span className="comment-date">
                                                                      2 days ago
                                                                    </span>
                                                                  </div>
                                                                  <p className="comment-desc">
                                                                    {
                                                                      subSubComment?.message
                                                                    }
                                                                  </p>
                                                                  <ul className="comment-count-info">
                                                                    <li>
                                                                      <i className="bi bi-heart pointer"></i>
                                                                      {
                                                                        subSubComment?.likes
                                                                      }
                                                                    </li>
                                                                    <li>
                                                                      <i className="bi bi-chat pointer"></i>
                                                                      {subSubComment
                                                                        ?.comments
                                                                        ?.length
                                                                        ? subSubComment
                                                                            ?.comments
                                                                            ?.length
                                                                        : 0}
                                                                    </li>
                                                                  </ul>
                                                                  {subSubIndex ===
                                                                    subSubComments.length -
                                                                      1 && (
                                                                    <input
                                                                      type="text"
                                                                      className="form-control"
                                                                      placeholder="Replay"
                                                                    />
                                                                  )}
                                                                </div>
                                                              </div>
                                                            </div>
                                                          );
                                                        }
                                                      )}
                                                  </div>
                                                </div>
                                              </div>
                                            );
                                          }
                                        )
                                      ) : (
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder="Replay"
                                          defaultValue={mainComments}
                                          disabled={isCommentDisabled}
                                          // onKeyPress={(event) => onPostCommentOneReplay(event, comment)}
                                        />
                                      )}
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    )}
                    {!updatedLivestream[0]?.isComments && (
                      <p>Comments hidden</p>
                    )}
                  </div>
                  {updatedLivestream[0]?.isComments && (
                    <div className="comment-sec">
                      <div className="row mb-2">
                        <div className="col-auto d-flex flex-wrap">
                          <span className="comment-count">
                            {postComments.length} comments
                          </span>
                        </div>
                        <div className="ms-auto col-auto">
                          <button
                            className="btn btn-outline-black"
                            onClick={() => getComments()}
                          >
                            <i className="bi bi-arrow-repeat"></i>
                          </button>
                        </div>
                      </div>
                      <div className="comment-box">
                        <div className="comment-box-photo">
                          <img
                            src={loginUserImage ? loginUserImage : dummyImg}
                            alt="dummy image"
                          />
                        </div>
                        <div className="comment-add">
                          {!isCommentDisabled && (
                            <textarea
                              ref={commentText}
                              className="form-control"
                              placeholder="Add Comments"
                              onKeyPress={(event) => onPostComment(event)}
                              onChange={(event) => onPostComment(event)}
                              value={mainComments}
                            />
                          )}
                          {isCommentDisabled && (
                            <input
                              type={"text"}
                              className="form-control"
                              placeholder="Add Comments"
                              onKeyPress={(event) => onPostComment(event)}
                              onChange={(event) => onPostComment(event)}
                              value={mainComments}
                              readOnly
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </aside>
            </div>
          </div>
        </div>
      </main>
      <AppFooter></AppFooter>
      <Modal
        show={showUserAlertPopup}
        onHide={handleCloseUserAlertPopup}
        centered
        dialogClassName="sign-up-modal login-modal"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="auth-container">
            <div className="auth-sec auth-right-sec">
              <div className="auth-info">
                <h2>Info</h2>
              </div>
              <div>Livestream ended.</div>
              <button
                className="btn btn-black w-100 mt-5"
                onClick={onCloseLivestream}
              >
                OK
              </button>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>

      <Modal
        show={showTicketPopup}
        centered
        backdrop="static"
        keyboard={false}
        dialogClassName="sign-up-modal login-modal"
      >
        {/* <Modal.Header closeButton></Modal.Header> */}
        <Modal.Body>
          <div className="auth-container">
            <div className="auth-sec auth-right-sec">
              <div className="auth-info">
                <h4>Enter your ticket number</h4>
                {/* <p>Your Ticket Number: {ticketData[0]?.ticket_number}</p>*/}
              </div>
              <div>
                <TextField
                  fullWidth
                  id="outlined-basic"
                  label="Ticket Number"
                  variant="outlined"
                  value={ticketNumber}
                  onChange={(event) => onChangeTicketNumber(event)}
                />
                {ticketNumberError && (
                  <p style={{ color: "red" }}>{ticketNumberError}</p>
                )}
              </div>
            </div>
          </div>
          <div className="col-md-12 mt-3 text-center mb-3">
            <button
              className="btn btn-outline-black mx-auto d-inline-flex btn-wide"
              onClick={onSendTicket}
            >
              Submit
            </button>
          </div>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </div>
  );
};
