import React, { useEffect, useState, useRef } from "react";
import AppFooter from "../../components/footer";
import { useHistory, useParams } from "react-router-dom";
import { AppHeader } from "../../components/header";
import { LivestreamUploader } from "../../components/livestream-uploader";
import { LivestreamService } from "../../app/service/livestream";
import { Modal } from "react-bootstrap";
import { LivestreamComments } from "../../components/livestream-comments";
import moment from "moment";
import { STREAMING_HOST } from "../../config/constant";
import copyIcon from "../../assets/img/copy-icon.png"

interface Props {
  UserReducerState: any;
}

export const GoLivestreamPage: React.FC<Props> = (props) => {
  let { id, club_id } = useParams<{ id: string; club_id: string }>();
  const [showUserAlertPopup, setShowUserAlertPopup] = useState(false);
  const handleCloseUserAlertPopup = () => setShowUserAlertPopup(false);
  const handleShowUserAlertPopup = () => setShowUserAlertPopup(true);
  const [goLiveLiveStream, setGoLiveLiveStream] = useState<any[] | []>([]);
  const [selectedLivestreamComments, setSelectedLivestreamComments] = useState<any[] | []>([]);
  const thiredPartyData = localStorage.getItem('thiredPartyData') || '';
  const thiredPartyDataObj = thiredPartyData ? JSON.parse(thiredPartyData) : {};
  const history = useHistory();
  const [isStopedLiveStream, setIsStopedLiveStream] = useState(false);
  const [isCommentsShow, setIsCommentsShow] = useState<boolean>(false);

  const messageEl = useRef(null);
  useEffect(() => {
    LivestreamService.getlivestreamById(id).then((res) => {
      if (res) {
        console.log("res", res);

        setGoLiveLiveStream([...res]);
        console.log(goLiveLiveStream, 'goLiveLiveStream');
        
        sessionStorage.setItem('collectionName', res[0]?.collectionName);
        LivestreamService.goLiveStream(res[0]).then((goLiveRes) => {
          if (goLiveRes) {
          }
        });
      }
    });
    // if(messageEl) {
    //   messageEl.current.addEventListener('DOMNodeInserted', (event:any) => {
    //     const { currentTarget: target } = event;
    //     target.scroll({ top: target.scrollHeight, behavior: 'smooth' });
    //   });
    // }
  }, []);

  const onHideComments = (e: any) => {
    setIsCommentsShow(e.target.checked);
    console.log(e.target.checked);
    const data = {
      isComments: e.target.checked ? true : false,
      stream_id: id,
      collectionName: goLiveLiveStream[0]?.collectionName,
    };
    LivestreamService.updateLsShowOrHide(data).then((res) => { });
  };

  const endLivestream = () => {
    LivestreamService.getlivestreamById(id).then((livestream) => {
      if (livestream) {
        LivestreamService.completeLiveStream(livestream[0]).then(
          async (res) => {
            if (res) {
              // history.push("/dashboard/mylivestreams/completed");
              await window.close(); //window.location.reload();
            }
          }
        );
      }
    });
    setIsStopedLiveStream(true);
    handleShowUserAlertPopup();
  };
  const [expanded, setExpanded] = useState(false);
  const handleChatExpand = () => {
    setExpanded(!expanded);
  };

  const getLivestreamByComments = (data: any) => {
    setSelectedLivestreamComments([...data])
  }
  return (
    <>
      <section className="livestream-streaming">
        <div
          className={
            expanded
              ? "livestream-streaming-video fullWidth"
              : " livestream-streaming-video"
          }
        >
          {thiredPartyData ? <div className="row">
            {/* <div>
              <strong>Server:</strong> {thiredPartyDataObj?.server}
            </div>
            <div>
              <strong>Stream Key:</strong> {thiredPartyDataObj?.stream_key}
            </div> */}
            <div className="mt-2">
                        <label>Server</label>{" "}
                        {/* <i
                          className="bi bi-clipboard ms-2 pointer"
                          title="Copy to clipboard"
                          onClick={() => {
                            navigator.clipboard.writeText(
                              `${thiredPartyDataObj?.server}`
                            );
                          }}
                        ></i> */}
                         <img className ="copy-icon"
                          src={copyIcon} alt="copy-icon"
                          title="Copy to clipboard"
                          onClick={() => {
                            navigator.clipboard.writeText(
                              `${thiredPartyDataObj?.server}`
                            );
                          }}
                          />
                        <input
                          type="text"
                          className="form-control mt-2"
                          readOnly
                          value={`${thiredPartyDataObj?.server}`}
                        />
                      </div>
                      <div className="mt-2">
                        <label>Stream Key</label>{" "}
                        {/* <i
                          className="bi bi-clipboard ms-2 pointer"
                          title="Copy to clipboard"
                          onClick={() => {
                            navigator.clipboard.writeText(
                              `${thiredPartyDataObj?.stream_key}`
                            );
                          }}
                        ></i> */}
                        <img className="copy-icon second"
                          src={copyIcon} alt="copy-icon"
                          title="Copy to clipboard"
                          onClick={() => {
                            navigator.clipboard.writeText(
                              `${thiredPartyDataObj?.stream_key}`
                            );
                          }}
                          />
                        <input
                          type="text"
                          className="form-control mt-2"
                          readOnly
                          value={`${thiredPartyDataObj?.stream_key}`}
                        />
                      </div>
            <div className="comment-action-right col-md-3" style={{marginTop:'40%'}}>
            <label className="mt-3 ps-0">Comments</label>
            <div className="mt-2 mb-3 ms-2">
              <label
                className={isCommentsShow ? "switch switch-active" : "switch"}
              >
                <input
                  type="checkbox"
                  onChange={(e) => onHideComments(e)}
                  checked={isCommentsShow}
                />
                <span className="slider round"></span>
                <span className="comment-label">
                  {isCommentsShow ? "Hide" : "Show"}
                </span>
              </label>
            </div>
          </div>
          </div>
           : <LivestreamUploader
            streamId={id}
            club_id={club_id}
            onEndStreming={endLivestream}
          ></LivestreamUploader>
          }
        </div>

        <div
          className={
            expanded
              ? "livestream-streaming-comments hidden"
              : "livestream-streaming-comments"
          }
        >
          {!thiredPartyData && <i
            style={{ cursor: "pointer" }}
            onClick={handleChatExpand}
            className={
              expanded
                ? "icon btn-active bi bi-caret-left"
                : "icon bi bi-caret-right"
            }
          ></i>}
          <LivestreamComments
            ref={messageEl}
            streamId={id}
            club_id={club_id}
            stopedLiveStream={isStopedLiveStream}
            onEndStreming={endLivestream}
           
          ></LivestreamComments>
        </div>
        <div className="col-md-9 order-first order-md-last mb-3 mb-mb-0">
          <h3 className="livestream-streaming-title">
            {goLiveLiveStream[0]?.title ? goLiveLiveStream[0]?.title : ""}
          </h3>
          {/* <div className="livestream-streaming-info">
            <div className="d-flex w-100">
              <span className="me-auto">
                <span className="livestream-streaming-users me-3">
                  <i className="bi bi-eye-fill me-1"></i>{selectedLivestreamComments[0]?.watching_count} watching now
                </span>
                <span className="livestream-streaming-time">
                  <i className="bi bi-camera-reels-fill me-1"></i>Started at{" "}
                  {moment(goLiveLiveStream[0]?.schedule_From)
                    .utc()
                    .format("lll")}{" "}
                  IST
                </span>
              </span>
              <span className="ms-auto">
                <span className="livestream-streaming-likes ">
                  <i className="bi bi-hand-thumbs-up me-1"></i>
                  1K likes
                </span>
                <span className="livestream-streaming-share ms-3">
                  <i className="bi bi-share me-1"></i>URL
                </span>
              </span>
            </div>
          </div> */}
        </div>
      </section>

      <Modal
        show={showUserAlertPopup}
        onHide={handleCloseUserAlertPopup}
        centered
        dialogClassName="sign-up-modal login-modal"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="auth-container">
            <div className="auth-sec auth-right-sec">
              <div className="auth-info">
                <h2>Info</h2>
              </div>
              <div>Your livestream completed successfully.</div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </>
  );
};
