import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useHistory, useParams } from "react-router";
import { NavLink } from "react-router-dom";
import { toast } from "react-toastify";
import {
  Perks,
  SubscriptionPackages,
} from "../../../app/model/subscription-packages";
import { SubscriptionPackagesService } from "../../../app/service/subscription-packages-service";
import FormBuilder from "../../../components/form-builder";
import {
  FormControlError,
  FormField,
  FormValidators,
} from "../../../components/form-builder/model/form-field";
import {
  FormValidator,
  GetControlIsValid,
} from "../../../components/form-builder/validations";

export const MyClubPackagePage = () => {
  let { id } = useParams<{ id: string }>();
  const [subscriptionPackageData, setPackage] = useState<any>({});
  const [subscriptionPackage, setSubscriptionPackage] =
    useState<SubscriptionPackages>();
  const [isLoading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [perks, setPerk] = useState<Perks[]>([]);
  const history = useHistory();
  const [enteredPerk, setEnteredPerk] = useState("");
  const [showPerksList, setShowPerksList] = useState(false);
  const handleClosePerksList = () => setShowPerksList(false);
  let perksList = [
    { name: "Perk 1", isSelected: false },
    { name: "Perk 2", isSelected: false },
    { name: "Perk 3", isSelected: false },
    { name: "Perk 4", isSelected: false },
    { name: "Perk 5", isSelected: false },
  ];
  const [selectedPerks, setSelectedPerks] = useState(perksList);

  let clubId = sessionStorage.getItem("clubId") || "";
  useEffect(() => {
    if (id) {
      getSubscriptionPackage(id);
    }
  }, [id]);

  const getSubscriptionPackage = (id: string) => {
    SubscriptionPackagesService.getClubSubscriptionPackages(clubId).then(
      (res) => {
        if (res) {
          const selectdPakage = res.find((element) => {
            if (element._id === id) {
              return element;
            }
          });
          if (selectdPakage) {
            setSubscriptionPackage(selectdPakage);
            if (selectdPakage.perks.length > 0) {
              selectdPakage.perks.forEach((element: any) => {
                perks.push(element);
                setPerk([...perks]);
              });
            }
          }
        }
      }
    );
  };
  const updateFormData = (data: any) => {
    setPackage(data);
    const errors: FormControlError[] = FormValidator(
      formValidations,
      data.value
    );
    setValidationErrors(errors);
  };

  const onSaveMyClub = () => {
    // const data = subscriptionPackageData.value;
    const data = { ...subscriptionPackageData.value };
    data.club_id = sessionStorage.getItem("clubId");
    data._id = id;
    data.name = data.name
      ? data.name
      : subscriptionPackage?.name
      ? subscriptionPackage?.name
      : "";
    data.price_monthly = data.price_monthly
      ? data.price_monthly
      : subscriptionPackage?.price_monthly
      ? subscriptionPackage?.price_monthly
      : "";
    data.price_yearly = data.price_yearly
      ? data.price_yearly
      : subscriptionPackage?.price_yearly
      ? subscriptionPackage?.price_yearly
      : "";
    data.description = data.description
      ? data.description
      : subscriptionPackage?.description
      ? subscriptionPackage?.description
      : "";
    data.code = subscriptionPackage?.code;
    data.perks = [];
    perks.forEach((element) => {
      data.perks.push(element);
    });
    setIsFormSubmitted(true);
    const errors: FormControlError[] = FormValidator(formValidations, data);
    setValidationErrors(errors);
    setLoading(true);
    if (errors.length < 1) {
      SubscriptionPackagesService.createNewSubscriptionPackage(data).then(
        (res) => {
          setIsFormSubmitted(false);
          if (res) {
            const perks: any = [];
            setPerk([...perks]);
            getSubscriptionPackage(id);
            setLoading(false);
            toast.success("Club Package Updated successfully");
            history.push("/dashboard/myclubs/packages");
          } else {
            setLoading(false);
            toast.error(res.message);
          }
        }
      );
    } else {
      setLoading(false);
    }
  };

  const handleInputChange = (e: any) => {
    setEnteredPerk(e.target.value);
    // const { name, value } = e.target;
    // const list: any = [...perks];
    // // list[index][name] = value;
    // list.push({ name: value });
    // setPerk(list);
  };

  const onAddPerk = () => {
    // setPerk([...perks, { perkName: '' }]);
    const list: any = [...perks];
    if (enteredPerk) {
      // list[index][name] = value;
      list.push({
        value: enteredPerk,
        package_code: subscriptionPackage?.code,
      });
      setPerk(list);
      setEnteredPerk("");
    }
  };
  const onDeletePerk = (index: number) => {
    const list = [...perks];
    list.splice(index, 1);
    setPerk(list);
  };
  const onAddSelectedPerks = () => {
    const perksList = [...perks];
    selectedPerks.forEach((element: any) => {
      if (element.isSelected) {
        perksList.push({
          package_code: subscriptionPackage?.code || "",
          value: element?.name || "",
        });
        setPerk(perksList);
      }
    });
    handleClosePerksList();
  };
  const onSelectPerk = (event: any, perk: string, index: number) => {
    const selectedPerk: any = selectedPerks;
    selectedPerk[index].isSelected = event.target.checked;
    // const isExit = selectedPerk.find((perkName: string) => perkName === perk);
    // if (event.target.checked) {
    //     if (!isExit) {
    //         selectedPerk.push(perk);
    //         setSelectedPerks([...selectedPerk]);
    //     }
    // } else {
    //     selectedPerk.splice(index, 1);
    //     setSelectedPerks([...selectedPerk]);
    // }
    perksList = [...selectedPerk];
    setSelectedPerks([...selectedPerk]);
  };
  const onPublish = () => {
    const data = { ...subscriptionPackageData.value };
    data.perks = [];
    perks.forEach((element: any) => {
      return data.perks.push({
        package_code: element?.package_code || "",
        value: element?.value || "",
      });
    });
    const clubPackageData: any = {
      club_id: sessionStorage.getItem("clubId") || "",
      _id: id,
      name: data?.name
        ? data.name
        : subscriptionPackage?.name
        ? subscriptionPackage?.name
        : "",
      price_monthly: data?.price_monthly
        ? data.price_monthly
        : subscriptionPackage?.price_monthly
        ? subscriptionPackage?.price_monthly
        : "",
      price_yearly: data?.price_yearly
        ? data.price_yearly
        : subscriptionPackage?.price_yearly
        ? subscriptionPackage?.price_yearly
        : "",
      description: data?.description
        ? data.description
        : subscriptionPackage?.description
        ? subscriptionPackage?.description
        : "",
      code: subscriptionPackage?.code,
      perks: data.perks,
    };
    setIsFormSubmitted(true);
    const errors: FormControlError[] = FormValidator(
      formValidations,
      clubPackageData
    );
    setValidationErrors(errors);

    if (
      errors.length < 1 ||
      !(
        clubPackageData.price_monthly !== 0 &&
        clubPackageData.price_yearly !== 0
      )
    ) {
      SubscriptionPackagesService.publishSubscriptionPackage(
        clubPackageData
      ).then((res) => {
        setIsFormSubmitted(false);
        if (res) {
          getSubscriptionPackage(id);
          setLoading(false);
          toast.success("Club Package Published successfully");
          history.push("/dashboard/creations");
        } else {
          setLoading(false);
          toast.error(res.message);
        }
      });
    }
  };

  const [validationErrors, setValidationErrors] = useState<FormControlError[]>(
    []
  );
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const formValidations = [
    new FormField("price_monthly", [FormValidators.REQUIRED]),
    new FormField("price_monthly", [FormValidators.REQUIRED]),
    new FormField("price_yearly", [FormValidators.REQUIRED]),
    // new FormField('name', [FormValidators.REQUIRED]),
  ];

  const getInputValid = (control: string) => {
    const value = GetControlIsValid(validationErrors, control);
    return value;
  };
  return (
    <div className="edit-package active" id="edit-package-1">
      <FormBuilder onUpdate={updateFormData}>
        <form>
          <div className="row">
            <div className="col-md-4">
              <div className="form-floating mb-3">
                <input
                  className="form-control"
                  disabled={!subscriptionPackage?.isEdit}
                  id="packageName"
                  type="text"
                  placeholder="Name"
                  name="name"
                  defaultValue={subscriptionPackage?.name}
                />
                {/* <label htmlFor="packageName">Package Name</label> */}
                {isFormSubmitted && !getInputValid("name") ? (
                  <label className="clr-red" htmlFor="packageName">
                    Enter Package Name
                  </label>
                ) : (
                  <label htmlFor="packageName">Package Name</label>
                )}
              </div>
            </div>
            <div className="col-md-4 mb-3">
              <div className="form-floating">
                <input
                  className="form-control"
                  id="packagePrice"
                  type="text"
                  placeholder="Set your bundle's monthly price"
                  name="price_monthly"
                  defaultValue={subscriptionPackage?.price_monthly}
                />
                {isFormSubmitted && !getInputValid("price_monthly") ? (
                  <label className="clr-red" htmlFor="packagePrice">
                    Enter Package Price Monthly
                  </label>
                ) : (
                  <label htmlFor="packagePrice">
                    Package Price Monthly{" "}
                    <span className="form-text">(INR)</span>
                  </label>
                )}
              </div>
            </div>
            <div className="col-md-4 mb-3">
              <div className="form-floating">
                <input
                  className="form-control"
                  id="packagePriceYearly"
                  type="text"
                  placeholder="Set your bundle's yearly price"
                  name="price_yearly"
                  defaultValue={subscriptionPackage?.price_yearly}
                />
                {isFormSubmitted && !getInputValid("price_yearly") ? (
                  <label className="clr-red" htmlFor="packagePriceYearly">
                    Enter Package Price Yearly
                  </label>
                ) : (
                  <label htmlFor="packagePriceYearly">
                    Package Price Yearly{" "}
                    <span className="form-text">(INR) (optional)</span>
                  </label>
                )}
              </div>
            </div>
          </div>
          <div className="form-floating mb-3">
            <textarea
              className="form-control"
              id="packageDesc"
              placeholder="Tell something about this Bundle"
              style={{ height: "100px" }}
              name="description"
              defaultValue={subscriptionPackage?.description}
            ></textarea>
            <label htmlFor="packageDesc">
              Package Description <span className="form-text">(optional)</span>
            </label>
          </div>
          <div className="row mb-3">
            <div className="col">
              {/* {perks.map((perk, index) => {
                                return  */}
              {/* <div key={index} className={`form-floating ${index >= 1 ? 'mt-3' : ''}`}> */}
              <div className={`form-floating`}>
                <input
                  className="form-control"
                  id="perks"
                  type="text"
                  placeholder="List the perks for fans"
                  name="perkName"
                  onChange={(e) => handleInputChange(e)}
                  value={enteredPerk}
                />
                <label htmlFor="perks">Deliverables</label>
                {/* <div >
                                        <button onClick={() => onDeletePerk(index)} className="btn btn-link-orange no-bdr no-text-desktop" type="button" data-bs-toggle="tooltip" data-bs-placement="top" title="Delete">
                                            <i className="bi bi-trash"></i><span className="d-inline-flex d-lg-none">Delete</span></button>
                                    </div> */}
              </div>

              {/* // })} */}
              <span className="form-text">
                Below are list of deliverables of this package
              </span>
              {/* <a className="link link-primary pointer" onClick={() => setShowPerksList(true)}>Add from a pre-defined list of Perks</a> */}
            </div>
            <div className="col-auto">
              <button
                type="button"
                className="btn btn-outline-black mt-2"
                onClick={onAddPerk}
              >
                Add
              </button>
            </div>
            {perks.length > 0 && (
              <ol className="mx-3">
                {perks.map((perk: any, index) => {
                  return (
                    <li key={index}>
                      {perk?.value}
                      {subscriptionPackage?.code === perk?.package_code && (
                        <button
                          onClick={() => onDeletePerk(index)}
                          className="btn btn-link-orange d-inline"
                          type="button"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title="Delete"
                        >
                          <i className="bi bi-trash"></i>
                          <span className="d-inline-flex d-lg-none">
                            Delete
                          </span>
                        </button>
                      )}
                    </li>
                  );
                })}
              </ol>
            )}
          </div>
        </form>
      </FormBuilder>
      <div className="row mt-5">
        <div className="col-auto d-flex flex-wrap">
          <NavLink
            className="btn btn-outline-orange me-2"
            to="/dashboard/myclubs/packages"
          >
            Cancel
          </NavLink>
          <button
            disabled={isLoading && !errorMsg}
            type="submit"
            className="btn btn-outline-black"
            onClick={onSaveMyClub}
          >
            {!isLoading || errorMsg ? (
              "Save"
            ) : (
              <div
                className="spinner-border text-primary spinner-border-sm"
                role="status"
              >
                <span className="sr-only">Loading...</span>
              </div>
            )}
          </button>
        </div>
        <div className="ms-auto col-auto">
          <button className="btn btn-primary" onClick={onPublish}>
            Publish
          </button>
        </div>
      </div>
      <Modal
        show={showPerksList}
        onHide={handleClosePerksList}
        centered
        dialogClassName="sign-up-modal login-modal"
        className=""
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="auth-container">
            <div className="auth-sec auth-right-sec">
              <div className="auth-info">
                <h2>Perks</h2>
              </div>
              {perksList.map((perk: any, index: number) => {
                return (
                  <div>
                    <div className="custom-checkbox no-bdr d-inline-flex">
                      <input
                        id={perk?.name}
                        type="checkbox"
                        checked={perk?.isSekected}
                        name={perk?.name}
                        onChange={(event) => onSelectPerk(event, perk, index)}
                      />
                      <label htmlFor={perk?.name}>{perk?.name}</label>
                    </div>
                  </div>
                );
              })}
              <div className="row justify-content-end mt-3">
                <div className="col-auto">
                  <button
                    onClick={onAddSelectedPerks}
                    className="btn btn-outline-black"
                  >
                    Add
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </div>
  );
};
