import React, { useState } from "react";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Admin from "./Admin";
import AdminUser from "./AdminUser";
const AdminSignups = () => {
    const [value, setValue] = React.useState(0);
    return (
        <div style={{display:"flex"}}>
            <Admin />
        <div style={{width:"100%"}}>    
       <Tabs 
      id="uncontrolled-tab-example"
      className="mb-3 signup-user"
      defaultActiveKey="User"
    >
    <Tab eventKey="User" className="signup-user" title="User"><AdminUser/></Tab>
    {/* <Tab eventKey="Influencer" className="signup-user"  title="Influencer">Tab 2 content</Tab> */}
</Tabs>
</div>
        </div>
    )
}

export default AdminSignups