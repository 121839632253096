import React from 'react'
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import "../scss/pages/_thankyou-page.scss";

const ThankyouPage = () => {
    const history = useHistory();

    const redirectHomePage = () => {
        history.push('/')
    }
  return (
    <div className="thankyou-page-main">
        <div className="thankyou-page-container">
            <h1>Welcome.</h1>
            <p>You successfully registered on <strong>ClanWorld</strong></p>
            {/* Go to HomePage  */}
            <Link
            to={`/`}
            className="homepage-link"
          >
            Go to HomePage
          </Link>
        </div>
    </div>
  )
}

export default ThankyouPage