import { SubscriptionPackages } from "../model/subscription-packages";
import API from "../utils/axios";

export class SubscriptionPackagesService {
    constructor() { }

    static GET_SUBSCRIPTION_PACKAGES = (id: string): Promise<SubscriptionPackages[]> => {
        return API.get(`/getAciveSubscriptionPackages/${id}`);
    }

    static GET_SUBSCRIPTION_PACKAGE_ID = (id: string, club_id: string): Promise<SubscriptionPackages[]> => {
        return API.get(`/getSubscriptionPackages`, { params: { _id: id, club_id } });
    }

    static PUT_SUBSCRIPTION_PACKAGE = (data: SubscriptionPackages): Promise<any> => {
        return API.put(`/updateSubscriptionPackage`, data);
    }

    static getClubSubscriptionPackages = (id: string): Promise<SubscriptionPackages[]> => {
        return API.get(`/getClubSubscriptionPackages/${id}`);
    }

    static createNewSubscriptionPackage = (data: SubscriptionPackages): Promise<any> => {
        return API.post(`/createNewSubscriptionPackage`, data);
    }

    static publishSubscriptionPackage = (data: SubscriptionPackages): Promise<any> => {
        return API.post(`/publishSubscriptionPackage`, data);
    }

    static unPublishPackage = (package_id: string, club_id: string): Promise<SubscriptionPackages[]> => {
        return API.post(`/unPublishPackage/${package_id}/${club_id}`);
    }
}
