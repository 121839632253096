import React, { useEffect, useState } from 'react';
import IntroSection from './dashboard/intro-section';
import BannerImage from "../assets/img/banner.jpg";
import HowItWorks from './dashboard/how-it-work';
import { HomePageData } from '../data/data'
import { Clubs } from '../app/model/clubs';
import FeaturedClubs from './dashboard/featured-clubs';
import ClubBenefits from './dashboard/club-benefits';
import { DiveIn } from './dashboard/dive-in-livestream';
import { ClubService } from '../app/service/club.service';
import { Loading } from '../redux/actions';
import { connect, useDispatch } from 'react-redux';
import OurLivestreamCollection from './dashboard/our-livestream-collection';
import { UpcomingLivestream } from './dashboard/upcoming-livestream';
import NFTListing from './dashboard/nft-listing';
import { isMobile } from "react-device-detect";
import LandingPage from './dashboard/landing-page';
import { UserService } from "../app/service/user.service";
// import UpcomingLivestream from './dashboard/upcoming-livestream';
interface Props {
    UserReducer?: any;
    loading?: (data: any) => void;
}
const AppDashboard: React.FC<Props> = (props: Props) => {
    const [clubs, setClubs] = useState<Clubs[] | []>([]);
    const [loading, setloading] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        if (props.loading) {
            dispatch(props.loading(true));
        }
        document.body.classList.add("header-fixed", "w-gradient");
        return function cleanup() {
            document.body.classList.remove('header-fixed', "w-gradient");
        };
    }, []);

    useEffect(() => {
        console.log(isMobile, navigator.userAgent, 'in dashboard')
        setloading(true);
        getMyClub();
    }, []);

    // const verifyUserLogin = () => {
    //     if (localStorage.getItem('token')) {
    //         UserService.verifyUserToken().then(res => {
    //             sessionStorage.setItem("loginUserImage", res[0]?.image || "");
    //             const _id = res[0]?._id || "";
    //             sessionStorage.setItem("isLogin", _id);
    //         })
    //     }
    // }

    const getMyClub = () => {
        ClubService.getClubs().then(
            res => {
                if (res) {
                    setClubs(res);
                    setloading(false);
                    if (props.loading) {
                        dispatch(props.loading(false));
                    }
                } else {
                    setloading(false);
                    if (props.loading) {
                        dispatch(props.loading(false));
                    }
                }
            }
        );
    }

    const scrollDown = () => {
        let headerHeight = document.querySelector<HTMLElement>(".site-header")?.clientHeight!;
        let aboutRef = document.querySelector<HTMLElement>(".featured-club-new")?.offsetTop!
        let pageHeight = aboutRef - headerHeight;
        window.scrollTo(0, pageHeight);
    }

    return (
        <main className="main-content-sec-static-page">
            {/* Intro Section */}
            <IntroSection scrollDown={scrollDown} BannerImage={BannerImage} title={HomePageData.intro.title} subtitle={HomePageData.intro.subtitle} />
            {/* New Homepage */}
            {/* <LandingPage /> */}
            {/* Upcoming Livestream */}
            <UpcomingLivestream userId={'0'} />
            {/* Our Livestream Collection */}
            <OurLivestreamCollection userId={'0'} />
            {/* NFT Collection */}
            {/* <NFTListing /> */}
            {/* How It Work Section */}
            {/* <HowItWorks title={HomePageData.howItWork.title} description={HomePageData.howItWork.subtitle} /> */}
            {/* Featured Clubs */}
            {/* <FeaturedClubs clubs={clubs} /> */}
            {/* Club Benefits */}
            {/* <DiveIn diveIn={'0'} /> */}
            {/* Club Benefits */}
            {/* <ClubBenefits title={HomePageData.clubBenefits.title} description={HomePageData.clubBenefits.description} /> */}
        </main >
    );
};
const mapStateToProps = (state: any) => {
    return {
        UserReducer: state.UserReducer,
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        loading: (data: any) => dispatch(Loading(data)),
    }
}

const connectedNavBar = connect(mapStateToProps, mapDispatchToProps)(AppDashboard);
export { connectedNavBar as AppDashboard };
