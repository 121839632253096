import React, {useState } from "react";
import { useHistory } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { UserService } from "../../../app/service/user.service";
import { connect, useDispatch } from "react-redux";
import { LoginUserId, UserLogin, UserLogout } from "../../../redux/actions";
import { Loading } from "../../../redux/actions";
import { UserData } from "../../../app/model/user";

interface Props {
    UserReducer?: any;
    loading?: (data: any) => void;
    loginUserId?: (data: any) => void;
    LoginUserIdReducer?: any;
    logout?: (data: any) => void;
  }
const MyAccountDetails:React.FunctionComponent<Props> = (props) => {
    let id = sessionStorage.getItem("isLogin") || "";
    const [showModal, setShowModal] = useState<any>(false);
    const handleCloseDeleteUser = () => setShowModal(false);
    const handleShowDeleteUser = () => setShowModal(true);
    const [isUserLogin, setUserLogin] = useState(
        sessionStorage.getItem("isLogin") || ""
      );
      const history = useHistory();
      const dispatch = useDispatch();


    const handleLogoutClick = () => {
        setUserLogin("");
        if (props.logout) {
          const data = { loginPopup: false, popupType: "" };
          dispatch(props.logout(data));
        }
        sessionStorage.setItem("isLogin", "");
        sessionStorage.setItem("token", "");
        if (props.loginUserId) {
          dispatch(props.loginUserId(null));
        }
        sessionStorage.clear();
        localStorage.clear();
        history.push("/");
      };

    const handleDeleteUser = () => {
        console.log("object");
        const _id = sessionStorage.getItem("isLogin") || "";
        UserService.deleteUser(_id).then((res) => {
          if (res) {
            console.log("hello world");
            handleLogoutClick();
          }
        });
      };
  return (
    <>
    <p><h5 style={{display:"inline", color:"red"}}>WARNING ! :</h5>If you click on Delete User. This will delete all your progress and data.</p>
        <button
                className="btn btn-black"
                type="button"
                onClick={handleShowDeleteUser}
                style={{marginTop: "25px" }}
              >
                Delete Account
              </button>

              <Modal
        dialogClassName="sign-up-modal app-store"
        show={showModal}
        onHide={handleCloseDeleteUser}
      >
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="auth-container top-space-login pl-3 pr-3 pt-5">
            <div className="auth-info text-center">
              <h4>Are you sure you want to Delete your acoount prmanently ?</h4>
              <div className="btn-app-store mb-4">
                <button
                  className="btn btn-outline-black d-inline-flex col-auto me-2 size"
                  onClick={handleCloseDeleteUser}
                >
                  No
                </button>
                <button
                  className="btn btn-black d-inline-flex col-auto size"
                  onClick={handleDeleteUser}
                >
                  Yes
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </>
  )
}

const mapStateToProps = (state: any) => {
    return {
      UserReducer: state.UserReducer,
      LoginPopupReducer: state.LoginPopupReducer,
      LoginUserIdReducer: state.LoginUserIdReducer,
    };
  };
  
  const mapDispatchToProps = (dispatch: any) => {
    return {
      loading: (data: any) => dispatch(Loading(data)),
      logout: () => dispatch(UserLogout()),
      login: (data: UserData) => dispatch(UserLogin(data)),
    };
  };
  
  const connectedNavBar = connect(
    mapStateToProps,
    mapDispatchToProps
  )(MyAccountDetails);
  export { connectedNavBar as MyAccountDetails };
export default MyAccountDetails;