import React, { useEffect, useState } from "react";
import FormBuilder from "../../../../components/form-builder";
import Capture from "../../../assets/img/Capture1.png";
interface Props { }

export const MyLivestreamsTeaserPage: React.FC<Props> = (props: Props) => {
  const [isLoading, setLoading] = useState(false);
  const [myclubsData, setMyClubData] = useState<any>({});

  const updateFormData = (data: any) => {
    setMyClubData(data);
  };

  const onSaveMyClub = () => {
    setLoading(true);
  };
  return (
    <div className="main-content">
      <div className="container container-custom">
        <div className="tab-content" id="pills-tabContent">
          <h4>Teaser</h4>
          <div
            className="tab-pane active"
            id="details"
            role="tabpanel"
            aria-labelledby="details-tab"
          >
            <FormBuilder onUpdate={updateFormData}>
              <form>
                <div className="form-floating mb-3">
                  <input
                    className="form-control"
                    type="text"
                    name="name"
                    placeholder="Title of the post"
                  />
                  <label htmlFor="floatingInput">Title</label>
                </div>
                <div className="form-floating mb-3">
                  <textarea
                    className="form-control"
                    name="creations"
                    placeholder="Add a description"
                  ></textarea>
                  <label htmlFor="creations">Description</label>
                </div>
                <div className="row mb-3">
                  <div className="col-md-4">
                    <label className="form-label" htmlFor="formFileSm">
                      video
                    </label>
                    <div className="upload-sec upload-video custom">
                      <div className="button_video_outer">
                        <div className="btn_video_upload btn">
                          <input
                            className="upload_video_file"
                            id="upload_video_file"
                            type="file"
                            name="intro_video"
                            accept=".mov,.mkv, .mpg, .mp2, .mpeg,.mpe,.mpv,.m2v, .mp4,.m4p,.m4v, .avi, .wmv, .flv, .3gp, .webm"
                          />
                          <i className="bi bi-cloud-arrow-up"></i>
                          <strong>Upload Video </strong>
                          <span>(drag and drop or browse)</span>
                        </div>
                        <div className="processing_video_bar"></div>
                        <div className="success_video_box"></div>
                      </div>
                      <div className="error_video_msg upload_video_error_msg"></div>
                      <div
                        className="uploaded_video_view"
                        id="uploaded_video_view"
                      >
                        <span className="file_video_remove">X</span>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
              <div className="row mt-5">
                <div className="col-auto d-flex flex-wrap">
                  <button className="btn btn-outline-orange me-2">
                    Cancel
                  </button>
                  <button
                    disabled={isLoading}
                    type="submit"
                    className="btn btn-outline-black"
                    onClick={onSaveMyClub}
                  >
                    {!isLoading ? (
                      "Save"
                    ) : (
                      <div
                        className="spinner-border text-primary spinner-border-sm"
                        role="status"
                      >
                        <span className="sr-only">Loading...</span>
                      </div>
                    )}
                  </button>
                </div>
                <div className="ms-auto col-auto">
                  <button className="btn btn-primary">Publish</button>
                </div>
              </div>
            </FormBuilder>
          </div>
        </div>
      </div>
    </div>
  );
};
