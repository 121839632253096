import React,{useEffect} from "react";
import { FooterData } from "../data/data";
import { Link } from "react-router-dom";
interface Props {}


const AppFooter: React.FC<Props> = (props) => {

 
  return (
    <footer className="site-footer">
      <div className="footer-sec">
        <div className="footer-info">
          <h3 className="footer-info-title">{FooterData.location.title}</h3>
          <ul className="footer-info-loc">
            {FooterData.location.locationInfo?.map((location, index) => {
              return (
                <li key={index}>
                  <div className="footer-info-loc-title">{location.title}</div>
                  <address className="footer-info-address">
                    {location.address}
                  </address>
                </li>
              );
            })}
          </ul>
        </div>
        <div className="footer-info">
          <h3 className="footer-info-title">{FooterData.company.title}</h3>
          <div className="footer-links">
            {FooterData.company.footerLinks.map((link) => {
              return (
                <Link key={link.id} to={link.url}>
                  {link.title}
                </Link>
              );
            })}
          </div>
          <h3 className="footer-info-title mt-4">
            {FooterData.policies.title}
          </h3>
          <div className="footer-links">
            {FooterData.policies.footerLinks.map((link) => {
              return (
                <Link key={link.id} to={link.url}>
                  {link.title}
                </Link>
              );
            })}
          </div>
        </div>
        <div className="footer-info">
          <h3 className="footer-info-title">Go to &nbsp;<a style={{color:
        '#fff', textDecoration:'none'}} href="https://www.clanconnect.ai/" target="_blank">
              ClanConnect
            </a></h3>
          <div className="footer-links">
            
          </div>
          <h3 className="footer-info-title mt-4">{FooterData.contact.title}</h3>
          <div className="footer-links">
            <a href={`mailto:${FooterData.contact.email}`}>
              {FooterData.contact.email}
            </a>
            <a href={`tel:${FooterData.contact.tel}`}>
              {FooterData.contact.tel}
            </a>
          </div>
          <h3 className="footer-info-title mt-3">Follow Us</h3>
          <div className="social-icon">
            {FooterData.contact.socialLinks.map((social) => {
              return (
                <Link key={social.id} to={social.url}>
                  <i className={social.iconName} ></i>
                </Link>
              );
            })}
          </div>
          {/* <a className="demo" href="">Request Demo</a> */}
        </div>
      </div>
      <div className="text-center px-2">
        &copy; {new Date().getFullYear()} Irida Interactive Pvt. Ltd. All Rights
        Reserved.{" "}
      </div>
    </footer>
  );
};

export default AppFooter;
