import React, { Fragment, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { toast } from "react-toastify";
import { Clubs } from "../../../app/model/clubs";
import { SubscriptionPackages } from "../../../app/model/subscription-packages";
import { ClubService } from "../../../app/service/club.service";
import { SubscriptionPackagesService } from "../../../app/service/subscription-packages-service";
import ClubsRoutes from "../../../routes/clubs";
import dummyImg from "../../../assets/img/dummy-img.svg";
import { connect, useDispatch } from "react-redux";
import { GetClub, Loading, SaveClub } from "../../../redux/actions";
import { MyClubDetailsPage } from "./details";
interface Props {
  UserReducer?: any;
  loading?: (data: any) => void;
  saveClub?: (data: any) => void;
  getClub?: (data: any) => void;
  LoadingReducer?: any;
  SaveClubReducer?: any;
}

const MYClubsPage: React.FC<Props> = (props: Props) => {
  const [club, setClubs] = useState<Clubs[] | []>([]);
  const aboutRef = React.useRef<HTMLDivElement>(null);
  const packagesRef = React.useRef<HTMLDivElement>(null);
  const postsRef = React.useRef<HTMLDivElement>(null);
  const [subscriptionPackages, setSubscriptionPackages] = useState<
    SubscriptionPackages[] | []
  >([]);
  const dispatch = useDispatch();
  const id = sessionStorage.getItem("isLogin") || "";
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  useEffect(() => {
    if (id) {
      ClubService.getUserClubs(id).then((res) => {
        if (res) {
          // sessionStorage.setItem('clubPreview', JSON.stringify(res[0]));
        }
      });
    }
  }, [id]);

  const getMyClub = (id: string) => {
    ClubService.getUserClubs(id).then((res) => {
      if (res) {
        // setClubs(res);
        publishClub(res[0]);
        // SubscriptionPackagesService.GET_SUBSCRIPTION_PACKAGES(res[0]._id).then((res) => {
        //     if (res) {
        //         res.sort((a, b) => (a.name > b.name ? 1 : -1));
        //         setSubscriptionPackages([...res]);
        //         // clubSubscriberDetails(res);
        //     }
        // });
      }
    });
  };

  const onPublishPost = () => {
    const club: any =
      props.SaveClubReducer.saveClub ||
      JSON.parse(sessionStorage.getItem("clubPreview") || "");
    if (!club?._id) {
      let formData = new FormData();
      formData.append("name", club?.name ? club.name : "");
      formData.append("creations", club?.creations ? club.creations : "");
      formData.append(
        "intro_video",
        club?.intro_video ? club?.intro_video : ""
      );
      formData.append(
        "cover_photo",
        club?.cover_photo ? club?.cover_photo : ""
      );
      formData.append("owned_by", id);
      formData.append("about", club?.about ? club.about : "");
      formData.append("video_url", club?.video_url ? club.video_url : "");
      ClubService.publishClub(formData).then((res) => {
        if (res) {
          sessionStorage.setItem("clubId", res?._id || "");
          toast.success("Club Saved & Published successfully");
        }
      });
    } else {
      getMyClub(id);
    }
  };

  const publishClub = (data: Clubs) => {
    ClubService.publishClub(data).then((res) => {
      if (res) {
        sessionStorage.setItem("clubId", res?._id || "");
        toast.success("Club Published successfully");
      }
    });
  };

  // const getClub = (id: string) => {
  //     ClubService.getClub(id).then((res) => {
  //         if (res) {
  //             setClubs(res);
  //         }
  //     });
  //     SubscriptionPackagesService.GET_SUBSCRIPTION_PACKAGES(id).then((res) => {
  //         if (res) {
  //             res.sort((a, b) => (a.name > b.name ? 1 : -1));
  //             setSubscriptionPackages([...res]);
  //             clubSubscriberDetails(res);
  //         }
  //     });
  //     // getClubPosts(id);
  // };

  const onPrewiew = () => {
    const club: any =
      props.SaveClubReducer.saveClub ||
      JSON.parse(sessionStorage.getItem("clubPreview") || "");
    try {
      const data = club;
      if (data._id) {
        const resData = [];
        resData.push(data);
        setClubs(resData);
        clubPackages(data);
      } else {
        let formData = new FormData();
        formData.append("name", data?.name ? data.name : "");
        formData.append("creations", data?.creations ? data.creations : "");
        formData.append(
          "intro_video",
          data?.intro_video ? data?.intro_video : ""
        );
        formData.append(
          "cover_photo",
          data?.cover_photo ? data?.cover_photo : ""
        );
        formData.append("owned_by", id);
        formData.append("about", data?.about ? data.about : "");
        formData.append("video_url", data?.video_url ? data.video_url : "");
        ClubService.createClub(formData).then((res) => {
          if (res) {
            getMyClub(id);
            const resData = [];
            resData.push(res);
            setClubs([...resData]);
            clubPackages(res);
            sessionStorage.setItem('clubId', res?._id || '');
          }
        });
      }
    } catch (error) { }
    handleShow();
  };

  const clubPackages = (data: Clubs) => {
    if (data?._id) {
      SubscriptionPackagesService.GET_SUBSCRIPTION_PACKAGES(data?._id).then(
        (res) => {
          if (res) {
            res.sort((a, b) => (a.name > b.name ? 1 : -1));
            setSubscriptionPackages([...res]);
            // clubSubscriberDetails(res);
          }
        }
      );
    }
  };
  const onPackagesClick = (elementRef: any) => {
    if (elementRef.current) {
      elementRef.current.scrollIntoView();
    }
  };
  return (
    <div className="main-content">
      <div className="container container-custom">
        <div className="main-content__top">
          <div className="custom-tab">
            <ul className="nav nav-pills mb-2 ">
              <li className="nav-item" role="presentation">
                <NavLink className="nav-link" to="/dashboard/myclubs/details">
                  Details
                </NavLink>
              </li>
              <li className="nav-item" role="presentation">
                <NavLink
                  className="nav-link"
                  id="packages-tab"
                  to="/dashboard/myclubs/packages"
                >
                  Packages
                </NavLink>
              </li>
              <li className="nav-item" role="presentation">
                <NavLink
                  className="nav-link"
                  id="payments-tab"
                  to="/dashboard/myclubs/payments"
                >
                  Payments
                </NavLink>
              </li>
            </ul>
          </div>
          <div className="main-content__top__right">
            {/* <a className="btn btn-primary me-2" onClick={onPrewiew}>
              Save + Preview
            </a> */}
          </div>
        </div>
        <div className="tab-content mt-3" id="pills-tabContent">
          <div
            className="tab-pane active"
            id="details"
            role="tabpanel"
            aria-labelledby="details-tab"
          >
            <ClubsRoutes></ClubsRoutes>
          </div>
        </div>
      </div>
      <Modal dialogClassName="previewModal" size="lg" show={show} onHide={handleClose}>
        <Modal.Header closeButton style={{ borderBottom: "none" }}>
          <Modal.Title style={{ marginBottom: "0" }}>Preview</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="main-content">
            <main className="main-content-sec-static-page">
              <section className="banner-cover category-detail-page">
                <img src={club[0]?.cover_photo || dummyImg} alt="banner" />
                <div className="banner-cover-profile">
                  <div className="banner-cover-profile__left">
                    <div className="banner-cover-profile__left-img">
                      <img src={club[0]?.image || dummyImg} alt="banner" />
                    </div>
                    <div className="banner-cover-profile__info">
                      <h1 className="banner-cover-profile__info-title">
                        {club[0]?.name}
                      </h1>
                      <p className="banner-cover-profile__info-category-tags">
                        {club[0]?.tags}
                      </p>
                      <p className="banner-cover-profile__info-subs-count">
                        {club[0]?.total_subscribers} Subscribers
                      </p>
                    </div>
                  </div>
                  <div className="banner-cover-profile__right">
                    <a className="btn btn-outline-black btn-lg" href="">
                      Enquiry
                    </a>
                    <div className="social-sec">
                      <a href="">
                        {" "}
                        <i className="bi bi-instagram"></i>
                      </a>
                      <a href="">
                        {" "}
                        <i className="bi bi-facebook"></i>
                      </a>
                      <a href="">
                        {" "}
                        <i className="bi bi-twitter"></i>
                      </a>
                      <a href="">
                        {" "}
                        <i className="bi bi-youtube"></i>
                      </a>
                      <a href="">
                        {" "}
                        <i className="bi bi-share"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </section>
              <section className="card-detail-page">
                {/* <div className="custom-tab card-detail-tab">
                  <ul className="nav nav-pills">
                    <li className="nav-item" role="presentation">
                      <a
                        className="nav-link pointer"
                        id="details-tab"
                        onClick={() => onPackagesClick(aboutRef)}
                      >
                        About
                      </a>
                    </li>
                    <li className="nav-item" role="presentation">
                      <a
                        className="nav-link pointer"
                        id="packages-tab"
                        onClick={() => onPackagesClick(packagesRef)}
                      >
                        Packages
                      </a>
                    </li>
                    <li className="nav-item" role="presentation">
                      <a
                        className="nav-link pointer"
                        id="payments-tab"
                        onClick={() => onPackagesClick(postsRef)}
                      >
                        Posts
                      </a>
                    </li>
                  </ul>
                </div> */}
                <div className="card-detail-tab__content-sec">
                  <div
                    className="card-detail-tab__content"
                    id="about"
                    ref={aboutRef}
                  >
                    <div className="container container-small">
                      <h3 className="card-detail-tab-title">About</h3>
                      <div className="ratio ratio-16x9 mb-3 main-video">
                        {club[0]?.intro_video && (
                          <video width="560" height="315" controls controlsList="nodownload">
                            <source
                              src={club[0]?.intro_video}
                              type="video/mp4"
                            />
                            Your browser does not support HTML video.
                          </video>
                        )}
                      </div>
                      <p>{club[0]?.about}</p>
                    </div>
                  </div>
                  <div
                    className="card-detail-tab__content"
                    id="packages"
                    ref={packagesRef}
                  >
                    <div className="container container-medium">
                      <h3 className="card-detail-tab-title">Packages</h3>
                      <div className="package-sec row">
                        {subscriptionPackages.map((subscription) => {
                          return (
                            <div key={subscription._id} className="col-md-4">
                              <div className="package">
                                <div className="package-header">
                                  <div className="package-info">
                                    <strong>{subscription?.name}</strong>
                                    <span className="package-price">
                                      {subscription?.price_monthly}
                                    </span>
                                    <span className="package-term">
                                      per month
                                    </span>
                                    <button className="btn btn-primary">
                                      Subscribe
                                    </button>
                                    <a className="package-link pointer">
                                      or pay annually | SAVE 5%
                                    </a>
                                  </div>
                                </div>
                                <div className="package-desc">
                                  <p>{subscription?.description} </p>
                                  <ol>
                                    {subscription.perks.map((perk, index) => {
                                      return <li key={index}>{perk?.value}</li>;
                                    })}
                                  </ol>
                                  {/* {subscription.perks.length > 0 && (
                                                                        <a href="">more</a>
                                                                    )} */}
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </main>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-outline-black"
            type="button"
            onClick={handleClose}
          >
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
const mapStateToProps = (state: any) => {
  return {
    UserReducer: state.UserReducer,
    LoadingReducer: state.LoadingReducer,
    SaveClubReducer: state.SaveClubReducer,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    loading: (data: any) => dispatch(Loading(data)),
    saveClub: (data: any) => dispatch(SaveClub(data)),
    getClub: (data: any) => dispatch(GetClub(data)),
  };
};

const connectedNavBar = connect(
  mapStateToProps,
  mapDispatchToProps
)(MYClubsPage);
export { connectedNavBar as MYClubsPage };
