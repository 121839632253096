import React, { useEffect } from "react";
import "./scss/style.scss";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import "./assets/styles/custom-style.css";
// import { GOOGLE_ANALYTICS_ID } from "../src/config/constant";


import { HashRouter } from "react-router-dom";
// import { Provider } from 'react-redux';
// import { store } from './redux/store';
import Routes from "./routes/index";
import { AppLoader } from "./components/loader";
import ReactGA from "react-ga";
import API, { dataDecryption } from "./app/utils/axios";
import { Buffer } from "buffer";
import { connect } from "react-redux";


import {GOOGLE_ANALYTICS_ID } from "../src/config/constant";


ReactGA.initialize(`${GOOGLE_ANALYTICS_ID}`);

interface Props{

}

const AppConnect:React.FC<Props>= (props:Props)=> {
  
  // @ts-ignore
    // document.addEventListener('keydown', function (event: any) {
  //   if (event.keyCode == 123 || (event.ctrlKey && event.shiftKey && event.keyCode == 73) || (event.ctrlKey && event.keyCode == 85)) {
  //     alert("This operation is not allowed");
  //     event.preventDefault();
  //     return;
  //   }
  // }, false);

  document.addEventListener("click", function () {
    ReactGA.pageview(window.location.hash + window.location.search);
  });

  // if (document.addEventListener) {
  //   document.addEventListener('contextmenu', function (e) {
  //     alert("This operation is not allowed");
  //     e.preventDefault();
  //     return;
  //   }, false);
  // }
  //   API.interceptors.response.use(
  //     async (response: any) => {
  //       console.log('response ', response);

  //         const returnData = await dataDecryption(response.data.data);
  //         // const returnData = response.data.data;
  //         return returnData;
  //     }, (error) => {
  //         let errorStatusCode = JSON.parse(JSON.stringify(error));
  //         if (errorStatusCode.status === 401) {
  //             // history.push('/');
  //             sessionStorage.clear();
  //             localStorage.clear();
  //             console.log('Show popup here');

  //             // toast.error('Session expired, Please login');
  //             // window.location.href = '/';
  //         }
  //         return errorStatusCode;
  //     }
  // );

  //         const returnData = await dataDecryption(response.data.data);
  //         // const returnData = response.data.data;
  //         return returnData;
  //     }, (error) => {
  //         let errorStatusCode = JSON.parse(JSON.stringify(error));
  //         if (errorStatusCode.status === 401) {
  //             // history.push('/');
  //             sessionStorage.clear();
  //             localStorage.clear();
  //             console.log('Show popup here');

  //             // toast.error('Session expired, Please login');
  //             // window.location.href = '/';
  //         }
  //         return errorStatusCode;
  //     }
  // );
useEffect(() => {
  const isLogin=localStorage.getItem('isLogin')||'';
  sessionStorage.setItem('isLogin',isLogin);
}, []);
  return (
    // <Provider store={store}>
    <>
      {/* <Router history={RouterHistory}>
      <Routes></Routes>
    </Router> */}
      <AppLoader />
      <HashRouter>
        <Routes />
      </HashRouter>
      <ToastContainer hideProgressBar={true} />
    </>

    // </Provider>
  );
}
const mapStateToProps = (state: any) => {
  return {
      UserReducer: state.UserReducer,
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
      // loading: (data: any) => dispatch(Loading(data)),
  }
}

const App = connect(mapStateToProps, mapDispatchToProps)(AppConnect);
export default  App ;
// export default App;
