import React, { Fragment } from "react";
import { NavLink, useLocation } from "react-router-dom";
import PurchasedTicketRoutes from "../../../routes/purchased-tickets";

export const PurchasedTicketsPage: React.FC = () => {
  const location = useLocation();
  return (
    <div className="main-content">
      <div className="container-fluid">
        <div className="main-content__top">
          <div className="custom-tab mb-2">
            <ul className="nav nav-pills">
              <li className="nav-item" role="presentation">
                <NavLink
                  className="nav-link"
                  id="subs-subscription-tab"
                  to="/dashboard/purchasedtickets/upcoming"
                >
                  Upcoming
                </NavLink>
              </li>
              <li className="nav-item" role="presentation">
                <NavLink
                  className="nav-link"
                  id="subs-subscription-tab"
                  to="/dashboard/purchasedtickets/attended"
                >
                  Attended
                </NavLink>
              </li>
              <li className="nav-item" role="presentation">
                <NavLink
                  className="nav-link"
                  id="subs-subscription-tab"
                  to="/dashboard/purchasedtickets/recordings"
                >
                  Past Events
                </NavLink>
                </li>
              <li className="nav-item" role="presentation">
                <NavLink
                  className="nav-link"
                  id="subs-subscription-tab"
                  to="/dashboard/purchasedtickets/cancelled"
                >
                  Cancelled
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
        <div className="tab-content" id="pills-tabContent">
          <div
            className="tab-pane active"
            id=""
            role="tabpanel"
            aria-labelledby=""
          >
            <PurchasedTicketRoutes></PurchasedTicketRoutes>
          </div>
        </div>
      </div>
    </div>
    // </div>
  );
};
