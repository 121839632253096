import React, { Fragment, useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
import { ClubsPost } from "../../../app/model/clubs";
import { ClubService } from "../../../app/service/club.service";
import { PostCount } from "../../../redux/actions";
import PostsRoutes from "../../../routes/posts";
interface Props {
  PostCountReducer?: any;
  postCount?: (data: any) => void;
}
const PostsPage: React.FC<Props> = (props: Props) => {
  let club_id = sessionStorage.getItem("clubId") || "";
  const [unPublishedPosta, setUnPublishedPosta] = useState<any[] | []>([]);
  const [publishedPosts, setPublishedPosts] = useState<ClubsPost[] | []>([]);
  const dispatch = useDispatch();

  useEffect(() => {
    getPost();
  }, []);
  const getPost = () => {
    let postData = { pulished: 0, unpulished: 0 };
    if (club_id) {
      ClubService.getUnPublishPost(club_id, "", "").then((res) => {
        if (res) {
          setUnPublishedPosta([...res]);
          postData = { pulished: postData.pulished, unpulished: res.length };
          ClubService.getClubPost(club_id, "", "").then((res) => {
            if (res) {
              setPublishedPosts([...res]);
              postData = { pulished: res.length, unpulished: postData.unpulished };
              if (props.postCount) {
                dispatch(props.postCount(postData));
              }
            }
          });
        }
      });

    }
  };
  return (
    <div className="main-content">
      <div className="container container-custom">
        <div className="main-content__top">
          <div className="custom-tab">
            <ul className="nav nav-pills mb-2">
              <li className="nav-item" role="presentation">
                <NavLink className="nav-link" to="/dashboard/posts/published">
                  PUBLISHED ({props.PostCountReducer?.postCount ? props.PostCountReducer?.postCount?.pulished : 0}
                  )
                </NavLink>
              </li>
              <li className="nav-item" role="presentation">
                <NavLink className="nav-link" to="/dashboard/posts/drafts">
                  DRAFTS (
                  {props.PostCountReducer?.postCount ? props.PostCountReducer?.postCount?.unpulished : 0})
                </NavLink>
              </li>
            </ul>
          </div>
        </div>

        <div className="tab-content" id="pills-tabContent">
          <div
            className="tab-pane active"
            id="details"
            role="tabpanel"
            aria-labelledby="details-tab"
          >
            <PostsRoutes></PostsRoutes>
          </div>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state: any) => {
  return {
    PostCountReducer: state.PostCountReducer,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    postCount: (data: any) => dispatch(PostCount(data)),
  };
};

const connectedNavBar = connect(
  mapStateToProps,
  mapDispatchToProps
)(PostsPage);
export { connectedNavBar as PostsPage };
