import React, { useEffect, useState } from "react";
import BankDetails from "./bank-details";
import { MyProfilePasswordPage } from "./password";
import PaymentHistory from "./payment-history";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Modal } from "react-bootstrap";
import { PersonalDetail } from "./personal-details";
import { S3Helper } from "../../../app/utils/s3-helper";
import { ClubService } from "../../../app/service/club.service";
import { UserService } from "../../../app/service/user.service";
import { toast } from "react-toastify";
import { CLOUDFRONT_URL, S3_URL } from "../../../config/constant";
import MyAccountDetails from "./my-account-details";

interface Props {
  children?: React.ReactNode;
  index: number;
  value: number;
}
function TabPanel(props: Props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const Settings: React.FunctionComponent<Props> = (props) => {
  const [value, setValue] = React.useState(0);
  let id = sessionStorage.getItem("isLogin") || "";

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const [show, setShow] = useState(false);
  const [userData, setUserData] = useState<any[] | []>([]);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [profileImage, setProfileImage] = useState("");
  const [awsInfo, setAwsInfo] = useState<any>(null);
  const baseUrl = S3_URL;
  useEffect(() => {
    if (id) {
      const data = {
        user_id: id,
      };
      ClubService.getUserFolderInfo(data).then((res) => {
        if (res) {
          setAwsInfo(res);
        } else {
          toast.error(res?.message);
        }
      });
      getUserData(id);
    }
  }, []);

  const getUserData = (id: string) => {
    // if (props.loading) {
    //   dispatch(props.loading(true));
    // }
    UserService.getUser(id).then((res) => {
      if (res) {
        setUserData([...res]);
        sessionStorage.setItem("userImages", "");
        if (res.length > 0) {
          setProfileImage(res[0]?.cover_photo);
        }
      }
    });
  };
  const onUploadProgress = (data: any) => {
    const progress = Math.round((data.loaded / data.total) * 100);
    // setProgress(progress);
  };
  const onUploadContent = async (event: any) => {
    setProfileImage("");
    ClubService.signedCall({
      fileName: `${awsInfo?.folderPath}/${event.target.files[0]?.name}`,
      fileType: event.target.files[0]?.type,
    }).then(async (res) => {
      console.log(res, "signed Response");
      const result = await S3Helper.uploadFilesToS3BySigned(
        res,
        event.target.files[0],
        event.target.files[0]?.type
      );
      console.log("result", result);
      // if (props.loading) {
      //   dispatch(props.loading(false));
      // }
      setProfileImage(
        `${CLOUDFRONT_URL}${awsInfo?.folderPath}/${event.target.files[0]?.name}`
      );
      console.log(profileImage.split(" ").join(""))
      toast.success("Uploaded Successfully");
    });
    // if (event.target.files && event.target.files[0]) {
    //   const result = await S3Helper.uploadFilesToS3(
    //     event.target.files[0],
    //     awsInfo
    //   );
    //   setProfileImage(
    //     result
    //   );
    //   // setProfileImage(
    //   //   `${baseUrl}${awsInfo.folderPath}/${event.target.files[0].name}`
    //   // );
    //   toast.success("Uploaded Successfully");
    // }
  };

  const onSaveMyProfile = () => {
    const value = {
      _id: id,
      cover_photo: profileImage ? profileImage : "",
    };

    UserService.updateUsers(value).then((res) => {
      if (res) {
        handleClose();
      }
    });
  };

  const onSaveCoverPic = () => {
    onSaveMyProfile();
  };

  return (
    <>
      <div className="main-content profile-sec">
        <div className="container container-custom">
          <div
            className="per-acc-sec"
            style={{
              backgroundImage: `url(${encodeURI(profileImage)
                  ? encodeURI(profileImage)
                  : "linear-gradient(to right, #DDEFBB, #FFEEEE)"
                })`,
              backgroundPosition: "center center",
              backgroundSize: "cover",
            }}
          >
            <div className="per-acc-sec-detail">
              <h2>Account</h2>
              <div className="per-acc-sec-upload">
                <div className="per-acc-sec-upload-left">
                  <span>Upload Cover Photo</span>
                  <span>This cover photo will appear on your profile</span>
                </div>
                <div className="per-acc-sec-upload-right">
                  <a href="javascript:void(0)" onClick={handleShow}>
                    <i className="bi bi-camera"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="per-acc-detail">
            <div className="per-acc-detail-menu">
              <Tabs
                className="per-acc-detail-menu-tab "
                orientation="vertical"
                value={value}
                onChange={handleChange}
                aria-label="Vertical tabs example"
              >
                <Tab
                  className="tab-profile try"
                  label="PERSONAL INFO"
                  {...a11yProps(0)}
                />
                {userData[0]?.club_influencer && (
                  <Tab
                    className="tab-profile try"
                    label="BANK DETAILS"
                    {...a11yProps(1)}
                  />
                )}
                <Tab
                  className="tab-profile"
                  label="MY ACCOUNT"
                  {...a11yProps(2)}
                />
              </Tabs>
              {/* <Tab
                  className="tab-profile"
                  label="Notification Settings"
                  {...a11yProps(3)}
                /> */}


              {/* <a
                href=""
                className="active"
                id="v-pills-profile-tab"
                data-bs-toggle="pill"
                data-bs-target="#v-pills-profile"
                type="button"
                role="tab"
                aria-controls="v-pills-profile"
                aria-selected="true"
              >
                
              </a>
              <a
                href=""
                id="v-pills-password-tab"
                data-bs-toggle="pill"
                data-bs-target="#v-pills-password"
                type="button"
                role="tab"
                aria-controls="v-pills-password"
                aria-selected="false"
              >
                
              </a>
              <a
                href=""
                id="v-pills-messages-tab"
                data-bs-toggle="pill"
                data-bs-target="#v-pills-messages"
                type="button"
                role="tab"
                aria-controls="v-pills-messages"
                aria-selected="false"
              >
                
              </a>
              <a
                href=""
                id="v-pills-settings-tab"
                data-bs-toggle="pill"
                data-bs-target="#v-pills-settings"
                type="button"
                role="tab"
                aria-controls="v-pills-settings"
                aria-selected="false"
              >
                
              </a> */}
            </div>
            <div className="per-acc-detail-content">
              <TabPanel value={value} index={0}>
                <PersonalDetail />
              </TabPanel>

              <TabPanel value={value} index={1}>
                <BankDetails />
              </TabPanel>
              <TabPanel value={value} index={2}>
                <MyAccountDetails />
              </TabPanel>
              <TabPanel value={value} index={3}></TabPanel>
              <div className="per-acc-detail-menu-btm">
                <a href="mailto:support@clanworld.ai">Support</a>
                <a href="tel:+91-9289315858">Contact Us</a>
              </div>
            </div>
            <Modal show={show} onHide={handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>Upload Cover Photo</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <input
                  type="file"
                  accept="image/jpg, image/jpeg, image/png, image/svg, image/gif"
                  onChange={(e) => onUploadContent(e)}
                />
              </Modal.Body>
              <Modal.Footer>
                <button
                  className="btn btn-black"
                  type="button"
                  onClick={onSaveCoverPic}
                >
                  Save Changes
                </button>
              </Modal.Footer>
            </Modal>
          </div>
        </div>
      </div>
    </>
  );
};

export default Settings;
