import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { NftService } from "../../../app/service/nft.service";
import moment from "moment";
import Banner from "../../../assets/img/banner.jpg";

type Props = {};

const NFTCollecting = (props: Props) => {
  let userId = sessionStorage.getItem("isLogin") || "";
  const [userOwningNft, setUserOwningNft] = useState<any[] | []>([]);

  useEffect(() => {
    getOwningNftByUserID(userId);
  }, []);

  const getOwningNftByUserID = (userId: any) => {
    NftService.getOwningNftByUserID(userId).then((res) => {
      console.log(res, 'res')
      if (res) {
        setUserOwningNft(res);
      }
    })
  }
  return (
    <div className="row mt-5 mb-5">
      {userOwningNft.map((data: any, index: number) => {
        return (
          <div className="col-xl-4 col-xxl-3 mb-4 col-sm-6">
            <div className="card-nft mw-100">
              <a href="" className="card-nft-img ratio ratio-1x1">
                <img src={data.asset_url} alt="Profile Image" className="mw-100" />
              </a>
              <div className="card-nft-info">
                <div className="card-nft-info__title">
                  <div className="d-flex flex-wrap">
                    <Link to="" className="fw-bold text-decoration-none me-auto pe-2">{data?.title}</Link>
                    {/* <span >{data?.date}</span> */}
                    <span >{moment(data?.date).utc().format('DD-MM-YYYY hh:mm A')} IST</span>
                  </div>
                </div>
                <div className="nft-price-info">
                  <span className="me-auto pe-2">Price: {data?.price}</span>
                  <span className="">Current Price: {data?.price}<span className="text-success ps-1 fw-bold">({data?.percentage})</span></span>
                </div>
                <button className="btn btn-outline-black btn-block">Claim</button>
              </div>
            </div>
          </div>
        )
      })}
    </div>
  );
};

export default NFTCollecting;
