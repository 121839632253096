import React, { useState } from 'react';
// import IconEmail from '../../assets/img/ic_email.png';
// import IconGoogle from '../../assets/img/ic_google.png';
// import IconFacebook from '../../assets/img/ic_facebook_blue.png';
import { useTransition, animated } from 'react-spring';
// import { POST_SAVE_USER } from '../../app/service/userdata.service';

interface Props {
    transition: string | null;
    update: (transition: string | null) => void;
    closePopup(): void;
}

const SignUp: React.FC<Props> = (props) => {
    let [isEmailSignup, setEmailSignup] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [isFail, setIsFail] = useState(false);
    let [signUpData, setSignupData] = useState({
        name: '',
        phno: '',
        email: '',
        password: '',
        isValidName: true,
        isValidPhno: true,
        isValidEmail: true,
        isValidPassword: true
    });

    let startFrom = '';
    switch (props.transition) {
        case 'left':
            startFrom = 'translate(350px, 0px)';
            break;
        case 'right':
            startFrom = 'translate(-350px, 0px)';
            break;
        default:
            startFrom = 'translate(0px, 0px)';
            break;
    }

    const transistion = useTransition(true, {
        from: {
            opacity: 0,
            transform: startFrom
        },
        enter: {
            opacity: 1,
            transform: 'translate(0px, 0px)'
        },
        leave: {
            transform: startFrom
        },
        delay: 100
    });

    const handleUserInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.name === "name") {
            if ((e.target.value !== "") && (e.target.value.length > 0)) {
                setSignupData({ ...signUpData, isValidName: true, [e.target.name]: e.target.value })
            } else {
                setSignupData({ ...signUpData, isValidName: false, [e.target.name]: e.target.value })
            }
        } else if (e.target.name === 'email') {
            let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (re.test(e.target.value)) {
                setSignupData({ ...signUpData, isValidEmail: true, [e.target.name]: e.target.value })
            } else {
                setSignupData({ ...signUpData, isValidEmail: false, [e.target.name]: '' })
            }
        } else if (e.target.name === 'phno') {
            if ((e.target.value !== "") && (e.target.value.length >= 10)) {
                setSignupData({ ...signUpData, isValidPhno: true, [e.target.name]: e.target.value })
            } else {
                setSignupData({ ...signUpData, isValidPhno: false, [e.target.name]: '' })
            }
        } else if (e.target.name === 'password') {
            const password = e.target.value;
            if (password.length >= 6) {
                setSignupData({ ...signUpData, isValidPassword: true, [e.target.name]: e.target.value })
            } else {
                setSignupData({ ...signUpData, isValidPassword: false, [e.target.name]: '' })
            }
        }
    };

    const submitUserData = (e: { preventDefault: () => void; }) => {
        e.preventDefault();
        if ((signUpData.isValidEmail === true) && (signUpData.isValidPassword === true) &&
            (signUpData.isValidName === true) && (signUpData.isValidPhno === true) &&
            (signUpData.name !== "") && (signUpData.phno !== "") &&
            (signUpData.email !== "") && (signUpData.password !== "")) {
            setLoading(true);
            // POST_SAVE_USER(formData).then(
            //     res => {
            //         if (res.data.httpCode === 200) {
            //             setLoading(false);
            //             setIsFail(false);
            //             props.closePopup();
            //         } else {
            //             setLoading(false);
            //             setIsFail(true);
            //         }
            //     }
            // );
        } else if ((signUpData.name === "") && (signUpData.phno === "") && (signUpData.email === "") && (signUpData.password === "")) {
            setSignupData({
                ...signUpData, isValidName: false, isValidPhno: false, isValidEmail: false, isValidPassword: false
            })
        } else if ((signUpData.name === '') || (signUpData.name === null)) {
            setSignupData({ ...signUpData, isValidName: false })
        } else if ((signUpData.email === "") || (signUpData.email === null)) {
            setSignupData({ ...signUpData, isValidEmail: false })
        } else if ((signUpData.phno === "") || (signUpData.phno === null)) {
            setSignupData({ ...signUpData, isValidPhno: false })
        } else if ((signUpData.password === "") || (signUpData.password === null)) {
            setSignupData({ ...signUpData, isValidPassword: false })
        } else {
            setSignupData({
                ...signUpData, isValidName: signUpData.isValidName, isValidPhno: signUpData.isValidPhno,
                isValidEmail: signUpData.isValidEmail, isValidPassword: signUpData.isValidPassword
            })
        }
    }


    return (
        <div className="h-100">
            {transistion((style, item) => <animated.div style={style} className="h-100">
                <div className="top-space-login pl-3 pr-3 h-100">
                    <h4 className="login-heading f30">Sign Up</h4>
                    <div className="mt-4">
                        <div className="form-floating">
                            {(signUpData.isValidName) ? (<label className="clr-black" htmlFor="exampleInputEmail1">Name *</label>) :
                                (<label className="clr-red" htmlFor="exampleInputEmail1">Enter Valid Name</label>)
                            }
                            <input type="text"
                                className={`form-control ${!signUpData.isValidName ? 'error' : ''}`}
                                id="exampleInputPassword1" placeholder=""
                                name="name" onChange={(e) => handleUserInput(e)} />
                        </div>

                        <div className="form-floating">
                            {(signUpData.isValidEmail) ? (<label className="clr-black" htmlFor="exampleInputEmail1">Email *</label>) :
                                (<label className="clr-red" htmlFor="exampleInputEmail1">Enter Valid Email Id</label>)
                            }
                            <input type="email"
                                className={`form-control ${!signUpData.isValidEmail ? 'error' : ''}`}
                                id="exampleInputPassword1" placeholder=""
                                name="email" onChange={(e) => handleUserInput(e)} />
                        </div>

                        <div className="form-floating">
                            {(signUpData.isValidPhno) ? (<label className="clr-black" htmlFor="exampleInputEmail1">Phone Number *</label>) :
                                (<label className="clr-red" htmlFor="exampleInputEmail1">Enter Valid Phone Number</label>)
                            }
                            <input type="text"
                                className={`form-control ${!signUpData.isValidPhno ? 'error' : ''}`}
                                id="exampleInputPassword1" placeholder=""
                                name="phno" onChange={(e) => handleUserInput(e)} />
                        </div>

                        <div className="form-floating">
                            {(signUpData.isValidPassword) ? (<label className="clr-black" htmlFor="exampleInputEmail1">Password *</label>) :
                                (<label className="clr-red" htmlFor="exampleInputEmail1">Enter Valid Password</label>)
                            }
                            <input type="password"
                                className={`form-control ${!signUpData.isValidPassword ? 'error' : ''}`}
                                id="exampleInputPassword1" placeholder=""
                                name="password" onChange={(e) => handleUserInput(e)} />
                        </div>
                        <div className="bundel btn-black mt-2 btn black form-control">
                            {(isLoading) ? (
                                <div className="spinner-border text-primary spinner-border-sm" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                            ) : (<button type="submit" className="clr-white clr-bg-black btn-outline" style={{ padding: '0', border: 'none', background: 'none' }} onClick={(e) => submitUserData(e)} >Create An Account</button>)
                            }
                        </div>
                        {isFail && <p className="clr-red">Error while creating users</p>}

                    </div>
                </div>

            </animated.div>)}
        </div>
    );
};

export default SignUp;