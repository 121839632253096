import React, { Fragment, useEffect, useState } from "react";
import { NavLink, useLocation, useHistory } from "react-router-dom";
import { DashboardRoutes } from "../../routes/dashboard.routes";
import Logo from "../../assets/img/ClanClublogo.svg";
import MyClubMenuMobile from "../../components/my-club/my-club-menu-mobile";
import ScrollToTop from "../../components/scroll-to-top";
import { AppHeader } from "../../components/header";
import { UserService } from "../../app/service/user.service";
import Profile from "../../assets/img/profile.png";
import { LoginUserId, UserLogin, UserLogout } from "../../redux/actions";
import { connect, useDispatch } from "react-redux";
import { UserData } from "../../app/model/user";
import LoginImg from "../../assets/img/profile-icon.png";
import { Modal } from "react-bootstrap";

interface Props {
  UserReducer?: any;
  loginUserId?: (data: any) => void;
  LoginUserIdReducer?: any;
  logout?: (data: any) => void;
}

const DashboardPage: React.FC<Props> = (props) => {
  const [show, setShow] = React.useState(false);
  const location = useLocation();
  const [loginUserData, setLoginUserData] = useState<any[] | []>([]);
  const loginUserImage = sessionStorage.getItem("loginUserImage");
  const [profileImage, setProfileImage] = useState("");
  const [isUserLogin, setUserLogin] = useState(
    sessionStorage.getItem("isLogin") || ""
  );
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    const _id = sessionStorage.getItem("isLogin") || "";
    if (_id) {
      UserService.getUser(_id).then((res) => {
        if (res) {
          sessionStorage.setItem("userImages", "");
          setLoginUserData(res);
          if (res.length > 0) {
            sessionStorage.setItem("userImages", res[0]?.image);
          }
        }
      });
    }
  }, []);
  const handleLogoutClick = () => {
    setUserLogin("");
    if (props.logout) {
      const data = { loginPopup: false, popupType: "" };
      dispatch(props.logout(data));
    }
    sessionStorage.setItem("isLogin", "");
    sessionStorage.setItem("token", "");
    if (props.loginUserId) {
      dispatch(props.loginUserId(null));
    }
    sessionStorage.clear();
    localStorage.clear();
    history.push("/");
  };

  return (
    <Fragment>
      <AppHeader />
      <ScrollToTop />

      <div
        className={`main-content-sec ${location.pathname.includes("myclubs") ||
            location.pathname.includes("purchasedtickets")
            ? ""
            : "static-page"
          }  ${show ? "" : "active"}`}
      >
        {/* {!location.pathname.includes("settings") && ( */}
        {
          <div className={`d-none d-lg-flex sidebar ${show ? "" : "active"}`}>
            <a className="sidebar-toggle" onClick={() => setShow(!show)}>
              <i className="bi bi-list"></i>
            </a>
            <a className="sidebar-logo" href="">
              <img src={Logo} />
            </a>
            {/* <div className="sidebar-profile-info text-center mb-4">
              <img
                className="rounded-circle mb-2"
                src={
                  loginUserImage
                    ? loginUserImage
                    : props?.UserReducer?.UserData &&
                      props?.UserReducer?.UserData[0]?.image
                    ? props?.UserReducer?.UserData[0]?.image
                    : profileImage
                    ? profileImage
                    : { Profile }
                }
                alt="profile"
              />
              <h3>{loginUserData[0]?.name}</h3>
            </div> */}
            {
              <>
                <ul className="main-menu">
                  {loginUserData[0]?.club_influencer && (
                    <>
                      <li className="menu-main">
                        <NavLink to="/dashboard/maindashboard">
                          <span className="menu-text">Dashboard</span>
                        </NavLink>
                      </li>
                      <li className="menu-main">
                        <NavLink to="/dashboard/mylivestreams/scheduled">
                          <span className="menu-text">Livestream</span>
                        </NavLink>
                      </li>
                      <li className="menu-main">
                        <NavLink to="/dashboard/purchasedtickets/upcoming">
                          <span className="menu-text">Tickets</span>
                        </NavLink>
                      </li>
                      {/* <li className="menu-main">
                        <NavLink to="/dashboard/mynft/selling">
                          <span className="menu-text">My NFT</span>
                        </NavLink>
                      </li> */}
                      {/* <li>
                        <NavLink to="/dashboard/nft">
                          <i className="bi bi-camera-video"></i>
                          <span className="menu-text">NFT</span>
                        </NavLink>
                      </li> */}
                      {/* <li>
                        <NavLink to="/dashboard/creations">
                          <i className="bi bi-camera-video"></i>
                          <span className="menu-text">Creations</span>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/dashboard/myfans">
                          <i className="bi bi-camera-video"></i>
                          <span className="menu-text">My Fans</span>
                        </NavLink>
                      </li> */}
                      {/* <li>
                        <NavLink to="/dashboard/nft/created">
                          <span className="menu-text">NFT Owned</span>
                        </NavLink>
                      </li> */}
                      {/* <li>
                      <NavLink to="/dashboard/requests">
                        <i className="bi bi-camera-video"></i>
                        <span className="menu-text">Requests</span>
                      </NavLink>
                    </li> */}
                      {/* <li>
                      <NavLink to="/dashboard/myclubs/details">
                        <i className="icon-my-club"></i>
                        <span className="menu-text">My Club</span>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/dashboard/post">
                        <i className="icon-post"></i>
                        <span className="menu-text">Create Content</span>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/dashboard/posts/published">
                        <i className="icon-all-post"></i>
                        <span className="menu-text">Posts</span>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/dashboard/mylivestreams/drafts">
                        <i className="bi bi-camera-video"></i>
                        <span className="menu-text">Livestreams</span>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/dashboard/subscribers/list">
                        <i className="icon-subscribers"></i>
                        <span className="menu-text">Subscribers</span>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/dashboard/subscribers/inbox">
                        <i className="bi bi-camera-video"></i>
                        <span className="menu-text">Inbox</span>
                      </NavLink>
                    </li> */}
                    </>
                  )}

                  {/* <li>
                  <NavLink to="">
                    <span className="menu-text">*******************</span>
                  </NavLink>
                </li> */}
                  {!loginUserData[0]?.club_influencer && (
                    <>
                      <li>
                        <NavLink to="/dashboard/purchasedtickets/upcoming">
                          <span className="menu-text">My Tickets</span>
                        </NavLink>
                      </li>

                      {/* <li>
                        <NavLink to="/dashboard/mysubscriptions/subscriptions">
                          <i className="bi bi-people"></i>
                          <span className="menu-text">Subscriptions</span>
                        </NavLink>
                      </li> */}
                      {/* <li>
                        <NavLink to="/dashboard/mysubscriptions/inbox">
                          <i className="bi bi-people"></i>
                          <span className="menu-text">My Inbox</span>
                        </NavLink>
                      </li> */}
                      {/* <li>
                        <NavLink to="/dashboard/mynft">
                          <span className="menu-text">My NFT</span>
                        </NavLink>
                      </li> */}
                    </>
                  )}

                  {loginUserData[0]?.is_finance && (
                    <li>
                      <NavLink to="/admin/signups" target="_blank">
                        <span className="menu-text">Admin</span>
                      </NavLink>
                    </li>
                  )}
                </ul>

                <div className="sidebar-bottom-menu">
                  <ul className="main-menu">
                    {loginUserData[0]?.club_influencer && (
                      <>
                        <li>
                          <NavLink
                            className="dropdown-item"
                            to={`/livestreams/more-livestream/${loginUserData[0]?._id}/ls`}
                            target="_blank"
                          >
                            My Profile
                          </NavLink>
                        </li>


                      </>
                    )}

                    {/* <li>
                        <NavLink to="asd">
                          <span className="menu-text">Send Feedback</span>
                        </NavLink>
                      </li> */}

                    <li>
                      <NavLink to="/dashboard/settings">
                        <span className="menu-text">Settings</span>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/support" target="_blank">
                        <span className="menu-text">Contact us</span>
                      </NavLink>
                    </li>
                    <li>
                      <a
                        href="javascript:void(0)"
                        className="dropdown-item"
                        onClick={handleLogoutClick}
                      >
                        Logout
                      </a>
                    </li>
                  </ul>
                </div>
              </>
            }
          </div>
        }

        <DashboardRoutes></DashboardRoutes>
        {/* <MyClubMenuMobile /> */}
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state: any) => {
  return {
    UserReducer: state.UserReducer,
    LoginPopupReducer: state.LoginPopupReducer,
    LoginUserIdReducer: state.LoginUserIdReducer,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    logout: () => dispatch(UserLogout()),
    login: (data: UserData) => dispatch(UserLogin(data)),
  };
};
const connectedNavBar = connect(
  mapStateToProps,
  mapDispatchToProps
)(DashboardPage);
export { connectedNavBar as DashboardPage };
