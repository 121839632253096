import { createStore, applyMiddleware, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import { fetchInfo, GetClubReducer, LoadingReducer, LoginPopupReducer, LoginUserIdReducer, PostCountReducer, SaveClubReducer, UserReducer } from './user-data/reducer';

export const store = createStore(combineReducers(
    {
        UserReducer: UserReducer,
        LoadingReducer: LoadingReducer,
        LoginPopupReducer: LoginPopupReducer,
        SaveClubReducer: SaveClubReducer,
        GetClubReducer: GetClubReducer,
        LoginUserIdReducer: LoginUserIdReducer,
        PostCountReducer: PostCountReducer,
        fetchInfo:fetchInfo,
        // getInfo:getInfo,
        
    }
), applyMiddleware(thunk));
