import React, { useState, useEffect } from "react";
import { Clubs } from "../../app/model/clubs";
import FeatureImg1 from "../../assets/img/dummy-img.svg";
import { Link, NavLink } from "react-router-dom";
import { ClubService } from "../../app/service/club.service";
import { Loading } from "../../redux/actions";
import { connect, useDispatch } from "react-redux";
import Slider from "react-slick";
interface Props {
  loading?: (data: any) => void;
  vehicle: {};
}

const AllClubs: React.FC<Props> = (props: Props) => {
  const [clubs, setClubs] = useState<Clubs[] | []>([]);
  const [loading, setloading] = useState(false);
  const dispatch = useDispatch();

  const getMyClub = () => {
    ClubService.getClubs().then((res) => {
      if (res) {
        setClubs(res);
        setloading(false);
        if (props.loading) {
          dispatch(props.loading(false));
        }
      } else {
        setloading(false);
        if (props.loading) {
          dispatch(props.loading(false));
        }
      }
    });
  };
  useEffect(() => {
    setloading(true);
    getMyClub();
  }, []);
  const settings = {
    className: "slider variable-width",
    dots: false,
    infinite: true,
    variableWidth: true,
    draggable: true,
  };

  // For Category List
  let array1: any = [];

  let counts: any = {};

  let categoryList = clubs.map((el) => {
    if (el.genre?.includes(",") || el.genre === undefined) {
      el.genre?.split(",").map((el2: string, count: number) => {
        return array1.push(el2.trim());
      });
    } else {
      array1.push(el.genre.trim());
    }
  });
  let uniqueChars = array1.filter((c: string, index: number) => {
    return array1.indexOf(c) === index;
  });

  // let filteredCategory:[] = [];

  // let uniqueCate = clubs.map((ele:any) => {
  //   if(ele.genre?.includes(",")){
  //     return ele.genre?.split(",").filter((cate:any) => cate!== cate?.genre)
  //   }
  // })

  const allCategories: any = ["All", ...Array.from(new Set(array1))];

  let coun: any = {};
  let count = array1.forEach(function (x: number) {
    counts[x] = (counts[x] || 0) + 1;
  });
  var duplicateCount: any = {};
  array1.forEach((e: any) => duplicateCount[e] = duplicateCount[e] ? duplicateCount[e] + 1 : 1);
  var result = Object.keys(duplicateCount).map(e => { return { key: e, count: duplicateCount[e] } });


  // let combined =  allCategories.map((list:any, result:any)=>{
  //   return <li><Link to={`#${list}`}>{list}{result}</Link></li>
  // })
  const [menuItems, setMenuItems] = useState(clubs);
  // const [categories, setCategories] = useState(allCategories);
  const filterItems = (category: any) => {
    if (category === "All") {
      setMenuItems(clubs);
      return;
    }
    const filteredGenre: any = [];
    const newCategories: any = clubs?.filter((item: any) => item.genre?.includes(category) ? item?.genre?.split(category).map((el3: any) => filteredGenre.push(el3?.genre)) : filteredGenre.push(item?.genre) === category)

    setMenuItems(newCategories);

  };

  return (
    <section className="club-list category-list">
      <div className="category-listing sidebar">
        <ul className="main-menu">
          {allCategories.map((category: any) => {
            return (
              <li>
                <a
                  href="javascript:void(0)"
                  onClick={() => filterItems(category)}
                >
                  {category}

                </a>

              </li>
            );
          })}
        </ul>
      </div>
      <div className="category-listing-main-content">
        <h2 className="home-sec-title mb-5 pt-5">CREATORS</h2>
        <div className="category-slider row">
          {menuItems?.map((club, index) => {
            return (
              <div className="col-md-3">
                <div className="featured-card mw-100 me-0" key={club._id}>
                  <div className="featured-card__left">
                    <NavLink
                      className="featured-card__left-img ratio ratio-1x1"
                      to={`/clubs/${club._id}`}
                    >
                      <img
                        src={club.cover_photo ? club.cover_photo : FeatureImg1}
                        alt={club.name}
                        className="mw-100"
                      />
                    </NavLink>
                  </div>
                  <div className="featured-card__right">
                    <NavLink
                      to={`/clubs/${club._id}`}
                      className="featured-card__title"
                    >
                      {club.name}
                    </NavLink>
                    <div className="featured-card__handle">
                      @{club.user_info[0]?.name}
                    </div>
                    <ul className="featured-card__post-info">
                      <li>
                        <strong>{club.total_posts}</strong> Posts
                      </li>
                      <li>
                        <strong>{club.total_subscribers}</strong> Subscribers
                      </li>
                    </ul>
                  </div>
                </div>

                {/* <li key={index}>
                    <NavLink
                      to={`/clubs/${club._id}`}
                      key={club._id}
                      className="featured-club-img"
                    >
                      <img
                        src={club.cover_photo ? club.cover_photo : FeatureImg1}
                        alt={club.name}
                      />
                      <div className="featured-club-caption">
                        <img
                          src={
                            club.user_info[0]?.image
                              ? club.user_info[0]?.image
                              : FeatureImg1
                          }
                        />
                        <h3>{club.name}</h3>
                        <h4>@{club.user_info[0]?.name}</h4>
                        <ul>
                          <li>{club.total_posts} Posts</li>
                          <li>{club.total_subscribers} Subscribers</li>
                        </ul>
                      </div>
                    </NavLink>
                  </li> */}
              </div>
            );
          })}
          {/* <div className="featured-card">
            <div className="featured-card__left">
              <div className="featured-card__left-img">
                <img src="images/feature-img.jpg" />
              </div>
            </div>
            <div className="featured-card__right">
              <div className="featured-card__title">Crazy Vacations</div>
              <div className="featured-card__handle">@shenaztreasury</div>
              <ul className="featured-card__post-info">
                <li>
                  {" "}
                  <strong>231</strong>Posts
                </li>
                <li>
                  {" "}
                  <strong>42</strong>Fans
                </li>
              </ul>
            </div>
          </div>
          <div className="featured-card">
            <div className="featured-card__left">
              <div className="featured-card__left-img">
                <img src="images/feature-img1.jpg" />
              </div>
            </div>
            <div className="featured-card__right">
              <a className="featured-card__title" href="category-detail.html">
                Crazy Vacations
              </a>
              <div className="featured-card__handle">@shenaztreasury</div>
              <ul className="featured-card__post-info">
                <li>
                  {" "}
                  <strong>231</strong>Posts
                </li>
                <li>
                  {" "}
                  <strong>42</strong>Fans
                </li>
              </ul>
            </div>
          </div>
          <div className="featured-card">
            <div className="featured-card__left">
              <div className="featured-card__left-img">
                <img src="images/feature-img2.jpg" />
              </div>
            </div>
            <div className="featured-card__right">
              <div className="featured-card__title">Crazy Vacations</div>
              <div className="featured-card__handle">@shenaztreasury</div>
              <ul className="featured-card__post-info">
                <li>
                  {" "}
                  <strong>231</strong>Posts
                </li>
                <li>
                  {" "}
                  <strong>42</strong>Fans
                </li>
              </ul>
            </div>
          </div>
          <div className="featured-card">
            <div className="featured-card__left">
              <div className="featured-card__left-img">
                <img src="images/feature-img.jpg" />
              </div>
            </div>
            <div className="featured-card__right">
              <div className="featured-card__title">Crazy Vacations</div>
              <div className="featured-card__handle">@shenaztreasury</div>
              <ul className="featured-card__post-info">
                <li>
                  {" "}
                  <strong>231</strong>Posts
                </li>
                <li>
                  {" "}
                  <strong>42</strong>Fans
                </li>
              </ul>
            </div>
          </div>
          <div className="featured-card">
            <div className="featured-card__left">
              <div className="featured-card__left-img">
                <img src="images/feature-img1.jpg" />
              </div>
            </div>
            <div className="featured-card__right">
              <div className="featured-card__title">Crazy Vacations</div>
              <div className="featured-card__handle">@shenaztreasury</div>
              <ul className="featured-card__post-info">
                <li>
                  {" "}
                  <strong>231</strong>Posts
                </li>
                <li>
                  {" "}
                  <strong>42</strong>Fans
                </li>
              </ul>
            </div>
          </div>
          <div className="featured-card">
            <div className="featured-card__left">
              <div className="featured-card__left-img">
                <img src="images/feature-img2.jpg" />
              </div>
            </div>
            <div className="featured-card__right">
              <div className="featured-card__title">Crazy Vacations</div>
              <div className="featured-card__handle">@shenaztreasury</div>
              <ul className="featured-card__post-info">
                <li>
                  {" "}
                  <strong>231</strong>Posts
                </li>
                <li>
                  {" "}
                  <strong>42</strong>Fans
                </li>
              </ul>
            </div>
          </div>
          <div className="featured-card">
            <div className="featured-card__left">
              <div className="featured-card__left-img">
                <img src="images/feature-img.jpg" />
              </div>
            </div>
            <div className="featured-card__right">
              <div className="featured-card__title">Crazy Vacations</div>
              <div className="featured-card__handle">@shenaztreasury</div>
              <ul className="featured-card__post-info">
                <li>
                  {" "}
                  <strong>231</strong>Posts
                </li>
                <li>
                  {" "}
                  <strong>42</strong>Fans
                </li>
              </ul>
            </div>
          </div> */}
        </div>
      </div>
    </section>
  );
};
const mapDispatchToProps = (dispatch: any) => {
  return {
    loading: (data: any) => dispatch(Loading(data)),
  };
};
const connectedNavBar = connect(mapDispatchToProps)(AllClubs);
export { connectedNavBar as AllClubs };

export default AllClubs;
