import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
import TimePicker from "@mui/lab/TimePicker";
import Switch from "@mui/material/Switch";
import { Link, NavLink, useHistory, useParams } from "react-router-dom";
import livestreamImage from "../../../assets/img/livestream-detail-img.png";
import BannerImage from "../../../assets/img/livestream-detail-img.png";
import dummyImg from "../../assets/img/dummy-img.svg";
import moment from "moment";
import { UserService } from "../../app/service/user.service";
import { ClubService } from "../../app/service/club.service";
import { LivestreamService } from "../../app/service/livestream";
import { S3Helper } from "../../app/utils/s3-helper";
import { toast } from "react-toastify";
import { Modal } from "react-bootstrap";
import {
  Checkbox,
  FormControl,
  Select,
  InputLabel,
  OutlinedInput,
  ListItemText,
  SelectChangeEvent,
  FormControlLabel,
} from "@mui/material";
import { TooltipWrapper } from "../../components/tooltip";
import { NftService } from "../../app/service/nft.service";
import useRazorpay, { RazorpayOptions } from "react-razorpay";
import { CLOUDFRONT_URL, RAZORPAY_KEY, S3_URL } from "../../config/constant";
const names = [
  "Oliver Hansen",
  "Van Henry",
  "April Tucker",
  "Ralph Hubbard",
  "Omar Alexander",
  "Carlos Abbott",
  "Miriam Wagner",
  "Bradley Wilkerson",
  "Virginia Andrews",
  "Kelly Snyder",
];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const steps = ["Basic Details", "Pay & Confirm"];
const switchLabel = { inputProps: { "aria-label": "Switch demo" } };

export default function CreateNFT() {
  let { id } = useParams<{ id: string }>();
  const [activeStep, setActiveStep] = React.useState(0);
  const [oncheckedSwitch, setOncheckedSwitch] = React.useState(false);
  const [videoUrl, setVideoUrl] = useState("");
  const baseUrl = S3_URL;
  const [content, setContent] = useState("");
  const [showThumnail, setShowThumnail] = useState("");
  const [skipped, setSkipped] = React.useState(new Set<number>());
  const [dateValue, setDateValue] = React.useState<Date | null>(new Date());
  const [timeValue, setTimeValue] = React.useState<Date | null>(new Date());
  const [count, setCount] = useState(1);
  const [forMintRes, setForMintRes] = useState({});

  const countInc = () => {
    setCount(count + 1);
  };
  const history = useHistory();
  const countDec = () => {
    if (count <= 0) {
      alert("No Negative");
      return;
    }
    setCount(count - 1);
  };
  const [loginUserData, setLoginUserData] = useState<any[] | []>([]);
  const [createdLivestream, setCreatedLivestream] = useState<any>({});

  let userId = sessionStorage.getItem("isLogin") || "";
  const club_id = sessionStorage.getItem("clubId") || "";
  const [awsInfo, setAwsInfo] = useState<any>(null);
  const [progress, setProgress] = useState(0);
  const [price, setPrice] = React.useState("");
  const [title, setTitle] = React.useState("");
  const [gasFee, setGasFee] = React.useState("");
  const [ticketPrice, setTicketPrice] = React.useState("");
  const [NFTCopies, setNFTCopies] = React.useState("");
  const [textareaValue, setTextareaValue] = React.useState("");
  const [livestreamDescValue, setLivestreamDescValue] = React.useState("");
  const [selectedLivestream, setSelectedLivestream] = useState<any[] | []>([]);
  const [titleError, setTitleError] = React.useState("");
  const [regularPriceError, setRegularPriceError] = React.useState("");
  const [uploadError, setUploadError] = React.useState("");
  const [gasFeeError, setGasFeeError] = React.useState("");
  const [NFTCopiesError, setNFTCopiesError] = React.useState("");
  const [razorpay_payment_id, setRazorpayPaymentId] = React.useState("");



  const [nftCheck, setNftCheck] = useState(false);

  const Razorpay = useRazorpay();

  const [amount, setAmount] = useState<any>("");

  const RAZORPAY_OPTIONS: RazorpayOptions = {
    key: RAZORPAY_KEY,// "rzp_test_DsjVVApo8X8BCB",
    amount: amount + "000",
    currency: "INR",
    name: "Club clan",
    description: "Test Transaction",
    image: "",
    order_id: "",
    prefill: {
      name: "",
      email: "",
      contact: "",
    },
    notes: {
      address: "Razorpay Corporate Office",
    },
    theme: {
      color: "#3399cc",
    },
  };

  useEffect(() => {
    const _id = sessionStorage.getItem("isLogin") || "";
    if (_id) {
      UserService.getUser(_id).then((res) => {
        if (res) {
          sessionStorage.setItem("userImages", "");
          setLoginUserData(res);
          if (res.length > 0) {
            sessionStorage.setItem("userImages", res[0]?.image);
          }
        }
      });
    }

    if (club_id) {
      const data = {
        club_id: club_id,
        type: 1,
      };
      LivestreamService.getLiveStreamFolderInfo(data).then((res) => {
        if (res) {
          setAwsInfo(res);
        } else {
          toast.error(res?.message);
        }
      });
    }

  }, []);

  const isStepOptional = (step: number) => {
    return step === 1;
  };

  const isStepSkipped = (step: number) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    setTitleError("");
    setRegularPriceError("");
    setUploadError('');
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }
    // if (activeStep === 2) {
    //   history.push('/dashboard/mylivestreams/scheduled');
    // }
    console.log(videoUrl, 'videoUrlvideoUrlvideoUrl')
    if (activeStep === 0 && title && ticketPrice && videoUrl && NFTCopies) {
      const data = {
        no_of_copies: NFTCopies ? NFTCopies : '',
        asset_url: videoUrl
      }
      NftService.forMint(data).then(
        res => {
          setGasFee(res.fee < 1 ? 1 : res.fee);
          setForMintRes(res)
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
          setSkipped(newSkipped);
        })
    } else {
      if (!title) {
        setTitleError("Please enter title of the NFT");
      }
      if (!ticketPrice) {
        setRegularPriceError("Please enter NFT price");
      }
      if (!NFTCopies) {
        setNFTCopiesError("Please enter no of copies");
      }
      if (!videoUrl) {
        setUploadError("Please upload image / video")
      }
    }
  };
  const handleFinish = () => {
    if (activeStep === 1 && title && ticketPrice && videoUrl && NFTCopies && gasFee) {
      if (userId) {
        const amount = gasFee;
        if (Number(amount) > 0) {
          RAZORPAY_OPTIONS.amount = amount + "00";
          RAZORPAY_OPTIONS.handler = (response: any) => {
            setRazorpayPaymentId(response.razorpay_payment_id);
            createNft(response.razorpay_payment_id)
          };
          let razorpay = new Razorpay(RAZORPAY_OPTIONS);
          razorpay.open();
          razorpay.on("payment.failed", function (response: any) { });
        } else {
        }
      } else {
        toast.error("Please Login");
      }
    }
  }

  const createNft = (paymentId: string) => {
    const data = {
      user_id: userId,
      no_of_copies: NFTCopies ? NFTCopies : '',
      asset_url: videoUrl,
      title: title,
      price_per_copy: ticketPrice,
      payment_ref: paymentId,
      description: livestreamDescValue,
      fee: gasFee,
      mint: forMintRes
    }
    NftService.createNft(data).then(
      res => {
        if (res) {
          history.push('');
        } else {
          toast.error('Faile to create nft');
        }
      })
  }
  const onUploadProgress = (data: any) => {
    const progress = Math.round((data.loaded / data.total) * 100);
    setProgress(progress);
  };

  const onUploadThumbnailPic = async (event: any) => {
    if (event.target.files && event.target.files[0]) {
      setContent(event.target.files[0]);
      ClubService.signedCall({
        fileName: `${awsInfo?.folderPath}/${event.target.files[0]?.name}`,
        fileType: event.target.files[0]?.type
      }).then(async res => {
        console.log(res, 'signed Response');
        const result = await S3Helper.uploadFilesToS3BySigned(res,
          event.target.files[0],
          event.target.files[0]?.type
        );
        console.log("result", result);
        // if (props.loading) {
        //   dispatch(props.loading(false));
        // }
        setShowThumnail(`${CLOUDFRONT_URL}${awsInfo?.folderPath}/${event.target.files[0]?.name}`);
        toast.success("Uploaded Successfully");
      })
      // const result = await S3Helper.uploadFile(
      //   event.target.files[0],
      //   onUploadProgress,
      //   awsInfo
      // );
      // setVideoUrl(`${baseUrl}${awsInfo.folderPath}/${event.target.files[0].name}`);
      // setShowThumnail(
      //   `${baseUrl}${awsInfo.folderPath}/${event.target.files[0].name}`
      // );
      // toast.success("Uploaded Successfully");
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const onUploadContent = async (event: any) => {
    setVideoUrl("");
    setProgress(0);
    if (event.target.files && event.target.files[0]) {
      setContent(event.target.files[0]);
      const result = await S3Helper.uploadFile(
        event.target.files[0],
        onUploadProgress,
        awsInfo
      );
      console.log(result, 'resultresultresult', `${baseUrl}${awsInfo.folderPath}/${event.target.files[0].name}`)
      setVideoUrl(
        `${baseUrl}${awsInfo.folderPath}/${event.target.files[0].name}`
      );
      toast.success("Uploaded Successfully");
    }
  };

  const handleTextareaChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTextareaValue(event.target.value);
  };
  const handleLivestreamDescChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setLivestreamDescValue(event.target.value);
  };
  const [modalState, setModalState] = useState<
    "modal-one" | "modal-two" | "close"
  >("close");

  const handleChangeSwitch = (event: any) => {
    setOncheckedSwitch(!oncheckedSwitch);
  };
  const onBack = () => {
    history.goBack();
  };

  const handleClose = () => {
    setModalState("close");
  };

  const handleShowModalTwo = () => {
    setModalState("modal-two");
  };

  const onChangeTitle = (event: any) => {
    setTitleError("");
    if (event.target.value) {
      if (!event.target.value.trim()) {
        setTitle(event.target.value.trim());
        setTitleError('Enter only alphanumeric');
      } else {
        setTitle(event.target.value);
      }
    } else {
      setTitle("");
      setTitleError("Please enter title of the NFT");
    }
  };
  const onChangePrice = (event: any) => {
    setRegularPriceError("");
    if (event.target.value && event.target.value.length <= 4 && event.target.value >= 1) {
      // setTicketPrice(event.target.value);
      const re = /^[0-9\b]+$/;
      if (re.test(event.target.value) && event.key !== ".") {
        setTicketPrice(event.target.value);
      }
    } else {
      if (!event.target.value || !(event.target.value >= 1)) {
        setTicketPrice("");
        setRegularPriceError("Please enter NFT price");
      }
    }
  };
  const onChangeNFTCopies = (event: any) => {
    setNFTCopiesError("");
    if (event.target.value && event.target.value.length <= 4 && event.target.value >= 1) {
      // setNFTCopies(event.target.value);
      const re = /^[0-9\b]+$/;
      if (re.test(event.target.value) && event.key !== ".") {
        setNFTCopies(event.target.value);
      }
    } else {
      if (!event.target.value || !(event.target.value >= 1)) {
        setNFTCopies("");
        setNFTCopiesError("Please enter no of copies");
      }
    }
  };

  const [personName, setPersonName] = React.useState<string[]>([]);

  const handleChange = (event: SelectChangeEvent<typeof personName>) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const handleCheckBoxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNftCheck(event.target.checked);
  };
  const onChangeGasFee = (event: any) => {
    setGasFeeError("");
    if (event.target.value) {
      setGasFee(event.target.value);
    } else {
      setGasFee("");
      setGasFeeError("Please enter gas fee");
    }
  };

  const [show, setShow] = useState(false);

  const handlePreviewClose = () => setShow(false);
  const handlePreviewShow = () => setShow(true);

  return (
    <>
      <section className="create-livestream nft">
        <div className="container-fluid px-0">
          <div className="create-livestream-steps">
            <div className="create-livestream-left pb-4">
              <div className="d-flex flex-wrap flex-column flex-sm-row">
                <h3>
                  <span className="pointer" onClick={onBack}>
                    <i className="bi bi-arrow-left"></i>
                  </span>
                  {id !== "0" ? "Create an NFT" : "Create an NFT"}
                </h3>
                <Stepper className="ms-auto" activeStep={activeStep}>
                  {steps.map((label, index) => {
                    const stepProps: { completed?: boolean } = {};
                    const labelProps: {
                      optional?: React.ReactNode;
                    } = {};
                    // if (isStepOptional(index)) {
                    //   labelProps.optional = (
                    //     <Typography variant="caption">Optional</Typography>
                    //   );
                    // }
                    if (isStepSkipped(index)) {
                      stepProps.completed = false;
                    }
                    return (
                      <Step key={label} {...stepProps}>
                        <StepLabel {...labelProps}>
                          {/* <span className="d-block step-number">
                            Step {index + 1}
                          </span>{" "} */}
                          {label}
                        </StepLabel>
                      </Step>
                    );
                  })}
                </Stepper>
              </div>
              <Box sx={{ width: "100%" }}>
                {activeStep === steps.length ? (
                  <React.Fragment>
                    <Typography sx={{ mt: 2, mb: 1 }}>
                      All steps completed - you&apos;re finished
                    </Typography>
                    <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                      <Box sx={{ flex: "1 1 auto" }} />
                      <Button onClick={handleReset}>Reset</Button>
                    </Box>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <div className="create-livestream-step">
                      {activeStep === 0 && (
                        <Typography sx={{ mt: 2, mb: 1 }}>
                          <div className="col-md-12">
                            <div className="row mb-3">
                              <div className="col-md-12">
                                <TextField
                                  fullWidth
                                  id="outlined-basic"
                                  label="Title of the NFT"
                                  variant="outlined"
                                  margin="normal"
                                  value={title}
                                  onChange={(event) => onChangeTitle(event)}
                                />
                                {titleError && (
                                  <p
                                    style={{
                                      color: "red",
                                      marginBottom: "0",
                                      fontSize: "14px",
                                    }}
                                  >
                                    {titleError}
                                  </p>
                                )}
                              </div>
                            </div>
                            <div className="row mb-0 mb-md-3">
                              <div className="col-md-6 mb-3 mb-md-0">
                                <TextField
                                  fullWidth
                                  id="outlined-basic"
                                  label="NFT Price per copy"
                                  variant="outlined"
                                  // type="number"
                                  value={ticketPrice}
                                  onChange={(event) => onChangePrice(event)}
                                />

                                {regularPriceError && (
                                  <p
                                    style={{
                                      color: "red",
                                      marginBottom: "0",
                                      fontSize: "14px",
                                    }}
                                  >
                                    {regularPriceError}
                                  </p>
                                )}
                              </div>
                              <div className="col-md-6 mb-3 mb-md-0">
                                <TextField
                                  fullWidth
                                  id="outlined-basic"
                                  label="Number of copies"
                                  variant="outlined"
                                  // type="number"
                                  value={NFTCopies}
                                  onChange={(event) => onChangeNFTCopies(event)}
                                />

                                {NFTCopiesError && (
                                  <p
                                    style={{
                                      color: "red",
                                      marginBottom: "0",
                                      fontSize: "14px",
                                    }}
                                  >
                                    {NFTCopiesError}
                                  </p>
                                )}
                              </div>
                            </div>
                            <div className="col-md-12 mb-3">
                              <TextField
                                id="livestream-desc"
                                label="NFT Description"
                                multiline
                                rows={6}
                                value={livestreamDescValue}
                                fullWidth
                                onChange={handleLivestreamDescChange}
                                margin="normal"
                              />
                            </div>
                            <div className="col-md-12">
                              <div className="upload-sec custom">
                                <div className="outer-btn button_outer1">
                                  <div className="btn_upload1 upload_btn btn">
                                    <input
                                      id="upload_file1"
                                      type="file"
                                      name="cover_photo"
                                      accept="image/jpg, image/jpeg, image/png, image/svg, image/gif"
                                      onChange={(event) =>
                                        onUploadThumbnailPic(event)
                                      }
                                    />
                                    <i className="bi bi-cloud-arrow-up"></i>
                                    <strong>Upload image or a video </strong>
                                    <span>(drag and drop or browse)</span>
                                    <div className="upload-requirement">
                                      <div className="upload-requirement-left">
                                        <strong className="">
                                          Image requirements
                                        </strong>
                                        <span className="upload-accept-filename">
                                          fileSize: 10 MB
                                          <br />
                                          formats: jpg, jpeg, png, gif <br />
                                          recommendedDimension:1920 w x 500 h
                                        </span>
                                      </div>
                                      <div className="upload-requirement-right">
                                        <strong className="">
                                          Video requirements
                                        </strong>
                                        <span className="upload-accept-filename">
                                          fileSize: 500 MB
                                          <br /> formats: .mov,.mkv, .mpg,
                                          .mpeg,.mp4,.avi, .wmv, .flv, .3gp,
                                          .webm
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="processing_bar"></div>
                                  <div className="success_box"></div>
                                </div>
                                {showThumnail && (
                                  <img
                                    className="my_club_media d-lg-none"
                                    src={showThumnail}
                                    alt=""
                                  />
                                )}
                                <div className="error_msg1 upload_error_msg"></div>
                                <div
                                  className="uploaded_file_view"
                                  id="uploaded_view1"
                                >
                                  <span className="file_remove1 remove_file">
                                    X
                                  </span>
                                </div>
                              </div>
                              {uploadError && (
                                <p
                                  style={{ color: "red", marginBottom: "0" }}
                                >
                                  {uploadError}
                                </p>
                              )}
                            </div>
                          </div>
                        </Typography>
                      )}
                      {activeStep === 1 && (
                        <Typography sx={{ mt: 2, mb: 1 }}>
                          <div className="col-md-12">
                            <strong className="d-block pb-2">
                              Please Confirm
                            </strong>
                            <div className="d-flex flex-column">
                              <span className="d-block ">
                                Title: <strong>{title}</strong>
                              </span>
                              <span className="d-block">
                                NFT:&nbsp; Price: <strong>{ticketPrice}</strong>
                                &nbsp; No of copies:{" "}
                                <strong>{NFTCopies}</strong>{" "}
                              </span>
                            </div>
                          </div>
                          <div className="col-md-12">
                            <TextField
                              fullWidth
                              id="nft-gas-fee"
                              label="Gas Fee"
                              variant="outlined"
                              margin="normal"
                              value={gasFee}
                              disabled
                              onChange={(event) => onChangeGasFee(event)}
                            />
                            {gasFeeError && (
                              <p
                                style={{
                                  color: "red",
                                  marginBottom: "0",
                                  fontSize: "14px",
                                }}
                              >
                                {gasFeeError}
                              </p>
                            )}
                          </div>
                          <button
                            onClick={handlePreviewShow}
                            className="d-lg-none btn btn-outline-black d-inline-flex"
                          >
                            Preview
                          </button>
                          {/* <a href="">Upload a video </a>(optional) */}
                        </Typography>
                      )}
                    </div>
                    <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                      <Button
                        variant="outlined"
                        className="btn btn-outline-orange"
                        color="inherit"
                        disabled={activeStep === 0}
                        onClick={handleBack}
                        sx={{ mr: 1 }}
                      >
                        Back
                      </Button>
                      <Box sx={{ flex: "1 1 auto" }} />
                      {/* {isStepOptional(activeStep) && (
                      <Button
                        color="inherit"
                        onClick={handleSkip}
                        sx={{ mr: 1 }}
                      >
                        Skip
                      </Button>
                    )} */}
                      {activeStep === 0 && (
                        <Button
                          variant="outlined"
                          className="btn btn-outline-black"
                          onClick={handleNext}
                        >
                          {activeStep === 0 && "Next"}
                        </Button>
                      )}
                      {activeStep === 1 && (
                        <Button
                          variant="outlined"
                          className="btn btn-outline-black"
                          onClick={handleFinish}
                        >
                          {activeStep === 1 && "Finish"}
                        </Button>
                      )}
                    </Box>
                  </React.Fragment>
                )}
              </Box>
            </div>
            <div className="create-livestream-right">
              <div
                className="create-livestream-right-bg-img bgImg"
                style={{
                  background: "linear-gradient(to right, #DDEFBB, #FFEEEE)",
                }}
              >
                <h3 className="pt-4 ps-4">Preview</h3>
                <section className="banner-cover livestream align-items-center justify-content-center">
                  <div className="card-nft mw-100 bg-white w-50 mb-5">
                    <div className="card-nft-img">
                      <span className="ratio ratio-1x1">
                        <img
                          src={showThumnail ? encodeURI(showThumnail) : dummyImg}
                          alt="Profile Image"
                          className="mw-100"
                        />
                        {console.log(showThumnail)}
                      </span>
                    </div>
                    <div className="card-nft-content">
                      <span className="card-nft-title">{title}</span>
                      <span className="card-nft-creator">
                        {loginUserData[0]?.name}
                      </span>
                      {ticketPrice && (
                        <div className="card-nft-price">
                          <span className="me-auto">Price: {ticketPrice}</span>
                        </div>
                      )}
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Modal show={show} onHide={handlePreviewClose}>
        <Modal.Header className="py-3" closeButton>
          <Modal.Title className="mb-0">Your NFT Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="card-nft mw-100 bg-white w-50 mx-auto my-5 mb-5 p-0">
            <div className="card-nft-img">
              <span className="ratio ratio-1x1">
                <img
                  src={showThumnail ? showThumnail : dummyImg}
                  alt="Profile Image"
                  className="mw-100"
                />
              </span>
            </div>
            <div className="card-nft-content">
              <span className="card-nft-title">{title}</span>
              <span className="card-nft-creator">{loginUserData[0]?.name}</span>
              {ticketPrice && (
                <div className="card-nft-price">
                  <span className="me-auto">Price: {ticketPrice}</span>
                </div>
              )}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
