import React, { Fragment, useEffect, useState } from "react";
import { Dropdown, DropdownButton } from "react-bootstrap";
import { NavLink, Link } from "react-router-dom";
import { LivestreamService } from "../../../../app/service/livestream";
import ClubsRoutes from "../../../../routes/clubs";
export const MyLivestreamsPage = () => {
  let userId = sessionStorage.getItem("isLogin") || "";
  const [livestreamReport, setLivestreamReport] = useState<any>({});

  useEffect(() => {
    getlivestreamReport();
  }, []);
  const getlivestreamReport = () => {
    LivestreamService.getLiveStreamReportDetails(userId).then((res) => {
      if (res) {
        setLivestreamReport({ ...res });
      }
    });
  };
  return (
    <div className="main-content">
      <div className="container-fluid">
        <div className="main-content-info-sec mb-5">
          <div className="d-flex flex-wrap mb-3">
            <h3 className="col-auto me-auto mb-md-0">Livestream</h3>
            <Link
              to="/livestreams/create-livestream/0"
              className="btn btn-outline-black d-inline-flex col-auto"
            >
              Create
            </Link>
          </div>
          <div className="main-content-info d-none d-md-flex">
            <div className="main-content-info-card">
              <div className="main-content-info-card-content">
                <i className="bi bi-camera-video"></i>
                <div className="main-content-info-card-desc">
                  <span>Total Livestreams</span>
                  <strong>{livestreamReport?.total_live_streams}</strong>
                </div>
              </div>
            </div>
            <div className="main-content-info-card">
              <div className="main-content-info-card-content">
                <i className="bi bi-person"></i>
                <div className="main-content-info-card-desc">
                  <span>Total Attendees</span>
                  <strong>{livestreamReport?.total_attendees}</strong>
                </div>
              </div>
            </div>
            <div className="main-content-info-card">
              <div className="main-content-info-card-content">
                <i className="bi bi-server"></i>
                <div className="main-content-info-card-desc">
                  <span>Total Revenue</span>
                  <strong>{livestreamReport?.total_revenue} INR</strong>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="main-content__top">
          <div className="custom-tab mb-1">
            <ul className="nav nav-pills">
              {/* <li className="nav-item" role="presentation">
                <NavLink
                  className="nav-link"
                  to="/dashboard/mylivestreams/drafts"
                >
                  Drafts
                </NavLink>
              </li> */}
              <li className="nav-item" role="presentation">
                <NavLink
                  className="nav-link"
                  id="scheduled-tab"
                  to="/dashboard/mylivestreams/scheduled"
                >
                  Upcoming
                </NavLink>
              </li>
              <li className="nav-item" role="presentation">
                <NavLink
                  className="nav-link"
                  id="completed-tab"
                  to="/dashboard/mylivestreams/completed"
                >
                  Completed
                </NavLink>
              </li>
              <li className="nav-item" role="presentation">
                <NavLink
                  className="nav-link"
                  id="cancelled-tab"
                  to="/dashboard/mylivestreams/cancelled"
                >
                  Cancelled
                </NavLink>
              </li>

            </ul>
          </div>
          {/* <div className="main-content__top__right">
            <DropdownButton
              id="dropdown-basic-button"
              title="Schedule Livestream"
            >
              <Dropdown.Item href="/#/dashboard/mylivestreams-public/1">
                Public{" "}
              </Dropdown.Item>
              <Dropdown.Item href="/#/dashboard/mylivestreams-private/1">
                Private
              </Dropdown.Item>
            </DropdownButton>
          </div> */}
        </div>
        <div className="tab-content" id="pills-tabContent">
          <div
            className="tab-pane active"
            id="details"
            role="tabpanel"
            aria-labelledby="details-tab"
          >
            <ClubsRoutes></ClubsRoutes>
          </div>
        </div>
      </div>
    </div>
  );
};
