import React from "react";
import {Tooltip, OverlayTrigger} from 'react-bootstrap'

interface TooltipWrapperProps {
    placement?:any;
    tooltipText:any;
    iconName?:any;
}

export const TooltipWrapper:React.FC<TooltipWrapperProps> = (props) => {
  return (
    <>
      <OverlayTrigger
        placement={props.placement}
        delay={{ show: 250, hide: 400 }}
        overlay={<Tooltip>{props.tooltipText}</Tooltip>}
      >
        <span className="ms-1">
          <i className={props.iconName}></i>
        </span>
      </OverlayTrigger>
    </>
  );
};
