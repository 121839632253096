import { useEffect, useState } from "react";
import { LivestreamService } from "../../../app/service/livestream";
import Capture from "../../../assets/img/Capture1.png";

export const MyLivestreamAttendedPage = () => {
  let userId = sessionStorage.getItem('isLogin') || '';
  const [livestream, setLivestream] = useState<any[] | []>([]);

  useEffect(() => {
    getlivestream();
  }, [])

  const getlivestream = () => {
    LivestreamService.getScheduledLiveStreamsByUserID(userId).then(
      (res) => {
        if (res) {
          setLivestream([...res]);
        }
      })
  }
  return (
    <div
      id="subs-liv-attended-tab"
      role="tabpanel"
      aria-labelledby="subs-liv-attended-tab"
    >
      {/* Card Subscription */}
      {/* <div className="card-subscription">
        <div className="card-subscription-left">
          <div className="card-subscription-img">
            <img src={Capture} alt="draft" style={{ height: "150px" }} />
          </div>
          <div className="card-subscription-detail">
            <h3 className="card-subscription-title">
              Travel. Romance. Smiles
              <a href="" className="card-subscription-username">
                @username
              </a>
            </h3>
            <div className="subscription-schedule">
              <span className="subscription-schedule-date">
                Saturday, August 7
              </span>
              <span className="subscription-schedule-time">
                4:30pm - 5:30pm
              </span>
            </div>
            <div className="subscription-detail">
              <span className="subscription-term">Ticket Price:</span>
              <strong className="subscription-price">INR 499</strong>
            </div>

            <div className="subscription-comments">
              <span className="subscription-comment-title">You commented:</span>
              <strong className="subscription-comment-desc">
                Dummy content for the creator's video
              </strong>
            </div>
            <div className="subscription-download-info">
              <div className="subscription-download-info-box">
                <span>View recording</span>
                <span>4 days left</span>
              </div>
              <div className="subscription-download-info-box">
                <span>Download recording</span>
                <span>4 days left</span>
              </div>
            </div>
          </div>
        </div>
        <div className="card-subscription-right">
          <div className="rating-group">
            <input
              disabled
              checked
              className="rating__input rating__input--none"
              name="rating3"
              id="rating3-none"
              value="0"
              type="radio"
            />
            <label
              aria-label="1 star"
              className="rating__label"
              htmlFor="rating3-1"
            >
              <i className="rating__icon rating__icon--star bi bi-star-fill"></i>
            </label>
            <input
              className="rating__input"
              name="rating3"
              id="rating3-1"
              value="1"
              type="radio"
            />
            <label
              aria-label="2 stars"
              className="rating__label"
              htmlFor="rating3-2"
            >
              <i className="rating__icon rating__icon--star bi bi-star-fill"></i>
            </label>
            <input
              className="rating__input"
              name="rating3"
              id="rating3-2"
              value="2"
              type="radio"
            />
            <label
              aria-label="3 stars"
              className="rating__label"
              htmlFor="rating3-3"
            >
              <i className="rating__icon rating__icon--star bi bi-star-fill"></i>
            </label>
            <input
              className="rating__input"
              name="rating3"
              id="rating3-3"
              value="3"
              type="radio"
            />
            <label
              aria-label="4 stars"
              className="rating__label"
              htmlFor="rating3-4"
            >
              <i className="rating__icon rating__icon--star bi bi-star-fill"></i>
            </label>
            <input
              className="rating__input"
              name="rating3"
              id="rating3-4"
              value="4"
              type="radio"
            />
            <label
              aria-label="5 stars"
              className="rating__label"
              htmlFor="rating3-5"
            >
              <i className="rating__icon rating__icon--star bi bi-star-fill"></i>
            </label>
            <input
              className="rating__input"
              name="rating3"
              id="rating3-5"
              value="5"
              type="radio"
            />
          </div>
          <button className="btn btn-outline-orange mt-auto">Delete</button>
        </div>
      </div> */}
      {/* Card Subscription */}
      {/* <div className="card-subscription">
        <div className="card-subscription-left">
          <div className="card-subscription-img">
            <img src={Capture} alt="draft" style={{ height: "150px" }} />
          </div>
          <div className="card-subscription-detail">
            <h3 className="card-subscription-title">
              Travel. Romance. Smiles
              <a href="" className="card-subscription-username">
                @username
              </a>
              <span className="badge rounded-pill ms-3 bg-primary">Private</span>
            </h3>
            <div className="subscription-schedule">
              <span className="subscription-schedule-date">
                Saturday, August 7
              </span>
              <span className="subscription-schedule-time">
                4:30pm - 5:30pm
              </span>
            </div>
            <div className="subscription-detail">
              <span className="subscription-term">Ticket Price:</span>
              <strong className="subscription-price">INR 499</strong>
            </div>

            <div className="subscription-comments">
              <span className="subscription-comment-title">You commented:</span>
              <strong className="subscription-comment-desc">
                Dummy content for the creator's video
              </strong>
            </div>
            <div className="subscription-download-info">
              <div className="subscription-download-info-box">
                <span>View recording</span>
                <span>4 days left</span>
              </div>
              <div className="subscription-download-info-box">
                <span>Download recording</span>
                <span>4 days left</span>
              </div>
            </div>
          </div>
        </div>
        <div className="card-subscription-right">
          <div className="rating-group">
            <input
              disabled
              checked
              className="rating__input rating__input--none"
              name="rating4"
              id="rating4-none"
              value="0"
              type="radio"
            />
            <label
              aria-label="1 star"
              className="rating__label"
              htmlFor="rating4-1"
            >
              <i className="rating__icon rating__icon--star bi bi-star-fill"></i>
            </label>
            <input
              className="rating__input"
              name="rating4"
              id="rating4-1"
              value="1"
              type="radio"
            />
            <label
              aria-label="2 stars"
              className="rating__label"
              htmlFor="rating4-2"
            >
              <i className="rating__icon rating__icon--star bi bi-star-fill"></i>
            </label>
            <input
              className="rating__input"
              name="rating4"
              id="rating4-2"
              value="2"
              type="radio"
            />
            <label
              aria-label="3 stars"
              className="rating__label"
              htmlFor="rating4-3"
            >
              <i className="rating__icon rating__icon--star bi bi-star-fill"></i>
            </label>
            <input
              className="rating__input"
              name="rating4"
              id="rating4-3"
              value="3"
              type="radio"
            />
            <label
              aria-label="4 stars"
              className="rating__label"
              htmlFor="rating4-4"
            >
              <i className="rating__icon rating__icon--star bi bi-star-fill"></i>
            </label>
            <input
              className="rating__input"
              name="rating4"
              id="rating4-4"
              value="4"
              type="radio"
            />
            <label
              aria-label="5 stars"
              className="rating__label"
              htmlFor="rating4-5"
            >
              <i className="rating__icon rating__icon--star bi bi-star-fill"></i>
            </label>
            <input
              className="rating__input"
              name="rating4"
              id="rating4-5"
              value="5"
              type="radio"
            />
          </div>
          <button className="btn btn-outline-orange mt-auto">Delete</button>
        </div>
      </div> */}
    </div>
  );
};
