import React, { Fragment, useRef, useEffect, useState } from "react";
import AppFooter from "../../components/footer";
import dummyImg from "../../assets/img/dummy-img.svg";
import { NavLink, useParams } from "react-router-dom";
import { ClubsPost } from "../../app/model/clubs";
import moment from "moment";
import ScrollToTop from "../../components/scroll-to-top";
import PlaceholderImage from "../../assets/img/image.jpg";
import { User } from "../../app/model/user";
import { ClubService } from "../../app/service/club.service";
import { AppHeader } from "../../components/header";
import { toast } from "react-toastify";
import Slider from "react-slick";

interface Props {
  UserReducerState: any;
}

export const ClubSingle: React.FC<Props> = (props) => {
  let { id, club_id } = useParams<{ id: string; club_id: string }>();
  const [club, setClubs] = useState<ClubsPost[] | []>([]);
  const [clubOtherPosts, setClubOtherPosts] = useState<ClubsPost[] | []>([]);
  const [clubUser, setClubUser] = useState<User[] | []>([]);
  const [subComments, setSubComments] = useState<any[]>([]);
  const [selectedComments, setSelectedComments] = useState(-1);
  const [subSubComments, setSubSubComments] = useState<any[]>([]);
  const [selectedSubComments, setSelectedSubComments] = useState(-1);
  let userId = sessionStorage.getItem("isLogin") || "";
  const [postComments, setPostComments] = useState<any[]>([]);
  const [mainComments, setMainComments] = useState<string>('');

  useEffect(() => {
    document.body.classList.add("innerpage");
    getSelectedClubPost(id, club_id);
    return function cleanup() {
      document.body.classList.remove("innerpage");
    };
  }, []);

  const createClubPostViews = (data: any) => {
    let requestObj = {
      club_id: data.club_id,
      _id: data._id,
      collectionName: data.collectionName
    }
    ClubService.createClubPostViews(requestObj).then((res) => {
    });
  }


  const getSelectedClubPost = (id: string, club_id: string) => {
    ClubService.getClubPosts(id, club_id).then((res) => {
      if (res) {
        if (res.length > 0) {
          res.forEach((element: any) => {
            createClubPostViews(element);
            element.isLikes =
              element.likes.length > 0
                ? element.likes.find((like: any) => {
                  if (like.user_id === userId) {
                    return true;
                  } else {
                    return false;
                  }
                })
                : false;
            if (element.comments.length > 0) {
              element.comments.forEach((mainComment: any) => {
                mainComment.isLikes =
                  mainComment.likes.length > 0
                    ? mainComment.likes.find((mainCommetLike: any) => {
                      if (mainCommetLike.user_id === userId) {
                        return true;
                      } else {
                        return false;
                      }
                    })
                    : false;
              });
            }
          });
          setClubs([...res]);
          setPostComments([...res[0]?.comments]);
          setClubOtherPosts([...res[0]?.other_posts]);
          setClubUser([...res[0]?.user_info]);
        }
      }
    });
  };
  const updatePostLikes = (post: any) => {
    const data = {
      club_id: club_id,
      user_id: sessionStorage.getItem("isLogin") || "",
      _id: post?._id,
      collectionName: post?.collectionName
      // user_name: props.UserReducer.name || ''
    };
    ClubService.updatePostLikes(data).then((res) => {
      if (res) {
        getSelectedClubPost(post?._id, res[0].club_id);
      } else {
        toast.error("Please Login");
      }
    });
  };

  const onSelectPost = (post: ClubsPost) => {
    getSelectedClubPost(post?._id, post?.club_id);
  };
  const onSelectComment = (comments: any, index: number) => {
    setSelectedComments(index);
    setSubSubComments([...[]]);
    const subComments = comments.comments;
    setSubComments([...subComments]);
  };

  const onSelectSubComment = (comments: any, index: number) => {
    setSelectedSubComments(index);
    const subComments = comments.comments;
    setSubSubComments([...subComments]);
  };
  const settings = {
    dots: false,
    arrows: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    adaptiveHeight: true
  };
  const onPostComment = (event: any) => {
    setMainComments(event.target.value);
    if (userId) {
      if (event.key === 'Enter' && !event.shiftKey) {
        if (event.target.value) {
          const data = {
            collectionName: club[0]?.collectionName,
            _id: club[0]?._id,
            club_id: club[0]?.club_id,
            user_id: userId,
            comments: event.target.value
          }
          ClubService.createClubPostComments(data).then((res) => {
            if (res) {
              setMainComments('');
              getSelectedClubPost(id, club_id);
            }
          });
        }
      }
    } else {
      toast.error("Please Login");
    }
  }

  const onPostCommentOneReplay = (event: any, selectedComment: any) => {
    if (userId) {
      if (event.key === 'Enter' && !event.shiftKey) {
        if (event.target.value) {
          const data = {
            collectionName: club[0]?.collectionName,
            _id: club[0]?._id,
            club_id: club[0]?.club_id,
            user_id: userId,
            comments: event.target.value,
            reply_to: selectedComment._id
          }
          ClubService.replyClubPostComments(data).then((res) => {
            if (res) {
              getSelectedClubPost(id, club_id);
              setMainComments('');
            }
          });
        }
      }
    } else {
      toast.error("Please Login");
    }
  }

  const onCommentLike = (post: any) => {
    if (userId) {
      const data = {
        collectionName: club[0]?.collectionName,
        _id: club[0]?._id,
        club_id: club[0]?.club_id,
        user_id: userId,
        comment_id: post._id
      }
      ClubService.likeComments(data).then((res) => {
        if (res) {
          getSelectedClubPost(id, club_id);
        }
      });
    } else {
      toast.error("Please Login");
    }
  }
  const commentsDays = (date: any) => {
    const now = moment();
    const expiration = moment(date);
    const diff = expiration.diff(now);
    const diffDuration = moment.duration(diff);
    return `${diffDuration.days() ? `${diffDuration.days().toString().replace('-', '')} days ago` : ''} ${diffDuration.hours() ? `${diffDuration.hours().toString().replace('-', '')} hours ago` : ''}  ${diffDuration.minutes() ? `${diffDuration.minutes().toString().replace('-', '')} minutes ago` : ''} `;
  }
  return (
    <div>
      <AppHeader />
      <ScrollToTop />
      <main className="main-content-sec static-page">
        <div className="main-content">
          <div className="container container-custom">
            <div className="row post-detail">
              <article className="col-md-8">
                <div className="post-card">
                  <div className="post-card__img text-center">
                    {club[0]?.type === "" && (
                      <img
                        className="mw-100 w-100"
                        src={PlaceholderImage}
                        alt=""
                      />
                    )}
                    {club[0]?.type === "0" && (
                      <video className="mw-100 w-100" controls controlsList="nodownload">
                        <source src={club[0]?.content} type="video/mp4" />
                        Your browser does not support HTML video.
                      </video>
                    )}
                    {club[0]?.type === "1" && (
                      <audio controls>
                        <source src={club[0]?.content} type="audio/mpeg" />
                        Your browser does not support the audio element.
                      </audio>
                    )}

                    {club[0]?.type === "2" &&
                      <Slider className="slider " {...settings}>
                        {JSON.parse(club[0]?.content).map((image: any, index: number) => {
                          return (
                            <section>
                              <div className="slider-item">
                                <div className="slider-item-img ">
                                  <img src={image?.img} alt="" style={{margin:'auto', maxWidth:'100%'}} />
                                </div>
                              </div>
                            </section>
                          );
                        })}
                      </Slider>
                      // JSON.parse(club[0]?.content).map(
                      //   (image: string, index: number) => {
                      //     return (
                      //       <img className="mw-100 w-100" src={image} alt="" />
                      //     );
                      //   }
                      // )
                      // <img
                      //   className="mw-100 w-100"
                      //   src={club[0]?.content}
                      //   alt=""
                      // />
                    }
                    {club[0]?.type === "3" && (
                      <div className="text-post">{club[0]?.content}</div>
                    )}
                  </div>
                  <div className="post-card__body">
                    <h3 className="post-card__title">{club[0]?.title}</h3>
                    <h6>{club[0]?.club_info[0]?.name} - {club[0]?.subscription_name}</h6>
                    <p className="post-card__desc">{club[0]?.description}</p>
                  </div>
                  <div className="post-card__footer">
                    <ul className="post-card__footer-left">
                      <li>
                        <i
                          className={`pointer ${club[0]?.isLikes ? "bi bi-heart clr-red" : "bi bi-heart"
                            } `}
                          onClick={() => updatePostLikes(club[0])}
                        ></i>
                        {club[0]?.likes?.length ? club[0]?.likes?.length : 0}
                      </li>
                      <li>
                        <i className="bi bi-chat"></i>
                        {postComments.length ? postComments.length : 0}
                      </li>
                    </ul>
                    <div className="post-card__footer-right">
                      <small className="post-card__postedInfo">
                        {moment(club[0]?.published_dt).format("lll")} IST
                      </small>
                    </div>
                  </div>
                </div>
                <div className="comment-sec">
                  <span className="comment-count">
                    {postComments.length} comments
                  </span>
                  <div className="comment-box">
                    <div className="comment-box-photo">
                      <img src={clubUser[0]?.image ? clubUser[0]?.image : dummyImg} alt="dummy image" />
                    </div>
                    <div className="comment-add">
                      <textarea className="form-control" placeholder="Add Comments" name="comment" onChange={(event) => onPostComment(event)} onKeyPress={(event) => onPostComment(event)} value={mainComments} />
                    </div>
                  </div>
                  {postComments.map((comment, index) => {
                    return (
                      <div key={index} className="commented-info">
                        <div className="commented-info-left">
                          <img src={comment?.user_info[0]?.image ? comment?.user_info[0]?.image : dummyImg} alt="dummy image" />
                        </div>
                        <div className="commented-info-right">
                          <div className="comment">
                            <div className="comment-name">
                              <a>{comment?.user_info[0]?.name}</a>
                              <span className="comment-date">{commentsDays(comment?.created_at)}</span>
                            </div>
                            <p className="comment-desc">{comment?.comments}</p>
                            <ul className="comment-count-info">
                              <li>
                                <i className={`pointer ${comment?.isLikes ? "bi bi-heart clr-red" : "bi bi-heart"
                                  } `} onClick={() => onCommentLike(comment)}></i>
                                {comment?.likes?.length ? comment.likes?.length : 0}
                              </li>
                              <li>
                                <i className="bi bi-chat pointer"
                                  onClick={() =>
                                    onSelectComment(comment, index)
                                  }
                                ></i>
                                {comment?.replies?.length ? comment?.replies?.length : 0}
                              </li>
                            </ul>
                            {selectedComments === index &&
                              <div className="pt-2">{comment?.replies?.length > 0 ?
                                comment?.replies?.map((subComment: any, subIndex: number) => {
                                  return (
                                    <div
                                      key={subIndex}
                                      className="commented-info"
                                    >
                                      <div className="commented-info-left">
                                        <img src={subComment?.user_info[0]?.image ? subComment?.user_info[0]?.image : dummyImg} alt="dummy image" />
                                      </div>
                                      <div className="commented-info-right">
                                        <div className="comment">
                                          <div className="comment-name">
                                            <a>{subComment?.user_info[0]?.name}</a>
                                            <span className="comment-date">
                                              {commentsDays(subComment?.created_at)}
                                            </span>
                                          </div>
                                          <p className="comment-desc">
                                            {subComment?.comments}
                                          </p>
                                          {/* <ul className="comment-count-info">
                                            <li>
                                              <i className="bi bi-heart pointer"></i>
                                              {subComment?.likes.length ? subComment.likes.length : 0}
                                            </li>
                                            <li>
                                              <i
                                                className="bi bi-chat pointer"
                                                onClick={() =>
                                                  onSelectSubComment(
                                                    subComment,
                                                    subIndex
                                                  )
                                                }
                                              ></i>
                                              {subComment?.replies?.length
                                                ? subComment?.replies?.length
                                                : 0}
                                            </li>
                                          </ul> */}
                                          {subIndex ===
                                            comment?.replies?.length - 1 && (
                                              <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Replay"
                                                defaultValue={mainComments}
                                                onKeyPress={(event) => onPostCommentOneReplay(event, comment)}
                                              />
                                            )}
                                          {selectedSubComments === subIndex &&
                                            subComment.replies.map(
                                              (subSubComment: any, subSubIndex: number) => {
                                                return (
                                                  <div
                                                    key={subIndex}
                                                    className="commented-info"
                                                  >
                                                    <div className="commented-info-left">
                                                      <img
                                                        src={subComment?.user_info[0]?.image ? subComment?.user_info[0]?.image : dummyImg}
                                                        alt="dummy image"
                                                      />
                                                    </div>
                                                    <div className="commented-info-right">
                                                      <div className="comment">
                                                        <div className="comment-name">
                                                          {" "}
                                                          <a href="">
                                                            {subSubComment?.name}
                                                          </a>
                                                          <span className="comment-date">
                                                            2 days ago
                                                          </span>
                                                        </div>
                                                        <p className="comment-desc">
                                                          {subSubComment?.message}
                                                        </p>
                                                        <ul className="comment-count-info">
                                                          <li>
                                                            <i className="bi bi-heart pointer"></i>
                                                            {subSubComment?.likes}
                                                          </li>
                                                          <li>
                                                            <i className="bi bi-chat pointer"></i>
                                                            {subSubComment
                                                              ?.comments?.length
                                                              ? subSubComment
                                                                ?.comments
                                                                ?.length
                                                              : 0}
                                                          </li>
                                                        </ul>
                                                        {subSubIndex ===
                                                          subSubComments.length -
                                                          1 && (
                                                            <input
                                                              type="text"
                                                              className="form-control"
                                                              placeholder="Replay"
                                                            />
                                                          )}
                                                      </div>
                                                    </div>
                                                  </div>
                                                );
                                              }
                                            )}
                                        </div>
                                      </div>
                                    </div>
                                  );
                                }) : <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Replay"
                                  defaultValue={mainComments}
                                  onKeyPress={(event) => onPostCommentOneReplay(event, comment)}
                                />
                              }</div>
                            }
                          </div>
                        </div>
                      </div>
                    );
                  })}
                  {/* <div className="commented-info">
                                        <div className="commented-info-left"><img src={dummyImg} alt="dummy image" /></div>
                                        <div className="commented-info-right">
                                            <div className="comment">
                                                <div className="comment-name"> <a href="">Vishal Krishna</a><span className="comment-date">2 days ago</span></div>
                                                <p className="comment-desc">Comment startes here. Dummy tmet nibh consequat laoreet. Fusce sed mi hendrerit, bibendum mauris vitae, fringilla lacu.</p>
                                                <ul className="comment-count-info">
                                                    <li><i className="bi bi-heart pointer"></i>4</li>
                                                    <li><i className="bi bi-chat pointer"></i>1</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="commented-info">
                                        <div className="commented-info-left"><img src={dummyImg} alt="dummy image" /></div>
                                        <div className="commented-info-right">
                                            <div className="comment">
                                                <div className="comment-name"> <a href="">Lina Mahajan</a><span className="comment-date">1 week ago</span></div>
                                                <p className="comment-desc">Comment startes here. Dummy tmet nibh consequat laoreet. Fusce sed mi hendrerit, bibendum mauris vitae, fringilla lacu.</p>
                                                <ul className="comment-count-info">
                                                    <li><i className="bi bi-heart pointer"></i>3</li>
                                                    <li><i className="bi bi-chat pointer" onClick={() => onSelectComment(4)}></i>4</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div> */}
                </div>
              </article>
              <aside className="col-md-4">
                <div className="recent-posts-sec d-none d-md-block">
                  {/* <article className="post"> */}
                  {clubOtherPosts.map((post, index) => {
                    return (
                      <article key={index} className="post">
                        <div className="card card-post">
                          <div className="card-post__content">
                            <div className="card-post__content-left">
                              <div className="card-post__content-img d-none d-md-flex">
                                {/* <img className="img-fluid" src={sliderImg} alt="dummy image" /> */}
                                {post?.type === "" && (
                                  <img
                                    className="mw-100 w-100"
                                    src={PlaceholderImage}
                                    alt=""
                                  />
                                )}
                                {/* {post?.type === '1' && <img className="mw-100 w-100" src={post?.content} alt="" />}
                                                            {post?.type === '0' &&
                                                                <video className="mw-100 w-100" controls>
                                                                    <source src={post?.content} type="video/mp4" />
                                                                    Your browser does not support HTML video.
                                                                </video>
                                                            } */}
                                {post?.type === "0" && (
                                  <video className="mw-100 w-100" controls controlsList="nodownload">
                                    <source
                                      src={post?.content}
                                      type="video/mp4"
                                    />
                                    Your browser does not support HTML video.
                                  </video>
                                )}
                                {post?.type === "1" && (
                                  <audio controls className="w-100">
                                    <source
                                      src={post?.content}
                                      type="audio/mpeg"
                                    />
                                    Your browser does not support the audio
                                    element.
                                  </audio>
                                )}
                                {post?.type === "2" && (
                                  <img
                                    className="mw-100 w-100"
                                    src={post?.thumbnail}
                                    alt=""
                                  />
                                )}
                                {post?.type === "3" && (
                                  <i
                                    className="bi bi-card-text"
                                    style={{
                                      fontSize: "60px",
                                      top: "-13px",
                                      position: "absolute",
                                      color: "#f56500",
                                    }}
                                  ></i>
                                )}
                              </div>
                              <div className="card-post__content-desc">
                                <h3>
                                  <a onClick={() => onSelectPost(post)} href="javascript:void(0)">
                                    {post?.title}
                                  </a>
                                </h3>
                                <p>
                                  {moment(post?.published_dt).format("lll")} IST
                                </p>
                                <ul className="comment-count-info">
                                  <li>
                                    <i className="bi bi-heart pointer"></i>
                                    {post?.likes?.length || 0}
                                  </li>
                                  <li>
                                    <i className="bi bi-chat pointer"></i>
                                    {post?.comments?.length || 0}
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </article>
                    );
                  })}
                  {clubOtherPosts.length <= 0 && (
                    <p className="text-center">No Posts</p>
                  )}
                  {/* <article className="post">

                                        <div className="card card-post">
                                            <div className="card-post__content">
                                                <div className="card-post__content-left">
                                                    <div className="card-post__content-img d-none d-md-flex"><img className="img-fluid" src={sliderImg1} alt="dummy image" /></div>
                                                    <div className="card-post__content-desc">
                                                        <h3><a href="post-detail.html">Travel ashore</a></h3>
                                                        <p>Aug 7, 4:30pm IST</p>
                                                        <ul className="comment-count-info">
                                                            <li><i className="bi bi-heart pointer"></i>4</li>
                                                            <li><i className="bi bi-chat pointer"></i>1</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </article>
                                    <article className="post">

                                        <div className="card card-post">
                                            <div className="card-post__content">
                                                <div className="card-post__content-left">
                                                    <div className="card-post__content-img d-none d-md-flex"><img className="img-fluid" src={sliderImg2} alt="dummy image" /></div>
                                                    <div className="card-post__content-desc">
                                                        <h3><Link to="/club-detail">Travel ashore</Link></h3>
                                                        <p>Aug 7, 4:30pm IST</p>
                                                        <ul className="comment-count-info">
                                                            <li><i className="bi bi-heart pointer"></i>4</li>
                                                            <li><i className="bi bi-chat pointer"></i>1</li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </article> */}
                </div>
                <div className="profile-info mt-3 d-md-flex d-none">
                  <div className="profile-info-left">
                    <img src={clubUser[0]?.image ? clubUser[0]?.image : dummyImg} alt="dummy" />
                  </div>
                  <div className="profile-info-right">
                    <NavLink to={`/clubs/${club_id}`}>
                      {clubUser[0]?.name}
                    </NavLink>
                    <ul>
                      <li>
                        {" "}
                        <strong>{club[0]?.total_posts} </strong>Posts
                      </li>
                      <li>
                        {" "}
                        <strong>{club[0]?.total_fans} </strong>Fans
                      </li>
                    </ul>
                    {/* <NavLink to="" className="btn btn-primary">
                      Subscribe
                    </NavLink> */}
                  </div>
                </div>
              </aside>
            </div>
          </div>
        </div>
      </main>
      <AppFooter></AppFooter>
    </div>
  );
};
