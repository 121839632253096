import { Route, Switch } from "react-router";
import { MYClubsPage } from "../pages/dashboard/my-clubs";
import { MyLivestreamsPage } from "../pages/dashboard/my-livestreams/all";
import { MyLivestreamsSchedulePage } from "../pages/dashboard/my-livestreams/schedule/schedule";
import { MyLivestreamsPrivatePage } from "../pages/dashboard/my-livestreams/schedule/schedule-private";
import { MyLivestreamsPublicPage } from "../pages/dashboard/my-livestreams/schedule/schedule-public";
import { MyLivestreamsTeaserPage } from "../pages/dashboard/my-livestreams/schedule/schedule-teaser";

import { PostPage } from "../pages/dashboard/post";
import { AudioPage } from "../pages/dashboard/post/audio";
import { PollsPage } from "../pages/dashboard/post/polls";
import { StaticPostPage } from "../pages/dashboard/post/static-post";
import { TextPage } from "../pages/dashboard/post/text";
import { VideoPage } from "../pages/dashboard/post/video";
import { PostsPage } from "../pages/dashboard/posts";
import { SubscribersPage } from "../pages/dashboard/subscribers";

import { MySubscriptionsPage } from "../pages/dashboard/my-subscriptions";
import { MyClubsPreviewPage } from "../pages/dashboard/my-clubs/preview";
import { MyProfileSettingsPage } from "../pages/dashboard/my-profile";
import { Creations } from "../pages/clan-world/creations";
import Settings from "../pages/clan-world/settings/index";
import PersonalDetailInitial from "../pages/clan-world/settings/personal-details-welcome";
import { MainDashboard } from "../pages/clan-world/dashboard";
import { PurchasedTicketsPage } from "../pages/dashboard/purchased-tickets";
import { MyNFTPage } from "../pages/dashboard/my-nft";
import NFTCollecting from "../pages/dashboard/my-nft/nft-collecting";
import Admin from "../pages/dashboard/purchased-tickets/Admin";



export const DashboardRoutes = () => {
  return (
    <Switch>
      <Route path="/dashboard/myclubs" component={MYClubsPage}></Route>
      <Route path="/dashboard/preview/:id" component={MyClubsPreviewPage}></Route>
      <Route path="/dashboard/posts" component={PostsPage}></Route>
      <Route path="/dashboard/post" component={PostPage}></Route>
      <Route path="/dashboard/subscribers" component={SubscribersPage}></Route>
      <Route path="/dashboard/clubpost/:id/:type" component={VideoPage}></Route>
      <Route path="/dashboard/polls/:id" component={PollsPage}></Route>
      <Route path="/dashboard/audio/:id" component={AudioPage}></Route>
      <Route
        path="/dashboard/staticpost/:id"
        component={StaticPostPage}
      ></Route>
      <Route path="/dashboard/text/:id" component={TextPage}></Route>
      <Route
        path="/dashboard/mylivestreams"
        component={MyLivestreamsPage}
      ></Route>
      <Route
        path="/dashboard/mylivestreams-schedule"
        component={MyLivestreamsSchedulePage}
      ></Route>
      <Route
        path="/dashboard/mylivestreams-teaser"
        component={MyLivestreamsTeaserPage}
      ></Route>
      <Route
        path="/dashboard/mylivestreams-private/:id"
        component={MyLivestreamsPrivatePage}
      ></Route>
      <Route
        path="/dashboard/mylivestreams-public/:id"
        component={MyLivestreamsPublicPage}
      ></Route>
      <Route
        path="/dashboard/mysubscriptions"
        component={MySubscriptionsPage}
      ></Route>
      <Route
        path="/dashboard/myprofile"
        component={MyProfileSettingsPage}
      ></Route>
      <Route exact path="/dashboard/creations" component={Creations}></Route>
      <Route  
        path="/dashboard/settings"
        component={Settings}
        ></Route>
       
      <Route
        path="/dashboard/myprofilewelcome"
        component={PersonalDetailInitial}
      ></Route>
      <Route
        path="/dashboard/maindashboard"
        component={MainDashboard}
      ></Route>
      <Route
        path="/dashboard/purchasedtickets"
        component={PurchasedTicketsPage}
      ></Route>
      <Route
        path="/dashboard/mynft"
        component={MyNFTPage}
      ></Route>
      <Route
        path="/dashboard/mynft/owning"
        component={NFTCollecting}
      ></Route>

    </Switch>
  );
};
