import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { Dropdown } from 'react-bootstrap';
import { NavLink } from 'react-router-dom'
import { MyClubSubscribers } from '../../../app/model/clubs';
import { ClubService } from '../../../app/service/club.service';

export const MySubscriptionsInboxPage = () => {
    let id = sessionStorage.getItem('isLogin') || '';
    const [userSubscriptionClubs, setUserSubscriptionClubs] = useState<any[] | []>([]);
    const [selectedUser, setSelectedUser] = useState<MyClubSubscribers>();
    const [message, setMessage] = useState<string>('');
    const [userMessage, setUserMessage] = useState<any[] | []>([]);
    const userImages = sessionStorage.getItem('userImages') || '';
    useEffect(() => {
        if (id) {
            getMyClub(id);
        }
    }, [id]);

    const getMyClub = (id: string) => {
        ClubService.getUserSubscriptionClubs(id, 'true').then(
            res => {
                if (res) {
                    setUserSubscriptionClubs([...res]);
                    onSelectUser(res[0]);
                } else {
                }
            }
        );
    }

    const onSelectUser = (userData: any) => {
        setSelectedUser(userData);
        ClubService.getMessagesById(id, userData?.club_owner_id).then(
            res => {
                if (res) {
                    setUserMessage([...res]);
                }
            }
        );
    }

    const onSendMessage = () => {
        const data = {
            sender_id: id,
            receiver_id: selectedUser?.club_owner_id,
            message: message
        }
        ClubService.createMessages(data).then(
            res => {
                if (res) {
                    setMessage('');
                    onSelectUser(selectedUser);
                }
            }
        );
    }
    const onEnterMessage = (event: any) => {
        setMessage(event.target.value)
    }
    const onEnterSendMessage = (event: any) => {
        setMessage(event.target.value);
        if (event.key === 'Enter') {
            onSendMessage();
        }
    }

    const onSearchSubscribers = (event: any) => {
        const searchInput = event.target.value;
        userSubscriptionClubs.filter((obj: any) => (
            Object.values(obj)
                .flat()
                .some((v) => (
                    `${v}`.toLowerCase().includes(`${searchInput}`.toLowerCase())
                ))
        ))
        setUserSubscriptionClubs([...userSubscriptionClubs]);

    }
    return (
        <div id="subs-inbox-tab" role="tabpanel" aria-labelledby="subs-inbox-tab">
            <h3>My Inbox</h3>
            <hr />
            <div className="row g-0 chat-container">
                <div className="col-12 col-sm-5 col-xl-4">
                    <div className="chat-user-sec">
                        <div className="d-none d-md-block px-3">
                            <div className="d-flex align-items-center">
                                <div className="flex-grow-1">
                                    <input className="form-control rounded-pill my-3" type="text" onInput={(event) => onSearchSubscribers(event)} placeholder="Search..." />
                                </div>
                            </div>
                        </div>
                        {userSubscriptionClubs.map((data: any, index: number) => {
                            return <div key={index} className="chat-user">
                                <div className="chat-user-img"><img className="rounded-circle mr-1" src={data.user_image ? data.user_image : 'https://bootdey.com/img/Content/avatar/avatar5.png'} alt="Vanessa Tucker" /></div>
                                <div className="chat-user-info"><a className="chat-username pointer" onClick={() => onSelectUser(data)}>{data.club_owner_name}
                                    {/* <div className="chat-time">16:15</div> */}
                                </a>
                                    {/* <div className="chat-user-status"><span>span Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus vitae magna</span></div> */}
                                </div>
                            </div>
                        })}
                        {/* <div className="chat-user">
                            <div className="chat-user-img"><img className="rounded-circle mr-1" src="https://bootdey.com/img/Content/avatar/avatar5.png" alt="Vanessa Tucker" /></div>
                            <div className="chat-user-info"><a className="chat-username" href="#">Vanessa Tucker
                                <div className="chat-time">21:00</div></a>
                                <div className="chat-user-status"><span>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus vitae magna</span></div>
                            </div>
                        </div>
                        <div className="chat-user">
                            <div className="chat-user-img"><img className="rounded-circle mr-1" src="https://bootdey.com/img/Content/avatar/avatar5.png" alt="Vanessa Tucker" /></div>
                            <div className="chat-user-info"><a className="chat-username" href="#">Vanessa Tucker
                                <div className="chat-time">Yesterday</div></a>
                                <div className="chat-user-status"><span>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus vitae magna</span></div>
                            </div>
                        </div>
                        <div className="chat-user">
                            <div className="chat-user-img"><img className="rounded-circle mr-1" src="https://bootdey.com/img/Content/avatar/avatar5.png" alt="Vanessa Tucker" /></div>
                            <div className="chat-user-info"><a className="chat-username" href="#">Vanessa Tucker
                                <div className="chat-time">05-12-20</div></a>
                                <div className="chat-user-status"><span>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus vitae magna</span></div>
                            </div>
                        </div> */}
                    </div>
                    <hr className="d-block d-lg-none mt-1 mb-0" />
                </div>
                <div className="col-12 col-sm-7 col-xl-8">
                    <div className="chat-sec">
                        <div className="chat-sec-top">
                            <div className="chat-sec-top-img d-flex flex-wrap">
                                <img className="rounded-circle mr-1" src={`${selectedUser?.user_image ? selectedUser?.user_image : 'https://bootdey.com/img/Content/avatar/avatar1.png'}`} style={{ height: "40px" }} alt="Sharon Lessman" />
                                <strong className="ms-1 align-self-center">{selectedUser?.club_owner_name}</strong>
                            </div>
                            <div className="chat-sec-top-username ms-auto col-auto">
                                {/* <button className="btn btn-outline-black" onClick={() => onSelectUser(selectedUser)}><i className="bi bi-arrow-repeat"></i></button> */}
                                <div className="chat-sec-top-username ms-auto col-auto">
                                    {/* <button className="btn btn-outline-black" onClick={() => onSelectUser(selectedUser)}><i className="bi bi-arrow-repeat"></i></button> */}
                                    <Dropdown className='dropdownReport'>
                                        <Dropdown.Toggle className="btn btn-default"   id="dropdown-basic">
                                            ...
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                            <Dropdown.Item href="#/action-1">Report Abuse</Dropdown.Item>
                                            <Dropdown.Item href="#/action-2">Block</Dropdown.Item>
                                        </Dropdown.Menu>
                                        </Dropdown>
                                </div>
                            </div>
                        </div>
                        <div className="position-relative">
                            <div className="chat-messages">
                                {userMessage.map((data: any, index: number) => {
                                    return <div key={index}>
                                        {id === data.receiver_id ? < div className="chat-message-left">
                                            <div className="chat-message-img"><img className="rounded-circle mr-1" src={`${data?.receiver_image ? data?.receiver_image : 'https://bootdey.com/img/Content/avatar/avatar1.png'}`} style={{ height: "40px" }} alt="Chris Wood" /></div>
                                            <div className="chat-message-box">
                                                <div className="chat-message-box-textby">
                                                    <div className="chat-message-box-textby-user">{selectedUser?.club_owner_name}</div>
                                                    <div className="chat-message-time">{moment(data?.createdAt).format("MM-DD hh:mm")}</div>
                                                </div>
                                                <div className="chat-message-box-desc">{data?.message}</div>
                                            </div>
                                        </div> : <div className="chat-message-right">
                                            <div className="chat-message-img"><img className="rounded-circle mr-1" src={`${data?.sender_image ? data?.sender_image : 'https://bootdey.com/img/Content/avatar/avatar1.png'}`} style={{ height: "40px" }} alt="Chris Wood" /></div>
                                            <div className="chat-message-box">
                                                <div className="chat-message-box-textby">
                                                    <div className="chat-message-box-textby-user me-1">Me</div>
                                                    <div className="chat-message-time">{moment(data?.createdAt).format("MM-DD hh:mm")}</div>
                                                </div>
                                                <div className="chat-message-box-desc">{data?.message}</div>
                                            </div>
                                        </div>
                                        }
                                    </div>
                                })}
                                {userMessage.length <= 0 && <div className="text-center">No Messages</div>}
                            </div>
                        </div>
                    </div>
                    <div className="chat-sec-btm">
                        <div className="input-group">
                            <input className="form-control" type="text" placeholder="Type your message" onChange={(event) => onEnterMessage(event)} onKeyPress={(event) => onEnterSendMessage(event)} value={message} />
                            <button className="btn btn-primary" onClick={() => onSendMessage()}>Send</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
