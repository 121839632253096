import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { MyClubSubscribers } from '../../../app/model/clubs';
import { ClubService } from '../../../app/service/club.service';
import profilePic from '../../../assets/img/profile.png';

export const SubscribersListPage = () => {
    let clubId = sessionStorage.getItem('clubId')
    const [myclubSubscribers, setMyClubSubscribers] = useState<MyClubSubscribers[]>([]);
    const [sortingOrder, setSortingOrder] = useState<string>('asc');
    const [selectedSorting, setSelectedSorting] = useState<string>('user_name');


    useEffect(() => {
        if (clubId) {
            getClubSubscribersByClubId(clubId);
        }
    }, [clubId]);

    const getClubSubscribersByClubId = (clubId: string) => {
        ClubService.getClubSubscribersByClubId(clubId, 'false').then(
            res => {
                if (res) {
                    setMyClubSubscribers(res);
                }
            }
        );
    }
    const sorting = (sortKey: any, sortOrder: string) => {
        const data: any = myclubSubscribers;
        setSortingOrder(sortOrder === 'asc' ? 'dsc' : 'asc');
        setSelectedSorting(sortKey);
        if (sortOrder === 'asc') {
            data.sort((a: any, b: any) => (a[sortKey] > b[sortKey]) ? 1 : ((b[sortKey] > a[sortKey]) ? -1 : 0))
        } else {
            data.sort((a: any, b: any) => (a[sortKey] > b[sortKey]) ? -1 : ((b[sortKey] > a[sortKey]) ? 1 : 0))
        }
        setMyClubSubscribers([...data]);
    }
    return (
        <div className="tab-pane" id="subscribers" role="tabpanel" aria-labelledby="subscribers-tab">
                <h3 className='py-2'>
                    Subscribers
                </h3>
            <div className="table-responsive">
                <table className="table table-custom">
                    <thead>
                        <tr>
                            <th style={{'minWidth':'150px'}} scope="col" className="pointer" onClick={() => sorting('user_name', sortingOrder)}>Name {selectedSorting === 'user_name' && <span> {sortingOrder === 'asc' ? <i className="bi bi-arrow-down-short"></i> : <i className="bi bi-arrow-up-short"></i>}</span>} </th>
                            <th style={{'minWidth':'100px'}} scope="col">Package</th>
                            <th style={{'minWidth':'100px'}} scope="col">Model</th>
                            <th style={{'minWidth':'100px'}} scope="col" className="pointer" onClick={() => sorting('amount', sortingOrder)}>Amount{selectedSorting === 'amount' && <span> {sortingOrder === 'asc' ? <i className="bi bi-arrow-down-short"></i> : <i className="bi bi-arrow-up-short"></i>}</span>} </th>
                            <th style={{'minWidth':'170px'}} scope="col">subscribed Date</th>
                            <th style={{'minWidth':'170px'}} scope="col">End Date</th>

                        </tr>
                    </thead>
                    <tbody>
                        {myclubSubscribers.map((subscribers: MyClubSubscribers, index) => {
                            return <tr key={index}>
                                <th scope="row">
                                    <img src={subscribers.user_image? subscribers.user_image : profilePic} style={{ borderRadius:'50%',width:'30px', height:'30px', marginRight:'10px' }} alt=""/>
                                    {subscribers?.user_name}</th>
                                <td>{subscribers?.package_name}</td>
                                <td>{subscribers?.model}</td>
                                <td>INR {subscribers?.amount}</td>
                                <td>{moment(subscribers?.subscribed_dt).format("lll")}</td>
                                <td>{subscribers?.end_dt ? moment(subscribers?.end_dt).format("lll") : ''}</td>
                            </tr>
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    )
}
