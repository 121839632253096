import React from "react";
import { Link } from "react-router-dom";
import aboutBg from "../assets/img/about-us-top-bg.png";
type Props = {};

const AboutUs = (props: Props) => {
  return (
    <div className="page-aboutus">
      <section
        id="aboutus"
        className="d-flex align-items-center mb-5"
        style={{ backgroundImage: `url(${aboutBg})` }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h1>About us</h1>
              <p>
                Enjoy the live stream of your favourite idols from anywhere in
                the world.
              </p>
              <p>
                ClanWorld is a platform where creators, artists, influencers and
                skilled professionals can perform live events and their fans can
                view them exclusively by buying a ticket to the event.
              </p>
            </div>
          </div>
        </div>
      </section>
      <div className="container mb-3">
        <div className="row">
          <div className="col-lg-12">
            <h3>For Creators: </h3>
            <ul>
              <li>
                They can schedule a live stream for a particular date and time.
              </li>
              <li>Creators need to set a price for their event.</li>
              <li>
                Once scheduled they can promote their event on social media and
                drive the audience to buy a ticket on ClanWorld.
              </li>
              <li>
                Creators can also send the tickets for free to their near and
                dear ones.
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="container mb-3">
        <div className="row">
          <div className="col-lg-12">
            <h3>For Users: </h3>
            <ul>
              <li>
                Users can buy tickets to the live streaming event at the price
                set by the creator.
              </li>
              <li>They can also purchase for their family and friends.</li>
              <li>
                A live stream once completed is available for 24 hrs for the
                attendees.
              </li>
              <li>
                Users who have missed the livestream can also view its recording
                when its available after a few days from the livestream.
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="container  mb-3">
        <div className="row">
          <div className="col-lg-12">
            <h6>Customer Support: </h6>
            <p className="mb-4 fs-sml">
              Email us at{" "}
              <a href="mailto:support@clanworld.ai">support@clanworld.ai</a>
            </p>
            <h6>Please Note:</h6>
            <p className="fs-sml">
              To have a good experience, use a high speed internet connection,
              try not sitting in a crowded place and use headphones.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
