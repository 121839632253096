import { Switch, Route } from "react-router";
import { MyClubDetailsPage } from "../pages/dashboard/my-clubs/details";
import { MyClubPackagePage } from "../pages/dashboard/my-clubs/package";
import { MyClubPackagesPage } from "../pages/dashboard/my-clubs/packages";
import { MyClubPaymentsPage } from "../pages/dashboard/my-clubs/payments";
import { MyLivestreamsCancelledPage } from "../pages/dashboard/my-livestreams/all/cancelled";
import { MyLivestreamsCompletedPage } from "../pages/dashboard/my-livestreams/all/completed";
import { MyLivestreamsDraftsPage } from "../pages/dashboard/my-livestreams/all/drafts";
import { MyLivestreamsRecordingsPage } from "../pages/dashboard/my-livestreams/all/recordings";
// import { MyLivestreamsSchedulePage } from "../pages/dashboard/my-livestreams/schedule/schedule";
import { MyLivestreamsScheduledPage } from "../pages/dashboard/my-livestreams/all/scheduled";

const ClubsRoutes = () => {
    return (
        <Switch>
            <Route exact path="/dashboard/myclubs/details" component={MyClubDetailsPage}></Route>
            <Route exact path="/dashboard/myclubs/packages" component={MyClubPackagesPage}></Route>
            <Route exact path="/dashboard/myclubs/payments" component={MyClubPaymentsPage}></Route>
            <Route exact path="/dashboard/myclubs/package/:id" component={MyClubPackagePage}></Route>
            <Route exact path="/dashboard/mylivestreams/drafts" component={MyLivestreamsDraftsPage}></Route>
            <Route exact path="/dashboard/mylivestreams/scheduled" component={MyLivestreamsScheduledPage}></Route>
            <Route exact path="/dashboard/mylivestreams/completed" component={MyLivestreamsCompletedPage}></Route>
            <Route exact path="/dashboard/mylivestreams/recordings" component={MyLivestreamsRecordingsPage}></Route>
            <Route exact path="/dashboard/mylivestreams/cancelled" component={MyLivestreamsCancelledPage}></Route>
            {/* <Route exact path="/dashboard/mylivestreams/schedule" component={MyLivestreamsSchedulePage}></Route> */}
        </Switch>
    );
}

export default ClubsRoutes;