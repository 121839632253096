import React from "react";

export const PollsPage = () => {
  return (
    <div className="main-content">
      <div className="container container-custom">
        <div className="form-create-post">
          <div className="d-flex flex-wrap align-items-start">
          <div className="dropdown create-post">
              <span
                className="title-w-bdr dropdown-toggle"
                id="dropdownMenuLink"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <span>Polls</span>
              </span>
              <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                <li>
                  <a className="dropdown-item" href="#/dashboard/audio">
                    Audio Post
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="#/dashboard/staticpost">
                    Static Post
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="#/dashboard/text">
                    Text
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="#/dashboard/video">
                    Video
                  </a>
                </li>
              </ul>
            </div>
            <a className="ms-auto btn btn-primary ms-2" href="">
              Publish
            </a>
          </div>
          <div className="row post-form-sec">
            <div className="col-md-9 post-form">
              <div className="form-floating mb-3">
                <input
                  className="form-control"
                  type="Title of the Post"
                  placeholder="title"
                  id="title"
                  name="title"
                />
                <label htmlFor="title">Title</label>
              </div>
              <div className="form-floating mb-3">
                <textarea
                  className="form-control"
                  placeholder="Add a description"
                  id="description"
                  name="description"
                ></textarea>
                <label htmlFor="description">Description</label>
              </div>
              <div className="custom-checkbox no-bdr">
                <input id="makeItAvailable" type="checkbox" />
              </div>
              <div className="radio-w-input">
                <div className="radio">
                  <div className="custom-radio no-bdr">
                    <input id="enterChoice" type="radio" />
                    <label htmlFor="enterChoice"> </label>
                  </div>
                </div>
                <div className="input">
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Enter an answer here"
                    name="enterAnAnswer"
                  />
                </div>
              </div>
              <div className="radio-w-input">
                <div className="radio">
                  <div className="custom-radio no-bdr">
                    <input id="enterChoice1" type="radio" />
                    <label htmlFor="enterChoice1"> </label>
                  </div>
                </div>
                <div className="input">
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Enter an answer here"
                    name="enterAnAnswer"
                  />
                </div>
              </div>
              <a className="link link-primary ms-4 mb-2" href="">
                Add an option
              </a>
              <hr className="mt-5" />
              <div className="row mb-3 set-end-date-sec">
                <div className="col-12">
                  <div className="custom-checkbox no-bdr">
                    <input id="setEndDate" type="checkbox" />
                    <label htmlFor="setEndDate">
                      {" "}
                      <span className="d-inline-flex flex-column">
                        Set end date
                      </span>
                    </label>
                  </div>
                </div>
                <div className="set-end-date-row row">
                  <div className="col-auto">
                    <div className="input-group date" id="datepicker">
                      <input
                        className="form-control"
                        placeholder="MM/DD/YYYY"
                      />
                      <span className="input-group-append input-group-addon input-height">
                        <span className="input-group-text input-height">
                          <i className="bi bi-calendar-date"></i>
                        </span>
                      </span>
                    </div>
                  </div>
                  <div className="col-auto">
                    <div className="input-group date" id="timepicker">
                      <input className="form-control" placeholder="HH:MM" />
                      <span className="input-group-append input-group-addon input-height">
                        <span className="input-group-text input-height">
                          <i className="bi bi-calendar-event"></i>
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="custom-checkbox no-bdr">
                <input id="allowMultiple" type="checkbox" />
                <label htmlFor="allowMultiple">
                  {" "}
                  <span className="d-inline-flex flex-column">
                    Allow multiple choice{" "}
                  </span>
                </label>
              </div>
              <div className="d-flex flex-wrap justify-content-end mt-5">
                <a className="btn btn-outline-orange" href="">
                  Discard
                </a>
                <a className="btn btn-outline-black ms-2" href="">
                  Save
                </a>
              </div>
            </div>
            <div className="col-md-3 post-form-detail">
              <h6 className="d-inline-flex">
                Viewership
                <i
                  className="bi bi-info-circle ms-1"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="Tooltip on top"
                ></i>
              </h6>
              <div className="custom-checkbox no-bdr">
                <input id="publicFree" type="checkbox" />
                <label htmlFor="publicFree">
                  {" "}
                  <span className="d-inline-flex flex-column">
                    Public - Free
                    <div className="form-text fs-xsml mt-0" id="publicFree">
                      Anyone can view
                    </div>
                  </span>
                </label>
              </div>
              <div className="custom-checkbox no-bdr">
                <input id="selectClubPkg" type="checkbox" />
                <label htmlFor="selectClubPkg">
                  {" "}
                  <span className="d-inline-flex flex-column">
                    Select Club Package
                    <div className="form-text fs-xsml mt-0" id="publicFree">
                      Anyone can view
                    </div>
                  </span>
                </label>
              </div>
              <div className="plans-checkbox">
                <div className="custom-checkbox no-bdr">
                  <input id="basicPlan" type="checkbox" />
                  <label htmlFor="basicPlan">
                    <div className="bundle-info">
                      <div className="bundle-name">Basic</div>
                      <a className="bundle-detail" href="">
                        Details
                      </a>
                    </div>
                    <div className="bundle-desc fs-xsml mt-0">
                      <div className="bundle-info-price">
                        299 INR / Month |{" "}
                      </div>
                      <div className="bundle-info-fans">12 Fans</div>
                    </div>
                  </label>
                </div>
              </div>
              <div className="plans-checkbox">
                <div className="custom-checkbox no-bdr">
                  <input id="premiumPlan" type="checkbox" />
                  <label htmlFor="premiumPlan">
                    <div className="bundle-info">
                      <div className="bundle-name">Premium</div>
                      <a className="bundle-detail" href="">
                        Details
                      </a>
                    </div>
                    <div className="bundle-desc fs-xsml mt-0">
                      <div className="bundle-info-price">
                        399 INR / Month |{" "}
                      </div>
                      <div className="bundle-info-fans">15 Fans</div>
                    </div>
                  </label>
                </div>
              </div>
              <div className="plans-checkbox">
                <div className="custom-checkbox no-bdr">
                  <input id="goldPlan" type="checkbox" />
                  <label htmlFor="goldPlan">
                    <div className="bundle-info">
                      <div className="bundle-name">Gold</div>
                      <a className="bundle-detail" href="">
                        Details
                      </a>
                    </div>
                    <div className="bundle-desc fs-xsml mt-0">
                      <div className="bundle-info-price">
                        499 INR / Month |{" "}
                      </div>
                      <div className="bundle-info-fans">20 Fans </div>
                    </div>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
