import React, { useEffect, useState } from 'react'
import { connect, useDispatch } from 'react-redux';
import { NavLink, useHistory } from 'react-router-dom'
import { SubscriptionPackages } from '../../../app/model/subscription-packages';
import { ClubService } from '../../../app/service/club.service';
import { SubscriptionPackagesService } from '../../../app/service/subscription-packages-service';
import { Loading } from '../../../redux/actions';
import { toast } from 'react-toastify';

interface Props {
    UserReducer?: any;
    loading?: (data: any) => void;
    SaveClubReducer?: any;
    save: any;
}
const MyClubPackagesPage: React.FC<Props> = (props: Props) => {
    const [subscriptionPackages, setSubscriptionPackages] = useState<SubscriptionPackages[] | []>([]);
    const dispatch = useDispatch();
    const userId = sessionStorage.getItem("isLogin") || "";
    const history = useHistory();

    useEffect(() => {
        if (props.loading) {
            dispatch(props.loading(true));
        }
        if (userId) {
            getClubPackages();
        } else {
            if (props.loading) {
                dispatch(props.loading(false));
            }
        }
    }, [])

    const getClubPackages = () => {
        ClubService.getUserClubs(userId).then(
            res => {
                if (res) {
                    if (res.length > 0) {
                        if (res[0]?._id) {
                            sessionStorage.setItem('clubId', res[0]?._id || '');
                            SubscriptionPackagesService.getClubSubscriptionPackages(res[0]?._id).then(
                                res => {
                                    if (res) {
                                        // res.sort((a, b) => (a.name > b.name) ? 1 : -1);
                                        setSubscriptionPackages(res);
                                        if (props.loading) {
                                            dispatch(props.loading(false));
                                        }
                                    }
                                }
                            );
                        }
                    }
                }
            }
        )
    }
    const onUnpublishPackage = (selectedPackage: SubscriptionPackages) => {
        if (selectedPackage?.price_monthly !== 0 && selectedPackage?.price_yearly !== 0) {
            if (selectedPackage?.status !== "1") {
                SubscriptionPackagesService.publishSubscriptionPackage(selectedPackage).then(
                    res => {
                        if (res) {
                            toast.success('Club Package Published successfully');
                            getClubPackages();
                        } else {
                            toast.error(res.message);
                        }
                    }
                );
            } else {
                SubscriptionPackagesService.unPublishPackage(selectedPackage?._id, selectedPackage.club_id).then(
                    res => {
                        if (res) {
                            toast.success('Club Package Unpublished successfully');
                            getClubPackages();
                        }
                    }
                );
            }
        } else {
            toast.error('Please fill required fields before publish');
        }
    }
    const onPreview = () => {
        sessionStorage.setItem('previewFrom', 'packages');
        history.push(`/dashboard/preview/${sessionStorage.getItem('clubId')}`);
    }

    return (
        <div id="packages" role="tabpanel" aria-labelledby="packages-tab" className="pb-5 mb-3">
            <div className="row g-4 plan-info">
                {subscriptionPackages.map((subscription: any, index: number) => {
                    return <div key={index} className="col-md-6 col-lg-4">
                        <div className="card" >
                            <div className="card-header" style={{ background: subscription?.status === "1" ? "#2CA266" : "", color: subscription?.status === "1" ? "white" : "" }}>
                                <div className="card-header__left"><strong>{subscription?.name}</strong>
                                    <span>{subscription?.status === "1" ? "Published" : "Unpublished"} | {subscription?.total_fans ? subscription?.total_fans : 0} Fans</span>
                                </div>
                                <div className="card-header__right"><strong>{subscription?.price_monthly} INR</strong><span className="card-header__right__status">per month</span></div>
                            </div>
                            <div className="card-body">
                                <p>{subscription?.description}</p>
                                <ol>
                                    {subscription.perks.map((peark: any, perkIndex: number) => { return < li key={perkIndex} >{peark?.value}</li> })}
                                </ol>
                                <div className="btn-group">
                                    <NavLink className="edit-package-btn btn-lg btn btn-outline-black" to={`/dashboard/myclubs/package/${subscription._id}`} data-package="edit-package-1">Edit</NavLink>
                                    {/* <button className="btn-lg btn btn-outline-black" onClick={() => onUnpublishPackage(subscription)}>{subscription?.status !== "1" ? "Publish" : "Unpublish"}</button> */}
                                </div>
                            </div>

                        </div>
                    </div>
                })}
            </div>
            <div className="row justify-content-end mt-5">
                <div className="col-auto">
                    <a className="btn btn-primary me-2 d-inline" onClick={onPreview}>
                        Preview
                    </a>
                </div>
            </div>
        </div >
    )
}
const mapStateToProps = (state: any) => {
    return {
        UserReducer: state.UserReducer,
        SaveClubReducer: state.SaveClubReducer,
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        loading: (data: any) => dispatch(Loading(data)),
    };
};

const connectedNavBar = connect(
    mapStateToProps,
    mapDispatchToProps
)(MyClubPackagesPage);
export { connectedNavBar as MyClubPackagesPage };