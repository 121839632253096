import React from "react";
import { useContext, useState } from "react";
import Form from "react-bootstrap/Form";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import TextField from "@mui/material/TextField";
import { Button } from "react-bootstrap";
import axios from "axios";
import { toast } from "react-toastify";
import "..//scss/pages/_support.scss";
import { UserService } from "../app/service/user.service";
import { Modal } from "react-bootstrap";
import contactUsImage from "../assets/img/CW_contact_us.jpg"

const Support = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");

  // Errors
  const [EmailError, setEmailError] = useState("");
  const [PhoneError, setPhoneError] = useState("");
  const [NameError, setNameError] = useState("");
  const [MessageError, setMessageError] = useState("");

  // handle Inputs
  const handleName = (e: any) => {
    setNameError("");
    const reg = /^[A-Za-z]+(?:[ _-][A-Za-z]+)*$/;
    if (e) {
      if (!reg.test(e.target.value.trim())) {
        if (!e.target.value.trim()) {
          setName("");
          setNameError("Enter only alphabets");
        } else {
          setName(e.target.value);
          setNameError("Enter only alphabets");
        }
      } else {
        setName(e.target.value);
      }
    } else {
      setName("");
      setNameError("Please enter name");
    }
  };
  const handleEmail = (e: any) => {
    setEmailError("");

    setEmail(e.target.value);
    if (e.target.value) {
      const re = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,6})+$/;
      if (!e.target.value.trim()) {
        setEmail("");
        setEmailError("Enter only alphabets");
      } else if (re.test(e.target.value.trim())) {
        setEmail(e.target.value);
      } else {
        setEmail(e.target.value);
        setEmailError("Please enter proper email id");
      }
    } else {
      setEmail("");
      setEmailError("Please enter email id");
    }
  };
  const handlePhone = (e: any) => {
    setPhoneError("");
    if (e.target.value && e.target.value.length <= 10) {
      const re = /^[5-9]{1}[0-9]{9}$/;
      if (re.test(e.target.value.trim())) {
        setPhone(e.target.value.trim());
      } else {
        setPhone(e.target.value.trim());
        setPhoneError("Please enter proper phone number");
      }
    } else if (!(e.target.value.length >= 10)) {
      setPhone("");
      setPhoneError("Please enter phone number");
    }
  };

  const handleMessage = (e: any) => {
    setMessageError("");
    if (e.target.value && e.target.value.length >= 1) {
      setMessage(e.target.value);
    } else {
      setMessage("");
      setMessageError("Message can't be Empty");
    }
  };

  // function to post user data

  const sendEmail = (e: any) => {
    e.preventDefault();

    const userData = {
      name: name,
      email: email,
      phone: phone,
      message: message,
    };
    UserService.support(userData).then((res) => {
      if (res) {
        toast.success("support request Created successfully");
        setMessage("");
        setPhone("");
        setEmail("");
        setName("");
      } else {
        toast.error("Request not submitted");
      }
    });
  };

  return (
    <>
    <div className="container main-support">
      <h2 style={{ textAlign: "center", margin: "30px 0" }}>Get In Touch</h2>
      <div className="support-main">
        <div className="support left">
          <Form
            style={{
              display: "flex",
              // alignItems: "center",
              // justifyContent: "center",
              flexDirection: "column",
              paddingLeft: "0",
              paddingRight: "15px",
              paddingTop: "20px",
            }}
            onSubmit={sendEmail}
            action="/support"
            method="POST"
          >
            <h6 style={{ textAlign: "left" }}>
              Do you have any questions?<br/>
            </h6>
            <span className="support-heading-content">Please do not hesitate to contact us
              directly. Our team will come back to you within a matter of hours
              to help you.
            </span>

            {/* <Form.Group className="mb-3" controlId="s_name"> */}
            <div className="col-md-12 ">
              <TextField
                fullWidth
                id="outlined-basic"
                label="Name"
                // placeholder="This will be cover title of your livestream."
                variant="outlined"
                margin="normal"
                value={name}
                onChange={handleName}
                // minLength={3}
                required
              />
              {NameError && (
                <p
                  style={{ color: "red", fontSize: "12px", marginBottom: "0" }}
                >
                  {NameError}
                </p>
              )}
            </div>
            {/* <Form.Control
            type="text"
            placeholder=""
            
            value={name}
            minLength={3}
            
          /> */}

            {/* </Form.Group> */}

            {/* <Form.Group className="mb-3" controlId="s_email"> */}
            <div className="col-md-12 ">
              <TextField
                fullWidth
                type="email"
                id="outlined-basic"
                label="Email"
                // placeholder="This will be cover title of your livestream."
                variant="outlined"
                margin="normal"
                onChange={handleEmail}                // minLength={3}
                value={email}
                required
              />
            {EmailError && (
              <p style={{ color: "red", fontSize: "12px", marginBottom: "0" }}>
                {EmailError}
              </p>
            )}
            </div>
            
            <div className="col-md-12">
              <TextField
                fullWidth
                type="number"
                id="outlined-basic"
                label="Phone"
                // placeholder="This will be cover title of your livestream."
                variant="outlined"
                margin="normal"
                onChange={handlePhone}
                value={phone}
                // pattern={"^[0-9]{10}$"}
                required
              />
         {PhoneError && (
              <p style={{ color: "red", fontSize: "12px", marginBottom: "0" }}>
                {PhoneError}
              </p>
            )}
            </div>

            {/* <Form.Group className="mb-3" controlId="s_phone">
              <Form.Label> Enter your Phone Number</Form.Label>
              <Form.Control
                type="number"
                placeholder=""
                onChange={handlePhone}
                value={phone}
                pattern={"^[0-9]{10}$"}
                required
              />
            </Form.Group> */}

{/* <div className="col-md-8">
              <TextField
                fullWidth
                type="textarea"
                rows={3}
                id="outlined-basic"
                label="Email"
                // placeholder="This will be cover title of your livestream."
                variant="outlined"
                margin="normal"
                onChange={handleMessage}
                value={message}
                // pattern={"^[0-9]{10}$"}
                required
              /> */}
               <div className="col-md-12">
                            <TextField
                              id="outlined-basic"
                              label="Message"
                              multiline
                              variant="outlined"
                              rows={3}
                              value={message}
                              fullWidth
                              onChange={handleMessage}
                              margin="normal"
                              // placeholder="Why should someone buy ticket to your show? Write something interesting &amp; compelling."
                              required
                            />
                          {/* </div> */}
        {MessageError && (
              <p style={{ color: "red", fontSize: "12px", marginBottom: "0" }}>
                {MessageError}
              </p>
            )}
            </div>
           
            {/* <Form.Group className="mb-3" controlId="s_message">
              <Form.Label> Enter your Message</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                placeholder=""
                onChange={handleMessage}
                value={message}
                minLength={3}
                required
              />
            </Form.Group> */}
           

            <Button type="submit" variant="secondary" className="btn btn-black"style={{backgroundColor:"#2b2b2b"}}>
              Send
            </Button>
          </Form>
        </div>
        <div className="support">
          <div className="right">
            <div className="contactus-container">
              {/* <h3 style={{ margin: "20px", textAlign: "center" }}>
                Contact Us
              </h3> */}
              <div className="contactus-content">
                <img src={contactUsImage} alt="contact us"/>
                <span className="contact-details">
                  <i
                    className="bi bi-geo-alt"
                    style={{
                      marginRight: "20px",
                      fontSize: "16px",
                      // borderRadius: "50%",
                      color: "#2b2b2b",
                    }}
                  ></i>
                  <p>43A, Ground Floor,Phase III, Okhla Industrial Area,
                  <br /> 
                  South Delhi, Delhi 110020
                  </p>
                </span>
                <span className="contact-details">
                  <i
                    className="bi bi-telephone"
                    style={{
                      marginRight: "20px",
                      fontSize: "16px",
                      color: "#2b2b2b",
                    }}
                  ></i>
                  011-41193911
                </span>
                <span className="contact-details">
                  <i
                    className="bi bi-envelope"
                    style={{
                      marginRight: "20px",
                      fontSize: "16px",
                      color: "#2b2b2b",
                    }}
                  ></i>
                  info@clanconnect.ai
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
};

export default Support;
