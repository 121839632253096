import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
interface Props { }

export const MyLivestreamsSchedulePage: React.FC<Props> = (props: Props) => {
  return (
    <div className="main-content">
      <div className="container container-custom">
        <div className="tab-content" id="pills-tabContent">
          <div
            className="tab-pane active"
            id="details"
            role="tabpanel"
            aria-labelledby="details-tab"
          >
            <div className="row create-livestream p-2">
              {/* <div className="col-md-4">
                <NavLink
                  className="card card-boxed d-flex justify-content-center align-items-center"
                  to="/dashboard/mylivestreams-teaser"
                >
                  <i className="bi bi-camera-video"></i>
                  <h3 className="text-center">
                    Upload a teaser
                    <br /> <span className="fs-xsml">(upto 30secs)</span>
                  </h3>
                </NavLink>
              </div> */}
              <div className="col-md-6">
                <NavLink className="card card-boxed d-flex justify-content-center align-items-center" to="/dashboard/mylivestreams-public/1">
                  <i className="bi bi-camera-video"></i>
                  <h3 className="text-center">
                    Schedule a <br />public Livestream
                  </h3>
                </NavLink>
              </div>
              <div className="col-md-6">
                <NavLink className="card card-boxed d-flex justify-content-center align-items-center" to="/dashboard/mylivestreams-private/1">
                  <i className="bi bi-camera-video"></i>
                  <h3 className="text-center">
                    Schedule a <br />public individual or private group livestream
                  </h3>
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
