import React, { useEffect, useState } from "react";
import Banner from "../../../assets/img/live-stream.webp";
import { NavLink, Link } from "react-router-dom";
import Slider from "react-slick";
import { LivestreamService } from "../../../app/service/livestream";
import { Loading } from "../../../redux/actions";
import { connect } from "react-redux";
import moment from "moment";
import { UserService } from "../../../app/service/user.service";
import goLive from "../../../assets/img/goLive1.svg";

interface Props {
  LoginUserIdReducer?: any;
  UserReducer?: any;
}

const ViewAllUpcomingLivestream: React.FC<Props> = (props: Props) => {
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const [livestream, setLivestream] = useState<any[] | []>([]);
  let userId =
    sessionStorage.getItem("isLogin") || props?.LoginUserIdReducer?.loginUserId;
  const [pasLlivestream, setPasLlivestream] = useState<any[] | []>([]);
  const [isUserLogin, setUserLogin] = useState(
    sessionStorage.getItem("isLogin") || ""
  );

  const [loginUserData, setLoginUserData] = useState<any[] | []>([]);
  // const [isFetching, setIsFetching] = useState(false);
  // const [HasMore, setHasMore] = useState(true);
  // const [page, setPage] = useState(0);

  useEffect(() => {
    LivestreamService.getScheduledLivestream("false").then((res) => {
      if (res) {
        console.log("res res res", res);
        // const data = res.sort((a:any, b:any) => (a.schedule_From < b.schedule_From) ? 1 : ((b.schedule_From < a.schedule_From) ? -1 : 0));
        setLivestream([...res]);
      }
    });

    LivestreamService.getCompletedLivestream("false").then((res) => {
      if (res) {
        setPasLlivestream([...res]);
      }
    });

    const _id = sessionStorage.getItem("isLogin") || "";
    if (_id) {
      UserService.getUser(_id).then((res) => {
        if (res) {
          sessionStorage.setItem("userImages", "");
          setLoginUserData(res);
          if (res.length > 0) {
            sessionStorage.setItem("userImages", res[0]?.image);
          }
        }
      });
    }
    // loadMoreItems();
  }, []);

  // function loadMoreItems() {
  //   setIsFetching(true);
  //   LivestreamService.getScheduledLivestream("false")
  //     .then((res) => {
  //       setLivestream([...res]);
  //       setHasMore(res.data.length > 0);
  //       setIsFetching(false);
  //     })
  //     .catch((e) => {
  //       console.log(e);
  //     });
  // }

  return (
    <>
      <section className="livestream-coll-sec pt-5 ">
        <div className="container-fluid">
          <div className="livestream-coll-sec-title">
            <h2 className=" mb-2">Streaming Soon</h2>

            {(isUserLogin || props?.UserReducer?.IsUserLogged) &&
              loginUserData[0]?.club_influencer && (
                <NavLink
                  className="btn btn-outline-black mx-auto d-inline-flex btn-wide"
                  to={`/livestreams/create-livestream/0`}
                >
                  Create Livestream
                </NavLink>
              )}
          </div>
          <div className="row">
            {livestream.map((data: any, index: number) => {
              {
                console.log(data);
              }
              return (
                <div key={index} className="col-sm-6 col-lg-4 col-xxl-3 mb-4">
                  <div
                    className="d-none d-md-flex flex-column card-livestream"
                    style={{
                      backgroundImage: `url(${
                        data?.thumbnail ? data?.thumbnail : Banner
                      })`,
                      backgroundPosition: "center center",
                      backgroundSize: "cover",
                    }}
                  >
                    {data.status === "LIVE" ? (
                      <div className="card-livestream-live-sec">
                        <img
                          className="card-livestream-liveIcon"
                          src={goLive}
                          alt=""
                        />
                        <span className="card-livestream-live-status-text">
                          Ends in 15 mins
                        </span>
                      </div>
                    ) : null}
                    <div className="card-livestream-content">
                      {/* <a
                        href="javascript:void(0)"
                        onClick={() => {}}
                        className="ratio ratio-1x1 mb-3 video-img"
                      >
                        <video
                          poster=""
                          className="my_club_media"
                          controls
                          controlsList="nodownload"
                          src={data?.video_url}
                        >
                          <source src="" type="video/mp4" />
                          Your browser does not support HTML video.
                        </video>
                      </a> */}

                      <h3 className="card-livestream-title">
                        <a
                          href="javascript:void(0)"
                          onClick={() => {}}
                          title="title"
                        >
                          {data?.title}
                        </a>
                      </h3>
                      <Link
                        to="asdf"
                        className="dive-in-channel position-relative"
                      >
                        @{data?.user_info[0]?.name}
                      </Link>
                      <span className="card-livestream-date">
                        {moment(data?.schedule_From).utc().format('DD-MM-YYYY hh:mm:ss A')} IST
                      </span>
                    </div>
                    <Link
                      className="btn btn-outline-black mx-auto"
                      to={`/livestreams/livestream-detail/${data?._id}`}
                    >
                      View Details
                    </Link>
                  </div>
                  <Link
                    to={`/livestreams/livestream-detail/${data?._id}`}
                    className="d-flex d-md-none card-livestream"
                    style={{
                      backgroundImage: `url(${
                        data?.thumbnail ? data?.thumbnail : Banner
                      })`,
                      backgroundPosition: "center center",
                      backgroundSize: "cover",
                    }}
                  >
                    {data.status === "LIVE" ? (
                      <div className="card-livestream-live-sec">
                        <img
                          className="card-livestream-liveIcon"
                          src={goLive}
                          alt=""
                        />
                        <span className="card-livestream-live-status-text">
                          Ends in 15 mins
                        </span>
                      </div>
                    ) : null}
                    <div className="card-livestream-content">
                      {/* <a
                        href="javascript:void(0)"
                        onClick={() => {}}
                        className="ratio ratio-1x1 mb-3 video-img"
                      >
                        <video
                          poster=""
                          className="my_club_media"
                          controls
                          controlsList="nodownload"
                          src={data?.video_url}
                        >
                          <source src="" type="video/mp4" />
                          Your browser does not support HTML video.
                        </video>
                      </a> */}

                      <h3 className="card-livestream-title">
                        <a
                          href="javascript:void(0)"
                          onClick={() => {}}
                          title="title"
                        >
                          {data?.title}
                        </a>
                      </h3>
                      <Link
                        to="asdf"
                        className="dive-in-channel position-relative"
                      >
                        @{data?.user_info[0]?.name}
                      </Link>
                      <span className="card-livestream-date">
                        {moment(data?.schedule_From).utc().format('DD-MM-YYYY hh:mm:ss A')} IST
                      </span>
                    </div>
                  </Link>
                </div>
              );
            })}
            {/* <div className="col-sm-6 col-lg-4 col-xxl-3 mb-4">
              <div
                className="card-livestream"
                style={{
                  backgroundImage: `url(${Banner})`,
                  backgroundPosition: "center center",
                  backgroundSize: "cover",
                }}
              >
                <div className="card-livestream-content">
                  <a
                    href="javascript:void(0)"
                    onClick={() => { }}
                    className="ratio ratio-1x1 mb-3 video-img"
                  >
                    <video
                      poster=""
                      className="my_club_media"
                      controls
                      controlsList="nodownload"
                    >
                      <source src="" type="video/mp4" />
                      Your browser does not support HTML video.
                    </video>
                  </a>

                  <h3 className="card-livestream-title">
                    <a
                      href="javascript:void(0)"
                      onClick={() => { }}
                      title="title"
                    >
                      Shadows of the past
                    </a>
                  </h3>
                  <Link to="asdf" className="dive-in-channel position-relative">
                    @amitabhbachchan
                  </Link>
                  <span className="card-livestream-date">
                    24 Aug, 8:00 pm IST
                  </span>
                </div>
                <Link className="btn btn-outline-black mx-auto" to="/livestreams/livestream-detail">View Details</Link>
              </div>
            </div> */}
          </div>
          {/* <button className="btn btn-outline-black btn-wide mx-auto">
            Load More
          </button> */}
          {/* {!isFetching && <p>Fetching items...</p>}
          {!isFetching && HasMore && (
            <button
              onClick={loadMoreItems}
              className="btn btn-outline-black btn-wide mx-auto"
            >
              Load More
            </button>
          )} */}
        </div>
      </section>
      <section className="livestream-coll-sec">
        <div className="container-fluid">
          <div className="livestream-coll-sec-title">
            <h2 className=" mb-2">Past Events</h2>
            <Link
              to="/livestreams/livestream-listing"
              className="btn btn-outline-black d-inline-flex  btn-wide"
            >
              View All
            </Link>
          </div>
          <div className="row">
            <Slider className="past-event-coll" {...settings}>
              {pasLlivestream.map((data: any, index: number) => {
                return (
                  <div key={index}>
                    <div
                      className="card-livestream square d-none d-md-flex"
                      style={{
                        backgroundImage: `url(${
                          data?.thumbnail ? data?.thumbnail : Banner
                        })`,

                        backgroundPosition: "center center",
                        backgroundSize: "cover",
                      }}
                    >
                      <div className="card-livestream-content">
                        {/* <a
                          href="javascript:void(0)"
                          onClick={() => {}}
                          className="ratio ratio-1x1 mb-3 video-img"
                        >
                          <video
                            poster=""
                            className="my_club_media"
                            controls
                            controlsList="nodownload"
                            src={data?.video_url}
                          >
                            <source src="" type="video/mp4" />
                            Your browser does not support HTML video.
                          </video>
                        </a> */}

                        <h3 className="card-livestream-title">
                          <a
                            href="javascript:void(0)"
                            onClick={() => {}}
                            title={data.title}
                          >
                            {data.title}
                          </a>
                        </h3>
                        <Link
                          to="asdf"
                          className="dive-in-channel position-relative mb-2"
                        >
                          @{data?.user_info[0]?.name}
                        </Link>
                        <Link
                          className="btn btn-outline-black mx-auto"
                          to={`/livestreams/livestream-detail/${data?._id}`}
                        >
                          View Details
                        </Link>
                      </div>
                    </div>
                    {/* Past Events Mobile View */}
                    <Link
                      to={`/livestreams/livestream-detail/${data?._id}`}
                      className="d-block d-md-none card-livestream square"
                      style={{
                        backgroundImage: `url(${
                          data?.thumbnail ? data?.thumbnail : Banner
                        })`,

                        backgroundPosition: "center center",
                        backgroundSize: "cover",
                      }}
                    >
                      <div className="card-livestream-content">
                        {/* <a
                          href="javascript:void(0)"
                          onClick={() => {}}
                          className="ratio ratio-1x1 mb-3 video-img"
                        >
                          <video
                            poster=""
                            className="my_club_media"
                            controls
                            controlsList="nodownload"
                            src={data?.video_url}
                          >
                            <source src="" type="video/mp4" />
                            Your browser does not support HTML video.
                          </video>
                        </a> */}

                        <h3 className="card-livestream-title">
                          <a
                            href="javascript:void(0)"
                            onClick={() => {}}
                            title={data.title}
                          >
                            {data.title}
                          </a>
                        </h3>
                        <span className="dive-in-channel position-relative mb-2">
                          @{data?.user_info[0]?.name}
                        </span>
                      </div>
                    </Link>
                  </div>
                );
              })}
              {/* <div className="">
                <div
                  className="card-livestream square"
                  style={{
                    backgroundImage: `url(${Banner})`,
                    backgroundPosition: "center center",
                    backgroundSize: "cover",
                  }}
                >
                  <div className="card-livestream-content">
                    <a
                      href="javascript:void(0)"
                      onClick={() => { }}
                      className="ratio ratio-1x1 mb-3 video-img"
                    >
                      <video
                        poster=""
                        className="my_club_media"
                        controls
                        controlsList="nodownload"
                      >
                        <source src="" type="video/mp4" />
                        Your browser does not support HTML video.
                      </video>
                    </a>

                    <h3 className="card-livestream-title">
                      <a
                        href="javascript:void(0)"
                        onClick={() => { }}
                        title="title"
                      >
                        Shadows of the past
                      </a>
                    </h3>
                    <Link
                      to="asdf"
                      className="dive-in-channel position-relative"
                    >
                      @amitabhbachchan
                    </Link>
                  </div>
                </div>
                </div>*/}
            </Slider>
          </div>
        </div>
      </section>
    </>
  );
};
const mapStateToProps = (state: any) => {
  return {
    UserReducer: state.UserReducer,
    LoginUserIdReducer: state.LoginUserIdReducer,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    loading: (data: any) => dispatch(Loading(data)),
  };
};

const connectedNavBar = connect(
  mapStateToProps,
  mapDispatchToProps
)(ViewAllUpcomingLivestream);
export { connectedNavBar as ViewAllUpcomingLivestream };
// export default ViewAllUpcomingLivestream;
