import React, { MouseEvent, UIEvent, useState } from 'react';
// import Login from './login';
import SignUp from './sign-up';
import { useSpring, animated } from 'react-spring';

interface Props {
    popupType: string | null;
    closePopup: () => void;
    handleLogin: (emailId: string, password: string) => void;
}

interface TransitionType {
    type: string | null;
    transition: string | null;
}
function stopEventPropagation(event: MouseEvent): void {
    event.stopPropagation();
}

function stopScrollPropagation(event: UIEvent): void {
    event.stopPropagation();
}

const FormPopup: React.FC<Props> = (props) => {
    const fade = useSpring({ from: { opacity: 0 }, to: { opacity: 1 }, delay: 100 });
    const [popupType, setPopupType] = useState<TransitionType>({ type: props.popupType, transition: null });
    return (
        <div >
            <animated.div style={fade} className="popup-center" onClick={props.closePopup} onScroll={stopScrollPropagation}>
                <div className="overlay"></div>
                <div >
                    <div className="login main-div d-md-block d-none" onClick={stopEventPropagation}>
                        <div className="row" style={{ width: '800px', minHeight: '520px' }}>
                            <div className="col-md-6 p-4 rounded-left"  >
                            {/* style={{ backgroundRepeat: 'repeat', backgroundImage: `url(${LoginBackground})` }} */}
                                <div className="f40 clr-dark-grey text-left line-height-1-1"> Let India’s best <br /> teach you <br /> how it’s done. </div>
                                <div className="position-absolute bottom-0 mb-3">
                                    {/* <img src={AppLogo} alt="logo" /> */}
                                    </div>
                            </div>
                            <div className="col-md-6 p-4 bg-white rounded-right" style={{ overflow: 'hidden' }}>
                                {/* {popupType.type === 'login' && <Login closePopup={props.closePopup} transition={popupType.transition} update={(transition) => setPopupType({ type: transition, transition })} />} */}
                                {popupType.type === 'signup' && <SignUp closePopup={props.closePopup} transition={popupType.transition} update={(transition) => setPopupType({ type: 'login', transition })} />}
                                <div className="text-right position-absolute right r-10 t-10">
                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={props.closePopup}>
                                        <span aria-hidden="true">
                                            Close
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </animated.div>
        </div>

    );
}
export default FormPopup;