import React from "react";
import clanLogo from "../../../assets/img/ClanClublogo.svg";
import cardImage from "../../../assets/img/deepika.jpg";
import { Link } from "react-router-dom";
type Props = {};

const PaymentConfirmation = (props: Props) => {
  return (
    <>
      <h3 className="text-center pt-4">
        Regular ticket Purchase confirmation email
      </h3>
      <strong className="text-center pb-4">
        Subject: Payment Confirmation for Live Event of Creator name
      </strong>
      <table
        className="mailer-container"
        style={{
          margin: "0 auto",
          display: "block",
          maxWidth: "700px",
          width: "100%",
          border: "1px solid #979797",
          borderRadius: "8px",
        }}
      >
        <tr>
          <td width={50}></td>
          <td width={600}>
            <tr
              style={{
                textAlign: "center",
                margin: "0 auto",
                display: "block",
                width: "100%",
              }}
            >
              <td
                style={{
                  textAlign: "center",
                  margin: "0 auto",
                  display: "block",
                  width: "100%",
                }}
              >
                <img
                  src={clanLogo}
                  alt=""
                  width="97"
                  height="55"
                  style={{ margin: "0 auto" }}
                />
              </td>
            </tr>
            <tr>
              <td height="5">&nbsp;</td>
            </tr>
            <tr
              style={{
                textAlign: "center",
                margin: "0 auto",
                display: "block",
                width: "100%",
              }}
            >
              <td
                style={{
                  textAlign: "center",
                  margin: "0 auto",
                  display: "block",
                  width: "100%",
                  fontSize: "16px",
                  paddingBottom: "10px",
                }}
              >
                Order ID: CWR-202203000213
              </td>
            </tr>
            <tr>
              <td height={10}></td>
            </tr>
            {/* Main content sec */}
            <tr>
              <td width="250" style={{ verticalAlign: "top" }}>
                <table
                  cellPadding="0"
                  cellSpacing="0"
                  width="100%"
                  style={{
                    borderRadius: "10px",
                    overflow: "hidden",
                    boxShadow: "0 0 18px 0 rgba(0,0,0,0.11)",
                  }}
                >
                  <tr>
                    <td colSpan={3}>
                      <img src={cardImage} alt="" width="300" height="150" />
                    </td>
                  </tr>
                  <tr>
                    <td width="30">&nbsp;</td>
                    <td width="215">
                      <table cellPadding="0" cellSpacing="0" width="100%">
                        <tr>
                          <td style={{ fontSize: "13px", paddingTop: "10px" }}>
                            Naach meri rani
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{
                              fontSize: "18px",
                              fontWeight: "700",
                              paddingBottom: "10px",
                            }}
                          >
                            Guru Randhawa
                          </td>
                        </tr>
                        <tr>
                          <td style={{ fontSize: "13px" }}>
                            12th February, 2:00 pm IST
                          </td>
                        </tr>
                        <tr>
                          <td
                            height={10}
                            style={{ borderBottom: "1px dashed #000" }}
                          ></td>
                        </tr>
                        <tr>
                          <td height={5}></td>
                        </tr>
                        <tr>
                          <td>
                            <table cellPadding="0" cellSpacing="0" width="100%">
                              <tr>
                                <td style={{ fontSize: "13px" }}>
                                  Ticket Price
                                </td>
                                <td style={{ fontSize: "13px" }} align="right">
                                  &#8377; 5,000
                                </td>
                              </tr>
                              <tr>
                                <td
                                  style={{
                                    fontSize: "13px",
                                    paddingBottom: "15px",
                                  }}
                                >
                                  Attendees
                                </td>
                                <td
                                  style={{
                                    fontSize: "13px",
                                    paddingBottom: "15px",
                                  }}
                                  align="right"
                                >
                                  4
                                </td>
                              </tr>
                              <tr>
                                <td
                                  style={{
                                    fontSize: "16px",
                                    paddingBottom: "15px",
                                    fontWeight: "bold",
                                  }}
                                >
                                  Total
                                </td>
                                <td
                                  style={{
                                    fontSize: "16px",
                                    paddingBottom: "15px",
                                    fontWeight: "bold",
                                  }}
                                  align="right"
                                >
                                  &#8377; 20,000
                                </td>
                              </tr>
                            </table>
                          </td>
                        </tr>
                      </table>
                    </td>
                    <td width="30">&nbsp;</td>
                  </tr>
                </table>
              </td>
              <td width="25" style={{ verticalAlign: "top" }}>
                &nbsp;
              </td>
              <td width="325" style={{ verticalAlign: "top" }}>
                <table cellPadding="0" cellSpacing="0" width="100%">
                  <tr>
                    <td>Naach meri rani</td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        fontSize: "28px",
                        paddingBottom: "5px",
                        fontWeight: "bold",
                        borderBottom: "1px solid #979797",
                        borderRadius: "8px",
                      }}
                    >
                      Guru Randhawa
                    </td>
                  </tr>
                  <tr>
                    <td style={{ height: "20px" }}>&nbsp;</td>
                  </tr>
                  <tr>
                    <td style={{ paddingBottom: "10px" }}>
                      12th February, 2:00 pm IST
                    </td>
                  </tr>
                  <tr>
                    <td>4 Tickets</td>
                  </tr>
                  <tr>
                    <td style={{ height: "30px" }}>&nbsp;</td>
                  </tr>
                  <tr>
                    <td>
                      Total Amount Paid: &nbsp;{" "}
                      <strong
                        style={{
                          fontSize: "16px",
                          paddingBottom: "15px",
                          fontWeight: "bold",
                        }}
                      >
                        &#8377;20,000
                      </strong>{" "}
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
          </td>
          <td width={50}></td>
        </tr>
        <tr>
          <td height={200}></td>
        </tr>
        <tr>
          <td width={50}></td>
          <td
            style={{
              height: "1px",
              borderBottom: "1px solid #979797",
              display: "block",
              width: "100%",
            }}
          ></td>
          <td width={50}></td>
        </tr>
        <tr>
          <td height={20}></td>
        </tr>
        <tr
          style={{
            textAlign: "center",
            margin: "0 auto",
            display: "block",
            width: "100%",
          }}
        >
          <td
            style={{
              textAlign: "center",
              margin: "0 auto",
              display: "block",
              width: "100%",
              fontSize: "16px",
              paddingBottom: "10px",
            }}
          >
            Team Clan World
          </td>
        </tr>
        <tr
          style={{
            textAlign: "center",
            margin: "0 auto",
            display: "block",
            width: "100%",
          }}
        >
          <td
            style={{
              textAlign: "center",
              margin: "0 auto",
              display: "block",
              width: "100%",
              fontSize: "16px",
              paddingBottom: "10px",
            }}
          >
            For any queries please contact{" "}
            <a href="mailto:support@clanworld.ai">support@clanworld.ai</a>
          </td>
        </tr>
        <tr>
          <td height={15}></td>
        </tr>
        <tr
          style={{
            textAlign: "center",
            margin: "0 auto",
            display: "block",
            width: "100%",
          }}
        >
          <td
            style={{
              textAlign: "center",
              margin: "0 auto",
              display: "block",
              width: "100%",
              fontSize: "16px",
              paddingBottom: "10px",
            }}
          >
            <a href="">Unsubscribe</a>
          </td>
        </tr>
        <tr>
          <td height={30}></td>
        </tr>
      </table>
      {/* NFT + Purchase ticket confirmation */}
      <h3 className="text-center pt-4">NFT + Purchase ticket confirmation</h3>
      <strong className="text-center pb-4">
        Subject: Payment Confirmation for NFT Ticketed Live Event of Creator
        name
      </strong>
      <table
        className="mailer-container"
        style={{
          margin: "0 auto",
          display: "block",
          maxWidth: "700px",
          width: "100%",
          border: "1px solid #979797",
          borderRadius: "8px",
        }}
      >
        <tr>
          <td width={50}></td>
          <td width={600}>
            <tr
              style={{
                textAlign: "center",
                margin: "0 auto",
                display: "block",
                width: "100%",
              }}
            >
              <td
                style={{
                  textAlign: "center",
                  margin: "0 auto",
                  display: "block",
                  width: "100%",
                }}
              >
                <img
                  src={clanLogo}
                  alt=""
                  width="97"
                  height="55"
                  style={{ margin: "0 auto" }}
                />
              </td>
            </tr>
            <tr>
              <td height="5">&nbsp;</td>
            </tr>
            <tr
              style={{
                textAlign: "center",
                margin: "0 auto",
                display: "block",
                width: "100%",
              }}
            >
              <td
                style={{
                  textAlign: "center",
                  margin: "0 auto",
                  display: "block",
                  width: "100%",
                  fontSize: "16px",
                  paddingBottom: "10px",
                }}
              >
                Order ID: CWR-202203000213
              </td>
            </tr>
            <tr>
              <td height={10}></td>
            </tr>
            {/* Main content sec */}
            <tr>
              <td width="250" style={{ verticalAlign: "top" }}>
                <table
                  cellPadding="0"
                  cellSpacing="0"
                  width="100%"
                  style={{
                    borderRadius: "10px",
                    overflow: "hidden",
                    boxShadow: "0 0 18px 0 rgba(0,0,0,0.11)",
                  }}
                >
                  <tr>
                    <td colSpan={3}>
                      <img src={cardImage} alt="" width="300" height="150" />
                    </td>
                  </tr>
                  <tr>
                    <td width="30">&nbsp;</td>
                    <td width="215">
                      <table cellPadding="0" cellSpacing="0" width="100%">
                        <tr>
                          <td style={{ fontSize: "13px", paddingTop: "10px" }}>
                            Naach meri rani
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{
                              fontSize: "18px",
                              fontWeight: "700",
                              paddingBottom: "10px",
                            }}
                          >
                            Guru Randhawa
                          </td>
                        </tr>
                        <tr>
                          <td style={{ fontSize: "13px" }}>
                            12th February, 2:00 pm IST
                          </td>
                        </tr>
                        <tr>
                          <td
                            height={10}
                            style={{ borderBottom: "1px dashed #000" }}
                          ></td>
                        </tr>
                        <tr>
                          <td height={5}></td>
                        </tr>
                        <tr>
                          <td>
                            <table cellPadding="0" cellSpacing="0" width="100%">
                              <tr>
                                <td style={{ fontSize: "13px" }}>
                                  Ticket Price
                                </td>
                                <td style={{ fontSize: "13px" }} align="right">
                                  &#8377; 5,000
                                </td>
                              </tr>
                              <tr>
                                <td
                                  style={{
                                    fontSize: "13px",
                                    paddingBottom: "15px",
                                  }}
                                >
                                  Attendees
                                </td>
                                <td
                                  style={{
                                    fontSize: "13px",
                                    paddingBottom: "15px",
                                  }}
                                  align="right"
                                >
                                  4
                                </td>
                              </tr>
                              <tr>
                                <td
                                  style={{
                                    fontSize: "16px",
                                    paddingBottom: "15px",
                                    fontWeight: "bold",
                                  }}
                                >
                                  Total
                                </td>
                                <td
                                  style={{
                                    fontSize: "16px",
                                    paddingBottom: "15px",
                                    fontWeight: "bold",
                                  }}
                                  align="right"
                                >
                                  &#8377; 20,000
                                </td>
                              </tr>
                            </table>
                          </td>
                        </tr>
                      </table>
                    </td>
                    <td width="30">&nbsp;</td>
                  </tr>
                </table>
              </td>
              <td width="25" style={{ verticalAlign: "top" }}>
                &nbsp;
              </td>
              <td width="325" style={{ verticalAlign: "top" }}>
                <table cellPadding="0" cellSpacing="0" width="100%">
                  <tr>
                    <td>Naach meri rani</td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        fontSize: "28px",
                        paddingBottom: "5px",
                        fontWeight: "bold",
                        borderBottom: "1px solid #979797",
                        borderRadius: "8px",
                      }}
                    >
                      Guru Randhawa
                    </td>
                  </tr>
                  <tr>
                    <td style={{ height: "20px" }}>&nbsp;</td>
                  </tr>
                  <tr>
                    <td style={{ paddingBottom: "10px" }}>
                      12th February, 2:00 pm IST
                    </td>
                  </tr>
                  <tr>
                    <td>4 Tickets</td>
                  </tr>
                  <tr>
                    <td style={{ height: "30px" }}>&nbsp;</td>
                  </tr>
                  <tr>
                    <td>
                      Total Amount Paid: &nbsp;{" "}
                      <strong
                        style={{
                          fontSize: "16px",
                          paddingBottom: "15px",
                          fontWeight: "bold",
                        }}
                      >
                        &#8377;20,000
                      </strong>{" "}
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
            <tr>
              <td height={70}></td>
            </tr>
            <tr>
              <td colSpan={3}>
                <table
                  style={{ textAlign: "center" }}
                  width="100%"
                  cellSpacing="0"
                  cellPadding="0"
                >
                  <tr
                    style={{
                      textAlign: "center",
                      margin: "0 auto",
                      display: "block",
                      width: "100%",
                    }}
                  >
                    <td
                      style={{
                        padding: "6px",
                        border: "1px solid #979797",
                        display: "block",
                        width: "100%",
                        borderRadius: "5px",
                      }}
                    >
                      NFT Ticketing
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        textAlign: "center",
                        margin: "0 auto",
                        display: "block",
                        width: "100%",
                      }}
                      height={20}
                    ></td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        width: "100%",
                        textAlign: "center",
                        fontWeight: "bold",
                      }}
                    >
                      4 NFTs
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        width: "100%",
                      }}
                      height={10}
                    ></td>
                  </tr>
                  <tr>
                    <td
                      colSpan={2}
                      style={{ width: "100%", textAlign: "center" }}
                    >
                      Once the Livestream ends, your NFT will be minted and
                      visible in
                      <br />
                      24 hrs under your NFTs on ClanWorld .
                    </td>
                  </tr>
                  <tr>
                    <td height={30}></td>
                  </tr>
                  <tr>
                    <td style={{ width: "100%", textAlign: "center" }}>
                      <button
                        type="button"
                        style={{
                          backgroundColor: "#2b2b2b",
                          color: "#fff",
                          borderRadius: "50rem",
                          padding: "10px 50px",
                          border: "1px solid transparent",
                          textAlign: "center",
                        }}
                      >
                        View my NFT
                      </button>
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
          </td>
          <td width={50}></td>
        </tr>
        <tr>
          <td colSpan={3} height={200}></td>
        </tr>

        <tr>
          <td width={50}></td>
          <td
            style={{
              height: "1px",
              borderBottom: "1px solid #979797",
              display: "block",
              width: "100%",
            }}
          ></td>
          <td width={50}></td>
        </tr>
        <tr>
          <td height={20}></td>
        </tr>
        <tr
          style={{
            textAlign: "center",
            margin: "0 auto",
            display: "block",
            width: "100%",
          }}
        >
          <td
            style={{
              textAlign: "center",
              margin: "0 auto",
              display: "block",
              width: "100%",
              fontSize: "16px",
              paddingBottom: "10px",
            }}
          >
            Team Clan World
          </td>
        </tr>
        <tr
          style={{
            textAlign: "center",
            margin: "0 auto",
            display: "block",
            width: "100%",
          }}
        >
          <td
            style={{
              textAlign: "center",
              margin: "0 auto",
              display: "block",
              width: "100%",
              fontSize: "16px",
              paddingBottom: "10px",
            }}
          >
            For any queries please contact{" "}
            <a href="mailto:support@clanworld.ai">support@clanworld.ai</a>
          </td>
        </tr>
        <tr>
          <td height={15}></td>
        </tr>
        <tr
          style={{
            textAlign: "center",
            margin: "0 auto",
            display: "block",
            width: "100%",
          }}
        >
          <td
            style={{
              textAlign: "center",
              margin: "0 auto",
              display: "block",
              width: "100%",
              fontSize: "16px",
              paddingBottom: "10px",
            }}
          >
            <a href="">Unsubscribe</a>
          </td>
        </tr>
        <tr>
          <td height={30}></td>
        </tr>
      </table>
      {/* Ticket link email to all attendees */}
      <h3 className="text-center pt-4">Ticket link email to all attendees</h3>
      <strong className="text-center pb-4">
        Subject: Your Ticket to The Live Event of Creator name
      </strong>
      <table
        className="mailer-container"
        style={{
          margin: "0 auto",
          display: "block",
          maxWidth: "700px",
          width: "100%",
          border: "1px solid #979797",
          borderRadius: "8px",
          textAlign: "center",
        }}
      >
        <tr>
          <td style={{ height: "50px" }}>&nbsp;</td>
        </tr>
        <tr>
          <td width={50}></td>
          <td width={600} align="center">
            <tr
              style={{
                textAlign: "center",
                margin: "0 auto",
                display: "block",
                width: "100%",
              }}
            >
              <td
                style={{
                  textAlign: "center",
                  margin: "0 auto",
                  display: "block",
                  width: "100%",
                }}
              >
                <img
                  src={clanLogo}
                  alt=""
                  width="97"
                  height="55"
                  style={{ margin: "0 auto" }}
                />
              </td>
            </tr>
            <tr>
              <td height="5">&nbsp;</td>
            </tr>

            <tr>
              <td height={10}></td>
            </tr>
            {/* Main content sec */}
            <tr>
              <td>
                <table style={{ width: "100%", textAlign: "center" }}>
                  <tr>
                    <td>
                      <strong style={{ fontSize: "18px" }}>Arijit Singh</strong>
                      <br />
                      Tum hi ho musical
                    </td>
                  </tr>
                  <tr>
                    <td style={{ fontWeight: "600" }}>
                      Tuesday, 13th March, 02:00 pm to 02:45 pm IST
                    </td>
                  </tr>
                  <tr>
                    <td style={{ height: "20px" }}>&nbsp;</td>
                  </tr>
                  <tr>
                    <td>
                      <Link
                        to="/livestreams/livestream-soon-private/6255121ac6be112871861aa8"
                        style={{
                          backgroundColor: "#2b2b2b",
                          color: "#fff",
                          borderRadius: "50rem",
                          padding: "5px 50px",
                          border: "1px solid transparent",
                          textAlign: "center",
                          fontWeight: "700",
                          textDecoration: "none",
                        }}
                      >
                        Enter
                      </Link>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ height: "20px" }}>&nbsp;</td>
                  </tr>
                  <tr>
                    <td align="left">
                      Watch now by clicking below link or copy-paste the same in
                      your browser.
                      <br />
                      <Link to="" style={{ fontSize: "14px" }}>
                        https://d1imluh7ioqiru.cloudfront.net/#/livestreams/livestream-soon-private
                      </Link>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ height: "20px" }}>&nbsp;</td>
                  </tr>
                  <tr>
                    <td style={{ height: "20px" }}>OR</td>
                  </tr>
                  <tr>
                    <td style={{ height: "20px" }}>&nbsp;</td>
                  </tr>
                  <tr style={{ textAlign: "left" }}>
                    <td>
                      Log In to our web portal <Link to="">https://clanworld.ai</Link> with the
                      below credentials and navigate to the purchased ticket
                      section.
                    </td>
                  </tr>
                  <tr style={{ textAlign: "left" }}>
                    <td>
                      <strong>Email:</strong> sravi@clanconnect.ai
                      <br />
                      <strong>Mobile:</strong> 9871388299
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
          </td>
          <td width={50}></td>
        </tr>
        <tr>
          <td colSpan={3} height={50}></td>
        </tr>

        <tr>
          <td width={50}></td>
          <td
            style={{
              height: "1px",
              borderBottom: "1px solid #979797",
              display: "block",
              width: "100%",
            }}
          ></td>
          <td width={50}></td>
        </tr>
        <tr>
          <td height={20}></td>
        </tr>
        <tr
          style={{
            textAlign: "center",
            margin: "0 auto",
            display: "block",
            width: "100%",
          }}
        >
          <td
            style={{
              textAlign: "center",
              margin: "0 auto",
              display: "block",
              width: "100%",
              fontSize: "16px",
              paddingBottom: "10px",
            }}
          >
            Team Clan World
          </td>
        </tr>
        <tr
          style={{
            textAlign: "center",
            margin: "0 auto",
            display: "block",
            width: "100%",
          }}
        >
          <td
            style={{
              textAlign: "center",
              margin: "0 auto",
              display: "block",
              width: "100%",
              fontSize: "16px",
              paddingBottom: "10px",
            }}
          >
            For any queries please contact{" "}
            <a href="mailto:support@clanworld.ai">support@clanworld.ai</a>
          </td>
        </tr>
        <tr>
          <td height={15}></td>
        </tr>
        <tr
          style={{
            textAlign: "center",
            margin: "0 auto",
            display: "block",
            width: "100%",
          }}
        >
          <td
            style={{
              textAlign: "center",
              margin: "0 auto",
              display: "block",
              width: "100%",
              fontSize: "16px",
              paddingBottom: "10px",
            }}
          >
            <a href="">Unsubscribe</a>
          </td>
        </tr>
        <tr>
          <td height={30}></td>
        </tr>
      </table>
      <span style={{ margin: "0 auto", maxWidth: "700px" }}>
        This mail will go:
        <br />
        24 hrs prior to event. Subject: Only 1 day left! Your Ticket to The Live
        Event of creator name
        <br />
        6 hrs prior to event. Subejct: Only 6 hrs left! Your Ticket to The Live
        Event of creator name
        <br />
        2 hrs prior to event: Subejct: Only 2 hrs left! Your Ticket to The Live
        Event of creator name
        <br />
        30 min prior to event: Subejct: Only 30 mins left! Your Ticket to The
        Live Event of creator name
        <br />
      </span>

      {/* NFT minted confirmation email */}
      <h3 className="text-center pt-4">NFT minted confirmation email</h3>
      <strong className="text-center pb-4">
        Subject: Your NFT(s) for the Live Event of creator name is ready!
      </strong>
      <table
        className="mailer-container"
        style={{
          margin: "0 auto",
          display: "block",
          maxWidth: "700px",
          width: "100%",
          border: "1px solid #979797",
          borderRadius: "8px",
        }}
      >
        <tr>
          <td width={50}></td>
          <td width={600}>
            <tr
              style={{
                textAlign: "center",
                margin: "0 auto",
                display: "block",
                width: "100%",
              }}
            >
              <td
                style={{
                  textAlign: "center",
                  margin: "0 auto",
                  display: "block",
                  width: "100%",
                }}
              >
                <img
                  src={clanLogo}
                  alt=""
                  width="97"
                  height="55"
                  style={{ margin: "0 auto" }}
                />
              </td>
            </tr>
            <tr>
              <td height="5">&nbsp;</td>
            </tr>
            <tr
              style={{
                textAlign: "center",
                margin: "0 auto",
                display: "block",
                width: "100%",
              }}
            >
              <td
                style={{
                  textAlign: "center",
                  margin: "0 auto",
                  display: "block",
                  width: "100%",
                  fontSize: "16px",
                  paddingBottom: "10px",
                }}
              >
                Order ID: CWR-202203000213
              </td>
            </tr>
            <tr>
              <td height={10}></td>
            </tr>
            {/* Main content sec */}
            <tr>
              <td width="250" style={{ verticalAlign: "top" }}>
                <table
                  cellPadding="0"
                  cellSpacing="0"
                  width="100%"
                  style={{
                    borderRadius: "10px",
                    overflow: "hidden",
                    boxShadow: "0 0 18px 0 rgba(0,0,0,0.11)",
                  }}
                >
                  <tr>
                    <td colSpan={3}>
                      <img src={cardImage} alt="" width="300" height="150" />
                    </td>
                  </tr>
                  <tr>
                    <td width="30">&nbsp;</td>
                    <td width="215">
                      <table cellPadding="0" cellSpacing="0" width="100%">
                        <tr>
                          <td style={{ fontSize: "13px", paddingTop: "10px" }}>
                            Naach meri rani
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{
                              fontSize: "18px",
                              fontWeight: "700",
                              paddingBottom: "10px",
                            }}
                          >
                            Guru Randhawa
                          </td>
                        </tr>
                        <tr>
                          <td style={{ fontSize: "13px" }}>
                            12th February, 2:00 pm IST
                          </td>
                        </tr>
                        <tr>
                          <td
                            height={10}
                            style={{ borderBottom: "1px dashed #000" }}
                          ></td>
                        </tr>
                        <tr>
                          <td height={5}></td>
                        </tr>
                        <tr>
                          <td>
                            <table cellPadding="0" cellSpacing="0" width="100%">
                              <tr>
                                <td style={{ fontSize: "13px" }}>
                                  Ticket Price
                                </td>
                                <td style={{ fontSize: "13px" }} align="right">
                                  &#8377; 5,000
                                </td>
                              </tr>
                              <tr>
                                <td
                                  style={{
                                    fontSize: "13px",
                                    paddingBottom: "15px",
                                  }}
                                >
                                  Attendees
                                </td>
                                <td
                                  style={{
                                    fontSize: "13px",
                                    paddingBottom: "15px",
                                  }}
                                  align="right"
                                >
                                  4
                                </td>
                              </tr>
                              <tr>
                                <td
                                  style={{
                                    fontSize: "16px",
                                    paddingBottom: "15px",
                                    fontWeight: "bold",
                                  }}
                                >
                                  Total
                                </td>
                                <td
                                  style={{
                                    fontSize: "16px",
                                    paddingBottom: "15px",
                                    fontWeight: "bold",
                                  }}
                                  align="right"
                                >
                                  &#8377; 20,000
                                </td>
                              </tr>
                            </table>
                          </td>
                        </tr>
                      </table>
                    </td>
                    <td width="30">&nbsp;</td>
                  </tr>
                </table>
              </td>
              <td width="25" style={{ verticalAlign: "top" }}>
                &nbsp;
              </td>
              <td width="325" style={{ verticalAlign: "top" }}>
                <table cellPadding="0" cellSpacing="0" width="100%">
                  <tr>
                    <td>Naach meri rani</td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        fontSize: "28px",
                        paddingBottom: "5px",
                        fontWeight: "bold",
                        borderBottom: "1px solid #979797",
                        borderRadius: "8px",
                      }}
                    >
                      Guru Randhawa
                    </td>
                  </tr>
                  <tr>
                    <td style={{ height: "20px" }}>&nbsp;</td>
                  </tr>
                  <tr>
                    <td style={{ paddingBottom: "10px" }}>
                      12th February, 2:00 pm IST
                    </td>
                  </tr>
                  <tr>
                    <td>4 Tickets</td>
                  </tr>
                  <tr>
                    <td style={{ height: "30px" }}>&nbsp;</td>
                  </tr>
                  <tr>
                    <td>
                      Total Amount Paid: &nbsp;{" "}
                      <strong
                        style={{
                          fontSize: "16px",
                          paddingBottom: "15px",
                          fontWeight: "bold",
                        }}
                      >
                        &#8377;20,000
                      </strong>{" "}
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
            <tr>
              <td height={70}></td>
            </tr>
            <tr>
              <td colSpan={3}>
                <table
                  style={{ textAlign: "center" }}
                  width="100%"
                  cellSpacing="0"
                  cellPadding="0"
                >
                  <tr
                    style={{
                      textAlign: "center",
                      margin: "0 auto",
                      display: "block",
                      width: "100%",
                    }}
                  >
                    <td
                      style={{
                        padding: "6px",
                        border: "1px solid #979797",
                        display: "block",
                        width: "100%",
                        borderRadius: "5px",
                      }}
                    >
                      NFT Ticketing
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        textAlign: "center",
                        margin: "0 auto",
                        display: "block",
                        width: "100%",
                      }}
                      height={20}
                    ></td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        width: "100%",
                        textAlign: "center",
                        fontWeight: "bold",
                      }}
                    >
                      4 NFTs
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        width: "100%",
                      }}
                      height={10}
                    ></td>
                  </tr>
                  <tr>
                    <td
                      colSpan={2}
                      style={{ width: "100%", textAlign: "center" }}
                    >
                      Your NFTs are ready!
                    </td>
                  </tr>
                  <tr>
                    <td height={30}></td>
                  </tr>
                  <tr>
                    <td style={{ width: "100%", textAlign: "center" }}>
                      <button
                        type="button"
                        style={{
                          backgroundColor: "#2b2b2b",
                          color: "#fff",
                          borderRadius: "50rem",
                          padding: "10px 50px",
                          border: "1px solid transparent",
                          textAlign: "center",
                        }}
                      >
                        View my NFT
                      </button>
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
          </td>
          <td width={50}></td>
        </tr>
        <tr>
          <td colSpan={3} height={200}></td>
        </tr>

        <tr>
          <td width={50}></td>
          <td
            style={{
              height: "1px",
              borderBottom: "1px solid #979797",
              display: "block",
              width: "100%",
            }}
          ></td>
          <td width={50}></td>
        </tr>
        <tr>
          <td height={20}></td>
        </tr>
        <tr
          style={{
            textAlign: "center",
            margin: "0 auto",
            display: "block",
            width: "100%",
          }}
        >
          <td
            style={{
              textAlign: "center",
              margin: "0 auto",
              display: "block",
              width: "100%",
              fontSize: "16px",
              paddingBottom: "10px",
            }}
          >
            Team Clan World
          </td>
        </tr>
        <tr
          style={{
            textAlign: "center",
            margin: "0 auto",
            display: "block",
            width: "100%",
          }}
        >
          <td
            style={{
              textAlign: "center",
              margin: "0 auto",
              display: "block",
              width: "100%",
              fontSize: "16px",
              paddingBottom: "10px",
            }}
          >
            For any queries please contact{" "}
            <a href="mailto:support@clanworld.ai">support@clanworld.ai</a>
          </td>
        </tr>
        <tr>
          <td height={15}></td>
        </tr>
        <tr
          style={{
            textAlign: "center",
            margin: "0 auto",
            display: "block",
            width: "100%",
          }}
        >
          <td
            style={{
              textAlign: "center",
              margin: "0 auto",
              display: "block",
              width: "100%",
              fontSize: "16px",
              paddingBottom: "10px",
            }}
          >
            <a href="">Unsubscribe</a>
          </td>
        </tr>
        <tr>
          <td height={30}></td>
        </tr>
      </table>
    </>
  );
};

export default PaymentConfirmation;
