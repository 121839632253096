import { UserData, UserPost } from "../model/user";
import API from "../utils/axios";

export class NftService {
    constructor() { }
    static getNftByCreator(id: any): Promise<any> {
        return API.get(`/getNftByCreator/${id}`);
    }

    static getNftList(id: any): Promise<any> {
        return API.get(`/getNftList/${id}`);
    }

    static nftListingDashsboard(): Promise<any> {
        return API.get(`/nftListingDashsboard`);
    }

    static getNftReportDetails(id: string): Promise<any> {
        return API.get(`/getNftReportDetails/${id}`);
    }

    static getNftBySpecificCreatorID(id: string): Promise<any> {
        return API.get(`/getNftBySpecificCreatorID/${id}`);
    }

    static forMint(data: any): Promise<any> {
        return API.post(`/forMint`, data);
    }

    static createNft(data: any): Promise<any> {
        return API.post(`/createNft`, data);
    }

    static getParticularNftList(data: any): Promise<any> {
        return API.post(`/getParticularNftList`, data);
    }

    static getOwningNftByUserID(id: string): Promise<any> {
        return API.get(`/getOwningNftByUserID/${id}`);
    }

}