import ReactGA from 'react-ga';
import { GOOGLE_ANALYTICS_ID } from "../../config/constant";
ReactGA.initialize(`${GOOGLE_ANALYTICS_ID}`);
export class googleAnalytics {
    constructor() {
    }

    static async loadGoogleAnalytics(requestData: any) {
        console.log(requestData, 'requestData');

        try {
            ReactGA.event({
                category: 'User Navigation',
                action: `${requestData.action} - by ${requestData.user_id ? requestData.user_id : 'GUEST'}- ${requestData.livestream_id ? requestData.livestream_id : ''} on ${new Date()}`,
            });
        } catch (error) {
            console.log(error, 'error in loadGoogleAnalytics');
        }
    }
}