import axios from 'axios';
import { toast } from 'react-toastify';
import { SessionExpirePopup } from '../../components/session-expire-popup';
// import { useHistory } from 'react-router-dom';
import { BASE_URL } from '../../config/constant';
const CryptoJS = require("crypto-js");
const algorithm = 'aes-128-cbc';
const key = 'jvdvd5rfdf3lva9v';//Gindowa@123#';
const iv = 'jvdvd5rfdf3lva9v';


const API = axios.create({
    baseURL: BASE_URL,
    // headers: {
    //     authorization: sessionStorage.getItem('token') || ''
    // }
});
// const history = useHistory();

API.interceptors.request.use(async function (config: any) {
    if ((config.method == 'post' || config.method == 'put') && !config.url.includes('https://clanworldassets.s3.ap-south-1.amazonaws.com')) {
        let obj = {
            data: ''
        };
        obj.data = await dataEncryption(config.data);
        config.data = obj;
    }
    // const token = localStorage.getItem('token');
    // config.headers['Content-Type'] = 'application/json';
    // config.headers.Authorization = token ? `${token}` : '';
    if (!config.url.includes('https://clanworldassets.s3.ap-south-1.amazonaws.com')) {
        const token = localStorage.getItem('token');
        config.headers['Content-Type'] = 'application/json';
        config.headers.Authorization = token ? `${token}` : '';
    }
    return config;
});
// API.interceptors.request.use(function (config: any) {
//     const token = sessionStorage.getItem('token');
//     config.headers['Content-Type'] = 'application/json';
//     config.headers.Authorization = token ? `Bearer ${token}` : '';
//     return config;
// });
API.interceptors.response.use(
    async (response: any) => {
        try {
            const returnData = await dataDecryption(response.data.data);
            // console.log('returnData', returnData);

            // const returnData = response.data.data;
            return returnData;
        } catch (error) {
            console.log('error error aa', error);

        }

    }, (error) => {
        console.log('error', error.response.data);

        let errorStatusCode = JSON.parse(JSON.stringify(error));
        if (errorStatusCode.status === 401) {
            // history.push('/');
            sessionStorage.clear();
            localStorage.clear();
            alert(error?.response?.data?.error_msg);
            // return <SessionExpirePopup show={true}></SessionExpirePopup>
            // toast.error('Session expired, Please login');
            return window.location.href = '/';
        } else {
            // alert(error?.response?.data?.error);
        toast.error(error?.response?.data?.error);


        }
        // return error.response.data;
    }
);

export async function dataEncryption(ciphertext: any) {
    const cipher = CryptoJS.AES.encrypt(
        JSON.stringify(ciphertext),
        key
    ).toString();
    return cipher;
    // return {
    //     content: cipher.toString("hex"),
    // };
}
export async function dataDecryption(deciphertext: any) {
    var bytes = CryptoJS.AES.decrypt(deciphertext, key);
    var originalText = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    return originalText;
    // return {
    //     originalText: originalText,
    // };
}

// export async function dataEncryption(data: any) {
//     try {
//         const ivAlloc = Buffer.alloc(16, iv);
//         var cipher = CryptoJS.createCipheriv(algorithm, key, ivAlloc);
//         var encrypted = cipher.update(JSON.stringify(data), 'utf8', 'hex') + cipher.final('hex');
//         const decr = await dataDecryption(encrypted);
//         return encrypted;
//     } catch (err) {
//         return false;
//     }
// }

// export async function dataDecryption(data: any) {
//     try {
//         const ivAlloc = Buffer.alloc(16, iv);
//         var decipher = CryptoJS.createDecipheriv(algorithm, key, ivAlloc);
//         var decrypted = decipher.update(data, 'hex', 'utf8') + decipher.final('utf8');
//         return JSON.parse(decrypted);
//     } catch (err) {
//         return false;
//     }

// }
export default API;