import { S3Client } from "@aws-sdk/client-s3";
import { Upload } from "@aws-sdk/lib-storage";
import AWS from "aws-sdk";
import moment from "moment";
import { AwsFolderInfo } from "../model/clubs";
import API from "./axios";

export class S3Helper {
  constructor() {
    AWS.config.update({
      accessKeyId: "AKIAV4Y6RJHNSABI6XIQ",
      secretAccessKey: "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",
    });
  }

  static async uploadFile(file: any, progressCB: (progress: any) => void, awsInfo: AwsFolderInfo): Promise<any> {
    const target = {
      Bucket: awsInfo?.bucketName,
      Key: `${awsInfo?.folderPath}/${file?.name}`,
      Body: file,
      ACL: "public-read",
    };
    const parellelUpload = new Upload({
      client: new S3Client({
        region: "ap-south-1",
        credentials: {
          accessKeyId: "AKIAV4Y6RJHNSABI6XIQ",
          secretAccessKey: "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",
        },
      }),
      leavePartsOnError: false,
      params: target,
    });
    parellelUpload.on("httpUploadProgress", progressCB);
    const result = await parellelUpload.done();
    return result;
  }

  static async uploadFilesToS3(file: any, awsInfo: AwsFolderInfo): Promise<string> {
    try {
      console.log(file, 'file');

      const imageNames = file.name.split('.');
      const currentData = new Date();
      const renamedFile = `${moment().valueOf()}.${imageNames[1]}`
      var newParams: any = {
        Key: renamedFile,
        Body: file,
        ACL: 'public-read'
      };
      AWS.config.update({
        accessKeyId: "AKIAV4Y6RJHNSABI6XIQ",
        secretAccessKey: "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",
        region: 'ap-south-1'
      });
      const userFolderName = `${awsInfo?.bucketName}/${awsInfo?.folderPath}`;
      const s3Bucket = await new AWS.S3({ params: { Bucket: userFolderName } });
      let responseUrl: any = new Promise(async (resolve, reject) => {
        s3Bucket.upload(newParams, function (err: any, data: any) {
          if (err) {
            console.log(err, 'in AWS Helper');

            reject(err);
          } else {
            console.log(data.Location, 'in Resolve Loaction');

            resolve(data.Location);
          }
        });
      });
      console.log('return', `${userFolderName}/${renamedFile}`);

      return `${userFolderName}/${renamedFile}`;
    } catch (err) {
      console.log(err, 'error aws helper');

      return '';
    }
  }

  static async uploadFilesToS3BySigned(signedUrl: any, file: any, fileType: any) {
    try {
      const formData = new FormData();
      formData.append('Content-Type', fileType);
      // Object.entries(signedUrl.fields).forEach(([k, v]) => {
      //   formData.append(k, v);
      // });
      formData.append('file', file);
      const myHeaders = new Headers({ 'Content-Type': fileType });
      const response = await fetch(signedUrl, {
        method: 'PUT',
        headers: myHeaders,
        body: file
      });
      // const response = await API.post(signedUrl, { body: file }, {
      //   headers: { 'Content-Type': 'multipart/form-data' },
      // });
      console.log('response', response);
      return response;
      // API.put(signedUrl, { body: file }).then(res => {
      //   console.log(res, ' in S3 helper');

      // })

      // console.log(file, 'file');

      // const imageNames = file.name.split('.');
      // const currentData = new Date();
      // const renamedFile = `${moment().valueOf()}.${imageNames[1]}`
      // var newParams: any = {
      //   Key: renamedFile,
      //   Body: file,
      //   ACL: 'public-read'
      // };
      // AWS.config.update({
      //   accessKeyId: "AKIAV4Y6RJHNSABI6XIQ",
      //   secretAccessKey: "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",
      // });
      // const userFolderName = `${awsInfo?.bucketName}/${awsInfo?.folderPath}`;
      // const s3Bucket = await new AWS.S3({ params: { Bucket: userFolderName } });
      // let responseUrl: any = new Promise(async (resolve, reject) => {
      //   s3Bucket.upload(newParams,signedUrl, function (err: any, data: any) {
      //     if (err) {
      //       console.log(err, 'in AWS Helper');

      //       reject(err);
      //     } else {
      //       console.log(data.Location, 'in Resolve Loaction');

      //       resolve(data.Location);
      //     }
      //   });
      // });
      // return responseUrl;
    } catch (err) {
      console.log(err, 'error aws helper');

      return '';
    }
  }
}
