import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
import TimePicker from "@mui/lab/TimePicker";
import { Link, NavLink, useHistory, useParams } from "react-router-dom";
import moment from "moment";
import { UserService } from "../../../app/service/user.service";
import { ClubService } from "../../../app/service/club.service";
import { LivestreamService } from "../../../app/service/livestream";
import { S3Helper } from "../../../app/utils/s3-helper";
import { toast } from "react-toastify";
import { Modal, OverlayTrigger } from "react-bootstrap";
import { Checkbox, FormControlLabel } from "@mui/material";
import { TooltipWrapper } from "../../../components/tooltip";
import Multiselect from "multiselect-react-dropdown";
import { CLOUDFRONT_URL, S3_URL, RAZORPAY_KEY } from "../../../config/constant";
import { Loading } from "../../../redux/actions";
import { connect, useDispatch } from "react-redux";
import Web3 from "web3";
import {
  NFT_PUBLIC_SMART_CONTRACT_ADDRESS,
  NFT_SMART_CONTRACT_ABI,
  MUMBAI_NETWORK_RPC,
} from "../../../config/nft_config";
import axios from "axios";
import useRazorpay, { RazorpayOptions } from "react-razorpay";
import razorPayIcon from "../../../assets/img/razorpay.svg";
import CircularProgress from '@mui/material/CircularProgress';
import { switchChain } from "../../../config/nft-switch-chain";
// import Box from '@mui/material/Box';
declare let window: any;

const steps = ["Basic Details", "Info & Cover Photo"];

interface Props {
  loading?: (data: any) => void;
}

const CreateLivestream: React.FC<Props> = (props) => {
  const dispatch = useDispatch();

  let { id } = useParams<{ id: string }>();
  const [activeStep, setActiveStep] = React.useState(0);
  const [oncheckedSwitch, setOncheckedSwitch] = React.useState(false);
  const [videoUrl, setVideoUrl] = useState("");
  const baseUrl = S3_URL;
  const [content, setContent] = useState("");
  const [showThumnail, setShowThumnail] = useState("");
  const [skipped, setSkipped] = React.useState(new Set<number>());
  const [dateValue, setDateValue] = React.useState<Date | null>(new Date());
  const [timeValue, setTimeValue] = React.useState<Date | null>(new Date());
  const [count, setCount] = useState(1);
  const [currencies, setCurrencies] = useState<any>([]);
  const [imageLoader, setImageLoader] = useState<any>(false)

  const web3 = new Web3(Web3.givenProvider || MUMBAI_NETWORK_RPC);

  const countInc = () => {
    setCount(count + 1);
  };
  const history = useHistory();
  const countDec = () => {
    if (count <= 0) {
      alert("No Negative");
      return;
    }
    setCount(count - 1);
  };

  const [loginUserData, setLoginUserData] = useState<any[] | []>([]);
  const [createdLivestream, setCreatedLivestream] = useState<any>({});
  const [genere, setGenere] = useState<any[] | []>([]);
  const [languages, setLanguages] = useState<any[] | []>([]);
  let userId = sessionStorage.getItem("isLogin") || "";
  const club_id = sessionStorage.getItem("clubId") || "";
  const [awsInfo, setAwsInfo] = useState<any>(null);
  const [progress, setProgress] = useState(0);
  const [price, setPrice] = React.useState("");
  const [avalableToPublic, setAvalableToPublic] = React.useState("");
  const [category, setCategory] = React.useState("");
  const [language, setLanguage] = React.useState([]);
  const [duration, setDuration] = React.useState("");
  const [title, setTitle] = React.useState("");
  const [gustEmail, setGustEmail] = React.useState("");
  const [gustName, setGustName] = React.useState("");
  const [gustPhone, setGustPhone] = React.useState("");
  const [ticketPrice, setTicketPrice] = React.useState("");
  const [nft_price_each, setNftPriceEach] = React.useState("");
  const [nft_count, setNftCount] = React.useState("");
  const [textareaValue, setTextareaValue] = React.useState("");
  const [livestreamDescValue, setLivestreamDescValue] = React.useState("");
  const [selectedLivestream, setSelectedLivestream] = useState<any[] | []>([]);
  const [gustList, setGustList] = useState<any[] | []>([]);
  const [titleError, setTitleError] = React.useState("");
  const [regularPriceError, setRegularPriceError] = React.useState("");
  const [durationError, setDurationError] = React.useState("");
  const [categoryError, setCategoryError] = React.useState("");
  const [languageError, setLanguageError] = React.useState("");
  const [gustNameError, setGustNameError] = React.useState("");
  const [gustEmailError, setGustEmailError] = React.useState("");
  const [gustPhoneError, setGustPhoneError] = React.useState("");
  const [nftCheck, setNftCheck] = useState(false);
  const [noOfNftError, setNoOfNftError] = React.useState("");
  const [noOfNftPriceError, setNoOfNftPriceError] = React.useState("");
  const [noOfreRunPriceError, setnoOfreRunPriceError] = React.useState("");
  const [dateTimeError, setDateTimeError] = React.useState("");
  const [imageError, setImageError] = React.useState("");
  const [liveStreamDescriptionError, setliveStreamDescriptionError] = React.useState("");
  // Meta Mask
  const [showMetaMaskModal, setMetaMaskModal] = useState(false);
  const handleCloseMetaMaskModal = () => setMetaMaskModal(false);
  const handleShowMetaMaskModal = () => setMetaMaskModal(true);
  const [isRerun, setIsRerun] = useState(false)
  const [reRunPrice, setreRunPrice] = React.useState("");
  const [blockChainIssue, setBlockChainIssue] = React.useState(false)
  const [aboutYouError, setAboutYouError] = React.useState("")
  // After click on finish btn, show user to select Wallet or INR
  const [showUserSelectMethodModal, setUserSelectMethodModal] = useState(false);
  const handleCloseUserSelectMethodModal = () =>
    setUserSelectMethodModal(false);
  const handleShowUserSelectMethodModal = () => setUserSelectMethodModal(true);

  // to show in wallet popup
  const [valueInINR, setValueInINR] = useState(0);

  const Razorpay = useRazorpay();
  const [amount, setAmount] = useState<any>("");

  const RAZORPAY_OPTIONS: RazorpayOptions = {
    key: RAZORPAY_KEY, // "rzp_test_DsjVVApo8X8BCB",
    amount: amount + "000",
    currency: "INR",
    name: "Clan World",
    description: "Ticket Price",
    image: "",
    order_id: "",
    prefill: {
      name: "",
      email: "",
      contact: "",
    },
    notes: {
      address: "Razorpay Corporate Office",
    },
    theme: {
      color: "#3399cc",
    },
  };

  useEffect(() => {
    // create();
    // console.log(100/53.14);
    // console.log(Number(100)/Number(53.14));

    // buyTicket();

    if(loginUserData[0]?.about_you){
      setTextareaValue(loginUserData[0]?.about_you)
    }
    let count = 0;
    let newCount = [];
    for (let index = 1; index <= 12; index++) {
      count = count + 5;
      newCount.push({
        value: count,
        label: count,
      });
    }
    setCurrencies([...newCount]);
    const _id = sessionStorage.getItem("isLogin") || "";
    if (_id) {
      UserService.getUser(_id).then((res) => {
        if (res) {
          sessionStorage.setItem("userImages", "");
          console.log("loginUserData", res);

          setLoginUserData(res);
          if (res.length > 0) {
            sessionStorage.setItem("userImages", res[0]?.image);
          }
        }
      });
    }
    ClubService.fetchGenereList().then((res) => {
      if (res) {
        setGenere([...res]);
      }
    });

    ClubService.fetchLanguageList().then((res) => {
      if (res) {
        setLanguages([...res]);
      }
    });
    if (club_id) {
      const data = {
        club_id: club_id,
        type: 1,
      };
      LivestreamService.getLiveStreamFolderInfo(data).then((res) => {
        if (res) {
          setAwsInfo(res);
        } else {
          toast.error(res?.message);
        }
      });
    }

    if (id !== "0") {
      LivestreamService.getlivestreamById(id).then((res) => {
        if (res) {
          setSelectedLivestream(res);
          setTitle(res[0]?.title);
          setTicketPrice(res[0]?.price);
          setDuration(res[0]?.duration);
          setLanguage(res[0]?.language);
          setLivestreamDescValue(res[0]?.description);
          setShowThumnail(res[0]?.thumbnail);
          setCategory(res[0]?.genre);
          setOncheckedSwitch(res[0]?.AVAILABLE_TO_PUBLIC);
          setPrice(res[0]?.AVAILABLE_TO_PUBLIC_PRICE);
          setAvalableToPublic(res[0]?.AVAILABLE_TO_PUBLIC_DAYS);
          setTextareaValue(res[0]?.about_you);
          setVideoUrl(res[0]?.video_url);
        } else {
          toast.error(res?.message);
        }
      });
    }
  }, [loginUserData[0]?.about_you]);

  
  const handleCloseWait = () => setBlockChainIssue(false);
  /**
   * NFT
   * Create
   */
  const create = async (data: any) => {
    if (props.loading) {
      dispatch(props.loading(true));
    }
    const addresses = await web3.eth.getAccounts();
    console.log(addresses, "in create");
    console.log(data.priceInMatic.toString(), "data in matic");

    //@ts-ignore
    const contract = new web3.eth.Contract(
      NFT_SMART_CONTRACT_ABI,
      NFT_PUBLIC_SMART_CONTRACT_ADDRESS
    );
    // 10 => Total no of ticket
    // url =>  json file url
    // title
    // 3 => Max no of tickets user can buy
    // 10000000000000000 => Nft Price in Matic
    // 1652431808 => From timestamp event start time
    // 1952431798 => To timestamp event end time

    // we can add then and catch methods after send
    // const createResponse = await contract.methods.createEvent(10, 'https://abhitest.mypinata.cloud/ipfs/QmeF6VW7knNpVEVN4ckMVH1iNexw82F1E9LGUxzWZFPTya/1.json', "Party night - LiveStream Title", 3, "10000000000000000", 1652438285, 1952431798).send({ from: addresses[0] });
    console.log(
      `tickets:- ${data.tickets},JSON FIle:- ${data.jsonFile},Title:- ${data.title
      },Tickets:- ${data.tickets
      },Matic Price:- ${data.priceInMatic.toString()},StartTimeStamp ${data.startTimeStamp
      },EndTimestamp ${data.endTimeStamp}`
    );
    const price = web3.utils.toWei(data.priceInMatic.toFixed(18).toString());
    console.log(price, "Price");

    const createResponse = await contract.methods.createEvent(
      data.tickets,
      data.jsonFile,
      data.title,
      data.tickets,
      price,
      data.endTimeStamp
    );
    const returnRes = await createResponse
      .send({ from: addresses[0], gas: 500000 })
      .catch((err: any) => {
        console.log(err, "in err in createResponse");
        if (props.loading) {
          dispatch(props.loading(false));
        }
        return err;
      });
    console.log(createResponse, "createResponse", returnRes);
    if (props.loading) {
      dispatch(props.loading(false));
    }
    return returnRes;
  };

  const buyTicket = async () => {
    const addresses = await web3.eth.getAccounts();
    //@ts-ignore
    const contract = new web3.eth.Contract(
      NFT_SMART_CONTRACT_ABI,
      NFT_PUBLIC_SMART_CONTRACT_ADDRESS
    );
    // 2 => event id
    // ticketQuantity => 2
    // addresses[0] => address of metamask
    // 8 => remaining
    // 10000000000000000 => Price of nFT
    // 0x9CfCD3D329549D9A327114F5ABf73637d13eFD07 => Admin address
    // 7799 => Value which need to be dynamic everytime
    // sign => From Api
    const buyticketResponse = await contract.methods
      .buyTicket(
        2,
        2,
        "0x9CfCD3D329549D9A327114F5ABf73637d13eFD07",
        7799,
        "0x76d65b4420129345a798ee5a43ec5bd403375197e9577b364ce7d589857c29b70b707f9df49faeddcf90944e19b3ac367340f368cad22195c5bd8f8b49413f5d1b"
      )
      .send({ from: addresses[0], value: (10000000000000000 * 2).toString() });
    console.log(buyticketResponse, "buyticketResponse");
  };

  const isStepSkipped = (step: number) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    setTitleError("");
    setRegularPriceError("");
    setDurationError("");
    setCategoryError("");
    setLanguageError("");
    setDateTimeError("");
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }
    let dateTime: any = `${moment(dateValue).format("YYYY-MM-DD")} ${moment(
      timeValue
    ).format("HH:mm a")}`;
    dateTime = moment(dateTime, "YYYY-MM-DD HH:mm").format("YYYY-MM-DD HH:mm");
    if (
      activeStep === 0 &&
      title &&
      ticketPrice &&
      language.length > 0 &&
      category &&
      duration &&
      !moment().isAfter(moment(dateTime).utc())
    ) {
      if (nftCheck) {
        if (
          nft_count &&
          nft_price_each &&
          !noOfNftError &&
          !noOfNftPriceError&&
          reRunPrice&&
          !noOfreRunPriceError
        ) {
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
          setSkipped(newSkipped);
        } else {
          if (!nft_count) {
            setNoOfNftError("Minimum 10 nft's required");
          }
          if (!nft_count) {
            setNoOfNftPriceError("Please enter price per NFT");
          }
          if (!reRunPrice) {
            setnoOfreRunPriceError("please enter rerun price")
          }
        }
      } else {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
      }
    } else {
      if (!title) {
        setTitleError("Please enter title of the livestream");
      }
      if (!ticketPrice) {
        setRegularPriceError("Please enter regular ticket price");
      }
      if (!(language.length > 0)) {
        setLanguageError("Please select language");
      }
      if (!category) {
        setCategoryError("Please select category");
      }
      if (!duration) {
        setDurationError("Please select duration");
      }

      if (moment().isAfter(moment(dateTime).utc())) {
        setDateTimeError("Please select future time");
      }
      if (nftCheck) {
        if (!nft_count) {
          setNoOfNftError("Minimum 10 nft's required");
        }
        if (!nft_count) {
          setNoOfNftPriceError("Please enter price per NFT");
        }
        
      }
      if (!isRerun) {
        setnoOfreRunPriceError("Please enter rerun price");
      }
      if (isRerun) {
        setnoOfreRunPriceError("Please enter rerun price");
      }
    }
    
    
  };

  const onUploadProgress = (data: any) => {
    const progress = Math.round((data.loaded / data.total) * 100);
    setProgress(progress);
  };

  const onUploadThumbnailPic = async (event: any) => {
    setImageError("");
    const img = new Image();
    img.src = window.URL.createObjectURL(event.target.files[0]);
    var filename = img.src.substring(img.src.lastIndexOf("/") + 1);
    console.log(img.src, "filename", filename);
    
    img.onload = () => {
      if (
        (img.width < 1280 && img.height <= 720) ||
        img.width < 1280 ||
        img.height < 720
      ) {
        console.log(img.width, img.height);
        alert("Minimum Recommended Dimension:1280Wx720H");
        return;
      } else if (event.target.files && event.target.files[0]) {
        onUploadProgress(event.target.files[0]);
        setContent(event.target.files[0]);
        
        if (props.loading) {
          dispatch(props.loading(true));
        }
        ClubService.signedCall({
          fileName: `${awsInfo?.folderPath}/${event.target.files[0]?.name}`,
          fileType: event.target.files[0]?.type,
        }).then(async (res) => {
          console.log(res, "signed Response");
          const result = await S3Helper.uploadFilesToS3BySigned(
            res,
            event.target.files[0],
            event.target.files[0]?.type
          );
          console.log("result", result);

          if (props.loading) {
            dispatch(props.loading(false));
          }

          setShowThumnail(
            `${CLOUDFRONT_URL}${awsInfo?.folderPath}/${event.target.files[0]?.name}`
          );

          // setShowThumnail(
          //   `${CLOUDFRONT_URL}${awsInfo?.folderPath}/${filename}.jpg`
          //   )
          toast.success("Uploaded Successfully");
        });
      }
    }
    // if (props.loading) {
    //   dispatch(props.loading(true));
    // }

    // if (event.target.files && event.target.files[0]) {
    //   console.log(event.target.files[0]);
    //   setContent(event.target.files[0]);

    //   ClubService.signedCall({
    //     fileName: `${awsInfo?.folderPath}/${event.target.files[0]?.name}`,
    //     fileType: event.target.files[0]?.type,
    //   }).then(async (res) => {
    //     console.log(res, "signed Response");
    //     const result = await S3Helper.uploadFilesToS3BySigned(
    //       res,
    //       event.target.files[0],
    //       event.target.files[0]?.type
    //     );
    //     console.log("result", result);
    //     const img = new Image();
    //     img.src = showThumnail;

    //     img.onload = () => {
    //       const actualwidth = img.width;
    //       const actualheight = img.height;
    //       console.log(actualwidth, actualheight);
    //       if (actualwidth > 700) {
    //         setShowThumnail(img.src);
    //         setIsImageLarge(true);
    //       }
    //     };
    //     if (props.loading) {
    //       dispatch(props.loading(false));
    //     }
    //     setShowThumnail(
    //       `${CLOUDFRONT_URL}${awsInfo?.folderPath}/${event.target.files[0]?.name}`
    //     );
    //     toast.success("Uploaded Successfully");
    //   });
    //   // const result = await S3Helper.uploadFilesToS3(
    //   //   event.target.files[0],
    //   //   awsInfo
    //   // );
    //   // console.log("result", result);
    //   // if (props.loading) {
    //   //   dispatch(props.loading(false));
    //   // }
    //   // setShowThumnail(`${result}`);
    //   // toast.success("Uploaded Successfully");
    // }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const handleChangeDuration = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDurationError("");
    if (event.target.value) {
      setDuration(event.target.value);
    } else {
      setDuration("");
      setDurationError("Please select duration");
    }
  };

  const handleChangeCategory = (event: any) => {
    setCategoryError("");
    if (event.target.value) {
      setCategory(event.target.value);
    } else {
      setCategory("");
      setCategoryError("Please select category");
    }
  };

  // const handleTextareaChange = (event:any) => {

  //   setTextareaValue(event.target.value);
  // };
   const handleTextareaChange = (event:any) => {
    setAboutYouError("")
    setTextareaValue(event.target.value);
    if (event.target.value) {
      
      if (!event.target.value.trim()) {
        setTextareaValue(event.target.value.trim());
        setAboutYouError("Enter only alphanumeric");
      } else {
        setTextareaValue(event.target.value);
      }
    } else {
      setTextareaValue("");
      setAboutYouError("Please enter about you");
    }
  };
  // setAboutYouError
  // const handleLivestreamDescChange = (
  //   event: React.ChangeEvent<HTMLInputElement>
  // ) => {
  //   setLivestreamDescValue(event.target.value);
  // };

  const handleLivestreamDescChange=(event:any) => {
    setliveStreamDescriptionError("")
    // setLivestreamDescValue(event.target.value);
    if (event.target.value) {
      if (!event.target.value.trim()) {
        setLivestreamDescValue(event.target.value.trim());
        setliveStreamDescriptionError("Enter only alphanumeric");
      } else {
        setLivestreamDescValue(event.target.value);
      }
    } else {
      setLivestreamDescValue("");
      setliveStreamDescriptionError("Please enter description of the livestream");
    }
  };
  const [modalState, setModalState] = useState<
    "modal-one" | "modal-two" | "close"
  >("close");

  const onBack = () => {
    history.goBack();
  };
  /**
   * nft
   */
  const nftMinting = () => {
    let url =
      "https://abhitest.mypinata.cloud/ipfs/QmeF6VW7knNpVEVN4ckMVH1iNexw82F1E9LGUxzWZFPTya/1.json";
    let createEventObj = {
      _totalSupply: "",
      _projectUri: "",
      _eventName: "",
      max: "",
      price: "",
      _startTime: "",
      _expiryTimme: "",
    };
  };

  // To show metamask and Pay INR

  const handleWalletAndInr = async () => {
    if(!showThumnail && !livestreamDescValue && !textareaValue){
      setImageError("Please upload cover photo");
            setliveStreamDescriptionError("Please enter livestream description");
            setAboutYouError("please enter about you");


    }
    else{
    // if (!showThumnail) {
    //   setImageError("Please upload cover photo");
    // }
    // if (!livestreamDescValue) {
    //   setliveStreamDescriptionError("Please upload cover photo");
    // }
    if (props.loading) {
      dispatch(props.loading(true));
    }
    if (nftCheck) {
      const dateTime = `${moment(dateValue).format("YYYY-MM-DD")} ${moment(
        timeValue
      ).format("HH:mm a")}`;
      const inrValue = await getPriceMatic();
      const getInrValueObj = {
        totalSupply: Number(nft_count),
        projectUri: showThumnail,
        eventName: title,
        max: Number(nft_count),
        price: Number(nft_price_each) / Number(inrValue),
        expiryTimme:
          moment(dateTime, "YYYY-MM-DDTHH:mm")
            .add(Number(duration), "minutes")
            .valueOf() / 1000,
      };
      console.log(getInrValueObj, "getInrValueObj");

      const responseData = await LivestreamService.forCreate(
        getInrValueObj
      ).then((res) => {
        if(res){
          handleShowUserSelectMethodModal();
        
        if (res < 1) {
          setValueInINR(1);
          setAmount(Number(nft_price_each) + 1);
        } else {
          setValueInINR(res);
          setAmount(Number(nft_price_each) + 1);
        }
        if (props.loading) {
          dispatch(props.loading(false));
        }
      }else{
        if (props.loading) {
          dispatch(props.loading(false));
        }
        // toast.error("Some issue in blockchain try after 30 seconds");
        setBlockChainIssue(true)
      }
      });
      // handleShowUserSelectMethodModal();
    } else {
      handleShowModalOne();
    }
  }
  };

  const connectWallet = async (walletType: string, data?: any) => {
    if (props.loading) {
      dispatch(props.loading(true));
    }
    if (window.ethereum) {
      await switchChain();
      window.ethereum
        .request({ method: "eth_requestAccounts" })
        .then((res: any) => {
          console.log("res in ethereum", res);

        });
    } else {
      // alert("install metamask extension!!");
      handleShowMetaMaskModal();
      if (props.loading) {
        dispatch(props.loading(false));
      }
      return;
    }
    const addresses: any = await web3.eth.getAccounts();
    if (!addresses.length) {
      toast.error("Please connect your wallet");
      if (props.loading) {
        dispatch(props.loading(false));
      }
      return;
    } else {
      handleShowModalOne();
    }
  };

  const openRezerPay = async (event: any) => {
    console.log(amount, "amount");
    const createOrderObj = {
      amount: amount + "00",
      currency: 'INR',
      notes: 'LS purchase'
    }
    LivestreamService.createOrder(createOrderObj).then((createOrderResponse) => {
      RAZORPAY_OPTIONS.amount = amount + "00";
      RAZORPAY_OPTIONS.order_id = createOrderResponse.id;
      RAZORPAY_OPTIONS.handler = (response: any) => {
        handleSaveCreateLiveStreamFormWalletPopup();
      };
      let razorpay = new Razorpay(RAZORPAY_OPTIONS);
      razorpay.open();
      razorpay.on("payment.failed", function (response: any) { });
    });
  };

  const handleSaveCreateLiveStreamFormWalletPopup = async () => {
    if (props.loading) {
      dispatch(props.loading(true));
    }
    if (activeStep === 1) {
      const dateTime = `${moment(dateValue).format("YYYY-MM-DD")} ${moment(
        timeValue
      ).format("HH:mm a")}`;
      const bodyData = {
        club_id: club_id,
        video_url: videoUrl,
        user_id: userId,
        type: 1,
        type_name: "PUBLIC_LIVESTREAM",
        VIEW_LIVESTREAM: null,
        DOWNLOAD_LIVESTREAM: 7,
        title: title ? title : "",
        description: livestreamDescValue,
        price: ticketPrice ? Number(ticketPrice) : "",
        schedule_From: dateValue ? `${dateTime}` : "",
        thumbnail: showThumnail,
        duration: Number(duration),
        AVAILABLE_TO_PUBLIC: oncheckedSwitch,
        AVAILABLE_TO_PUBLIC_PRICE: oncheckedSwitch ? Number(price) : "",
        AVAILABLE_TO_PUBLIC_DAYS: oncheckedSwitch ? avalableToPublic : "",
        stream_type: "web-rtmp",
        about_you: textareaValue,
        genre: category,
        isNftEnabled: nftCheck,
        language: language,
        nft_count: nftCheck ? Number(nft_count) : 0,
        nft_price_each: nftCheck ? Number(nft_price_each) : 0,
        nftID: "",
        isRerun: isRerun ,
        reRunPrice: isRerun ? Number(reRunPrice):0
      };
      // For NFT Smart contract
      const dateTimeValue = `${moment(dateValue).format("YYYY-MM-DD")} ${moment(
        timeValue
      ).format("HH:mm a")}`;
      const inrValue = await getPriceMatic();
      const getInrValueObj = {
        totalSupply: Number(nft_count),
        projectUri: showThumnail,
        eventName: title,
        max: Number(nft_count),
        price: Number(nft_price_each) / Number(inrValue),
        expiryTimme:
          moment(dateTimeValue, "YYYY-MM-DDTHH:mm")
            .add(Number(duration), "minutes")
            .valueOf() / 1000,
      };

      // const createNft = await create(getInrValueObj);
      const createNft = await LivestreamService.create(getInrValueObj)
        .then((res: any) => {
          bodyData.nftID = res.events.ProjectCreated.returnValues.id;
          createLiveStream(bodyData, getInrValueObj, res);
        })
        .catch((res) => {
          if (props.loading) {
            dispatch(props.loading(false));
          }
        });

      if (props.loading) {
        dispatch(props.loading(false));
      }
    }
  };

  const handleShowModalOne = async () => {
    if (props.loading) {
      dispatch(props.loading(true));
    }
    if (activeStep === 1) {
      const dateTime = `${moment(dateValue).format("YYYY-MM-DD")} ${moment(
        timeValue
      ).format("HH:mm a")}`;
      const bodyData = {
        club_id: club_id,
        video_url: videoUrl,
        user_id: userId,
        type: 1,
        type_name: "PUBLIC_LIVESTREAM",
        VIEW_LIVESTREAM: null,
        DOWNLOAD_LIVESTREAM: 7,
        title: title ? title : "",
        description: livestreamDescValue,
        price: ticketPrice ? Number(ticketPrice) : "",
        schedule_From: dateValue ? `${dateTime}` : "",
        thumbnail: showThumnail,
        duration: Number(duration),
        AVAILABLE_TO_PUBLIC: oncheckedSwitch,
        AVAILABLE_TO_PUBLIC_PRICE: oncheckedSwitch ? Number(price) : "",
        AVAILABLE_TO_PUBLIC_DAYS: oncheckedSwitch ? avalableToPublic : "",
        stream_type: "web-rtmp",
        about_you: textareaValue,
        genre: category,
        isNftEnabled: nftCheck,
        isRerun: isRerun,
        reRunPrice: isRerun ? Number(reRunPrice):0,
        language: language,
        nft_count: nftCheck ? Number(nft_count) : 0,
        nft_price_each: nftCheck ? Number(nft_price_each) : 0,
        nftID: "",
      };
      console.log(bodyData, "bodyData");
      // const nftObj = {
      //   startTimeStamp: moment(bodyData.schedule_From, "YYYY-MM-DDTHH:mm").utc().valueOf(),
      //   endTimeStamp: moment(bodyData.schedule_From, "YYYY-MM-DDTHH:mm").add(bodyData.duration, 'minutes').valueOf(),
      //   title: bodyData.title,
      //   tickets: bodyData.nft_count,
      //   jsonFile: `${CLOUDFRONT_URL}/${1}.json`
      // }
      // console.log(nftObj, 'nftObj')

      if (bodyData.isNftEnabled) {
        if (window.ethereum) {
          window.ethereum
            .request({ method: "eth_requestAccounts" })
            .then((res: any) => {
              console.log("res in ethereum", res);
            });
        } else {
          // alert("install metamask extension!!");
          handleShowMetaMaskModal();
          if (props.loading) {
            dispatch(props.loading(false));
          }
          return;
        }
        const getAddress: any = await web3.eth.getAccounts();
        if (!getAddress.length) {
          toast.error("Please connect your wallet");
          if (props.loading) {
            dispatch(props.loading(false));
          }
          return;
        }
      }
      // For NFT Smart contract
      if (bodyData.isNftEnabled) {
        const jsonObj = {
          name: bodyData.title,
          description: bodyData.description,
          image: bodyData.thumbnail,
          category: bodyData.genre,
        };
        const fileName = `${moment().valueOf()}.json`;
        const str = JSON.stringify(jsonObj);
        const bytes = new TextEncoder().encode(str);
        const blob = new Blob([bytes], {
          type: "application/json;charset=utf-8",
        });
        const file = new File([bytes], fileName);
        ClubService.signedCall({
          fileName: `nftJsonFiles/${fileName}`,
          fileType: "application/json;charset=utf-8",
        }).then(async (res) => {
          console.log(res, "signed Response");
          const result = await S3Helper.uploadFilesToS3BySigned(
            res,
            file,
            "application/json;charset=utf-8"
          );
          console.log("result", result);

          // setShowThumnail(
          //   `${CLOUDFRONT_URL}/${fileName}.json`
          // );
          // toast.success("Uploaded Successfully");
        });
        const getMaticValue = await axios
          .get(
            `https://api.coingecko.com/api/v3/simple/price?ids=matic-network&vs_currencies=INR`
          )
          .then(async (res: any) => {
            console.log(res.data["matic-network"].inr, "res");
            const nftObj = {
              // startTimeStamp: moment(bodyData.schedule_From, "YYYY-MM-DDTHH:mm").utc().valueOf(),
              startTimeStamp: moment().valueOf() / 1000,
              endTimeStamp:
                moment(bodyData.schedule_From, "YYYY-MM-DDTHH:mm")
                  .add(bodyData.duration, "minutes")
                  .valueOf() / 1000,
              title: bodyData.title,
              tickets: bodyData.nft_count,
              priceInMatic:
                Number(bodyData.nft_price_each) /
                Number(res.data["matic-network"].inr),
              jsonFile: `${CLOUDFRONT_URL}nftJsonFiles/${fileName}`,
            };
            console.log(
              moment().valueOf(),
              moment().valueOf() / 1000,
              moment(bodyData.schedule_From, "YYYY-MM-DDTHH:mm")
                .add(bodyData.duration, "minutes")
                .valueOf(),
              moment(bodyData.schedule_From, "YYYY-MM-DDTHH:mm")
                .add(bodyData.duration, "minutes")
                .valueOf() / 1000,
              "TimeStamp"
            );
            console.log(nftObj, "nftObj");

            const createNft = await create(nftObj);
            if (!(createNft.code === 4001)) {
              bodyData.nftID = createNft.events.ProjectCreated.returnValues.id;
              createLiveStream(bodyData, nftObj, createNft);
            } else {
              toast.error("User denied the transaction");
              return;
            }
            if (props.loading) {
              dispatch(props.loading(false));
            }
          });
      } else {
        createLiveStream(bodyData);
      }

      // if (id === "0") {
      //   if (showThumnail) {
      //     LivestreamService.createLiveStream(bodyData).then(async (res) => {
      //       setCreatedLivestream(res);

      //       if (props.loading) {
      //         dispatch(props.loading(false));
      //       }
      //       setModalState("modal-one");
      //     });
      //   } else {
      //     if (props.loading) {
      //       dispatch(props.loading(false));
      //     }
      //     if (!showThumnail) {
      //       setImageError("Please upload cover photo");
      //     }
      //   }
      // } else {
      //   const data = Object.assign(bodyData, {
      //     _id: selectedLivestream[0]?._id,
      //     collectionName: selectedLivestream[0]?.collectionName,
      //   });
      //   LivestreamService.updateLiveStream(data).then((res) => {
      //     history.push("/dashboard/mylivestreams/scheduled");
      //   });
      // }
    }
  };

  const createLiveStream = (bodyData: any, nftObj?: any, nftResponse?: any) => {
    ClubService.getUserClubs(userId).then((res) => {
      if (res) {
        sessionStorage.setItem("clubId", res[0]?._id || "");
        bodyData.club_id = res[0]?._id;
        if (id === "0") {
          if (showThumnail && livestreamDescValue && textareaValue) {
            LivestreamService.createLiveStream(bodyData).then(async (res) => {
              setCreatedLivestream(res);
              if (nftObj) {
                const saveResponse = {
                  livestream_id: res._id,
                  user_id: userId,
                  type: "CREATE",
                  req_payload: nftObj,
                  response: nftResponse,
                };
                LivestreamService.createLsSmartContract(saveResponse).then(
                  (res) => { }
                );
              }
    
              if (props.loading) {
                dispatch(props.loading(false));
              }
              setModalState("modal-one");
            });
          } else {
            if (props.loading) {
              dispatch(props.loading(false));
            }
            if (!showThumnail) {
              setImageError("Please upload cover photo");
            }
            if(!livestreamDescValue){
              setliveStreamDescriptionError("please enter livestream description")
            }
            if(!textareaValue){
              setAboutYouError("please enter about you")
            }
          }
        } else {
          const data = Object.assign(bodyData, {
            _id: selectedLivestream[0]?._id,
            collectionName: selectedLivestream[0]?.collectionName,
          });
          LivestreamService.updateLiveStream(data).then((res) => {
            // console.log("chitra",data)
            history.push(`/dashboard/mylivestreams/scheduled/`);
          });
        }
      }
    });
  };

  const getPriceMatic = async () => {
    // Here we will get the inr value for 1 matic
    const getInrValue = await axios
      .get(
        `https://api.coingecko.com/api/v3/simple/price?ids=matic-network&vs_currencies=INR`
      )
      .then(async (res: any) => {
        console.log(res.data["matic-network"].inr, "res");
        return Number(res.data["matic-network"].inr);
      });
    return getInrValue;
  };

  const handleClose = () => {
    setModalState("close");
    history.push("/dashboard/mylivestreams/scheduled");
  };

  const handleShowModalTwo = () => {
    setModalState("modal-two");
  };

  const onAddGustList = () => {
    const re = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,6})+$/;

    const gustData: any = gustList;
    setGustNameError("");
    setGustEmailError("");
    if(!re.test(gustEmail)){
      setGustEmailError("not a valid email");
  
     }else if (
      gustName &&
      gustEmail &&
      gustPhone &&
      loginUserData[0]?.email !== gustEmail
    ) {
      const isEmailExist = gustData.find(
        (data: any) => data.email === gustEmail || data.phone === gustPhone
      );
      if (!isEmailExist) {
        gustData.push({ name: gustName, email: gustEmail, phone: gustPhone });
        setGustList([...gustData]);
        setGustName("");
        setGustEmail("");
        setGustPhone("");
      } else {
        setGustEmailError("Email id already exist");
      }
    } else {
      if (!gustName) {
        setGustNameError("Please enter guest name");
      }
      if (!gustEmail) {
        setGustEmailError("Please enter guest email id");
      }
      if (loginUserData[0]?.email === gustEmail) {
        setGustEmailError("Creator email not allowed");
      }
      if (!gustPhone) {
        setGustPhoneError("Please enter phone number");
      }
    }
  };
  const onDeleteGustList = (index: number) => {
    const gustData: any = gustList;
    gustData.splice(index, 1);
    setGustList([...gustData]);
  };

  const onSendTickets = () => {
    const selectedLivestreamData: any = createdLivestream;
    selectedLivestreamData.guest_list = gustList;
    LivestreamService.updateLiveStream(selectedLivestreamData).then((res) => {
      createTicket();
    });
  };

  const createTicket = () => {
    const data = {
      guest_list: gustList,
      live_stream_id: createdLivestream?._id,
      club_id: createdLivestream?.club_id,
    };
    LivestreamService.createTickets(data).then((res) => {
      history.push("/dashboard/mylivestreams/scheduled");
    });
  };

  const onChangeGuestEmail = (event: any) => {
    setGustEmailError("");
    if (event.target.value) {
      const re = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,6})+$/;
      if (!event.target.value.trim()) {
        setGustEmail("");
        setGustEmailError("Enter only alphabets");
      } else if (re.test(event.target.value.trim())) {
        setGustEmail(event.target.value);
      } else {
        setGustEmail(event.target.value);
        setGustEmailError("Please enter proper guest email id");
      }
    } else {
      setGustEmail("");
      setGustEmailError("Please enter guest email id");
    }
  };

  const onChangeGuestPhone = (event: any) => {
    setGustPhoneError("");
    if (event.target.value && event.target.value.length <= 10) {
      const re = /^[5-9]{1}[0-9]{9}$/;
      if (re.test(event.target.value.trim())) {
        setGustPhone(event.target.value.trim());
      } else {
        setGustPhone(event.target.value.trim());
        setGustPhoneError("Please enter proper phone number");
      }
    } else if (!(event.target.value.length >= 10)) {
      setGustPhone("");
      setGustPhoneError("Please enter phone number");
    }
  };

  const onChangeGuestName = (event: any) => {
    setGustNameError("");
    const reg = /^[A-Za-z]+(?:[ _-][A-Za-z]+)*$/;
    if (event) {
      if (!reg.test(event.target.value.trim())) {
        if (!event.target.value.trim()) {
          setGustName("");
          setGustNameError("Enter only alphabets");
        } else {
          setGustName(event.target.value);
          setGustNameError("Enter only alphabets");
        }
      } else {
        setGustName(event.target.value);
      }
    } else {
      setGustName("");
      setGustNameError("Please enter name");
    }

    // if (event.target.value) {
    //   const re = /^[A-Za-z]+(?:[ _-][A-Za-z]+)*$/;
    //   if (re.test(event.target.value)) {
    //     setGustName(event.target.value);
    //   } else {
    //     setGustName(event.target.value);
    //     setGustNameError("Name must not contains special charaters");
    //   }
    // } else {
    //   setGustName("");
    //   setGustNameError("Please enter guest name");
    // }
  };

  const onChangeTitle = (event: any) => {
    setTitleError("");
    if (event.target.value) {
      if (!event.target.value.trim()) {
        setTitle(event.target.value.trim());
        setTitleError("Enter only alphanumeric");
      } else {
        setTitle(event.target.value);
      }
    } else {
      setTitle("");
      setTitleError("Please enter title of the livestream");
    }
  };
  const onChangePrice = (event: any) => {
    setRegularPriceError("");
    if (
      event.target.value &&
      event.target.value.length <= 4 &&
      event.target.value >= 1
    ) {
      const re = /^[0-9\b]+$/;
      if (re.test(event.target.value) && event.key !== ".") {
        setTicketPrice(event.target.value);
      }
    } else {
      if (!event.target.value || !(event.target.value >= 1)) {
        setTicketPrice("");
        setRegularPriceError("Please enter regular ticket price");
      }
    }
  };
  const onChangeNftCount = (event: any) => {
    setNoOfNftError("");
    if (Number(event.target.value) >= 10 && event.target.value.length <= 4) {
      const re = /^[0-9\b]+$/;
      if (re.test(event.target.value) && event.key !== ".") {
        setNftCount(event.target.value);
      }
    } else {
      if (!event.target.value || !(event.target.value >= 10)) {
        if (!(event.target.value >= 1)) {
          setNftCount("");
        } else {
          const re = /^[0-9\b]+$/;
          if (re.test(event.target.value) && event.key !== ".") {
            setNftCount(event.target.value);
          }
        }
        setNoOfNftError("Minimum 10 nft's required");
      }
    }
  };

  const onChangeNftPriceEach = (event: any) => {
    setNoOfNftPriceError("");
    if (
      event.target.value &&
      event.target.value.length <= 4 &&
      event.target.value >= 1
    ) {
      // setNftPriceEach(event.target.value);
      const re = /^[0-9\b]+$/;
      if (re.test(event.target.value) && event.key !== ".") {
        setNftPriceEach(event.target.value);
      }
    } else {
      if (!event.target.value || !(event.target.value >= 1)) {
        setNftPriceEach("");
        setNoOfNftPriceError("Please enter price per NFT");
      }
    }
    // setNftPriceEach(event.target.value);
  };

  const onRerunPriceEach = (event: any) => {
    
    setnoOfreRunPriceError("");
    if (
      event.target.value &&
      event.target.value.length <= 4 &&
      event.target.value >= 1
    ) {
      // setNftPriceEach(event.target.value);
      const re = /^[0-9\b]+$/;
      if (re.test(event.target.value) && event.key !== ".") {
        setreRunPrice(event.target.value);
      }
    } else {
      if (!event.target.value || !(event.target.value >= 1)) {
        setreRunPrice("");
        setnoOfreRunPriceError("Please enter price per Rerun");
      }
    }
    // setNftPriceEach(event.target.value);
  };

  const [personName, setPersonName] = React.useState<string[]>([]);

  const handleCheckBoxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNftCheck(event.target.checked);
    setIsRerun(event.target.checked);
  };
  const handleRerunBoxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsRerun(event.target.checked);
  };

  const onSelect = (selectedList: any, selectedItem: any) => {
    setLanguageError("");
    if (selectedList.length > 0) {
      setLanguage(selectedList);
    } else {
      setLanguage([]);
      setLanguageError("Please select language");
    }
  };

  const onRemove = (selectedList: any, removedItem: any) => {
    if (selectedList.length > 0) {
      setLanguage(selectedList);
    } else {
      setLanguage([]);
      setLanguageError("Please select language");
    }
  };

  const onChangeTime = (event: any) => {
    setDateTimeError("");
    setTimeValue(event);

    let dateTime: any = `${moment(dateValue).format("YYYY-MM-DD")} ${moment(
      event
    ).format("HH:mm a")}`;
    dateTime = moment(dateTime, "YYYY-MM-DD HH:mm").format("YYYY-MM-DD HH:mm");
    if (!moment().isAfter(moment(dateTime).utc())) {
    } else {
      setDateTimeError("Please select future time");
    }
  };
  return (
    <>
      <section className="create-livestream">
        <div className="container-fluid px-0">
          <div className="create-livestream-steps">
            <div className="create-livestream-left">
              <div className="d-flex flex-wrap flex-column flex-lg-row">
                <h3>
                  <span className="pointer" onClick={onBack}>
                    <i className="bi bi-chevron-left"></i>
                  </span>
                  {id !== "0" ? "Update a Livestream" : "Create a Livestream"}
                </h3>
                <Stepper className="ms-auto" activeStep={activeStep}>
                  {steps.map((label, index) => {
                    const stepProps: { completed?: boolean } = {};
                    const labelProps: {
                      optional?: React.ReactNode;
                    } = {};
                    if (isStepSkipped(index)) {
                      stepProps.completed = false;
                    }
                    return (
                      <Step key={label} {...stepProps}>
                        <StepLabel {...labelProps}>
                          {/* <span className="d-block step-number">
                            Step {index + 1}
                          </span> */}
                          {label}
                        </StepLabel>
                      </Step>
                    );
                  })}
                </Stepper>
              </div>
              <Box sx={{ width: "100%" }}>
                {activeStep === steps.length ? (
                  <React.Fragment>
                    <Typography sx={{ mt: 2, mb: 1 }}>
                      All steps completed - you&apos;re finished
                    </Typography>
                    <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                      <Box sx={{ flex: "1 1 auto" }} />
                      <Button onClick={handleReset}>Reset</Button>
                    </Box>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <div className="create-livestream-step">
                      {activeStep === 0 && (
                        <Typography sx={{ mt: 2, mb: 1 }}>
                          <div className="col-md-12">
                            <div className="row mb-3">
                              <div className="col-md-12">
                                <TextField
                                  fullWidth
                                  id="outlined-basic"
                                  label="Title of the Livestream"
                                  placeholder="This will be cover title of your livestream."
                                  variant="outlined"
                                  margin="normal"
                                  value={title}
                                  onChange={(event) => onChangeTitle(event)}
                                />
                                {titleError && (
                                  <p
                                    style={{
                                      color: "red",
                                      fontSize: "14px",
                                      marginBottom: "0",
                                    }}
                                  >
                                    {titleError}
                                  </p>
                                )}
                              </div>
                              {/* <div className="col-md-4">
                                <TextField
                                  fullWidth
                                  margin="normal"
                                  id="outlined-basic"
                                  label="Ticket Price"
                                  variant="outlined"
                                  value={ticketPrice}
                                  onChange={(event) => onChangePrice(event)}
                                />

                                {regularPriceError && (
                                  <p
                                    style={{
                                      color: "red",
                                      fontSize: "14px",
                                      marginBottom: "0",
                                    }}
                                  >
                                    {regularPriceError}
                                  </p>
                                )}
                              </div> */}
                            </div>
                            <div className="row mb-4">
                              <div className="col-md-8">
                              
                                {/* <div className="row mx-auto create-nft-sec mb-0">
                                  <span className="nft-title">NFT</span>
                                  <div className="col-md-12 col-xl-3 align-self-center pe-0 pb-3 pb-xl-0">
                                    <FormControlLabel
                                      value="top"
                                      control={
                                        <Checkbox
                                          checked={nftCheck}
                                          onChange={handleCheckBoxChange}
                                          className="col-auto nft-sec-chk"
                                        />
                                      }
                                      labelPlacement="end"
                                      label="Create NFT"
                                      className="col-auto nft-sec-chk "
                                    />
                                    <span className="align-self-center">
                                      <TooltipWrapper
                                        tooltipText="A non-fungible token (NFT) will be created within 24Hours after the completion of the event. Notification will be sent to your email and mobile."
                                        iconName="bi bi-info-circle"
                                      />
                                    </span>
                                  </div>
                                  <div className=" col-xl-5 col-md-8 mb-3 mb-md-0">
                                    <TextField
                                      fullWidth
                                      // type="number"
                                      id="noOfNFTTickets"
                                      label="No. of NFT"
                                      variant="outlined"
                                      placeholder="min 10"
                                      value={nft_count}
                                      onChange={(event) => onChangeNftCount(event)}
                                      disabled={nftCheck ? false : true}
                                    />
                                    {noOfNftError && nftCheck && (
                                      <p
                                        style={{
                                          color: "red",
                                          fontSize: "14px",
                                          marginBottom: "0",
                                        }}
                                      >
                                        {noOfNftError}
                                      </p>
                                    )}
                                  </div>
                                  <div className=" col-xl-4 col-md-4">
                                    <TextField
                                      fullWidth
                                      id="outlined-basic"
                                      label="Price per NFT"
                                      variant="outlined"
                                      // type="number"
                                      value={nft_price_each}
                                      onChange={(event) =>
                                        onChangeNftPriceEach(event)
                                      }
                                      disabled={nftCheck ? false : true}
                                    />
                                    {noOfNftPriceError && nftCheck && (
                                      <p
                                        style={{
                                          color: "red",
                                          fontSize: "14px",
                                          marginBottom: "0",
                                        }}
                                      >
                                        {noOfNftPriceError}
                                      </p>
                                    )}
                                  </div>
                                  
                                </div>
                                <div className="col-12 d-flex justify-content-end fs-sml pt-2">
                                    <span className="text-right nft-helper-text">
                                      Earn revenue lifetime by converting your
                                      livestream into NFT.{" "}
                                      <Link to="/faq/creatorfaq">Learn more</Link>
                                    </span>
                                  </div> */}
                              </div>
                              <div className="col-md-12">
                                <div className="row mx-auto create-nft-sec">
                                  
                                 <div className="col-md-6 ticket-price">
                                 <FormControlLabel
                                      value="top"
                                      style={{paddingRight:"8px"}}
                                      control={
                                        <Checkbox
                                          checked
                                          disabled
                                          // onChange={handleRerunBoxChange}
                                          className="col-auto nft-sec-chk"
                                        />
                                      }
                                      labelPlacement="end"
                                      label="Regular Ticket"
                                      className="col-auto nft-sec-chk "
                                    />
                                    <div className="col">
                                 <TextField
                                  fullWidth
                                  margin="normal"
                                  id="outlined-basic-rerun"
                                  label="Price Per Ticket"
                                  variant="outlined"
                                  value={ticketPrice}
                                  onChange={(event) => onChangePrice(event)}
                                  />

                                  {regularPriceError && (
                                  <p
                                    style={{
                                      color: "red",
                                      fontSize: "14px",
                                      marginBottom: "0",
                                    }}
                                  >
                                    {regularPriceError}
                                   </p>
                                   )}
                                   
                                </div>
                               </div>
                                  <span className="nft-title">Ticketing</span>
                                  <div className="col-md-6">
                                  <div className="col-auto align-self-center pb-3 pb-xl-0 rerun-enable">
                                    <FormControlLabel
                                      value="top"
                                      control={
                                        <Checkbox
                                          checked={isRerun}
                                          onChange={handleRerunBoxChange}
                                          className="col-auto nft-sec-chk"
                                        />
                                      }
                                      labelPlacement="end"
                                      label="Rerun Ticket"
                                      className="col-auto nft-sec-chk "
                                    />
                                    <span className="rerun-text">&nbsp; &#40;Earn on every replay&#41;</span>
                                    {/* <span
                                         style={{
                                           borderColor: "#000",
                                         }}
                                       > */}
                                         <i
                                           className="bi bi-info rerun-price-info"
                                           style={{ color: "#2b2b2b" }}
                                         ></i>
                                       {/* </span> */}
                                  </div>
                                  <div className="col rerun">
                                    <TextField
                                      fullWidth
                                      id="outlined-basic-rerun"
                                      label="Price Per Rerun"
                                      variant="outlined"
                                      // type="number"
                                      value={reRunPrice}
                                      onChange={(event) =>
                                        onRerunPriceEach(event)
                                      }
                                      disabled={isRerun ? false : true}
                                    />
                                    {isRerun&& (
                                      <p
                                        style={{
                                          color: "red",
                                          fontSize: "14px",
                                          marginBottom: "0",
                                        }}
                                      >
                                        {noOfreRunPriceError}
                                      </p>
                                    )}
                                     {nftCheck===true && isRerun ==false&& (
                                      <p
                                        style={{
                                          color: "red",
                                          fontSize: "14px",
                                          marginBottom: "0",
                                        }}
                                      >
                                        {noOfreRunPriceError}
                                      </p>
                                    )}
                                  </div>
                                  </div>
                                       {/* <div className="col-12 d-flex justify-content-end fs-sml pt-2">
                                           <span className="text-right nft-helper-text">
                                        Earn revenue lifetime by converting your
                                          livestream into NFT.{" "}
                                         <Link to="/faq/creatorfaq">Learn more</Link>
                                           </span>
                                           </div> */}
                                </div>
                              </div>
                            </div>


                            <div className="row mx-auto create-nft-sec ">
                              <span className="nft-title">Schedule</span>
                              <div className="col-sm-4 mt-2 mb-2 mb-sm-0">
                                <LocalizationProvider
                                  dateAdapter={AdapterDateFns}
                                >
                                  <DatePicker
                                    disablePast
                                    label="Date"
                                    value={dateValue}
                                    onChange={(newValue) => {
                                      setDateValue(newValue);
                                    }}
                                    renderInput={(params) => (
                                      <TextField {...params} fullWidth />
                                    )}
                                  />
                                </LocalizationProvider>
                              </div>

                              <div className="col-sm-4 mt-2 mb-2 mb-sm-0">
                                <LocalizationProvider
                                  dateAdapter={AdapterDateFns}
                                >
                                  <TimePicker
                                    value={timeValue}
                                    label="Time"
                                    onChange={(e) => onChangeTime(e)}
                                    renderInput={(params) => (
                                      <TextField {...params} fullWidth />
                                    )}
                                  />
                                </LocalizationProvider>

                                {dateTimeError && (
                                  <p
                                    style={{ color: "red", marginBottom: "0" }}
                                  >
                                    {dateTimeError}
                                  </p>
                                )}
                              </div>

                              <div className="col-sm-4 mt-2">
                                <TextField
                                  id="outlined-select-duration"
                                  select
                                  label="Duration (Mins)"
                                  value={duration}
                                  onChange={handleChangeDuration}
                                  fullWidth
                                >
                                  {currencies.map((option: any) => (
                                    <MenuItem
                                      key={option.value}
                                      value={option.value}
                                    >
                                      {option.label}
                                    </MenuItem>
                                  ))}
                                </TextField>
                                {durationError && (
                                  <p
                                    style={{
                                      color: "red",
                                      fontSize: "14px",
                                      marginBottom: "0",
                                    }}
                                  >
                                    {durationError}
                                  </p>
                                )}
                              </div>
                            </div>
                            <div className="row mb-3">
                              <div className="col-sm-6 mb-3 mb-md-0">
                                <TextField
                                  id="outlined-select-category"
                                  select
                                  label="Category"
                                  value={category}
                                  onChange={(e) => handleChangeCategory(e)}
                                  fullWidth
                                >
                                  {genere.map((category) => (
                                    <MenuItem key={category} value={category}>
                                      {category}
                                    </MenuItem>
                                  ))}
                                </TextField>
                                {categoryError && (
                                  <p
                                    style={{
                                      color: "red",
                                      fontSize: "14px",
                                      marginBottom: "0",
                                    }}
                                  >
                                    {categoryError}
                                  </p>
                                )}
                              </div>
                              <div className="col-sm-6">
                                <Multiselect
                                  placeholder="Language (max 2)"
                                  options={languages}
                                  selectedValues={language}
                                  onSelect={onSelect}
                                  onRemove={onRemove}
                                  selectionLimit={2}
                                  isObject={false}
                                  avoidHighlightFirstOption={true}
                                  showCheckbox={true}
                                />
                                {languageError && (
                                  <p
                                    style={{
                                      color: "red",
                                      fontSize: "14px",
                                      marginBottom: "0",
                                    }}
                                  >
                                    {languageError}
                                  </p>
                                )}
                              </div>
                            </div>
                            <div className="row mb-1">
                              <div className="col-md-12 d-flex align-items-center">
                                <span className="">
                                  Recording will be available to attendees for 24
                                  hours
                                </span>
                              </div>
                            </div>
                            {/* <span className="fs-sml">
                              These settings cannot be edited later. To edit
                              please <Link to="/support">contact us</Link>.
                            </span> */}
                          </div>
                        </Typography>
                      )}
                      {activeStep === 1 && (
                        <Typography sx={{ mt: 2, mb: 1 }}>
                          <div className="col-md-12">
                            <TextField
                              id="about-you"
                              label="About you"
                              multiline
                              rows={3}
                              defaultValue={loginUserData[0]?.about_you ? loginUserData[0]?.about_you : ""}
                              fullWidth
                              onChange={(event)=>handleTextareaChange(event)}
                              margin="normal"
                              placeholder="Why should someone buy ticket to your show? Write something interesting &amp; compelling."
                            />
                              {aboutYouError && (
                                  <p
                                    style={{
                                      color: "red",
                                      fontSize: "14px",
                                      marginBottom: "0",
                                    }}
                                  >
                                    {aboutYouError}
                                  </p>
                                )}
                          </div>
                          <div className="col-md-12">
                            <TextField
                              id="livestream-desc"
                              label="Livestream Description"
                              multiline
                              rows={3}
                              value={livestreamDescValue}
                              fullWidth
                              onChange={(event) => handleLivestreamDescChange(event)}
                              margin="normal"
                              placeholder="Tell your audience what your livestream is all about."
                            />
                              {liveStreamDescriptionError && (
                                  <p
                                    style={{
                                      color: "red",
                                      fontSize: "14px",
                                      marginBottom: "0",
                                    }}
                                  >
                                    {liveStreamDescriptionError}
                                  </p>
                                )}
                          </div>
                          <div className="row">
                            <div className="col-md-12">
                              <div className="upload-sec custom">
                                <div className="outer-btn button_outer1">
                                  <div className="btn_upload1 upload_btn btn">
                                    <input
                                      id="upload_file1"
                                      type="file"
                                      name="cover_photo"
                                      accept="image/jpg, image/jpeg, image/png, image/svg, image/gif"
                                      onChange={(event) =>
                                        onUploadThumbnailPic(event)
                                      }
                                    />
                                    <i className="bi bi-cloud-arrow-up"></i>
                                    <strong>Upload Cover Photo </strong>
                                    <span>(drag and drop or browse)</span>
                                    <span className="upload-accept-filename">
                                      Formats: jpg, jpeg, png, gif
                                      <br />
                                      Recommended resolution: 1280W x 720H, upto
                                      10mb
                                    </span>
                                  </div>
                                  <div className="processing_bar"></div>
                                  <div className="success_box"></div>
                                </div>

                                {/* {showThumnail && (
                                  <div className="uploaded-img"
                                  style={{backgroundImage:`url("${showThumnail}")`}}
                                  >
                                    <img
                                      className=""
                                      src={showThumnail}
                                      alt=""
                                    />

                                    <span className="file_remove1 remove_file">
                                      X
                                    </span>
                                  </div>
                                )}
                                <div className="error_msg1 upload_error_msg"></div>
                                <div
                                  className="uploaded_file_view"
                                  id="uploaded_view1"
                                ></div> */}
                              </div>
                              {imageError && (
                                <p
                                  style={{
                                    color: "red",
                                    fontSize: "14px",
                                    marginBottom: "0",
                                  }}
                                >
                                  {imageError}
                                </p>
                              )}
                            </div>
                          </div>
                          <span className="fs-sml">
                            These settings cannot be edited later. To edit
                            please <Link to="/support">contact us</Link>.
                          </span>
                        </Typography>
                      )}
                      {activeStep === 2 && (
                        <Typography sx={{ mt: 2, mb: 1 }}>
                          <div className="row mb-3">
                            <div className="col">
                              <TextField
                                fullWidth
                                id="outlined-basic"
                                label="Email id / Phone number"
                                variant="outlined"
                                value={title}
                                onChange={(event) =>
                                  setTitle(event.target.value)
                                }
                              />
                            </div>
                            <div className="col-md-2 d-flex">
                              <button className="btn btn-outline-black w-100">
                                Add
                              </button>
                            </div>
                          </div>
                          <div className="col-md-12 border p-4 rounded-3 guest-list">
                            <ul>
                              <li>
                                <span className="guest-list-mail">
                                  arnikagarg@gmail.com
                                </span>
                                <span className="guest-list-unused">
                                  Unused
                                </span>
                                <span className="guest-list-actions">
                                  <button type="button" className="btn px-2">
                                    <i className="bi bi-link-45deg"></i>
                                  </button>
                                  <button type="button" className="btn px-2">
                                    <i className="bi bi-send"></i>
                                  </button>
                                  <button type="button" className="btn px-2">
                                    <i className="bi bi-trash text-danger"></i>
                                  </button>
                                </span>
                              </li>
                              <li>
                                <span className="guest-list-mail">
                                  arnikagarg@gmail.com
                                </span>
                                <span className="guest-list-unused">
                                  Unused
                                </span>
                                <span className="guest-list-actions">
                                  <button type="button" className="btn px-2">
                                    <i className="bi bi-link-45deg"></i>
                                  </button>
                                  <button type="button" className="btn px-2">
                                    <i className="bi bi-send"></i>
                                  </button>
                                  <button type="button" className="btn px-2">
                                    <i className="bi bi-trash text-danger"></i>
                                  </button>
                                </span>
                              </li>
                            </ul>
                            <button className="btn btn-outline-black mx-auto">
                              Send Tickets
                            </button>
                          </div>
                        </Typography>
                      )}
                    </div>
                    <Box className="next-button"
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        pt: 2,
                        pb: 2,
                      }}
                    >
                      <Button
                        variant="outlined"
                        className="btn btn-outline-orange"
                        color="inherit"
                        disabled={activeStep === 0}
                        onClick={handleBack}
                        sx={{ mr: 1 }}
                      >
                        Back
                      </Button>
                      <Box sx={{ flex: "1 1 auto" }} />
                      {/* {isStepOptional(activeStep) && (
                      <Button
                        color="inherit"
                        onClick={handleSkip}
                        sx={{ mr: 1 }}
                      >
                        Skip
                      </Button>
                    )} */}
                      {activeStep === 0 && (
                        <Button
                          variant="outlined"
                          className="btn btn-outline-black"
                          onClick={handleNext}
                        >
                          {activeStep === 0 && "Next"}
                        </Button>
                      )}
                      {activeStep === 1 && (
                        <Button
                          variant="outlined"
                          className="btn btn-outline-black"
                          onClick={handleWalletAndInr}
                        // onClick={handleNext}
                        
                        >
                          {activeStep === 1 && "Finish"}
                        </Button>
                      )}
                    </Box>
                  </React.Fragment>
                )}
              </Box>
              <Modal
              dialogClassName="sign-up-modal app-store blockchain-wait"
              show={blockChainIssue}
              onHide={handleCloseWait}
              >
              <Modal.Header closeButton>
                <Modal.Title className="wait-popup">Please wait</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="auth-container top-space-login pl-3 pr-3 pt-5">
                  <div className="auth-info text-center">
                    <p>The Blockchain is busy, please try after some time.</p>
                    <div className="btn-app-store mb-4">
                      <button
                        className="btn btn-outline-black d-inline-flex col-auto me-2"
                        onClick={handleCloseWait}
                      >
                        Ok
                      </button>
                      {/* <button
                        className="btn btn-black d-inline-flex col-auto"
                        onClick={handleClose}
                        // onClick={onStopLivestream}
                      >
                        ok
                      </button> */}
                    </div>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer></Modal.Footer>
             </Modal>
            </div>
            {/* create livestream with nft popup */}
            
            <div className="create-livestream-right">
              <div
                className="create-livestream-right-bg-img bgImg"
                style={{
                  background: "linear-gradient(to right, #DDEFBB, #FFEEEE)",
                }}
              >
                {/* {!isImageLarge && showThumnail && (
                  <span
                    style={{ position: "absolute", zIndex: 1, padding: "10px" }}
                  >
                    Image size is too small. Recommended Size: 1920Wx1080H px.
                  </span>
                )} */}
                {showThumnail && (
                  <>
                    <div
                      className="bgImg"
                      style={{
                        backgroundImage: `url(${showThumnail})`,
                      }}
                    ></div>
                    <img
                      className={"create-livestream-preview-img"}
                      src={showThumnail}
                    />
                  </>
                )}
                {/* {isImageLarge && showThumnail && (
                  <img
                    className="create-livestream-preview-img"
                    src={showThumnail}
                  />
                )} */}

                <section className="banner-cover livestream">
                  <div className="banner-cover-profile">
                    {/* <div className="banner-cover-profile__left">
                    <div className="banner-cover-profile__left-img">
                      <img alt="banner" src={dummyImg} />
                    </div>
                  </div> */}
                 
                    <div className="banner-cover-profile__right">
                      <div className="banner-cover-profile__info">
                        <h3 className="">{loginUserData[0]?.name}</h3>
                        <h1 className="banner-cover-profile__info-title">
                          {title}
                        </h1>
                        <strong>
                          {moment(dateValue).format("MMM DD")},
                          {moment(timeValue).format("hh:mm a")} IST
                        </strong>
                      </div>
                      <div className="livestream-details">
                        {textareaValue && (
                          <div className="livestream-desc">
                            <h3>{loginUserData[0]?.name}</h3>

                            <p>{textareaValue}</p>
                          </div>
                        )}
                        {livestreamDescValue && (
                          <div className="livestream-desc">
                            <h3>About Livestream</h3>
                            <p>{livestreamDescValue}</p>
                          </div>
                        )}
                      </div>

                      <div className="livestream-detail-btm">
                        {/* <strong className="text-center">
                          Recording will be available till 7 days after
                          Livestream.
                        </strong> */}
                        {/* <button
                          disabled
                          type="button"
                          className="w-100 btn rounded-pill btn-white pe-none"
                        >
                          Buy Tickets
                        </button> */}
                      </div>
                    </div>
                  </div>

                  {/* Desktop View New Changes */}
                  {/* <div className="checkout-card "> */}
                  <div className="checkout-card-latest ms-2">
                    <div className="checkout-btn-sec">
                      <button
                        type="button"
                        className="btn rounded-pill btn-outline-black mb-0"
                      >
                        Regular Ticket &nbsp; &#8377;
                        {ticketPrice}
                      </button>
                      <span
                        style={{
                          borderColor: "#000",
                        }}
                      >
                        <i
                          className="bi bi-info"
                          style={{ color: "#2b2b2b" }}
                        ></i>
                      </span>
                    </div>

                    {nftCheck && (
                      <div className="checkout-btn-sec">
                        <button
                          type="button"
                          className="btn rounded-pill btn-outline-black"
                        >
                          Ticket with NFT &nbsp; &#8377;
                          {nft_price_each}
                        </button>
                        <span
                          style={{
                            borderColor: "#000",
                          }}
                        >
                          <i
                            className="bi bi-info"
                            style={{ color: "#2b2b2b" }}
                          ></i>
                        </span>
                      </div>
                    )}
                  </div>
                  <div className="checkout-img">
                    {/* <img src={CheckoutCard} /> */}
                  </div>
                  {/* <div className="checkout-desc">
                      <h3>{title}</h3>
                      <h2>{loginUserData[0]?.name}</h2>
                      <span className="checkout-time">
                        {moment(dateValue).format("MMM DD")},{" "}
                        {moment(timeValue).format("hh:mm a")} IST
                      </span>

                      <div className="checkout-count">
                        <form className="row">
                          <div className="col-md-12 d-flex mb-2">
                            <label className="me-auto">Ticket</label>
                            {ticketPrice && (
                              <span className="checkout-price">
                                &#8377; {ticketPrice}
                              </span>
                            )}
                          </div>
                          <div className="col-md-12 d-flex mb-2">
                            <label className="me-auto">NFT Price / Each</label>
                            {nft_price_each && (
                              <span className="checkout-price">
                                &#8377; {nft_price_each}
                              </span>
                            )}
                          </div>

                          <div className="col-md-12 d-flex mb-3">
                            <label className="me-auto">Attendees</label>
                            <div className="checkout-counter dummy">
                              <span
                                className="dec"
                                style={{
                                  cursor: "default",
                                  backgroundColor: "#eee !important",
                                }}
                              >
                                
                                <i className="bi bi-dash  "></i>
                              </span>
                              <input
                                type="text"
                                className="form-control"
                                value={count}
                                name="count"
                                disabled
                              />
                              <span
                                className="inc"
                                style={{
                                  cursor: "default",
                                  backgroundColor: "#eee !important",
                                }}
                              >
                                <i className="bi bi-plus"></i>
                              </span>
                            </div>
                          </div>
                          <div className="col-md-12 d-flex">
                            <label className="me-auto fw-bold">Total</label>
                            {ticketPrice && (
                              <div className="checkout-total">
                                <strong>
                                  &#8377;{" "}
                                  {Number(ticketPrice) + Number(nft_price_each)}
                                </strong>
                              </div>
                            )}
                          </div>
                        </form>
                      </div>
                    </div> */}
                  {/* <button
                      disabled
                      type="button"
                      className="w-100 btn rounded-pill btn-white btn-block"
                    >
                      Checkout
                    </button> */}
                  {/* </div> */}
                </section>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Modal
        className="text-center"
        centered
        size="lg"
        show={modalState === "modal-one"}
      >
        <Modal.Body className="py-5">
          <h3>You're all set for your livestream!</h3>
          <p className="pb-3">
            Do you want to send free tickets to your friends and family? (upto
            25)
          </p>
          <div className="d-flex justify-content-center flex-column flex-sm-row">
            <button
              onClick={handleShowModalTwo}
              type="button"
              className="btn btn-outline-black me-sm-3 mb-2  mb-sm-0"
            >
              Yes, send now
            </button>
            <Link
              to="/dashboard/mylivestreams/scheduled"
              type="button"
              className="btn btn-outline-black"
            >
              Do it later
            </Link>
          </div>
        </Modal.Body>
      </Modal>

      <Modal size="lg" show={modalState === "modal-two"}>
        <Modal.Body>
          <div className="d-flex mb-5 mt-2 align-items-center">
            <h3 className="mb-0">Add Guest List (upto 25)</h3>
            <NavLink
              className="ms-auto btn p-0"
              to="/dashboard/mylivestreams/scheduled"
            >
              <i className="bi bi-x-lg me-0"></i>
            </NavLink>
          </div>

          <div className="row mb-3 flex-column flex-lg-row">
            <div className="col mb-3 mb-lg-0">
              <TextField
                fullWidth
                id="outlined-basic"
                label="Name"
                variant="outlined"
                value={gustName}
                onChange={(event) => onChangeGuestName(event)}
              />
              {gustNameError && (
                <p
                  style={{ color: "red", fontSize: "14px", marginBottom: "0" }}
                >
                  {gustNameError}
                </p>
              )}
            </div>
            <div className="col mb-3 mb-lg-0">
              <TextField
                fullWidth
                id="outlined-basic"
                label="Email id"
                variant="outlined"
                value={gustEmail}
                onChange={(event) => onChangeGuestEmail(event)}
              />
              {gustEmailError && (
                <p
                  style={{ color: "red", fontSize: "14px", marginBottom: "0" }}
                >
                  {gustEmailError}
                </p>
              )}
            </div>
            <div className="col  input-w-inner-input mb-3 mb-lg-0">
              <div className="d-flex w-100">
                <div className="w-100">
                  <TextField
                    className="country-code"
                    id="outlined-basic"
                    variant="standard"
                    value={"+91"}
                    disabled
                  />
                  <TextField
                    fullWidth
                    id="outlined-basic"
                    className="phone-number"
                    label="Phone"
                    variant="outlined"
                    value={gustPhone}
                    type="number"
                    onChange={(event) => onChangeGuestPhone(event)}
                  />
                  {gustPhoneError && (
                    <p
                      style={{
                        color: "red",
                        marginBottom: "0",
                        fontSize: "14px",
                      }}
                    >
                      {gustPhoneError}
                    </p>
                  )}
                </div>
              </div>
            </div>
            <div className="col-lg-2 d-flex">
              {gustList.length <= 24 && (
                <button
                  className="btn btn-outline-black w-100"
                  style={{ height: "50px" }}
                  onClick={onAddGustList}
                >
                  Add
                </button>
              )}
            </div>
          </div>
          {gustList.length > 0 && (
            <div className="col-md-12 border p-4 rounded-3 guest-list">
              <ul>
                {gustList.map((data: any, index: number) => {
                  return (
                    <li key={index}>
                      <span className="guest-list-mail">{data?.name}</span>
                      <span className="guest-list-mail">{data?.email}</span>
                      <span className="guest-list-unused">{data?.phone}</span>
                      <span className="guest-list-actions">
                        {/* <button type="button" className="btn px-2">
                      <i className="bi bi-link-45deg"></i>
                    </button>
                    <button type="button" className="btn px-2">
                      <i className="bi bi-send"></i>
                    </button> */}
                        <button
                          type="button"
                          className="btn px-2"
                          onClick={() => onDeleteGustList(index)}
                        >
                          <i className="bi bi-trash text-danger"></i>
                        </button>
                      </span>
                    </li>
                  );
                })}
              </ul>
              {gustList.length > 0 && (
                <button
                  className="btn btn-outline-black mx-auto"
                  onClick={onSendTickets}
                >
                  Send Tickets
                </button>
              )}
            </div>
          )}
        </Modal.Body>
      </Modal>

      {/* Meta Mask Modal */}
      <Modal
        show={showMetaMaskModal}
        onHide={handleCloseMetaMaskModal}
        centered
        dialogClassName="sign-up-modal login-modal"
      >
        <Modal.Header closeButton className="p-3">
          <h4 className="text-center mb-0">MetaMask Not Found</h4>
        </Modal.Header>

        <Modal.Body>
          <div className="auth-container">
            <div className="auth-sec auth-right-sec px-0">
              <div className="text-center">
                <svg
                  width="100"
                  height="100"
                  viewBox="0 0 203 210"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M191.545 145L179.396 186L155.545 179.489L191.545 145Z"
                    fill="#E4761B"
                    stroke="#E4761B"
                  />
                  <path
                    d="M155.545 179L178.549 147.988L191.545 145L155.545 179Z"
                    fill="#E4761B"
                    stroke="#E4761B"
                  />
                  <path
                    d="M173.545 113.971L191.545 144.96L178.459 148L173.545 113.971ZM173.545 113.971L185.127 106L191.545 144.96L173.545 113.971Z"
                    fill="#F6851B"
                    stroke="#F6851B"
                  />
                  <path
                    d="M158.545 91.0971L192.545 76.9995L190.855 84.7221L158.545 91.0971ZM189.78 93.9995L158.545 91.0971L190.855 84.7221L189.78 93.9995Z"
                    fill="#763D16"
                    stroke="#763D16"
                  />
                  <path
                    d="M188.922 93.3432L184.689 106L158.545 90.5528L188.922 93.3432ZM195.545 80.1387L189.968 84.4239L191.611 76.9995L195.545 80.1387ZM188.922 93.3432L189.968 84.4239L194.4 88.1112L188.922 93.3432Z"
                    fill="#763D16"
                    stroke="#763D16"
                  />
                  <path
                    d="M130.545 170L138.603 172.373L155.545 179L130.545 170Z"
                    fill="#E2761B"
                    stroke="#E2761B"
                  />
                  <path
                    d="M185.524 106L189.839 93.7092L193.545 96.4189L185.524 106ZM185.524 106L150.545 95.0156L158.871 90.9995L185.524 106Z"
                    fill="#763D16"
                    stroke="#763D16"
                  />
                  <path
                    d="M167.545 51.9995L159.045 90.8591L150.545 94.9995L167.545 51.9995Z"
                    fill="#763D16"
                    stroke="#763D16"
                  />
                  <path
                    d="M192.545 77.3821L158.545 90.9995L166.943 51.9995L192.545 77.3821Z"
                    fill="#763D16"
                    stroke="#763D16"
                  />
                  <path
                    d="M167.545 51.9518L201.545 47.9995L192.076 76.9995L167.545 51.9518Z"
                    fill="#763D16"
                    stroke="#763D16"
                  />
                  <path
                    d="M185.545 106.144L173.811 114L150.545 94.9995L185.545 106.144Z"
                    fill="#F6851B"
                    stroke="#F6851B"
                  />
                  <path
                    d="M200.073 19.9995L201.545 48.0058L167.545 51.9995L200.073 19.9995Z"
                    fill="#763D16"
                    stroke="#763D16"
                  />
                  <path
                    d="M200.545 19.9995L131.443 69.9995L130.545 36.0869L200.545 19.9995Z"
                    fill="#E2761B"
                    stroke="#E2761B"
                  />
                  <path
                    d="M79.5449 31.9995L130.652 36.0727L131.545 69.9995L79.5449 31.9995Z"
                    fill="#F6851B"
                    stroke="#F6851B"
                  />
                  <path
                    d="M151.19 94.9995L131.545 70.257L167.545 51.9995L151.19 94.9995Z"
                    fill="#763D16"
                    stroke="#763D16"
                  />
                  <path
                    d="M150.752 94.9995L173.545 114.434L141.545 118L150.752 94.9995Z"
                    fill="#E4761B"
                    stroke="#E4761B"
                  />
                  <path
                    d="M141.624 118L131.545 69.9995L150.545 94.9555L141.624 118Z"
                    fill="#E4761B"
                    stroke="#E4761B"
                  />
                  <path
                    d="M167.515 51.8267L131.545 69.9995L200.545 19.9995L167.515 51.8267Z"
                    fill="#763D16"
                    stroke="#763D16"
                  />
                  <path
                    d="M79.6438 179.16L96.5449 196L73.5449 176L79.6438 179.16Z"
                    fill="#C0AD9E"
                    stroke="#C0AD9E"
                  />
                  <path
                    d="M155.545 179L163.261 150.154L178.545 148L155.545 179Z"
                    fill="#CD6116"
                    stroke="#CD6116"
                  />
                  <path
                    d="M13.5449 96.9995L42.5449 67.9995L17.2852 93.539L13.5449 96.9995Z"
                    fill="#E2761B"
                    stroke="#E2761B"
                  />
                  <path
                    d="M178.545 147.813L163.333 150L173.689 113.879L178.545 147.813ZM131.473 70.0079L104.171 69.1136L79.5449 31.9995L131.473 70.0079Z"
                    fill="#F6851B"
                    stroke="#F6851B"
                  />
                  <path
                    d="M173.545 114L163.372 150L162.545 132.42L173.545 114Z"
                    fill="#E4751F"
                    stroke="#E4751F"
                  />
                  <path
                    d="M141.545 117.626L173.545 114L162.298 133L141.545 117.626Z"
                    fill="#CD6116"
                    stroke="#CD6116"
                  />
                  <path
                    d="M104.545 68.9995L131.37 69.9069L141.545 118L104.545 68.9995Z"
                    fill="#F6851B"
                    stroke="#F6851B"
                  />
                  <path
                    d="M104.545 69.3722L30.4854 0.999512L79.792 32.2542L104.545 69.3722ZM79.7422 189.124L15.3945 209L2.54492 160.055L79.7422 189.124Z"
                    fill="#E4761B"
                    stroke="#E4761B"
                  />
                  <path
                    d="M23.5449 109L48.0265 90.9995L68.5449 95.4643L23.5449 109Z"
                    fill="#763D16"
                    stroke="#763D16"
                  />
                  <path
                    d="M68.5449 94.9995L47.5449 90.3399L58.7894 45.9995L68.5449 94.9995Z"
                    fill="#763D16"
                    stroke="#763D16"
                  />
                  <path
                    d="M17.5449 94.1013L47.5449 90.9995L23.4263 109L17.5449 94.1013Z"
                    fill="#763D16"
                    stroke="#763D16"
                  />
                  <path
                    d="M162.545 133L149.854 126.671L141.545 118L162.545 133Z"
                    fill="#CD6116"
                    stroke="#CD6116"
                  />
                  <path
                    d="M17.6618 93.9995L15.5449 82.9995L47.5449 90.8013L17.6618 93.9995Z"
                    fill="#763D16"
                    stroke="#763D16"
                  />
                  <path
                    d="M147.545 141L150.111 127L162.545 133.216L147.545 141Z"
                    fill="#233447"
                    stroke="#233447"
                  />
                  <path
                    d="M163.545 150L147.545 140.614L162.698 133L163.545 150Z"
                    fill="#F6851B"
                    stroke="#F6851B"
                  />
                  <path
                    d="M47.7523 90.3399L15.1992 82.443L12.5449 73.0746L47.7523 90.3399ZM58.7202 45.9995L47.7523 90.3399L12.5449 73.0746L58.7202 45.9995ZM58.7202 45.9995L104.545 69.5921L68.2357 94.9995L58.7202 45.9995Z"
                    fill="#763D16"
                    stroke="#763D16"
                  />
                  <path
                    d="M68.5449 94.7158L104.518 68.9995L120.545 118L68.5449 94.7158ZM120.545 118L70.8274 116.957L68.5449 94.7158L120.545 118Z"
                    fill="#E4761B"
                    stroke="#E4761B"
                  />
                  <path
                    d="M23.5449 109.014L67.9807 94.7158L70.2594 116.957L23.5449 109.014ZM141.545 117.255L119.897 118L103.896 68.9995L141.545 117.255Z"
                    fill="#F6851B"
                    stroke="#F6851B"
                  />
                  <path
                    d="M149.545 126.7L147.024 141L141.545 118L149.545 126.7Z"
                    fill="#CD6116"
                    stroke="#CD6116"
                  />
                  <path
                    d="M30.5449 0.999512L104.545 68.9995L59.0103 45.2292L30.5449 0.999512Z"
                    fill="#763D16"
                    stroke="#763D16"
                  />
                  <path
                    d="M2.54492 160.53L64.8404 158L79.5449 189L2.54492 160.53Z"
                    fill="#E4761B"
                    stroke="#E4761B"
                  />
                  <path
                    d="M79.441 189L64.5449 157.484L95.5449 156L79.441 189Z"
                    fill="#CD6116"
                    stroke="#CD6116"
                  />
                  <path
                    d="M147.783 140.325L163.744 150.133L172.545 171L147.783 140.325ZM70.6145 117.006L2.54492 159.991L23.7266 109L70.6145 117.006ZM64.8965 157.389L2.54492 159.991L70.6145 117.006L64.8965 157.389ZM142.165 117.306L146.242 132.218L126.552 133.369L142.165 117.306ZM126.552 133.369L120.436 118.057L142.165 117.306L126.552 133.369Z"
                    fill="#F6851B"
                    stroke="#F6851B"
                  />
                  <path
                    d="M96.2224 195.7L79.5449 189L137.545 199L96.2224 195.7Z"
                    fill="#C0AD9E"
                    stroke="#C0AD9E"
                  />
                  <path
                    d="M23.5449 109L13.5449 97.3591L17.347 93.9995L23.5449 109Z"
                    fill="#763D16"
                    stroke="#763D16"
                  />
                  <path
                    d="M144.545 191.588L137.92 199L79.5449 189L144.545 191.588Z"
                    fill="#D7C1B3"
                    stroke="#D7C1B3"
                  />
                  <path
                    d="M147.545 173.811L79.5449 189L95.4514 156L147.545 173.811Z"
                    fill="#E4761B"
                    stroke="#E4761B"
                  />
                  <path
                    d="M79.5449 189.435L147.545 174L144.413 192L79.5449 189.435Z"
                    fill="#D7C1B3"
                    stroke="#D7C1B3"
                  />
                  <path
                    d="M12.7786 73.2228L10.5449 34.9995L58.5449 45.7855L12.7786 73.2228ZM17.5439 93.9995L10.942 87.2893L15.4095 82.7164L17.5439 93.9995Z"
                    fill="#763D16"
                    stroke="#763D16"
                  />
                  <path
                    d="M108.545 128.227L120.545 118L118.831 143L108.545 128.227Z"
                    fill="#CD6116"
                    stroke="#CD6116"
                  />
                  <path
                    d="M120.545 118L109.141 128.25L92.5449 137L120.545 118Z"
                    fill="#CD6116"
                    stroke="#CD6116"
                  />
                  <path
                    d="M172.545 171L169.483 168.797L147.545 141L172.545 171Z"
                    fill="#F6851B"
                    stroke="#F6851B"
                  />
                  <path
                    d="M93.0998 137L70.5449 118L120.545 119.028L93.0998 137Z"
                    fill="#CD6116"
                    stroke="#CD6116"
                  />
                  <path
                    d="M118.545 143L120.197 118L126.545 133.805L118.545 143Z"
                    fill="#E4751F"
                    stroke="#E4751F"
                  />
                  <path
                    d="M8.54492 76.9786L12.7763 72.9995L15.5449 82.9995L8.54492 76.9786Z"
                    fill="#763D16"
                    stroke="#763D16"
                  />
                  <path
                    d="M118.545 143L92.5449 136.863L108.908 128L118.545 143Z"
                    fill="#233447"
                    stroke="#233447"
                  />
                  <path
                    d="M58.5449 45.9995L10.5449 35.0889L30.152 0.999512L58.5449 45.9995Z"
                    fill="#763D16"
                    stroke="#763D16"
                  />
                  <path
                    d="M137.826 199.023L140.545 206L96.5449 196L137.826 199.023Z"
                    fill="#C0AD9E"
                    stroke="#C0AD9E"
                  />
                  <path
                    d="M95.0044 156L92.5449 137L118.545 142.714L95.0044 156Z"
                    fill="#F6851B"
                    stroke="#F6851B"
                  />
                  <path
                    d="M70.5449 118L93.0998 136.975L95.5449 156L70.5449 118Z"
                    fill="#E4751F"
                    stroke="#E4751F"
                  />
                  <path
                    d="M126.427 134.128L146.189 132.994L169.545 169L126.427 134.128ZM70.284 118L95.2864 156.323L64.5449 157.803L70.284 118Z"
                    fill="#F6851B"
                    stroke="#F6851B"
                  />
                  <path
                    d="M126.211 134L150.545 171L118.545 142.793L126.211 134Z"
                    fill="#E4751F"
                    stroke="#E4751F"
                  />
                  <path
                    d="M118.545 143L150.545 171.007L147.182 174L118.545 143Z"
                    fill="#F6851B"
                    stroke="#F6851B"
                  />
                  <path
                    d="M147.663 174L95.5449 156.222L118.869 142.79L147.663 174ZM169.545 168.913L151.045 170.987L126.577 134L169.545 168.913Z"
                    fill="#F6851B"
                    stroke="#F6851B"
                  />
                  <path
                    d="M174.545 186L166.48 201.851L140.317 206L174.545 186ZM140.317 206L137.545 198.592L144.249 191.382L140.317 206Z"
                    fill="#C0AD9E"
                    stroke="#C0AD9E"
                  />
                  <path
                    d="M144.451 191.382L149.358 189.456L140.545 206L144.451 191.382ZM140.545 206L149.358 189.456L174.545 186L140.545 206Z"
                    fill="#C0AD9E"
                    stroke="#C0AD9E"
                  />
                  <path
                    d="M169.296 169L175.545 172.75L156.545 175L169.296 169Z"
                    fill="#161616"
                    stroke="#161616"
                  />
                  <path
                    d="M156.447 174.795L150.545 171.028L168.691 169L156.447 174.795ZM154.35 177.644L176.545 174.988L173.472 186L154.35 177.644Z"
                    fill="#161616"
                    stroke="#161616"
                  />
                  <path
                    d="M173.509 186.536L149.268 190.045L154.617 177.866L173.509 186.536ZM149.268 190.045L144.545 192L147.581 174.057L149.268 190.045ZM147.581 174.057L150.858 171L156.69 174.909L147.581 174.057ZM174.714 172.653L176.545 175.109L154.617 177.866L174.714 172.653Z"
                    fill="#161616"
                    stroke="#161616"
                  />
                  <path
                    d="M154.806 177.394L156.945 174.334L175.545 172L154.806 177.394ZM147.545 173.452L154.806 177.394L149.286 190L147.545 173.452Z"
                    fill="#161616"
                    stroke="#161616"
                  />
                  <path
                    d="M156.545 174.894L154.497 178L147.545 174L156.545 174.894Z"
                    fill="#161616"
                    stroke="#161616"
                  />
                </svg>
              </div>
              <h3 className="text-center">Please Install MetaMask</h3>
              <div className="text-center">
                <svg
                  width="50"
                  height="50"
                  stroke="currentColor"
                  fill="currentColor"
                  stroke-width="0"
                  viewBox="0 0 16 16"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M11.957 6h.05a2.99 2.99 0 0 1 2.116.879 3.003 3.003 0 0 1 0 4.242 2.99 2.99 0 0 1-2.117.879v-1a2.002 2.002 0 0 0 0-4h-.914l-.123-.857a2.49 2.49 0 0 0-2.126-2.122A2.478 2.478 0 0 0 6.231 5.5l-.333.762-.809-.189A2.49 2.49 0 0 0 4.523 6c-.662 0-1.297.263-1.764.732A2.503 2.503 0 0 0 4.523 11h.498v1h-.498a3.486 3.486 0 0 1-2.628-1.16 3.502 3.502 0 0 1 1.958-5.78 3.462 3.462 0 0 1 1.468.04 3.486 3.486 0 0 1 3.657-2.06A3.479 3.479 0 0 1 11.957 6zm-5.25 5.121l1.314 1.314V7h.994v5.4l1.278-1.279.707.707-2.146 2.147h-.708L6 11.829l.707-.708z"
                  ></path>
                </svg>
              </div>
              <a
                href="https://metamask.io/download/"
                className="btn rounded-pill btn-outline-black btn-block flex-grow-1 my-3"
                target="_blank"
              >
                Download
              </a>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
      {/* Payment Method with Wallet and INR */}
      <Modal
        show={showUserSelectMethodModal}
        onHide={handleCloseUserSelectMethodModal}
        centered
        dialogClassName="ticket-modal nft"
        style={{ zIndex: "1300" }}
      >
        <Modal.Header className="p-2 mb-2 d-flex flex-column align-items-center">
          <h3 className=" mb-0">NFT Price</h3>
          <span className="checkout-card-price sml mx-auto">
            &#8377; {nft_price_each} (NFT) + &#8377; {Math.ceil(valueInINR)} (Gas)
          </span>
        </Modal.Header>

        <Modal.Body className="px-0">
          <div className="row mt-2">
            <div className=" w-100 d-flex flex-wrap">
              <strong className="col-12 text-center pb-2 ">Pay via</strong>
              <div className="justify-content-between d-none d-md-flex mb-3">
                <span
                  className="me-3 me-md-5 pay-in"
                  title="MetaMask"
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    connectWallet("MetaMask", selectedLivestream[0])
                  }
                >
                  <label className="ps-0 fw-bold">Crypto</label>
                  <svg
                    width="50"
                    height="50"
                    viewBox="0 0 203 210"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M191.545 145L179.396 186L155.545 179.489L191.545 145Z"
                      fill="#E4761B"
                      stroke="#E4761B"
                    />
                    <path
                      d="M155.545 179L178.549 147.988L191.545 145L155.545 179Z"
                      fill="#E4761B"
                      stroke="#E4761B"
                    />
                    <path
                      d="M173.545 113.971L191.545 144.96L178.459 148L173.545 113.971ZM173.545 113.971L185.127 106L191.545 144.96L173.545 113.971Z"
                      fill="#F6851B"
                      stroke="#F6851B"
                    />
                    <path
                      d="M158.545 91.0971L192.545 76.9995L190.855 84.7221L158.545 91.0971ZM189.78 93.9995L158.545 91.0971L190.855 84.7221L189.78 93.9995Z"
                      fill="#763D16"
                      stroke="#763D16"
                    />
                    <path
                      d="M188.922 93.3432L184.689 106L158.545 90.5528L188.922 93.3432ZM195.545 80.1387L189.968 84.4239L191.611 76.9995L195.545 80.1387ZM188.922 93.3432L189.968 84.4239L194.4 88.1112L188.922 93.3432Z"
                      fill="#763D16"
                      stroke="#763D16"
                    />
                    <path
                      d="M130.545 170L138.603 172.373L155.545 179L130.545 170Z"
                      fill="#E2761B"
                      stroke="#E2761B"
                    />
                    <path
                      d="M185.524 106L189.839 93.7092L193.545 96.4189L185.524 106ZM185.524 106L150.545 95.0156L158.871 90.9995L185.524 106Z"
                      fill="#763D16"
                      stroke="#763D16"
                    />
                    <path
                      d="M167.545 51.9995L159.045 90.8591L150.545 94.9995L167.545 51.9995Z"
                      fill="#763D16"
                      stroke="#763D16"
                    />
                    <path
                      d="M192.545 77.3821L158.545 90.9995L166.943 51.9995L192.545 77.3821Z"
                      fill="#763D16"
                      stroke="#763D16"
                    />
                    <path
                      d="M167.545 51.9518L201.545 47.9995L192.076 76.9995L167.545 51.9518Z"
                      fill="#763D16"
                      stroke="#763D16"
                    />
                    <path
                      d="M185.545 106.144L173.811 114L150.545 94.9995L185.545 106.144Z"
                      fill="#F6851B"
                      stroke="#F6851B"
                    />
                    <path
                      d="M200.073 19.9995L201.545 48.0058L167.545 51.9995L200.073 19.9995Z"
                      fill="#763D16"
                      stroke="#763D16"
                    />
                    <path
                      d="M200.545 19.9995L131.443 69.9995L130.545 36.0869L200.545 19.9995Z"
                      fill="#E2761B"
                      stroke="#E2761B"
                    />
                    <path
                      d="M79.5449 31.9995L130.652 36.0727L131.545 69.9995L79.5449 31.9995Z"
                      fill="#F6851B"
                      stroke="#F6851B"
                    />
                    <path
                      d="M151.19 94.9995L131.545 70.257L167.545 51.9995L151.19 94.9995Z"
                      fill="#763D16"
                      stroke="#763D16"
                    />
                    <path
                      d="M150.752 94.9995L173.545 114.434L141.545 118L150.752 94.9995Z"
                      fill="#E4761B"
                      stroke="#E4761B"
                    />
                    <path
                      d="M141.624 118L131.545 69.9995L150.545 94.9555L141.624 118Z"
                      fill="#E4761B"
                      stroke="#E4761B"
                    />
                    <path
                      d="M167.515 51.8267L131.545 69.9995L200.545 19.9995L167.515 51.8267Z"
                      fill="#763D16"
                      stroke="#763D16"
                    />
                    <path
                      d="M79.6438 179.16L96.5449 196L73.5449 176L79.6438 179.16Z"
                      fill="#C0AD9E"
                      stroke="#C0AD9E"
                    />
                    <path
                      d="M155.545 179L163.261 150.154L178.545 148L155.545 179Z"
                      fill="#CD6116"
                      stroke="#CD6116"
                    />
                    <path
                      d="M13.5449 96.9995L42.5449 67.9995L17.2852 93.539L13.5449 96.9995Z"
                      fill="#E2761B"
                      stroke="#E2761B"
                    />
                    <path
                      d="M178.545 147.813L163.333 150L173.689 113.879L178.545 147.813ZM131.473 70.0079L104.171 69.1136L79.5449 31.9995L131.473 70.0079Z"
                      fill="#F6851B"
                      stroke="#F6851B"
                    />
                    <path
                      d="M173.545 114L163.372 150L162.545 132.42L173.545 114Z"
                      fill="#E4751F"
                      stroke="#E4751F"
                    />
                    <path
                      d="M141.545 117.626L173.545 114L162.298 133L141.545 117.626Z"
                      fill="#CD6116"
                      stroke="#CD6116"
                    />
                    <path
                      d="M104.545 68.9995L131.37 69.9069L141.545 118L104.545 68.9995Z"
                      fill="#F6851B"
                      stroke="#F6851B"
                    />
                    <path
                      d="M104.545 69.3722L30.4854 0.999512L79.792 32.2542L104.545 69.3722ZM79.7422 189.124L15.3945 209L2.54492 160.055L79.7422 189.124Z"
                      fill="#E4761B"
                      stroke="#E4761B"
                    />
                    <path
                      d="M23.5449 109L48.0265 90.9995L68.5449 95.4643L23.5449 109Z"
                      fill="#763D16"
                      stroke="#763D16"
                    />
                    <path
                      d="M68.5449 94.9995L47.5449 90.3399L58.7894 45.9995L68.5449 94.9995Z"
                      fill="#763D16"
                      stroke="#763D16"
                    />
                    <path
                      d="M17.5449 94.1013L47.5449 90.9995L23.4263 109L17.5449 94.1013Z"
                      fill="#763D16"
                      stroke="#763D16"
                    />
                    <path
                      d="M162.545 133L149.854 126.671L141.545 118L162.545 133Z"
                      fill="#CD6116"
                      stroke="#CD6116"
                    />
                    <path
                      d="M17.6618 93.9995L15.5449 82.9995L47.5449 90.8013L17.6618 93.9995Z"
                      fill="#763D16"
                      stroke="#763D16"
                    />
                    <path
                      d="M147.545 141L150.111 127L162.545 133.216L147.545 141Z"
                      fill="#233447"
                      stroke="#233447"
                    />
                    <path
                      d="M163.545 150L147.545 140.614L162.698 133L163.545 150Z"
                      fill="#F6851B"
                      stroke="#F6851B"
                    />
                    <path
                      d="M47.7523 90.3399L15.1992 82.443L12.5449 73.0746L47.7523 90.3399ZM58.7202 45.9995L47.7523 90.3399L12.5449 73.0746L58.7202 45.9995ZM58.7202 45.9995L104.545 69.5921L68.2357 94.9995L58.7202 45.9995Z"
                      fill="#763D16"
                      stroke="#763D16"
                    />
                    <path
                      d="M68.5449 94.7158L104.518 68.9995L120.545 118L68.5449 94.7158ZM120.545 118L70.8274 116.957L68.5449 94.7158L120.545 118Z"
                      fill="#E4761B"
                      stroke="#E4761B"
                    />
                    <path
                      d="M23.5449 109.014L67.9807 94.7158L70.2594 116.957L23.5449 109.014ZM141.545 117.255L119.897 118L103.896 68.9995L141.545 117.255Z"
                      fill="#F6851B"
                      stroke="#F6851B"
                    />
                    <path
                      d="M149.545 126.7L147.024 141L141.545 118L149.545 126.7Z"
                      fill="#CD6116"
                      stroke="#CD6116"
                    />
                    <path
                      d="M30.5449 0.999512L104.545 68.9995L59.0103 45.2292L30.5449 0.999512Z"
                      fill="#763D16"
                      stroke="#763D16"
                    />
                    <path
                      d="M2.54492 160.53L64.8404 158L79.5449 189L2.54492 160.53Z"
                      fill="#E4761B"
                      stroke="#E4761B"
                    />
                    <path
                      d="M79.441 189L64.5449 157.484L95.5449 156L79.441 189Z"
                      fill="#CD6116"
                      stroke="#CD6116"
                    />
                    <path
                      d="M147.783 140.325L163.744 150.133L172.545 171L147.783 140.325ZM70.6145 117.006L2.54492 159.991L23.7266 109L70.6145 117.006ZM64.8965 157.389L2.54492 159.991L70.6145 117.006L64.8965 157.389ZM142.165 117.306L146.242 132.218L126.552 133.369L142.165 117.306ZM126.552 133.369L120.436 118.057L142.165 117.306L126.552 133.369Z"
                      fill="#F6851B"
                      stroke="#F6851B"
                    />
                    <path
                      d="M96.2224 195.7L79.5449 189L137.545 199L96.2224 195.7Z"
                      fill="#C0AD9E"
                      stroke="#C0AD9E"
                    />
                    <path
                      d="M23.5449 109L13.5449 97.3591L17.347 93.9995L23.5449 109Z"
                      fill="#763D16"
                      stroke="#763D16"
                    />
                    <path
                      d="M144.545 191.588L137.92 199L79.5449 189L144.545 191.588Z"
                      fill="#D7C1B3"
                      stroke="#D7C1B3"
                    />
                    <path
                      d="M147.545 173.811L79.5449 189L95.4514 156L147.545 173.811Z"
                      fill="#E4761B"
                      stroke="#E4761B"
                    />
                    <path
                      d="M79.5449 189.435L147.545 174L144.413 192L79.5449 189.435Z"
                      fill="#D7C1B3"
                      stroke="#D7C1B3"
                    />
                    <path
                      d="M12.7786 73.2228L10.5449 34.9995L58.5449 45.7855L12.7786 73.2228ZM17.5439 93.9995L10.942 87.2893L15.4095 82.7164L17.5439 93.9995Z"
                      fill="#763D16"
                      stroke="#763D16"
                    />
                    <path
                      d="M108.545 128.227L120.545 118L118.831 143L108.545 128.227Z"
                      fill="#CD6116"
                      stroke="#CD6116"
                    />
                    <path
                      d="M120.545 118L109.141 128.25L92.5449 137L120.545 118Z"
                      fill="#CD6116"
                      stroke="#CD6116"
                    />
                    <path
                      d="M172.545 171L169.483 168.797L147.545 141L172.545 171Z"
                      fill="#F6851B"
                      stroke="#F6851B"
                    />
                    <path
                      d="M93.0998 137L70.5449 118L120.545 119.028L93.0998 137Z"
                      fill="#CD6116"
                      stroke="#CD6116"
                    />
                    <path
                      d="M118.545 143L120.197 118L126.545 133.805L118.545 143Z"
                      fill="#E4751F"
                      stroke="#E4751F"
                    />
                    <path
                      d="M8.54492 76.9786L12.7763 72.9995L15.5449 82.9995L8.54492 76.9786Z"
                      fill="#763D16"
                      stroke="#763D16"
                    />
                    <path
                      d="M118.545 143L92.5449 136.863L108.908 128L118.545 143Z"
                      fill="#233447"
                      stroke="#233447"
                    />
                    <path
                      d="M58.5449 45.9995L10.5449 35.0889L30.152 0.999512L58.5449 45.9995Z"
                      fill="#763D16"
                      stroke="#763D16"
                    />
                    <path
                      d="M137.826 199.023L140.545 206L96.5449 196L137.826 199.023Z"
                      fill="#C0AD9E"
                      stroke="#C0AD9E"
                    />
                    <path
                      d="M95.0044 156L92.5449 137L118.545 142.714L95.0044 156Z"
                      fill="#F6851B"
                      stroke="#F6851B"
                    />
                    <path
                      d="M70.5449 118L93.0998 136.975L95.5449 156L70.5449 118Z"
                      fill="#E4751F"
                      stroke="#E4751F"
                    />
                    <path
                      d="M126.427 134.128L146.189 132.994L169.545 169L126.427 134.128ZM70.284 118L95.2864 156.323L64.5449 157.803L70.284 118Z"
                      fill="#F6851B"
                      stroke="#F6851B"
                    />
                    <path
                      d="M126.211 134L150.545 171L118.545 142.793L126.211 134Z"
                      fill="#E4751F"
                      stroke="#E4751F"
                    />
                    <path
                      d="M118.545 143L150.545 171.007L147.182 174L118.545 143Z"
                      fill="#F6851B"
                      stroke="#F6851B"
                    />
                    <path
                      d="M147.663 174L95.5449 156.222L118.869 142.79L147.663 174ZM169.545 168.913L151.045 170.987L126.577 134L169.545 168.913Z"
                      fill="#F6851B"
                      stroke="#F6851B"
                    />
                    <path
                      d="M174.545 186L166.48 201.851L140.317 206L174.545 186ZM140.317 206L137.545 198.592L144.249 191.382L140.317 206Z"
                      fill="#C0AD9E"
                      stroke="#C0AD9E"
                    />
                    <path
                      d="M144.451 191.382L149.358 189.456L140.545 206L144.451 191.382ZM140.545 206L149.358 189.456L174.545 186L140.545 206Z"
                      fill="#C0AD9E"
                      stroke="#C0AD9E"
                    />
                    <path
                      d="M169.296 169L175.545 172.75L156.545 175L169.296 169Z"
                      fill="#161616"
                      stroke="#161616"
                    />
                    <path
                      d="M156.447 174.795L150.545 171.028L168.691 169L156.447 174.795ZM154.35 177.644L176.545 174.988L173.472 186L154.35 177.644Z"
                      fill="#161616"
                      stroke="#161616"
                    />
                    <path
                      d="M173.509 186.536L149.268 190.045L154.617 177.866L173.509 186.536ZM149.268 190.045L144.545 192L147.581 174.057L149.268 190.045ZM147.581 174.057L150.858 171L156.69 174.909L147.581 174.057ZM174.714 172.653L176.545 175.109L154.617 177.866L174.714 172.653Z"
                      fill="#161616"
                      stroke="#161616"
                    />
                    <path
                      d="M154.806 177.394L156.945 174.334L175.545 172L154.806 177.394ZM147.545 173.452L154.806 177.394L149.286 190L147.545 173.452Z"
                      fill="#161616"
                      stroke="#161616"
                    />
                    <path
                      d="M156.545 174.894L154.497 178L147.545 174L156.545 174.894Z"
                      fill="#161616"
                      stroke="#161616"
                    />
                  </svg>
                  <span className="d-block pt-2">MetaMask</span>
                </span>
                <span
                  id="nftButton"
                  className="pay-in pay-in-inr"
                  onClick={(ev) => openRezerPay(ev)}
                  style={{ cursor: "pointer" }}
                >
                  <label className="ps-0 fw-bold">Fiat Money</label>
                  <img src={razorPayIcon} alt="" />
                  <button type="button" value="nftButton" className="btn p-0">
                    <span className="sml mx-auto">
                      <strong>
                      INR (&#8377;){" "}
                      {Number(nft_price_each && nft_price_each) + Math.ceil(Number(valueInINR && valueInINR))}
                      </strong>
                    </span>
                  </button>
                </span>

                {/* <span
                    className="me-3 me-md-5"
                    title="Coinbase"
                    style={{ cursor: "pointer" }}
                    onClick={() => connectWallet("Coinbase")}
                  >
                    <svg
                      width="50"
                      height="50"
                      viewBox="0 0 383 383"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0)">
                        <g filter="url(#filter0_dd)">
                          <path
                            d="M0.998047 0.572266H382.78V382.354H0.998047V0.572266Z"
                            fill="url(#paint0_linear)"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M59.1074 191.572C59.1074 264.966 118.605 324.463 191.998 324.463C265.392 324.463 324.889 264.966 324.889 191.572C324.889 118.179 265.392 58.6816 191.998 58.6816C118.605 58.6816 59.1074 118.179 59.1074 191.572ZM158.037 148.752C153.144 148.752 149.178 152.718 149.178 157.611V225.533C149.178 230.426 153.144 234.393 158.037 234.393H225.959C230.852 234.393 234.818 230.426 234.818 225.533V157.611C234.818 152.718 230.852 148.752 225.959 148.752H158.037Z"
                            fill="white"
                          />
                        </g>
                      </g>
                      <defs>
                        <filter
                          id="filter0_dd"
                          x="-23.002"
                          y="-7.42773"
                          width="429.782"
                          height="429.782"
                          filterUnits="userSpaceOnUse"
                          color-interpolation-filters="sRGB"
                        >
                          <feFlood
                            flood-opacity="0"
                            result="BackgroundImageFix"
                          />
                          <feColorMatrix
                            in="SourceAlpha"
                            type="matrix"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                          />
                          <feOffset dy="16" />
                          <feGaussianBlur stdDeviation="12" />
                          <feColorMatrix
                            type="matrix"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0"
                          />
                          <feBlend
                            mode="normal"
                            in2="BackgroundImageFix"
                            result="effect1_dropShadow"
                          />
                          <feColorMatrix
                            in="SourceAlpha"
                            type="matrix"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                          />
                          <feOffset />
                          <feGaussianBlur stdDeviation="4" />
                          <feColorMatrix
                            type="matrix"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.04 0"
                          />
                          <feBlend
                            mode="normal"
                            in2="effect1_dropShadow"
                            result="effect2_dropShadow"
                          />
                          <feBlend
                            mode="normal"
                            in="SourceGraphic"
                            in2="effect2_dropShadow"
                            result="shape"
                          />
                        </filter>
                        <linearGradient
                          id="paint0_linear"
                          x1="191.889"
                          y1="0.572266"
                          x2="191.889"
                          y2="382.354"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stop-color="#2E66F8" />
                          <stop offset="1" stop-color="#124ADB" />
                        </linearGradient>
                        <clipPath id="clip0">
                          <rect width="383" height="383" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </span>
                  <span
                    style={{ cursor: "pointer" }}
                    className="me-3 me-md-5"
                    title="Walletconnect"
                    onClick={() => connectWallet("Walletconnect")}
                  >
                    <svg
                      width="50"
                      height="50"
                      viewBox="0 0 92 91"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <ellipse
                        cx="46.0003"
                        cy="45.2335"
                        rx="45.639"
                        ry="45.0778"
                        fill="#3C99FC"
                        fill-opacity="0.1"
                      />
                      <g clip-path="url(#clip0)">
                        <path
                          d="M31.011 35.427C39.4889 27.1436 53.2344 27.1436 61.7123 35.427L62.7327 36.4239C63.1566 36.8381 63.1566 37.5096 62.7327 37.9237L59.2423 41.334C59.0303 41.5411 58.6867 41.5411 58.4748 41.334L57.0707 39.9621C51.1562 34.1834 41.5671 34.1834 35.6526 39.9621L34.1489 41.4313C33.937 41.6384 33.5934 41.6384 33.3814 41.4313L29.8911 38.021C29.4672 37.6069 29.4672 36.9354 29.8911 36.5212L31.011 35.427ZM68.9307 42.4798L72.0371 45.5149C72.461 45.9291 72.461 46.6006 72.0371 47.0147L58.0301 60.7005C57.6062 61.1147 56.9189 61.1147 56.495 60.7006C56.495 60.7005 56.495 60.7005 56.495 60.7005L46.5536 50.9873C46.4476 50.8838 46.2758 50.8838 46.1698 50.9873C46.1698 50.9873 46.1698 50.9873 46.1698 50.9873L36.2286 60.7005C35.8048 61.1147 35.1175 61.1147 34.6936 60.7006C34.6936 60.7006 34.6936 60.7006 34.6936 60.7005L20.6861 47.0145C20.2622 46.6004 20.2622 45.9289 20.6861 45.5147L23.7925 42.4796C24.2164 42.0654 24.9037 42.0654 25.3276 42.4796L35.2691 52.1929C35.3751 52.2965 35.5469 52.2965 35.6529 52.1929C35.6529 52.1929 35.6529 52.1929 35.6529 52.1929L45.5939 42.4796C46.0178 42.0654 46.7051 42.0654 47.129 42.4795C47.129 42.4796 47.129 42.4796 47.129 42.4796L57.0705 52.1929C57.1765 52.2965 57.3483 52.2965 57.4543 52.1929L67.3957 42.4798C67.8195 42.0656 68.5068 42.0656 68.9307 42.4798Z"
                          fill="#3C99FC"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0">
                          <rect
                            width="52"
                            height="32"
                            fill="white"
                            transform="translate(20.3613 29.1556)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  </span>
                  <span
                    style={{ cursor: "pointer" }}
                    title="MEW"
                    onClick={() => connectWallet("MEW")}
                  >
                    <svg
                      width="50"
                      height="50"
                      viewBox="0 0 120 120"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g
                        id="Buttons"
                        stroke="none"
                        stroke-width="1"
                        fill="none"
                        fill-rule="evenodd"
                      >
                        <g
                          id="04.-Guide---App-icon"
                          transform="translate(-54.000000, -188.000000)"
                        >
                          <g
                            id="App-Icon/mew_wallet_appicon"
                            transform="translate(54.000000, 188.000000)"
                          >
                            <path
                              d="M111.821972,8.17802805 C104.415006,0.771062258 96.991511,0.513035658 84.5656411,0.50054281 L35.4338562,0.500543063 C23.008489,0.513035658 15.5849938,0.771062258 8.17802805,8.17802805 C0.771062212,15.5849938 0.513035654,23.008489 0.50054281,35.4343589 L0.500543063,84.5661438 C0.513035654,96.991511 0.771062212,104.415006 8.17802805,111.821972 C15.9079529,119.551897 23.656924,119.5 37.0828183,119.5 L82.9171817,119.5 C96.343076,119.5 104.092047,119.551897 111.821972,111.821972 C119.551897,104.092047 119.5,96.343076 119.5,82.9171817 L119.5,37.0828183 C119.5,23.656924 119.551897,15.9079529 111.821972,8.17802805 Z"
                              id="iOS-icon-mask"
                              stroke="#DADCE0"
                              fill="#FFFFFF"
                            ></path>
                            <g id="Elements/App-Icon">
                              <g
                                id="icon"
                                transform="translate(2.000000, 2.000000)"
                              >
                                <path
                                  d="M78.2902062,106.530612 C100.188166,107.566326 102.396167,88.4204441 95.9815645,77.2712546 C89.7672785,66.4702342 80.4458501,62.7712544 65.3703851,64.1900465 C50.29492,65.6088385 65.4352032,90.169341 75.777958,88.3681934 C80.4702531,87.5510499 76.3984486,106.441137 78.2902062,106.530612 Z"
                                  id="right-arm"
                                  stroke="#000000"
                                  stroke-width="2.5"
                                  fill="#FFFFFF"
                                  fill-rule="nonzero"
                                ></path>
                                <path
                                  d="M37.7086826,106.530612 C15.8107234,107.566326 13.6027223,88.4204441 20.0173244,77.2712546 C26.2316101,66.4702341 35.5530388,62.7712544 50.6285038,64.1900464 C65.7039688,65.6088385 50.5636857,90.169341 40.2209306,88.3681933 C35.5286355,87.5510498 39.6004401,106.441137 37.7086826,106.530612 Z"
                                  id="left-arm"
                                  stroke="#000000"
                                  stroke-width="2.5"
                                  fill="#FFFFFF"
                                  fill-rule="nonzero"
                                ></path>
                                <path
                                  d="M60.7410433,9.46938776 C68.2263324,9.46938776 73.0871763,10.6723916 77.3062878,12.9313812 C81.5253989,15.1903709 84.8365819,18.5053464 87.0929873,22.7292899 C89.3251303,26.9078146 90.5250571,31.7150649 90.5510204,39.072678 L90.5510204,43.5436364 C90.5510204,51.0374983 89.349393,55.9039096 87.0929873,60.1278531 C84.8365819,64.3517963 81.5253989,67.6667718 77.3062878,69.9257617 C73.0871763,72.1847514 68.2263324,73.3877551 60.7410433,73.3877551 L55.2589567,73.3877551 C47.7736676,73.3877551 42.9128237,72.1847514 38.6937122,69.9257617 C34.4746011,67.6667718 31.1634181,64.3517963 28.9070125,60.1278531 C26.6506072,55.9039096 25.4489796,51.0374983 25.4489796,43.5436364 L25.4489796,39.3135064 C25.4489796,31.8196446 26.6506072,26.9532333 28.9070125,22.7292899 C31.1634181,18.5053464 34.4746011,15.1903709 38.6937122,12.9313812 C42.8674567,10.6966818 47.6692075,9.4953809 55.0184035,9.46938776 L60.7410433,9.46938776 Z"
                                  id="helmet"
                                  stroke="#000000"
                                  stroke-width="2.5"
                                  fill="#FFFFFF"
                                  fill-rule="nonzero"
                                ></path>
                                <path
                                  d="M54.0368739,27.8163265 L61.9631261,27.8163265 C65.9787611,27.8163265 69.0898725,28.5567899 71.7010112,29.9472255 C74.3121499,31.3376607 76.361387,33.3780684 77.7578394,35.9779566 C79.1542915,38.5778448 79.8979592,41.6755514 79.8979592,45.6738844 L79.8979592,46.6526462 C79.8979592,50.6509792 79.1542915,53.7486858 77.7578394,56.348574 C76.361387,58.9484622 74.3121499,60.9888699 71.7010112,62.3793051 C69.0898725,63.7697406 65.9787611,64.5102041 61.9631261,64.5102041 L54.0368739,64.5102041 C50.0212389,64.5102041 46.9101275,63.7697406 44.2989888,62.3793051 C41.6878501,60.9888699 39.638613,58.9484622 38.2421606,56.348574 C36.8457085,53.7486858 36.1020408,50.6509792 36.1020408,46.6526462 L36.1020408,45.6738844 C36.1020408,41.6755514 36.8457085,38.5778448 38.2421606,35.9779566 C39.638613,33.3780684 41.6878501,31.3376607 44.2989888,29.9472255 C46.9101275,28.5567899 50.0212389,27.8163265 54.0368739,27.8163265 Z"
                                  id="screen"
                                  stroke="#000000"
                                  stroke-width="2.5"
                                  fill="#FFFFFF"
                                  fill-rule="nonzero"
                                ></path>
                                <circle
                                  id="left-eye"
                                  fill="#000000"
                                  fill-rule="nonzero"
                                  cx="44.0918367"
                                  cy="46.4591837"
                                  r="4.73469388"
                                ></circle>
                                <circle
                                  id="right-eye"
                                  fill="#000000"
                                  fill-rule="nonzero"
                                  cx="71.9081633"
                                  cy="46.4591837"
                                  r="4.73469388"
                                ></circle>
                                <path
                                  d="M52.9693878,45.5714286 C52.9693878,48.1863278 55.2216695,50.3061224 58,50.3061224 C60.7783305,50.3061224 63.0306122,48.1863278 63.0306122,45.5714286"
                                  id="month"
                                  stroke="#000000"
                                  stroke-width="2.5"
                                  stroke-linecap="round"
                                ></path>
                                <g
                                  id="left-ear"
                                  transform="translate(16.867347, 27.816327)"
                                >
                                  <path
                                    d="M8.50342442,0.0863375259 C9.91857046,0.286175668 11.1461997,0.798351267 12.1319865,1.5504265 C13.1177733,2.30250175 13.8617176,3.29447664 14.309494,4.45391321 C14.7572701,5.61334978 14.9088781,6.94024834 14.7099923,8.36217071 L14.5288878,9.65696686 C14.3300019,11.0788892 13.8202671,12.3123968 13.071776,13.302904 C12.3232849,14.2934111 11.3360374,15.0409178 10.1821262,15.4908383 C9.02821495,15.9407585 7.70764003,16.0930925 6.29249399,15.8932544 C4.87734794,15.6934163 3.64971867,15.1812405 2.66393187,14.4291654 C1.67814507,13.6770901 0.93420071,12.6851152 0.486424508,11.5256786 C0.0386483057,10.366242 -0.112959744,9.0393435 0.0859260681,7.61742112 L0.267030596,6.32262498 C0.465916407,4.9007026 0.975651203,3.667195 1.72414235,2.67668787 C2.4726335,1.6861807 3.459881,0.93867404 4.6137922,0.488753688 C5.76770343,0.0388333657 7.08827838,-0.113500645 8.50342442,0.0863375259 Z"
                                    id="ear"
                                    stroke="#000000"
                                    stroke-width="2.5"
                                    fill="#FFFFFF"
                                    fill-rule="nonzero"
                                  ></path>
                                  <line
                                    x1="6.37952543"
                                    y1="5.0274423"
                                    x2="6.31931734"
                                    y2="9.34663542"
                                    id="Path-3"
                                    stroke="#D8D8D8"
                                    stroke-width="3.25510204"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    transform="translate(6.349421, 7.187039) rotate(8.000000) translate(-6.349421, -7.187039) "
                                  ></line>
                                </g>
                                <g
                                  id="right-ear"
                                  transform="translate(84.336735, 27.816327)"
                                >
                                  <path
                                    d="M6.2618911,0.0841025481 C4.85094235,0.278035669 3.62831666,0.773308529 2.64727023,1.50080271 C1.6662238,2.22829697 0.926756924,3.18801232 0.482125803,4.31083077 C0.0374943827,5.43364922 -0.112301284,6.71957077 0.0859944004,8.09947658 L0.33833548,9.85547156 C0.536631465,11.2353777 1.04304614,12.4311036 1.78690638,13.3905656 C2.53076662,14.3500274 3.51207212,15.073225 4.66015005,15.5080737 C5.80822797,15.9429225 7.12307803,16.0894225 8.53402708,15.8954892 C9.94497612,15.7015561 11.1676018,15.2062832 12.1486482,14.4787892 C13.1296944,13.7512949 13.8691612,12.7915795 14.3137927,11.6687611 C14.7584241,10.5459426 14.9082198,9.26002107 14.7099238,7.88011526 L14.457583,6.12412028 C14.259287,4.74421417 13.7528723,3.54848824 13.0090121,2.5890262 C12.2651519,1.62956451 11.2838461,0.906367009 10.1357681,0.471518224 C8.9876902,0.0366694379 7.67284015,-0.109830602 6.2618911,0.0841025481 Z"
                                    id="Rectangle"
                                    stroke="#000000"
                                    stroke-width="2.5"
                                    fill="#FFFFFF"
                                    fill-rule="nonzero"
                                  ></path>
                                  <line
                                    x1="8.46951223"
                                    y1="5.02744227"
                                    x2="8.40930409"
                                    y2="9.34663539"
                                    id="Path-3"
                                    stroke="#D8D8D8"
                                    stroke-width="3.25510204"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    transform="translate(8.439408, 7.187039) scale(-1, 1) rotate(8.000000) translate(-8.439408, -7.187039) "
                                  ></line>
                                </g>
                                <g
                                  id="logo"
                                  transform="translate(32.255102, 58.591837)"
                                  fill-rule="nonzero"
                                >
                                  <path
                                    d="M25.744898,0 C11.5257474,0 0,11.526868 0,25.744898 C0,39.962928 11.5268702,51.4897959 25.744898,51.4897959 C39.9629259,51.4897959 51.4897959,39.962928 51.4897959,25.744898 C51.4897959,11.526868 39.9646053,0 25.744898,0 Z"
                                    id="Path"
                                    fill="#07A6B3"
                                  ></path>
                                  <path
                                    d="M25.744898,0 C11.5257474,0 0,11.526868 0,25.744898 C0,39.962928 11.5268702,51.4897959 25.744898,51.4897959 C39.9629259,51.4897959 51.4897959,39.962928 51.4897959,25.744898 C51.4897959,11.526868 39.9646053,0 25.744898,0 Z"
                                    id="Path"
                                    stroke="#000000"
                                    stroke-width="2.5"
                                  ></path>
                                  <path
                                    d="M10.5747318,19.933241 L17.5712648,22.9090435 C17.54866,23.0066523 17.5198385,23.1347351 17.4876233,23.278616 C17.2782827,23.9600935 17.155078,24.6650236 17.1208711,25.3770231 C17.1057594,25.5181127 17.1004708,25.6600752 17.1050536,25.8018938 L17.1112704,25.8995026 L17.1112704,25.8995026 C17.1426831,28.5013969 18.3546872,30.9485523 20.4064807,32.5528721 C22.4582839,34.1571827 25.1282665,34.7453775 27.6656853,34.1520604 L31.6428772,40.8952791 C29.7702781,41.6410616 27.77227,42.0229311 25.7562013,42.0203956 C16.987505,42.0203956 9.46938776,34.9228115 9.46938776,26.1652602 C9.46938776,24.1091733 9.8609994,21.7359824 10.5747318,19.933241 Z M25.7578933,9.46940335 C34.5265898,9.46940335 42.0204082,16.6984625 42.0204082,25.4537595 C42.0188219,27.6680417 41.7505206,29.8773667 40.9528869,31.8041756 L40.7853878,32.1843878 L23.0256268,24.6711529 L34.4175299,24.6215044 C34.0083913,20.4957866 29.7616442,17.1419515 25.7567718,17.1419515 C24.8485591,17.1411745 23.9465535,17.2914341 23.087785,17.5865722 L19.1462021,10.9048581 C21.2206294,9.95587716 23.4761075,9.46620246 25.7578933,9.46940335 Z"
                                    id="Combined-Shape"
                                    fill="#FFFFFF"
                                  ></path>
                                </g>
                                <g
                                  id="hand"
                                  transform="translate(37.383915, 95.943703) rotate(-1.000000) translate(-37.383915, -95.943703) translate(27.883915, 84.943703)"
                                  fill="#FFFFFF"
                                  fill-rule="nonzero"
                                  stroke="#000000"
                                  stroke-width="2.5"
                                >
                                  <path
                                    d="M2.49642257,16.0564812 L0.670687359,10.6382004 C-0.719178706,6.51345957 1.54691405,2.05927254 5.73214719,0.689495241 L5.82626625,0.659328139 L5.82626625,0.659328139 C10.0961729,-0.680484311 14.6654895,1.61250088 16.0777701,5.80376189 L17.9035053,11.2220427 C19.2933714,15.3467835 17.0272786,19.8009706 12.8420455,21.1707479 L12.7479264,21.200915 L12.7479264,21.200915 C8.47801977,22.5407274 3.90870315,20.2477422 2.49642257,16.0564812 Z"
                                    id="Rectangle-Copy-3"
                                  ></path>
                                  <line
                                    x1="12.1092443"
                                    y1="9.54323997"
                                    x2="12.1513308"
                                    y2="3.32969571"
                                    id="Line-Copy-2"
                                    stroke-linecap="round"
                                    transform="translate(12.130288, 6.436468) rotate(-108.000000) translate(-12.130288, -6.436468) "
                                  ></line>
                                  <line
                                    x1="14.307877"
                                    y1="14.6120534"
                                    x2="14.2684898"
                                    y2="8.39789246"
                                    id="Line-Copy-3"
                                    stroke-linecap="round"
                                    transform="translate(14.288183, 11.504973) rotate(-108.000000) translate(-14.288183, -11.504973) "
                                  ></line>
                                </g>
                                <g
                                  id="hand"
                                  transform="translate(78.680251, 95.943703) scale(-1, 1) rotate(-1.000000) translate(-78.680251, -95.943703) translate(69.180251, 84.943703)"
                                  fill="#FFFFFF"
                                  fill-rule="nonzero"
                                  stroke="#000000"
                                  stroke-width="2.5"
                                >
                                  <path
                                    d="M2.49642257,16.0564812 L0.670687359,10.6382004 C-0.719178706,6.51345957 1.54691405,2.05927254 5.73214719,0.689495241 L5.82626625,0.659328139 L5.82626625,0.659328139 C10.0961729,-0.680484311 14.6654895,1.61250088 16.0777701,5.80376189 L17.9035053,11.2220427 C19.2933714,15.3467835 17.0272786,19.8009706 12.8420455,21.1707479 L12.7479264,21.200915 L12.7479264,21.200915 C8.47801977,22.5407274 3.90870315,20.2477422 2.49642257,16.0564812 Z"
                                    id="Rectangle-Copy-3"
                                  ></path>
                                  <line
                                    x1="12.1092443"
                                    y1="9.54323997"
                                    x2="12.1513308"
                                    y2="3.32969571"
                                    id="Line-Copy-2"
                                    stroke-linecap="round"
                                    transform="translate(12.130288, 6.436468) rotate(-108.000000) translate(-12.130288, -6.436468) "
                                  ></line>
                                  <line
                                    x1="14.307877"
                                    y1="14.6120534"
                                    x2="14.2684898"
                                    y2="8.39789246"
                                    id="Line-Copy-3"
                                    stroke-linecap="round"
                                    transform="translate(14.288183, 11.504973) rotate(-108.000000) translate(-14.288183, -11.504973) "
                                  ></line>
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                    </svg>
                  </span> */}
              </div>

              {/* <button
                  type="button"
                  onClick={() => openRezerPay()}
                  className="mb-2 btn rounded-pill btn-black btn-outline-block flex-grow-1 "
                >
                  Pay in Crypto
                </button> */}
              {/* <span
                  className="d-none d-md-flex"
                  style={{
                    border: "1px solid #5c5c5c",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "40px",
                    height: "40px",
                    borderRadius: "50%",
                    margin: "15px auto 0",
                  }}
                >
                  OR
                </span> */}
              {/* <button
                  type="button"
                  id="nftButton"
                  value="nftButton"
                  onClick={(ev) => openRezerPay(ev)}
                  className="btn rounded-pill btn-black btn-block flex-grow-1 my-3"
                >
                  Pay in &#8377;
                </button>  */}
              <button
                className="btn btn-outline-black flex-grow-1"
                onClick={handleCloseUserSelectMethodModal}
              >
                Cancel
              </button>
              <div className="d-flex flex-wrap mb-2 w-100 px-0 flex-column">
                {/* <label className="ps-0 fw-bold"> Pay in Crypto</label> */}

                <span className="pt-2 d-flex d-md-none font-weight-bold">
                  Please use the desktop version for payment in crypto wallet.
                </span>
                {/* <div className="my-3 justify-content-between d-none d-md-flex">
                  <span
                    className="me-3 me-md-5"
                    title="MetaMask"
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      connectWallet("MetaMask", selectedLivestream[0])
                    }
                  >
                    <svg
                      width="50"
                      height="50"
                      viewBox="0 0 203 210"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M191.545 145L179.396 186L155.545 179.489L191.545 145Z"
                        fill="#E4761B"
                        stroke="#E4761B"
                      />
                      <path
                        d="M155.545 179L178.549 147.988L191.545 145L155.545 179Z"
                        fill="#E4761B"
                        stroke="#E4761B"
                      />
                      <path
                        d="M173.545 113.971L191.545 144.96L178.459 148L173.545 113.971ZM173.545 113.971L185.127 106L191.545 144.96L173.545 113.971Z"
                        fill="#F6851B"
                        stroke="#F6851B"
                      />
                      <path
                        d="M158.545 91.0971L192.545 76.9995L190.855 84.7221L158.545 91.0971ZM189.78 93.9995L158.545 91.0971L190.855 84.7221L189.78 93.9995Z"
                        fill="#763D16"
                        stroke="#763D16"
                      />
                      <path
                        d="M188.922 93.3432L184.689 106L158.545 90.5528L188.922 93.3432ZM195.545 80.1387L189.968 84.4239L191.611 76.9995L195.545 80.1387ZM188.922 93.3432L189.968 84.4239L194.4 88.1112L188.922 93.3432Z"
                        fill="#763D16"
                        stroke="#763D16"
                      />
                      <path
                        d="M130.545 170L138.603 172.373L155.545 179L130.545 170Z"
                        fill="#E2761B"
                        stroke="#E2761B"
                      />
                      <path
                        d="M185.524 106L189.839 93.7092L193.545 96.4189L185.524 106ZM185.524 106L150.545 95.0156L158.871 90.9995L185.524 106Z"
                        fill="#763D16"
                        stroke="#763D16"
                      />
                      <path
                        d="M167.545 51.9995L159.045 90.8591L150.545 94.9995L167.545 51.9995Z"
                        fill="#763D16"
                        stroke="#763D16"
                      />
                      <path
                        d="M192.545 77.3821L158.545 90.9995L166.943 51.9995L192.545 77.3821Z"
                        fill="#763D16"
                        stroke="#763D16"
                      />
                      <path
                        d="M167.545 51.9518L201.545 47.9995L192.076 76.9995L167.545 51.9518Z"
                        fill="#763D16"
                        stroke="#763D16"
                      />
                      <path
                        d="M185.545 106.144L173.811 114L150.545 94.9995L185.545 106.144Z"
                        fill="#F6851B"
                        stroke="#F6851B"
                      />
                      <path
                        d="M200.073 19.9995L201.545 48.0058L167.545 51.9995L200.073 19.9995Z"
                        fill="#763D16"
                        stroke="#763D16"
                      />
                      <path
                        d="M200.545 19.9995L131.443 69.9995L130.545 36.0869L200.545 19.9995Z"
                        fill="#E2761B"
                        stroke="#E2761B"
                      />
                      <path
                        d="M79.5449 31.9995L130.652 36.0727L131.545 69.9995L79.5449 31.9995Z"
                        fill="#F6851B"
                        stroke="#F6851B"
                      />
                      <path
                        d="M151.19 94.9995L131.545 70.257L167.545 51.9995L151.19 94.9995Z"
                        fill="#763D16"
                        stroke="#763D16"
                      />
                      <path
                        d="M150.752 94.9995L173.545 114.434L141.545 118L150.752 94.9995Z"
                        fill="#E4761B"
                        stroke="#E4761B"
                      />
                      <path
                        d="M141.624 118L131.545 69.9995L150.545 94.9555L141.624 118Z"
                        fill="#E4761B"
                        stroke="#E4761B"
                      />
                      <path
                        d="M167.515 51.8267L131.545 69.9995L200.545 19.9995L167.515 51.8267Z"
                        fill="#763D16"
                        stroke="#763D16"
                      />
                      <path
                        d="M79.6438 179.16L96.5449 196L73.5449 176L79.6438 179.16Z"
                        fill="#C0AD9E"
                        stroke="#C0AD9E"
                      />
                      <path
                        d="M155.545 179L163.261 150.154L178.545 148L155.545 179Z"
                        fill="#CD6116"
                        stroke="#CD6116"
                      />
                      <path
                        d="M13.5449 96.9995L42.5449 67.9995L17.2852 93.539L13.5449 96.9995Z"
                        fill="#E2761B"
                        stroke="#E2761B"
                      />
                      <path
                        d="M178.545 147.813L163.333 150L173.689 113.879L178.545 147.813ZM131.473 70.0079L104.171 69.1136L79.5449 31.9995L131.473 70.0079Z"
                        fill="#F6851B"
                        stroke="#F6851B"
                      />
                      <path
                        d="M173.545 114L163.372 150L162.545 132.42L173.545 114Z"
                        fill="#E4751F"
                        stroke="#E4751F"
                      />
                      <path
                        d="M141.545 117.626L173.545 114L162.298 133L141.545 117.626Z"
                        fill="#CD6116"
                        stroke="#CD6116"
                      />
                      <path
                        d="M104.545 68.9995L131.37 69.9069L141.545 118L104.545 68.9995Z"
                        fill="#F6851B"
                        stroke="#F6851B"
                      />
                      <path
                        d="M104.545 69.3722L30.4854 0.999512L79.792 32.2542L104.545 69.3722ZM79.7422 189.124L15.3945 209L2.54492 160.055L79.7422 189.124Z"
                        fill="#E4761B"
                        stroke="#E4761B"
                      />
                      <path
                        d="M23.5449 109L48.0265 90.9995L68.5449 95.4643L23.5449 109Z"
                        fill="#763D16"
                        stroke="#763D16"
                      />
                      <path
                        d="M68.5449 94.9995L47.5449 90.3399L58.7894 45.9995L68.5449 94.9995Z"
                        fill="#763D16"
                        stroke="#763D16"
                      />
                      <path
                        d="M17.5449 94.1013L47.5449 90.9995L23.4263 109L17.5449 94.1013Z"
                        fill="#763D16"
                        stroke="#763D16"
                      />
                      <path
                        d="M162.545 133L149.854 126.671L141.545 118L162.545 133Z"
                        fill="#CD6116"
                        stroke="#CD6116"
                      />
                      <path
                        d="M17.6618 93.9995L15.5449 82.9995L47.5449 90.8013L17.6618 93.9995Z"
                        fill="#763D16"
                        stroke="#763D16"
                      />
                      <path
                        d="M147.545 141L150.111 127L162.545 133.216L147.545 141Z"
                        fill="#233447"
                        stroke="#233447"
                      />
                      <path
                        d="M163.545 150L147.545 140.614L162.698 133L163.545 150Z"
                        fill="#F6851B"
                        stroke="#F6851B"
                      />
                      <path
                        d="M47.7523 90.3399L15.1992 82.443L12.5449 73.0746L47.7523 90.3399ZM58.7202 45.9995L47.7523 90.3399L12.5449 73.0746L58.7202 45.9995ZM58.7202 45.9995L104.545 69.5921L68.2357 94.9995L58.7202 45.9995Z"
                        fill="#763D16"
                        stroke="#763D16"
                      />
                      <path
                        d="M68.5449 94.7158L104.518 68.9995L120.545 118L68.5449 94.7158ZM120.545 118L70.8274 116.957L68.5449 94.7158L120.545 118Z"
                        fill="#E4761B"
                        stroke="#E4761B"
                      />
                      <path
                        d="M23.5449 109.014L67.9807 94.7158L70.2594 116.957L23.5449 109.014ZM141.545 117.255L119.897 118L103.896 68.9995L141.545 117.255Z"
                        fill="#F6851B"
                        stroke="#F6851B"
                      />
                      <path
                        d="M149.545 126.7L147.024 141L141.545 118L149.545 126.7Z"
                        fill="#CD6116"
                        stroke="#CD6116"
                      />
                      <path
                        d="M30.5449 0.999512L104.545 68.9995L59.0103 45.2292L30.5449 0.999512Z"
                        fill="#763D16"
                        stroke="#763D16"
                      />
                      <path
                        d="M2.54492 160.53L64.8404 158L79.5449 189L2.54492 160.53Z"
                        fill="#E4761B"
                        stroke="#E4761B"
                      />
                      <path
                        d="M79.441 189L64.5449 157.484L95.5449 156L79.441 189Z"
                        fill="#CD6116"
                        stroke="#CD6116"
                      />
                      <path
                        d="M147.783 140.325L163.744 150.133L172.545 171L147.783 140.325ZM70.6145 117.006L2.54492 159.991L23.7266 109L70.6145 117.006ZM64.8965 157.389L2.54492 159.991L70.6145 117.006L64.8965 157.389ZM142.165 117.306L146.242 132.218L126.552 133.369L142.165 117.306ZM126.552 133.369L120.436 118.057L142.165 117.306L126.552 133.369Z"
                        fill="#F6851B"
                        stroke="#F6851B"
                      />
                      <path
                        d="M96.2224 195.7L79.5449 189L137.545 199L96.2224 195.7Z"
                        fill="#C0AD9E"
                        stroke="#C0AD9E"
                      />
                      <path
                        d="M23.5449 109L13.5449 97.3591L17.347 93.9995L23.5449 109Z"
                        fill="#763D16"
                        stroke="#763D16"
                      />
                      <path
                        d="M144.545 191.588L137.92 199L79.5449 189L144.545 191.588Z"
                        fill="#D7C1B3"
                        stroke="#D7C1B3"
                      />
                      <path
                        d="M147.545 173.811L79.5449 189L95.4514 156L147.545 173.811Z"
                        fill="#E4761B"
                        stroke="#E4761B"
                      />
                      <path
                        d="M79.5449 189.435L147.545 174L144.413 192L79.5449 189.435Z"
                        fill="#D7C1B3"
                        stroke="#D7C1B3"
                      />
                      <path
                        d="M12.7786 73.2228L10.5449 34.9995L58.5449 45.7855L12.7786 73.2228ZM17.5439 93.9995L10.942 87.2893L15.4095 82.7164L17.5439 93.9995Z"
                        fill="#763D16"
                        stroke="#763D16"
                      />
                      <path
                        d="M108.545 128.227L120.545 118L118.831 143L108.545 128.227Z"
                        fill="#CD6116"
                        stroke="#CD6116"
                      />
                      <path
                        d="M120.545 118L109.141 128.25L92.5449 137L120.545 118Z"
                        fill="#CD6116"
                        stroke="#CD6116"
                      />
                      <path
                        d="M172.545 171L169.483 168.797L147.545 141L172.545 171Z"
                        fill="#F6851B"
                        stroke="#F6851B"
                      />
                      <path
                        d="M93.0998 137L70.5449 118L120.545 119.028L93.0998 137Z"
                        fill="#CD6116"
                        stroke="#CD6116"
                      />
                      <path
                        d="M118.545 143L120.197 118L126.545 133.805L118.545 143Z"
                        fill="#E4751F"
                        stroke="#E4751F"
                      />
                      <path
                        d="M8.54492 76.9786L12.7763 72.9995L15.5449 82.9995L8.54492 76.9786Z"
                        fill="#763D16"
                        stroke="#763D16"
                      />
                      <path
                        d="M118.545 143L92.5449 136.863L108.908 128L118.545 143Z"
                        fill="#233447"
                        stroke="#233447"
                      />
                      <path
                        d="M58.5449 45.9995L10.5449 35.0889L30.152 0.999512L58.5449 45.9995Z"
                        fill="#763D16"
                        stroke="#763D16"
                      />
                      <path
                        d="M137.826 199.023L140.545 206L96.5449 196L137.826 199.023Z"
                        fill="#C0AD9E"
                        stroke="#C0AD9E"
                      />
                      <path
                        d="M95.0044 156L92.5449 137L118.545 142.714L95.0044 156Z"
                        fill="#F6851B"
                        stroke="#F6851B"
                      />
                      <path
                        d="M70.5449 118L93.0998 136.975L95.5449 156L70.5449 118Z"
                        fill="#E4751F"
                        stroke="#E4751F"
                      />
                      <path
                        d="M126.427 134.128L146.189 132.994L169.545 169L126.427 134.128ZM70.284 118L95.2864 156.323L64.5449 157.803L70.284 118Z"
                        fill="#F6851B"
                        stroke="#F6851B"
                      />
                      <path
                        d="M126.211 134L150.545 171L118.545 142.793L126.211 134Z"
                        fill="#E4751F"
                        stroke="#E4751F"
                      />
                      <path
                        d="M118.545 143L150.545 171.007L147.182 174L118.545 143Z"
                        fill="#F6851B"
                        stroke="#F6851B"
                      />
                      <path
                        d="M147.663 174L95.5449 156.222L118.869 142.79L147.663 174ZM169.545 168.913L151.045 170.987L126.577 134L169.545 168.913Z"
                        fill="#F6851B"
                        stroke="#F6851B"
                      />
                      <path
                        d="M174.545 186L166.48 201.851L140.317 206L174.545 186ZM140.317 206L137.545 198.592L144.249 191.382L140.317 206Z"
                        fill="#C0AD9E"
                        stroke="#C0AD9E"
                      />
                      <path
                        d="M144.451 191.382L149.358 189.456L140.545 206L144.451 191.382ZM140.545 206L149.358 189.456L174.545 186L140.545 206Z"
                        fill="#C0AD9E"
                        stroke="#C0AD9E"
                      />
                      <path
                        d="M169.296 169L175.545 172.75L156.545 175L169.296 169Z"
                        fill="#161616"
                        stroke="#161616"
                      />
                      <path
                        d="M156.447 174.795L150.545 171.028L168.691 169L156.447 174.795ZM154.35 177.644L176.545 174.988L173.472 186L154.35 177.644Z"
                        fill="#161616"
                        stroke="#161616"
                      />
                      <path
                        d="M173.509 186.536L149.268 190.045L154.617 177.866L173.509 186.536ZM149.268 190.045L144.545 192L147.581 174.057L149.268 190.045ZM147.581 174.057L150.858 171L156.69 174.909L147.581 174.057ZM174.714 172.653L176.545 175.109L154.617 177.866L174.714 172.653Z"
                        fill="#161616"
                        stroke="#161616"
                      />
                      <path
                        d="M154.806 177.394L156.945 174.334L175.545 172L154.806 177.394ZM147.545 173.452L154.806 177.394L149.286 190L147.545 173.452Z"
                        fill="#161616"
                        stroke="#161616"
                      />
                      <path
                        d="M156.545 174.894L154.497 178L147.545 174L156.545 174.894Z"
                        fill="#161616"
                        stroke="#161616"
                      />
                    </svg>
                  </span>
                  <span
                    className="me-3 me-md-5"
                    title="Coinbase"
                    style={{ cursor: "pointer" }}
                    onClick={() => connectWallet("Coinbase")}
                  >
                    <svg
                      width="50"
                      height="50"
                      viewBox="0 0 383 383"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0)">
                        <g filter="url(#filter0_dd)">
                          <path
                            d="M0.998047 0.572266H382.78V382.354H0.998047V0.572266Z"
                            fill="url(#paint0_linear)"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M59.1074 191.572C59.1074 264.966 118.605 324.463 191.998 324.463C265.392 324.463 324.889 264.966 324.889 191.572C324.889 118.179 265.392 58.6816 191.998 58.6816C118.605 58.6816 59.1074 118.179 59.1074 191.572ZM158.037 148.752C153.144 148.752 149.178 152.718 149.178 157.611V225.533C149.178 230.426 153.144 234.393 158.037 234.393H225.959C230.852 234.393 234.818 230.426 234.818 225.533V157.611C234.818 152.718 230.852 148.752 225.959 148.752H158.037Z"
                            fill="white"
                          />
                        </g>
                      </g>
                      <defs>
                        <filter
                          id="filter0_dd"
                          x="-23.002"
                          y="-7.42773"
                          width="429.782"
                          height="429.782"
                          filterUnits="userSpaceOnUse"
                          color-interpolation-filters="sRGB"
                        >
                          <feFlood
                            flood-opacity="0"
                            result="BackgroundImageFix"
                          />
                          <feColorMatrix
                            in="SourceAlpha"
                            type="matrix"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                          />
                          <feOffset dy="16" />
                          <feGaussianBlur stdDeviation="12" />
                          <feColorMatrix
                            type="matrix"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0"
                          />
                          <feBlend
                            mode="normal"
                            in2="BackgroundImageFix"
                            result="effect1_dropShadow"
                          />
                          <feColorMatrix
                            in="SourceAlpha"
                            type="matrix"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                          />
                          <feOffset />
                          <feGaussianBlur stdDeviation="4" />
                          <feColorMatrix
                            type="matrix"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.04 0"
                          />
                          <feBlend
                            mode="normal"
                            in2="effect1_dropShadow"
                            result="effect2_dropShadow"
                          />
                          <feBlend
                            mode="normal"
                            in="SourceGraphic"
                            in2="effect2_dropShadow"
                            result="shape"
                          />
                        </filter>
                        <linearGradient
                          id="paint0_linear"
                          x1="191.889"
                          y1="0.572266"
                          x2="191.889"
                          y2="382.354"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stop-color="#2E66F8" />
                          <stop offset="1" stop-color="#124ADB" />
                        </linearGradient>
                        <clipPath id="clip0">
                          <rect width="383" height="383" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </span>
                  <span
                    style={{ cursor: "pointer" }}
                    className="me-3 me-md-5"
                    title="Walletconnect"
                    onClick={() => connectWallet("Walletconnect")}
                  >
                    <svg
                      width="50"
                      height="50"
                      viewBox="0 0 92 91"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <ellipse
                        cx="46.0003"
                        cy="45.2335"
                        rx="45.639"
                        ry="45.0778"
                        fill="#3C99FC"
                        fill-opacity="0.1"
                      />
                      <g clip-path="url(#clip0)">
                        <path
                          d="M31.011 35.427C39.4889 27.1436 53.2344 27.1436 61.7123 35.427L62.7327 36.4239C63.1566 36.8381 63.1566 37.5096 62.7327 37.9237L59.2423 41.334C59.0303 41.5411 58.6867 41.5411 58.4748 41.334L57.0707 39.9621C51.1562 34.1834 41.5671 34.1834 35.6526 39.9621L34.1489 41.4313C33.937 41.6384 33.5934 41.6384 33.3814 41.4313L29.8911 38.021C29.4672 37.6069 29.4672 36.9354 29.8911 36.5212L31.011 35.427ZM68.9307 42.4798L72.0371 45.5149C72.461 45.9291 72.461 46.6006 72.0371 47.0147L58.0301 60.7005C57.6062 61.1147 56.9189 61.1147 56.495 60.7006C56.495 60.7005 56.495 60.7005 56.495 60.7005L46.5536 50.9873C46.4476 50.8838 46.2758 50.8838 46.1698 50.9873C46.1698 50.9873 46.1698 50.9873 46.1698 50.9873L36.2286 60.7005C35.8048 61.1147 35.1175 61.1147 34.6936 60.7006C34.6936 60.7006 34.6936 60.7006 34.6936 60.7005L20.6861 47.0145C20.2622 46.6004 20.2622 45.9289 20.6861 45.5147L23.7925 42.4796C24.2164 42.0654 24.9037 42.0654 25.3276 42.4796L35.2691 52.1929C35.3751 52.2965 35.5469 52.2965 35.6529 52.1929C35.6529 52.1929 35.6529 52.1929 35.6529 52.1929L45.5939 42.4796C46.0178 42.0654 46.7051 42.0654 47.129 42.4795C47.129 42.4796 47.129 42.4796 47.129 42.4796L57.0705 52.1929C57.1765 52.2965 57.3483 52.2965 57.4543 52.1929L67.3957 42.4798C67.8195 42.0656 68.5068 42.0656 68.9307 42.4798Z"
                          fill="#3C99FC"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0">
                          <rect
                            width="52"
                            height="32"
                            fill="white"
                            transform="translate(20.3613 29.1556)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  </span>
                  <span
                    style={{ cursor: "pointer" }}
                    title="MEW"
                    onClick={() => connectWallet("MEW")}
                  >
                    <svg
                      width="50"
                      height="50"
                      viewBox="0 0 120 120"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g
                        id="Buttons"
                        stroke="none"
                        stroke-width="1"
                        fill="none"
                        fill-rule="evenodd"
                      >
                        <g
                          id="04.-Guide---App-icon"
                          transform="translate(-54.000000, -188.000000)"
                        >
                          <g
                            id="App-Icon/mew_wallet_appicon"
                            transform="translate(54.000000, 188.000000)"
                          >
                            <path
                              d="M111.821972,8.17802805 C104.415006,0.771062258 96.991511,0.513035658 84.5656411,0.50054281 L35.4338562,0.500543063 C23.008489,0.513035658 15.5849938,0.771062258 8.17802805,8.17802805 C0.771062212,15.5849938 0.513035654,23.008489 0.50054281,35.4343589 L0.500543063,84.5661438 C0.513035654,96.991511 0.771062212,104.415006 8.17802805,111.821972 C15.9079529,119.551897 23.656924,119.5 37.0828183,119.5 L82.9171817,119.5 C96.343076,119.5 104.092047,119.551897 111.821972,111.821972 C119.551897,104.092047 119.5,96.343076 119.5,82.9171817 L119.5,37.0828183 C119.5,23.656924 119.551897,15.9079529 111.821972,8.17802805 Z"
                              id="iOS-icon-mask"
                              stroke="#DADCE0"
                              fill="#FFFFFF"
                            ></path>
                            <g id="Elements/App-Icon">
                              <g
                                id="icon"
                                transform="translate(2.000000, 2.000000)"
                              >
                                <path
                                  d="M78.2902062,106.530612 C100.188166,107.566326 102.396167,88.4204441 95.9815645,77.2712546 C89.7672785,66.4702342 80.4458501,62.7712544 65.3703851,64.1900465 C50.29492,65.6088385 65.4352032,90.169341 75.777958,88.3681934 C80.4702531,87.5510499 76.3984486,106.441137 78.2902062,106.530612 Z"
                                  id="right-arm"
                                  stroke="#000000"
                                  stroke-width="2.5"
                                  fill="#FFFFFF"
                                  fill-rule="nonzero"
                                ></path>
                                <path
                                  d="M37.7086826,106.530612 C15.8107234,107.566326 13.6027223,88.4204441 20.0173244,77.2712546 C26.2316101,66.4702341 35.5530388,62.7712544 50.6285038,64.1900464 C65.7039688,65.6088385 50.5636857,90.169341 40.2209306,88.3681933 C35.5286355,87.5510498 39.6004401,106.441137 37.7086826,106.530612 Z"
                                  id="left-arm"
                                  stroke="#000000"
                                  stroke-width="2.5"
                                  fill="#FFFFFF"
                                  fill-rule="nonzero"
                                ></path>
                                <path
                                  d="M60.7410433,9.46938776 C68.2263324,9.46938776 73.0871763,10.6723916 77.3062878,12.9313812 C81.5253989,15.1903709 84.8365819,18.5053464 87.0929873,22.7292899 C89.3251303,26.9078146 90.5250571,31.7150649 90.5510204,39.072678 L90.5510204,43.5436364 C90.5510204,51.0374983 89.349393,55.9039096 87.0929873,60.1278531 C84.8365819,64.3517963 81.5253989,67.6667718 77.3062878,69.9257617 C73.0871763,72.1847514 68.2263324,73.3877551 60.7410433,73.3877551 L55.2589567,73.3877551 C47.7736676,73.3877551 42.9128237,72.1847514 38.6937122,69.9257617 C34.4746011,67.6667718 31.1634181,64.3517963 28.9070125,60.1278531 C26.6506072,55.9039096 25.4489796,51.0374983 25.4489796,43.5436364 L25.4489796,39.3135064 C25.4489796,31.8196446 26.6506072,26.9532333 28.9070125,22.7292899 C31.1634181,18.5053464 34.4746011,15.1903709 38.6937122,12.9313812 C42.8674567,10.6966818 47.6692075,9.4953809 55.0184035,9.46938776 L60.7410433,9.46938776 Z"
                                  id="helmet"
                                  stroke="#000000"
                                  stroke-width="2.5"
                                  fill="#FFFFFF"
                                  fill-rule="nonzero"
                                ></path>
                                <path
                                  d="M54.0368739,27.8163265 L61.9631261,27.8163265 C65.9787611,27.8163265 69.0898725,28.5567899 71.7010112,29.9472255 C74.3121499,31.3376607 76.361387,33.3780684 77.7578394,35.9779566 C79.1542915,38.5778448 79.8979592,41.6755514 79.8979592,45.6738844 L79.8979592,46.6526462 C79.8979592,50.6509792 79.1542915,53.7486858 77.7578394,56.348574 C76.361387,58.9484622 74.3121499,60.9888699 71.7010112,62.3793051 C69.0898725,63.7697406 65.9787611,64.5102041 61.9631261,64.5102041 L54.0368739,64.5102041 C50.0212389,64.5102041 46.9101275,63.7697406 44.2989888,62.3793051 C41.6878501,60.9888699 39.638613,58.9484622 38.2421606,56.348574 C36.8457085,53.7486858 36.1020408,50.6509792 36.1020408,46.6526462 L36.1020408,45.6738844 C36.1020408,41.6755514 36.8457085,38.5778448 38.2421606,35.9779566 C39.638613,33.3780684 41.6878501,31.3376607 44.2989888,29.9472255 C46.9101275,28.5567899 50.0212389,27.8163265 54.0368739,27.8163265 Z"
                                  id="screen"
                                  stroke="#000000"
                                  stroke-width="2.5"
                                  fill="#FFFFFF"
                                  fill-rule="nonzero"
                                ></path>
                                <circle
                                  id="left-eye"
                                  fill="#000000"
                                  fill-rule="nonzero"
                                  cx="44.0918367"
                                  cy="46.4591837"
                                  r="4.73469388"
                                ></circle>
                                <circle
                                  id="right-eye"
                                  fill="#000000"
                                  fill-rule="nonzero"
                                  cx="71.9081633"
                                  cy="46.4591837"
                                  r="4.73469388"
                                ></circle>
                                <path
                                  d="M52.9693878,45.5714286 C52.9693878,48.1863278 55.2216695,50.3061224 58,50.3061224 C60.7783305,50.3061224 63.0306122,48.1863278 63.0306122,45.5714286"
                                  id="month"
                                  stroke="#000000"
                                  stroke-width="2.5"
                                  stroke-linecap="round"
                                ></path>
                                <g
                                  id="left-ear"
                                  transform="translate(16.867347, 27.816327)"
                                >
                                  <path
                                    d="M8.50342442,0.0863375259 C9.91857046,0.286175668 11.1461997,0.798351267 12.1319865,1.5504265 C13.1177733,2.30250175 13.8617176,3.29447664 14.309494,4.45391321 C14.7572701,5.61334978 14.9088781,6.94024834 14.7099923,8.36217071 L14.5288878,9.65696686 C14.3300019,11.0788892 13.8202671,12.3123968 13.071776,13.302904 C12.3232849,14.2934111 11.3360374,15.0409178 10.1821262,15.4908383 C9.02821495,15.9407585 7.70764003,16.0930925 6.29249399,15.8932544 C4.87734794,15.6934163 3.64971867,15.1812405 2.66393187,14.4291654 C1.67814507,13.6770901 0.93420071,12.6851152 0.486424508,11.5256786 C0.0386483057,10.366242 -0.112959744,9.0393435 0.0859260681,7.61742112 L0.267030596,6.32262498 C0.465916407,4.9007026 0.975651203,3.667195 1.72414235,2.67668787 C2.4726335,1.6861807 3.459881,0.93867404 4.6137922,0.488753688 C5.76770343,0.0388333657 7.08827838,-0.113500645 8.50342442,0.0863375259 Z"
                                    id="ear"
                                    stroke="#000000"
                                    stroke-width="2.5"
                                    fill="#FFFFFF"
                                    fill-rule="nonzero"
                                  ></path>
                                  <line
                                    x1="6.37952543"
                                    y1="5.0274423"
                                    x2="6.31931734"
                                    y2="9.34663542"
                                    id="Path-3"
                                    stroke="#D8D8D8"
                                    stroke-width="3.25510204"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    transform="translate(6.349421, 7.187039) rotate(8.000000) translate(-6.349421, -7.187039) "
                                  ></line>
                                </g>
                                <g
                                  id="right-ear"
                                  transform="translate(84.336735, 27.816327)"
                                >
                                  <path
                                    d="M6.2618911,0.0841025481 C4.85094235,0.278035669 3.62831666,0.773308529 2.64727023,1.50080271 C1.6662238,2.22829697 0.926756924,3.18801232 0.482125803,4.31083077 C0.0374943827,5.43364922 -0.112301284,6.71957077 0.0859944004,8.09947658 L0.33833548,9.85547156 C0.536631465,11.2353777 1.04304614,12.4311036 1.78690638,13.3905656 C2.53076662,14.3500274 3.51207212,15.073225 4.66015005,15.5080737 C5.80822797,15.9429225 7.12307803,16.0894225 8.53402708,15.8954892 C9.94497612,15.7015561 11.1676018,15.2062832 12.1486482,14.4787892 C13.1296944,13.7512949 13.8691612,12.7915795 14.3137927,11.6687611 C14.7584241,10.5459426 14.9082198,9.26002107 14.7099238,7.88011526 L14.457583,6.12412028 C14.259287,4.74421417 13.7528723,3.54848824 13.0090121,2.5890262 C12.2651519,1.62956451 11.2838461,0.906367009 10.1357681,0.471518224 C8.9876902,0.0366694379 7.67284015,-0.109830602 6.2618911,0.0841025481 Z"
                                    id="Rectangle"
                                    stroke="#000000"
                                    stroke-width="2.5"
                                    fill="#FFFFFF"
                                    fill-rule="nonzero"
                                  ></path>
                                  <line
                                    x1="8.46951223"
                                    y1="5.02744227"
                                    x2="8.40930409"
                                    y2="9.34663539"
                                    id="Path-3"
                                    stroke="#D8D8D8"
                                    stroke-width="3.25510204"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    transform="translate(8.439408, 7.187039) scale(-1, 1) rotate(8.000000) translate(-8.439408, -7.187039) "
                                  ></line>
                                </g>
                                <g
                                  id="logo"
                                  transform="translate(32.255102, 58.591837)"
                                  fill-rule="nonzero"
                                >
                                  <path
                                    d="M25.744898,0 C11.5257474,0 0,11.526868 0,25.744898 C0,39.962928 11.5268702,51.4897959 25.744898,51.4897959 C39.9629259,51.4897959 51.4897959,39.962928 51.4897959,25.744898 C51.4897959,11.526868 39.9646053,0 25.744898,0 Z"
                                    id="Path"
                                    fill="#07A6B3"
                                  ></path>
                                  <path
                                    d="M25.744898,0 C11.5257474,0 0,11.526868 0,25.744898 C0,39.962928 11.5268702,51.4897959 25.744898,51.4897959 C39.9629259,51.4897959 51.4897959,39.962928 51.4897959,25.744898 C51.4897959,11.526868 39.9646053,0 25.744898,0 Z"
                                    id="Path"
                                    stroke="#000000"
                                    stroke-width="2.5"
                                  ></path>
                                  <path
                                    d="M10.5747318,19.933241 L17.5712648,22.9090435 C17.54866,23.0066523 17.5198385,23.1347351 17.4876233,23.278616 C17.2782827,23.9600935 17.155078,24.6650236 17.1208711,25.3770231 C17.1057594,25.5181127 17.1004708,25.6600752 17.1050536,25.8018938 L17.1112704,25.8995026 L17.1112704,25.8995026 C17.1426831,28.5013969 18.3546872,30.9485523 20.4064807,32.5528721 C22.4582839,34.1571827 25.1282665,34.7453775 27.6656853,34.1520604 L31.6428772,40.8952791 C29.7702781,41.6410616 27.77227,42.0229311 25.7562013,42.0203956 C16.987505,42.0203956 9.46938776,34.9228115 9.46938776,26.1652602 C9.46938776,24.1091733 9.8609994,21.7359824 10.5747318,19.933241 Z M25.7578933,9.46940335 C34.5265898,9.46940335 42.0204082,16.6984625 42.0204082,25.4537595 C42.0188219,27.6680417 41.7505206,29.8773667 40.9528869,31.8041756 L40.7853878,32.1843878 L23.0256268,24.6711529 L34.4175299,24.6215044 C34.0083913,20.4957866 29.7616442,17.1419515 25.7567718,17.1419515 C24.8485591,17.1411745 23.9465535,17.2914341 23.087785,17.5865722 L19.1462021,10.9048581 C21.2206294,9.95587716 23.4761075,9.46620246 25.7578933,9.46940335 Z"
                                    id="Combined-Shape"
                                    fill="#FFFFFF"
                                  ></path>
                                </g>
                                <g
                                  id="hand"
                                  transform="translate(37.383915, 95.943703) rotate(-1.000000) translate(-37.383915, -95.943703) translate(27.883915, 84.943703)"
                                  fill="#FFFFFF"
                                  fill-rule="nonzero"
                                  stroke="#000000"
                                  stroke-width="2.5"
                                >
                                  <path
                                    d="M2.49642257,16.0564812 L0.670687359,10.6382004 C-0.719178706,6.51345957 1.54691405,2.05927254 5.73214719,0.689495241 L5.82626625,0.659328139 L5.82626625,0.659328139 C10.0961729,-0.680484311 14.6654895,1.61250088 16.0777701,5.80376189 L17.9035053,11.2220427 C19.2933714,15.3467835 17.0272786,19.8009706 12.8420455,21.1707479 L12.7479264,21.200915 L12.7479264,21.200915 C8.47801977,22.5407274 3.90870315,20.2477422 2.49642257,16.0564812 Z"
                                    id="Rectangle-Copy-3"
                                  ></path>
                                  <line
                                    x1="12.1092443"
                                    y1="9.54323997"
                                    x2="12.1513308"
                                    y2="3.32969571"
                                    id="Line-Copy-2"
                                    stroke-linecap="round"
                                    transform="translate(12.130288, 6.436468) rotate(-108.000000) translate(-12.130288, -6.436468) "
                                  ></line>
                                  <line
                                    x1="14.307877"
                                    y1="14.6120534"
                                    x2="14.2684898"
                                    y2="8.39789246"
                                    id="Line-Copy-3"
                                    stroke-linecap="round"
                                    transform="translate(14.288183, 11.504973) rotate(-108.000000) translate(-14.288183, -11.504973) "
                                  ></line>
                                </g>
                                <g
                                  id="hand"
                                  transform="translate(78.680251, 95.943703) scale(-1, 1) rotate(-1.000000) translate(-78.680251, -95.943703) translate(69.180251, 84.943703)"
                                  fill="#FFFFFF"
                                  fill-rule="nonzero"
                                  stroke="#000000"
                                  stroke-width="2.5"
                                >
                                  <path
                                    d="M2.49642257,16.0564812 L0.670687359,10.6382004 C-0.719178706,6.51345957 1.54691405,2.05927254 5.73214719,0.689495241 L5.82626625,0.659328139 L5.82626625,0.659328139 C10.0961729,-0.680484311 14.6654895,1.61250088 16.0777701,5.80376189 L17.9035053,11.2220427 C19.2933714,15.3467835 17.0272786,19.8009706 12.8420455,21.1707479 L12.7479264,21.200915 L12.7479264,21.200915 C8.47801977,22.5407274 3.90870315,20.2477422 2.49642257,16.0564812 Z"
                                    id="Rectangle-Copy-3"
                                  ></path>
                                  <line
                                    x1="12.1092443"
                                    y1="9.54323997"
                                    x2="12.1513308"
                                    y2="3.32969571"
                                    id="Line-Copy-2"
                                    stroke-linecap="round"
                                    transform="translate(12.130288, 6.436468) rotate(-108.000000) translate(-12.130288, -6.436468) "
                                  ></line>
                                  <line
                                    x1="14.307877"
                                    y1="14.6120534"
                                    x2="14.2684898"
                                    y2="8.39789246"
                                    id="Line-Copy-3"
                                    stroke-linecap="round"
                                    transform="translate(14.288183, 11.504973) rotate(-108.000000) translate(-14.288183, -11.504973) "
                                  ></line>
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                    </svg>
                  </span>
                </div> */}
                {/* <button
                  type="button"
                  onClick={() => openRezerPay()}
                  className="mb-2 btn rounded-pill btn-black btn-outline-block flex-grow-1 "
                >
                  Pay in Crypto
                </button> */}
                {/* <span
                  className="d-none d-md-flex"
                  style={{
                    border: "1px solid #5c5c5c",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "40px",
                    height: "40px",
                    borderRadius: "50%",
                    margin: "15px auto 0",
                  }}
                >
                  OR
                </span> */}
                {/* <button
                  type="button"
                  id="nftButton"
                  value="nftButton"
                  onClick={(ev) => openRezerPay(ev)}
                  className="btn rounded-pill btn-black btn-block flex-grow-1 my-3"
                >
                  Pay in INR
                </button> */}
                {/* <button
                  className="btn btn-outline-black flex-grow-1"
                  onClick={handleCloseUserSelectMethodModal}
                >
                  Cancel
                </button> */}
              </div>
            </div>
            {/* <button
              className="btn btn-outline-orange flex-grow-1"
              onClick={handleCloseNFTWithTicket}
            >
              Cancel
            </button> */}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    UserReducer: state.UserReducer,
    LoginMessage: state.UserReducer.LoginMessage,
    LoginPopupReducer: state.LoginPopupReducer,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    loading: (data: any) => dispatch(Loading(data)),
  };
};

const connectedNavBar = connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateLivestream);
export { connectedNavBar as CreateLivestream };
