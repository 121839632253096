export const HOST = '';
export const WEB_STREAM_PORT = 3334;
export const NMS_STREAM_PORT = 8887;


// ============ PROD ================
export const BASE_URL = 'https://api.clanworld.ai';
export const STREAMING_HOST = 'media.clanworld.ai';
export const RAZORPAY_KEY = 'rzp_live_OtRn3lHK9qOh7I';
// export const RAZORPAY_KEY = 'rzp_test_DsjVVApo8X8BCB';
export const S3_URL = 'https://clanworldassets.s3.ap-south-1.amazonaws.com/';
export const FACEBOOK_APP_ID = "586539722116753";
export const GOOGLE_APP_ID = "603155034626-402scs6c5ag7mogjesp2esq9nkjsdkse.apps.googleusercontent.com";
export const CLOUDFRONT_URL:any = 'https://dr4k9by56dsyz.cloudfront.net/';
export const STREAMING_URL = 'https://d3meybdr6x93p8.cloudfront.net'
export const GOOGLE_ANALYTICS_ID = 'G-C2LQ9SCCKK';


// ================ Stage =================
// export const BASE_URL = 'https://apistage.irida.in';
// export const STREAMING_HOST = 'mediastage.irida.in';
// export const RAZORPAY_KEY = 'rzp_test_DsjVVApo8X8BCB';
// export const S3_URL = 'https://clubclangindowa.s3.ap-south-1.amazonaws.com/';
// export const FACEBOOK_APP_ID = "152110156924040";
// export const GOOGLE_APP_ID = "603155034626-402scs6c5ag7mogjesp2esq9nkjsdkse.apps.googleusercontent.com";
// export const CLOUDFRONT_URL = 'https://d24mce9006s6b3.cloudfront.net/';
// export const STREAMING_URL = 'https://d3meybdr6x93p8.cloudfront.net'

//============= DEV================
// export const BASE_URL = 'https://api.irida.in';
// export const STREAMING_HOST = 'mediastage.irida.in';
// export const RAZORPAY_KEY = 'rzp_test_DsjVVApo8X8BCB';
// export const S3_URL = 'https://clubclangindowa.s3.ap-south-1.amazonaws.com/';
// export const FACEBOOK_APP_ID = "152110156924040";
// export const GOOGLE_APP_ID = "603155034626-402scs6c5ag7mogjesp2esq9nkjsdkse.apps.googleusercontent.com";


// ============ LOCAL==============
// export const BASE_URL:any = 'http://localhost:7799';
// export const STREAMING_HOST:any = 'mediastage.irida.in';
// export const RAZORPAY_KEY:any = 'rzp_test_DsjVVApo8X8BCB';
// export const S3_URL:any = 'https://clanworldassets.s3.ap-south-1.amazonaws.com/';
// export const FACEBOOK_APP_ID:any = "152110156924040";
// export const GOOGLE_APP_ID:any = "603155034626-402scs6c5ag7mogjesp2esq9nkjsdkse.apps.googleusercontent.com";
// export const CLOUDFRONT_URL:any = 'https://dr4k9by56dsyz.cloudfront.net/';
// export const STREAMING_URL = 'https://d3meybdr6x93p8.cloudfront.net'
// export const GOOGLE_ANALYTICS_ID = 'UA-218252815-1';


export const reFreshChat:any = 5000;