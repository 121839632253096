import * as React from 'react';
import AppFooter from '../components/footer';
import { AppHeader } from '../components/header';
import ScrollToTop from '../components/scroll-to-top';

interface IPublicLayoutProps {
    children?:any
}

const PublicLayout: React.FunctionComponent<IPublicLayoutProps> = ( {children}) => {
  return <>
    <AppHeader />
    <ScrollToTop />
    <main className='main-content-sec'>{children}</main>
    <AppFooter />
  </>
};

export default PublicLayout;
