import React, { useEffect, useState, useRef } from "react";
import { Link, NavLink, useHistory, useLocation } from "react-router-dom";
// import { useNavigate } from "react-router-dom";

import { BsSearch } from "react-icons/bs";


import { Modal, Offcanvas } from "react-bootstrap";
import { UserService } from "../app/service/user.service";
import Logo from "../assets/img/ClanClublogo.svg";
import LogoWhite from "../assets/img/ClanClublogoWhite.svg";
import Profile from "../assets/img/dummy-img.svg";
import LoginImg from "../assets/img/profile-icon.png";
// import { BsSearch } from "react-icons/bs";

import {
  LoginPopup,
  LoginUserId,
  UserLogin,
  UserLogout,
} from "../redux/actions";
import { connect, useDispatch, useSelector } from "react-redux";
import { UserData } from "../app/model/user";
import { Login } from "./form-popups/login";
import { LoginPopupReducerState } from "../redux/user-data/user-reducer-state";
import { ClubService } from "../app/service/club.service";
import { LivestreamService } from "../app/service/livestream";
import TextField from "@mui/material/TextField";
// import { getInfluencersData } from "../redux/actions";
import CreatorSearchList from "./creatorSearchList";

import { ClubSingle } from "../pages/clubs/club-single";


interface Props {
  onShowPopup?: (poptype: string) => void;
  UserReducer?: any;
  login?: (data: any) => void;
  logout?: (data: any) => void;
  loginPopup?: (data: any) => void;
  LoginPopupReducer?: any;
  loginUserId?: (data: any) => void;
  LoginUserIdReducer?: any;
}

const AppHeader: React.FC<Props> = (props) => {
  const [headerColor, setHeaderColor] = useState(Boolean);
  const [isPopupType, setIsPopupType] = useState("");
  const [isOpen, setIsOpen] = useState<any>(false)
  const [isUserLogin, setUserLogin] = useState(
    localStorage.getItem("isLogin") || ""
  );
  const [inputText, setInputText] = useState("");
  const [isShown, setIsShown] = useState(false);
  const searchInput: any = useRef(null);

  // search bar
  const expandSearch = (event:any) => {
    setIsOpen(!isOpen);
    // searchInput.current.focus();

    console.log("hello")
  };

   

    const onSearchBlur = (event:any) => {
    if (isOpen === true) {
      setIsOpen(false);
      console.log("world")
    }
  };

 
  let inputHandler = (e: any) => {
    if (e.target.value.length < 3) {
      setIsShown(false);
    }
    if (e.target.value.length >= 3) {      
        setIsShown(true);
    }

    //convert input text to lower case
    var lowerCase = e.target.value.toLowerCase();
    setInputText(lowerCase);
  };
  const location = useLocation();

  const body = document.body;

  const scrollUp = "scroll-up";
  const scrollDown = "scroll-down";
  let lastScroll = 0;

  window.addEventListener("scroll", () => {
    // const sc = window.scrollY;
    // if (sc > 150) {
    //   setHeaderColor(true);
    // } else {
    //   setHeaderColor(false);
    // }
    const currentScroll = window.pageYOffset;
    if (currentScroll <= 0) {
      body.classList.remove(scrollUp);
      body.classList.remove(scrollDown);
      return;
    }

    if (currentScroll > lastScroll && !body.classList.contains(scrollDown)) {
      // down
      body.classList.remove(scrollUp);
      body.classList.add(scrollDown);
    } else if (
      currentScroll < lastScroll &&
      body.classList.contains(scrollDown)
    ) {
      // up
      body.classList.remove(scrollDown);
      body.classList.add(scrollUp);
    }
    lastScroll = currentScroll;
  });
  const [showMyProfile, setShowMyProfile] = useState(false);
  const handleCloseMyProfile = () => setShowMyProfile(false);

  const history = useHistory();
  const [showOffCanvas, setShowOffCanvas] = useState<Boolean>(false);
  const handleOffCanvasClose = () => setShowOffCanvas(false);
  const toggleOffCanvasShow = () => setShowOffCanvas((s) => !s);
  const dispatch = useDispatch();

  const [profileImage, setProfileImage] = useState("");
  const [loginUserData, setLoginUserData] = useState<any[] | []>([]);
  const [showSearchBar, setShowSearchBar] = useState<any>(false)
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const loginUserImage = sessionStorage.getItem("loginUserImage");
  // const navigate = useNavigate();

  // const handleSearchField=()=>{
  //   setShowSearchBar(!showSearchBar)
  // }
  // const handleSearchFocus=(event:any)=>{
  // //   if(event.target.value === ""){
  // //   setShowSearchBar(false)
  // // }else {
  //   setShowSearchBar(false)

  // // }
  // }
  const handleShow = (type: string) => {
    history.push('/login');
    // setShow(true);
    // // console.log("hello")
    // if (props.loginPopup) {
    //   const data = { loginPopup: true, popupType: type };
    //   dispatch(props.loginPopup(data));
    // }
    
  };

  let id = sessionStorage.getItem("isLogin") || "";

  useEffect(() => {

    // if (isOpen === true) {
    //   searchInput.current.focus();
    // }
    try {
      const userDataStr = sessionStorage.getItem("userData") || "";
      console.log(userDataStr, 'userData string');
      const userData: any = JSON.parse(userDataStr);

      if (userData && userData?.UserData && props.login) {
        dispatch(props.login(userData));
      }
    } catch (error) {
      console.log("session storage error::" + error);
    }
console.log('in header Component');

    const _id = sessionStorage.getItem("isLogin") || "";
    if (_id) {
      UserService.getUser(_id).then((res) => {
        if (res) {
          sessionStorage.setItem("userImages", "");
          setLoginUserData(res);
          if (res.length > 0) {
            setProfileImage(res[0]?.image);
            sessionStorage.setItem("userImages", res[0]?.image);
          }
        }
      });
    }
    // getCreator()
    // if (_id) {
    //   LivestreamService.getlivestreamById(_id).then((res) => {
    //     if (res) {
    //       console.log("res", res);

    //       setSelectedLivestream(res);
    //       LivestreamService.getCompletedLiveStreamByCreatorID(
    //         res[0]?.user_id
    //       ).then((res) => {
    //         setSelectedLivestream(res);
    //       });
    //     }
    //   });
    // }
  }, []);

  // const getCreator = () =>{
   
  // }
  // console.log(userData)
  const handleLogoutClick = () => {
    setUserLogin("");
    // if (props.logout) {
    //   const data = { loginPopup: false, popupType: "" };
    //   dispatch(props.logout(data));
    // }
    // sessionStorage.setItem("isLogin", "");
    // sessionStorage.setItem("token", "");
    if (props.loginUserId) {
      dispatch(props.loginUserId(null));
    }
    sessionStorage.clear();
    localStorage.clear();
    history.push("/");
  };

  const onMyProfile = () => {
    if (id) {
      getMyClub(id, "profile");
    }
  };
  const getMyClub = (id: string, type: string) => {
    setIsPopupType(type);
    ClubService.getUserClubs(id).then((res) => {
      if (res) {
        if (res.length > 0) {
          if (type === "profile") {
            history.push(`/clubs/${res[0]?._id}`);
          } else {
            history.push(`/dashboard/mylivestreams-schedule`);
          }
        }
      } else {
        setShowMyProfile(true);
      }
    });
  };

  const onMyProfileCreateClub = () => {
    history.push(`/dashboard/myclubs/details`);
    handleCloseMyProfile();
  };

  const onMyLivestreams = () => {
    if (id) {
      getMyClub(id, "livestream");
    }
  };

  const onCreateClub = () => {
    let formData = new FormData();
    formData.append(
      "name",
      loginUserData[0]?.name ? `${loginUserData[0]?.name} club` : ""
    );
    formData.append("owned_by", id);
    // formData.append('intro_video', '');
    // formData.append('cover_photo', '');
    ClubService.createClub(formData).then((res) => {
      sessionStorage.setItem("clubId", res[0]?._id || "");
      history.push(`/dashboard/mylivestreams-schedule`);
    });
  };

  const [selectedLivestream, setSelectedLivestream] = useState<any[] | []>([]);
  return (
    <>
      <header
        className={`site-header static navbar navbar-expand-lg ${headerColor ? "fixedActive" : ""
          }`}
      >
        {/* <div className="spinner">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div> */}
        <div className="container-fluid">
          <div className="site-header__left">
            {(isUserLogin || props?.UserReducer?.IsUserLogged) && (
              <span
                onClick={toggleOffCanvasShow}
                className="d-inline-flex d-md-none align-self-center mobile-toggle"
                style={{ cursor: "pointer" }}
              >
                <img
                  className="profile-icon"
                  src={
                    loginUserImage
                      ? loginUserImage
                      : props?.UserReducer?.UserData &&
                        props?.UserReducer?.UserData[0]?.image
                        ? props?.UserReducer?.UserData[0]?.image
                        : profileImage
                          ? profileImage
                          : LoginImg
                  }
                  style={{
                    height:
                      loginUserImage &&
                        props?.UserReducer?.UserData &&
                        props?.UserReducer?.UserData[0]?.image
                        ? "38px"
                        : "38px",
                    width:
                      loginUserImage &&
                        props?.UserReducer?.UserData &&
                        props?.UserReducer?.UserData[0]?.image
                        ? "38px"
                        : "38px",
                    display: "block",
                    borderRadius: "50%",
                  }}
                  alt="profile"
                />
                <i className="bi bi-list"></i>
              </span>
            )}

            <Link to="/" className="navbar-brand">
              {location.pathname.includes("livestream-detail") ? (
                <>
                  <img src={LogoWhite} className="logo-white" alt="Clan Logo" />
                  <img className="logo-mobile" src={Logo} alt="Clan Logo" />
                </>
              ) : (
                <img src={Logo} alt="Clan Logo" />
              )}
            </Link>
          </div>
          <ul className="navbar-nav me-auto">
          
            <li className="nav-item">
              <NavLink
                className="nav-link"
                aria-current="page"
                to="/livestreams/creator-livestream"
              >
                Livestream
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                className="nav-link"
                to="/livestreams/livestream-listing"
              >
                Past Events
              </NavLink>
            </li>
            {/* <li className="nav-item">
              <NavLink className="nav-link" to="/nft/nft-listing">
                NFT
              </NavLink>
            </li> */}
          </ul>
          {/* <TextField
          id="outlined-basic"
          variant="outlined"
          onChange={inputHandler}
          color="secondary"
          label="Search"
          style={{color:"white", backgroundColor:"white"}}
        /> */}



<span className="search-creator d-none d-md-inline-flex">

            {/* {showSearchBar && ( */}
            <input
              type="search"
              id="form1"
              className="form-control "
              placeholder="Search Creator"
              onChange={inputHandler}
              aria-label="Search"
              // onBlur={(event)=>handleSearchFocus(event)}
              autoFocus
            />
            {/* )} */}
            {/* <BsSearch onClick={handleSearchField}/> */}
          </span>
          {isShown && (
            <div className="creator_search_container">
          
               
              <CreatorSearchList input={inputText}/>
           
            
             </div>
          )}

          {/* {!props?.UserReducer?.IsUserLogged && ( */}
          {!(isUserLogin || props?.UserReducer?.IsUserLogged) && (
            <div className=" site-header__right d-flex">
              <>
                <div className="login-sec">
                  <span
                    style={{ cursor: "pointer" }}
                    className="login-icon"
                    onClick={() => handleShow("login")}
                  >
                    <svg
                      width="29px"
                      height="30px"
                      viewBox="0 0 29 30"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                    >
                      {/* <title>6C8BF58A-1E7C-44DF-9853-A6D4CC5D2F79</title> */}
                      <g
                        id="Main-pages"
                        stroke="none"
                        stroke-width="1"
                        fill="none"
                        fill-rule="evenodd"
                      >
                        <g
                          id="home/LS"
                          transform="translate(-1382.000000, -15.000000)"
                          fill="#000000"
                          fill-rule="nonzero"
                        >
                          <g
                            id="Group"
                            transform="translate(1382.000000, 15.000000)"
                            className="login-profile-icon"
                          >
                            <path
                              d="M19.3822679,15.7952113 L9.581423,15.7952113 C4.27879237,15.7952113 0,20.0601507 0,25.2651092 L0,25.3374695 C0,27.9038686 2.12107837,30 4.71744026,30 L24.2825597,30 C26.8791828,30 29,27.9036104 29,25.3374695 L29,25.2651092 C29,20.0241997 24.684572,15.7952113 19.3820067,15.7952113 L19.3822679,15.7952113 Z M26.8060422,25.3373985 C26.8060422,26.7109596 25.6724277,27.8314437 24.282625,27.8314437 L4.71750556,27.8314437 C3.32776822,27.8314437 2.1940884,26.7110242 2.1940884,25.3373985 L2.1940884,25.2650382 C2.1940884,21.2169021 5.52205253,17.9638192 9.58129239,17.9638192 L19.4187076,17.9638192 C23.5145178,17.9638192 26.8060422,21.2530467 26.8060422,25.2650382 L26.8060422,25.3373985 Z"
                              id="Shape"
                            ></path>
                            <path
                              d="M14.4818454,14.5662933 C18.4679447,14.5662933 21.6861979,10.1926617 21.6861979,6.61447064 C21.6861979,2.9639257 18.4314397,0 14.4818454,0 C10.5322512,0 7.27749304,2.96399025 7.27749304,6.57832599 C7.27749304,10.1926617 10.4957462,14.5662933 14.4818454,14.5662933 Z M14.4818454,2.1686789 C17.2246192,2.1686789 19.4919135,4.1566991 19.4919135,6.57832599 C19.4919135,9.14472511 17.1514133,12.3615988 14.4818454,12.3615988 C11.8122776,12.3615988 9.47177735,9.10870955 9.47177735,6.57832599 C9.47177735,4.15663455 11.7390717,2.1686789 14.4818454,2.1686789 Z"
                              id="Shape"
                            ></path>
                          </g>
                        </g>
                      </g>
                    </svg>
                  </span>
                  {/* <NavLink className="btn" to="/clubsingup"> */}
                  {/* <a className="btn" onClick={() => handleShow('signup')}>
                  <span>Signup</span>
                </a> */}
                  {/* </NavLink> */}
                </div>
              </>
            </div>
          )}
          {(isUserLogin || props?.UserReducer?.IsUserLogged) && (
            <div className="ml-auto site-header__right d-flex">
              {/* <div className="search-box">
                <button className="btn-search">
                  <i className="bi bi-search"></i>
                </button>
                <input
                  className="input-search"
                  type="search"
                  placeholder="Search"
                />
              </div> */}
              {/* Notification Panel */}
              {/* <div className="dropdown-notification dropdown">
                <a
                  className="dropdown-icon"
                  href="#"
                  role="button"
                  id="notificationMenuLink"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  data-bs-auto-close="false"
                >
                  <i className="bi bi-bell"></i>
                  <span className="position-absolute bottom-0 start-50 translate-bottom badge rounded-pill bg-primary">
                    10 <span className="visually-hidden">unread messages</span>
                  </span>
                </a>

                <div
                  className="dropdown-menu dropdown-menu-end notification"
                  aria-labelledby="notificationMenuLink"
                >
                  <div className="notification-heading-sec d-flex p-3">
                    <strong>Notifications</strong>
                    <Link className="ms-auto fs-sml" to="">
                      See all
                    </Link>
                  </div>

                  <ul
                    className="nav nav-tabs mx-3"
                    id="notificationTab"
                    role="tablist"
                  >
                    <li className="nav-item" role="presentation">
                      <button
                        className="nav-link active"
                        id="clubs-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#clubs"
                        type="button"
                        role="tab"
                        aria-controls="clubs"
                        aria-selected="true"
                      >
                        Clubs
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        className="nav-link"
                        id="livestream-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#livestream"
                        type="button"
                        role="tab"
                        aria-controls="livestream"
                        aria-selected="false"
                      >
                        Livestream
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        className="nav-link"
                        id="subscription-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#subscription"
                        type="button"
                        role="tab"
                        aria-controls="subscription"
                        aria-selected="false"
                      >
                        Subscription
                      </button>
                    </li>
                  </ul>
                  <div
                    className="tab-content px-3 pt-3"
                    id="notificationTabContent"
                  >
                    <div
                      className="tab-pane fade show active"
                      id="clubs"
                      role="tabpanel"
                      aria-labelledby="clubs-tab"
                    >
                      <div className="card card-post">
                        <div className="card-post-img">
                          <img
                            src="https://via.placeholder.com/75C"
                            alt="post image"
                          />
                        </div>
                        <div className="card-post-detail">
                          <span className="card-post-title">Post Title</span>

                          <p className="card-post-info">
                            <strong>Abhishek Sunkuru</strong> liked your post.
                          </p>

                          <span className="card-posted-info">1 day ago</span>
                        </div>
                      </div>
                      <div className="card card-post">
                        <div className="card-post-img">
                          <img
                            src="https://via.placeholder.com/75C"
                            alt="post image"
                          />
                        </div>
                        <div className="card-post-detail">
                          <span className="card-post-title">Post Title</span>

                          <p className="card-post-info">
                            <strong>Abhishek Sunkuru</strong> liked your post.
                          </p>

                          <span className="card-posted-info">1 day ago</span>
                        </div>
                      </div>
                      <div className="card card-normal">
                        <div className="card-post-detail">
                          <p className="card-post-info">
                            <strong>Abhishek Sunkuru</strong> subscribed to your
                            Club. <a href="">Say thanks!</a>
                          </p>

                          <span className="card-posted-info">1 day ago</span>
                        </div>
                      </div>
                      <div className="card card-normal">
                        <div className="card-post-detail">
                          <p className="card-post-info">
                            <strong>Rahul Madaan</strong> has sent you a
                            message.
                          </p>

                          <span className="card-posted-info older">
                            1 week ago
                          </span>
                        </div>
                      </div>
                      <div className="card card-post">
                        <div className="card-post-img">
                          <img
                            src="https://via.placeholder.com/75C"
                            alt="post image"
                          />
                        </div>
                        <div className="card-post-detail">
                          <span className="card-post-title">Post Title</span>

                          <p className="card-post-info">
                            <strong>Abhishek Sunkuru</strong> liked your post.
                          </p>

                          <span className="card-posted-info older">
                            2 weeks ago
                          </span>
                        </div>
                      </div>
                    </div>

                    <div
                      className="tab-pane fade"
                      id="livestream"
                      role="tabpanel"
                      aria-labelledby="livestream-tab"
                    >
                      ...
                    </div>
                    <div
                      className="tab-pane fade"
                      id="subscription"
                      role="tabpanel"
                      aria-labelledby="subscription-tab"
                    >
                      ...
                    </div>
                  </div>
                </div>
              </div> */}
              <div className="dropdown d-none d-md-flex">
                <NavLink
                  to={
                    loginUserData[0]?.club_influencer
                      ? "/dashboard/maindashboard"
                      : "/dashboard/purchasedtickets/upcoming"
                  }
                  onClick={handleOffCanvasClose}
                  className="dropdown-toggle profile-dropdown"
                >
                
                  <img
                    className="profile-icon"
                    src={
                      sessionStorage.getItem("userImages")
                        ? sessionStorage.getItem("userImages")
                        : props?.UserReducer?.UserData &&
                          props?.UserReducer?.UserData[0]?.image
                          ? props?.UserReducer?.UserData[0]?.image
                          : profileImage
                            ? profileImage
                            : LoginImg
                    }
                    style={{
                      height:
                        loginUserImage &&
                          props?.UserReducer?.UserData &&
                          props?.UserReducer?.UserData[0]?.image
                          ? "38px"
                          : "38px",
                      width:
                        loginUserImage &&
                          props?.UserReducer?.UserData &&
                          props?.UserReducer?.UserData[0]?.image
                          ? "38px"
                          : "38px",
                      display: "block",
                      borderRadius: "50%",
                    }}
                    alt="profile"
                  />
                </NavLink>
                {/* <a
                  className="dropdown-toggle profile-dropdown"
                  id="dropdownMenuLink"
                  href="#"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <img
                    className="profile-icon"
                    src={
                      loginUserImage
                        ? loginUserImage
                        : props?.UserReducer?.UserData &&
                          props?.UserReducer?.UserData[0]?.image
                        ? props?.UserReducer?.UserData[0]?.image
                        : profileImage
                        ? profileImage
                        : LoginImg
                    }
                    style={{
                      height:
                        loginUserImage &&
                        props?.UserReducer?.UserData &&
                        props?.UserReducer?.UserData[0]?.image
                          ? "38px"
                          : "38px",
                      width:
                        loginUserImage &&
                        props?.UserReducer?.UserData &&
                        props?.UserReducer?.UserData[0]?.image
                          ? "38px"
                          : "38px",
                      display: "block",
                      borderRadius: "50%",
                    }}
                    alt="profile"
                  /> */}

                {/* <span className="profile-user-name">
                      <span>{loginUserData[0]?.name}</span>
                      
                      <span>
                        {loginUserData[0]?.instagram_handle_name}
                      </span>
                    </span> */}

                {/* <div className="profile-detail-right">
                    <a
                      href="javascript:void(0)"
                      onClick={handleLogoutClick}
                      style={{
                        fontSize: "20px",
                        color: "orange",
                        marginLeft: "auto",
                        padding: "0",
                      }}
                    >
                      <i className="bi bi-box-arrow-right"></i>
                    </a>
                  </div> 
                </a>*/}

                {/* <ul
                  className="dropdown-menu dropdown-menu-end"
                  aria-labelledby="dropdownMenuLink"
                >
                  <li>
                    <NavLink
                      className="dropdown-item"
                      to={
                        loginUserData[0]?.club_influencer
                          ? "/dashboard/maindashboard"
                          : "/dashboard/purchasedtickets/upcoming"
                      }
                    >
                      Dashboard
                    </NavLink>
                  </li>
                  <li>
                    <NavLink className="dropdown-item" to="/dashboard/settings">
                      Settings
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      className="dropdown-item"
                      to={`/livestreams/more-livestream/${loginUserData[0]?._id}`}
                    >
                      My Profile
                    </NavLink>
                  </li>
                  <li>
                    <a
                      href="javascript:void(0)"
                      className="dropdown-item"
                      onClick={handleLogoutClick}
                    >
                      Logout
                    </a>
                  </li>
                </ul> */}
              </div>
            </div>
          )}
        </div>
      </header>

      {/* Login Modal */}
      {props.LoginPopupReducer.loginPopup?.loginPopup && <Login />}

      <Offcanvas
        id="mobileMenu"
        show={showOffCanvas}
        onHide={handleOffCanvasClose}
        target=".nav-link"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            {/* <NavLink
              to="/dashboard/settings"
              onClick={handleOffCanvasClose}
              className="d-flex align-items-center text-decoration-none"
            >
              <img
                className="rounded-circle me-2 profile-icon"
                src={
                  loginUserImage
                    ? loginUserImage
                    : props?.UserReducer?.UserData &&
                      props?.UserReducer?.UserData[0]?.image
                    ? props?.UserReducer?.UserData[0]?.image
                    : profileImage
                    ? profileImage
                    : Profile
                }
                alt="profile"
              />
               <span className="fs-sml ">{props?.UserReducer?.UserData[0]?.name}</span> 
            </NavLink> */}
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          {(isUserLogin || props?.UserReducer?.IsUserLogged) && (
            <>
              <div className="dropdown">
                <ul className="list-style-none mobile-menu">
                  {/* <li>
                    <a href="#" role="button">
                      <img
                        className="rounded-circle"
                        src={props?.UserReducer?.UserData ? props?.UserReducer?.UserData[0]?.image : Profile}
                        style={{ height: "50px", width: "50px" }}
                        alt="profile"
                      />
                    </a>
                  </li> */}

                  {loginUserData[0]?.club_influencer && (
                    <>
                      <li className="menu-main">
                        <NavLink
                          to="/dashboard/maindashboard"
                          onClick={handleOffCanvasClose}
                        >
                          <span className="menu-text">Dashboard</span>
                        </NavLink>
                      </li>
                      <li className="menu-main">
                        <NavLink
                          to="/dashboard/mylivestreams/scheduled"
                          onClick={handleOffCanvasClose}
                        >
                          <span className="menu-text">Livestream</span>
                        </NavLink>
                      </li>
                      <li className="menu-main">
                        <NavLink
                          to="/dashboard/purchasedtickets/upcoming"
                          onClick={handleOffCanvasClose}
                        >
                          <span className="menu-text">Tickets</span>
                        </NavLink>
                      </li>

                      {/* <li>
                        <NavLink
                          to="/dashboard/mynft"
                          onClick={handleOffCanvasClose}
                        >
                          <span className="menu-text">My NFT</span>
                        </NavLink>
                      </li> */}
                      {/* <li>
                        <NavLink to="/dashboard/myclubs/details">
                          <i className="icon-my-club"></i>
                          <span className="menu-text">Dashboard</span>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/dashboard/post">
                          <i className="icon-post"></i>
                          <span className="menu-text">Create Content</span>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/dashboard/posts/published">
                          <i className="icon-all-post"></i>
                          <span className="menu-text">Posts</span>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/dashboard/mylivestreams/drafts">
                          <i className="bi bi-camera-video"></i>
                          <span className="menu-text">Livestreams</span>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/dashboard/subscribers/list">
                          <i className="icon-subscribers"></i>
                          <span className="menu-text">Subscribers</span>
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/dashboard/subscribers/inbox">
                          <i className="bi bi-camera-video"></i>
                          <span className="menu-text">Subscribers Inbox</span>
                        </NavLink>
                      </li> */}
                    </>
                  )}
                  {/* <li>
                    <NavLink to="/dashboard/mysubscriptions/posts">
                      <i className="bi bi-people"></i>
                      <span className="menu-text">My Feed</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/dashboard/mysubscriptions/subscriptions">
                      <i className="bi bi-people"></i>
                      <span className="menu-text">Subscriptions</span>
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/dashboard/mysubscriptions/inbox">
                      <i className="bi bi-people"></i>
                      <span className="menu-text">My Inbox</span>
                    </NavLink>
                  </li> */}
                  {!loginUserData[0]?.club_influencer && (
                    <>
                      <li>
                        <NavLink
                          to="/dashboard/purchasedtickets/upcoming"
                          onClick={handleOffCanvasClose}
                        >
                          <span className="menu-text">My Tickets</span>
                        </NavLink>
                      </li>
                      {loginUserData[0]?.is_finance && (
                      <li>
                        <NavLink
                          className="dropdown-item"
                          to="/admin"
                          target="_blank"
                          onClick={handleOffCanvasClose}
                        >
                          Admin
                        </NavLink>
                      </li>
                       )}
                      {/* <li>
                        <NavLink
                          to="/dashboard/mynft"
                          onClick={handleOffCanvasClose}
                        >
                          <span className="menu-text">My NFT</span>
                        </NavLink>
                      </li> */}
                    </>
                  )}
                </ul>
              </div>

              <div className="offcanvas-footer">
                <ul className="" aria-labelledby="dropdownMenuLink">
                  {/* <li>
                    <button className="dropdown-item" onClick={onMyProfile}>
                      My Profile
                    </button>
                  </li>
                  <li>
                    <NavLink
                      className="dropdown-item"
                      to="/dashboard/myprofile/personalDetail"
                    >
                      Account Settings
                    </NavLink>
                  </li> */}
                  {/* <li>
                    <NavLink
                      className="dropdown-item"
                      to="/dashboard/settings"
                      onClick={handleOffCanvasClose}
                    >
                      Account settings
                    </NavLink>
                  </li> */}
                  {/* <li>
                    <NavLink
                      className="dropdown-item"
                      to="/dashboard/mylivestreams/drafts"
                    >
                      My Livestreams
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      className="dropdown-item"
                      to="/dashboard/mysubscriptions/posts"
                    >
                      My Subscriptions
                    </NavLink>
                  </li> */}
                  {loginUserData[0]?.club_influencer && (
                    <li>
                      <NavLink
                        className="dropdown-item"
                        to={`/livestreams/more-livestream/${loginUserData[0]?._id}/ls`}
                        onClick={handleOffCanvasClose}
                      >
                        My Profile
                      </NavLink>
                    </li>
                  )}

                  {/* {!loginUserData[0]?.club_influencer && (
                    <li className="pt-3">
                      <a href="" className="dropdown-item fs-xsml">
                        Update to creator account
                      </a>
                    </li>
                  )} */}
                 <li>
                    <NavLink
                      className="dropdown-item"
                      to="/dashboard/settings"
                      onClick={handleOffCanvasClose}
                    >
                      Settings
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      className="dropdown-item"
                      to="/support"
                      onClick={handleOffCanvasClose}
                    >
                      Contact Us
                    </NavLink>
                  </li>
                  <li>
                    <a
                      href="javascript:void(0)"
                      style={{ cursor: "pointer" }}
                      className="dropdown-item"
                      onClick={handleLogoutClick}
                    >
                      Logout
                    </a>
                  </li>
                </ul>
              </div>
            </>
          )}
        </Offcanvas.Body>
      </Offcanvas>
      <Modal
        show={showMyProfile}
        onHide={handleCloseMyProfile}
        centered
        dialogClassName="sign-up-modal login-modal"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="auth-container">
            <div className="auth-sec auth-right-sec">
              <div className="auth-info">
                <h2>Info</h2>
              </div>
              <div className="text-center">
                {isPopupType === "profile"
                  ? "You have not created club, please create the club"
                  : `you have not created club, we are creating club on your name (${loginUserData[0]?.name} club).`}
              </div>

              <div className="row justify-content-end mt-3">
                <div className="col-auto">
                  {isPopupType === "profile" ? (
                    <button
                      onClick={onMyProfileCreateClub}
                      className="btn btn-outline-black"
                    >
                      OK
                    </button>
                  ) : (
                    <button
                      onClick={onCreateClub}
                      className="btn btn-outline-black"
                    >
                      OK
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    UserReducer: state.UserReducer,
    LoginPopupReducer: state.LoginPopupReducer,
    LoginUserIdReducer: state.LoginUserIdReducer,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    logout: () => dispatch(UserLogout()),
    login: (data: UserData) => dispatch(UserLogin(data)),
    loginPopup: (data: any) => dispatch(LoginPopup(data)),
    loginUserId: (data: any) => dispatch(LoginUserId(data)),
  };
};
const connectedNavBar = connect(mapStateToProps, mapDispatchToProps)(AppHeader);
export { connectedNavBar as AppHeader };

// export default AppHeader;
