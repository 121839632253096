import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { UserService } from "../../../app/service/user.service";
import FormBuilder from "../../../components/form-builder";
import { Loading } from "../../../redux/actions";

interface Props {
  UserReducer?: any;
  loading?: (data: any) => void;
}

const MyProfilePersonalSocialConnectPage = (props: Props) => {
  let id = sessionStorage.getItem('isLogin') || '';
  const [userData, setUserData] = useState<any[] | []>([]);
  const [userFormData, setUserFormData] = useState<any>();
  const [facebook, setFacebook] = useState<string>('');
  const [instagram, setInstagram] = useState<string>('');
  const [twitter, setTwitter] = useState<string>('');
  const [youtube, setYoutube] = useState<string>('');
  const dispatch = useDispatch();

  useEffect(() => {
    if (id) {
      getUserData(id);
    }
  }, [id]);

  const getUserData = (id: string) => {
    if (props.loading) {
      dispatch(props.loading(true));
    }
    UserService.getUser(id).then(
      res => {
        if (res) {
          setUserData([...res]);
          if (res.length > 0) {
            setFacebook(res[0].facebook_url ? res[0].facebook_url : '');
            setInstagram(res[0].instagram_url ? res[0].instagram_url : '');
            setTwitter(res[0].twitter_url ? res[0].twitter_url : '');
            setYoutube(res[0].youtube_url ? res[0].youtube_url : '');
          }
          if (props.loading) {
            dispatch(props.loading(false));
          }
        } else {
          if (props.loading) {
            dispatch(props.loading(false));
          }
        }
      }
    );
  }
  const updateFormData = (data: any) => {
    setUserFormData(data);
  };

  const onSaveMyProfile = () => {
    if (props.loading) {
      dispatch(props.loading(true));
    }
    const data: any = userFormData?.value;
    data._id = id;
    UserService.updateUsers(data).then(
      res => {
        if (res) {
          getUserData(id);
          if (props.loading) {
            dispatch(props.loading(false));
          }
        } else {
          if (props.loading) {
            dispatch(props.loading(false));
          }
        }
      }
    );
  }
  const onDisconnect = (value: string) => {
    const data: any = {
      _id: id,
    }
    data[value] = '';
    UserService.updateUsers(data).then(
      res => {
        if (res) {
          getUserData(id);
          setFacebook('');
          setInstagram('');
          setTwitter('');
          setYoutube('');
        }
      }
    );
  }
  const onChangeFaceBook = (event: any) => {
    setFacebook(event.target.value);
  }

  const onChangeInstagram = (event: any) => {
    setInstagram(event.target.value);
  }

  const onChangeTwitter = (event: any) => {
    setTwitter(event.target.value);
  }

  const onChangeYoutube = (event: any) => {
    setYoutube(event.target.value);
  }
  return (<div>
    <FormBuilder onUpdate={updateFormData}>
      <form>
        <div className="social-connect">
          <section className="myprofile-info row">
            <div className="myprofile-info-left col-md-4">
              <h3>Facebook</h3>
            </div>
            <div className="col-md-4">
              {/* <p>https://www.facebook.com/{facebook} <a href={`https://www.facebook.com/${facebook}`} target="_blank"><i className="bi bi-arrow-up-right"></i></a></p> */}
              <div className="form-floating mb-3 social_content_fix">
                <input type="text" className="form-control" readOnly value={'https://www.facebook.com/'} />
                <input
                  type="text"
                  className="form-control social_contact"
                  id="facebook_url"
                  placeholder="Facebook"
                  name="facebook_url"
                  defaultValue={userData[0]?.facebook_url}
                  onInput={onChangeFaceBook}
                />
                <label htmlFor="name">Facebook</label>
              </div>
            </div>
            <div className="myprofile-info-right col-md-4 mt-2">
              {userData[0]?.facebook_url === '' ? <button type="button" className="btn btn-outline-black btn-lg" onClick={onSaveMyProfile}>
                Save
              </button> : <button type="button" className="btn btn-outline-danger btn-lg" onClick={() => onDisconnect('facebook_url')}>
                Remove
              </button>}
            </div>
          </section>
          <section className="myprofile-info row">
            <div className="myprofile-info-left col-md-4">
              <h3>Instagram</h3>
            </div>
            <div className="col-md-4">
              {/* <p>https://www.instagram.com/{instagram} <a href={`https://www.instagram.com/${instagram}`} target="_blank"><i className="bi bi-arrow-up-right"></i></a></p> */}
              <div className="form-floating mb-3 social_content_fix">
                <input type="text" className="form-control" readOnly value={'https://www.instagram.com/'} />
                <input
                  type="text"
                  className="form-control social_contact"
                  id="instagram_url"
                  placeholder="Instagram"
                  name="instagram_url"
                  defaultValue={userData[0]?.instagram_url}
                  onInput={onChangeInstagram}
                />
                <label htmlFor="name">Instagram</label>
              </div>
            </div>
            <div className="myprofile-info-right col-md-4 mt-2">
              {userData[0]?.instagram_url === '' ? <button type="button" className="btn btn-outline-black btn-lg" onClick={onSaveMyProfile}>
                Save
              </button> : <button type="button" className="btn btn-outline-danger btn-lg" onClick={() => onDisconnect('instagram_url')}>
                Remove
              </button>}
              {/* <div className="social-connect-info">
                <div className="social-connect-info-left">
                  <i className="bi bi-instagram"></i>clan.social
                </div>
                <a href="" className="link link-orange">Disconnect</a>
              </div> */}
            </div>
          </section>
          <section className="myprofile-info row">
            <div className="myprofile-info-left col-md-4">
              <h3>Twitter</h3>
            </div>
            <div className="col-md-4">
              {/* <p>https://twitter.com/{twitter} <a href={`https://twitter.com/${twitter}`} target="_blank"><i className="bi bi-arrow-up-right"></i></a></p> */}
              <div className="form-floating mb-3 social_content_fix">
              <input type="text" className="form-control" readOnly value={'https://twitter.com/'} />

                <input
                  type="text"
                  className="form-control social_contact_twitter"
                  id="twitter_url"
                  placeholder="Twitter"
                  name="twitter_url"
                  defaultValue={userData[0]?.twitter_url}
                  onInput={onChangeTwitter}
                />
                <label htmlFor="name">Twitter</label>
              </div>
            </div>
            <div className="myprofile-info-right col-md-4 mt-2">
              {userData[0]?.twitter_url === '' ? <button type="button" className="btn btn-outline-black btn-lg" onClick={onSaveMyProfile}>
                Save
              </button> : <button type="button" className="btn btn-outline-danger btn-lg" onClick={() => onDisconnect('twitter_url')}>
                Remove
              </button>}
              {/* <button type="button" className="btn btn-outline-black btn-lg" onClick={onSaveMyProfile}>
                Connect
              </button> */}
            </div>
          </section>
          <section className="myprofile-info row">
            <div className="myprofile-info-left col-md-4">
              <h3>Youtube</h3>
            </div>
            <div className="col-md-4">
              {/* <p> https://www.youtube.com/{youtube} <a href={`https://www.youtube.com/${youtube}`} target="_blank"><i className="bi bi-arrow-up-right"></i></a></p> */}
              <div className="form-floating mb-3 social_content_fix">
              <input type="text" className="form-control" readOnly value={'https://www.youtube.com/'} />

                <input
                  type="text"
                  className="form-control social_contact"
                  id="youtube_url"
                  placeholder="Youtube"
                  name="youtube_url"
                  defaultValue={userData[0]?.youtube_url}
                  onInput={onChangeYoutube}
                />
                <label htmlFor="name">Youtube</label>
              </div>
            </div>
            <div className="myprofile-info-right col-md-4 mt-2">
              {userData[0]?.youtube_url === '' ? <button type="button" className="btn btn-outline-black btn-lg" onClick={onSaveMyProfile}>
                Save
              </button> : <button type="button" className="btn btn-outline-danger btn-lg" onClick={() => onDisconnect('youtube_url')}>
                Remove
              </button>}
              {/* <button type="button" className="btn btn-outline-black btn-lg" onClick={onSaveMyProfile}>
                Connect
              </button> */}
            </div>
          </section>
        </div>
      </form>
    </FormBuilder>
  </div >
  );
};
const mapStateToProps = (state: any) => {
  return {
    UserReducer: state.UserReducer,
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    loading: (data: any) => dispatch(Loading(data)),
  }
}

const connectedNavBar = connect(mapStateToProps, mapDispatchToProps)(MyProfilePersonalSocialConnectPage);
export { connectedNavBar as MyProfilePersonalSocialConnectPage };
// export default MyProfilePersonalSocialConnectPage;
