import {
  Container,
  Grid,
  TextField,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import React from "react";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { Link } from "react-router-dom";

type Props = {};

const SignupProfile = (props: Props) => {
  const [dateValue, setDateValue] = React.useState<Date | null>(new Date());
  const [timeValue, setTimeValue] = React.useState<Date | null>(
    new Date("2018-01-01T00:00:00.000Z")
  );
  const [gender, setGender] = React.useState("");

  const handleChange = (event: SelectChangeEvent) => {
    setGender(event.target.value);
  };
  return (
    <div className="main-content vh-100">
      <Container
        className="text-center pt-md-4 vh-100 signup-last-step"
        maxWidth="sm"
        fixed
      >
        <h2 className="pb-md-3">Almost done</h2>
        <TextField
          id="name"
          label="Your name (This appears on your profile)"
          variant="outlined"
          fullWidth
          margin="normal"
        />
        <TextField
          id="email"
          label="Your Email"
          variant="outlined"
          fullWidth
          margin="normal"
        />

        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            disablePast
            label="Date"
            value={dateValue}
            onChange={(newValue) => {
              setDateValue(newValue);
            }}
            renderInput={(params) => (
              <TextField {...params} margin="normal" fullWidth />
            )}
          />
        </LocalizationProvider>
        <FormControl fullWidth margin="normal">
          <InputLabel id="demo-simple-select-label">Gender</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={gender}
            label="Age"
            onChange={handleChange}
          >
            <MenuItem value="Male">Male</MenuItem>
            <MenuItem value="Female">Female</MenuItem>
            <MenuItem value="Other">Other</MenuItem>
          </Select>
        </FormControl>

        <TextField
          id="location"
          label="Location"
          variant="outlined"
          fullWidth
          placeholder="Enter your city"
          margin="normal"
        />
        <TextField
          id="phone"
          label="Phone"
          variant="outlined"
          fullWidth
          margin="normal"
          placeholder="Enter your phone number to verify"
        />
        <span className="mb-2 d-flex align-items-center">
          <FormControlLabel
            className="me-1 p-0"
            control={<Checkbox />}
            label="I agree to the"
          />
          <Link to="">
            <strong>Terms &amp; Conditions</strong>
          </Link>
        </span>
        <div className="d-flex align-items-center px-4">
          <Link to="" className="me-auto fs-xsml">Skip</Link>
          <button
            type="submit"
            className="mt-md-4 btn btn-black btn-wide"
          >
            Finish
          </button>
        </div>
        <span className="auth-sec-already-member">
          Already have an account. &nbsp;
          <a className="">Log in</a>
        </span>
      </Container>
    </div>
  );
};

export default SignupProfile;
