import React, { useState, useEffect } from "react";
import Admin from './Admin'
import { UserService } from "../../../app/service/user.service";
import { ClubService } from "../../../app/service/club.service";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
const AdminPayment = () => {
    const [AdminPaymentDetails, setAdminPaymentDetails] = useState<any[] | []>([]);
    const [UserAccountDetails, setUserAccountDetails] = useState<any[] | []>([]);
    const [showUserDetails, setShowUserDetails] = useState<any>(false)
    const [remarks, setRemarks] = useState<any>("")
    const [amount, setAmount] = useState<any>("")
    const [transactionId, setTranscationId] = useState<any>("")
    const [remarksError, setRemarksError] = useState<any>("")
    const [amountError, setAmountError] = useState<any>("")
    const [transactionIdError, setTranscationIdError] = useState<any>("")
    const [showUpdateInput, setShowUpdateInput] = useState<any>(true)
    const [claimRequestStatus, setclaimRequestStatus] = useState<any[] | []>([])
    const [confirmationDetail, setConfirmationDetail] = useState<any>(false)
    const [cancelDetail, setCancelDetail] = useState(false)
    const handleClose = () => setShowUserDetails(false);
    const handleShow = () => setShowUserDetails(true);
    let user_id: any
    let request_no:any
    let mobileNo:any 
    console.log(user_id);
    useEffect(() => {
        getPaymentDetails()
    }, []);
    const getPaymentDetails = () => {
        UserService.AdminPaymentDetails().then((res) => {
            // console.log("res getCreator data", res);
            console.log(res);
            setAdminPaymentDetails(res);

            // setIsShown(true);
        })

    }

    const getUserClubAndAccountDetails = (user_id:any, status:any) => {
        console.log(user_id, "accountDetails");

        ClubService.getUserClubAndAccountDetails(user_id).then((res) => {
            // console.log("res getCreator data", res);
            console.log(res, "accountDetails");
            setUserAccountDetails(res);
            handleShow()
            if(status=="Successful"){
                setShowUpdateInput(false)
                console.log(status)
                setCancelDetail(false)

                console.log(showUpdateInput);
                UserService.claimRequestStatus(user_id).then((res) => {
                    // console.log("res getCreator data", res);
                    console.log(res);
                    
                    setclaimRequestStatus(res)
                    // setIsShown(true);
                })  
                
                setConfirmationDetail(true)
            }
            else if(status=="pending"){
                setShowUpdateInput(true)
                setConfirmationDetail(false)
                setCancelDetail(false)
            }else{
                setShowUpdateInput(false)
                setConfirmationDetail(false)
                setCancelDetail(true)
                UserService.claimRequestStatus(user_id).then((res) => {
                    // console.log("res getCreator data", res);
                    console.log(res);
                    
                    setclaimRequestStatus(res)
                    // setIsShown(true);
                })
            }
            
            // setIsShown(true);
        })

    }
    const onChangeRemarks = (event: any) => {
        setRemarksError("")
        if (event.target.value) {
            setRemarks(event.target.value);
            console.log(remarks);
        } else {
            setRemarks("");
        }
    };
    const onChangeAmount = (event: any) => {
        setAmountError("")
        if (event.target.value) {
            setAmount(event.target.value);
            console.log(amount);
        } else {
            setAmount("");
        }
    };
    const onChangeTransactionId = (event: any) => {
        setTranscationIdError("")
        if (event.target.value) {
            setTranscationId(event.target.value);
            console.log(transactionId);
        } else {
            setTranscationId("");
        }
    };

    let date = Date.now()
    var d = new Date(date + 19800000);
    let finance_update_time = d.toUTCString();
    const successfulBodyData = {
        amount_transferred: amount,
        status: "Successful",
        finance_team_remarks: remarks,
        transaction_id: transactionId,
        finance_update_time: finance_update_time,
        user_id: "",
        // request_no:request_no
    }
    const onClickSuccessful = (data: any) => {

        successfulBodyData.user_id = data;
        console.log(successfulBodyData);
        if (!remarks){
            setRemarksError("Please enter remarks")
        }else if (!amount){
            setAmountError("Please enter amount")
        }else if(!transactionId){
            setTranscationIdError("please enter transaction id")
        }else {
        UserService.updateClaimSuccessful(successfulBodyData).then((res) => {
            if (res) {
                console.log(res, "update");
                toast.success("Updated Successfully")
                window.location.reload();

            }
        })
    }
    }

    const cancelBodyData = {
        status:"Cancelled",
        user_id: "",
        finance_team_remarks: remarks,
        finance_update_time: finance_update_time,
    }
    const onClickCancel = (data:any)=>{
        cancelBodyData.user_id = data;
        if(!remarks){
            setRemarksError("Remarks is mandatory in Cancelled")
        }else{
            UserService.updateClaimCancel(cancelBodyData).then((res) => {
                if(res){
                    console.log(res)
                    toast.success("Updated Successfully")
                    window.location.reload();
                }
            })
        }
    }
console.log(mobileNo);
    return (
        <div style={{ display: "flex" }}>
            <Admin />
            <div style={{ width: "100%" }}>
                <table className="table" >
                    <thead>
                        <tr>
                            <th scope="col">Request No.</th>
                            <th scope="col">Mobile No.</th>
                            <th scope="col">Amount to Withdraw</th>
                            <th scope="col">Status</th>
                            <th scope="col">Transferred Amount</th>
                            <th scope="col">Created At</th>
                            <th scope="col">User Details</th>
                            {/* <th scope="col"></th> */}


                        </tr>
                    </thead>
                    <tbody>
                        {AdminPaymentDetails.map((data: any, index: any) => {
                            console.log(data, "anything")
                            console.log(AdminPaymentDetails)
                            return (
                                <tr className="verification-table-row">
                                    <td>{data.request_no}</td>
                                    <td>{data.user_Info[0].phone}</td>
                                    <td>{data.amount_to_withdraw}</td>
                                    <td>{data.status}</td>
                                    <td>{data.amount_transferred}</td>
                                    <td>{data.createdAt}</td>
                                    <td><button onClick={() => getUserClubAndAccountDetails(data.user_id, data.status)}>details</button></td>
                                    {/* {data.influencer_verified ? <td></td> : <td>
                <button className="verification-accept">Accept</button>
                <button className="verification-reject">Reject</button>
             </td>} */}

                                </tr>
                            )
                        })}




                    </tbody>
                </table>
            </div>
            <Modal
                show={showUserDetails}
                onHide={() => setShowUserDetails(false)}
                dialogClassName="modal-90w"
                aria-labelledby="example-custom-modal-styling-title"
                size="lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-custom-modal-styling-title">
                        UserDetails
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="col-md-12" style={{ display: "flex" }}>
                        <div className="col-md-6">
                            {UserAccountDetails.map((data: any) => {
                                return (
                                    <div>
                                        <p><strong>User Name : </strong> {data.name}</p>
                                        <p><strong>MobileNo : </strong> {data.phone}</p>
                                        <p><strong>Email : </strong> {data.email}</p>
                                        <span style={{display:"none"}}>{user_id = data.owned_by} {finance_update_time}</span>
                                    </div>
                                )
                            })}

                            {UserAccountDetails[0]?.payout_details.map((data: any) => {
                                return (
                                    <div>
                                        <p>
                                            <strong>Account Holder Name : </strong>
                                            {data?.account_holder_name}
                                        </p>
                                        <p className="claim-account-details">
                                            <strong>Bank Name : </strong>
                                            {data?.bank_name}
                                        </p>
                                        <p className="claim-account-details">
                                            <strong>Account No. : </strong>
                                            {data?.account_number}
                                        </p>
                                        <p className="claim-account-details">
                                            <strong>IFSC : </strong>
                                            {data?.ifsc}
                                        </p>
                                        <p className="claim-account-details">
                                            <strong>Account Type : </strong>
                                            {data?.account_type}
                                        </p>
                                        <p className="claim-account-details">
                                            <strong>Address : </strong>
                                            {data?.address}
                                        </p>
                                    </div>
                                )
                            })}
                        </div>
                        {showUpdateInput &&
                        <div className="col-md-6 admin-withdrawl-modal">

                            <input
                                type="text"
                                placeholder="Remarks"
                                onChange={(event: any) => onChangeRemarks(event)}
                                value={remarks}
                            />
                            {remarksError && (
                                <p

                                    className="amount-error-field"
                                >
                                    *{remarksError}
                                </p>
                            )}


                            <input
                                type="number"
                                placeholder="Ammount Transferred"
                                onChange={(event: any) => onChangeAmount(event)}
                                value={amount}
                            />
                             {amountError && (
                                <p

                                    className="amount-error-field"
                                >
                                    *{amountError}
                                </p>
                            )}

                            <input
                                type="text"
                                placeholder="Transaction Id"
                                onChange={(event: any) => onChangeTransactionId(event)}
                                value={transactionId}
                            />
                             {transactionIdError && (
                                <p

                                    className="amount-error-field"
                                >
                                    *{transactionIdError}
                                </p>
                            )}
                            <div>
                                <button className="verification-accept" onClick={() => onClickSuccessful(user_id)}>Successful </button>
                                <button className="verification-reject" onClick={() => onClickCancel(user_id)}>Cancelled </button>
                            </div>
                        </div>
                        }
                        {confirmationDetail &&
                        <div className="after-succesful-confirmation">
                        {claimRequestStatus.map((data:any)=>{
                            console.log(data, "hello world")
                            return(
                                <>
                                <span><strong>Status :</strong>{data.status}</span>
                                <span><strong>Transaction Id :</strong>{data.transaction_id}</span>
                                <span><strong>Remarks :</strong>{data.finance_team_remarks}</span>
                                <span><strong>Amount Transferred :</strong>{data.amount_transferred}</span>
                                <span><strong>Date :</strong>{data.finance_update_time}</span>

                                </>
                            );
                        })}
                        
                        </div>
                        }
                        {cancelDetail &&
                        <div className="after-succesful-confirmation">
                        {claimRequestStatus.map((data:any)=>{
                            console.log(data, "hello world")
                            return(
                                <>
                                <span><strong>Status :</strong>{data.status}</span>
                                <span><strong>Date :</strong>{data.finance_update_time}</span>
                                <span><strong>Remarks :</strong>{data.finance_team_remarks}</span>
                                

                                </>
                            );
                        })}
                        
                        </div>
                        }
                    
                    </div>
                </Modal.Body>
            </Modal>


        </div>
    )
}

export default AdminPayment;