import React, { useEffect, useState, useRef } from "react";
import { connect, useDispatch } from "react-redux";
import FormBuilder from "../form-builder";
import { Modal } from "react-bootstrap";
import { useHistory, Link, useLocation } from "react-router-dom";
import {
  FormControlError,
  FormField,
  FormValidators,
} from "../form-builder/model/form-field";
import {
  FormValidator,
  GetControlIsValid,
  GetEmailControlIsValid,
  GetMobileControlIsValid,
} from "../form-builder/validations";
import { UserService } from "../../app/service/user.service";
import { toast } from "react-toastify";
import {
  Loading,
  LoginPopup,
  LoginUserId,
  UserLogin,
} from "../../redux/actions";
import GoogleLogin from "react-google-login";
import { ClubService } from "../../app/service/club.service";
import FacebookLogin from "react-facebook-login";
import { Checkbox, FormControlLabel } from "@mui/material";
import { FACEBOOK_APP_ID, GOOGLE_APP_ID } from "../../config/constant";
import { TooltipWrapper } from "../tooltip";
import Timer from "react-compound-timer";

interface Props {
  UserReducer?: any;
  isLogin?: boolean;
  LoginPopupReducer?: any;
  loginPopup?: (data: any) => void;
  login?: (data: any) => void;
  loginUserId?: (data: any) => void;
  loading?: (data: any) => void;
}

const Login: React.FC<Props> = (props) => {
  const [validationErrors, setValidationErrors] = useState<FormControlError[]>(
    []
  );
  const [showUserLogin, setShowUserLogin] = useState(true)
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const [loginData, setLoginData] = useState<any>({});
  const [showPassword, setShowPassword] = useState<any>(false);
  const [disabledSendOtp, setDisabledSendOtp] = useState<any>(false);
  const [showResendOtp, setShowResendOtp] = useState<any>(false);
  const [isOtpValid, SetIsOtpValid] = useState<any>("");
  const [mobileNumber, SetMobileNumber] = useState<any>("");
  const [addMobileNumber, SetAddMobileNumber] = useState<any>("");
  const [facebookEmail, setFacebookEmail] = useState<any>();
  const [showfacebookEmail, setShowFacebookEmail] = useState<any>(false);
  const [creatorEmail, SetCreatrorEmail] = useState<any>(facebookEmail);
  const [mobileNumberInput, setMobileNumberInput] = useState<any>(true);
  const [email, SetEmail] = useState<any>("");
  const [socialMediaData, SetSocialMediaData] = useState<any>({});
  const [otpResponse, SetOtpResponse] = useState<any>({});
  const [showMobileNumberView, SetShowMobileNumberView] = useState<any>(false);
  const [thankyoupage, setThankyouPage] = useState<any>(false)
  const phone_number: any = useRef(null);
  const mobileInput: any = useRef(null);
  const location = useLocation();
  console.log('location', location?.pathname);
  const tiRef:any = useRef(null);
 
  const formValidations = [
    new FormField("phone_number", [FormValidators.REQUIRED]),
    new FormField("country_code", [FormValidators.REQUIRED]),
    // new FormField("email", [FormValidators.REQUIRED]),
  ];
  const handleClose = () => {
    if (props.loginPopup) {
      const data = { loginPopup: false, popupType: "" };
      dispatch(props.loginPopup(data));
    }
  };


  const [isUserLogin, setUserLogin] = useState(
    sessionStorage.getItem("isLogin") || ""
  );
  const getEmailValid = (control: string) => {
    const data = { ...loginData.value };
    const value = GetEmailControlIsValid(validationErrors, control, data);
    return value;
  };

  const getMobileValid = (control: string) => {
    const data = { ...loginData.value };
    const value = GetMobileControlIsValid(validationErrors, control, data);
    return value;
  };

  const getInputValid = (control: string) => {
    const value = GetControlIsValid(validationErrors, control);
    return value;
  };

  const [conformationPopupText, setConformationPopupText] = useState<any>();

  useEffect(() => {
    if (mobileInput.current) {
      mobileInput.current.focus();
    }
    const id = sessionStorage.getItem("isLogin") || null;
    if (props.loginUserId && id) {
      dispatch(props.loginUserId(id));
    }
    console.log(props.LoginPopupReducer.loginPopup?.popupType)
   
   
  }, []);

  const handleLoginClick = () => {
    setLoading(true);
    setIsFormSubmitted(true);
    const data = { ...loginData.value };
    const errors: FormControlError[] = FormValidator(formValidations, data);
    const providers = {
      eventData: loginData.value,
      provider: "local",

    };
    setValidationErrors(errors);
    if (errors.length < 1 && !getEmailValid("email")) {
      UserService.loginUser(providers).then((res) => {
        setLoading(false);
        if (res) {
          if (props.login) {
            dispatch(props.login(res));
            sessionStorage.setItem(
              "userData",
              JSON.stringify(props.UserReducer)
            );
          }
          sessionStorage.setItem("loginUserImage", res[0]?.image || "");
          setIsFormSubmitted(false);
          const _id = res[0]?._id || "";
          setUserLogin(_id);
          sessionStorage.setItem("isLogin", _id);
          localStorage.setItem("isLogin", _id);
          if (props.loginUserId) {
            dispatch(props.loginUserId(_id));
          }
          if (_id) {
            UserService.checkUserClubsPostsByUserID(_id).then((resData) => {
              if (resData?.status) {
                // if (
                //   resData?.navigation === "../dashboard/posts/published" ||
                //   resData?.navigation === "/dashboard/mysubscriptions/posts"
                // ) {
                history.push(resData?.navigation);
                handleClose();
                // } else {
                //   setConformationPopupText(resData);
                // }
              } else {
                handleClose();
              }
            });
          }
        } else {
          toast.error("Invalid credentials");
          setIsFormSubmitted(false);
        }
      });
    } else {
      setLoading(false);
    }
  };

  const updateFormData = (data: any) => {
    setLoginData(data);
    const errors: FormControlError[] = FormValidator(
      formValidations,
      data.value
    );
    setValidationErrors(errors);
  };
  const onConformation = () => {
    handleClose();
    history.push("/livestreams/creator-livestream");
  };
  const onLoginChange = (event: any) => {
    if (event.key === "Enter") {
      // handleLoginClick();
      onLoginWithOtp()
      event.preventDefault();
    }
  };
  const onLoginChangeCreator = (event: any) => {
    if (event.key === "Enter") {
      // handleLoginClick();
      onSaveMobilenumber();
      event.preventDefault();
    }
  };

  const onEmailChange = (event: any) => {
    SetEmail(event.target.value);
    if (event.key === "Enter") {
      handleLoginClick();
    }
  };

  const onMobileNumberChange = (event: any) => {
    SetMobileNumber(event.target.value);
    if (event.keyCode === 13) {
      event.preventDefault();
      event.stopPropagation();
    }
    else
      if (event.key === "Enter") {
        showOTPInput()
      }
  };

  const onAddMobileNumberChange = (event: any) => {
    SetAddMobileNumber(event.target.value);
  };

  const responseFacebook = (event: any) => {
    const eventData = event;
    const providers: any = {
      eventData,
      ...{ provider: "facebook" },
    };
    if (creatorModal) {
      providers.club_influencer = true;
    }

    ;
    
   
    setFacebookEmail(eventData.email === undefined ? "" : eventData.email);
    SetSocialMediaData(providers);
    isMobileNumberExist(providers);
    // isMobileNumberExist();

    // login(providers);
  };

  const responseGoogle = (event: any) => {
    const eventData = event;
    const providers = {
      eventData,
      ...{ provider: "google" },
    };
    if (event?.googleId) {
      SetSocialMediaData(providers);
      isMobileNumberExist(providers);
      // login(providers);
    }
  };

  const onSaveMobilenumber = () => {
    const data = {
      phone_number: addMobileNumber,
    };
    const OTP = { ...loginData.value };
    // UserService.checkMobileNumberExists(data).then((resData) => {
    //   console.log("resData", resData);
    //   if (resData?.code === 400) {
    //     toast.error(resData.message);
    //   } else {
        const newData = socialMediaData;
        newData.phone_number = addMobileNumber;
        newData.otp = OTP.otp;
        newData.country_code = "+91";
        login(newData);
        console.log(newData);
    //   }
    // });
  };

  const isMobileNumberExist = (providers: any) => {
    const data = {
      email: providers?.eventData?.email,
    };
    UserService.checkMobileNumberExistsWithAccount(data).then((resData) => {
      console.log("resData", resData);
      if (resData) {
        SetShowMobileNumberView(true);
      } else {

        SetShowMobileNumberView(true);

      }
    });
  };

  const [creatorModal, setCreatorModal] = useState(false);
  const [userModal, setUserModal] = useState(false);
  const showSignupModal = () => {
    setCreatorModal(!creatorModal);
  };
  const onBackToLogin = () => {
    onPopupType("login");
    showSignupModal();
    setShowUserLogin(true)
  };
  const onBackToEmail = () => {
    SetOTPInputVisible(false);
  };
  const showUserModal = () => {
    setUserModal(!userModal);
  };
  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const [OTPInputVisible, SetOTPInputVisible] = useState(false);



  const showOTPInput = (): void => {
    setIsFormSubmitted(true);
    // setMobileNumberInput(false);
    const data = { ...loginData.value };
    data.country_code = data.country_cod ? data.country_cod : "+91";
    const errors: FormControlError[] = FormValidator(formValidations, data);
    setValidationErrors(errors);
    // !getEmailValid("email") &&'
    // console.log(data);
    if (errors.length < 1 && !getMobileValid("phone_number")) {
      UserService.sendOtp(data).then((res) => {
        if (res?.code === 400) {
          toast.error(res.message);
          SetOTPInputVisible(false);
        } else {
          SetOTPInputVisible(true);
          setDisabledSendOtp(true);
          SetOtpResponse({ ...res });
          toast.success(res?.message);
          if (res?.isEmailExists) {
            // console.log("acha");
            // setThankyouPage(false)
            loginData.value.email = res?.email;
          } else {
            console.log("acha");

            setThankyouPage(true)
            formValidations.push(
              new FormField("email", [FormValidators.REQUIRED])
            );
          }
          formValidations.push(new FormField("otp", [FormValidators.REQUIRED]));
        }
      });
      setTimeout(() => {
        setDisabledSendOtp(false);
        setShowResendOtp(true);
      }, 30000);
    }
  };

  const resendOtp=()=>{
    const data = { ...loginData.value };
    data.country_code = data.country_cod ? data.country_cod : "+91";
    console.log(data);
    setShowResendOtp(false);
    tiRef.current.reset()
    tiRef.current.start()
    UserService.sendOtp(data).then((res) => {
      console.log(res);
      // SetOtpResponse({ ...res });
    })
    setTimeout(() => {
      
      setShowResendOtp(true);
    }, 30000);
  }
  const onLoginWithOtp = () => {
    setIsFormSubmitted(true);
    const data = { ...loginData.value };
    data.country_code = data.country_cod ? data.country_cod : "+91";
    data.email = data.email ? data.email.toLowerCase() : "";
    data.is_finance = false;

    const errors: FormControlError[] = FormValidator(formValidations, data);
    setValidationErrors(errors);
    console.log(errors);

    if (
      errors.length < 1 &&
      !getEmailValid("email") &&
      !getMobileValid("phone_number") &&
      data.otp
    ) {
      UserService.verifyOtp(data).then((res) => {
        console.log(res)

       
        
        toast.success(res?.message);
        if(res){
        if (props.login) {
          dispatch(props.login(res));
          sessionStorage.setItem("userData", JSON.stringify(props.UserReducer));
          localStorage.setItem("userData", JSON.stringify(props.UserReducer));
        }
        sessionStorage.setItem("loginUserImage", res[0]?.image || "");
        setIsFormSubmitted(false);
        const _id = res[0]?._id || "";
        setUserLogin(_id);
        localStorage.setItem("token", res[0]?.token || "");
        sessionStorage.setItem("isLogin", _id);
        localStorage.setItem("isLogin", _id);
        if (props.loginUserId) {
          dispatch(props.loginUserId(_id));
        }
        if (props.loading) {
          dispatch(props.loading(false));
        }
        if (_id) {
          ClubService.getUserClubs(_id).then((res) => {
            console.log(res, "another one")
            if (res) {
              sessionStorage.setItem("clubId", res[0]?._id || "");
            }
          });
         
          UserService.checkUserClubsPostsByUserID(_id).then((resData) => {
            console.log(resData)
            console.log(thankyoupage)
            if (resData?.status) {
              console.log(resData)
              thankyoupage ? history.push(`/welcome-page/${res[0]?._id}`) : history.push('/');
              // history.push(location?.pathname);
              // history.push(`/welcome-page/${res[0]?._id}`)
              handleClose();
            } else {
              thankyoupage ? history.push(`/welcome-page/${res[0]?._id}`) : history.push('/');
              handleClose();
            }

          });
        
        }
      }else{
        toast.error("Invalid/Incorrect otp")
      }
      });
      
    } else {
      if (!data.otp) {
        toast.error("Please verify your mobile number with otp");
        console.log(toast.error)
      }
    }
  };

  const login = (data: any) => {
    if (props.loading) {
      dispatch(props.loading(true));
    }

    UserService.loginUser(data).then((res) => {
      setLoading(false);
      console.log(res)
      if (res) {
        if (props.login) {
          dispatch(props.login(res));
          sessionStorage.setItem("userData", JSON.stringify(props.UserReducer));
        }
        sessionStorage.setItem("loginUserImage", res[0]?.image || "");
        setIsFormSubmitted(false);
        const _id = res[0]?._id || "";
        setUserLogin(_id);
        localStorage.setItem("token", res[0]?.token || "");
        sessionStorage.setItem("isLogin", _id);
        localStorage.setItem("isLogin", _id);
        if (props.loginUserId) {
          dispatch(props.loginUserId(_id));
        }
        if (props.loading) {
          dispatch(props.loading(false));
        }
        if (_id) {
          ClubService.getUserClubs(_id).then((res) => {
            console.log(res, "anything")
            if (res) {
              sessionStorage.setItem("clubId", res[0]?._id || "");
            }
          });
          UserService.checkUserClubsPostsByUserID(_id).then((resData) => {
            if (resData?.status) {
              // history.push(location?.pathname);
              thankyoupage ? history.push(`/welcome-page/${res[0]?._id}`) : history.push('/');
              handleClose();
            } else {
              thankyoupage ? history.push(`/welcome-page/${res[0]?._id}`) : history.push('/');
              handleClose();
            }
          });
        }
      } else {
        toast.error("Invalid credentials");
        setIsFormSubmitted(false);
        if (props.loading) {
          dispatch(props.loading(false));
        }
      }
    });
  };
  const onPopupType = (type: string) => {
    if (type === "singup") {
      showSignupModal();
      setShowUserLogin(!showUserLogin)
    }
    // if (props.loginPopup) {
    //   // showUserModal(false)
    //   const data = { loginPopup: false, popupType: type };
    //   dispatch(props.loginPopup(data));
    // }
  };

  const handleKeypress = (e: any) => {
    if (e.key === "Enter") {
      showOTPInput();
      e.preventDefault();
    }
    // do stuff
  };

  return (
    <div className="loginPage-background">
      {/* <Modal
        show={props.LoginPopupReducer.loginPopup?.loginPopup}
        onHide=background{handleClose}
        centered
        dialogClassName="sign-up-modal login-modal"
        style={{ zIndex: "9999" }}
      > */}
        {/* <Modal.Header closeButton></Modal.Header>
        <Modal.Body> */}
        
          {conformationPopupText?.status ? (
            <div className="auth-container wel-msg">
              <div className="auth-sec auth-right-sec">
                <div className="auth-info">
                  <h2>Welcome</h2>
                </div>
                <p>
                  This is your first step towards the Clan of content. Customize
                  and curate content for your world and there will be no bar to
                  your revenue. Let's get the best content out of our sleeves
                  for our Clan!
                </p>
                <button
                  className="btn btn-primary"
                  type="button"
                  onClick={onConformation}
                >
                  Get Started
                </button>
              </div>
            </div>
          ) : (
            <div className="auth-container login-page">
              <div className="auth-sec auth-right-sec">
                <FormBuilder onUpdate={updateFormData} >
                  <form className="top-space-login pl-3 pr-3 pt-5">
                    <div className="auth-info text-center">
                      {/* {props.LoginPopupReducer.loginPopup?.popupType === */}
                        {/* "login" ? ( */}
                        <>
                        
                          <h4>Log in to Clan World</h4>
                          {!OTPInputVisible && <span>Welcome</span>}
                          {creatorModal===false && otpResponse?.isEmailExists && (
                            <span>
                              {" "}
                              Email:{" "}
                              <strong className="d-inline-flex">
                                {otpResponse?.email}
                              </strong>
                            </span>
                          )}
                          {OTPInputVisible && (
                            <a
                              href="javascript:void(0)"
                              onClick={() => onBackToEmail()}
                              className="back-arrow"
                            >
                              <i className="bi bi-arrow-left-circle" style={{color:"white"}}></i>
                            </a>
                          )}
                        </>
                      {/* ) : (
                        <>
                          <span>
                            {creatorModal || userModal
                              ? "Sign up"
                              : "Welcome To"}
                          </span>
                          <h4>
                            {creatorModal
                              ? "Creator"
                              : userModal
                                ? "User"
                                : "Clan World"}
                          </h4>
                        </>
                      )} */}
                    </div>
                    <div className="sign-up-social">
                      {showUserLogin   ? ( 
                        
                        <div className="social-btn text-center">
                          <strong className="mb-3 d-block">
                            {!OTPInputVisible
                              ? "Login with OTP"
                              : "OTP Verification"}
                          </strong>
                          <>
                            {!OTPInputVisible && (
                              <>
                                {/* <div className="form-floating mb-3 w-100">
                                  <input
                                    type="text"
                                    className={`form-control mobileOTP ${isFormSubmitted &&
                                      (!getInputValid("email") ||
                                        getEmailValid("email"))
                                      ? "error"
                                      : ""
                                      }`}
                                    id="exampleInputEmail1"
                                    name="email"
                                    aria-describedby="emailHelp"
                                    placeholder="Email"
                                    value={email}
                                    onChange={(event) => onEmailChange(event)}
                                  />
                                  {isFormSubmitted &&
                                    !getInputValid("email") ? (
                                    <label
                                      className="clr-red"
                                      htmlFor="exampleInputEmail1"
                                    >
                                      Enter Email
                                    </label>
                                  ) : isFormSubmitted &&
                                    getEmailValid("email") ? (
                                    <label
                                      className="clr-red"
                                      htmlFor="exampleInputEmail1"
                                    >
                                      Enter Valid Email
                                    </label>
                                  ) : (
                                    <label
                                      className="clr-black"
                                      htmlFor="exampleInputEmail1"
                                    >
                                      Email
                                    </label>
                                  )}
                                </div> */}
                                <div className="d-flex">
                                  <div className="form-floating mb-3 w-100 otp-field">
                                    <select
                                      name="country_code"
                                      id="country_code"
                                      className={`form-control px-2 mobileOTP`}
                                    >
                                      <option value="">Country</option>
                                      <option value="+91" selected>
                                        +91
                                      </option>
                                      <option value="+1">+1</option>
                                    </select>
                                    {/* <label
                                      className="clr-black"
                                      htmlFor="exampleInputEmail1"
                                    >
                                      Country
                                    </label> */}
                                    <input

                                      type="tel"
                                      className={`form-control mobileOTP ${isFormSubmitted &&
                                          (!getInputValid("phone_number") ||
                                            getMobileValid("phone_number"))
                                          ? "error"
                                          : ""
                                        }`}
                                      id="exampleInputEmail1"
                                      name="phone_number"
                                      aria-describedby="emailHelp"
                                      placeholder="Mobile"
                                      onChange={(event) =>
                                        onMobileNumberChange(event)
                                      }
                                      value={mobileNumber}
                                      ref={mobileInput}
                                      onKeyDown={(e) => {
                                        handleKeypress(e)

                                      }

                                      }

                                    />

                                    {isFormSubmitted &&
                                      !getInputValid("phone_number") ? (
                                      <label
                                        className="clr-red mobile-error"
                                        htmlFor="exampleInputEmail1"
                                      >
                                        Enter Mobile
                                      </label>
                                    ) : isFormSubmitted &&
                                      getMobileValid("phone_number ") ? (
                                      <label
                                        className="clr-red mobile-error"
                                        htmlFor="exampleInputEmail1"
                                      >
                                        Enter Valid Mobile
                                      </label>
                                    ) : (
                                      <label
                                        className="clr-black mobile-error"
                                        htmlFor="exampleInputEmail1"
                                      >
                                        Mobile
                                      </label>
                                    )}
                                  </div>
                                </div>
                              </>
                            )}
                            {OTPInputVisible && (
                              <>
                                <p style={{marginBottom:"2rem"}}>
                                  OTP sent to{" "}
                                  <strong className="d-inline-flex">
                                    +91-{mobileNumber}
                                  </strong>
                                  <a
                                    href="javascript:void(0)"
                                    onClick={() => onBackToEmail()}
                                    className="fs-xsml link-effect"
                                    style={{color:"white"}}
                                  >
                                    Change
                                  </a>
                                </p>
                              </>
                            )}
                            {OTPInputVisible && (
                              <div className="text-start align-self-start w-100">
                                {!otpResponse?.isEmailExists && (
                                  <div className="form-floating mb-3 w-100">
                                    <input
                                      type="text"
                                      className={`form-control mobileOTP ${isFormSubmitted &&
                                          (!getInputValid("email") ||
                                            getEmailValid("email"))
                                          ? "error"
                                          : ""
                                        }`}
                                      id="exampleInputEmail1"
                                      name="email"
                                      aria-describedby="emailHelp"
                                      placeholder="Email"
                                      value={email}
                                      onChange={(event) => onEmailChange(event)}
                                      autoFocus
                                    />
                                    {isFormSubmitted &&
                                      !getInputValid("email") ? (
                                      <label
                                        className="clr-red email-label mobile-error"
                                        htmlFor="exampleInputEmail1"
                                      >
                                        Enter Email
                                      </label>
                                    ) : isFormSubmitted &&
                                      getEmailValid("email") ? (
                                      <label
                                        className="clr-red email-label mobile-error"
                                        htmlFor="exampleInputEmail1"
                                      >
                                        Enter Valid Email
                                      </label>
                                    ) : (
                                      <label
                                        className="clr-black email-label mobile-error"
                                        htmlFor="exampleInputEmail1"
                                      >
                                        Email
                                      </label>
                                    )}
                                  </div>
                                )}
                              </div>
                            )}
                            {OTPInputVisible && (
                              <div
                                className={
                                  OTPInputVisible
                                    ? "form-floating w-100 mb-3"
                                    : "form-floating w-100"
                                }
                              >
                                <strong className="text-start">
                                  {isFormSubmitted && !getInputValid("otp")
                                    ? "Enter Correct OTP"
                                    : "Enter OTP"}
                                </strong>
                                <div className="enterOTPrecdOuter">
                                  <div className="enterOTPrecdInner">
                                    <input
                                      type="tel"
                                      className={`form-control enterOTPrecd ${isFormSubmitted && !getInputValid("otp")
                                          ? "error"
                                          : ""
                                        }`}
                                      id="exampleInputEmail1"
                                      name="otp"
                                      aria-describedby="emailHelp"
                                      maxLength={6}
                                      onChange={(event) =>
                                        SetIsOtpValid(event.target.value)
                                      }
                                      onKeyDown={(event) =>
                                        onLoginChange(event)
                                      }
                                      value={isOtpValid}
                                      autoFocus
                                    />
                                  </div>
                                </div>
                              </div>
                            )}
                            {!OTPInputVisible && (
                              <button
                                type="button"
                                className="btn btn-black btn-sm appearance"
                                onClick={showOTPInput}
                              // onKeyDown={handleKeypress}
                              >
                                Send OTP

                              </button>
                            )}
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "end",
                                width: "100%",
                              }}
                            >
                              {showResendOtp && OTPInputVisible && (
                                <button
                                  disabled={
                                    disabledSendOtp ||
                                    !getInputValid("phone_number") ||
                                    getMobileValid("phone_number")
                                  }
                                  className="resend-otp-link mb-3 fs-xsml"
                                  onClick={resendOtp}
                                >
                                  Resend OTP
                                </button>
                              )}
                              {OTPInputVisible && (
                                <div className="d-inline-flex resend-otp-link mb-3 fs-xsml">
                                  <Timer
                                    initialTime={30000}
                                    direction="backward"
                                    ref={tiRef}
                                    formatValue={(value: any) =>
                                      `${value < 10 ? `0${value}` : value}  `
                                    }
                                  
                                  >
                                    {() => (
                                      <React.Fragment>
                                        <Timer.Minutes />:
                                        <Timer.Seconds />
                                      </React.Fragment>
                                    )}
                                  </Timer>
                                </div>
                              )}
                            </div>
                            {OTPInputVisible && (
                              <>
                                {isOtpValid.length !== 6 ? (
                                  <button
                                    className="btn btn-black verify"
                                    disabled={
                                      isOtpValid.length !== 6 ? true : false
                                    }
                                    
                                    onClick={onLoginWithOtp}
                                  >
                                    Verify
                                  </button>
                                ) : (
                                  <span
                                    className="btn btn-black"
                                    onClick={onLoginWithOtp}
                                  >
                                    Verify
                                  </span>
                                )}
                              </>
                            )}
                            {!OTPInputVisible && (
                              <span className="auth-sec-already-member">
                                &nbsp;
                                <a
                                  className=""
                                  onClick={() => onPopupType("singup")}
                                >
                                  Register
                                </a>
                                &nbsp; as Creator
                                {/* <TooltipWrapper
                                  placement="top"
                                  tooltipText="information"
                                  iconName="bi-info-circle"
                                /> */}
                              </span>
                            )}
                          </>
                        
                        </div>
                        
                      ) : ( 
                        <div>
                          {console.log(
                            "showMobileNumberView",
                            showMobileNumberView
                          )}
                          {!showMobileNumberView && (
                            <div className="social-btn">
                              <div
                                className={[
                                  "sec-signup-creator",
                                  creatorModal ? "active" : "",
                                ].join(" ")}
                              >
                                <a
                                  href="javascript:void(0)"
                                  onClick={() => onBackToLogin()}
                                  className="back-arrow"
                                >
                                  <i className="bi bi-arrow-left-circle"></i>
                                </a>
                                <GoogleLogin
                                  clientId={GOOGLE_APP_ID}
                                  buttonText="Signup with Google"
                                  onSuccess={responseGoogle}
                                  onFailure={responseGoogle}
                                  className="btn btn-outline-black"
                                />
                                <FacebookLogin
                                  appId={FACEBOOK_APP_ID}
                                  autoLoad={false}
                                  fields="name,email,picture"
                                  callback={responseFacebook}
                                  icon="fa-facebook"
                                  textButton="Signup with facebook"
                                  cssClass="btn btn-outline-black"
                                />
                              </div>
                              <div
                                className={[
                                  "sec-signup-user",
                                  userModal ? "active" : "",
                                ].join(" ")}
                              >
                                <a
                                  href="javascript:void(0)"
                                  onClick={() => setUserModal(false)}
                                  className="back-arrow"
                                >
                                  <i className="bi bi-arrow-left-circle"></i>
                                </a>
                                <div className="social-btn text-center">
                                  <GoogleLogin
                                    className="btn btn-outline-black"
                                    clientId={GOOGLE_APP_ID}
                                    buttonText="Login with Google"
                                    onSuccess={responseGoogle}
                                    onFailure={responseGoogle}
                                  />
                                  <FacebookLogin
                                    appId={FACEBOOK_APP_ID}
                                    autoLoad={false}
                                    fields="name,email,picture"
                                    callback={responseFacebook}
                                    cssClass="btn btn-outline-black"
                                    icon="fa-facebook"
                                  />
                                  <strong className="login-other">
                                    <span>or</span>
                                  </strong>
                                  <strong className="mb-3 d-block">
                                    Login with OTP
                                  </strong>
                                  <>
                                    <div className="form-floating mb-3 w-100 otp-field">
                                      <input
                                        type="tel"
                                        className={`form-control ${isFormSubmitted &&
                                            (!getInputValid("email") ||
                                              getEmailValid("email"))
                                            ? "error"
                                            : ""
                                          }`}
                                        id="exampleInputEmail1"
                                        name="email"
                                        aria-describedby="emailHelp"
                                        placeholder="Email"
                                        onKeyDown={(event) =>
                                          onLoginChange(event)
                                        }
                                      />
                                      <button
                                        type="button"
                                        className="btn btn-outline-black d-inline-flex w-auto btn-sm"
                                        onClick={showOTPInput}
                                      >
                                        Send OTP
                                      </button>
                                      {isFormSubmitted &&
                                        !getInputValid("email") ? (
                                        <label
                                          className="clr-red mobile-error"
                                          htmlFor="exampleInputEmail1"
                                        >
                                          Enter Mobile
                                        </label>
                                      ) : isFormSubmitted &&
                                        getEmailValid("email") ? (
                                        <label
                                          className="clr-red mobile-error"
                                          htmlFor="exampleInputEmail1"
                                        >
                                          Enter Valid Mobile
                                        </label>
                                      ) : (
                                        <label
                                          className="clr-black mobile-error"
                                          htmlFor="exampleInputEmail1"
                                        >
                                          Mobile
                                        </label>
                                      )}
                                    </div>
                                    {OTPInputVisible && (
                                      <div className="form-floating mb-3 w-100 otp-field">
                                        <input
                                          type="tel"
                                          className={`form-control mobileOTP ${isFormSubmitted &&
                                              (!getInputValid("email") ||
                                                getEmailValid("email"))
                                              ? "error"
                                              : ""
                                            }`}
                                          id="exampleInputEmail1"
                                          name="email"
                                          aria-describedby="emailHelp"
                                          placeholder="Mobile"
                                          onKeyDown={(event) =>
                                            onLoginChange(event)
                                          }
                                        />
                                        {isFormSubmitted &&
                                          !getInputValid("email") ? (
                                          <label
                                            className="clr-red"
                                            htmlFor="exampleInputEmail1"
                                          >
                                            Enter OTP
                                          </label>
                                        ) : isFormSubmitted &&
                                          getEmailValid("email") ? (
                                          <label
                                            className="clr-red"
                                            htmlFor="exampleInputEmail1"
                                          >
                                            Enter Valid OTP
                                          </label>
                                        ) : (
                                          <label
                                            className="clr-black"
                                            htmlFor="exampleInputEmail1"
                                          >
                                            OTP
                                          </label>
                                        )}
                                      </div>
                                    )}
                                    <span>
                                      <FormControlLabel
                                        className="me-1"
                                        control={<Checkbox />}
                                        label="I agree to"
                                      />
                                      <Link to="">Terms &amp; Conditions</Link>
                                    </span>
                                    <Link
                                      to="/signup/laststep"
                                      className="mt-4 btn btn-black"
                                    >
                                      Get Started
                                    </Link>
                                  </>
                                </div>
                              </div>
                            </div>
                          )}
                          {showMobileNumberView && (
                            <div>
                              {console.log(facebookEmail)}
                              {!OTPInputVisible && (
                                <span
                                  id="influencer_mail"
                                  style={{
                                    display: "block",
                                    marginBottom: "15px",
                                  }}
                                >
                                  Email :{" "}
                                  <strong className="d-inline-flex">
                                    {facebookEmail}{" "}
                                  </strong>
                                </span>
                              )}
                              {!OTPInputVisible && (
                                <div className="d-flex">
                                  <div className="form-floating mb-3 w-100 otp-field">
                                    <select
                                      name="country_code"
                                      id="country_code"
                                      className={`form-control px-2 mobileOTP`}
                                    >
                                      <option value="">Country</option>
                                      <option value="+91" selected>
                                        +91
                                      </option>
                                      <option value="+1">+1</option>
                                    </select>
                                    {/* <label
                                      className="clr-black"
                                      htmlFor="exampleInputEmail1"
                                    >
                                      Country
                                    </label> */}
                                    {/* {otpResponse?.isEmailExists && (
                                   <span>
                                     {" "}
                                     Email:{" "}
                                     <strong className="d-inline-flex">
                                       {otpResponse?.email}
                                     </strong>
                                   </span>
                                 )} */}
                                    <input
                                      type="tel"
                                      className={`form-control mobileOTP ${isFormSubmitted &&
                                          (!getInputValid("phone_number") ||
                                            getMobileValid("phone_number"))
                                          ? "error"
                                          : ""
                                        }`}
                                      id="exampleInputEmail1"
                                      name="phone_number"
                                      aria-describedby="emailHelp"
                                      placeholder="Mobi"
                                      onChange={(event) =>
                                        onAddMobileNumberChange(event)
                                      }
                                      ref={phone_number}
                                      value={addMobileNumber}
                                      onKeyDown={(e) => { handleKeypress(e) }}
                                      autoFocus
                                    />

                                    {isFormSubmitted &&
                                      !getInputValid("phone_number") ? (
                                      <label
                                        className="clr-red mobile-error"
                                        htmlFor="exampleInputEmail1"
                                      >
                                        Enter Mobile
                                      </label>
                                    ) : isFormSubmitted &&
                                      getMobileValid("phone_number") ? (
                                      <label
                                        className="clr-red mobile-error"
                                        htmlFor="exampleInputEmail1"
                                      >
                                        Enter Valid Mobile
                                      </label>
                                    ) : (
                                      <label
                                        className="clr-black mobile-error"
                                        htmlFor="exampleInputEmail1"
                                      >
                                        Mobile
                                      </label>
                                    )}
                                  </div>
                                </div>
                              )}

                              {/* <button
                               type="button"
                               className="btn btn-outline-black"
                               onClick={onSaveMobilenumber}
                             >
                               Save
                             </button> */}
                              {/* {!OTPInputVisible && (
                             <button
                               type="button"
                               className="btn btn-black btn-sm"
                               onClick={showOTPInput}
                               onKeyPress={handleKeypress}
                             >
                               Send OTP
                             </button>
                           )}
                           {showResendOtp && OTPInputVisible && (
                             <button
                               disabled={
                                 disabledSendOtp ||
                                 !getInputValid("phone_number") ||
                                 getMobileValid("phone_number")
                               }
                               className="resend-otp-link mb-3 fs-xsml"
                               onClick={showOTPInput}
                             >
                               Resend OTP
                             </button>
                           )} */}
                              {OTPInputVisible && (
                                <>
                                  <span className="mb-3 d-block">
                                    Email:{" "}
                                    <strong className="d-inline-flex">
                                      {facebookEmail}
                                    </strong>
                                  </span>
                                  <span
                                    className="d-block mb-1"
                                    style={{ textAlign: "center" }}
                                  >
                                    <strong className="">
                                      OTP Verification
                                    </strong>
                                  </span>
                                </>
                              )}
                              {OTPInputVisible && (
                                <>
                                  <p>
                                    OTP sent to{" "}
                                    <strong className="d-inline-flex">
                                      +91-{addMobileNumber}
                                    </strong>
                                    <a
                                      href="javascript:void(0)"
                                      onClick={() => onBackToEmail()}
                                      className="fs-xsml link-effect"
                                      style={{color:"white"}}
                                    >
                                      Change
                                    </a>
                                  </p>
                                </>
                              )}
                              {OTPInputVisible && (
                                <div
                                  className={
                                    OTPInputVisible
                                      ? "form-floating w-100 mb-3"
                                      : "form-floating w-100"
                                  }
                                >
                                  <strong className="text-start">
                                    {isFormSubmitted && !getInputValid("otp")
                                      ? "Enter Correct OTP"
                                      : "Enter OTP"}
                                  </strong>
                                  <div className="enterOTPrecdOuter">
                                    <div className="enterOTPrecdInner">
                                      <input
                                        type="tel"
                                        className={`form-control enterOTPrecd ${isFormSubmitted &&
                                            !getInputValid("otp")
                                            ? "error"
                                            : ""
                                          }`}
                                        id="exampleInputEmail1"
                                        name="otp"
                                        aria-describesend="emailHelp"
                                        maxLength={6}
                                        onChange={(event) =>
                                          SetIsOtpValid(event.target.value)
                                        }
                                        onKeyDown={(event) =>
                                          onLoginChangeCreator(event)
                                        }
                                        value={isOtpValid}
                                        autoFocus
                                      />
                                    </div>
                                  </div>
                                </div>
                              )}
                              {!OTPInputVisible && (
                                <button
                                  type="button"
                                  className="btn btn-black btn-sm appearance"
                                  onClick={showOTPInput}

                                >
                                  Send OTP
                                </button>
                              )}
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "end",
                                  width: "100%",
                                }}
                              >
                                {showResendOtp && OTPInputVisible && (
                                  <button
                                    disabled={
                                      disabledSendOtp ||
                                      !getInputValid("phone_number") ||
                                      getMobileValid("phone_number")
                                    }
                                    className="resend-otp-link mb-3 fs-xsml"
                                    onClick={resendOtp}
                                  >
                                    Resend OTP
                                  </button>
                                )}
                                {OTPInputVisible && (
                                  <div className="d-inline-flex resend-otp-link mb-3 fs-xsml">
                                    <Timer
                                      initialTime={30000}
                                      direction="backward"
                                      ref={tiRef}
                                      formatValue={(value: any) =>
                                        `${value < 10 ? `0${value}` : value}  `
                                      }
                                    >
                                      {() => (
                                        <React.Fragment>
                                          <Timer.Minutes />:
                                          <Timer.Seconds />
                                        </React.Fragment>
                                      )}
                                    </Timer>
                                  </div>
                                )}
                              </div>{" "}
                              {/* {showcounter && (
                             <span className="resend-otp-link mb-3 fs-xsml">00:{seconds}</span>
                           )} */}
                              {OTPInputVisible && (
                                <>
                                  {isOtpValid.length !== 6 ? (
                                    <button
                                      className="btn btn-black verify"
                                      disabled={
                                        isOtpValid.length !== 6 ? true : false
                                      }
                                      onClick={onSaveMobilenumber}
                                    >
                                      Verify
                                    </button>
                                  ) : (
                                    <span
                                      className="btn btn-black verify"
                                      onClick={onSaveMobilenumber}
                                    // onClick={onSaveMobilenumber}
                                    >
                                      Verify
                                    </span>
                                  )}
                                </>
                              )}
                            </div>
                          )}
                        </div>
                      )} 
                    </div>
                  </form>
                </FormBuilder>
              </div>
            </div>
          )}
        {/* </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal> */}
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    UserReducer: state.UserReducer,
    LoginMessage: state.UserReducer.LoginMessage,
    LoginPopupReducer: state.LoginPopupReducer,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    login: (data: any) => dispatch(UserLogin(data)),
    loginPopup: (data: any) => dispatch(LoginPopup(data)),
    loginUserId: (data: any) => dispatch(LoginUserId(data)),
    loading: (data: any) => dispatch(Loading(data)),
  };
};

const connectedNavBar = connect(mapStateToProps, mapDispatchToProps)(Login);
export { connectedNavBar as Login };