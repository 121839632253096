import React, { SyntheticEvent, useEffect, useState } from 'react';

interface Props {
    default?: any;
    onUpdate: (value: any) => void;
    children?: any;
}

export interface FormControl {
    name: string;
}

const FormBuilder: React.FC<Props> = (props: Props) => {
    const [formData, setFormData] = useState<{ value: any, errors: any, isValid: any, isRequired:any }>({ value: props.default || {}, errors: [], isValid: true, isRequired:true });

    const onUpdateForm = (event: SyntheticEvent) => {
        const target: HTMLInputElement = event.target as HTMLInputElement;
        const { value, dataset, name, defaultValue } = target;

        const errors: any = {};
        let isError = false;
        if (dataset.required) {
            if (!value) {
                
                errors[name] = ['required'];
                isError = true;
            }
        }

        const fieldValue = { ...formData.value, [name]: value };
        const fieldErrors = { ...formData.errors, ...errors };

        if (!isError) {
          
            delete fieldErrors[name];
        }

        setFormData({ ...formData, value: fieldValue, errors: fieldErrors, isValid: Object.keys(fieldErrors).length === 0 });
    };

    useEffect(() => {
        if (Object.keys(formData.value).length > 0) {
            props.onUpdate(formData);
        }
    }, [formData]);

    return (
        <div onChange={onUpdateForm}>
            {props.children}
        </div>
    );
};

export default FormBuilder;