import React from "react";
import { Link } from "react-router-dom";

type Props = {};

const RefundPolicy = (props: Props) => {
  return (
    <div className="container pt-5">
      <h3 className="pb-3">Refund Policy</h3>
      <div className="content-info">
        <p>
          This Refund Policy (<strong>“Policy”</strong>) governs any refunds
          made to you on the Clanworld website{" "}
          <Link to="www.clanworld.ai">www.clanworld.ai</Link> (
          <strong>“Website”</strong>), or/and on the Clanworld mobile
          application (<strong>“App”</strong>), (Website and App are hereinafter
          collectively referred to as <strong>“Platform”</strong>). The company{" "}
          <strong>Irida Interactive Private Limited</strong> having its
          registered office at 93, 3rd Floor, Okhla Phase III, Industrial
          Estate, New Delhi, South Delhi, 110020, India (hereinafter referred to
          as <strong>“we”, “our”, “us” and “Company”</strong>) is the sole and
          exclusive owner of the brand Clanworld and the Platform, which is
          owned and registered solely and exclusively in its name. For the
          purpose of this Policy, <strong>“you”, “your”, and “User”</strong>{" "}
          shall be in reference to an individual who purchases a ticket(s) to
          attend a Live Stream event on the Platform.
        </p>
        <p>
          This Policy constitutes the terms and conditions concerning the
          refunds against any ticket purchased on the Platform by the Users
          (when applicable) on the Platform. The Policy along with the
          &quot;Terms &amp; Conditions”, the “Privacy Policy”, the “Pricing
          Policy&quot; and such other policies as may be applicable collectively
          constitute a binding agreement between the User and the Company (
          <strong>“Agreement”</strong>).
        </p>
        <p>
          Unless otherwise specified, the capitalized terms used in this Policy
          will bear the same meanings as ascribed to them in the Agreement. In
          the event of conflict between this Policy and the Terms &amp;
          Conditions or any other agreement, with respect to the refund method
          provided by the Company, the terms of this Policy shall prevail.
        </p>
        <p>
          By registering and creating an account with us, the User is entitled
          to avail or render the Services through the use of the Platform and
          subsequently upon purchase of a ticket for a Live Stream event, you
          will be entitled to enjoy premium access on the Platform of the
          Services during the term as mentioned on the ticket.
        </p>
        <p>
          <strong>Conditions for Refund</strong>
          <br />
          The Company does not allow refunds or cancellation of any ticket,
          except at its own discretion. Refunds may be granted by the Company,
          only in case any event is cancelled by the celebrity. In case the Live
          Stream is cancelled, the Refund will be made automatically within 7
          (seven) days of such notification of cancellation of Live Stream by
          the Company. If the User does not receive the Refund after 7 (seven)
          days from the date of notification, then the User shall make a request
          for refund via at{" "}
          <a href="support@clanworld.ai">support@clanworld.ai.</a> The User,
          within 3 (three) business days, will receive a response email as to
          the status of the refund request by the Company.
        </p>
        <p>
          In the event a refund has been approved by the Company, it may take up
          to 10 (ten) business days for the Company to initiate the refund to
          the original payment source of the User through which the payment at
          the time of ticket purchase was made.
        </p>
        <p>
          The Company may amend this Policy at its sole discretion from time to
          time. By continuing to use the Platform, the User consents to the
          amended terms of this Policy.
        </p>
      </div>
    </div>
  );
};

export default RefundPolicy;
