import React, { useEffect, useState } from "react";
import Banner from "../../assets/img/live-stream.webp";
import ace from "../../assets/img/ace.svg";
import { NavLink, Link } from "react-router-dom";
import FeatureImg1 from "../../assets/img/dummy-img.svg";
import { LivestreamService } from "../../app/service/livestream";
import moment from "moment";
import { useHistory } from 'react-router-dom';
import { googleAnalytics } from '../../app/utils/google-analytics';
import CardPastEvents from "../card-past-events";

interface Props {
  userId: string;
  UserReducer?: any;
  loading?: (data: any) => void;
  LoginUserIdReducer?: any;
}

const OurLivestreamCollection: React.FC<Props> = (props: Props  ) => {
  const [livestream, setLivestream] = useState<any[] | []>([]);
  const history = useHistory();

  let userId =
  sessionStorage.getItem("isLogin") || props?.LoginUserIdReducer?.loginUserId;
  useEffect(() => {
    LivestreamService.getCompletedLivestream("true").then((res) => {
      if (res) {
        setLivestream([...res]);
      }
    });
  }, []);
  const navigateToDetailPage = (data: any) => {
    const googleAnalyticsDataObj = {
      user_id: userId,
      action: 'Clicked on view details in Rerun Section',
      livestream_id: data._id,
      date_and_time: new Date()
    };
    googleAnalytics.loadGoogleAnalytics(googleAnalyticsDataObj);
    history.push(`/livestreams/livestream-detail/${data?._id}`);

  }
  return (
    <section className="livestream-coll-sec">
      <div className="container">
        <div className="livestream-coll-sec-title">
          <h2 className=" mb-3">Past Events</h2>
          <NavLink
            to="/livestreams/livestream-listing"
            className="btn btn-outline-black d-inline-flex btn-wide"
          >
            View all
          </NavLink>
        </div>
        <div className="row">
          {livestream.map((data: any, index: number) => {
            return (
              <div className="col-sm-6 col-lg-4 mb-4">
                <CardPastEvents
                  data={data}
                />
                {/* <div className="d-none d-md-flex card-livestream square">
                  <span
                    className="card-livestream-bgimg"
                    style={{
                      backgroundImage: data?.thumbnail
                        ? `url("${data?.thumbnail}")`
                        : "linear-gradient(to right, #DDEFBB, #FFEEEE)",
                      backgroundPosition: "center center",
                      backgroundSize: "cover",
                    }}
                  ></span>
                  <img className="card-livestream-img" src={data?.thumbnail} alt={data?.title} />
                  <span className="card-livestream-likes-count">
                    <i className="bi bi-heart"></i>18
                  </span>
                  <div className="card-livestream-content">
                    {/* <a
                    href="javascript:void(0)"
                    onClick={() => { }}
                    className="ratio ratio-1x1 mb-3 video-img"
                  >
                    <video
                      poster=""
                      className="my_club_media"
                      controls
                      controlsList="nodownload"
                      src={data?.video_url}
                    >
                      <source src="" type="video/mp4" />
                      Your browser does not support HTML video.
                    </video>
                  </a> 

                    <h3 className="card-livestream-title">
                      <a
                        href="javascript:void(0)"
                        onClick={() => {}}
                        title={data.title}
                      >
                        {data.title}
                      </a>
                    </h3>
                    <span className="dive-in-channel position-relative">
                      @{data?.user_info[0]?.name}
                    </span>
                    <span className="card-livestream-date">
                      {moment(data?.schedule_From).utc().format("ll")}
                    </span>
                    <button onClick={() => navigateToDetailPage(data)}
                      className="btn btn-outline-black btn-rounded"
                    >
                      View Details
                    </button>
                  </div>
                </div> */}
                {/* Mobile View */}
                {/* <Link  to={`/livestreams/livestream-detail/${data?._id}`} className="d-flex d-md-none card-livestream square">
                  <span
                    className="card-livestream-bgimg"
                    style={{
                      backgroundImage: data?.thumbnail
                        ? `url("${data?.thumbnail}")`
                        : "linear-gradient(to right, #DDEFBB, #FFEEEE)",
                      backgroundPosition: "center center",
                      backgroundSize: "cover",
                    }}
                  ></span>
                  <img className="card-livestream-img" src={data?.thumbnail} alt={data?.title} />
                  <span className="card-livestream-likes-count">
                    <i className="bi bi-heart"></i>18
                  </span>
                  <div className="card-livestream-content">
                    {/* <a
                    href="javascript:void(0)"
                    onClick={() => { }}
                    className="ratio ratio-1x1 mb-3 video-img"
                  >
                    <video
                      poster=""
                      className="my_club_media"
                      controls
                      controlsList="nodownload"
                      src={data?.video_url}
                    >
                      <source src="" type="video/mp4" />
                      Your browser does not support HTML video.
                    </video>
                  </a> 

                    <h3 className="card-livestream-title">
                      <a
                        href="javascript:void(0)"
                        onClick={() => {}}
                        title={data.title}
                      >
                        {data.title}
                      </a>
                    </h3>
                    <span className="dive-in-channel position-relative">
                      @{data?.user_info[0]?.name}
                    </span>
                    <span className="card-livestream-date">
                      {moment(data?.schedule_From).utc().format("ll")}
                    </span>
                  </div>
                </Link> */}
              </div>
            );
          })}
          {/* <div className="col-md-4">
            <div
              className="card-livestream"
              style={{
                backgroundImage: `url(${Banner})`,
                backgroundPosition: "center center",
                backgroundSize: "cover",
              }}
            >
              <div className="card-livestream-content">
                <a
                  href="javascript:void(0)"
                  onClick={() => { }}
                  className="ratio ratio-1x1 mb-3 video-img"
                >
                  <video
                    poster=""
                    className="my_club_media"
                    controls
                    controlsList="nodownload"
                  >
                    <source src="" type="video/mp4" />
                    Your browser does not support HTML video.
                  </video>
                </a>

                <h3 className="card-livestream-title">
                  <a href="javascript:void(0)" onClick={() => { }} title="title">
                    Shadows of the past
                  </a>
                </h3>
                <Link to="asdf" className="dive-in-channel position-relative">
                  @amitabhbachchan
                </Link>
                <span className="card-livestream-date">
                  24 Aug, 8:00 pm IST
                </span>
                <Link to="/livestreams/livestream-detail" className="btn btn-outline-black btn-rounded">
                  View Details
                </Link>
              </div>
            </div>
          </div> */}
          {/*   <div className="col-md-4">
            <div
              className="card-livestream"
              style={{
                backgroundImage: `url(${Banner})`,
                backgroundPosition: "center center",
                backgroundSize: "cover",
              }}
            >
              <div className="card-livestream-content">
                <a
                  href="javascript:void(0)"
                  onClick={() => { }}
                  className="ratio ratio-1x1 mb-3 video-img"
                >
                  <video
                    poster=""
                    className="my_club_media"
                    controls
                    controlsList="nodownload"
                  >
                    <source src="" type="video/mp4" />
                    Your browser does not support HTML video.
                  </video>
                </a>

                <h3 className="card-livestream-title">
                  <a href="javascript:void(0)" onClick={() => { }} title="title">
                    Shadows of the past
                  </a>
                </h3>
                <Link to="asdf" className="dive-in-channel position-relative">
                  @amitabhbachchan
                </Link>
                <span className="card-livestream-date">
                  24 Aug, 8:00 pm IST
                </span>
                <a
                  href="javascript:void(0)"
                  className="btn btn-outline-black btn-rounded"
                  onClick={() => { }}
                >
                  View Details
                </a>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </section>
  );
};

export default OurLivestreamCollection;
