import React, { useEffect, useState } from "react";
import Capture from "../../../../assets/img/Capture1.png";
import { Link, NavLink, useHistory } from "react-router-dom";
import { LivestreamService } from "../../../../app/service/livestream";
import moment from "moment";
import { TooltipWrapper } from "../../../../components/tooltip";

interface Props { }

export const MyLivestreamsDraftsPage: React.FC<Props> = (props: Props) => {
  let userId = sessionStorage.getItem("isLogin") || "";
  const [livestream, setLivestream] = useState<any[] | []>([]);
  const history = useHistory();

  useEffect(() => {
    getlivestream();
  }, []);
  const getlivestream = () => {
    LivestreamService.getlivestream("DRAFTS", userId).then((res) => {
      if (res) {
        const data = res.sort((a:any, b:any) => (a.schedule_From < b.schedule_From) ? 1 : ((b.schedule_From < a.schedule_From) ? -1 : 0));

        setLivestream([...res]);
      }
    });
  };
  const onSchedulePublish = (data: any) => {
    LivestreamService.publishLiveStream(data).then((res) => {
      if (res) {
        history.push("/dashboard/mylivestreams/scheduled");
      }
    });
  };
  const onDelete = (data: any) => {
    LivestreamService.deleteLivestream(data).then((res) => {
      if (res) {
        getlivestream();
      }
    });
  };
  return (
    <div className="tab-content" id="pills-tabContent">
      <div
        className="tab-pane active"
        id="details"
        role="tabpanel"
        aria-labelledby="details-tab"
      >
        <div className="livestream-table-view table-responsive">
          <table className="table table-hover">
            <thead>
              <tr>
                <th scope="col"></th>
                <th scope="col">Title</th>
                <th scope="col">Date</th>
                <th scope="col">Time</th>
                <th scope="col">Ticket Price</th>
                <th scope="col">Revenue</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              {/* Card Subscription */}
              {livestream.map((data: any, index: number) => {
                return (
                  <tr key={index}>
                    <td>
                      <img
                        src={data?.thumbnail ? data?.thumbnail : Capture}
                        alt="draft"
                        style={{ height: "40px", width: "40px" }}
                      />
                    </td>
                    <td>
                      {data?.title}
                    </td>
                    <td>
                      {moment(data?.schedule_From).utc().format("MMMM dddd DD")}
                    </td>
                    <td>
                      {moment(data?.schedule_From).utc().format("LT")} -{" "}
                      {moment(data?.schedule_To).utc().format("LT")}
                    </td>
                    <td>INR {data?.price}</td>
                    <td>INR {data?.tickets * data.price}</td>
                    <td className="d-flex">
                      <button
                        className="btn btn-primary"
                        onClick={() => onSchedulePublish(data)}
                      >
                        Publish
                      </button>
                      {data?.type === 1 ? (
                        <NavLink
                          to={`/livestreams/create-livestream/${data?._id}`}
                          className="btn border-0 bg-transparent"
                        >
                          <TooltipWrapper
                            placement="top"
                            tooltipText="Edit"
                            iconName="bi bi-pencil text-primary"
                          />
                        </NavLink>
                      ) : (
                        <NavLink
                          to={`/livestreams/create-livestream/${data?._id}`}
                          className="border-0 bg-transparent"
                        >
                          <TooltipWrapper
                            placement="top"
                            tooltipText="Edit"
                            iconName="bi bi-pencil text-primary"
                          />
                        </NavLink>
                      )}
                      <button
                        className="border-0 bg-transparent"
                        onClick={() => onDelete(data)}
                      >
                        <TooltipWrapper
                          placement="top"
                          tooltipText="Delete"
                          iconName="bi bi-trash3 text-danger"
                        />
                      </button>
                    </td>
                  </tr>
                );
              })}
              {livestream.length <= 0 && (
                <tr>
                  <td colSpan={7} className="text-center mb-3">
                    No livestream data
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        {/* Card Subscription */}
        {/* <div className="card-subscription">
          <div className="card-subscription-left">
            <div className="card-subscription-img">
              <Link to="">
                <img src={Capture} alt="draft" style={{ height: "150px" }} />
              </Link>
            </div>
            <div className="card-subscription-detail">
              <h3 className="card-subscription-title">
                <Link to="">Travel. Romance. Smiles</Link>
                <span className="badge rounded-pill bg-primary ms-2">
                  Private
                </span>
              </h3>
              <div className="subscription-schedule">
                <strong className="subscription-schedule-date">
                  Saturday, August 7
                </strong>
                <strong className="subscription-schedule-time">
                  4:30pm - 5:30pm
                </strong>
              </div>
              <div className="subscription-schedule">
                <span className="subscription-schedule-date">
                  Recurring every week on Sunday
                </span>
              </div>

              <div className="subscription-detail">
                <span className="">
                  Ticket Price: <strong className="">INR 799</strong>
                </span>
              </div>
            </div>
          </div>
          <div className="card-subscription-right">
            <button className="btn btn-primary">
              Schedule &amp; Publish
            </button>
            <div className="card-subscription-right-btm">
              <button className="btn btn-outline-black mt-auto">
                <i className="bi bi-pencil"></i>Edit
              </button>
              <button className="btn btn-outline-orange mt-auto">Delete</button>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
};
