import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import useRazorpay, { RazorpayOptions } from "react-razorpay";
import { connect } from "react-redux";
import { Link, NavLink, useHistory } from "react-router-dom";
import Slider from "react-slick";
import { toast } from "react-toastify";
import { ClubService } from "../../app/service/club.service";
import { LivestreamService } from "../../app/service/livestream";
import img from "../../assets/img/slider-img.png";
import { Loading } from "../../redux/actions";
import moment from "moment";
import { RAZORPAY_KEY } from "../../config/constant";

interface Props {
  diveIn: string;
  UserReducer?: any;
  loading?: (data: any) => void;
  LoginUserIdReducer?: any;
}

const DiveIn: React.FC<Props> = (props: Props) => {
  const [livestream, setLivestream] = useState<any[] | []>([]);
  const [nonLivestream, setNonLivestream] = useState<any[] | []>([]);

  let userId =
    sessionStorage.getItem("isLogin") || props?.LoginUserIdReducer?.loginUserId;
  const history = useHistory();
  const [showUserAlertPopup, setShowUserAlertPopup] = useState(false);
  const handleCloseUserAlertPopup = () => setShowUserAlertPopup(false);
  const handleShowUserAlertPopup = () => setShowUserAlertPopup(true);
  const [selectedLivestream, setSelectedLivestream] = useState<any>();
  const [showPaymentSuccessPopup, setShowPaymentSuccessPopup] = useState(false);
  const handleClosePaymentSuccessPopup = () =>
    setShowPaymentSuccessPopup(false);
  const handleShowPaymentSuccessPopup = () => setShowPaymentSuccessPopup(true);
  const Razorpay = useRazorpay();

  const [amount, setAmount] = useState<any>("");

  const RAZORPAY_OPTIONS: RazorpayOptions = {
    key: RAZORPAY_KEY,// "rzp_test_DsjVVApo8X8BCB",
    amount: amount + "000",
    currency: "INR",
    name: "Club clan",
    description: "Test Transaction",
    image: "",
    order_id: "",
    prefill: {
      name: "",
      email: "",
      contact: "",
    },
    notes: {
      address: "Razorpay Corporate Office",
    },
    theme: {
      color: "#3399cc",
    },
  };

  useEffect(() => {
    if (props.diveIn === "0") {
      LivestreamService.getAllScheduledLiveStreams(
        userId || props?.LoginUserIdReducer?.loginUserId
      ).then((res) => {
        if (res) {
          res.sort((a: any, b: any) => (a.createdAt < b.createdAt ? 1 : -1));
          setLivestream([...res]);
          loadDummayLivestream(res);
        }
      });
    } else {
      const loginUserId = userId || props?.LoginUserIdReducer?.loginUserId;
      LivestreamService.getAllScheduledLiveStreamsByClubID(
        loginUserId,
        props.diveIn
      ).then((res) => {
        if (res) {
          res.sort((a: any, b: any) => (a.createdAt < b.createdAt ? 1 : -1));
          setLivestream([...res]);
          loadDummayLivestream(res);
        }
      });
    }
  }, []);

  const loadDummayLivestream = (livestream: any) => {
    let totalLivestream = 4;
    let dummyLivestream = totalLivestream &&
      livestream.length <= totalLivestream && (
        <>
          {livestream.length < 4 && (
            <>
              <div
                className="dive-in-slide-inner"
                style={{ height: "388px", justifyContent: "center" }}
              >
                <span className="dive-in-date">Coming soon</span>
              </div>
            </>
          )}
        </>
      );
    const count = livestream.length < 4 ? 4 - livestream.length : 0;
    const nonLivestream = [];
    for (let index = 0; index < count; index++) {
      nonLivestream.push(dummyLivestream);
    }
    setNonLivestream([...nonLivestream]);
  };
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: false,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const onSelectTitle = (data: any) => {
    if (userId) {
      data.user_id = userId;
      LivestreamService.checkUserAccess(data).then((res) => {
        if (res) {
          history.push(`/livestream/${data?._id}`);
        } else {
          setSelectedLivestream(data);
          handleShowUserAlertPopup();
        }
      });
    } else {
      toast.error("Please Login");
    }
  };

  const onByTicket = (paymentId: string) => {
    const data = {
      user_id: userId || props?.LoginUserIdReducer?.loginUserId,
      club_id: selectedLivestream.club_id,
      post_id: selectedLivestream._id,
      amount: selectedLivestream.price,
      payment_id: paymentId,
    };
    ClubService.createClubSubscriber(data).then((subScriberRes) => {
      if (subScriberRes) {
        handleCloseUserAlertPopup();
        const byTicket = {
          user_id: userId || props?.LoginUserIdReducer?.loginUserId,
          club_id: selectedLivestream.club_id,
          price: selectedLivestream.price,
          collectionName: selectedLivestream.collectionName,
          live_stream_id: selectedLivestream._id,
        };
        LivestreamService.buyLiveStream(byTicket).then((buyTicket) => { });
      }
    });
  };

  const onOpenPaymentInfo = () => {
    handleCloseUserAlertPopup();
    const amount = selectedLivestream?.price;
    if (Number(amount) > 0) {
      RAZORPAY_OPTIONS.amount = amount + "00";
      RAZORPAY_OPTIONS.handler = (response: any) => {
        onByTicket(response.razorpay_payment_id);
        handleShowPaymentSuccessPopup();
      };
      let razorpay = new Razorpay(RAZORPAY_OPTIONS);
      razorpay.open();
      razorpay.on("payment.failed", function (response: any) { });
    } else {
    }
  };
  return (
    <>
      <section className="dive-in livestream-sec">
        <div className="container">
          <div className="dive-in-top">
            <h2 className="pb-0">Livestreams</h2>
            <div className="dive-in-top-rt">
              <p>
                A virtual live performance or sessions by your idols. Book for
                yourself or exclusively just for your friends and family. A
                virtual live performance or sessions by your idols. Book for
                yourself or exclusively just for your friends and family.
              </p>
              {livestream.length > 0 && (
                <Link
                  to="/livestream-listing"
                  className="btn btn-outline-black"
                >
                  View All
                </Link>
              )}
            </div>
          </div>
          <div className="dive-in-top-slider">
            <Slider className="dive-in-slider" {...settings}>
              {livestream.map((item, index) => {
                return (
                  <div key={index} className="dive-in-slide">
                    <div
                      className="dive-in-slide-inner"
                      style={{
                        backgroundImage: `url("${item?.club_info[0].cover_photo}")`,
                        backgroundPosition: "center center",
                        backgroundSize: "cover",
                      }}
                    >
                      <a
                        href="javascript:void(0)"
                        onClick={() => onSelectTitle(item)}
                        className="ratio ratio-1x1 mb-2"
                      >
                        {item.video_url ? (
                          <video
                            poster={item.video_url}
                            className="my_club_media"
                            controls
                            controlsList="nodownload"
                          >
                            <source src={item.video_url} type="video/mp4" />
                            Your browser does not support HTML video.
                          </video>
                        ) : (
                          <img
                            src={item?.thumbnail ? item?.thumbnail : img}
                            alt={item.title}
                          />
                        )}
                      </a>
                      <h3 className="position-relative">
                        <a
                          href="javascript:void(0)"
                          onClick={() => onSelectTitle(item)}
                          title={item.title}
                        >
                          {item.title.length > 16
                            ? `${item.title.substr(0, 16)}...`
                            : item.title}
                        </a>
                      </h3>
                      <Link
                        to={`clubs/${item.club_info[0]._id}`}
                        className="dive-in-channel position-relative"
                      >
                        @{item?.user_info[0]?.name}
                      </Link>
                      <span className="dive-in-date position-relative">
                        {moment(item.schedule_From).utc().format('DD-MM-YYYY hh:mm:ss A')} IST
                      </span>
                      {userId !== item?.user_info[0]?._id ? (
                        <div>
                          {item?.isLocked ? (
                            <NavLink
                              className="btn btn-outline-black mx-2 position-relative"
                              to={`/livestream-detail/${item?._id}`}
                            >
                              Buy Tickets
                            </NavLink>
                          ) : (
                            <NavLink
                              href="javascript:void(0)"
                              className="btn btn-outline-black  position-relative"
                              to={`/livestream/${item?._id}`}
                            >
                              View
                            </NavLink>
                          )}
                        </div>
                      ) : (
                        <NavLink
                          href="javascript:void(0)"
                          className="btn btn-outline-black  position-relative"
                          to={`/dashboard/mylivestreams-public/${item?._id}`}
                        >
                          Edit
                        </NavLink>
                      )}
                    </div>
                  </div>
                );
              })}
              {nonLivestream}
            </Slider>
            {livestream.length <= 0 && (
              <div className="text-center" style={{ color: "white" }}>
                No data found
              </div>
            )}
          </div>
        </div>
      </section>
      <Modal
        show={showUserAlertPopup}
        onHide={handleCloseUserAlertPopup}
        centered
        dialogClassName="sign-up-modal login-modal"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="auth-container">
            <div className="auth-sec auth-right-sec">
              <div className="auth-info">
                <h2>Info</h2>
              </div>
              <div>Please buy ticket for this livestream.</div>
              <div className="mt-3">
                <button
                  className="btn btn-primary w-100 mb-2"
                  onClick={onOpenPaymentInfo}
                >
                  Buy Tickets
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>

      <Modal
        show={showPaymentSuccessPopup}
        onHide={handleClosePaymentSuccessPopup}
        centered
        dialogClassName="sign-up-modal login-modal"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="auth-container">
            <div className="auth-sec auth-right-sec">
              <div className="auth-info pb-1">
                <h2>Success</h2>
              </div>
              <div>You have successfully purchased.</div>
            </div>
          </div>
          <div className="row">
            <div className="col-auto d-flex flex-wrap"></div>
            <div className="ms-auto col-auto">
              <NavLink
                className="btn btn-black mx-3 mb-2"
                to={`/livestream/${selectedLivestream?._id}`}
              >
                Ok
              </NavLink>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    UserReducer: state.UserReducer,
    LoginUserIdReducer: state.LoginUserIdReducer,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    loading: (data: any) => dispatch(Loading(data)),
  };
};

const connectedNavBar = connect(mapStateToProps, mapDispatchToProps)(DiveIn);
export { connectedNavBar as DiveIn };
