import moment from "moment";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { LivestreamService } from "../../../app/service/livestream";
import Capture from "../../../assets/img/Capture1.png";

export const MyLivestreamCancelledPage = () => {
  let userId = sessionStorage.getItem('isLogin') || '';
  const [livestream, setLivestream] = useState<any[] | []>([]);

  useEffect(() => {
    getlivestream();
  }, [])

  const getlivestream = () => {
    LivestreamService.getCanceledLiveStreamByUserID(userId).then(
      (res) => {
        if (res) {
          setLivestream([...res]);
        }
      })
  }
  return (
    <div
      id="subs-liv-cancelled-tab"
      role="tabpanel"
      aria-labelledby="subs-liv-cancelled-tab"
    >
      {/* Card Subscription */}
      {livestream.map((data: any, index: number) => {
        return <div>{data?.live_stream_data.length > 0 ? <div key={index} className="card-subscription">
          <div className="card-subscription-left">
            <div className="card-subscription-img">
              <img src={data?.liveStream[0]?.thumbnail ? data?.liveStream[0]?.thumbnail : Capture} alt="draft" style={{ height: "150px" }} />
            </div>
            <div className="card-subscription-detail">
              <h3 className="card-subscription-title">
                {data?.liveStream[0]?.title}
                <a href="" className="card-subscription-username">
                  @{data?.userInfo[0]?.name}
                </a>
              </h3>
              <div className="subscription-schedule">
                <span className="subscription-schedule-date">
                  {moment(data?.liveStream[0]?.schedule_From).utc().format('MMMM dddd DD')}
                </span>
                <span className="subscription-schedule-time">
                  {moment(data?.liveStream[0]?.schedule_From).utc().format('LT')}  - {moment(data?.liveStream[0]?.schedule_To).utc().format('LT')}
                </span>
              </div>
              <div className="subscription-detail">
                <span className="subscription-term">Ticket Price:</span>
                <strong className="subscription-price">INR {data?.liveStream[0]?.price}</strong>
              </div>
              <div className="subscription-pkg-info mt-auto">
                <span className="subscription-pkg-name">Refund initiated</span>
              </div>
            </div>
          </div>
          {/* <div className="card-subscription-right">
          <button className="btn btn-outline-orange mt-auto">Delete</button>
        </div> */}
        </div> : <div className="text-center">No data</div>}
        </div>
      })}
      {livestream.length <= 0 && (
        <div className="text-center mb-3">
          No livestream data
        </div>
      )}
    </div>
  );
};
