import React, { useEffect, useState } from "react";
import AppFooter from "../../components/footer";
import { Clubs, ClubsPost } from "../../app/model/clubs";
import { useHistory, useParams } from "react-router";
import { NavLink } from "react-router-dom";
import { SubscriptionPackagesService } from "../../app/service/subscription-packages-service";
import { SubscriptionPackages } from "../../app/model/subscription-packages";
import moment from "moment";
import ScrollToTop from "../../components/scroll-to-top";
import { ClubService } from "../../app/service/club.service";
import { AppHeader } from "../../components/header";
import { connect, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import dummyImg from "../../assets/img/dummy-img.svg";
import Slider from "react-slick";
import { Loading } from "../../redux/actions";
import { Modal } from "react-bootstrap";
import useRazorpay, { RazorpayOptions } from "react-razorpay";
import { DiveIn } from "../../components/dashboard/dive-in-livestream";
import { RAZORPAY_KEY } from "../../config/constant";

interface Props {
  UserReducer?: any;
  loading?: (data: any) => void;
  LoginUserIdReducer?: any;
}

const ClubsPage: React.FC<Props> = (props: Props) => {
  const aboutRef = React.useRef<HTMLDivElement>(null);
  const packagesRef = React.useRef<HTMLDivElement>(null);
  const postsRef = React.useRef<HTMLDivElement>(null);
  const [club, setClubs] = useState<Clubs[] | []>([]);
  const [subscriptionPackages, setSubscriptionPackages] = useState<
    SubscriptionPackages[] | []
  >([]);
  const [clubPosts, setclubPosts] = useState<ClubsPost[] | []>([]);
  const history = useHistory();
  const [filterSelectedPostType, setFilterSelectedPostType] =
    useState<string>("");
  const [filterSelectedPackageType, setFilterSelectedPackageType] =
    useState<string>("");
  const [more, setMore] = useState(false);
  const dispatch = useDispatch();
  const [showUserAlertPopup, setShowUserAlertPopup] = useState(false);
  const handleCloseUserAlertPopup = () => setShowUserAlertPopup(false);
  const handleShowUserAlertPopup = () => setShowUserAlertPopup(true);
  const [selectedPost, setSelectedPost] = useState<any>();
  const [showUserSubscribeAlertPopup, setShowUserSubscribeAlertPopup] = useState(false);
  const handleCloseUserSubscribeAlertPopup = () => setShowUserSubscribeAlertPopup(false);
  const handleShowUserSubscribeAlertPopup = () => setShowUserSubscribeAlertPopup(true);
  const [showUserUnSubscribeAlertPopup, setShowUserUnSubscribeAlertPopup] = useState(false);
  const handleCloseUserUnSubscribeAlertPopup = () => setShowUserUnSubscribeAlertPopup(false);
  const handleShowUserUnSubscribeAlertPopup = () => setShowUserUnSubscribeAlertPopup(true);
  const [selectedClubPacakge, setSelectedClubPacakge] = useState<any>();
  const [selectedClubPackageType, setSelectedClubPackageType] = useState('');
  const [isShowMorePerks, setIsShowMorePerks] = useState(-1);
  const [showPaymentSuccessPopup, setShowPaymentSuccessPopup] = useState(false);
  const handleClosePaymentSuccessPopup = () => setShowPaymentSuccessPopup(false);
  const handleShowPaymentSuccessPopup = () => setShowPaymentSuccessPopup(true);
  const [selectedPaymet, setSelectedPaymet] = useState<any>("");
  const [amount, setAmount] = useState<any>("");
  const [selectedOptions, setSelectedOptions] = useState<any[]>([]);
  const Razorpay = useRazorpay();

  const RAZORPAY_OPTIONS: RazorpayOptions = {
    key: RAZORPAY_KEY,// "rzp_test_DsjVVApo8X8BCB",
    amount: (amount + "000"),
    currency: "INR",
    name: "Club clan",
    description: "Test Transaction",
    image: "",
    order_id: "",
    prefill: {
      name: '',
      email: "",
      contact: "",
    },
    notes: {
      address: "Razorpay Corporate Office",
    },
    theme: {
      color: "#3399cc",
    },
  };

  let { id } = useParams<{ id: string }>();
  let userId = sessionStorage.getItem("isLogin") || props.LoginUserIdReducer.loginUserId;

  useEffect(() => {
    if (props.loading) {
      dispatch(props.loading(true));
    }
    if (id) {
      getClub(id);
    }
  }, [id]);
  const clubSubscriberDetails = (
    subscriptionPackages: SubscriptionPackages[]
  ) => {
    if (userId || props?.LoginUserIdReducer?.loginUserId) {
      const data = {
        user_id: userId || props?.LoginUserIdReducer?.loginUserId,
        club_id: id,
      };
      ClubService.clubSubscriberDetails(data).then((res) => {
        if (res) {
          subscriptionPackages.forEach((element: any) => {
            element.isSubscribed = false;
            const isExist = res.find((sub: any) => {
              return sub.subscription_package_id === element._id;
            });
            if (isExist) {
              element.isSubscribed = true;
            } else {
              element.isSubscribed = false;
            }
          });
          setSubscriptionPackages([...subscriptionPackages]);
        }
        if (props.loading) {
          dispatch(props.loading(false));
        }
      });
    }
  }

  const getClub = (id: string) => {
    if (props.loading) {
      dispatch(props.loading(true));
    }
    ClubService.getClub(id).then((res) => {
      if (res) {
        setClubs(res);
      }
    });
    SubscriptionPackagesService.GET_SUBSCRIPTION_PACKAGES(id).then((res) => {
      if (res) {
        res.sort((a, b) => (a.name > b.name ? 1 : -1));
        setSubscriptionPackages([...res]);
        clubSubscriberDetails(res);
        if (props.loading) {
          dispatch(props.loading(false));
        }
      }
    });
    getClubPosts(id);
  }

  const getClubPosts = (id: string) => {
    const data = {
      user_id: userId || props?.LoginUserIdReducer?.loginUserId,
      club_id: id,
      filterType: filterSelectedPostType ? filterSelectedPostType : "",
      bundleType: filterSelectedPackageType ? filterSelectedPackageType : "",
    };
    ClubService.getUserSubscriptionPost(data).then((res) => {
      if (res) {
        res.forEach((element: any) => {
          element.isLikes =
            element.likes.length > 0
              ? element.likes.find((like: any) => {
                if (
                  like.user_id ===
                  (userId || props?.LoginUserIdReducer?.loginUserId)
                ) {
                  return true;
                } else {
                  return false;
                }
              })
              : false;
          if (element.selectedOptions) {
            try {
              const votes = JSON.parse(element.selectedOptions);
              const optios = JSON.parse(element.options);
              votes.forEach((selectedVote: any) => {
                if (selectedVote.user_id === (userId || props?.LoginUserIdReducer?.loginUserId)) {
                  optios.forEach((selectOption: any) => {
                    if (selectOption.name === selectedVote.name) {
                      selectOption.value = true;
                    }
                  });
                }
              });
              element.options = JSON.stringify(optios);
            } catch (error) {

            }
          }
        });
        setclubPosts([...res]);
      }
    });
  };

  const onCreateSubscriber = (paymentId: string) => {
    if (userId || props?.LoginUserIdReducer?.loginUserId) {
      const data = {
        user_id: userId || props?.LoginUserIdReducer?.loginUserId,
        club_id: selectedClubPacakge.club_id,
        subscription_package_id: selectedClubPacakge._id,
        package_for: selectedClubPackageType,
        amount:
          selectedClubPackageType === "MONTHLY" ? selectedClubPacakge.price_monthly : selectedClubPacakge.price_yearly,
        payment_id: paymentId

      };
      ClubService.createClubSubscriber(data).then((res) => {
        if (res) {
          clubSubscriberDetails(subscriptionPackages);
          getClub(id);
          toast.success("Subscription successfully");
          handleCloseUserSubscribeAlertPopup();
        }
      });
    } else {
      history.push("/clubsingup");
    }
  };

  const onCreateUnSubscriber = () => {
    if (userId || props?.LoginUserIdReducer?.loginUserId) {
      const data = {
        user_id: userId || props?.LoginUserIdReducer?.loginUserId,
        club_id: selectedClubPacakge?.club_id || '',
        subscription_package_id: selectedClubPacakge?._id || '',
      };
      ClubService.unSubscribeSubscription(data).then((res) => {
        if (res) {
          clubSubscriberDetails(subscriptionPackages);
          getClub(id);
          handleCloseUserUnSubscribeAlertPopup();
          toast.success("UnSubscription successfully");
        }
      });
    } else {
      history.push("/clubsingup");
    }
  };

  const updatePostLikes = (post: any) => {
    const data = {
      club_id: id,
      user_id: sessionStorage.getItem("isLogin") || "",
      _id: post?._id,
      collectionName: post?.collectionName,
    };
    ClubService.updatePostLikes(data).then((res) => {
      if (res) {
        getClubPosts(id);
      } else {
        toast.error("Please Login");
      }
    });
  };
  const settings = {
    dots: false,
    arrows: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };
  const onSelectPost = (post: any) => {
    if (userId || props?.LoginUserIdReducer?.loginUserId) {
      setSelectedPost(post);
      if (post.PAID_POST && post.isLocked) {
        handleShowUserAlertPopup();
      } else {
        history.push(`/club-detail/${post._id}/${post.club_id}`);
      }
    } else {
      toast.error("Please login");
    }
  };
  const onSelectPackageType = (event: any) => {
    setFilterSelectedPackageType(event.target.value);
  };

  const onSeletcPostType = (event: any) => {
    setFilterSelectedPostType(event.target.value);
  };
  const onApplyFilter = () => {
    getClubPosts(id);
  };

  const handleMore = (perksId: any, index: number) => {
    if (index === isShowMorePerks) {
      setIsShowMorePerks(-1);
    } else {
      setIsShowMorePerks(index);
    }
    let more = subscriptionPackages.filter((perk) => {
      return perksId === perk._id;
    });

    if (more[0]._id === perksId) {
      document.getElementById(perksId)?.classList.toggle("expanded");
    }
  };
  const showMore = () => {
    setMore(!more);
  };
  const onSubscribePost = (paymentId: string) => {
    const data = {
      user_id: userId || props?.LoginUserIdReducer?.loginUserId,
      club_id: selectedPost.club_id,
      post_id: selectedPost._id,
      amount: selectedPost.price,
      payment_id: paymentId
    };
    ClubService.subscriberAccessCheck(data).then((res) => {
      if (res) {
        handleCloseUserAlertPopup();
      } else {
        ClubService.createClubSubscriber(data).then((subScriberRes) => {
          if (subScriberRes) {
            clubSubscriberDetails(subscriptionPackages);
            handleCloseUserAlertPopup();
            toast.success("Post Subscribed successfully");
          }
        });
      }
    });
  }
  const showSubscribePopup = (selectedSubscription: any, type: string) => {
    if (userId || props?.LoginUserIdReducer?.loginUserId) {
      setSelectedClubPacakge(selectedSubscription);
      setSelectedClubPackageType(type);
      handleShowUserSubscribeAlertPopup();
    } else {
      toast.error('Please login');
    }
  }

  const showUnsubscribePopup = (selectedSubscription: any, type: string) => {
    setSelectedClubPacakge(selectedSubscription);
    setSelectedClubPackageType(type);
    handleShowUserUnSubscribeAlertPopup();
  }

  const onOpenPaymentInfo = (type: string) => {
    if (type === 'package') {
      handleCloseUserSubscribeAlertPopup();
    } else {
      handleCloseUserAlertPopup();
    }
    setSelectedPaymet(type);
    const amount = type === 'package' ? (selectedClubPackageType === 'MONTHLY' ? selectedClubPacakge.price_monthly : selectedClubPacakge.price_yearly) : selectedPost.price;
    if (Number(amount) > 0) {
      RAZORPAY_OPTIONS.amount = amount + "00";
      RAZORPAY_OPTIONS.handler = (response: any) => {
        if (type === 'package') {
          onCreateSubscriber(response.razorpay_payment_id);
          handleShowPaymentSuccessPopup();
        } else {
          onSubscribePost(response.razorpay_payment_id);
          handleShowPaymentSuccessPopup();
        }
      }
      let razorpay = new Razorpay(RAZORPAY_OPTIONS);
      razorpay.open();
      razorpay.on("payment.failed", function (response: any) {
      });
    } else {
      // onUpdatePayment(txn_id, { "message": "SUM AMOUNT ZERO" }, "success");
    }
  }

  const onVote = (post: any,) => {
    if (userId || props?.LoginUserIdReducer?.loginUserId) {
      let formData = new FormData();
      formData.append("_id", post?._id);
      formData.append(
        "collectionName",
        post?.collectionName
      );
      formData.append(
        "selectedOptions",
        selectedOptions.length > 0
          ? JSON.stringify(selectedOptions)
          : ""
      );
      formData.append("club_id", post?.club_id);

      if (selectedOptions.length > 0) {
        ClubService.updateClubPost(formData).then(
          (res) => {
            toast.success('Voted Successfully');
          },
          (error) => {

          }
        );
      }
    } else {
      toast.error('Please login');
    }
  }

  const onSelectPollsOption = (event: any, data: any, type: string, post: any, index: number) => {
    const clubPostData = clubPosts;
    let options: any = selectedOptions;
    if (type === 'radio') {
      options = [];
      options.push({ user_id: userId || props?.LoginUserIdReducer?.loginUserId, name: data.name });
      setSelectedOptions([...options]);
    } else {
      if (data.value) {
        data.value = event.target.checked;
      }
      options.push({ user_id: userId || props?.LoginUserIdReducer?.loginUserId, name: data.name });
      if (post.selectedOptions) {
        const selectedOptionsData = JSON.parse(post.selectedOptions);
        const newOptions: any = [...options, ...selectedOptionsData];
        var filterArray = newOptions.reduce((accumalator: any, current: any) => {
          if (!accumalator.some((item: any) => item.user_id === current.user_id && item.name === current.name)) {
            accumalator.push(current);
          }
          return accumalator;
        }, []);
        if (!event.target.checked) {
          filterArray = filterArray.filter(function (obj: any) {
            return !(obj.name === data.name && obj.user_id === userId || props?.LoginUserIdReducer?.loginUserId);
          });
        }
        setSelectedOptions([...filterArray]);
      }
    }
    const optionData = JSON.parse(post.options);
    optionData.forEach((element: any) => {
      if (element.name === data.name) {
        element.value = event.target.checked;
      }
    });
    post.options = JSON.stringify(optionData);
    clubPostData[index] = post;
    setclubPosts([...clubPostData]);
  }
  return (
    <div>
      <AppHeader />
      <ScrollToTop />
      <main className="main-content-sec-static-page">
        <section className="banner-cover category-detail-page">
          <img src={club[0]?.cover_photo ? club[0]?.cover_photo : dummyImg} alt="cover image" />
          <div className="banner-cover-profile">
            <div className="banner-cover-profile__left">
              <div className="banner-cover-profile__left-img">
                <img
                  src={
                    club[0]?.user_info[0]?.image
                      ? club[0]?.user_info[0]?.image
                      : dummyImg
                  }
                  alt="banner cover image"
                />
              </div>
              <div className="banner-cover-profile__info">
                <h1 className="banner-cover-profile__info-title">
                  {club[0]?.name}
                </h1>
                <p className="banner-cover-profile__info-category-tags">
                  {club[0]?.tags}
                </p>
                <p className="banner-cover-profile__info-subs-count">
                  {club[0]?.total_subscribers} Subscribers
                </p>
              </div>
            </div>
            <div className="banner-cover-profile__right">
              <a className="btn btn-outline-black btn-lg">Enquiry</a>
              <div className="social-sec">
                <a
                  href={`https://www.instagram.com/${club[0]?.user_info[0]?.instagram_url}`}
                  target="_blank"
                >
                  <i className="bi bi-instagram"></i>
                </a>
                <a
                  href={`https://www.facebook.com/${club[0]?.user_info[0]?.facebook_url}`}
                  target="_blank"
                >
                  <i className="bi bi-facebook"></i>
                </a>
                <a
                  href={`https://twitter.com/${club[0]?.user_info[0]?.twitter_url}`}
                  target="_blank"
                >
                  <i className="bi bi-twitter"></i>
                </a>
                <a
                  href={`https://www.youtube.com/${club[0]?.user_info[0]?.youtube_url}`}
                  target="_blank"
                >
                  <i className="bi bi-youtube"></i>
                </a>
                <a href="#">
                  <i className="bi bi-share"></i>
                </a>
              </div>
            </div>
          </div>
        </section>
        <section className="card-detail-page">
          <div className="card-detail-tab__content-sec">
            <div className="card-detail-tab__content" id="about" ref={aboutRef}>
              <div className="container container-small">
                <h3 className="card-detail-tab-title">About</h3>
                <div className="ratio ratio-16x9 mb-3 main-video">
                  {club[0]?.intro_video && (
                    <video width="560" height="315" controls controlsList="nodownload">
                      <source src={club[0]?.intro_video} type="video/mp4" />
                      Your browser does not support HTML video.
                    </video>
                  )}
                  {!club[0]?.intro_video &&
                    <img src={dummyImg} alt="dummy image" />
                  }
                </div>
                {more ? (
                  <>
                    <>
                      <p>{club[0]?.about}</p>
                    </>
                  </>
                ) : (
                  <p>{club[0]?.about.substr(0, 1000)}</p>
                )}
                {club[0]?.about.length > 1000 && (
                  <span onClick={showMore} className="btn btn-outline-black">
                    {more ? "Read less" : "Read more..."}
                  </span>
                )}
              </div>
            </div>
            <div
              className="card-detail-tab__content"
              id="packages"
              ref={packagesRef}
            >
              <div className="container container-medium">
                {subscriptionPackages.length > 0 && (
                  <h3 className="card-detail-tab-title">Packages</h3>
                )}
                <div className="package-sec row" style={{ display: 'flex' }}>
                  {subscriptionPackages.map((subscription, i) => {
                    return (
                      <div className="col-md-4 mb-3"
                        id={subscription._id}
                        key={subscription._id}
                      // style={{ width: '33%', margin: 'auto' }}
                      >
                        <div className="package">
                          <div className="package-header">
                            <div className="package-info">
                              <strong>{subscription?.name}</strong>
                              <span className="package-price">
                                {subscription?.price_monthly}
                              </span>
                              <span className="package-term">per month</span>
                              {(club[0]?.user_info[0]?._id !== (userId || props?.LoginUserIdReducer?.loginUserId)) ? <div className="w-100 text-center mx-auto">
                                {subscription?.isSubscribed ? (
                                  <button
                                    className="btn btn-primary btn-rounded mx-auto"
                                    onClick={() =>
                                      showUnsubscribePopup(
                                        subscription,
                                        "MONTHLY"
                                      )
                                    }
                                  >
                                    Unsubscribe
                                  </button>
                                ) : (
                                  <button
                                    className="btn btn-primary btn-rounded mx-auto"
                                    onClick={() =>
                                      showSubscribePopup(subscription, "MONTHLY")
                                    }
                                  >
                                    Subscribe
                                  </button>
                                )}
                                {subscription?.isSubscribed ? (
                                  ""
                                ) : (
                                  <a
                                    className="package-link pointer"
                                    onClick={() =>
                                      showSubscribePopup(subscription, "YEARLY")
                                    }
                                  >
                                    or pay annually | SAVE 5%
                                  </a>
                                )}
                              </div> : <div className="w-100 text-center mx-auto">
                                <button
                                  className="btn btn-primary btn-rounded mx-auto" disabled
                                >
                                  Subscribe
                                </button>
                                <a
                                  className="package-link pointer"
                                >
                                  or pay annually | SAVE 5%
                                </a>
                              </div>
                              }
                            </div>
                          </div>
                          <div className="package-desc" style={{ maxHeight: '140px' }}>
                            <p style={{
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              maxHeight: '25px'
                            }} title={subscription?.description}>{subscription?.description} </p>
                            <ol style={{
                              maxHeight: '65px',
                              overflow: 'auto'
                            }}>
                              {subscription.perks.map((perk, index) => {
                                return <div>
                                  {index < 3 && <li key={index}>{perk?.value}</li>}
                                  {index >= 3 && isShowMorePerks === i && <li key={index}>{perk?.value}</li>}

                                </div>
                              })}
                            </ol>
                            {subscription.perks.length >= 3 && (
                              <button className='btn btn-primary mt-2'
                                onClick={() => {
                                  handleMore(subscription._id, i);
                                }}
                              >
                                {isShowMorePerks !== i ? 'More' : 'Less'}
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="card-detail-tab__content" id="posts" ref={postsRef}>
              <div className="container container-medium">
                <h3 className="card-detail-tab-title">Posts</h3>
                <div className="post-tab__content row">
                  <div className="post-tab__content-left col-md-9 row">
                    {clubPosts.length <= 0 && (
                      <div className="text-center mb-3">No club posts</div>
                    )}
                    {clubPosts.map((post: any, index) => {
                      return (
                        <div key={index} className="col-md-6">
                          {(club[0]?.user_info[0]?._id !== (userId || props?.LoginUserIdReducer?.loginUserId)) ? <div className="post-card fixed-height" title={`${post.isLocked ? 'Please subscribe/buy to view the post' : ''}`}>
                            {post.isLocked && (
                              <i
                                className="bi bi-file-lock"
                                style={{
                                  position: "absolute",
                                  color: "green",
                                  fontSize: "100px",
                                  padding: "0px 100px",
                                }}
                              ></i>
                            )}
                            {!post.isLocked ? (
                              <div className="post-card__img">
                                {post.type === "" && (
                                  <img
                                    className="mw-100"
                                    src={post?.thumbnail}
                                    alt=""
                                  />
                                )}
                                {post.type === "0" && (
                                  <video
                                    className="mw-100 w-100 ratio ratio-1x1  "
                                    style={{ height: "180px" }}
                                    controls controlsList="nodownload"
                                  >
                                    <source
                                      src={post?.content}
                                      type="video/mp4"
                                    />
                                    Your browser does not support HTML video.
                                  </video>
                                )}
                                {post.type === "1" && (
                                  <audio controls style={{ height: "180px" }}>
                                    <source
                                      src={post?.content}
                                      type="audio/mpeg"
                                    />
                                    Your browser does not support the audio
                                    element.
                                  </audio>
                                )}
                                {post.type === "2" && (
                                  <Slider
                                    className="slider slider-w-images-3"
                                    {...settings}
                                  >
                                    {JSON.parse(post?.content).map(
                                      (image: any, index: number) => {
                                        return (
                                          <section>
                                            <div className="slider-item">
                                              <div className="slider-item-img  ratio ratio-4x3">
                                                <img src={image?.img} alt="" />
                                              </div>
                                            </div>
                                          </section>
                                        );
                                      }
                                    )}
                                  </Slider>
                                )}

                                {post?.type === "3" && (
                                  <div className="text-post">
                                    {post?.content}
                                  </div>
                                )}
                                {post?.type === "4" && (
                                  <div className="text-post">
                                    <img
                                      className="mw-100"
                                      src={post?.thumbnail}
                                      alt=""
                                    />
                                  </div>
                                )}
                              </div>
                            ) : (
                              <div className="post-card__img">
                                <img
                                  className="mw-100"
                                  src={post?.thumbnail}
                                  style={{
                                    opacity: post.isLocked ? "0.3" : "",
                                  }}
                                  alt="thumbnail"
                                />
                              </div>
                            )}
                            <div className="post-card__body">
                              <h3 className="post-card__title">
                                {post?.PAID_POST ? (
                                  <a
                                    className="pointer"
                                    onClick={() => onSelectPost(post)}
                                  >
                                    {post?.title}
                                  </a>
                                ) : post?.isLocked ? (
                                  <span>{post?.title}</span>
                                ) : (
                                  <NavLink
                                    to={`/club-detail/${post._id}/${post.club_id}`}
                                    className="pointer"
                                  >
                                    {post?.title}
                                  </NavLink>
                                )}
                              </h3>
                              <p className="post-card__desc">
                                {post?.description.substr(0, 30)} ...
                              </p>
                              {post?.type === '4' && !post.isLocked && <div>{JSON.parse(post?.options).map(
                                (data: any, optionIndex: number) => {
                                  return (<div key={optionIndex}>
                                    {post?.isMultiple ?
                                      <div className="custom-checkbox no-bdr">
                                        <input id={`setEndDate${post?._id}${optionIndex}`} type="checkbox" checked={data?.value} name="optionsRadios" onChange={(event) => onSelectPollsOption(event, data, 'checkbox', post, index)} />
                                        <label htmlFor={`setEndDate${post?._id}${optionIndex}`}>
                                          {data?.name}
                                        </label>
                                      </div>
                                      : <div className="custom-radio no-bdr radio-green">
                                        <input
                                          type="radio"
                                          name="optionsRadios"
                                          checked={data?.value}
                                          onChange={(event) => onSelectPollsOption(event, data, 'radio', post, index)}
                                        /><label>{data?.name}</label>
                                      </div>}

                                  </div>)
                                })}
                                <button
                                  type="button"
                                  className="btn btn-primary btn-rounded btn-sm me-2"
                                  onClick={() => onVote(post)}
                                >
                                  Vote
                                </button>
                              </div>}
                            </div>
                            <div className="post-card__footer">
                              <ul className="post-card__footer-left">
                                <li>
                                  <i
                                    className={`${post?.isLikes
                                      ? "bi-heart-fill pointer clr-red"
                                      : "bi bi-heart pointer"
                                      }`}
                                    onClick={() => updatePostLikes(post)}
                                  ></i>
                                  {post?.likes?.length
                                    ? post?.likes?.length
                                    : 0}
                                </li>
                                {post?.type !== '4' && <li>
                                  <i className="bi bi-chat"></i>
                                  {post?.comments?.length
                                    ? post?.comments?.length
                                    : 0}
                                </li>}
                              </ul>
                              <div className="post-card__footer-right">
                                <small className="post-card__postedInfo">
                                  {moment(post?.published_dt).format("lll")} IST
                                  {/* {moment(post?.published_dt).toISOString(true)} */}
                                </small>
                              </div>
                            </div>
                          </div> : <div className="post-card fixed-height" >
                            <div className="post-card__img">
                              {post.type === "" && (
                                <img
                                  className="mw-100"
                                  src={post?.thumbnail}
                                  alt=""
                                />
                              )}
                              {post.type === "0" && (
                                <video
                                  className="mw-100 w-100 ratio ratio-1x1  "
                                  style={{ height: "180px" }}
                                  controls controlsList="nodownload"
                                >
                                  <source
                                    src={post?.content}
                                    type="video/mp4"
                                  />
                                  Your browser does not support HTML video.
                                </video>
                              )}
                              {post.type === "1" && (
                                <audio controls>
                                  <source
                                    src={post?.content}
                                    type="audio/mpeg"
                                  />
                                  Your browser does not support the audio
                                  element.
                                </audio>
                              )}
                              {post.type === "2" && (
                                <Slider
                                  className="slider slider-w-images-3"
                                  {...settings}
                                >
                                  {JSON.parse(post?.content).map(
                                    (image: any, index: number) => {
                                      return (
                                        <section>
                                          <div className="slider-item">
                                            <div className="slider-item-img  ratio ratio-4x3">
                                              <img src={image?.img} alt="" />
                                            </div>
                                          </div>
                                        </section>
                                      );
                                    }
                                  )}
                                </Slider>
                              )}
                              {post?.type === "3" && (
                                <div className="text-post">
                                  {post?.content}
                                </div>
                              )}

                              {post.type === "4" && (
                                <img
                                  className="mw-100"
                                  src={post?.thumbnail}
                                  alt=""
                                />
                              )}
                              {/* {post.type === "4" && (
                                <div className="card p-4">
                                  <div className="panel-heading">
                                    <h3>
                                      What's your vote for best science
                                      fiction movie of all time best
                                      science fiction movie of all timea?
                                    </h3>
                                    <p>
                                      Lorem ipsum dolor sit amet
                                      consectetur adipisicing elit. Libero
                                      minima quo, quis mollitia officia
                                      commodi. Ducimus quasi molestiae,
                                      labore quis autem
                                    </p>
                                  </div>
                                  <div className="panel-body mb-2">
                                    <div className="custom-radio no-bdr radio-green">
                                      <input
                                        type="radio"
                                        name="optionsRadios"
                                      />
                                      <label>Star Wars: A New Hope</label>
                                    </div>
                                    <div className="custom-radio no-bdr radio-green">
                                      <input
                                        type="radio"
                                        name="optionsRadios"
                                      />
                                      <label>
                                        The Day the Earth Stood Still
                                      </label>
                                    </div>
                                    <div className="custom-radio no-bdr radio-green">
                                      <input
                                        type="radio"
                                        name="optionsRadios"
                                      />
                                      <label>2001: A Space Odyssey</label>
                                    </div>
                                  </div>
                                  <div className="panel-footer d-flex align-items-center mt-2">
                                    <button
                                      type="button"
                                      className="btn btn-primary btn-rounded btn-sm me-2"
                                    >
                                      Vote
                                    </button>
                                  </div>
                                </div>

                              )} */}
                            </div>
                            <div className="post-card__body">
                              <h3 className="post-card__title">
                                <NavLink
                                  to={`/club-detail/${post._id}/${post.club_id}`}
                                  className="pointer"
                                >
                                  {post?.title}
                                </NavLink>
                              </h3>
                              <p className="post-card__desc">
                                {post?.description.substr(0, 30)} ...
                              </p>
                              {post?.type === '4' && <div>{JSON.parse(post?.options).map(
                                (data: any, optionIndex: number) => {
                                  return (<div key={optionIndex}>
                                    {post?.isMultiple ?
                                      <div className="custom-checkbox no-bdr">
                                        <input id={`setEndDate${post?._id}${optionIndex}`} checked={data?.value} type="checkbox" name="optionsRadios" onChange={(event) => onSelectPollsOption(event, data, 'checkbox', post, index)} />
                                        <label htmlFor={`setEndDate${post?._id}${optionIndex}`}>
                                          {data?.name}
                                        </label>
                                      </div>
                                      : <div className="custom-radio no-bdr radio-green">
                                        <input
                                          type="radio"
                                          name="optionsRadios"
                                          checked={data?.value}
                                          onChange={(event) => onSelectPollsOption(event, data, 'radio', post, index)}
                                        /><label>{data?.name}</label>
                                      </div>}

                                  </div>)
                                })}
                                <button
                                  type="button"
                                  className="btn btn-primary btn-rounded btn-sm me-2"
                                  onClick={() => onVote(post)}
                                >
                                  Vote
                                </button>
                              </div>}
                            </div>
                            <div className="post-card__footer">
                              <ul className="post-card__footer-left">
                                <li>
                                  <i
                                    className={`${post?.isLikes
                                      ? "bi-heart-fill pointer clr-red"
                                      : "bi bi-heart pointer"
                                      }`}
                                    onClick={() => updatePostLikes(post)}
                                  ></i>
                                  {post?.likes?.length
                                    ? post?.likes?.length
                                    : 0}
                                </li>
                                {post?.type !== '4' && <li>
                                  <i className="bi bi-chat"></i>
                                  {post?.comments?.length
                                    ? post?.comments?.length
                                    : 0}
                                </li>}
                              </ul>
                              <div className="post-card__footer-right">
                                <small className="post-card__postedInfo">
                                  {moment(post?.published_dt).format("lll")} IST
                                </small>
                              </div>
                            </div>
                          </div>
                          }

                        </div>
                      );
                    })}
                  </div>

                  <div className="post-tab__content-right text-start col-md-3">
                    <form>
                      <label className="ps-0">Filters</label>
                      <select
                        className="form-select mb-2"
                        aria-label="Default select example"
                        onChange={onSeletcPostType}
                      >
                        <option value="">All posts</option>
                        <option value="0">Video Posts</option>
                        <option value="1">Audio Posts</option>
                        <option value="2">Image Posts</option>
                        <option value="3">Text Posts</option>
                        <option value="4">Poll Posts</option>
                      </select>
                      <select
                        className="form-select mb-2"
                        aria-label="Default select example"
                        onChange={onSelectPackageType}
                      >
                        <option value="">All packages</option>
                        {subscriptionPackages.map((subscription) => {
                          return (
                            <option value={subscription._id}>
                              {subscription?.name}
                            </option>
                          );
                        })}
                      </select>
                      <div
                        className="btn btn-outline-black"
                        onClick={onApplyFilter}
                      >
                        Apply
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-none">
              <DiveIn diveIn={id} />
            </div>
          </div>
        </section>
      </main>
      <AppFooter></AppFooter>
      <Modal
        show={showUserAlertPopup}
        onHide={handleCloseUserAlertPopup}
        centered
        dialogClassName="sign-up-modal login-modal"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="auth-container">
            <div className="auth-sec auth-right-sec">
              <div className="auth-info">
                <h2>Alert</h2>
              </div>
              <div>
                Do you want to buy this post?
              </div>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-auto d-flex flex-wrap">
              <button className="btn btn-primary mx-3 mb-2" onClick={() => onOpenPaymentInfo('post')}>Yes</button>
            </div>
            <div className="ms-auto col-auto">
              <button className="btn btn-danger mx-3 mb-2" onClick={() => handleCloseUserAlertPopup()}>No</button>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showUserSubscribeAlertPopup}
        onHide={handleCloseUserSubscribeAlertPopup}
        centered
        dialogClassName="sign-up-modal login-modal"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="auth-container">
            <div className="auth-sec auth-right-sec">
              <div className="auth-info">
                <h2>Alert</h2>
              </div>
              <div>
                Do you want to subscribe this club?
              </div>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-auto d-flex flex-wrap">
              <button className="btn btn-primary mx-3 mb-2" onClick={() => onOpenPaymentInfo('package')}>Yes</button>
            </div>
            <div className="ms-auto col-auto">
              <button className="btn btn-danger mx-3 mb-2" onClick={() => handleCloseUserSubscribeAlertPopup()}>No</button>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showUserUnSubscribeAlertPopup}
        onHide={handleCloseUserUnSubscribeAlertPopup}
        centered
        dialogClassName="sign-up-modal login-modal"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="auth-container">
            <div className="auth-sec auth-right-sec">
              <div className="auth-info">
                <h2>Alert</h2>
              </div>
              <div>
                Do you want to unsubscribe this club?
              </div>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-auto d-flex flex-wrap">
              <button className="btn btn-primary mx-3 mb-2" onClick={() => onCreateUnSubscriber()}>Yes</button>
            </div>
            <div className="ms-auto col-auto">
              <button className="btn btn-danger mx-3 mb-2" onClick={() => handleCloseUserUnSubscribeAlertPopup()}>No</button>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showPaymentSuccessPopup}
        onHide={handleClosePaymentSuccessPopup}
        centered
        dialogClassName="sign-up-modal login-modal"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="auth-container">
            <div className="auth-sec auth-right-sec">
              <div className="auth-info">
                <h2>Success</h2>
              </div>
              <div>
                {selectedPaymet === 'package' ? `You have successfully subscribed to ${club[0]?.name}` : 'You have successfully purchased'}
              </div>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-auto d-flex flex-wrap">
            </div>
            <div className="ms-auto col-auto">
              {selectedPaymet === 'package' ? <button className="btn btn-primary mx-3 mb-2" onClick={() => handleClosePaymentSuccessPopup()}>Ok</button> :
                <NavLink className="btn btn-primary mx-3 mb-2" to={`/club-detail/${selectedPost?._id}/${selectedPost?.club_id}`}>Ok</NavLink>}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
        </Modal.Footer>
      </Modal>
    </div >
  );
};
const mapStateToProps = (state: any) => {
  return {
    UserReducer: state.UserReducer,
    LoginUserIdReducer: state.LoginUserIdReducer,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    loading: (data: any) => dispatch(Loading(data)),
  };
};

const connectedNavBar = connect(mapStateToProps, mapDispatchToProps)(ClubsPage);
export { connectedNavBar as ClubsPage };
