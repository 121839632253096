import moment from "moment";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { LivestreamService } from "../../../app/service/livestream";
import CardTicketImage from "../../../assets/img/livestream-detail-img.png";
import Banner from "../../../assets/img/live-stream.webp";
import { Loading } from "../../../redux/actions";
import { connect, useDispatch } from "react-redux";

interface Props {
  userId: string;
  UserReducer?: any;
  loading?: (data: any) => void;
  LoginUserIdReducer?: any;
}

const PurchasedTicketCancelledPage: React.FC<Props> = (props: Props) => {
  // export const PurchasedTicketCancelledPage = () => {
  let userId = sessionStorage.getItem("isLogin") || "";
  const [livestream, setLivestream] = useState<any[] | []>([]);
  const dispatch = useDispatch();

  useEffect(() => {
    getlivestream();
  }, []);

  const getlivestream = () => {
    if (props.loading) {
      dispatch(props.loading(true));
    }
    LivestreamService.getCanceledLiveStreamByUserID(userId).then((res) => {
      if (res) {
        setLivestream([...res]);
        if (props.loading) {
          dispatch(props.loading(false));
        }
      }
    });
  };
  console.log(livestream)
  return (
    <div
      id="subs-liv-cancelled-tab"
      role="tabpanel"
      aria-labelledby="subs-liv-cancelled-tab"
    >
      {/* Card Tickets */}
      <div className="row">
        {livestream.map((data: any, index: number) => {
          
          return (
            <>
           {data?.live_stream_data?.length != 0 && (
           
            <div key={index} className="col-sm-6 col-lg-4 col-xxl-3 mb-3 mt-3">
              {/* <div
                className="card-livestream"
                style={{
                  backgroundImage: `url(${
                    data?.live_stream_data[0]?.thumbnail
                      ? data?.live_stream_data[0]?.thumbnail
                      : Banner
                  })`,
                  backgroundPosition: "center center",
                  backgroundSize: "cover",
                }}
              >
                <div className="card-livestream-content">
                  {/* <a
                  href="javascript:void(0)"
                  onClick={() => { }}
                  className="ratio ratio-1x1 mb-3 video-img"
                >
                  <video
                    poster=""
                    className="my_club_media"
                    controls
                    controlsList="nodownload"
                    src={data?.video_url}
                  >
                    <source src="" type="video/mp4" />
                    Your browser does not support HTML video.
                  </video>
                </a> 

                  <h3 className="card-livestream-title">
                    <a
                      href="javascript:void(0)"
                      onClick={() => {}}
                      title="title"
                    >
                      {data?.live_stream_data[0]?.title}
                    </a>
                  </h3>
                  <Link to="asdf" className="dive-in-channel position-relative">
                    @{data?.user_info[0]?.name}
                  </Link>
                  <span className="card-livestream-date">
                    {moment(data?.live_stream_data[0]?.schedule_From)
                      .utc()
                      .format("lll")}{" "}
                    IST
                  </span>
                  <div className="card-ticket-footer refund-info">
                    <span>Refund initiated</span>
                    <span className="text-danger">Refund processed</span>
                  </div>
                  {/* <Link to={`/livestreams/livestream-detail/${data?._id}`} className="btn btn-outline-black btn-rounded">
                    View Details
                  </Link> 
                </div>
              </div> */}

              <div className="card-ticket record-unavailable">
                <figure className="card-ticket-img ratio ratio-4x3">
                  <img
                    src={
                      data?.live_stream_data[0]?.thumbnail
                        ? encodeURI(data?.live_stream_data[0]?.thumbnail)
                        : Banner
                    }
                  />
                </figure>
                <div className="card-ticket-content">
                  <span className="card-ticket-content-title">
                    <a
                      href="javascript:void(0)"
                      onClick={() => { }}
                      title="title"
                    >
                      {data?.live_stream_data[0]?.title}
                    </a>
                  </span>
                  <strong className="card-ticket-content-authorname">
                    {data?.user_info[0]?.name}
                  </strong>
                  <span className="card-ticket-content-time">
                    {moment(data?.live_stream_data[0]?.schedule_From)
                      .utc()
                      .format("lll")}{" "}
                    IST
                  </span>
                </div>
                <div className="card-ticket-footer refund-info">
                  <span>Refund initiated</span>
                  <span className="text-danger">Refund processed</span>
                </div>
              </div>
            </div>
              )} 
              </>
          );
        })}
        {/* <div className="col-md-4">
            <div
              className="card-livestream"
              style={{
                backgroundImage: `url(${Banner})`,
                backgroundPosition: "center center",
                backgroundSize: "cover",
              }}
            >
              <div className="card-livestream-content">
                <a
                  href="javascript:void(0)"
                  onClick={() => { }}
                  className="ratio ratio-1x1 mb-3 video-img"
                >
                  <video
                    poster=""
                    className="my_club_media"
                    controls
                    controlsList="nodownload"
                  >
                    <source src="" type="video/mp4" />
                    Your browser does not support HTML video.
                  </video>
                </a>

                <h3 className="card-livestream-title">
                  <a href="javascript:void(0)" onClick={() => { }} title="title">
                    Shadows of the past
                  </a>
                </h3>
                <Link to="asdf" className="dive-in-channel position-relative">
                  @amitabhbachchan
                </Link>
                <span className="card-livestream-date">
                  24 Aug, 8:00 pm IST
                </span>
                <a
                  href="javascript:void(0)"
                  className="btn btn-outline-black btn-rounded"
                  onClick={() => { }}
                >
                  View Details
                </a>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-lg-4 mb-3">
            <div
              className="card-livestream"
              style={{
                backgroundImage: `url(${Banner})`,
                backgroundPosition: "center center",
                backgroundSize: "cover",
              }}
            >
              <div className="card-livestream-content">
                <a
                  href="javascript:void(0)"
                  onClick={() => { }}
                  className="ratio ratio-1x1 mb-3 video-img"
                >
                  <video
                    poster=""
                    className="my_club_media"
                    controls
                    controlsList="nodownload"
                  >
                    <source src="" type="video/mp4" />
                    Your browser does not support HTML video.
                  </video>
                </a>

                <h3 className="card-livestream-title">
                  <a href="javascript:void(0)" onClick={() => { }} title="title">
                    Shadows of the past
                  </a>
                </h3>
                <Link to="asdf" className="dive-in-channel position-relative">
                  @amitabhbachchan
                </Link>
                <span className="card-livestream-date">
                  24 Aug, 8:00 pm IST
                </span>
                <a
                  href="javascript:void(0)"
                  className="btn btn-outline-black btn-rounded"
                  onClick={() => { }}
                >
                  View Details
                </a>
              </div>
            </div>
          </div> */}
      </div>
      {/* <div className="row">
        <div className="col-md-3 mb-4">
          <div className="card-ticket record-unavailable">
            <figure className="card-ticket-img ratio ratio-4x3">
              <img src={CardTicketImage} />
            </figure>
            <div className="card-ticket-content">
              <span className="card-ticket-content-title">
                Dhindora uncensored
              </span>
              <strong className="card-ticket-content-authorname">
                Bhuvan Bam
              </strong>
              <span className="card-ticket-content-time">
                12th February, 2:00 pm IST
              </span>
              <ul className="card-ticket-price-info">
                <li>
                  <span>Ticket Price</span>
                  <span>500 INR</span>
                </li>
                <li>
                  <span>Attendees</span>
                  <span>1</span>
                </li>
                <li>
                  <strong>Total</strong>
                  <strong>500 INR</strong>
                </li>
              </ul>
            </div>
            <div className="card-ticket-footer refund-info">
              <span>Refund initiated</span>
              <span className="text-danger">Refund processed</span>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
};
const mapStateToProps = (state: any) => {
  return {
    UserReducer: state.UserReducer,
    LoginUserIdReducer: state.LoginUserIdReducer,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    loading: (data: any) => dispatch(Loading(data)),
  };
};

const connectedNavBar = connect(
  mapStateToProps,
  mapDispatchToProps
)(PurchasedTicketCancelledPage);
export { connectedNavBar as PurchasedTicketCancelledPage };