import React, { useEffect, useState, useRef } from "react";
import dummyImg from "../assets/img/dummy-img.svg";
import moment from "moment";
import { LivestreamService } from "../app/service/livestream";
import { reFreshChat } from "../config/constant";

interface Props {
  streamId: string;
  club_id: string;
  onEndStreming: () => void;
  stopedLiveStream: boolean;
  ref?: any;
  // watchingCount?: (data: any) => void;
}
export const LivestreamComments: React.FC<Props> = (props: Props) => {
  const [postComments, setPostComments] = useState<any[]>([]);
  const [blockedUserdData, setBlockedUserdData] = useState<any[]>([]);
  const [mainComments, setMainComments] = useState<string>();
  const [selectedComments, setSelectedComments] = useState(-1);
  const [selectedSubComments, setSelectedSubComments] = useState(-1);
  const [subSubComments, setSubSubComments] = useState<any[]>([]);
  const [livestream, setLivestream] = useState<any[] | []>([]);
  const [watchCount, setWatchCount] = useState<any>('');

  let userId = sessionStorage.getItem("isLogin") || "";
  const loginUserImage = sessionStorage.getItem("loginUserImage");
  const chatContainer = React.useRef<HTMLDivElement>(null);
  useEffect(() => {
    LivestreamService.getlivestreamById(props.streamId).then((res) => {
      if (res) {
        setLivestream([...res]);
        setWatchCount(res[0]?.watching_count);
      }
    });


    getlivestream();
  }, []);

  const timer = () =>
    setTimeout(() => {
      getlivestream();
      if (props.stopedLiveStream) {
        clearTimeout(timer());
      }
    }, reFreshChat);

  const getlivestream = () => {
    LivestreamService.getlivestreamById(props.streamId).then((res) => {
      if (res) {
        setPostComments([...res[0]?.comments]);
        setBlockedUserdData([...res[0]?.blocked_users]);
        setWatchCount(res[0]?.watching_count);
        // props.watchingCount(res);
        timer();
        scrollToMyRef();
      }
    });
  };

  const commentsDays = (date: any) => {
    const now = moment();
    const expiration = moment(date);
    const diff = expiration.diff(now);
    const diffDuration = moment.duration(diff);
    return `${diffDuration.days()
      ? `${diffDuration.days().toString().replace("-", "")} days ago`
      : ""
      } ${diffDuration.hours()
        ? `${diffDuration.hours().toString().replace("-", "")} hours ago`
        : ""
      }  ${diffDuration.minutes()
        ? `${diffDuration.minutes().toString().replace("-", "")} minutes ago`
        : ""
      } `;
  };
  const onPostComment = (event: any) => {
    setMainComments(event.target.value);
    if (event.key === "Enter" && !event.shiftKey) {
      if (event.target.value) {
        const data = {
          collectionName: livestream[0]?.collectionName,
          _id: livestream[0]?._id,
          club_id: livestream[0]?.club_id,
          user_id: userId,
          comments: event.target.value,
        };
        LivestreamService.liveStreamcomments(data).then((res) => {
          if (res) {
            getlivestream();
            setMainComments("");
          }
        });
      }
    }
  };

  const scrollToMyRef = () => {
    if (chatContainer.current) {
      const scroll = chatContainer.current.scrollHeight - chatContainer.current.clientHeight;
      chatContainer.current.scrollTo(0, scroll);
    }
  };

  const isCheckBlock = (userId: string) => {
    const isBlockedUser = blockedUserdData.find((data) => data?.user_id === userId);
    if (isBlockedUser) {
      return true;
    } else {
      return false;
    }
  }
  const onUserComment = (userId: string) => {
    const data = {
      "user_id": userId,
      "stream_id": livestream[0]?._id,
      "collectionName": livestream[0]?.collectionName
    }
    LivestreamService.updateLsBlockList(data).then((res) => {
      if (res) {
        getlivestream();
        setMainComments("");
      }
    });
  }
  return (
    <div
      className="livestream-streaming-comments-sec"
      style={{
        zIndex: 1111,
        background: "white",
      }}
    >
      <div className="ratio ratio-16x9" style={{ height: "100%", minHeight: "400px" }}>
        <div className="w-100" style={{ padding: "0px 0px 20px 0px", height: "100%" }}>
          <div ref={chatContainer} className="chat">
            {postComments.map((comment, index) => {
              return (
                <div key={index} className="commented-info">
                  <div className="commented-info-left">
                    <img
                      src={
                        comment?.user_info[0]?.image
                          ? comment?.user_info[0]?.image
                          : dummyImg
                      }
                      alt="dummy image"
                    />
                  </div>
                  <div className="commented-info-right">
                    <div className="comment">
                      <div className="comment-name">
                        <a>{comment?.user_info[0]?.name}</a>
                        <span className="comment-date">
                          {commentsDays(comment?.created_at)}
                        </span>
                      </div>
                      <p className="comment-desc">{comment?.comments}</p>
                      {comment?.user_id !== userId && isCheckBlock(comment?.user_id) &&
                        <span className="pointer" style={{ color: 'blue' }} onClick={() => onUserComment(comment?.user_id)}>Unblock</span>}
                      {comment?.user_id !== userId && !isCheckBlock(comment?.user_id) && <span onClick={() => onUserComment(comment?.user_id)} style={{ color: 'blue' }} className="pointer">Block</span>}
                      {selectedComments === index && (
                        <div>
                          {comment?.replies?.length > 0 ? (
                            comment?.replies?.map(
                              (subComment: any, subIndex: number) => {
                                return (
                                  <div
                                    key={subIndex}
                                    className="commented-info"
                                  >
                                    <div className="commented-info-left">
                                      <img
                                        src={
                                          subComment?.user_info[0]?.image
                                            ? subComment?.user_info[0]?.image
                                            : dummyImg
                                        }
                                        alt="dummy image"
                                      />
                                    </div>
                                    <div className="commented-info-right">
                                      <div className="comment">
                                        <div className="comment-name">
                                          <a>
                                            {subComment?.user_info[0]?.name}
                                          </a>
                                          <span className="comment-date">
                                            {commentsDays(
                                              subComment?.created_at
                                            )}
                                          </span>
                                        </div>
                                        <p className="comment-desc">
                                          {subComment?.comments}
                                        </p>
                                        {selectedSubComments === subIndex &&
                                          subComment.replies.map(
                                            (
                                              subSubComment: any,
                                              subSubIndex: number
                                            ) => {
                                              return (
                                                <div
                                                  key={subIndex}
                                                  className="commented-info"
                                                >
                                                  <div className="commented-info-left">
                                                    <img
                                                      src={
                                                        subComment?.user_info[0]
                                                          ?.image
                                                          ? subComment
                                                            ?.user_info[0]
                                                            ?.image
                                                          : dummyImg
                                                      }
                                                      alt="dummy image"
                                                    />
                                                  </div>
                                                  <div className="commented-info-right">
                                                    <div className="comment">
                                                      <div className="comment-name">
                                                        {" "}
                                                        <a href="">
                                                          {subSubComment?.name}
                                                        </a>
                                                        <span className="comment-date">
                                                          2 days ago
                                                        </span>
                                                      </div>
                                                      <p className="comment-desc">
                                                        {subSubComment?.message}
                                                      </p>
                                                      <ul className="comment-count-info">
                                                        <li>
                                                          <i className="bi bi-heart pointer"></i>
                                                          {subSubComment?.likes}
                                                        </li>
                                                        <li>
                                                          <i className="bi bi-chat pointer"></i>
                                                          {subSubComment
                                                            ?.comments?.length
                                                            ? subSubComment
                                                              ?.comments
                                                              ?.length
                                                            : 0}
                                                        </li>
                                                      </ul>
                                                      {subSubIndex ===
                                                        subSubComments.length -
                                                        1 && (
                                                          <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Replay"
                                                          />
                                                        )}
                                                    </div>
                                                  </div>
                                                </div>
                                              );
                                            }
                                          )}
                                      </div>
                                    </div>
                                  </div>
                                );
                              }
                            )
                          ) : (
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Replay"
                              defaultValue={mainComments}
                            />
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="comment-sec">
            <div className="row mb-2">
              <div className="col-auto d-flex flex-wrap">
                <span className="comment-count mb-0">
                  {postComments.length} comments
                </span>
              </div>
              <div className="ms-auto col-auto">
                <i
                  onClick={() => getlivestream()}
                  className="bi bi-arrow-repeat"
                  style={{ cursor: "pointer", fontSize: "18px" }}
                ></i>
              </div>
            </div>

            <div className="comment-box">
              <div className="comment-box-photo">
                <img src={loginUserImage || dummyImg} alt="dummy image" />
              </div>
              <div className="comment-add">
                <textarea
                  className="form-control"
                  placeholder="Add Comments"
                  onKeyPress={(event) => onPostComment(event)}
                  onChange={(event) => onPostComment(event)}
                  value={mainComments}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="livestream-streaming-info" style={{
        width: '500px', position: 'absolute', bottom: '-79px', right: '250%'
      }}>
        <div className="d-flex w-100">
          <span className="me-auto">
            <span className="livestream-streaming-users me-3">
              <i className="bi bi-eye-fill me-1"></i>{watchCount} watching now
            </span>
            <span className="livestream-streaming-time">
              <i className="bi bi-camera-reels-fill me-1"></i>Started at{" "}
              {moment(livestream[0]?.schedule_From)
                .utc()
                .format("lll")}{" "}
              IST
            </span>
          </span>
          {/* <span className="ms-auto">
            <span className="livestream-streaming-likes ">
              <i className="bi bi-hand-thumbs-up me-1"></i>
              1K likes
            </span>
            <span className="livestream-streaming-share ms-3">
              <i className="bi bi-share me-1"></i>URL
            </span>
          </span> */}
        </div>
      </div>
    </div >
  );
};
