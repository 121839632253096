import moment from "moment";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { NftService } from "../../../app/service/nft.service";
import Feature from "../../../assets/img/feature-img.jpg"
import { SessionExpirePopup } from "../../../components/session-expire-popup";

type Props = {};

const AllNFT = (props: Props) => {
  let userId = sessionStorage.getItem("isLogin") || "";
  const history = useHistory();
  const [creatorNfts, setCreatorNfts] = useState<any[] | []>([]);

  const [loading, setloading] = useState(false);
  const [isSessionExpired, setIsSessionExpired] = useState(false);


  useEffect(() => {
    setloading(true);
    getNftBySpecificCreatorID();
  }, []);
  const getNftBySpecificCreatorID = () => {
    if (userId) {
      NftService.getNftBySpecificCreatorID(userId).then((res) => {
        console.log('res getNftByCreator', res);
        const data = res.sort((a:any, b:any) => (a.createdAt < b.createdAt) ? 1 : ((b.createdAt < a.createdAt) ? -1 : 0));

        setCreatorNfts(data);
      });
    } else {
      console.log('bbbbbb');
      setIsSessionExpired(true);
    }
  }
  return (
    <div className="tab-content" id="pills-tabContent">
      {/* {isSessionExpired && <SessionExpirePopup show={true}></SessionExpirePopup>} */}
      
      <div
        className="tab-pane active"
        id="details"
        role="tabpanel"
        aria-labelledby="details-tab"
      >
        <div className="tab-content" id="pills-tabContent">
          <div
            className="tab-pane active"
            id="details"
            role="tabpanel"
            aria-labelledby="details-tab"
          >
            <div className="livestream-table-view table-responsive">
              <table className="table table-hover">
                <thead>
                  <tr>
                    <th scope="col"></th>
                    <th scope="col">Title</th>
                    <th scope="col">Price per copy</th>
                    <th scope="col">Date of last sale</th>
                    <th scope="col">Number of copies sold</th>
                    <th scope="col">Revenues</th>
                    <th scope="col">Convenience Fee</th>
                    <th scope="col">Earnings</th>
                    <th scope="col">Rerun Revenue</th>

                  </tr>
                </thead>
                <tbody>
                  {creatorNfts.map((data: any, index: number) => {
                    <>{console.log(data)}</>
                    return <tr key={index}>
                      <th scope="row">
                        <a href="#/">
                          <img
                            src={data?.asset_url ? data?.asset_url : Feature}
                            alt="draft"
                            style={{ height: "40px", width: "40px" }}
                          />
                        </a>
                      </th>
                      <td>
                        <a href="#/">{data?.title}</a>
                        <div className="d-flex fs-sml">
                          <span>
                            {/* <i className="bi bi-star me-1"></i> */}
                            {data?.price}
                          </span>
                          <span>{moment(data?.createdAt).utc().format('DD-MM-YYYY hh:mm:ss A')}</span>
                        </div>
                      </td>
                      <td>{data?.price_per_copy}</td>
                      <td>{moment(data?.date_of_last_sale).utc().format('DD-MM-YYYY hh:mm:ss A')}</td>
                      <td>{data?.total_sold}/{data?.no_of_copies}</td>
                      <td>{data?.total_revenue}</td>
                      <td>{data?.convenience_fee}</td>
                      <td>{data?.earnings}</td>
                      <td>Rerun Revenue</td>
                    </tr>
                  })}

                  {/* <tr>
                    <th scope="row">
                      <a href="#/">
                        <img
                          src="images/feature-img.jpg"
                          alt="draft"
                          style={{ height: "40px", width: "40px" }}
                        />
                      </a>
                    </th>
                    <td>
                      <a href="#/">Dhindora Uncensored</a>
                      <div className="d-flex fs-sml">
                        <span>
                          <i className="bi bi-star me-1"></i>43
                        </span>
                        <span className="ms-3">02 Jul 21</span>
                      </div>
                    </td>
                    <td>0.5 ETH</td>
                    <td>-</td>
                    <td>0/1</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                  </tr> */}
                </tbody>
              </table>
            </div>

          </div>
        </div>
      </div>
    </div>
  );
};

export default AllNFT;
