import React, { useEffect, useState } from "react";
import { Grid, TextField } from "@mui/material";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
import dummyImg from "../../../assets/img/profile.svg";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import { Modal } from "react-bootstrap";
import { ClubService } from "../../../app/service/club.service";
import { toast } from "react-toastify";
import { Loading } from "../../../redux/actions";
import { connect, useDispatch } from "react-redux";
import { UserService } from "../../../app/service/user.service";
import { S3Helper } from "../../../app/utils/s3-helper";
import { CLOUDFRONT_URL, S3_URL } from "../../../config/constant";
import { useHistory } from "react-router-dom";
import { dataDecryption } from "../../../app/utils/axios";
import { UserData } from "../../../app/model/user";
import { LoginUserId, UserLogin, UserLogout } from "../../../redux/actions";

interface Props {
  UserReducer?: any;
  loading?: (data: any) => void;
  loginUserId?: (data: any) => void;
  LoginUserIdReducer?: any;
  logout?: (data: any) => void;
}
const PersonalDetail: React.FunctionComponent<Props> = (props) => {
  let id = sessionStorage.getItem("isLogin") || "";

  const [dateValue, setDateValue] = React.useState<Date | null>(new Date());
  const [timeValue, setTimeValue] = React.useState<Date | null>(
    new Date("2018-01-01T00:00:00.000Z")
  );
  const [gender, setGender] = React.useState("");
  const [genre, setGenre] = React.useState<any[] | []>([]);
  const [name, setName] = React.useState("");
  const [city, setCity] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [profileImage, setProfileImage] = useState("");
  const [awsInfo, setAwsInfo] = useState<any>(null);
  const baseUrl = S3_URL;
  const [userFormData, setUserFormData] = useState<any>();
  const [phoneError, setPhoneError] = React.useState("");
 
  const [subGenre, setSubGenre] = React.useState("");
  const [subGenere, setSubGenere] = useState<any[] | []>([]);
  const [isUserLogin, setUserLogin] = useState(
    sessionStorage.getItem("isLogin") || ""
  ); 
  const [loginUserImage, setloginUserImage] = useState(
    sessionStorage.getItem("loginUserImage") || ""
  );
  const [selectGenre, setSelectGenre] = React.useState("");

  const [userData, setUserData] = useState<any[] | []>([]);

  const handleChange = (event: SelectChangeEvent) => {
    setGender(event.target.value);
  };
  const [textareaValue, setTextareaValue] = React.useState("");
  const handleTextareaChange = (event: any) => {
    setTextareaValue(event.target.value);
  };
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();
  console.log(phone);
  console.log(name);
  console.log(genre);
  console.log(gender);
  console.log(email);
  console.log(textareaValue);
  console.log(profileImage);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


 
  useEffect(() => {
    // if (props.loading) {
    //   dispatch(props.loading(true));
    // }

    ClubService.getGenreLookup().then((res) => {
      if (res) {
        setGenre(res);
      } else {
        toast.error(res?.message);
      }
    });
    if (id) {
      const data = {
        user_id: id,
      };
      ClubService.getUserFolderInfo(data).then((res) => {
        if (res) {
          setAwsInfo(res);
        } else {
          toast.error(res?.message);
        }
      });
      getUserData(id);
    }
  }, []);

  const onchangeName = (event: any) => {
    setName(event.target.value);
  };

  const onSaveMyProfile = () => {

    if (props.loading) {
      dispatch(props.loading(true));
    }
    const value = {
      _id: id,
      name: name ? name : "",
      email: email ? email : "",
      phone: phone ? phone : "",
      country: "",
      state: "",
      city: city ? city : "",
      dob: dateValue ? dateValue : "",
      gender: gender ? gender : "",
      genre: selectGenre ? selectGenre : "",
      sub_genre: subGenre ? subGenre : "",
      facebook_url: "",
      instagram_url: "",
      twitter_url: "",
      youtube_url: "",
      image: profileImage ? profileImage : "",
      about_you: textareaValue ? textareaValue : "",
    };
    if (!phoneError) {
      UserService.updateUsers(value).then((res) => {
        if (res) {
          // getUserData(id);
          // sessionStorage.setItem("userImages", "");
          if (props.loading) {
            dispatch(props.loading(false));
          }
          toast.success("Profile updated successfully");
        } else {
          if (props.loading) {
            dispatch(props.loading(false));
          }
        }
      });
    } else {
      if (!value.phone) {
        setPhoneError("Please enter phone number");
      }
    }
  };

  const getUserData = (id: string) => {
    // if (props.loading) {
    //   dispatch(props.loading(true));
    // }
    UserService.getUser(id).then((res) => {
      if (res) {
        setUserData([...res]);
        sessionStorage.setItem("userImages", "");
        if (res.length > 0) {
          setName(res[0]?.name);
          setDateValue(res[0]?.dob ? res[0]?.dob : null);
          setGender(res[0]?.gender);
          setCity(res[0]?.city);
          setEmail(res[0]?.email);
          setPhone(res[0]?.phone);
          setSelectGenre(res[0]?.genre);
          setProfileImage(res[0]?.image);
          setSubGenre(res[0]?.sub_genre);
          setTextareaValue(res[0]?.about_you);
          sessionStorage.setItem("userImages", res[0]?.image);
          // setSelectedGenre(res[0]?.genre);
          // if (res[0]?.country) {
          //   onSelectCountry(res[0]?.country ? res[0]?.country : '');
          // }
          // if (res[0]?.state) {
          //   onSelectState(res[0]?.country ? res[0]?.country : '', res[0]?.state ? res[0]?.state : '');
          // }
          // setSelectedCountry(res[0]?.country ? res[0]?.country : '');
          // setSelectedState(res[0]?.state ? res[0]?.state : '');
          // setSelectedCity(res[0]?.city ? res[0]?.city : '');
        }
        if (props.loading) {
          dispatch(props.loading(false));
        }
      }
    });
  };
  const handleChangeGener = (gener: any) => {
    setSelectGenre(gener);
    ClubService.fetchSubGenereList(gener).then((res) => {
      if (res) {
        setSubGenere([...res]);
      }
    });
  };
  const handleChangeCity = (event: any) => {
    setCity(event);
  };

  const handleChangeEmail = (event: any) => {
    setEmail(event);
  };

  const handleChangePhone = (event: any) => {
    setPhoneError("");
    if (event) {
      if (event && event.length <= 10) {
        const re = /^[5-9]{1}[0-9]{9}$/;
        if (re.test(event.trim())) {
          setPhone(event.trim());
        } else {
          setPhone(event.trim());
          setPhoneError("Please enter proper phone number");
        }
      } else if (!(event.length >= 10)) {
        setPhone("");
        setPhoneError("Please enter phone number");
      }
    } else {
      setPhone("");
    }
  };
  const handleChangeSubGenre = (event: any) => {
    setSubGenre(event);
  };
  const onUploadProgress = (data: any) => {
    const progress = Math.round((data.loaded / data.total) * 100);
    // setProgress(progress);
  };
  const onUploadContent = async (event: any) => {
    setProfileImage("");
    ClubService.signedCall({
      fileName: `${awsInfo?.folderPath}/${event.target.files[0]?.name}`,
      fileType: event.target.files[0]?.type,
    }).then(async (res) => {
      console.log(res, "signed Response");
      const result = await S3Helper.uploadFilesToS3BySigned(
        res,
        event.target.files[0],
        event.target.files[0]?.type
      );
      console.log("result", result);
      // if (props.loading) {
      //   dispatch(props.loading(false));
      // }
      setProfileImage(
        `${CLOUDFRONT_URL}${awsInfo?.folderPath}/${event.target.files[0]?.name}`
      );
      sessionStorage.setItem("userImages", `${CLOUDFRONT_URL}${awsInfo?.folderPath}/${event.target.files[0]?.name}`);
        

      
      toast.success("Uploaded Successfully");
    });
    // if (event.target.files && event.target.files[0]) {
    //   const result = await S3Helper.uploadFilesToS3(
    //     event.target.files[0],
    //     awsInfo
    //   );
    //   setProfileImage(
    //     result
    //   );
    //   // setProfileImage(
    //   //   `${baseUrl}${awsInfo.folderPath}/${event.target.files[0].name}`
    //   // );
    //   toast.success("Uploaded Successfully");
    // }
  };

  const onDeleteProfile = () => {
    setProfileImage("");
  };

  const onSaveProfilePic = () => {
    onSaveMyProfile();
    handleClose();
  };
  const onCancelMyProfile = () => {};
  const history = useHistory();




  return (
    <>
      <div>
        <div className="col-md-12 mb-3">
          <form>
            <section className="myprofile-info settings-sec">
              <h3>Personal Information</h3>
              <div className="row">
                <div className="col-md-12">
                  <span className="mb-3">Your Photo</span>
                  <div className="d-flex flex-wrap mb-5 mt-2">
                    <div
                      className="uploaded-img"
                      style={{ border: "1px solid #ddd" }}
                    >
                      <img
                        src={profileImage ? profileImage : dummyImg}
                        alt="profile-img"
                        style={{
                          width: profileImage ? "66px" : "70px",
                          height: profileImage ? "66px" : "35px",
                          display: profileImage ? "" : "block",
                          margin: profileImage ? "0 auto" : "auto",
                        }}
                      />
                    </div>
                    <div className="upload-btn-sec w-auto align-self-center">
                      <button
                        onClick={handleShow}
                        type="button"
                        className="btn btn-black"
                      >
                        Upload new photo
                      </button>
                      {/* <button
                        type="button"
                        className="btn btn-outline-black"
                        onClick={onDeleteProfile}
                      >
                        Delete
                      </button> */}
                    </div>
                  </div>
                </div>
              </div>
              <TextField
                className="mb-3"
                fullWidth
                id="outlined-basic"
                label="Your name (This appears on your profile)"
                variant="outlined"
                onChange={(e) => onchangeName(e)}
                value={name}
              />

              <Grid container spacing={2} className="mb-3">
                <Grid item xs={12} sm={6}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      disableFuture
                      label="Date of Birth"
                      value={dateValue}
                      onChange={(newValue) => {
                        setDateValue(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField {...params} fullWidth />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Gender
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={gender}
                      label="Age"
                      onChange={handleChange}
                    >
                      <MenuItem value="M">Male</MenuItem>
                      <MenuItem value="F">Female</MenuItem>
                      <MenuItem value="O">Other</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={2} className="mb-3">
                <Grid item xs={12} sm={6} className="profile-email-field">
                  <TextField
                    id="email"
                    label="Email"
                    variant="outlined"
                    fullWidth
                    value={email}
                    onChange={(e) => handleChangeEmail(e.target.value)}
                    margin="normal"
                    disabled={true}
                  />
                </Grid>
                <Grid item xs={12} sm={6} className="profile-email-field">
                  <TextField
                    id="phone"
                    label="Phone"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={phone}
                    type="number"
                    onChange={(e) => handleChangePhone(e.target.value)}
                    placeholder="Enter your phone number to verify"
                  />

                  {phoneError && (
                    <p
                      style={{
                        color: "red",
                        marginBottom: "0",
                        fontSize: "14px",
                      }}
                    >
                      {phoneError}
                    </p>
                  )}
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Genre</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={selectGenre}
                      label="Age"
                      onChange={(event) =>
                        handleChangeGener(event?.target.value)
                      }
                    >
                      {genre.map((data: any, index: number) => {
                        return (
                          <MenuItem key={index} value={data}>
                            {data}
                          </MenuItem>
                        );
                      })}
                      {/* <MenuItem value="Genre1">Genre1</MenuItem>
                      <MenuItem value="Genre2">Genre2</MenuItem>
                      <MenuItem value="Genre3">Genre3</MenuItem> */}
                    </Select>
                  </FormControl>
                </Grid>
                {/* <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Sub Genre
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={subGenre}
                      label="Age"
                      onChange={(event) =>
                        handleChangeSubGenre(event.target.value)
                      }
                    >
                      {subGenere.map((data: any, index: number) => {
                        return (
                          <MenuItem key={index} value={data}>
                            {data}
                          </MenuItem>
                        );
                      })}
                      {/* <MenuItem value="SubGenre">Sub Genre</MenuItem>
                      <MenuItem value="SubGenre1">Sub Genre1</MenuItem>
                      <MenuItem value="SubGenre2">Sub Genre2</MenuItem>
                      <MenuItem value="SubGenre3">Sub Genre3</MenuItem> */}
                {/* </Select> */}
                {/* </FormControl> */}
                {/* </Grid> */}
                <Grid item xs={12} sm={6} className="profile-email-field">
                  <TextField
                    id="location"
                    label="Location"
                    variant="outlined"
                    fullWidth
                    value={city}
                    onChange={(e) => handleChangeCity(e.target.value)}
                    placeholder="Enter your city"
                    margin="normal"
                  />
                </Grid>
              </Grid>

              <TextField
                id="about-you"
                label="About you (optional)"
                multiline
                rows={6}
                value={textareaValue}
                fullWidth
                onChange={handleTextareaChange}
                margin="normal"
                placeholder="Write something about yourself"
              />

              <div className="row justify-content-end my-3">
                <div className="d-flex justify-content-between">
                  <button
                    type="button"
                    className="btn btn-outline-orange btn-wide "
                    onClick={() => history.goBack()}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="btn btn-outline-black ms-2"
                    onClick={onSaveMyProfile}
                  >
                    Save
                  </button>
                </div>
              </div>
            </section>
          </form>
        </div>
      </div>
     
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Upload Profile Photo</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <input
            type="file"
            accept="image/jpg, image/jpeg, image/png, image/svg, image/gif"
            onChange={(e) => onUploadContent(e)}
          />
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-black"
            type="button"
            onClick={onSaveProfilePic}
          >
            Save Changes
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    UserReducer: state.UserReducer,
    LoginPopupReducer: state.LoginPopupReducer,
    LoginUserIdReducer: state.LoginUserIdReducer,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    loading: (data: any) => dispatch(Loading(data)),
    logout: () => dispatch(UserLogout()),
    login: (data: UserData) => dispatch(UserLogin(data)),
  };
};

const connectedNavBar = connect(
  mapStateToProps,
  mapDispatchToProps
)(PersonalDetail);
export { connectedNavBar as PersonalDetail };

// export default PersonalDetail;
