import { Switch, Route } from "react-router";
import { PostsDraftsPage } from "../pages/dashboard/posts/drafts";
import { PostsPublishedPage } from "../pages/dashboard/posts/published";

const PostsRoutes = () => {
    return (
        <Switch>
            <Route exact path="/dashboard/posts/published" component={PostsPublishedPage}></Route>
            <Route exact path="/dashboard/posts/drafts" component={PostsDraftsPage}></Route>
        </Switch>
    );
}

export default PostsRoutes;