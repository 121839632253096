import moment from "moment";
import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { LivestreamService } from "../../../../app/service/livestream";
import Capture from "../../../../assets/img/Capture1.png";
import { TooltipWrapper } from "../../../../components/tooltip";
import { Loading } from "../../../../redux/actions";
import iconReschedule from '../../../../assets/img/icon-reschedule.svg';

interface Props {
  userId: string;
  UserReducer?: any;
  loading?: (data: any) => void;
  LoginUserIdReducer?: any;
}

const MyLivestreamsCancelledPage: React.FC<Props> = (props: Props) => {
  let userId = sessionStorage.getItem("isLogin") || "";
  const [livestream, setLivestream] = useState<any[] | []>([]);
  const dispatch = useDispatch();

  useEffect(() => {
    getlivestream();
  }, []);

  const getlivestream = () => {
    if (props.loading) {
      dispatch(props.loading(true));
    }
    LivestreamService.getlivestream("CANCEL", userId).then((res) => {
      if (res) {
        const data = res.sort((a: any, b: any) =>
          a.schedule_From < b.schedule_From
            ? 1
            : b.schedule_From < a.schedule_From
              ? -1
              : 0
        );
        setLivestream([...res]);
        if (props.loading) {
          dispatch(props.loading(false));
        }
      }
    });
  };
  const onDelete = (data: any) => {
    LivestreamService.deleteLivestream(data).then((res) => {
      if (res) {
        getlivestream();
      }
    });
  };
  // reschedule function
  const reSchedule = (data: any) => {
    window.location.href=`#/livestreams/create-livestream/${data._id}`
  };
  return (
    <>
      <div className="tab-content" id="pills-tabContent">
        <div
          className="tab-pane active"
          id="details"
          role="tabpanel"
          aria-labelledby="details-tab"
        >
          <div className="livestream-table-view table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th scope="col"></th>
                  <th scope="col" style={{ minWidth: "200px" }}>
                    Title
                  </th>
                  <th scope="col" style={{ minWidth: "250px" }}>
                    Schedule
                  </th>
                  <th scope="col" style={{ minWidth: "120px" }}>
                    Ticket Price
                  </th>
                  <th scope="col" style={{ minWidth: "70px" }}>
                    Sold
                  </th>
                  <th scope="col" style={{ minWidth: "100px" }}>
                    Action
                  </th>

                </tr>
              </thead>
              <tbody>
                {/* Card Subscription */}
                {livestream.map((data: any, index: number) => {
                  return (
                    <tr>
                      <td>
                        <img
                          src={data?.thumbnail ? data?.thumbnail : Capture}
                          alt="draft"
                          style={{ height: "40px", width: "40px" }}
                        />
                      </td>
                      <td>{data?.title}</td>
                      <td>
                        {moment(data?.schedule_From)
                          .utc()
                          .format("dddd, MMM DD YYYY")},<br/> &nbsp;
                        {moment(data?.schedule_From).utc().format("LT")} -{" "}
                        {moment(data?.schedule_To).utc().format("LT")}
                      </td>
                      <td>INR {data?.price}</td>
                      <td>{data?.tickets}</td>
                      <td className="d-flex align-items-center">
                        {/* reschedule onclick function */}
                        <button className="border-0 bg-transparent pointer text-primary" onClick={(e)=>reSchedule(data)}>
                          {/* <TooltipWrapper
                            placement="top"
                            tooltipText="Reschedule"
                            iconName="bi bi-calendar"
                          /> */}
                          <img src={iconReschedule} alt="" width="20" />
                        </button>
                        <button
                          className="border-0 bg-transparent text-danger"
                          onClick={() => onDelete(data)}
                        >
                          <TooltipWrapper
                            placement="top"
                            tooltipText="Delete"
                            iconName="bi bi-trash3"
                          />
                        </button>
                      </td>
                    </tr>
                  );
                })}
                {livestream.length <= 0 && (
                  <tr className="text-center mb-3">
                    <td colSpan={7}>No livestream data</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          {/* Card Subscription */}
          {/* <div className="card-subscription">
              <div className="card-subscription-left">
                <div className="card-subscription-img">
                  <Link to="">
                    <img src={Capture} alt="draft" style={{ height: "150px" }} />
                  </Link>
                </div>
                <div className="card-subscription-detail">
                  <h3 className="card-subscription-title">
                    <Link to="">
                      Rubaroo
                      <span className="badge rounded-pill bg-primary ms-2">
                        Private
                      </span>
                    </Link>
                  </h3>
                  <div className="subscription-schedule">
                    <strong className="subscription-schedule-date">
                      Saturday, August 7
                    </strong>
                    <strong className="subscription-schedule-time">
                      4:30pm - 5:30pm
                    </strong>
                  </div>
                  <div className="subscription-detail">
                    <span className="">
                      Ticket Price: <strong className="">INR 499</strong>
                    </span>
                  </div>

                </div>
              </div>
              <div className="card-subscription-right">
                <div className="card-subscription-right-btm">
                  <button className="btn btn-outline-black mt-auto">
                    <i className="bi bi-pencil"></i>Reschedule
                  </button>
                  <button className="btn btn-outline-orange mt-auto">
                    Cancel Event
                  </button>
                </div>
              </div>
            </div> */}
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state: any) => {
  return {
    UserReducer: state.UserReducer,
    LoginUserIdReducer: state.LoginUserIdReducer,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    loading: (data: any) => dispatch(Loading(data)),
  };
};

const connectedNavBar = connect(
  mapStateToProps,
  mapDispatchToProps
)(MyLivestreamsCancelledPage);
export { connectedNavBar as MyLivestreamsCancelledPage };