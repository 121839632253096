import React, { useEffect, useState, ChangeEvent } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link, useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { LivestreamService } from "../../../../app/service/livestream";
import { S3Helper } from "../../../../app/utils/s3-helper";
import { CustomUploadModal } from "../../../../components/custom-upload-modal";
import FormBuilder from "../../../../components/form-builder";
import {
  FormControlError,
  FormField,
  FormValidators,
} from "../../../../components/form-builder/model/form-field";
import {
  FormValidator,
  GetControlIsValid,
} from "../../../../components/form-builder/validations";
import { TooltipWrapper } from "../../../../components/tooltip";
import Capture from "../../../assets/img/Capture1.png";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import { S3_URL } from "../../../../config/constant";
interface Props {
  event: any;
}

export const MyLivestreamsPrivatePage: React.FC<Props> = (props: Props) => {
  let { id } = useParams<{ id: string }>();
  const [isLoading, setLoading] = useState(false);
  const [myclubsData, setMyClubData] = useState<any>({});
  const [customSchedule, setCustomSchedule] = useState("");
  const [meetingType, setMeetingType] = useState("INDIVIDUAL");
  const [maxParticipants, setMaxParticipants] = useState(1);
  const history = useHistory();
  const club_id = sessionStorage.getItem("clubId") || "";
  let userId = sessionStorage.getItem("isLogin") || "";
  const [attendeesView, setAttendeesView] = useState("");
  const [attendeesDownload, setAttendeesDownload] = useState("");
  const [attendeesViewChecked, setAttendeesViewChecked] = useState(false);
  const [attendeesDownloadChecked, setAttendeesDownloadChecked] =
    useState(false);
  const updateFormData = (data: any) => {
    setMyClubData(data);
    const errors: FormControlError[] = FormValidator(
      formValidations,
      data.value
    );
    setValidationErrors(errors);
  };
  const [selectedLivestream, setSelectedLivestream] = useState<any[] | []>([]);
  const [awsInfo, setAwsInfo] = useState<any>(null);
  const [progress, setProgress] = useState(0);
  const [duration, setDuration] = useState<any[]>([]);
  const [selectedDuration, setSelectedDuration] = useState(5);
  useEffect(() => {
    durationData();
    if (club_id) {
      const data = {
        club_id: club_id,
        type: 1,
      };
      LivestreamService.getLiveStreamFolderInfo(data).then((res) => {
        if (res) {
          setAwsInfo(res);
        } else {
          toast.error(res?.message);
        }
      });
    }
    if (id !== "1") {
      LivestreamService.getlivestreamById(id).then((res) => {
        if (res) {
          setSelectedLivestream(res);
          setAttendeesViewChecked(res[0]?.VIEW_LIVESTREAM ? true : false);
          setAttendeesDownloadChecked(
            res[0]?.DOWNLOAD_LIVESTREAM ? true : false
          );
          setAttendeesView(res[0]?.VIEW_LIVESTREAM);
          setAttendeesDownload(res[0]?.DOWNLOAD_LIVESTREAM);
          setCount(res[0]?.max_participants);
          setMeetingType(res[0]?.meeting_type);
        } else {
          toast.error(res?.message);
        }
      });
    }
  }, []);

  const durationData = () => {
    const data = duration || [];
    let value = 5;
    for (let index = 1; index <= 12; index++) {
      data.push(value);
      value = value + 5;
    }
    setDuration([...data]);
  };

  const onChangeDuration = (event: any) => {
    if (event.target.value) {
      setSelectedDuration(event.target.value);
    } else {
      setSelectedDuration(5);
    }
  };

  const onSaveLivestream = () => {
    // setLoading(true);
    // const data = myclubsData.value;
    // data.club_id = club_id;
    // data.user_id = userId;
    // data.type = 2;
    // data.type_name = 'PRIVATE_LIVESTREAM';
    // data.meeting_type = meetingType;
    // data.max_participants = meetingType === 'GROUP' ? maxParticipants : '';
    // data.VIEW_LIVESTREAM = attendeesViewChecked ? attendeesView : '';
    // data.DOWNLOAD_LIVESTREAM = attendeesDownloadChecked ? attendeesDownload : '';
    // LivestreamService.createLiveStream(data).then(
    //   res => {
    //     history.push('/dashboard/mylivestreams/drafts');
    //   })
    const data = { ...selectedLivestream[0], ...myclubsData.value };
    setIsFormSubmitted(true);
    const errors: FormControlError[] = FormValidator(formValidations, data);
    setValidationErrors(errors);
    if (errors.length < 1) {
      const bodyData = {
        club_id: club_id,
        user_id: userId,
        type: 2,
        type_name: "PRIVATE_LIVESTREAM",
        meeting_type: meetingType
          ? meetingType
          : selectedLivestream[0]?.meetingType
          ? selectedLivestream[0]?.meetingType
          : "",
        VIEW_LIVESTREAM: attendeesViewChecked
          ? attendeesView
          : selectedLivestream[0]?.VIEW_LIVESTREAM
          ? selectedLivestream[0]?.VIEW_LIVESTREAM
          : "",
        DOWNLOAD_LIVESTREAM: attendeesDownloadChecked
          ? attendeesDownload
          : selectedLivestream[0]?.DOWNLOAD_LIVESTREAM
          ? selectedLivestream[0]?.DOWNLOAD_LIVESTREAM
          : "",
        max_participants: maxParticipants,
        title: data.title
          ? data.title
          : selectedLivestream[0]?.title
          ? selectedLivestream[0]?.title
          : "",
        description: data.description
          ? data.description
          : selectedLivestream[0]?.description
          ? selectedLivestream[0]?.description
          : "",
        price: data.price
          ? data.price
          : selectedLivestream[0]?.price
          ? selectedLivestream[0]?.price
          : "",
        schedule_From: data.schedule_From
          ? data.schedule_From
          : selectedLivestream[0]?.schedule_From
          ? selectedLivestream[0]?.schedule_From
          : "",
        schedule_To: data.schedule_To
          ? data.schedule_To
          : selectedLivestream[0]?.schedule_To
          ? selectedLivestream[0]?.schedule_To
          : "",
        thumbnail: showThumnail
          ? showThumnail
          : selectedLivestream[0]?.thumbnail
          ? selectedLivestream[0]?.thumbnail
          : "",
        video_url: videoUrl
          ? videoUrl
          : selectedLivestream[0]?.video_url
          ? selectedLivestream[0]?.video_url
          : "",
        duration: selectedDuration
          ? selectedDuration
          : selectedLivestream[0]?.duration
          ? selectedLivestream[0]?.duration
          : "",
      };

      if (id === "1") {
        LivestreamService.createLiveStream(bodyData).then((res) => {
          history.push("/dashboard/mylivestreams/drafts");
          setIsFormSubmitted(false);
        });
      } else {
        const data = Object.assign(bodyData, {
          _id: selectedLivestream[0]?._id,
        });
        LivestreamService.updateLiveStream(data).then((res) => {
          history.push("/dashboard/mylivestreams/drafts");
          setIsFormSubmitted(false);
        });
      }
    }
  };

  const [count, setCount] = useState(0);
  const countInc = () => {
    setCount((prevCount) => prevCount + 1);
    setMaxParticipants((prevCount) => prevCount + 1);
  };

  const countDec = () => {
    if (count <= 0) {
      alert("No Negative");
      return;
    }
    setCount((prevCount) => prevCount - 1);
    setMaxParticipants((prevCount) => prevCount - 1);
  };
  const handleSelectChange = (
    e: React.ChangeEvent<HTMLSelectElement>
  ): void => {
    setCustomSchedule(e.target.value);
    if (e.target.value === "5") {
      e.target.parentElement?.classList.add("custom-option-active");
    } else {
      e.target.parentElement?.classList.remove("custom-option-active");
    }
  };

  const onchangeMeetingType = (event: any, type: string) => {
    setMeetingType(type);
  };
  const onchangeMaxParticipants = (event: any) => {
    setMaxParticipants(event.target.value);
  };
  const onSelectAttendeesView = (event: any) => {
    setAttendeesView(event.target.value);
  };

  const onSelectAttendeesDownload = (event: any) => {
    setAttendeesDownload(event.target.value);
  };

  const [showThumnail, setShowThumnail] = useState("");
  const [videoUrl, setVideoUrl] = useState("");
  const baseUrl = S3_URL;
  const [content, setContent] = useState("");
  const onUploadProgress = (data: any) => {
    const progress = Math.round((data.loaded / data.total) * 100);
    setProgress(progress);
  };

  const onUploadContent = async (event: any) => {
    setVideoUrl("");
    setShowVideo(false);
    setProgress(0);
    if (event.target.files && event.target.files[0]) {
      setContent(event.target.files[0]);
      const result = await S3Helper.uploadFile(
        event.target.files[0],
        onUploadProgress,
        awsInfo
      );
      setVideoUrl(
        `${baseUrl}${awsInfo.folderPath}/${event.target.files[0].name}`
      );
      toast.success("Uploaded Successfully");
    }
  };
  const onUploadThumbnailPic = async (event: any) => {
    setShow(false)
    if (event.target.files && event.target.files[0]) {
      setContent(event.target.files[0]);
      const result = await S3Helper.uploadFile(
        event.target.files[0],
        onUploadProgress,
        awsInfo
      );
      setShowThumnail(
        `${baseUrl}${awsInfo.folderPath}/${event.target.files[0].name}`
      );
      toast.success("Uploaded Successfully");
    }
  };

  const [validationErrors, setValidationErrors] = useState<FormControlError[]>(
    []
  );
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const formValidations = [
    new FormField("title", [FormValidators.REQUIRED]),
    new FormField("price", [FormValidators.REQUIRED]),
    new FormField("schedule_From", [FormValidators.REQUIRED]),
    // new FormField('schedule_To', [FormValidators.REQUIRED]),
  ];

  const getInputValid = (control: string) => {
    const value = GetControlIsValid(validationErrors, control);
    return value;
  };
  const [showVideo, setShowVideo] = useState(false);
  const handleVideoClose = () => setShowVideo(false);
  const handleVideoModal = () => setShowVideo(true);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleImageModal = () => setShow(true);
  const [startDate, setStartDate] = useState();
  return (
    <>
      <div className="main-content">
        <div className="container container-custom">
          <div className="tab-content" id="pills-tabContent">
            <div className="row mb-4 pb-2 border-bottom">
              <h4 className="col-auto mb-0">
                Private Livestream
                <span className="fs-xsml d-block fw-normal">
                  Private Livestreams can be booked for one - on.- one
                  interaction by an individual or a group of individuals (eg:
                  friends, family)
                </span>
              </h4>
              {/* <button
              className="btn btn-outline-black col-auto ms-auto"
              type="button"
            >
              Schedule &amp; Publish
            </button> */}
            </div>
            <div
              className="tab-pane active"
              id="details"
              role="tabpanel"
              aria-labelledby="details-tab"
            >
              <FormBuilder onUpdate={updateFormData}>
                <form>
                  <div className="row post-form-sec">
                    <div className="col-md-8 post-form pe-3">
                      <div className="form-floating mb-3">
                        <input
                          className={`form-control ${
                            isFormSubmitted && !getInputValid("title")
                              ? "error"
                              : ""
                          }`}
                          type="text"
                          name="title"
                          placeholder="Title of the Livestream"
                          defaultValue={selectedLivestream[0]?.title}
                        />
                        {isFormSubmitted && !getInputValid("title") ? (
                          <label className="clr-red" htmlFor="floatingInput">
                            Enter Title
                          </label>
                        ) : (
                          <label className="clr-black" htmlFor="floatingInput">
                            Title
                          </label>
                        )}
                      </div>
                      <div className="row mb-3">
                        <div className="custom-radio no-bdr w-auto">
                          <input
                            id="individual"
                            type="radio"
                            name="PrivateRadioCheckbox"
                            checked={meetingType === "INDIVIDUAL"}
                            onChange={(event) =>
                              onchangeMeetingType(event, "INDIVIDUAL")
                            }
                          />
                          <label htmlFor="individual">Individual</label>
                        </div>
                        <div className="custom-radio no-bdr  w-auto">
                          <input
                            id="group"
                            type="radio"
                            name="PrivateRadioCheckbox"
                            checked={meetingType === "GROUP"}
                            onChange={(event) =>
                              onchangeMeetingType(event, "GROUP")
                            }
                          />
                          <label htmlFor="group">Group</label>
                        </div>
                      </div>
                      {meetingType === "GROUP" && (
                        <div className="mb-3 col-auto d-flex flex-wrap">
                          <label
                            className="col-md-3 col-form-label  ps-md-0"
                            htmlFor="maxParticipants"
                          >
                            Max Participants
                            <TooltipWrapper
                              placement="top"
                              tooltipText="Max 10 participants allowed for private group livestream."
                              iconName="bi bi-info"
                            />
                          </label>
                          <div className="col-md-auto">
                            <div className="checkout-count">
                              <div className="row">
                                <div className="col checkout-counter">
                                  <span className="dec" onClick={countDec}>
                                    -
                                  </span>
                                  <input
                                    type="text"
                                    className="form-control"
                                    value={maxParticipants}
                                    onChange={(event) =>
                                      onchangeMaxParticipants(event)
                                    }
                                    id="maxParticipants"
                                    name="maxParticipants"
                                  />
                                  <span className="inc" onClick={countInc}>
                                    +
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}

                      <div className="mb-3 col-auto d-flex flex-wrap">
                        {/* <label
                        className="col-md-3 col-form-label  ps-md-0"
                        htmlFor="ticketPrice"
                      >
                        Ticket Price
                      </label> */}
                        {isFormSubmitted && !getInputValid("price") ? (
                          <label
                            className="col-md-3 col-form-label ps-md-0 clr-red"
                            htmlFor="ticketPrice"
                          >
                            Enter Ticket Price
                          </label>
                        ) : (
                          <label
                            className="col-md-3 col-form-label ps-md-0"
                            htmlFor="ticketPrice"
                          >
                            Ticket Price
                          </label>
                        )}
                        <div className="col-md-auto">
                          <input
                            className="form-control"
                            type="text"
                            id="ticketPrice"
                            name="price"
                            placeholder="Price"
                            defaultValue={selectedLivestream[0]?.price}
                          />
                        </div>
                      </div>
                      {/* <div className="row mb-3">
                        <div className="col-md-12">
                          <label
                            className="form-label col-md-2"
                            htmlFor="formFileSm"
                          >
                            Upload a teaser
                          </label>
                          <button
                            onClick={handleVideoModal}
                            type="button"
                            className="btn btn-link text-decoration-none d-inline-flex col-auto"
                          >
                            Upload
                          </button>
                          <div
                            className="upload-sec upload-video custom position-relative"
                            style={{ width: "200px" }}
                          >
                          {progress >= 100 && videoUrl && (
                              <>
                                <video
                                  poster={videoUrl}
                                  className="my_club_media"
                                  controls controlsList="nodownload"
                                >
                                  <source src={videoUrl} type="video/mp4" />
                                  Your browser does not support HTML video.
                                </video>
                              </>
                            )}
                            </div>
                          
                        </div>
                      </div> */}
                      <div className="row mb-3">
                        <div className="col-md-12">
                          <label
                            className="col-md-2 form-label"
                            htmlFor="formFileSm"
                          >
                            Thumbnail Photo
                          </label>
                          <button
                            onClick={handleImageModal}
                            type="button"
                            className="btn btn-link text-decoration-none d-inline-flex col-auto"
                          >
                            Upload
                          </button>
                          {showThumnail && (
                            <div
                              className="border"
                              style={{
                                width: "200px",
                                height: "200px",
                                objectFit: "cover",
                              }}
                            >
                              <img
                                className="my_club_media"
                                src={showThumnail}
                                alt=""
                                style={{
                                  width: "100%",
                                  height: "100%",
                                  objectFit: "cover",
                                  padding: "5px",
                                }}
                              />
                            </div>
                          )}
                          {/* <div className="upload-sec custom">
                            <div className="outer-btn button_outer1">
                              <div className="btn_upload1 upload_btn btn">
                                <input
                                  id="upload_file1"
                                  type="file"
                                  name="cover_photo"
                                  accept="image/jpg, image/jpeg, image/png, image/svg, image/gif"
                                  onChange={(event) =>
                                    onUploadThumbnailPic(event)
                                  }
                                />
                                <i className="bi bi-cloud-arrow-up"></i>
                                <strong>Upload thumbnail Photo </strong>
                                <span>(drag and drop or browse)</span>
                                <span className="upload-accept-filename">
                                  jpg, jpeg, png, svg, gif
                                </span>
                              </div>
                              <div className="processing_bar"></div>
                              <div className="success_box"></div>
                            </div>
                            
                            <div className="error_msg1 upload_error_msg"></div>
                            <div
                              className="uploaded_file_view"
                              id="uploaded_view1"
                            >
                              <span className="file_remove1 remove_file">
                                X
                              </span>
                            </div>
                          </div> */}
                        </div>
                      </div>
                      {/* <div className="mb-3 col-auto d-flex flex-wrap">
                      <label
                        className="col-md-3 col-form-label  ps-md-0"
                        htmlFor="ticketPrice"
                      >
                        Earnings
                      </label>
                      <div className="col-md-auto">
                        <strong className="h3 mb-0">495</strong>
                        <sub style={{ bottom: 0, paddingLeft: "5px" }}>INR</sub>
                        <div className="form-text fs-xsml mb-3" id="priceHelp">
                          This amount excludes the 10% of Clan convenience fee.
                        </div>
                      </div>
                    </div> */}
                      <div className="row mb-3">
                        <div className="custom-checkbox no-bdr d-inline-flex col ms-2">
                          <input
                            id="openForAttendees"
                            type="checkbox"
                            checked={attendeesViewChecked}
                            onClick={() =>
                              setAttendeesViewChecked(!attendeesViewChecked)
                            }
                          />
                          <label htmlFor="openForAttendees">
                            Open for attendees to view the recorded livestream.
                          </label>
                        </div>
                        <div className="col-12">
                          <span className="me-2">Till</span>
                          <select
                            className="form-select form-select-sm w-auto d-inline-flex"
                            aria-label="Available for"
                            value={attendeesView}
                            onChange={(event) => onSelectAttendeesView(event)}
                          >
                            <option value="">Select</option>
                            <option value="7">7days</option>
                            <option value="15">15days</option>
                            <option value="30">30days</option>
                          </select>
                          <span className="ms-2">days</span>
                        </div>

                        <div
                          className="form-text fs-xsml mb-3"
                          id="videoAvailableTill"
                        >
                          The video will be available to view till August 11,
                          2021.
                        </div>
                      </div>
                      <div className="row mb-3">
                        <div className="custom-checkbox no-bdr d-inline-flex col ms-2">
                          <input
                            id="openForAttendeesDownload"
                            type="checkbox"
                            checked={attendeesDownloadChecked}
                            onClick={() =>
                              setAttendeesDownloadChecked(
                                !attendeesDownloadChecked
                              )
                            }
                          />
                          <label htmlFor="openForAttendeesDownload">
                            Open for attendees to download the livestream ends.
                          </label>
                        </div>
                        <div className="col-12">
                          <span className="me-2">Till</span>
                          <select
                            className="form-select form-select-sm w-auto d-inline-flex"
                            aria-label="Available for"
                            value={attendeesDownload}
                            onChange={(event) =>
                              onSelectAttendeesDownload(event)
                            }
                          >
                            <option value="">Select</option>
                            <option value="7">7days</option>
                            <option value="15">15days</option>
                            <option value="30">30days</option>
                          </select>
                          <span className="ms-2">days</span>
                        </div>

                        <div
                          className="form-text fs-xsml mb-3"
                          id="videoAvailableDownload"
                        >
                          The video will be available to download till August
                          11, 2021.
                        </div>
                      </div>
                      {/* </form> */}

                      {/* </FormBuilder> */}
                    </div>
                    <div className="col-md-4 post-form-detail ps-3">
                      <h6 className="d-flex">
                        Schedule (IST)
                        <TooltipWrapper
                          placement="top"
                          iconName="bi bi-info"
                          tooltipText="15 min - 1 hr of duration is allowed"
                        />
                      </h6>
                      <div className="row mb-3">
                        {isFormSubmitted && !getInputValid("schedule_From") ? (
                          <label className="col-md-2 pe-0 clr-red">
                            select from date
                          </label>
                        ) : (
                          <label className="col-md-2 pe-0">From</label>
                        )}
                        <div className="col-md-7">
                          <>
                          <input
                            className="form-control"
                            type="datetime-local"
                            name="schedule_From"
                          />
                          </>
                          <DatePicker
                          className="form-control"
                            value={selectedLivestream[0]?.schedule_From}

                            selected={startDate}
                            onChange={(date) => setStartDate(startDate)}
                            showTimeSelect
                            dateFormat="MMMM d, yyyy h:mm aa"
                          />
                        </div>
                        {/* <div className="col-md-5">
                        <input
                          className="form-control"
                          type="datetime-local"
                          name="schedule_To"
                        />
                      </div> */}
                      </div>
                      <div className="row mb-3">
                        {isFormSubmitted && !selectedDuration ? (
                          <label className="col-md-2 pe-0 mt-1 clr-red">
                            select Duration
                          </label>
                        ) : (
                          <label className="col-md-2 mt-1 pe-0">Duration</label>
                        )}
                        <div className="col-md-5">
                          <select
                            name="selectedDuration"
                            value={selectedDuration}
                            onChange={(event) => onChangeDuration(event)}
                            className="form-control"
                          >
                            <option value="">Select</option>
                            {duration.map((data: any, index: number) => {
                              return <option value={data}>{data}mins</option>;
                            })}
                          </select>
                        </div>
                        {/* {isFormSubmitted && !getInputValid('schedule_To') ? (
                        <label className="col-md-2 pe-0 clr-red">
                          select to date
                        </label>
                      ) : (
                        <label className="col-md-2 pe-0">
                          To
                        </label>
                      )}
                      <div className="col-md-7">
                        <input
                          className="form-control"
                          type="datetime-local"
                          name="schedule_To"
                        />
                      </div> */}

                        {/* <div className="col-md-5">
                        <input
                          className="form-control"
                          type="text"
                          name="name"
                          placeholder="11:45AM"
                        />
                      </div> */}
                      </div>

                      {/* <select
                    className="form-select"
                    aria-label="Does not repeat"
                    onChange={handleSelectChange}
                    value={customSchedule}
                  >
                    <option value="0" selected>
                      Does not repeat
                    </option>
                    <option value="1">Daily</option>
                    <option value="2">Weekly on Sunday</option>
                    <option value="3">Monthly on first Sunday</option>
                    <option value="4">
                      Every Weekend (Saturday &amp; Sunday)
                    </option>
                    <option value="5">Custom</option>
                  </select>
                  <div className="custom-options">
                    <div className="row">
                      <label className="col-auto">Repeat every</label>
                      <div className="col-auto select-input-xsm">
                        <select
                          className="form-select"
                          aria-label="Does not repeat"
                        >
                          <option value="0" selected>
                            0
                          </option>
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                        </select>
                      </div>
                      <div className="col-auto select-input-sm">
                        <select
                          className="form-select"
                          aria-label="Does not repeat"
                        >
                          <option value="0" selected>
                            Week
                          </option>
                          <option value="1">Month</option>
                          <option value="2">Year</option>
                        </select>
                      </div>
                    </div>
                    <div className="repeat-options mb-3">
                      <strong className="d-block">Repeat on</strong>
                      <div className="repeat-check">
                        <input
                          type="radio"
                          className="btn-check"
                          id="sunday"
                          autoComplete="off"
                          name="repeatOptions"
                        />
                        <label
                          className="btn btn-outline-black"
                          htmlFor="sunday"
                        >
                          S
                        </label>
                      </div>
                      <div className="repeat-check">
                        <input
                          type="radio"
                          className="btn-check"
                          id="monday"
                          autoComplete="off"
                          name="repeatOptions"
                        />
                        <label
                          className="btn btn-outline-black"
                          htmlFor="monday"
                        >
                          M
                        </label>
                      </div>
                      <div className="repeat-check">
                        <input
                          type="radio"
                          className="btn-check"
                          id="tuesday"
                          autoComplete="off"
                          name="repeatOptions"
                        />
                        <label
                          className="btn btn-outline-black"
                          htmlFor="tuesday"
                        >
                          T
                        </label>
                      </div>
                      <div className="repeat-check">
                        <input
                          type="radio"
                          className="btn-check"
                          id="wednesday"
                          autoComplete="off"
                          name="repeatOptions"
                        />
                        <label
                          className="btn btn-outline-black"
                          htmlFor="wednesday"
                        >
                          W
                        </label>
                      </div>
                      <div className="repeat-check">
                        <input
                          type="radio"
                          className="btn-check"
                          id="thursday"
                          autoComplete="off"
                          name="repeatOptions"
                        />
                        <label
                          className="btn btn-outline-black"
                          htmlFor="thursday"
                        >
                          T
                        </label>
                      </div>
                      <div className="repeat-check">
                        <input
                          type="radio"
                          className="btn-check"
                          id="friday"
                          autoComplete="off"
                          name="repeatOptions"
                        />
                        <label
                          className="btn btn-outline-black"
                          htmlFor="friday"
                        >
                          F
                        </label>
                      </div>
                      <div className="repeat-check">
                        <input
                          type="radio"
                          className="btn-check"
                          id="saturday"
                          autoComplete="off"
                          name="repeatOptions"
                        />
                        <label
                          className="btn btn-outline-black"
                          htmlFor="saturday"
                        >
                          S
                        </label>
                      </div>
                    </div>
                    <div className="repeat-options">
                      <strong className="d-block">Ends</strong>
                      <div className="row mb-3">
                        <div className="custom-radio no-bdr mb-3">
                          <input id="endNever" type="radio" name="repeatEnds" />
                          <label htmlFor="endNever">Never</label>
                        </div>
                        <div className="row mb-3">
                          <div className="custom-radio no-bdr w-auto me-4">
                            <input id="endOn" type="radio" name="repeatEnds" />
                            <label htmlFor="endOn">On</label>
                          </div>
                          <input type="date" className="form-control w-auto" disabled />
                        </div>
                        <div className="row mb-3">
                          <div className="custom-radio no-bdr w-auto me-2"  >
                            <input
                              id="endAfter"
                              type="radio"
                              name="repeatEnds"
                            />
                            <label htmlFor="endAfter">After</label>
                          </div>
                          <input type="text" placeholder="13 occurances" className="form-control w-auto" disabled />
                        </div>
                      </div>
                    </div>
                    <div className="d-flex justify-content-end">
                      <button type="button" className="btn btn-outline-black">Cancel</button>
                      <button type="button" className="btn btn-primary ms-2">Done</button>
                    </div>
                  </div> */}
                    </div>
                  </div>
                </form>
              </FormBuilder>
              <div className="row mt-5">
                <div className="col-md-8">
                  <div className="row">
                    <div className="col-auto d-flex flex-wrap">
                      <button className="btn btn-outline-orange me-2">
                        Cancel
                      </button>
                      <button
                        disabled={isLoading}
                        type="submit"
                        className="btn btn-outline-black"
                        onClick={onSaveLivestream}
                      >
                        {!isLoading ? (
                          "Save"
                        ) : (
                          <div
                            className="spinner-border text-primary spinner-border-sm"
                            role="status"
                          >
                            <span className="sr-only">Loading...</span>
                          </div>
                        )}
                      </button>
                    </div>
                    <div className="ms-auto col-auto">
                      {/* <button className="btn btn-primary">Preview</button> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CustomUploadModal
        show={showVideo}
        handlemodal={handleVideoModal}
        handleClose={handleVideoClose}
        uploadType="video"
        uploadedFile={(e) => onUploadContent(e)}
      />
      <CustomUploadModal
        show={show}
        handlemodal={handleImageModal}
        handleClose={handleClose}
        uploadType="image"
        uploadedFile={(event) => onUploadThumbnailPic(event)}
      />
    </>
  );
};
