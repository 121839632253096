
import moment from "moment";
import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import Slider from "react-slick";
import { ClubsPost } from "../../../app/model/clubs";
import { ClubService } from "../../../app/service/club.service";
import dummyImg from "../../../assets/img/dummy-img.svg";

export const MySubscriptionsPostsPage = () => {
  const id = sessionStorage.getItem('isLogin') || '';
  const [mySubscribePosts, setMySubscribePosts] = useState<ClubsPost[] | []>([]);
  const [filterSelectedPostType, setFilterSelectedPostType] = useState<string>('');

  useEffect(() => {
    if (id) {
      getMySubscribePosts(id);
    }
  }, [id]);

  const getMySubscribePosts = (id: string) => {
    ClubService.getClubPostsByUserID(id).then(
      res => {
        if (res) {
          setMySubscribePosts(res);
        }
      }
    )
  }
  const settings = {
    dots: false,
    arrows: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };

  const onSelectPackageType = (event: any) => {
    // setFilterSelectedPackageType(event.target.value);
  }

  const onSeletcPostType = (event: any) => {
    setFilterSelectedPostType(event.target.value);
  }
  const onApplyFilter = () => {
    // getUnPublishPost();
  }
  return (
    <>
      <div className="filter-sec">
        <form className="row g-2 d-none d-md-inline-flex">
          <div className="col-auto align-self-center">
            <label className="ps-0">Filter by:</label>
          </div>
          <div className="col-auto">
            <select className="form-select form-select-sm" onChange={onSeletcPostType}>
              <option value="">Select</option>
              <option value="0">Video Posts</option>
              <option value="1">Audio Posts</option>
              <option value="2">Image Posts</option>
              <option value="3">Text Posts</option>
              <option value="4">Poll Posts</option>
              <option value="4">Livestreams</option>
            </select>
          </div>
          <div className="col-auto">
            <select className="form-select form-select-sm">
              <option>Creator Name 1</option>
              <option>Creator Name 2</option>
              <option>Creator Name 3</option>
            </select>
          </div>
          {/* <div className="col-auto">
            <input
              placeholder="Custom Date"
              type="text"
              className="form-control"
            />
          </div> */}
          <div className="col-auto">
            <a className="btn btn-outline-black" onClick={onApplyFilter}>
              Apply
            </a>
          </div>
        </form>
        <a
          className="d-inline-flex d-md-none btn btn-outline-black"
          href="#offCanvasFilter"
          data-bs-toggle="offcanvas"
          data-bs-target="#offCanvasFilter"
          aria-controls="offCanvasFilter"
        >
          Filter
        </a>
      </div>
      <div id="subs-posts" role="tabpanel" aria-labelledby="subs-posts-tab">
        <div className="post-tab__content-left row">
          {mySubscribePosts.map((post, index) => {
            return <div key={index} className="col-md-6 col-lg-4">
              <div className={
                post?.type === "0" ? "post-card video" : 
                post?.type === "1" ? "post-card audio" : 
                post?.type === "2" ? "post-card slider" : "post-card"}>
                <div className="post-card__img" style={{height:'285px'}}>
                  {post?.type === "" && (
                    <img
                      className="mw-100 w-100"
                      style={{minHeight: ' 280px'}}
                      src={dummyImg}
                      alt=""
                    />
                  )}
                  {post?.type === "0" && (
                    <video style={{maxHeight: ' 280px'}} className="mw-100 w-100" controls controlsList="nodownload">
                      <source src={post?.content} type="video/mp4" style={{maxHeight: ' 300px'}} />
                      Your browser does not support HTML video.
                    </video>
                  )}
                  {post?.type === "1" && (
                    <audio controls style={{minHeight: ' 280px', height: ' 280px', width:'100%'}}>
                      <source src={post?.content} type="audio/mpeg" style={{minHeight: ' 300px'}} />
                      Your browser does not support the audio element.
                    </audio>
                  )}

                  {post?.type === "2" &&
                    <Slider className="slider slider-w-images-3" {...settings}>
                      {JSON.parse(post?.content).map((image: any, index: number) => {
                        return (
                          <section>
                            <div className="slider-item">
                              <div className="slider-item-img  ratio ratio-4x3">
                                <img src={image?.img} alt="" />
                              </div>
                            </div>
                          </section>
                        );
                      })}
                    </Slider>
                  }
                  {post?.type === "3" && (
                    <div className="text-post">{post?.content}</div>
                  )}
                  {/* <img className="mw-100" src={dummyImg} alt="" /> */}
                </div>
                <div className="post-card__body">
                  <span className="post-card-club_name">{post?.club_info && post?.club_info[0]?.name}</span>
                  <h3 className="post-card__title">
                    <NavLink to={`../../club-detail/${post?._id}/${post?.club_id}`}>{post.title}</NavLink>
                  </h3>
                  <p className="post-card__desc">{post.description.substring(0,50)}...</p>
                </div>
                <div className="post-card__footer">
                  <ul className="post-card__footer-left">
                    <li>
                      <i className="bi bi-heart"></i>{post.likes?.length ? post.likes?.length : 0}
                    </li>
                    <li>
                      <i className="bi bi-chat"></i>{post.comments?.length ? post.comments?.length : 0}
                    </li>
                  </ul>
                  <div className="post-card__footer-right">
                    <small className="post-card__postedInfo">
                      {moment(post?.published_dt).format("lll")} IST
                    </small>
                  </div>
                </div>
              </div>
            </div>
          })
          }
          {mySubscribePosts.length <= 0 && <div className="text-center">No Posts</div>}

          {/* <div className="col-md-6 col-lg-4">
            <div className="post-card">
              <div className="post-card__img">
                <img className="mw-100" src={dummyImg} alt="" />
              </div>
              <div className="post-card__body">
                <span className="post-card-club_name">Club page name</span>
                <h3 className="post-card__title">
                  {" "}
                  <a href="">Travel ashore</a>
                </h3>
                <p className="post-card__desc">Lorem ipsum dolor sit amet</p>
              </div>
              <div className="post-card__footer">
                <ul className="post-card__footer-left">
                  <li>
                    <i className="bi bi-heart"></i>560
                  </li>
                  <li>
                    <i className="bi bi-chat"></i>560
                  </li>
                </ul>
                <div className="post-card__footer-right">
                  <small className="post-card__postedInfo">
                    Aug 7, 4:30pm IST{" "}
                  </small>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
};

