import React, { Fragment, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { LivestreamService } from "../../app/service/livestream";
import moment from "moment";
import { connect, useDispatch } from "react-redux";
import { Loading } from "../../redux/actions";
import { elementAcceptingRef } from "@mui/utils";
import { element } from "prop-types";
import { BsEject } from "react-icons/bs";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { ClubService } from "../../app/service/club.service";
import { UserService } from "../../app/service/user.service";
import { toast } from "react-toastify";
import TextField from "@mui/material/TextField";

interface IMainDashboardProps {
  userId: string;
  UserReducer?: any;
  loading?: (data: any) => void;
  LoginUserIdReducer?: any;
}

const MainDashboard: React.FunctionComponent<IMainDashboardProps> = (props) => {
  let user_id = sessionStorage.getItem("isLogin") || "";
  const [show, setShow] = useState(false);
  const [livestreamReport, setLivestreamReport] = useState<any>([{}]);
  const [upcomingLiveStream, setUpcomingLiveStream] = useState<any>([{}]);
  const [amount, setAmount] = useState<any>("");
  const [remarks, setRemarks] = useState<any>("");

  const [amountError, setAmountError] = useState<any>("");
  const [showAccountDetails, setShowAccountDetails] = useState(false);
  const [claimStatus, setClaimStatus] = useState<any>([{}]);
  const [userDetails, setUserDetails] = useState<any>([{}]);
  const history = useHistory();

  const dispatch = useDispatch();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleCloseAccount = () => setShowAccountDetails(false);
  const handleShowAccount = () => setShowAccountDetails(true);
  useEffect(() => {
    getlivestreamReport();
    getUpcomingLiveStreamByCreatorID();
    UserService.claimRequestStatus(user_id).then((res) => {
      console.log(res, "claimStatus");
      setClaimStatus(res);
    });

    ClubService.getUserClubs(user_id).then((res) => {
      if (res) {
        setUserDetails(res);
      }
    });
  }, []);
  const getlivestreamReport = () => {
    if (props.loading) {
      dispatch(props.loading(true));
    }
    LivestreamService.getCompletedLiveStreamByCreatorID(user_id).then((res) => {
      if (res) {
        setLivestreamReport([...res]);
        console.log(res);
        console.log(livestreamReport.length);
        console.log(livestreamReport);
      }
      if (props.loading) {
        dispatch(props.loading(false));
      }
    });
  };
  const getUpcomingLiveStreamByCreatorID = () => {
    LivestreamService.getUpcomingLiveStreamByCreatorID(user_id).then((res) => {
      if (res) {
        setUpcomingLiveStream([...res]);
        console.log(res);
        if (props.loading) {
          dispatch(props.loading(false));
        }
      }
    });
  };

  const onChangeAmount = (event: any) => {
    setAmountError("");
    if (
      event.target.value

      // event.target.value >= 10
    ) {
      const re = /^[0-9\b]+$/;
      if (re.test(event.target.value) && event.key !== ".") {
        setAmount(event.target.value);
      }
    } else {
      if (!event.target.value || !(event.target.value >= 1)) {
        setAmount("");
        // setAmountError("Please enter regular ticket price");
      }
    }
  };

  const onChangeRemarks = (event: any) => {
    if (event.target.value) {
      setRemarks(event.target.value);
      console.log(remarks);
    } else {
      setRemarks("");
    }
  };

  // let initial= 0
  let totalUpcomingTickets: any = [];
  console.log(totalUpcomingTickets);
  let status = "pending";
  let sumUpcoming = 0;
  for (let index = 0; index < upcomingLiveStream.length; index++) {
    const element = upcomingLiveStream[index];
    sumUpcoming += upcomingLiveStream[index].tickets;
    totalUpcomingTickets.push(element.tickets * element.price);
  }
  console.log(sumUpcoming);
  let sumlivestream = 0;
  for (let index = 0; index < livestreamReport.length; index++) {
    sumlivestream += livestreamReport[index].tickets;
  }
  const upcomingValues = Object.values(totalUpcomingTickets);
  console.log(upcomingValues);

  const upcomingSum: any = upcomingValues.reduce(
    (accumulator: any, value: any) => {
      return accumulator + value;
    },
    0
  );
  console.log(sumlivestream);
  // let total_attendees:any
  // let total_revenue: any

  let tickets: any = [];
  // let price:any = [];
  for (let index = 0; index < livestreamReport.length; index++) {
    const element = livestreamReport[index];
    console.log(element);
    // console.log(element.tickets, 'tickets', element.price, 'Prive');
    tickets.push(element.tickets * element.price);
    console.log(tickets);

    // price.push(element.price ? element.price : 0);
    // console.log(price)

    // total_attendees += element.tickets ? element.tickets : 0;
    // console.log(total_attendees)

    // total_revenue += tickets * price
    // console.log(total_revenue)
  }

  const values = Object.values(tickets);

  const sum: any = values.reduce((accumulator: any, value: any) => {
    return accumulator + value;
  }, 0);

  console.log(sum);

  let reRuntickets: any = [];
  let reRunTicketSum = 0;
  let reRunCounter = 0;
  for (let i = 0; i < livestreamReport.length; i++) {
    const element = livestreamReport[i];
    if (
      livestreamReport[i].status === "COMPLETED" &&
      livestreamReport[i].isRerun === true
    ) {
      reRunCounter++;
      reRunTicketSum += livestreamReport[i].tickets;
      reRuntickets.push(element.tickets * element.price);
    }
  }

  const ticketrerunvalues = Object.values(reRuntickets);
  const reRunRevenue: any = ticketrerunvalues.reduce(
    (accumulator: any, value: any) => {
      return accumulator + value;
    },
    0
  );
  console.log(reRunRevenue);

  let total = upcomingSum + sum + reRunRevenue;

  let amountAvailaibleWithdraw =
    total - ((sum * 20) / 100 + (reRunRevenue * 10) / 100);

  let claimed: any = [];
  for (let i = 0; i < claimStatus.length; i++) {
    const element = claimStatus[i];
    claimed.push(element.amount_to_withdraw);
    console.log(claimed, "claimed");
  }

  const claimedValues = Object.values(claimed);

  const claimedSum: any = claimedValues.reduce(
    (accumulator: any, value: any) => {
      return accumulator + value;
    },
    0
  );

  // const BankData = userDetails[0].map((num:any) => num.id);

  const handleCheckAccountDetails = () => {
    if (userDetails[0]?.payout_details.length === 0) {
      handleShowAccount();
    } else {
      console.log(userDetails[0]?.payout_details.length);
      handleShow();
    }
  };

  let availaibleToWithdrawLivestream = sum - (sum * 20) / 100;
  let availaibleToWithdrawRerun = reRunRevenue - (reRunRevenue * 10) / 100;
  const handleRedirectSettingPage = () => {
    history.push("/dashboard/settings");
  };

  const bodyData = {
    user_id: user_id,
    amount_to_withdraw: amount,
    status: status,
    user_remarks: remarks,
  };
  const handleClaim = () => {
    if (amount > amountAvailaibleWithdraw) {
      setAmountError("Insufficient fund");
    } else if (amount < 2) {
      setAmountError("Balnce lower than minimum");
    } else if (claimStatus[0]?.status === "pending") {
      toast.error("Withdrawl request already in pending");
    } else {
      UserService.claimRequest(bodyData).then((res) => {
        console.log(res);
        if (res) {
          setShow(false);
          window.location.reload();
          toast.success("claim Request raised Successfully");
        }
      });
    }
  };
  // const handleClaimStatus = () => {

  // }
  claimStatus.sort((a:any, b:any) => Date.parse(b.createdAt) - Date.parse(a.createdAt));
  return (
    <>
    
    <div className="main-content main-dashboard">
      <div className="container ">
        <div className="row">
          <h3 className="mb-4 offset-xl-2 dashboard">Dashboard</h3>
        </div>
        <div className="row justify-content-center">
          <div className="col-md-6 col-xl-4">
            <div className="card">
              <h3>Upcoming</h3>
              <ul>
                <li>
                  <span>Total Upcoming</span>
                  <span>{upcomingLiveStream.length}</span>
                </li>
                <li>
                  <span>Tickets Sold</span>
                  <span>{sumUpcoming}</span>
                </li>
                <li>
                  <span>Total Revenue</span>
                  <span> {upcomingSum} INR</span>
                </li>

                <details>
                  <summary>Details</summary>
                  <table>
                    <tr>
                      <th className="upcoming-table-head">Livestreams</th>
                      <th className="upcoming-table-head">No. of Tickets</th>
                      <th className="upcoming-table-head">Tickets price</th>
                      <th className="upcoming-table-head">Total</th>
                    </tr>
                    {upcomingLiveStream.map((comment: any, index: any) => {
                      return (
                        <>
                          <tr>
                            <td>{comment.title}</td>

                            <td className="upcoming-table">
                              {comment.tickets}
                            </td>
                            <td className="upcoming-table">{comment.price}</td>
                            <td className="upcoming-table">
                              {comment.tickets * comment.price}
                            </td>
                          </tr>
                        </>
                      );
                    })}
                  </table>
                </details>
                {/* <li>
                  <span>Total Unsold</span>
                  <span>22</span>
                </li>
                <li>
                  <hr />
                </li> */}
              </ul>
              {/* <div className="d-flex justify-content-end border-top pt-2">
                <Link
                  to="/dashboard/mylivestreams/scheduled"
                  className="card-link"
                >
                  View All
                </Link>
              </div> */}
            </div>
          </div>
          <div className="col-md-6 col-xl-4">
            <div className="card">
              <h3>Livestream</h3>
              <ul>
                {/* {livestreamReport.map((comment:any, index:any) => {
              return (
                <> */}
                <li>
                  <span>Total Conducted</span>
                  <span>{livestreamReport.length}</span>
                </li>
                <li>
                  <span>Tickets Sold</span>
                  <span>{sumlivestream}</span>
                </li>
                <li>
                  <span>Total Revenue</span>
                  <span>{sum} INR</span>
                </li>
                <details>
                  <summary>Details</summary>
                  <table>
                    <tr>
                      <th className="upcoming-table-head">Livestreams</th>
                      <th className="upcoming-table-head">No. of Tickets</th>
                      <th className="upcoming-table-head">Tickets price</th>
                      <th className="upcoming-table-head">Total</th>
                    </tr>
                    {livestreamReport.map((any: any, index: any) => {
                      return (
                        <tr>
                          <td key={index}>{any.title}</td>

                          <td className="upcoming-table">{any.tickets}</td>
                          <td className="upcoming-table">{any.price}</td>
                          <td className="upcoming-table">
                            {any.tickets * any.price}
                          </td>
                        </tr>
                      );
                    })}
                  </table>
                </details>
              </ul>
              {/* <hr className="mt-0 mb-4" /> */}
              {/* <h3 className="fs-body">Upcoming</h3>
              <ul>
                {console.log(upcomingLiveStream)}
                <li>
                  <span>{upcomingLiveStream.length ? upcomingLiveStream[0]?.title: ''}</span>
                  <span>
                    {upcomingLiveStream.length ? moment(upcomingLiveStream[0]?.schedule_From).utc().format("MMMM dddd DD") : ''}
                    <br />
                    {upcomingLiveStream.length ? moment(upcomingLiveStream[0]?.schedule_From).utc().format(" hh:mm A"):''}
                  </span>
                </li>
                <li>
                  <span>Tickets Sold</span>
                  <span>{upcomingLiveStream[0]?.tickets}</span>
                </li>
                <li>
                  <span>Amounting to</span>
                  <span>
                    {" "}
                    {upcomingLiveStream.length ? (upcomingLiveStream[0]?.tickets *
                      upcomingLiveStream[0]?.price) : ''}
                    INR
                  </span>
                </li>
              </ul>
              <div className="d-flex justify-content-end border-top pt-2">
                <Link
                  to="/dashboard/mylivestreams/scheduled"
                  className="card-link"
                >
                  View All
                </Link>
              </div> */}
            </div>
          </div>
          <div className="col-md-6 col-xl-4 rerun-card">
            <div className="card">
              <h3>Rerun</h3>
              <ul>
                <li>
                  <span>Total Conducted</span>

                  <span>{reRunCounter}</span>
                  {/* )}
                })} */}
                </li>
                <li>
                  <span>Tickets Sold</span>
                  <span>{reRunTicketSum}</span>
                </li>
                <li>
                  <span>Total Revenue</span>
                  <span>{reRunRevenue} INR</span>
                </li>
                <details>
                  <summary>Details</summary>
                  <table>
                    <tr>
                      <th className="upcoming-table-head">Livestreams</th>
                      <th className="upcoming-table-head">No. of Tickets</th>
                      <th className="upcoming-table-head">Tickets price</th>
                      <th className="upcoming-table-head">Total</th>
                    </tr>
                    {livestreamReport.map((data: any, index: any) => {
                      return (
                        <tr>
                          {data.isRerun === true && (
                            <>
                              <td key={index}>{data.title}</td>

                              <td className="upcoming-table">{data.tickets}</td>
                              <td className="upcoming-table">{data.price}</td>
                              <td className="upcoming-table">
                                {data.tickets * data.price}
                              </td>
                            </>
                          )}
                        </tr>
                      );
                    })}
                  </table>
                </details>

                {/* <li>
                  <span>Total Revenue</span>
                  <span>22</span>
                </li> */}
                {/* <li>
                  <hr />
                </li>
                */}
              </ul>

              {/* <div className="d-flex justify-content-end border-top pt-2">
                <Link
                  to="/dashboard/mynft/"
                  className="card-link"
                >
                  View All
                </Link>
              </div> */}
            </div>
          </div>

          {/* nft */}
          {/* <div className="col-md-6 col-xl-4">
            <div className="card">
              <h3>NFT</h3>
              <ul>
                <li>
                  <span>Total Created</span>
                  <span>50</span>
                </li>
                <li>
                  <span>Total Sold</span>
                  <span>28</span>
                </li>
                <li>
                  <span>Total Revenue</span>
                  <span>2 ETH, 5.1L INR</span>
                </li>
                <li>
                  <span>Total Unsold</span>
                  <span>22</span>
                </li>
                <li>
                  <hr />
                </li>
              </ul>
              <div className="d-flex justify-content-end border-top pt-2">
                <Link
                  to="/dashboard/mynft/"
                  className="card-link"
                >
                  View All
                </Link>
              </div>
            </div>
          </div> */}
        </div>
        <h3 className="mb-4 offset-xl-2 dashboard" style={{ marginTop: "20px" }}>Revenue <span style={{ fontSize: "14px",    fontWeight: "400" }}>(in INR)</span></h3>
        <table className="table" >
          <thead>
            <tr>
              <th scope="col">Upcoming</th>
              <th scope="col">Livestream</th>
              <th scope="col">Rerun</th>
              <th scope="col">Total</th>
              <th scope="col">Claimed</th>
              <th scope="col">Balance</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{upcomingSum}</td>
              <td>{sum}</td>
              <td>{reRunRevenue}</td>
              <td>{total}</td>
              <td>{claimedSum}</td>
              <td>
                {total - claimedSum}{" "}
                <span
                  className="claim-button"
                  onClick={handleCheckAccountDetails}
                >
                  Claim
                </span>
              </td>
            </tr>
          </tbody>
        </table>
        <details>
          <summary>Details</summary>
          <table className="table">
            <thead>
              <tr>
                <th scope="col">Request No.</th>
                <th scope="col">Withdrawl Amount</th>
                <th scope="col">Withdrawl Date</th>
                <th scope="col">Status</th>
              </tr>
            </thead>
            <tbody>
              {claimStatus.map((data: any, index: any) => {
                return (
                  <tr>
                    <>
                      <td>{data.request_no}</td>

                      <td>{data.amount_to_withdraw}</td>
                      <td>
                        {data?.createdAt
                          ?.slice(0, 10)
                          .split("-")
                          .reverse()
                          .join("-")}
                      </td>
                      <td>{data.status}</td>
                    </>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </details>
        <Modal
          show={show}
          onHide={() => setShow(false)}
          dialogClassName="modal-90w"
          aria-labelledby="example-custom-modal-styling-title"
          size="xl"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-custom-modal-styling-title">
              Withdrawl
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div
              className="col-md-12"
              style={{ display: "flex", justifyContent: "Space-between" }}
            >
              <div className="col-md-6" style={{ marginRight: "8px" }}>
                <div className="card">
                  <table className="table claim-modal-detail">
                    <thead>
                      <tr>
                        <th scope="col"></th>
                        <th scope="col">Total</th>
                        <th scope="col">Clan Share</th>
                        <th scope="col">Availaible to Withdraw</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td style={{ textAlign: "left" }}>
                          <strong>Livestream</strong>
                        </td>

                        <td>{sum}</td>

                        <td>{(sum * 20) / 100}</td>

                        <td>{availaibleToWithdrawLivestream}</td>
                      </tr>
                      <tr>
                        <td style={{ textAlign: "left" }}>
                          <strong>Rerun</strong>
                        </td>

                        <td>{reRunRevenue}</td>

                        <td>{(reRunRevenue * 10) / 100}</td>

                        <td>{availaibleToWithdrawRerun}</td>
                      </tr>

                      <tr>
                        <td style={{ textAlign: "left" }}>
                          <strong>Claimed</strong>
                        </td>

                        <td>{sum + reRunRevenue}</td>

                        <td>{Math.round(((reRunRevenue * 10) / 100 + (sum * 20) / 100) * 100) / 100}</td>

                        <td>
                          {Math.round((availaibleToWithdrawLivestream +
                            availaibleToWithdrawRerun) * 100) / 100}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div className="claim-section">
                    <input
                      type="text"
                      placeholder="Remarks"
                      // placeholder="This will be cover title of your livestream."

                      onChange={(event: any) => onChangeRemarks(event)}
                      value={remarks}
                    />
                    <div className="claim-button-amount">
                      <button
                        type="button"
                        style={{ margin: "0 auto" }}
                        className="btn btn-black btn-sm appearance"
                        disabled={amount.length <= 0 ? true : false}
                        onClick={handleClaim}
                      >
                        Claim
                      </button>
                      <input
                        // fullWidth
                        type="number"
                        placeholder="Enter amount to withdraw"
                        // placeholder="This will be cover title of your livestream."

                        onChange={(event: any) => onChangeAmount(event)}
                        value={amount}
                        required
                      />
                      
                    </div>
                   
                  </div>
                  {amountError && (
                        <p
                        
                          className="amount-error-field"
                        >
                          *{amountError}
                        </p>
                      )}
                  {/* <input
                      type="text"
                      style={{ textAlign: "right" }}
                      value={remarks}
                      onChange={(event: any) => onChangeRemarks(event)}
                    /> */}

                  {/* <input
                      type="number"
                      style={{ textAlign: "right" }}
                      value={amount}
                      onChange={(event: any) => onChangeAmount(event)}
                    />
                    {amountError && (
                      <p
                        style={{
                          color: "red",
                          fontSize: "14px",
                          marginBottom: "0",
                        }}
                      >
                        {amountError}
                      </p>
                    )} */}
                </div>
              </div>
              <div className="col-md-6">
                <div className="card claim-account-details">
                  <h3 style={{ textAlign: "left", fontSize: "15px" }}>
                    Account Details
                  </h3>
                  {userDetails[0].payout_details?.map(
                    (data: any, index: any) => {
                      console.log(data);
                      return (
                        <div>
                          <p>
                            <strong>Account Holder Name : </strong>
                            {data?.account_holder_name}
                          </p>
                          <p className="claim-account-details">
                            <strong>Bank Name : </strong>
                            {data?.bank_name}
                          </p>
                          <p className="claim-account-details">
                            <strong>Account No. : </strong>
                            {data?.account_number}
                          </p>
                          <p className="claim-account-details">
                            <strong>IFSC : </strong>
                            {data?.ifsc}
                          </p>
                          <p className="claim-account-details">
                            <strong>Account Type : </strong>
                            {data?.account_type}
                          </p>
                          <p className="claim-account-details">
                            <strong>Address : </strong>
                            {data?.address}
                          </p>
                        </div>
                      );
                    }
                  )}
                </div>
              </div>
            </div>
            <p
              style={{
                fontSize: "12px",
                color: "grey",
                textAlign: "center",
                marginTop: "10px",
              }}
            >
              * Amount will be credited to your Account in 5 working days
            </p>
          </Modal.Body>
        </Modal>
        <Modal
          dialogClassName="sign-up-modal app-store blockchain-wait"
          show={showAccountDetails}
          onHide={handleCloseAccount}
        >
          <Modal.Header closeButton>
            <Modal.Title className="wait-popup">
              Account Details Missing ?
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div
              className="auth-container top-space-login pl-3 pr-3 pt-5"
              style={{ display: "block" }}
            >
              <div className="auth-info text-center">
                <p style={{ textAlign: "center" }}>
                  Please fill your account detail first.
                </p>
                <div className="btn-app-store mb-4">
                  <button
                    className="btn btn-outline-black d-inline-flex col-auto me-2"
                    onClick={handleRedirectSettingPage}
                  >
                    Ok
                  </button>
                  {/* <button
                        className="btn btn-black d-inline-flex col-auto"
                        onClick={handleClose}
                        // onClick={onStopLivestream}
                      >
                        ok
                      </button> */}
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
      </div>
    </div>
    </>
  );
};
const mapStateToProps = (state: any) => {
  return {
    UserReducer: state.UserReducer,
    LoginUserIdReducer: state.LoginUserIdReducer,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    loading: (data: any) => dispatch(Loading(data)),
  };
};

const connectedNavBar = connect(
  mapStateToProps,
  mapDispatchToProps
)(MainDashboard);
export { connectedNavBar as MainDashboard };
