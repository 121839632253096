import {
  GetClubReducerState,
  LoadingReducerState,
  LoginPopupReducerState,
  LoginUserIdReducerState,
  PostCountReducerState,
  SaveClubReducerState,
  UserReducerState,
} from "./user-reducer-state";
import {
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  LOGOUT,
  LOADING,
  LOGINPOPUP,
  SAVECLUB,
  GETCLUB,
  LOGINUSERID,
  POSTSCOUNT,
} from "../types";

const initialState: UserReducerState = {
  IsUserLogged: false,
  UserData: null,
  triggerLogin: false,
  loading: false,
  loginPopup: false,
};

const loadingInitialState: LoadingReducerState = {
  loading: false,
};
const loginPopupInitialState: LoginPopupReducerState = {
  loginPopup: false,
  popupType: "",
};
const saveClubInitialState: SaveClubReducerState = {
  saveClub: null,
};

const getClubInitialState: GetClubReducerState = {
  getClub: false,
};

const loginUserIdInitialState: LoginUserIdReducerState = {
  loginUserId: null,
};
const postCountInitialState: PostCountReducerState = {
  postCount: null,
};

export const UserReducer: any = (
  state: UserReducerState = initialState,
  action: any
) => {
  switch (action.type) {
    case LOGIN_ERROR:
      return { ...state, IsUserLogged: false };
    case LOGIN_SUCCESS:
      return { ...state, UserData: action.data, IsUserLogged: true };
    case LOGOUT:
      return { ...state, UserData: null, IsUserLogged: false };
    case LOADING:
      return { ...state };
    case LOGINPOPUP:
      return { ...state };
    default:
      return state;
  }
};

export const LoadingReducer: any = (
  loading: LoadingReducerState = loadingInitialState,
  action: any
) => {
  switch (action.type) {
    case LOADING:
      return { loading: action.data };
    default:
      return loading;
  }
};

export const LoginPopupReducer: any = (
  isLogin: LoginPopupReducerState = loginPopupInitialState,
  action: any
) => {
  switch (action.type) {
    case LOGINPOPUP:
      return { loginPopup: action.data };
    default:
      return isLogin;
  }
};

export const SaveClubReducer: any = (
  saveClub: SaveClubReducerState = saveClubInitialState,
  action: any
) => {
  switch (action.type) {
    case SAVECLUB:
      return { saveClub: action.data };
    default:
      return saveClub;
  }
};

export const GetClubReducer: any = (
  getClub: GetClubReducerState = getClubInitialState,
  action: any
) => {
  switch (action.type) {
    case GETCLUB:
      return { getClub: action.data };
    default:
      return getClub;
  }
};

export const LoginUserIdReducer: any = (
  loginUserId: LoginUserIdReducerState = loginUserIdInitialState,
  action: any
) => {
  switch (action.type) {
    case LOGINUSERID:
      return { loginUserId: action.data };
    default:
      return loginUserId;
  }
};

export const PostCountReducer: any = (
  postCount: PostCountReducerState = postCountInitialState,
  action: any
) => {
  switch (action.type) {
    case POSTSCOUNT:
      return { postCount: action.data };
    default:
      return postCount;
  }
};

const dataState = { faq: [] };
export const fetchInfo: any = (state = dataState, action: any) => {
  switch (action.type) {
    case "faqData":
      // console.log("my data", action.data)
      return {
        faq: JSON.parse(action.data),
      };
    default:
      return state;
  }
};

// const creatorDataState = { influencer: [] };
// export const getInfo: any = (state = creatorDataState, action: any) => {
//   switch (action.type) {
//     case "getInfluencersData":
//       //  console.log("my data", action.data)
//       return {
//         influencer: JSON.parse(action.data),
//       };

//     default:
//       return state;
//   }
// };

