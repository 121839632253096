import React, { useEffect, useState, useRef } from "react";
import { NavLink, Link, useHistory } from "react-router-dom";
import CardTicketImage from "../../../assets/img/live-stream.webp";
import Banner from "../../../assets/img/live-stream.webp";
import { connect, useDispatch } from "react-redux";
import { UserService } from "../../../app/service/user.service";
import { LivestreamService } from "../../../app/service/livestream";
import moment from "moment";
import { Dropdown, Modal } from "react-bootstrap";
import { STREAMING_HOST } from "../../../config/constant";
import { Loading } from "../../../redux/actions";
import { isMobile } from "react-device-detect";
import CardLivestream from "../../../components/card-livestream";
import CardPastEvents from "../../../components/card-past-events";
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import { FacebookIcon, TwitterIcon } from "react-share";
import { toast } from "react-toastify";
import Slider from "react-slick";
import MobileViewModal from "../../../components/MobileViewModal";

interface Props {
  UserReducer?: any;
  loading?: (data: any) => void;
}

const CreatorLivestream: React.FC<Props> = (props: Props) => {
  const [isUserLogin, setUserLogin] = useState(
    sessionStorage.getItem("isLogin") || ""
  );
  const [canShowUpload, setCanShowUpload] = useState(false);
  const [goLiveLiveStream, setGoLiveLiveStream] = useState<any>();
  const dispatch = useDispatch();

  const [loginUserData, setLoginUserData] = useState<any[] | []>([]);
  const [livestream, setLivestream] = useState<any[] | []>([]);
  const [pasLlivestream, setPasLlivestream] = useState<any[] | []>([]);
  const [upcommingstream, setUpcommingstream] = useState<any[] | []>([]);
  const [showUserLivestreamPopup, setShowUserLivestreamPopup] = useState(false);
  const handleCloseUserLivestreamPopup = () =>
    setShowUserLivestreamPopup(false);
  const handleShowUserLivestreamPopup = () => setShowUserLivestreamPopup(true);
  const [selectedLivestream, setSelectedLivestream] = useState<any>();
  const [selectedLivestreanType, setSelectedLivestreanType] = useState("web");
  const history = useHistory();
  const sliderRef = useRef<any>()
  let userId = sessionStorage.getItem("isLogin") || "";
  const [purchasedLivestream, setPurchasedLivestream] = useState<any[] | []>(
    []
  );
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [showIsMobileGoLive, setShowIsMobileGoLive] = useState(false);
  const handleCloseShowIsMobileGoLive = () => setShowIsMobileGoLive(false);
  const handleShowShowIsMobileGoLive = () => setShowIsMobileGoLive(true);

  const gotoNext = () => {
    // console.log("Hello World")
    return sliderRef?.current?.slickNext();
  
  }
  useEffect(() => {
    // gotoNext();
    localStorage.setItem('thiredPartyData', '');
    if (props.loading) {
      dispatch(props.loading(true));
    }
    const _id = sessionStorage.getItem("isLogin") || "";
    if (_id) {
      UserService.getUser(_id).then((res) => {
        if (res) {
          sessionStorage.setItem("userImages", "");
          setLoginUserData(res);
          if (res.length > 0) {
            sessionStorage.setItem("userImages", res[0]?.image);
          }
          if (res[0]?.club_influencer) {
            LivestreamService.getUpcomingLiveStreamByCreatorID(_id).then(
              (res) => {
                if (res) {
                  const data = res.sort((a: any, b: any) =>
                    a.schedule_From < b.schedule_From
                      ? 1
                      : b.schedule_From < a.schedule_From
                      ? -1
                      : 0
                  );
                  setUpcommingstream([...res]);
                  if (props.loading) {
                    dispatch(props.loading(false));
                  }
                }
              }
            );
          }
        }
      });
    }

   

    LivestreamService.getScheduledLivestream("false").then((res) => {
      if (res) {
        // console.log("aaaaa", res);

        setLivestream([...res]);
      }
    });

    LivestreamService.getCompletedLivestream("false").then((res) => {
      if (res) {
        setPasLlivestream([...res]);
        if (props.loading) {
          dispatch(props.loading(false));
        }
      }
    });
    if (userId) {
      LivestreamService.getScheduledLiveStreamsByUserID(userId).then((res) => {
        if (res) {
          setPurchasedLivestream([...res]);
        }
      });
    }
  }, [ gotoNext() ]);

  const onGolive = (data: any) => {
    if (isMobile) {
      handleShowShowIsMobileGoLive();
    } else {
      setSelectedLivestream(data);
      handleShowUserLivestreamPopup();
    }
  };

  const onChangeLivestream = (event: any) => {
    setSelectedLivestreanType(event);
  };
  const startStreaming = (data: any) => {
    localStorage.setItem('thiredPartyData', '');
    handleCloseUserLivestreamPopup();
    setGoLiveLiveStream(data);
    setCanShowUpload(true);
    LivestreamService.goLiveStream(selectedLivestream).then((res) => {
      if (res) {
        if (isMobile) {
          history.push(
            `/golivestream/${selectedLivestream?._id}/${selectedLivestream?.club_id}`
          );
        } else {
          window.open(
            `${window.location.origin}/#/golivestream/${selectedLivestream?._id}/${selectedLivestream?.club_id}`,
            "_blank"
          );
        }
        // history.push(
        //   `/golivestream/${selectedLivestream?._id}/${selectedLivestream?.club_id}`
        // );
      }
    });
  };

  const onThairedParty = () => {
    const data = { server: `rtmp://${STREAMING_HOST}:1935/live`, stream_key: `${selectedLivestream?.collectionName}~${selectedLivestream?._id}` }
    localStorage.setItem('thiredPartyData', JSON.stringify(data));
    window.open(
      `${window.location.origin}/#/golivestream/${selectedLivestream?._id}/${selectedLivestream?.club_id}`,
      "_blank"
    );
  }

  const onClickEnter = (data: any) => {
    LivestreamService.getlivestreamById(data?.live_stream_data[0]?._id).then(
      (res) => {
        if (res) {
          console.log("res", res);
          if (res[0].status === "LIVE") {
            if (isMobile) {
              history.push(`/livestream/${data?.live_stream_data[0]?._id}`);
            } else {
              window.open(
                `${window.location.origin}/#/livestream/${data?.live_stream_data[0]?._id}`,
                "_blank"
              );
            }
          } else {
            history.push(
              `/livestreams/livestream-soon/${data?.live_stream_data[0]?._id}`
            );
          }
        }
      }
    );
  };
  const [copied, setCopied] = useState(false);

  const copy = (data: any) => {
    console.log(data);
    LivestreamService.getlivestreamById(data?._id).then((res) => {
      console.log(res[0]?._id);
      const el = document.createElement("input");
      navigator.clipboard.writeText(
        `${window.location.origin}/#/livestreams/livestream-detail/${res[0]?._id}`
      );
      setCopied(true);
      toast("Link Copied");
    });
  };
  const settings = {
    dots: false,
    infinite:false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <section className="livestream-purchased-info mt-4">
      <div className="container-fluid">
        {(isUserLogin || props?.UserReducer?.IsUserLogged) &&
          loginUserData[0]?.club_influencer && (
            <div className="livestream-section">
              <div className="row">
                <div className="heading-sec">
                  <h3>My Upcoming Livestreams</h3>
                  <div className="heading-sec-actions">
                    <NavLink
                      to={`/livestreams/create-livestream/0`}
                      type="button"
                      className="btn btn-black btn-wide"
                    >
                      Create
                    </NavLink>
                    <NavLink
                      to={"/dashboard/mylivestreams/scheduled"}
                      className="btn btn-outline-black"
                    >
                      View All
                    </NavLink>
                  </div>
                </div>
              </div>
              <div className="row mb-5">
                {upcommingstream.map((data: any, index: number) => {
                  return (
                    <div
                      key={index}
                      className="col-sm-6 col-lg-4 col-xxl-3 mb-3"
                    >
                      <div
                        className="card-livestream square"
                        style={{
                          backgroundImage: data?.thumbnail
                            ? `url("${data?.thumbnail}")`
                            : "linear-gradient(to right, #DDEFBB, #FFEEEE)",
                          backgroundPosition: "center center",
                          backgroundSize: "cover",
                        }}
                      >
                        <Dropdown className="share-dropdown">
                          <Dropdown.Toggle className="" id="share-dropdown">
                            <i className="bi bi-share"></i>
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            <Dropdown.Item>
                              <FacebookShareButton
                                url={`https://www.clanworld.ai/#/livestreams/livestream-detail/${data?._id}`}
                                hashtag="Influencer Marketing, Livestream"
                                className="Demo__some-network__share-button"
                              >
                                <FacebookIcon size={24} round /> Facebook
                              </FacebookShareButton>
                            </Dropdown.Item>
                            <Dropdown.Item>
                              <TwitterShareButton
                                url={`https://www.clanworld.ai/#/livestreams/livestream-detail/${data?._id}`}
                                hashtags={[
                                  "Influencer Marketing",
                                  "Livestream",
                                ]}
                                className="Demo__some-network__share-button"
                              >
                                <TwitterIcon size={24} round /> Twitter
                              </TwitterShareButton>
                            </Dropdown.Item>
                            <Dropdown.Item>
                              <WhatsappShareButton
                                url={`${window.location.origin}/#/livestreams/livestream-detail/${data?._id}`}
                                className="Demo__some-network__share-button"
                              >
                                <WhatsappIcon size={24} round /> Whatsapp
                              </WhatsappShareButton>
                            </Dropdown.Item>
                            <Dropdown.Item>
                              <a
                                href="javascript:void(0)"
                                onClick={() => copy(data)}
                                style={{ textDecoration: "none" }}
                              >
                                <i
                                  className="bi bi-clipboard"
                                  style={{
                                    width: "24px",
                                    marginRight: "10px",
                                    marginLeft: "4px",
                                    fontSize: "14px",
                                    textAlign: "center",
                                  }}
                                ></i>
                                Copy URL
                              </a>
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                        <div className="card-livestream-content">
                          <h3 className="card-livestream-title">
                            <a
                              href="javascript:void(0)"
                              onClick={() => {}}
                              title="title"
                            >
                              {data?.title}
                            </a>
                          </h3>

                          <span className="card-livestream-date">
                            {moment(data?.schedule_From)
                              .utc()
                              .format("DD-MM-YYYY | hh:mm A")}{" "}
                            IST
                          </span>
                        </div>
                        {/* <NavLink to={`/livestreams/create-livestream/${data?._id}`} className="btn btn-outline-black mx-auto">Go Live</NavLink> */}
                        <a
                          href="javascript:void(0)"
                          className="btn btn-outline-black mx-auto mb-2"
                          onClick={
                            !isMobile ? () => onGolive(data) : handleShow
                          }
                        >
                          Go Live
                        </a>
                        {/* <a
                          href="javascript:void(0)"
                          className="btn btn-outline-black mx-auto ms-2"
                        >
                          Share
                        </a> */}
                        <MobileViewModal
                          show={show}
                          handleClose={handleClose}
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        {(isUserLogin || props?.UserReducer?.IsUserLogged) &&
          !loginUserData[0]?.club_influencer && (
            <div className="livestream-section">
              <div className="row">
                <div className="heading-sec">
                  <h3>My Tickets</h3>
                  <div className="heading-sec-actions">
                    <button
                      type="button"
                      className="btn btn-outline-black ms-0 ms-md-3"
                    >
                      View All
                    </button>
                  </div>
                </div>
              </div>
              <div className="row mb-5">
                {purchasedLivestream.map((data: any, index: number) => {
                  return (
                    <div className="col-sm-6 col-lg-4 col-xxl-3 mb-3 mb-4">
                      <div className="card-ticket">
                        {console.log("data?.thumbnail", data, data?.thumbnail)}

                        <figure className="card-ticket-img ratio ratio-4x3">
                          <img
                            src={
                              data?.live_stream_data[0]?.thumbnail
                                ? data?.live_stream_data[0]?.thumbnail
                                : CardTicketImage
                            }
                          />
                        </figure>
                        <div className="card-ticket-content">
                          <span className="card-ticket-content-title">
                            {data?.live_stream_data[0]?.title}
                          </span>
                          <strong className="card-ticket-content-authorname">
                            {data?.user_info[0]?.name}
                          </strong>
                          <span className="card-ticket-content-time">
                            {moment(data?.live_stream_data[0]?.schedule_From)
                              .utc()
                              .format("lll")}{" "}
                            IST
                          </span>
                          <ul className="card-ticket-price-info">
                            <li>
                              <span>Ticket Price</span>
                              <span>
                                {data?.live_stream_data[0]?.price} INR
                              </span>
                            </li>
                            <li>
                              <span>Attendees</span>
                              <span>{data?.live_stream_data[0]?.tickets}</span>
                            </li>
                            <li>
                              <strong>Total</strong>
                              <strong>
                                {data?.live_stream_data[0]?.price} INR
                              </strong>
                            </li>
                          </ul>
                        </div>
                        <div className="card-ticket-footer">
                          {/* <NavLink className="btn btn-black" to={`/livestream/${data?.live_stream_data[0]?._id}`}>Enter</NavLink> */}
                          <button
                            className="btn btn-black"
                            onClick={
                              !isMobile ? () => onClickEnter(data) : handleShow
                            }
                          >
                            Enter
                          </button>
                          <a href="" className="btn btn-link">
                            <i className="bi bi-link-45deg"></i>
                          </a>
                        </div>
                      </div>
                      <MobileViewModal show={show} handleClose={handleClose} />
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        <div className="livestream-section">
          <div className="row">
            <div
              className={` ${
                isUserLogin || props?.UserReducer?.IsUserLogged
                  ? "heading-sec"
                  : "text-center"
              }`}
            >
              <div className="d-flex flex-column align-items-center">
                {isUserLogin || props?.UserReducer?.IsUserLogged ? (
                  <h3>Streaming Soon</h3>
                ) : (
                  <h2>Streaming Soon</h2>
                )}
              </div>
              <div
                className={`heading-sec-actions ${
                  isUserLogin || props?.UserReducer?.IsUserLogged
                    ? ""
                    : "d-inline-block mb-2"
                }`}
              >
                {/* <button type="button" className="btn btn-outline-black">
                  View All
                </button> */}
              </div>
            </div>
          </div>
          <div className="row mb-5">
            {livestream.map((data: any, index: number) => {
              return (
                <div
                  key={index}
                  className="col-sm-6 col-lg-4 col-xxl-3 mb-3 mb-4"
                >
                  <CardLivestream data={data} />
                  {/* <div className="card-livestream d-none d-md-block">
                    <span
                      className="card-livestream-bgimg"
                      style={{
                        backgroundImage: data?.thumbnail
                          ? `url("${data?.thumbnail}")`
                          : "linear-gradient(to right, #DDEFBB, #FFEEEE)",
                        backgroundPosition: "center center",
                        backgroundSize: "cover",
                      }}
                    ></span>
                    <img
                      className="card-livestream-img"
                      src={data?.thumbnail}
                      alt={data?.title}
                    />
                    <div className="card-livestream-content">
                      {/* <a
                        href="javascript:void(0)"
                        onClick={() => {}}
                        className="ratio ratio-1x1 mb-3 video-img"
                      >
                        <video
                          poster=""
                          className="my_club_media"
                          controls
                          controlsList="nodownload" 
                          src={data?.video_url}
                        >
                          <source src="" type="video/mp4" />
                          Your browser does not support HTML video.
                        </video>
                      </a> 

                      <h3 className="card-livestream-title">
                        <a
                          href="javascript:void(0)"
                          onClick={() => {}}
                          title="title"
                        >
                          {data?.title}
                        </a>
                      </h3>
                      <span className="dive-in-channel position-relative">
                        @{data?.user_info[0]?.name}
                      </span>
                      <span className="card-livestream-date">
                        {moment(data?.schedule_From).utc().format("lll")} IST
                      </span>
                    </div>
                    <NavLink
                      to={`/livestreams/livestream-detail/${data?._id}`}
                      className="btn btn-outline-black mx-auto"
                    >
                      View Details
                    </NavLink>
                  </div> */}
                  {/* Mobile View */}
                  {/* <NavLink
                    to={`/livestreams/livestream-detail/${data?._id}`}
                    className="card-livestream d-block d-md-none"
                  >
                    <span
                      className="card-livestream-bgimg"
                      style={{
                        backgroundImage: data?.thumbnail
                          ? `url("${data?.thumbnail}")`
                          : "linear-gradient(to right, #DDEFBB, #FFEEEE)",
                        backgroundPosition: "center center",
                        backgroundSize: "cover",
                      }}
                    ></span>
                    <img
                      className="card-livestream-img"
                      src={data?.thumbnail}
                      alt={data?.title}
                    />
                    <div className="card-livestream-content">
                      <h3 className="card-livestream-title">
                        <a
                          href="javascript:void(0)"
                          onClick={() => {}}
                          title="title"
                        >
                          {data?.title}
                        </a>
                      </h3>
                      <span className="dive-in-channel position-relative">
                        @{data?.user_info[0]?.name}
                      </span>
                      <span className="card-livestream-date">
                        {moment(data?.schedule_From).utc().format("lll")} IST
                      </span>
                    </div>
                  </NavLink> */}
                </div>
              );
            })}
          </div>
        </div>
        <div className="livestream-section">
          <div className="row">
            <div
              className={` ${
                isUserLogin || props?.UserReducer?.IsUserLogged
                  ? "heading-sec"
                  : "text-center"
              }`}
            >
              {isUserLogin || props?.UserReducer?.IsUserLogged ? (
                <h3>Past Events</h3>
              ) : (
                <h2>Past Events</h2>
              )}
              {/* <Link
                to="/livestreams/livestream-listing"
                className="btn btn-outline-black d-inline-flex  btn-wide mb-4"
              >
                View All
              </Link> */}
              <div
                className={`heading-sec-actions ${
                  isUserLogin || props?.UserReducer?.IsUserLogged
                    ? ""
                    : "d-inline-block mb-2"
                }`}
              >
                <Link
                  to="/livestreams/livestream-listing"
                  className="btn btn-outline-black d-inline-flex  btn-wide mb-4"
                >
                  View All
                </Link>
              </div>
            </div>
          </div>
          <div className="row mb-5">
            <Slider className="past-event-coll" ref={sliderRef} {...settings}>
              {pasLlivestream.map((data: any, index: number) => {
                return (
                  <div className="col-sm-6 col-lg-4 col-xxl-3 mb-3 mt-2">
                    <CardPastEvents data={data} />

                    {/* <div className="card-livestream square d-none d-md-block ">
                    <span
                      className="card-livestream-bgimg"
                      style={{
                        backgroundImage: data?.thumbnail
                          ? `url("${data?.thumbnail}")`
                          : "linear-gradient(to right, #DDEFBB, #FFEEEE)",
                        backgroundPosition: "center center",
                        backgroundSize: "cover",
                      }}
                    ></span>
                    <img
                      className="card-livestream-img"
                      src={data?.thumbnail}
                      alt={data?.title}
                    />
                    <span className="card-livestream-likes-count">
                      <i className="bi bi-heart"></i>18
                    </span>
                    <div className="card-livestream-content">
                      <h3 className="card-livestream-title">
                        <a
                          href="javascript:void(0)"
                          onClick={() => {}}
                          title={data.title}
                        >
                          {data.title}
                        </a>
                      </h3>
                      <span className="dive-in-channel ">
                        @{data?.user_info[0]?.name}
                      </span>
                      <span className="card-livestream-date">
                        {moment(data?.schedule_From).utc().format("ll")}
                      </span>
                    </div>
                    <NavLink
                      to={`/livestreams/livestream-detail/${data?._id}`}
                      className="btn btn-outline-black mx-auto"
                    >
                      View Details
                    </NavLink>
                  </div> */}
                    {/* Mobile View */}
                    {/* <NavLink
                    to={`/livestreams/livestream-detail/${data?._id}`}
                    className="card-livestream square d-block d-md-none"
                  >
                    <span
                      className="card-livestream-bgimg"
                      style={{
                        backgroundImage: data?.thumbnail
                          ? `url("${data?.thumbnail}")`
                          : "linear-gradient(to right, #DDEFBB, #FFEEEE)",
                        backgroundPosition: "center center",
                        backgroundSize: "cover",
                      }}
                    ></span>
                    <img
                      className="card-livestream-img"
                      src={data?.thumbnail}
                      alt={data?.title}
                    />
                    <span className="card-livestream-likes-count">
                      <i className="bi bi-heart"></i>18
                    </span>
                    <div className="card-livestream-content">
                      <h3 className="card-livestream-title">
                        <a
                          href="javascript:void(0)"
                          onClick={() => {}}
                          title={data.title}
                        >
                          {data.title}
                        </a>
                      </h3>
                      <span className="dive-in-channel ">
                        @{data?.user_info[0]?.name}
                      </span>
                      <span className="card-livestream-date">
                        {moment(data?.schedule_From).utc().format("ll")}
                      </span>
                    </div>
                  </NavLink> */}
                  </div>
                );
              })}
            </Slider>
          </div>
        </div>
      </div>
      <Modal
        show={showUserLivestreamPopup}
        onHide={handleCloseUserLivestreamPopup}
        centered
        dialogClassName="sign-up-modal login-modal"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="auth-container">
            <div className="auth-sec auth-right-sec">
              <div className="auth-info">
                {/* <h2>Aler_t</h2> */}
              </div>
              <div className="row">
                <h4>Start livestream using</h4>
                <div>
                  <input
                    type="radio"
                    name="isLivestreamType"
                    id="isLivestreamType"
                    value={selectedLivestreanType}
                    checked={selectedLivestreanType === "web"}
                    onChange={() => onChangeLivestream("web")}
                  />{" "}
                  <span className="me-2"> Web livestream</span>
                  <div>
                  <input
                    type="radio"
                    name="isLivestreamType"
                    id="isLivestreamType"
                    value={selectedLivestreanType}
                    checked={selectedLivestreanType === "3rdparty"}
                    onChange={() => onChangeLivestream("3rdparty")}
                  />
                  <span> 3rd party software</span>
                  </div>
                  {/* {selectedLivestreanType === "3rdparty" && (
                    <>
                      <div className="mt-2">
                        <label>Server</label>{" "}
                        <i
                          className="bi bi-clipboard ms-2 pointer"
                          title="Copy to clipboard"
                          onClick={() => {
                            navigator.clipboard.writeText(
                              `rtmp://${STREAMING_HOST}:1935/live`
                            );
                          }}
                        ></i>
                        <input
                          type="text"
                          className="form-control mt-2"
                          readOnly
                          value={`rtmp://${STREAMING_HOST}:1935/live`}
                        />
                      </div>
                      <div className="mt-2">
                        <label>Stream Key</label>{" "}
                        <i
                          className="bi bi-clipboard ms-2 pointer"
                          title="Copy to clipboard"
                          onClick={() => {
                            navigator.clipboard.writeText(
                              `${selectedLivestream?.collectionName}~${selectedLivestream?._id}`
                            );
                          }}
                        ></i>
                        <input
                          type="text"
                          className="form-control mt-2"
                          readOnly
                          value={`${selectedLivestream?.collectionName}~${selectedLivestream?._id}`}
                        />
                      </div>
                    </>
                  )} */}
                  <div className="mt-3">
                    {selectedLivestreanType === "web" && (
                      <button
                        style={{ padding: "0 12px", fontSize: "20px" }}
                        className="btn btn-outline-black"
                        onClick={startStreaming}
                        // to={`/golivestream/${selectedLivestream?._id}/${selectedLivestream?.club_id}`}
                      >
                        Go Live
                      </button>
                    )}
                    {selectedLivestreanType === "3rdparty" && <button
                      style={{ padding: "0 12px", fontSize: "20px" }}
                      className="btn btn-outline-black"
                      onClick={onThairedParty}
                    >
                      Ok
                    </button>}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
      <Modal
        show={showIsMobileGoLive}
        onHide={handleCloseShowIsMobileGoLive}
        centered
        dialogClassName="sign-up-modal login-modal"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="auth-container">
            <div className="auth-sec auth-right-sec">
              <div className="auth-info">
                <h2>Alert</h2>
              </div>
              <div className="row"></div>
              Please download mobile app and start stream.
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </section>
  );
};

const mapStateToProps = (state: any) => {
  return {
    UserReducer: state.UserReducer,
    // LoginPopupReducer: state.LoginPopupReducer,
    // LoginUserIdReducer: state.LoginUserIdReducer,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    // logout: () => dispatch(UserLogout()),
    // login: (data: UserData) => dispatch(UserLogin(data)),
    // loginPopup: (data: any) => dispatch(LoginPopup(data)),
    // loginUserId: (data: any) => dispatch(LoginUserId(data)),
    loading: (data: any) => dispatch(Loading(data)),
  };
};
const connectedNavBar = connect(
  mapStateToProps,
  mapDispatchToProps
)(CreatorLivestream);
export { connectedNavBar as CreatorLivestream };
