import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom';
import { Clubs, ClubsPost } from '../../../app/model/clubs';
import { SubscriptionPackages } from '../../../app/model/subscription-packages';
import { ClubService } from '../../../app/service/club.service';
import { SubscriptionPackagesService } from '../../../app/service/subscription-packages-service';
import dummyImg from '../../../assets/img/dummy-img.svg'
import PlaceholderImage from "../../../assets/img/dummy-img.svg";

export const MyClubsPreviewPage: React.FC = () => {
    let { id } = useParams<{ id: string }>();
    const aboutRef = React.useRef<HTMLDivElement>(null);
    const packagesRef = React.useRef<HTMLDivElement>(null);
    const postsRef = React.useRef<HTMLDivElement>(null);
    const [club, setClubs] = useState<Clubs>();
    const [subscriptionPackages, setSubscriptionPackages] = useState<
        SubscriptionPackages[] | []
    >([]);
    const [clubPosts, setclubPosts] = useState<ClubsPost[] | []>([]);
    const [clubSubscriber, setClubSubscriber] = useState<[]>([]);
    const onPackagesClick = (elementRef: any) => {
        if (elementRef.current) {
            elementRef.current.scrollIntoView();
        }
    };
    const loginUserImage = sessionStorage.getItem('loginUserImage');
    const history = useHistory();

    useEffect(() => {
        const club = sessionStorage.getItem('clubPreview');
        if (id) {
            ClubService.getClub(id).then((res) => {
                if (res) {
                    setClubs(res[0]);
                    SubscriptionPackagesService.GET_SUBSCRIPTION_PACKAGES(res[0]?._id).then(
                        (res) => {
                            if (res) {
                                res.sort((a, b) => (a.name > b.name ? 1 : -1));
                                setSubscriptionPackages([...res]);
                            }
                        }
                    );
                }
            });
        }
    }, [])

    const onClosePreview = () => {
        if (sessionStorage.getItem('previewFrom') === 'packages') {
            history.push(`/dashboard/myclubs/packages`);
        } else {
            history.push(`/dashboard/myclubs/details`);
        }
    }
    return (
        <div className="main-content">
            <main className="main-content-sec-static-page">

                <section className="banner-cover category-detail-page">
                    <div className="row justify-content-end mb-2">
                        <div className="col-auto">
                            <a className="btn btn-primary me-2 d-inline" onClick={onClosePreview}>
                                Close
                            </a>
                        </div>
                    </div>
                    <img src={club?.cover_photo || dummyImg} alt="banner" />
                    <div className="banner-cover-profile">
                        <div className="banner-cover-profile__left">
                            <div className="banner-cover-profile__left-img">
                                <img src={loginUserImage || dummyImg} alt="banner" />
                            </div>
                            <div className="banner-cover-profile__info">
                                <h1 className="banner-cover-profile__info-title">
                                    {club?.name}
                                </h1>
                                <p className="banner-cover-profile__info-category-tags">
                                    {club?.tags}
                                </p>
                                <p className="banner-cover-profile__info-subs-count">
                                    {club?.total_subscribers} Subscribers
                                </p>
                            </div>
                        </div>
                        <div className="banner-cover-profile__right">
                            <a className="btn btn-outline-black btn-lg">
                                Enquiry
                            </a>
                            <div className="social-sec">
                                <a style={{ color: "#42CB86" }}>
                                    {" "}
                                    <i className="bi bi-instagram"></i>
                                </a>
                                <a style={{ color: "#42CB86" }}>
                                    {" "}
                                    <i className="bi bi-facebook"></i>
                                </a>
                                <a style={{ color: "#42CB86" }}>
                                    {" "}
                                    <i className="bi bi-twitter"></i>
                                </a>
                                <a style={{ color: "#42CB86" }}>
                                    {" "}
                                    <i className="bi bi-youtube"></i>
                                </a>
                                <a style={{ color: "#42CB86" }}>
                                    {" "}
                                    <i className="bi bi-share"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="card-detail-page">
                    <div className="custom-tab card-detail-tab">
                        <ul className="nav nav-pills">
                            <li className="nav-item" role="presentation">
                                <a
                                    className="nav-link pointer"
                                    id="details-tab"
                                >
                                    About
                                </a>
                            </li>
                            <li className="nav-item" role="presentation">
                                <a
                                    className="nav-link pointer"
                                    id="packages-tab"
                                >
                                    Packages
                                </a>
                            </li>
                            <li className="nav-item" role="presentation">
                                <a
                                    className="nav-link pointer"
                                    id="payments-tab"
                                >
                                    Posts
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className="card-detail-tab__content-sec">
                        <div className="card-detail-tab__content" id="about" ref={aboutRef}>
                            <div className="container container-small">
                                <div className="ratio ratio-16x9 mb-3 main-video">
                                    {club?.intro_video && <video width="560" height="315" controls controlsList="nodownload">
                                        <source src={club?.intro_video} type="video/mp4" />
                                        Your browser does not support HTML video.
                                    </video>}
                                </div>
                                <p>{club?.about}</p>
                            </div>
                        </div>
                        <div
                            className="card-detail-tab__content"
                            id="packages"
                            ref={packagesRef}
                        >
                            <div className="container container-medium">
                                <div className="package-sec row">
                                    {subscriptionPackages.map((subscription) => {
                                        return (
                                            <div key={subscription._id} className="col-md-4">
                                                <div className="package">
                                                    <div className="package-header">
                                                        <div className="package-info">
                                                            <strong>{subscription?.name}</strong>
                                                            <span className="package-price">
                                                                {subscription?.price_monthly}
                                                            </span>
                                                            <span className="package-term">per month</span>
                                                            <button className="btn btn-primary" >
                                                                Subscribe
                                                            </button>
                                                            <a className="package-link pointer">
                                                                or pay annually | SAVE 5%
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div className="package-desc">
                                                        <p>{subscription?.description} </p>
                                                        <ol>
                                                            {subscription.perks.map((perk, index) => {
                                                                return <li key={index}>{perk?.value}</li>;
                                                            })}
                                                        </ol>
                                                        {subscription.perks.length > 0 && (
                                                            <a>more</a>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </div>
    )
}
