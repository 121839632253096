import React, { useEffect, useState } from "react";
import ProgressBar from "react-bootstrap/esm/ProgressBar";
import { NavLink, useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { ClubsPost } from "../../../app/model/clubs";
import { SubscriptionPackages } from "../../../app/model/subscription-packages";
import { ClubService } from "../../../app/service/club.service";
import { SubscriptionPackagesService } from "../../../app/service/subscription-packages-service";
import { S3Helper } from "../../../app/utils/s3-helper";
import FormBuilder from "../../../components/form-builder";
import { S3_URL } from "../../../config/constant";

export const StaticPostPage = () => {
  let { id } = useParams<{ id: string }>();
  const [clubPosts, setClubPosts] = useState<any>({});
  const [isLoading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [content, setContent] = useState("");
  const [postAfterDays, setpostAfterDays] = useState("7");
  const [awsInfo, setAwsInfo] = useState<any>(null);
  const [progress, setProgress] = useState(0);
  const baseUrl = S3_URL;
  const club_id = sessionStorage.getItem("clubId") || "";
  const [videoUrl, setVideoUrl] = useState("");
  const [publicChecked, setPublicChecked] = useState(false);
  const handleChecked = () => {
    setPublicChecked(!publicChecked);
  };
  const [subscriptionPackages, setSubscriptionPackages] = useState<
    SubscriptionPackages[] | []
  >([]);
  const [avalableChecked, setAvalableChecked] = useState(false);
  const [avalableOptions, setAvalableOptions] = useState('');
  const [selectedSubscriptionPakages, setSelectedSubscriptionPakages] = useState<string[]>([]);
  const history = useHistory();
  const [selectedPostData, setselectedPostData] = useState<ClubsPost>();

  const handleCheckedAvalable = () => {
    setAvalableChecked(!avalableChecked);
  };

  useEffect(() => {
    const data = {
      club_id: club_id,
      type: 0,
    };
    ClubService.getAwsFolderInfo(data).then(
      res => {
        if (res) {
          setAwsInfo(res);
        } else {
          toast.error(res.message);
        }
      }
    )
    SubscriptionPackagesService.GET_SUBSCRIPTION_PACKAGES(club_id).then((res) => {
      if (res) {
        res.forEach(element => {
          element.isChecked = false;
        });
        setSubscriptionPackages(res);
        if (id !== "0") {
          ClubService.getClubPostDetails(club_id, id).then(
            postRes => {
              if (postRes) {
                setselectedPostData(postRes[0]);
                setVideoUrl(postRes[0].content);
                setProgress(100);
                setpostAfterDays(postRes[0].AVAILABLE);
                res.forEach(element => {
                  element.isExist = false;
                  const isExist = postRes[0].subscription_package_id.find((sub: any) => { return sub === element._id });
                  if (isExist) {
                    element.isExist = true;
                  } else {
                    element.isExist = false;
                  }
                });
                setSubscriptionPackages([...res]);
              } else {
                toast.error(postRes.message);
              }
            }
          )
        }
      }
    });
  }, []);
  const onSaveImage = () => {
    const data = clubPosts.value;
    let formData = new FormData();
    formData.append("type", "2");
    formData.append("post_type", "");
    formData.append("club_id", club_id);
    formData.append("title", data?.title ? data.title : (selectedPostData?.title ? selectedPostData?.title : ''));
    formData.append("description", data.description ? data.description : (selectedPostData?.description ? selectedPostData?.description : ''));
    formData.append("content", videoUrl ? videoUrl : (selectedPostData?.content ? selectedPostData?.content : ''));
    formData.append("price", data?.price ? data.price : (selectedPostData?.price ? selectedPostData?.price : ''));
    formData.append("total_likes", data?.total_likes ? data.total_likes : (selectedPostData?.total_likes ? selectedPostData?.total_likes : ''));
    formData.append("AVAILABLE", avalableChecked ? postAfterDays : (selectedPostData?.AVAILABLE ? selectedPostData?.AVAILABLE : ''));
    formData.append("DELETE_POST", avalableOptions === 'DELETE_POST' ? postAfterDays : (selectedPostData?.DELETE_POST ? selectedPostData?.DELETE_POST : ''));
    formData.append("FREE_POST", avalableOptions === 'FREE_POST' ? postAfterDays : (selectedPostData?.FREE_POST ? selectedPostData?.FREE_POST : ''));
    formData.append("AVAILABLE_TO_SUBSCRIBER", avalableOptions === 'AVAILABLE_TO_SUBSCRIBER' ? postAfterDays : (selectedPostData?.AVAILABLE_TO_SUBSCRIBER ? selectedPostData?.AVAILABLE_TO_SUBSCRIBER : ''));
    formData.append("subscription_package_id", selectedSubscriptionPakages.length > 0 ? JSON.stringify(selectedSubscriptionPakages) : (selectedPostData?.subscription_package_id ? JSON.stringify(selectedPostData?.subscription_package_id) : ''));
    formData.append(
      "total_comments",
      data.total_comments ? data.total_comments : (selectedPostData?.total_comments ? selectedPostData?.total_comments : '')
    );
    if (id === "0") {
      ClubService.createClubPost(formData).then(
        (res) => {
          toast.success('Club Post Created Successfully');
          setClubPosts(res);
          history.push("/dashboard/post");
        },
        (error) => { }
      );
    } else {
      formData.append("_id", id);
      ClubService.updateClubPost(formData).then(
        (res) => {
          toast.success('Club Post Updated Successfully');
          setClubPosts(res);
          history.push("/dashboard/post");
        },
        (error) => { }
      );
    }
  };
  const updateFormData = (data: any) => {
    setClubPosts(data);
  };

  const onUploadProgress = (data: any) => {
    const progress = Math.round((data.loaded / data.total) * 100);
    setProgress(progress);
  };
  const onUploadContent = async (event: any) => {
    setVideoUrl('');
    setProgress(0);
    if (event.target.files && event.target.files[0]) {
      setContent(event.target.files[0]);
      const result = await S3Helper.uploadFile(event.target.files[0], onUploadProgress, awsInfo);
      setVideoUrl(`${baseUrl}${awsInfo.folderPath}/${event.target.files[0].name}`);
      toast.success('Image Uploaded Successfully');
    }
  }

  const onSelectPostAvailable = (event: any) => {
    setpostAfterDays(event.target.value);
  }

  const onPublishClubPost = (data: ClubsPost) => {
    ClubService.publishClubPost(data).then(
      res => {
        if (res) {
          toast.success('Club post published successfully');
        }
      }
    );
  }

  const onChangeAvalableOptions = (event: any, optionStr: string) => {
    setAvalableOptions(optionStr);
  }

  const onChangeSubscriptionPakages = (event: any, index: number) => {
    const selectedSubscription = selectedSubscriptionPakages.find(element => { return element === event });
    if (selectedSubscription) {
      const list = [...selectedSubscriptionPakages];
      list.splice(index, 1);
      setSelectedSubscriptionPakages([...list]);

    } else {
      const list = [...selectedSubscriptionPakages];
      list.push(event);
      setSelectedSubscriptionPakages(list);
    }
  }
  return (
    <div className="main-content">
      <div className="container container-custom">
        <div className="d-flex flex-wrap align-items-start">

          <div className="dropdown create-post">
            <span
              className="title-w-bdr dropdown-toggle"
              id="dropdownMenuLink"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <span>Static Post</span>
            </span>
            <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink">
              <li>
                <a className="dropdown-item" href="#/dashboard/video">
                  Video Post
                </a>
              </li>
              <li>
                <a className="dropdown-item" href="#/dashboard/audio">
                  Audio
                </a>
              </li>
              <li>
                <a className="dropdown-item" href="#/dashboard/text">
                  Text
                </a>
              </li>
              <li>
                <a className="dropdown-item" href="#/dashboard/polls">
                  Polls
                </a>
              </li>
            </ul>
          </div>
          <button className="ms-auto btn btn-primary ms-2" onClick={() => onPublishClubPost(clubPosts)}>
            Publish
          </button>
        </div>
        <FormBuilder onUpdate={updateFormData}>
          <form>
            <div className="row post-form-sec">
              <div className="col-md-9 post-form">
                <div className="upload-sec upload-video custom">
                  <div className="button_video_outer">
                    <div className="form-floating mb-3">
                      <input
                        className="form-control"
                        type="text"
                        placeholder="title"
                        name="title"
                        defaultValue={selectedPostData?.title}
                      />
                      <label htmlFor="title">Title</label>
                    </div>
                    <div className="btn_video_upload btn">
                      <input
                        className="upload_video_file"
                        id="upload_video_file"
                        type="file"
                        name="content"
                        accept="image/jpg, image/jpeg, image/png, image/svg, image/gif"
                        onChange={(e) => onUploadContent(e)}
                      />
                      <i className="bi bi-cloud-arrow-up"></i>
                      <strong>Upload Image </strong>
                      <span>(drag and drop or browse)</span>
                      <span className="upload-accept-filename">
                      jpg, jpeg, png, svg, gif
                      </span>
                    </div>
                    {progress >= 100 && videoUrl && (
                      <img src={videoUrl} />)
                    }
                    {progress > 0 && progress < 100 &&
                      <div className="processing_video"> <span className="uploading_value">Uploading... {progress} %</span>
                        <ProgressBar animated now={progress} />
                      </div>
                    }
                    <div className="processing_video_bar"></div>
                    <div className="success_video_box"></div>
                  </div>
                  <div className="error_video_msg upload_video_error_msg"></div>
                  <div className="uploaded_video_view" id="uploaded_video_view">
                    <span className="file_video_remove">X </span>
                  </div>
                </div>
                <div className={publicChecked ? "make-it-available-sec hidden" : "make-it-available-sec "}>
                  <div className="custom-checkbox no-bdr d-inline-flex">
                    <input id="makeItAvailable" type="checkbox" name="AVAILABLE" checked={selectedPostData?.AVAILABLE ? true : false} onClick={handleCheckedAvalable} />
                    <label htmlFor="makeItAvailable">
                      Make it available as a paid post for
                    </label>
                  </div>
                  <select
                    className="form-select form-select-sm w-auto d-inline-flex"
                    aria-label="Available for" value={selectedPostData?.AVAILABLE} onChange={(e) => onSelectPostAvailable(e)}
                  >
                    <option value="7">7days</option>
                    <option value="15">15days</option>
                    <option value="30">30days</option>
                  </select>
                  <div className="form-text fs-xsml mb-3" id="emailHelp">
                    The people who are not subscribers will be able to view this
                    post at a certain price.
                  </div>
                  <div className="row mb-3">
                    <label className="col-md-1 col-form-label" htmlFor="price" defaultValue={selectedPostData?.price}>
                      Price
                    </label>
                    <div className="col-md-auto">
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Amount in INR"
                        id="price"
                        name="price"
                        defaultValue={selectedPostData?.price}
                      />
                    </div>
                  </div>
                  <div className="custom-radio no-bdr">
                    <input
                      id="deletePost"
                      type="radio"
                      name="videoAvailable" checked={selectedPostData?.DELETE_POST ? true : false} onChange={(e) => onChangeAvalableOptions(e, 'DELETE_POST')}
                    />
                    <label htmlFor="deletePost">
                      Delete the post after {postAfterDays} days.
                    </label>
                  </div>
                  <div className="custom-radio no-bdr">
                    <input
                      id="deletePost1"
                      type="radio"
                      name="videoAvailable" checked={selectedPostData?.FREE_POST ? true : false} onChange={(e) => onChangeAvalableOptions(e, 'FREE_POST')}
                    />
                    <label htmlFor="deletePost1">
                      Make the post free after {postAfterDays} days.
                    </label>
                  </div>
                  <div className="custom-radio no-bdr">
                    <input
                      id="deletePost2"
                      type="radio"
                      name="videoAvailable" checked={selectedPostData?.AVAILABLE_TO_SUBSCRIBER ? true : false} onChange={(e) => onChangeAvalableOptions(e, 'AVAILABLE_TO_SUBSCRIBER')}
                    />
                    <label htmlFor="deletePost2">
                      The post shall be available only to subscribers after {postAfterDays} days.
                    </label>
                  </div>
                </div>
              </div>
              <div className="col-md-3 post-form-detail">
                <h6 className="d-inline-flex">
                  Viewership
                  <i
                    className="bi bi-info-circle ms-1"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="Tooltip on top"
                  ></i>
                </h6>
                <div className="custom-checkbox no-bdr">
                  <input id="publicFree" type="checkbox" />
                  <label htmlFor="publicFree" onClick={handleChecked}>
                    {" "}
                    <span className="d-inline-flex flex-column">
                      Public - Free
                      <div className="form-text fs-xsml mt-0" id="publicFree">
                        Anyone can view
                      </div>
                    </span>
                  </label>
                </div>
                <div className="custom-checkbox no-bdr">
                  <span className="d-inline-flex flex-column">
                    Club Packages
                    <div className="form-text fs-xsml mt-0" id="publicFree">
                      Only Subscribers can view
                    </div>
                  </span>
                </div>
                {subscriptionPackages?.map((element, index) => {
                  return <div key={element._id} className="plans-checkbox">
                    <div className="custom-checkbox no-bdr">
                      <input id={element._id} type="checkbox" name={element._id}
                        onChange={() => onChangeSubscriptionPakages(element._id, index)} checked={element?.isExist} />
                      <label htmlFor={element._id}>
                        <div className="bundle-info">
                          <div className="bundle-name">{element.name}</div>
                          <a className="bundle-detail" href="">
                            Details
                          </a>
                        </div>
                        <div className="bundle-desc fs-xsml mt-0">
                          <div className="bundle-info-price">
                            {element.price_monthly} INR / Month |{" "}
                          </div>
                          <div className="bundle-info-fans">{element.total_fans} Fans</div>
                        </div>
                      </label>
                    </div>
                  </div>
                })}
                {/* <div className="plans-checkbox">
                  <div className="custom-checkbox no-bdr">
                    <input id="premiumPlan" type="checkbox" />
                    <label htmlFor="premiumPlan">
                      <div className="bundle-info">
                        <div className="bundle-name">Premium</div>
                        <a className="bundle-detail" href="">
                          Details
                        </a>
                      </div>
                      <div className="bundle-desc fs-xsml mt-0">
                        <div className="bundle-info-price">
                          399 INR / Month |{" "}
                        </div>
                        <div className="bundle-info-fans">15 Fans</div>
                      </div>
                    </label>
                  </div>
                </div>
                <div className="plans-checkbox">
                  <div className="custom-checkbox no-bdr">
                    <input id="goldPlan" type="checkbox" />
                    <label htmlFor="goldPlan">
                      <div className="bundle-info">
                        <div className="bundle-name">Gold</div>
                        <a className="bundle-detail" href="">
                          Details
                        </a>
                      </div>
                      <div className="bundle-desc fs-xsml mt-0">
                        <div className="bundle-info-price">
                          499 INR / Month |{" "}
                        </div>
                        <div className="bundle-info-fans">20 Fans </div>
                      </div>
                    </label>
                  </div>
                </div> */}
              </div>
            </div>
          </form>
        </FormBuilder>
        <div className="col-md-12">
          <div className="d-flex flex-wrap justify-content-end mt-5">
            <NavLink className="btn btn-outline-orange me-2" to="  ">
              Discard
            </NavLink>
            <button className="btn btn-outline-black" onClick={onSaveImage}>Save</button>
          </div>
        </div>
      </div>
    </div>
  );
};
