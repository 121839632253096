import React, {useState,useEffect} from "react";
import { UserService } from "../../../app/service/user.service";
const AdminUser = () => {
    const [userData, setUserData] = useState<any[] | []>([]);

    useEffect(() => {
        getUsers()
        }, []);
    const getUsers=()=>{
        UserService.getUsersData().then((res) => {
            // console.log("res getCreator data", res);
        console.log(res);
        setUserData(res);
    
            // setIsShown(true);
          });
    
    }
  return (
    <div >
        <table className="table" >
    <thead>
      <tr>
      <th scope="col">S.No.</th>
        <th scope="col">Name</th>
        <th scope="col">Email Id</th>
        <th scope="col">Mobile No.</th>
        <th scope="col">Created At</th>
       
      </tr>
    </thead>
    <tbody>
        {userData.map((data:any, index:any) => {
            return (
                <tr>
                <td>{index +1}</td>
                <td>{data.name}</td>
                <td>{data.email}</td>
                <td>{data.phone}</td>
                <td>{data.createdAt}</td>
                </tr>
            )
        })}
     
      
       
      
    </tbody>
  </table>
  </div>
  )
}

export default AdminUser