import React, {useState,useEffect} from "react";
import { Link } from "react-router-dom";
// import { useDispatch, useSelector } from "react-redux";
import { UserService } from "../app/service/user.service";
// import { getInfluencersData } from "../redux/actions";

interface Props {
  input: any;
  
}

const CreatorSearchList: React.FC<Props> = (props) => {
    // const [option, setOption] = React.useState<any>(props.data);
    const [creatorData, setcreatorData] = useState<any[] | []>([]);
    // console.log(props.data)
    // const {data} = props.data
//   const dispatch = useDispatch();

 
//   const getState: any = useSelector<any>((state) => {
//     if (state.getInfo.influencer) {
//       return state.getInfo.influencer;
//     }
//   });

useEffect(() => {
  getCreator()
  }, []);
  
  const getCreator=()=>{
    UserService.getInfluencersData().then((res) => {
        // console.log("res getCreator data", res);
    console.log(res);
        setcreatorData(res);

        // setIsShown(true);
      });

}
let error = "User not found"

  const filteredData = creatorData.filter(function(el: any) {
    //if no input the return the original
    if (props.input === "") {
      return "";
    }
   

    //return the item which contains the user input
    else {
      return el.name.toLowerCase().includes(props.input);
    }
  });

  console.log(filteredData);
  const refresh = () => {
    setTimeout(() => {
      window.location.reload();
    }, 10);
  };
  

  return (
    <>
      <ul style={{ width: "100%", padding: 0 }}>
        {filteredData.map((item:any)=>{
          console.log(item)
            return(

      
       
              <li className="creator_searchbar_list">
                <Link
                  onClick={refresh}
                  className="position-relative link-underline"
                  to={`/livestreams/more-livestream/${item._id}/ls`}
                >
                   <img
                  style={{ width: "50px", height: "50px", borderRadius: "50%", marginRight:'10px' }}
                  src={
                    item.image
                      ? item.image
                      : "https://cdn.icon-icons.com/icons2/1378/PNG/512/avatardefault_92824.png"
                  }
                  alt="user-img"
                />
                
                  @{item.name}
                

               
                </Link>
              </li>
               ) })}
          
      </ul>
    </>
  );
};

export default CreatorSearchList;
