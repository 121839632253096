import React, { useRef, useState, useEffect } from "react";
import { STREAMING_HOST, WEB_STREAM_PORT } from "../config/constant";
import { S3Helper } from "../app/utils/s3-helper";
import { LivestreamService } from "../app/service/livestream";
import { toast } from "react-toastify";
import OvenLiveKit from "ovenlivekit";
import { Modal } from "react-bootstrap";
import { CloseButton } from "react-toastify/dist/components";

interface Props {
  streamId: string;
  club_id: string;
  onEndStreming: () => void;
}
export const LivestreamUploader: React.FC<Props> = (props: Props) => {
  const videoRef = useRef(null);
  const videoData: Blob[] = [];
  // let mediaRecorder: MediaRecorder;
  const [mute, setMute] = useState<boolean>(false);
  const [videoOff, setVideoOff] = useState<boolean>(false);
  const [isCommentsShow, setIsCommentsShow] = useState<boolean>(true);
  const [show, setShow] = useState(false);
  const [ovenLivekit, setOvenLivekit] = useState<any>(OvenLiveKit.create());

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  let activeStream: any;
  
  const collectionName = sessionStorage.getItem("collectionName") || "";
  let videoTrack: any;
  // Get media stream from user device
  useEffect(() => {

    ovenLivekit.getUserMedia().then((stream: any) => {
      // console.log('preparing stream ', stream);

      activeStream = stream;
      // mediaRecorder = new MediaRecorder(stream);
      // mediaRecorder.start(250);
      // mediaRecorder.ondataavailable = onUpdateMedia;
      // mediaRecorder.onstop = onStopRecord;
      let video: any = videoRef.current;
      if (video) {
        video.srcObject = stream;
        video.play();
      }
      ovenLivekit.startStreaming(
        `wss://${STREAMING_HOST}:${WEB_STREAM_PORT}/app/${props?.streamId}?direction=send`
      );
    });

  }, []);
  const onUpdateMedia = (event: BlobEvent) => {
    videoData.push(event.data);
  };
  const onStopLivestream = () => {
    if (ovenLivekit) {
      ovenLivekit.remove();
    }
    const data = {
      club_id: props.club_id,
      type: 1,
    };
    props.onEndStreming();
  };

  const onStopRecord = (event: any) => {
    const data = {
      club_id: props.club_id,
      type: 1,
      livestream_id: props?.streamId,
    };
    LivestreamService.getLiveStreamFolderInfo(data).then((res) => {
      if (res) {
        const file = new File(videoData, `${props?.streamId}.mp4`, {
          type: ".mp4",
        });
        const url = S3Helper.uploadFile(file, (progress: any) => { }, {
          bucketName: res.bucketName,
          folderPath: res.folderPath,
        });
      } else {
        toast.error(res?.message);
      }
    });
  };

  // const onMute = () => {
  //     setMute(!mute);
  // }
  // const onVideoOff = () => {
  //     setVideoOff(!videoOff);
  // }

  const onMute = () => {
    
    activeStream.getTracks().forEach((track: any) => {
      console.log(track)
      if (track.kind === "audio") {
        track.enabled = !track.enabled;
        setMute(!mute);
      }
    });
  };
  const onVideoOff = async () => {
    setVideoOff(!videoOff);
    if (videoOff) {
      activeStream = await ovenLivekit.getUserMedia();
      let video: any = videoRef.current;
      if (video) {
        video.srcObject = activeStream;
        video.play();
      }
      ovenLivekit.stream = activeStream;
    } else {
      ovenLivekit.stream.getTracks().forEach(async (track: any) => {
        if (track.kind === "video") {
          if (track.readyState === 'live') {
            track.stop();
          }
        }
      });
    }
  };

  const onHideComments = (e: any) => {
    setIsCommentsShow(e.target.checked);
    console.log(e.target.checked);
    const data = {
      isComments: e.target.checked ? true : false,
      stream_id: props?.streamId,
      collectionName: collectionName,
    };
    LivestreamService.updateLsShowOrHide(data).then((res) => { });
  };
  useEffect((): any => {
    const data = {
      isComments: true,
      stream_id: props?.streamId,
      collectionName: collectionName,
    };
    LivestreamService.updateLsShowOrHide(data).then((res) => { });
  }, []);
  return (
    <div
      className={`start-0 h-100 livestream-video-sec`}
      style={{
        zIndex: 1111,
        background: "white",
      }}
    >
      <div className="row">
        <div
          className="col-md-12 text-center livestream-video-inner"
          style={{ background: "white", overflow: "hidden" }}
        >
          <div className="videoWrapper ratio ratio-16x9">
            <video
              src=""
              muted={true}
              ref={videoRef}
              id="broadcaster-video-player"
              style={{
                width: "100%",
                transform: "scaleX(-1)",
                borderRadius: "8px",
              }}
            ></video>
          </div>
        </div>
        <div
          className="comment-action"
          style={{ background: "white", display: "flex", flexWrap: "wrap" }}
        >
          <div className="comment-action-left">
            <button
              className="btn btn-black mt-2 mb-3 me-2"
              onClick={handleShow} //{onStopLivestream}
            >
              End Livestream
            </button>
            <Modal
              dialogClassName="sign-up-modal app-store"
              show={show}
              onHide={handleClose}
            >
              <Modal.Header closeButton>
                <Modal.Title></Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="auth-container top-space-login pl-3 pr-3 pt-5">
                  <div className="auth-info text-center">
                    <h4>Are you sure you want to end livestream ?</h4>
                    <div className="btn-app-store mb-4">
                      <button
                        className="btn btn-outline-black d-inline-flex col-auto me-2 size"
                        onClick={handleClose}
                      >
                        No
                      </button>
                      <button
                        className="btn btn-black d-inline-flex col-auto size"
                        onClick={onStopLivestream}
                      >
                        Yes
                      </button>
                    </div>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer></Modal.Footer>
            </Modal>
            {mute ? (
              <button
                className="btn btn-outline-black mt-2 mb-3"
                onClick={onMute}
              >
                <i className="bi bi-mic-mute me-0 text"></i>
              </button>
            ) : (
              <button
                className="btn btn-outline-black mt-2 mb-3"
                onClick={onMute}
              >
                <i className="bi bi-mic me-0 text"></i>
              </button>
            )}

            {videoOff ? (
              <button
                className="btn btn-outline-black mt-2 mb-3 ms-2"
                onClick={onVideoOff}
              >
                <i className="bi bi-camera-video-off me-0 text"></i>
              </button>
            ) : (
              <button
                className="btn btn-outline-black mt-2 mb-3 ms-2"
                onClick={onVideoOff}
              >
                <i className="bi bi-camera-video me-0 text"></i>
              </button>
            )}
          </div>
          <div className="comment-action-right">
            <label className="mt-3 ps-0">Comments</label>
            <div className="mt-2 mb-3 ms-2">
              <label
                className={isCommentsShow ? "switch switch-active" : "switch"}
              >
                <input
                  type="checkbox"
                  onChange={(e) => onHideComments(e)}
                  checked={isCommentsShow}
                />
                <span className="slider round"></span>
                <span className="comment-label">
                  {isCommentsShow ? "Hide" : "Show"}
                </span>
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
