import moment from "moment";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { NavLink, useHistory, useParams, Link } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { LivestreamService } from "../../../app/service/livestream";
import { NftService } from "../../../app/service/nft.service";
import { UserService } from "../../../app/service/user.service";
import Banner from "../../../assets/img/banner.jpg";
import featuredImg from "../../../assets/img/featured-img.png";
import featuredImg1 from "../../../assets/img/featured-img1.png";
import featuredImg2 from "../../../assets/img/featured-img2.png";
import featuredImg3 from "../../../assets/img/featured-img3.png";
import CardTicketImage from "../../../assets/img/livestream-detail-img.png";
import { STREAMING_HOST } from "../../../config/constant";
import CardPastEvents from "../../../components/card-past-events";
import CardLivestream from "../../../components/card-livestream";
import ProfileIcon from "../../../assets/img/profile.svg";
type Props = {};

const MoreLivestream = (props: Props) => {
  let { id, type } = useParams<{ id: string, type: string }>();
  const [loginUserData, setLoginUserData] = useState<any[] | []>([]);
  const [upcommingstream, setUpcommingstream] = useState<any[] | []>([]);
  const [livestream, setLivestream] = useState<any[] | []>([]);
  const [pasLlivestream, setPasLlivestream] = useState<any[] | []>([]);
  const [showUserLivestreamPopup, setShowUserLivestreamPopup] = useState(false);
  const handleCloseUserLivestreamPopup = () =>
    setShowUserLivestreamPopup(false);
  const handleShowUserLivestreamPopup = () => setShowUserLivestreamPopup(true);
  const [selectedLivestream, setSelectedLivestream] = useState<any>();
  const [selectedLivestreanType, setSelectedLivestreanType] = useState("web");
  const history = useHistory();
  const [canShowUpload, setCanShowUpload] = useState(false);
  const [goLiveLiveStream, setGoLiveLiveStream] = useState<any>();
  const [creatorNfts, setCreatorNfts] = useState<any[] | []>([]);
  const [showIsMobileGoLive, setShowIsMobileGoLive] = useState(false);
  const handleCloseShowIsMobileGoLive = () => setShowIsMobileGoLive(false);
  const handleShowShowIsMobileGoLive = () => setShowIsMobileGoLive(true);

  useEffect(() => {
    localStorage.setItem('thiredPartyData', '');
    if (id) {
      UserService.getUser(id).then((res) => {
        if (res) {
          setLoginUserData(res);
          if (res[0]?.club_influencer) {
            LivestreamService.getUpcomingLiveStreamByCreatorID(id).then(
              (res) => {
                if (res) {
                  const data = res.sort((a: any, b: any) =>
                    a.schedule_From < b.schedule_From
                      ? 1
                      : b.schedule_From < a.schedule_From
                        ? -1
                        : 0
                  );
                  setUpcommingstream([...res]);
                }
              }
            );
            getNftByCreator();
          }
        }
      });
    }

    LivestreamService.getScheduledLivestream("false").then((res) => {
      if (res) {
        setLivestream([...res]);
      }
    });

    // LivestreamService.getCompletedLivestream("false").then((res) => {
    //   if (res) {
    //     setPasLlivestream([...res]);
    //   }
    // });
    if (id) {
      UserService.getUser(id).then((res) => {
        if (res) {
          setLoginUserData(res);
          if (res[0]?.club_influencer) {
            LivestreamService.getCompletedLiveStreamByCreatorID(id).then(
              (res) => {
                if (res) {
                  const data = res.sort((a: any, b: any) =>
                    a.schedule_From < b.schedule_From
                      ? 1
                      : b.schedule_From < a.schedule_From
                        ? -1
                        : 0
                  );
                  setPasLlivestream([...res]);
                }
              }
            );
            getNftByCreator();
          }
        }
      });
    }
    // if (userId) {
    //   LivestreamService.getScheduledLiveStreamsByUserID(userId).then(
    //     (res) => {
    //       if (res) {
    //         setPurchasedLivestream([...res]);
    //       }
    //     })
    // }
  }, []);

  const onGolive = (data: any) => {
    if (isMobile) {
      handleShowShowIsMobileGoLive();
    } else {
      setSelectedLivestream(data);
      handleShowUserLivestreamPopup();
    }
  };
  const onChangeLivestream = (event: any) => {
    setSelectedLivestreanType(event);
  };

  const getNftByCreator = () => {
    NftService.getNftByCreator(id).then((res) => {
      console.log(res, 'NFT By Creator');
      setCreatorNfts(res);
    });
  };
  console.log("NFT", creatorNfts)
  const startStreaming = (data: any) => {
    localStorage.setItem('thiredPartyData', '');
    handleCloseUserLivestreamPopup();
    setGoLiveLiveStream(data);
    setCanShowUpload(true);
    LivestreamService.goLiveStream(selectedLivestream).then((res) => {
      if (res) {
        if (isMobile) {
          history.push(
            `/golivestream/${selectedLivestream?._id}/${selectedLivestream?.club_id}`
          );
        } else {
          window.open(
            `${window.location.origin}/#/golivestream/${selectedLivestream?._id}/${selectedLivestream?.club_id}`,
            "_blank"
          );
        }
      }
    });
  };

  const onThairedParty = () => {
    const data = { server: `rtmp://${STREAMING_HOST}:1935/live`, stream_key: `${selectedLivestream?.collectionName}~${selectedLivestream?._id}` }
    localStorage.setItem('thiredPartyData', JSON.stringify(data));
    window.open(
      `${window.location.origin}/#/golivestream/${selectedLivestream?._id}/${selectedLivestream?.club_id}`,
      "_blank"
    );
  }

  return (
    <>
      <section className="banner-cover category-detail-page profile">
        <img
          src={
            loginUserData[0]?.cover_photo
              ? loginUserData[0]?.cover_photo
              : Banner
          }
          alt=""
        />
        <div className="banner-cover-profile">
          <div className="banner-cover-profile__left">
            <div className="banner-cover-profile__left-img">
              {loginUserData[0]?.image ? (
                <img
                  src={loginUserData[0]?.image && encodeURI(loginUserData[0]?.image)}
                  alt={loginUserData[0]?.name}
                  width="150"
                  height="150"
                />
              ) : (
                <img
                  src={ProfileIcon}
                  alt=""
                  width="100"
                  height="100"
                  style={{ borderRadius: 0 }}
                />
              )}
            </div>
            <div className="banner-cover-profile__info">
              <p className="banner-cover-profile__info-username">
                @{loginUserData[0]?.name}
              </p>
              <h2 className="banner-cover-profile__info-user-title">
                {loginUserData[0]?.name}
              </h2>
            </div>
          </div>
          <div className="banner-cover-profile__right">
            <ul
              className="nav nav-pills tab-rounded"
              id="pills-tab"
              role="tablist"
            >
              {/* <li className="nav-item" role="presentation">
                <button
                  className={`${type == 'ls' ? "active" : ""}`}
                  id="pills-livestream-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-livestream"
                  type="button"
                  role="tab"
                  aria-controls="pills-livestream"
                  aria-selected="true"
                >
                  Livestream ({upcommingstream?.length})
                </button>
              </li> */}
              {/* <li className="nav-item" role="presentation">
                <button
                  className={`${type == 'nft' ? "active" : ""}`}
                  id="pills-nft-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-nft"
                  type="button"
                  role="tab"
                  aria-controls="pills-nft"
                  aria-selected="false"
                >
                  NFT ({creatorNfts?.length})
                </button>
              </li> */}
            </ul>
          </div>
        </div>
      </section>

      <section className="tab-content-sec more-livestream-sec">
        <div className="container">
          <div className="tab-content" id="pills-tabContent">
            <div
              className={`tab-pane fade nft-listing ${type == 'ls' ? ' active show' : ''}`}
              id="pills-livestream"
              role="tabpanel"
              aria-labelledby="pills-livestream-tab"
            >
              <div className="container-fluid">
                <div className="d-inline-flex flex-column w-100 align-items-center mb-2">
                  <h2 className="mb-3">Streaming Soon</h2>
                </div>
                <div className="row mb-5">
                  {upcommingstream.map((data: any, index: number) => {
                    return (
                      <div className="col-lg-4 col-sm-6 mb-4">
                        <CardLivestream data={data} />
                        {/* <div className="card-livestream d-none d-md-block">
                          <span
                            className="card-livestream-bgimg"
                            style={{
                              backgroundImage: data?.thumbnail
                                ? `url("${data?.thumbnail}")`
                                : "linear-gradient(to right, #DDEFBB, #FFEEEE)",
                              backgroundPosition: "center center",
                              backgroundSize: "cover",
                            }}
                          ></span>
                          <img
                            className="card-livestream-img"
                            src={data?.thumbnail}
                            alt={data?.title}
                          />
                          <div className="card-livestream-content">
                            <h3 className="card-livestream-title">
                              <a
                                href="javascript:void(0)"
                                onClick={() => {}}
                                title="title"
                              >
                                {data?.title}
                              </a>
                            </h3>
                            <span className="card-livestream-date">
                              {moment(data?.schedule_From).utc().format("lll")}{" "}
                              IST
                            </span>
                          </div>
                          <Link
                            className="btn btn-outline-black mx-auto"
                            to={`/livestreams/livestream-detail/${data?._id}`}
                          >
                            View Details
                          </Link>
                        </div> */}
                        {/* Mobile View */}
                        {/* <Link
                          to={`/livestreams/livestream-detail/${data?._id}`}
                          className="card-livestream d-block d-md-none"
                        >
                          <span
                            className="card-livestream-bgimg"
                            style={{
                              backgroundImage: data?.thumbnail
                                ? `url("${data?.thumbnail}")`
                                : "linear-gradient(to right, #DDEFBB, #FFEEEE)",
                              backgroundPosition: "center center",
                              backgroundSize: "cover",
                            }}
                          ></span>
                          <img
                            className="card-livestream-img"
                            src={data?.thumbnail}
                            alt={data?.title}
                          />
                          <div className="card-livestream-content">
                            <h3 className="card-livestream-title">
                              <a
                                href="javascript:void(0)"
                                onClick={() => {}}
                                title="title"
                              >
                                {data?.title}
                              </a>
                            </h3>
                            <span className="card-livestream-date">
                              {moment(data?.schedule_From).utc().format("lll")}{" "}
                              IST
                            </span>
                          </div>
                        </Link> */}
                      </div>
                    );
                  })}
                  {upcommingstream.length < 1 && (
                    <span className="py-4 text-center">
                      No upcoming livestreams from this creator.
                    </span>
                  )}
                </div>
                <div className="d-inline-flex flex-column w-100 align-items-center mb-2">
                  <h2>Past events</h2>
                </div>
                <div className="row livestream-row">
                  {pasLlivestream.map((data: any, index: number) => {
                    return (
                      <div className="col-lg-4 col-sm-6 mb-4">
                        <CardPastEvents data={data}  />
                        {/* <div className="card-livestream square d-none d-md-block">
                          <span
                            className="card-livestream-bgimg"
                            style={{
                              backgroundImage: data?.thumbnail
                                ? `url("${data?.thumbnail}")`
                                : "linear-gradient(to right, #DDEFBB, #FFEEEE)",
                              backgroundPosition: "center center",
                              backgroundSize: "cover",
                            }}
                          ></span>
                          <img
                            className="card-livestream-img"
                            src={data?.thumbnail}
                            alt={data?.title}
                          />
                          <span className="card-livestream-likes-count">
                            <i className="bi bi-heart"></i>18
                          </span>
                          <div className="card-livestream-content">
                            <h3 className="card-livestream-title">
                              <a
                                href="javascript:void(0)"
                                onClick={() => {}}
                                title="title"
                              >
                                {data?.title}
                              </a>
                            </h3>
                            <span className="card-livestream-date">
                              {moment(data?.schedule_From).utc().format("ll")}
                            </span>
                          </div>
                          <NavLink
                            to={`/livestreams/livestream-detail/${data?._id}`}
                            className="btn btn-outline-black mx-auto"
                          >
                            View Details
                          </NavLink>
                        </div> */}
                        {/* Mobile View */}
                        {/* <NavLink
                          to={`/livestreams/livestream-detail/${data?._id}`}
                          className="d-block d-md-none card-livestream square"
                          title={data?.title}
                        >
                          <span
                            className="card-livestream-bgimg"
                            style={{
                              backgroundImage: data?.thumbnail
                                ? `url("${data?.thumbnail}")`
                                : "linear-gradient(to right, #DDEFBB, #FFEEEE)",
                              backgroundPosition: "center center",
                              backgroundSize: "cover",
                            }}
                          ></span>
                          <img
                            className="card-livestream-img"
                            src={data?.thumbnail}
                            alt={data?.title}
                          />
                          <span className="card-livestream-likes-count">
                            <i className="bi bi-heart"></i>18
                          </span>
                          <div className="card-livestream-content">
                            <h3 className="card-livestream-title">
                              <a
                                href="javascript:void(0)"
                                onClick={() => {}}
                                title={data?.title}
                              >
                                {data?.title}
                              </a>
                            </h3>
                            <span className="card-livestream-date">
                              {moment(data?.schedule_From).utc().format("ll")}
                            </span>
                          </div>
                        </NavLink> */}
                      </div>
                    );
                  })}
                  {pasLlivestream.length < 1 && (
                    <span className="py-4 text-center">
                      No Upcoming Livestreams Found
                    </span>
                  )}
                </div>
              </div>
            </div>
            <div
              className={`tab-pane fade ${type == 'nft' ? ' active show ' : ''}`}
              id="pills-nft"
              role="tabpanel"
              aria-labelledby="pills-nft-tab"
            >
              <div className="row">
                {creatorNfts.map((data: any, index: number) => {
                  return (
                    <div className="col-sm-6 col-lg-4 mb-4">
                      <div className="card-nft mw-100">
                        <span className="card-nft-available-count">
                          NFT Available: {data?.count}
                        </span>
                        <div className="card-nft-img">
                          <Link to={`/nft/nft-single/${data?.entries[0]?._id}/${data?.entries[0]?.collectionName}/${data?.count}`} className="ratio ratio-1x1">
                            {/* <a href="" className="ratio ratio-1x1"> */}
                            <img
                              src={data?.entries[0]?.asset_url ? data?.entries[0]?.asset_url : Banner}
                              alt="Profile Image"
                              className="mw-100"
                            />
                          </Link>
                          {/* </a> */}
                        </div>
                        <div className="card-nft-content">
                          <a href="" className="card-nft-title">
                            {data?.entries[0]?.title}
                          </a>
                          <span className="card-nft-creator">
                            {data?.entries[0]?.user_info[0]?.name}
                          </span>
                          <div className="card-nft-price">
                            <span className="me-auto">Price: {data?.entries[0]?.price}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                  // return (
                  //   <div key={index} className="col-md-3">
                  //     <div className="featured-card mw-100">
                  //       <div className="featured-card__left">
                  //         <a
                  //           href=""
                  //           className="featured-card__left-img ratio ratio-1x1"
                  //         >
                  //           <img
                  //             src={
                  //               data?.asset_url ? data?.asset_url : featuredImg
                  //             }
                  //             alt="Profile Image"
                  //             className="mw-100"
                  //           />
                  //         </a>
                  //       </div>
                  //       <div className="featured-card__right">
                  //         <a href="" className="featured-card__title">
                  //           {data?.title}
                  //         </a>
                  //         <div className="featured-card__handle">
                  //           {data?.user_info[0]?.name}
                  //         </div>
                  //         <div className="nft-price-info">
                  //           <span className="me-auto">
                  //             Price: {data?.price}
                  //           </span>
                  //         </div>
                  //       </div>
                  //     </div>
                  //   </div>
                  // );
                })}
              </div>
            </div>
          </div>
        </div>
        <Modal
          show={showUserLivestreamPopup}
          onHide={handleCloseUserLivestreamPopup}
          centered
          dialogClassName="sign-up-modal login-modal"
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <div className="auth-container">
              <div className="auth-sec auth-right-sec">
                <div className="auth-info">
                  {/* <h2>Alert</h2> */}
                </div>
                <div className="row">
                  <h4>Start livestream using</h4>
                  <div>
                    <input
                      type="radio"
                      name="isLivestreamType"
                      id="isLivestreamType"
                      value={selectedLivestreanType}
                      checked={selectedLivestreanType === "web"}
                      onChange={() => onChangeLivestream("web")}
                    />{" "}
                    <span className="me-2"> Web livestream</span>
                    <div>
                    <input
                      type="radio"
                      name="isLivestreamType"
                      id="isLivestreamType"
                      value={selectedLivestreanType}
                      checked={selectedLivestreanType === "3rdparty"}
                      onChange={() => onChangeLivestream("3rdparty")}
                    />
                    <span> 3rd party software</span>
                    </div>
                    {/* {selectedLivestreanType === "3rdparty" && (
                      <>
                        <div className="mt-2">
                          <label>Server</label>
                          <i
                            className="bi bi-clipboard ms-2 pointer"
                            title="Copy to clipboard"
                            onClick={() => {
                              navigator.clipboard.writeText(
                                `rtmp://${STREAMING_HOST}:1935/live`
                              );
                            }}
                          ></i>
                          <input
                            type="text"
                            className="form-control mt-2"
                            readOnly
                            value={`rtmp://${STREAMING_HOST}:1935/live`}
                          />
                        </div>
                        <div className="mt-2">
                          <label>Stream Key</label>{" "}
                          <i
                            className="bi bi-clipboard ms-2 pointer"
                            title="Copy to clipboard"
                            onClick={() => {
                              navigator.clipboard.writeText(
                                `${selectedLivestream?.collectionName}~${selectedLivestream?._id}`
                              );
                            }}
                          ></i>
                          <input
                            type="text"
                            className="form-control mt-2"
                            readOnly
                            value={`${selectedLivestream?.collectionName}~${selectedLivestream?._id}`}
                          />
                        </div>
                      </>
                    )} */}
                    <div className="mt-3">
                      {selectedLivestreanType === "web" && (
                        <button
                          style={{ padding: "0 12px", fontSize: "20px" }}
                          className="btn btn-outline-black"
                          onClick={startStreaming}
                        // to={`/golivestream/${selectedLivestream?._id}/${selectedLivestream?.club_id}`}
                        >
                          Go Live
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>

        <Modal
          show={showIsMobileGoLive}
          onHide={handleCloseShowIsMobileGoLive}
          centered
          dialogClassName="sign-up-modal login-modal"
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <div className="auth-container">
              <div className="auth-sec auth-right-sec">
                <div className="auth-info">
                  <h2>Alert</h2>
                </div>
                <div className="row"></div>
                Please download mobile app and start stream.
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer></Modal.Footer>
        </Modal>
      </section>
    </>
  );
};

export default MoreLivestream;
