import React, { Fragment } from "react";
import { NavLink } from "react-router-dom";
import PostRoutes from "../../../routes/post";

export const PostPage = () => {
  return (
    <div className="main-content create-content-sec">
      <div className="container container-custom">
        <h3 className="pb-2">Posts</h3>
        <div className="row g-4 row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-5">
          <div className="col">
            <NavLink
              className="card card-boxed d-flex justify-content-center align-items-center"
              to="/dashboard/clubpost/0/video"
            >
              <div className="card-boxed-img">
                {/* <i className="bi bi-camera-video"></i> */}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="67"
                  height="38"
                  viewBox="0 0 67 38"
                >
                  <defs>
                    <clipPath id="clip-Video">
                      <rect width="67" height="38" />
                    </clipPath>
                  </defs>
                  <g id="Video" clip-path="url(#clip-Video)">
                    <g
                      id="Group_113"
                      data-name="Group 113"
                      transform="translate(-311.644 -209.385)"
                    >
                      <path
                        id="Path_101"
                        data-name="Path 101"
                        d="M525.149,471.122a3.946,3.946,0,0,1-1.6-.342,1.955,1.955,0,0,1-.334-.19l-10.2-7.182h0a3.947,3.947,0,0,1-1.675-3.227v-9.794a3.947,3.947,0,0,1,1.674-3.226l10.2-7.182a1.955,1.955,0,0,1,.334-.19A3.947,3.947,0,0,1,529.1,443.4v23.778a3.947,3.947,0,0,1-3.947,3.947Z"
                        fill="#18CC73"
                      />
                      <path
                        id="Path_102"
                        data-name="Path 102"
                        d="M340.87,466.74H315.588c-5.158,0-9.338-3.757-9.343-8.388V439.6c.005-4.631,4.185-8.383,9.343-8.388h25.348c5.121.005,9.271,3.731,9.277,8.329v18.809C350.209,462.982,346.029,466.735,340.87,466.74Z"
                        transform="translate(6.492 -220.829)"
                        fill="#18CC73"
                      />
                    </g>
                  </g>
                </svg>
              </div>

              <h3>Video</h3>
            </NavLink>
          </div>
          <div className="col">
            <NavLink
              className="card card-boxed d-flex justify-content-center align-items-center"
              to="/dashboard/clubpost/0/audio"
            >
              {/* <i className="bi bi-mic"></i> */}
              <div className="card-boxed-img">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  width="88"
                  height="63"
                  viewBox="0 0 88 63"
                >
                  <defs>
                    <clipPath id="clip-Audio">
                      <rect width="88" height="63" />
                    </clipPath>
                  </defs>
                  <g id="Audio" clip-path="url(#clip-Audio)">
                    <g
                      id="Group_166"
                      data-name="Group 166"
                      transform="translate(1 1.069)"
                    >
                      <rect
                        id="Rectangle_113"
                        data-name="Rectangle 113"
                        width="85.849"
                        height="0.664"
                        transform="translate(0 58.914)"
                        fill="#cbcbcb"
                      />
                      <rect
                        id="Rectangle_114"
                        data-name="Rectangle 114"
                        width="58.141"
                        height="0.664"
                        transform="translate(0 58.914)"
                        fill="#18CC73"
                      />
                      <ellipse
                        className="audio"
                        id="Ellipse_38"
                        data-name="Ellipse 38"
                        cx="2.326"
                        cy="2.326"
                        rx="2.326"
                        ry="2.326"
                        fill="#18CC73"
                        transform="translate(55.817 56.921)"
                      />
                      <g
                        id="Group_101"
                        data-name="Group 101"
                        transform="translate(30.566)"
                      >
                        <path
                          id="Path_112"
                          data-name="Path 112"
                          d="M579.434,301.253a5.821,5.821,0,0,1-5.814-5.814V282.814a5.814,5.814,0,1,1,11.628,0v12.625A5.82,5.82,0,0,1,579.434,301.253Z"
                          transform="translate(-567.141 -277)"
                          fill="#18CC73"
                        />
                        <path
                          id="Path_113"
                          data-name="Path 113"
                          d="M566.413,337.789A12.307,12.307,0,0,1,554.12,325.5a1,1,0,1,1,1.993,0,10.3,10.3,0,1,0,20.6,0,1,1,0,0,1,1.993,0A12.307,12.307,0,0,1,566.413,337.789Z"
                          transform="translate(-554.12 -308.719)"
                          fill="#18CC73"
                        />
                        <path
                          id="Path_114"
                          data-name="Path 114"
                          d="M589.117,372.457a1,1,0,0,1-1-1V360.5a1,1,0,1,1,1.993,0V371.46a1,1,0,0,1-1,1Z"
                          transform="translate(-576.824 -332.091)"
                          fill="#18CC73"
                        />
                        <path
                          id="Path_115"
                          data-name="Path 115"
                          d="M581.143,395.03h-15.79a1,1,0,0,1,0-1.993h15.79a1,1,0,0,1,0,1.993Z"
                          transform="translate(-560.955 -354.486)"
                          fill="#18CC73"
                        />
                      </g>
                    </g>
                  </g>
                </svg>
              </div>
              <h3>Audio</h3>
            </NavLink>
          </div>
          <div className="col">
            <NavLink
              className="card card-boxed d-flex justify-content-center align-items-center"
              to="/dashboard/clubpost/0/static"
            >
              {/* <i className="bi bi-image"></i> */}
              <div className="card-boxed-img">
                <svg className="static-image-svg"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  width="141"
                  height="70"
                  viewBox="0 0 141 70"
                >
                  <defs>
                    <clipPath id="clip-Static_post">
                      <rect width="141" height="70" />
                    </clipPath>
                  </defs>
                  <g
                    id="Static_post"
                    data-name="Static post"
                    clip-path="url(#clip-Static_post)"
                  >
                    <g
                      id="Group_123"
                      data-name="Group 123"
                      transform="translate(-752 -205)"
                    >
                      <g
                        id="Group_117"
                        data-name="Group 117"
                        transform="translate(9.781 -3)"
                      >
                        <g
                          id="Group_76"
                          data-name="Group 76"
                          transform="translate(743.219 190.403)"
                        >
                          <path
                            id="Path_77"
                            data-name="Path 77"
                            d="M406.277,636.346H299.4c-.134,0-.267,0-.4-.01l50.548-28.468a8.506,8.506,0,0,1,4.823-.435,4.363,4.363,0,0,1,1.338.435l33.924,19.1,1.625.914Z"
                            transform="translate(-299 -548.136)"
                            fill="#e6e6e6"
                          />
                          <path
                            id="Path_78"
                            data-name="Path 78"
                            d="M560.184,657.114H467.743l17.913-8.46,1.289-.609,23.343-11.025a12.309,12.309,0,0,1,7.011-.136q.181.064.336.136Z"
                            transform="translate(-419.95 -568.904)"
                            fill="#e6e6e6"
                          />
                        </g>
                      </g>
                      <g
                      className="first-tree"
                        id="Group_122"
                        data-name="Group 122"
                        transform="translate(0 0.081)"
                      >
                        <ellipse
                          id="Ellipse_45"
                          data-name="Ellipse 45"
                          cx="18.433"
                          cy="23.073"
                          rx="18.433"
                          ry="23.073"
                          transform="translate(781.125 205.919)"
                          fill="#18cc73"
                        />
                        <path
                          id="Path_212"
                          data-name="Path 212"
                          d="M500.633,522.543h0a.456.456,0,0,0-.616-.191l-1.846.972-.632-36.243h-.051l-.962,52.261h1.924l-.262-15,2.253-1.186A.456.456,0,0,0,500.633,522.543Z"
                          transform="translate(302.086 -262.729)"
                          fill="#18cc73"
                        />
                      </g>
                      <g className="second-tree"
                        id="Group_121"
                        data-name="Group 121"
                        transform="translate(0 1.081)"
                      >
                        <ellipse
                          id="Ellipse_46"
                          data-name="Ellipse 46"
                          cx="18.433"
                          cy="23.073"
                          rx="18.433"
                          ry="23.073"
                          transform="translate(833.715 204.919)"
                          fill="#f2f2f2"
                        />
                        <path
                          id="Path_213"
                          data-name="Path 213"
                          d="M504.981,323.365h.051l.911,52.261h-1.924Z"
                          transform="translate(347.219 -100.013)"
                          fill="#ccc"
                        />
                        <path
                          id="Path_214"
                          data-name="Path 214"
                          d="M728.794,633.449h0a.456.456,0,0,1-.191.616l-2.644,1.392-.425-.807,2.644-1.392A.456.456,0,0,1,728.794,633.449Z"
                          transform="translate(126.549 -375.498)"
                          fill="#ccc"
                        />
                      </g>
                    </g>
                  </g>
                </svg>
              </div>
              <h3>Static Post</h3>
            </NavLink>
          </div>
          <div className="col">
            <NavLink
              className="card card-boxed d-flex justify-content-center align-items-center"
              to="/dashboard/clubpost/0/text"
            >
              {/* <i className="bi bi-card-text"></i> */}
              <div className="card-boxed-img">
                <svg className="text-img-svg"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  width="56"
                  height="60"
                  viewBox="0 0 56 60"
                >
                  <defs>
                    <clipPath id="clip-Text_post">
                      <rect width="56" height="60" />
                    </clipPath>
                  </defs>
                  <g
                    id="Text_post"
                    data-name="Text post"
                    clip-path="url(#clip-Text_post)"
                  >
                    <g
                      id="Group_79"
                      data-name="Group 79"
                      transform="translate(1 0.95)"
                    >
                      <path
                        id="Path_154"
                        data-name="Path 154"
                        d="M347.7,355.855v49.967a1.1,1.1,0,0,1-1.1,1.1H294.244a1.1,1.1,0,0,1-1.1-1.1V349.976a1.1,1.1,0,0,1,1.1-1.1h45.742Z"
                        transform="translate(-293.142 -348.874)"
                        fill="#18CC73"
                      />
                      <path className="text-anim"
                        id="Path_40"
                        data-name="Path 40"
                        d="M340.513,415.072a.69.69,0,1,0,0,1.38H358.7a.69.69,0,0,0,.023-1.379h-18.21Z"
                        transform="translate(-331.248 -402.911)"
                        fill="#fff"
                      />
                      <path
                        id="Path_40-2"
                        data-name="Path 40"
                        d="M340.513,450.291a.69.69,0,1,0,0,1.38h36.007a.69.69,0,1,0,.023-1.379H340.513Z"
                        transform="translate(-331.248 -431.66)"
                        fill="#fff"
                      />
                      <path
                        id="Path_40-3"
                        data-name="Path 40"
                        d="M340.513,485.51a.69.69,0,1,0,0,1.38h36.007a.69.69,0,1,0,.023-1.379H340.513Z"
                        transform="translate(-331.248 -460.409)"
                        fill="#fff"
                      />
                      <path
                        id="Path_40-4"
                        data-name="Path 40"
                        d="M340.513,520.729a.69.69,0,1,0,0,1.38h36.007a.69.69,0,1,0,.023-1.379H340.513Z"
                        transform="translate(-331.248 -489.158)"
                        fill="#fff"
                      />
                      <path
                        id="Path_40-5"
                        data-name="Path 40"
                        d="M340.513,555.948a.69.69,0,0,0,0,1.38h36.007a.69.69,0,0,0,.023-1.38H340.513Z"
                        transform="translate(-331.248 -517.908)"
                        fill="#fff"
                      />
                      <path
                        id="Path_40-6"
                        data-name="Path 40"
                        d="M340.513,591.167a.69.69,0,0,0,0,1.38h36.007a.69.69,0,1,0,.023-1.379H340.513Z"
                        transform="translate(-331.248 -546.657)"
                        fill="#fff"
                      />
                      <path
                        id="Path_155"
                        data-name="Path 155"
                        d="M555.857,355.855h-6.981a.735.735,0,0,1-.735-.735v-6.246h0Z"
                        transform="translate(-501.298 -348.874)"
                        fill="#118148"
                      />
                      <path
                        id="Path_192"
                        data-name="Path 192"
                        d="M555.857,355.855h-6.981a.735.735,0,0,1-.735-.735v-6.246h0Z"
                        transform="translate(-501.298 -348.874)"
                        fill="#129151"
                        opacity="0.2"
                      />
                    </g>
                  </g>
                </svg>
              </div>
              <h3>Text</h3>
            </NavLink>
          </div>
          <div className="col">
            <NavLink
              className="card card-boxed d-flex justify-content-center align-items-center"
              to="/dashboard/clubpost/0/polls"
            >
              {/* <i className="bi bi-diagram-3"></i> */}
              <div className="card-boxed-img">
                <svg className="polls-img-svg"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  width="107"
                  height="52"
                  viewBox="0 0 107 52"
                >
                  <defs>
                    <clipPath id="clip-Polls">
                      <rect width="107" height="52" />
                    </clipPath>
                  </defs>
                  <g id="Polls" clip-path="url(#clip-Polls)">
                    <g
                      id="Group_111"
                      data-name="Group 111"
                      transform="translate(-1232 -203)"
                    >
                      <g
                        id="Group_110"
                        data-name="Group 110"
                        transform="translate(1251.281 206)"
                      >
                        <path
                          id="Path_186" className="first-line"
                          data-name="Path 186"
                          d="M411.31,273.911H329.672a2.68,2.68,0,0,0,0,5.289H411.31a2.68,2.68,0,0,0,0-5.289Z"
                          transform="translate(-327.271 -273.756)"
                          fill="#e4e4e4"
                        />
                        <path
                          id="Path_189"
                          data-name="Path 189"
                          d="M379.707,279.057H329.863a2.855,2.855,0,0,1-2.886-2.611,2.777,2.777,0,0,1,.75-2.1,2.811,2.811,0,0,1,2.044-.888h44.491a5.451,5.451,0,0,1,5.445,5.445Z"
                          transform="translate(-326.97 -273.457)"
                          fill="#18CC73"
                        />
                      </g>
                      <g
                        id="Group_109"
                        data-name="Group 109"
                        transform="translate(1251.281 226)"
                      >
                        <path
                          id="Path_187"
                          className="second-line"
                          data-name="Path 187"
                          d="M411.31,333.428H329.672a2.68,2.68,0,0,0,0,5.289H411.31a2.68,2.68,0,0,0,0-5.289Z"
                          transform="translate(-327.27 -333.273)"
                          fill="#e4e4e4"
                        />
                        <path
                          id="Path_190"
                          data-name="Path 190"
                          d="M390.232,338.574H329.3a2.556,2.556,0,0,1-2.324-2.611,3.2,3.2,0,0,1,.6-2.1,2.086,2.086,0,0,1,1.646-.888h56.622c2.42,0,4.382,2.439,4.384,5.445Z"
                          transform="translate(-326.97 -332.974)"
                          fill="#18CC73"
                        />
                      </g>
                      <g
                        id="Group_108"
                        data-name="Group 108"
                        transform="translate(1251.281 246)"
                      >
                        <path
                          id="Path_188"
                          className="third-line"
                          data-name="Path 188"
                          d="M411.31,392.945H329.672a2.68,2.68,0,0,0,0,5.289H411.31a2.68,2.68,0,0,0,0-5.289Z"
                          transform="translate(-327.271 -392.79)"
                          fill="#e4e4e4"
                        />
                        <path
                          id="Path_191"
                          data-name="Path 191"
                          d="M394.952,398.091H329.863a2.855,2.855,0,0,1-2.886-2.611,2.777,2.777,0,0,1,.75-2.1,2.81,2.81,0,0,1,2.044-.888h59.737a5.451,5.451,0,0,1,5.445,5.445Z"
                          transform="translate(-326.97 -392.491)"
                          fill="#18CC73"
                        />
                      </g>
                      <path
                        id="Path_211"
                        data-name="Path 211"
                        d="M13.871,4.729,8.857,10.647,6.8,7.956a1.223,1.223,0,0,0-1.712-.245,1.24,1.24,0,0,0-.245,1.712l2.984,3.914a1.265,1.265,0,0,0,.954.489h.024a1.289,1.289,0,0,0,.93-.44l5.993-7.094a1.223,1.223,0,0,0-1.859-1.566Z"
                        transform="translate(1228.393 199.608)"
                        fill="#18CC73"
                      />
                      <circle
                        id="Ellipse_43"
                        data-name="Ellipse 43"
                        cx="5.5"
                        cy="5.5"
                        r="5.5"
                        transform="translate(1233 223)"
                        fill="#e4e4e4"
                      />
                      <circle
                        id="Ellipse_44"
                        data-name="Ellipse 44"
                        cx="5.5"
                        cy="5.5"
                        r="5.5"
                        transform="translate(1233 243)"
                        fill="#e4e4e4"
                      />
                    </g>
                  </g>
                </svg>
              </div>
              <h3>Polls</h3>
            </NavLink>
          </div>
        </div>
        <h3 className="pt-5 pb-2">Livestream</h3>
        <div className="row g-4 create-livestream">
          <div className="col-md-4">
            <NavLink
              className="card card-boxed d-flex justify-content-center align-items-center"
              to="/dashboard/mylivestreams-public/1"
            >
              {/* <i className="bi bi-camera-video"></i> */}
              <div className="card-boxed-img">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  width="134"
                  height="48"
                  viewBox="0 0 134 48"
                >
                  <defs>
                    <clipPath id="clip-Public_livestream">
                      <rect width="134" height="48" />
                    </clipPath>
                  </defs>
                  <g
                    id="Public_livestream"
                    data-name="Public livestream"
                    clip-path="url(#clip-Public_livestream)"
                  >
                    <g
                      id="Group_146"
                      data-name="Group 146"
                      transform="translate(-404 -478)"
                    >
                      <g
                        id="Group_132"
                        data-name="Group 132"
                        transform="translate(41 0.197)"
                      >
                        <g
                          id="Group_130"
                          data-name="Group 130"
                          transform="translate(366 480.803)"
                        >
                          <path
                            className="sound-wave"
                            id="Path_215"
                            data-name="Path 215"
                            d="M25.639,15.339a6.546,6.546,0,1,0,6.546,6.546A6.546,6.546,0,0,0,25.639,15.339Zm-9.82,6.546a9.82,9.82,0,1,1,9.82,9.82A9.819,9.819,0,0,1,15.82,21.885ZM14.083,9.933a17.255,17.255,0,0,0,0,23.9l-2.347,2.282a20.528,20.528,0,0,1,0-28.467ZM37.2,33.837a17.255,17.255,0,0,0,0-23.9l2.347-2.282a20.529,20.529,0,0,1,0,28.467Z"
                            transform="translate(3.82 -1.088)"
                            fill="#18CC73"
                            fill-rule="evenodd"
                          />
                          <path
                            id="Path_216"
                            className="sound-wave1"
                            data-name="Path 216"
                            d="M13.942,7.963a26.1,26.1,0,0,0,0,36.962l-2.313,2.317a29.377,29.377,0,0,1,0-41.6ZM50.976,44.925a26.1,26.1,0,0,0,0-36.962l2.313-2.317a29.377,29.377,0,0,1,0,41.6Z"
                            transform="translate(-3 -5.646)"
                            fill="#18CC73"
                            fill-rule="evenodd"
                          />
                        </g>
                        <circle
                          id="Ellipse_49"
                          data-name="Ellipse 49"
                          cx="7.5"
                          cy="7.5"
                          r="7.5"
                          transform="translate(387.977 494.217)"
                          fill="#18CC73"
                        />
                      </g>
                      <g
                        id="Group_143"
                        className="livestream-public"
                        data-name="Group 143"
                        transform="translate(54.393 -51.188)"
                      >
                        <path
                          id="group"
                          d="M19.347,17.971a2.618,2.618,0,0,1-.767,1.991,2.833,2.833,0,0,1-2.038.73H7.36a2.832,2.832,0,0,1-2.038-.73,2.62,2.62,0,0,1-.767-1.991q0-.557.037-1.088a10.73,10.73,0,0,1,.147-1.145,8.964,8.964,0,0,1,.278-1.14,5.367,5.367,0,0,1,.452-1.024,3.711,3.711,0,0,1,.651-.851,2.732,2.732,0,0,1,.9-.562,3.159,3.159,0,0,1,1.172-.21,1.234,1.234,0,0,1,.452.226q.346.226.767.5a4.711,4.711,0,0,0,3.96.5,4.615,4.615,0,0,0,1.124-.5q.421-.278.767-.5a1.234,1.234,0,0,1,.452-.226,3.156,3.156,0,0,1,1.172.21,2.739,2.739,0,0,1,.9.562,3.69,3.69,0,0,1,.651.851,5.414,5.414,0,0,1,.452,1.024,8.9,8.9,0,0,1,.278,1.14,10.73,10.73,0,0,1,.147,1.145q.037.531.037,1.088ZM15.985,8.589A3.888,3.888,0,0,1,14.8,11.441a3.884,3.884,0,0,1-2.852,1.182A4.034,4.034,0,0,1,7.917,8.589a4.034,4.034,0,0,1,4.034-4.034A3.884,3.884,0,0,1,14.8,5.736,3.888,3.888,0,0,1,15.985,8.589Z"
                          transform="translate(415.506 528.633)"
                          fill="#e6e6e6"
                        />
                        <path
                          id="group-2"
                          data-name="group"
                          d="M19.347,17.971a2.618,2.618,0,0,1-.767,1.991,2.833,2.833,0,0,1-2.038.73H7.36a2.832,2.832,0,0,1-2.038-.73,2.62,2.62,0,0,1-.767-1.991q0-.557.037-1.088a10.73,10.73,0,0,1,.147-1.145,8.964,8.964,0,0,1,.278-1.14,5.367,5.367,0,0,1,.452-1.024,3.711,3.711,0,0,1,.651-.851,2.732,2.732,0,0,1,.9-.562,3.159,3.159,0,0,1,1.172-.21,1.234,1.234,0,0,1,.452.226q.346.226.767.5a4.711,4.711,0,0,0,3.96.5,4.615,4.615,0,0,0,1.124-.5q.421-.278.767-.5a1.234,1.234,0,0,1,.452-.226,3.156,3.156,0,0,1,1.172.21,2.739,2.739,0,0,1,.9.562,3.69,3.69,0,0,1,.651.851,5.414,5.414,0,0,1,.452,1.024,8.9,8.9,0,0,1,.278,1.14,10.73,10.73,0,0,1,.147,1.145q.037.531.037,1.088ZM15.985,8.589A3.888,3.888,0,0,1,14.8,11.441a3.884,3.884,0,0,1-2.852,1.182A4.034,4.034,0,0,1,7.917,8.589a4.034,4.034,0,0,1,4.034-4.034A3.884,3.884,0,0,1,14.8,5.736,3.888,3.888,0,0,1,15.985,8.589Z"
                          transform="translate(415.506 550.943)"
                          fill="#e6e6e6"
                        />
                        <path
                          id="group-3"
                          data-name="group"
                          d="M19.347,17.971a2.618,2.618,0,0,1-.767,1.991,2.833,2.833,0,0,1-2.038.73H7.36a2.832,2.832,0,0,1-2.038-.73,2.62,2.62,0,0,1-.767-1.991q0-.557.037-1.088a10.73,10.73,0,0,1,.147-1.145,8.964,8.964,0,0,1,.278-1.14,5.367,5.367,0,0,1,.452-1.024,3.711,3.711,0,0,1,.651-.851,2.732,2.732,0,0,1,.9-.562,3.159,3.159,0,0,1,1.172-.21,1.234,1.234,0,0,1,.452.226q.346.226.767.5a4.711,4.711,0,0,0,3.96.5,4.615,4.615,0,0,0,1.124-.5q.421-.278.767-.5a1.234,1.234,0,0,1,.452-.226,3.156,3.156,0,0,1,1.172.21,2.739,2.739,0,0,1,.9.562,3.69,3.69,0,0,1,.651.851,5.414,5.414,0,0,1,.452,1.024,8.9,8.9,0,0,1,.278,1.14,10.73,10.73,0,0,1,.147,1.145q.037.531.037,1.088ZM15.985,8.589A3.888,3.888,0,0,1,14.8,11.441a3.884,3.884,0,0,1-2.852,1.182A4.034,4.034,0,0,1,7.917,8.589a4.034,4.034,0,0,1,4.034-4.034A3.884,3.884,0,0,1,14.8,5.736,3.888,3.888,0,0,1,15.985,8.589Z"
                          transform="translate(437.564 528.633)"
                          fill="#e6e6e6"
                        />
                        <path
                          id="group-4"
                          data-name="group"
                          d="M19.347,17.971a2.618,2.618,0,0,1-.767,1.991,2.833,2.833,0,0,1-2.038.73H7.36a2.832,2.832,0,0,1-2.038-.73,2.62,2.62,0,0,1-.767-1.991q0-.557.037-1.088a10.73,10.73,0,0,1,.147-1.145,8.964,8.964,0,0,1,.278-1.14,5.367,5.367,0,0,1,.452-1.024,3.711,3.711,0,0,1,.651-.851,2.732,2.732,0,0,1,.9-.562,3.159,3.159,0,0,1,1.172-.21,1.234,1.234,0,0,1,.452.226q.346.226.767.5a4.711,4.711,0,0,0,3.96.5,4.615,4.615,0,0,0,1.124-.5q.421-.278.767-.5a1.234,1.234,0,0,1,.452-.226,3.156,3.156,0,0,1,1.172.21,2.739,2.739,0,0,1,.9.562,3.69,3.69,0,0,1,.651.851,5.414,5.414,0,0,1,.452,1.024,8.9,8.9,0,0,1,.278,1.14,10.73,10.73,0,0,1,.147,1.145q.037.531.037,1.088ZM15.985,8.589A3.888,3.888,0,0,1,14.8,11.441a3.884,3.884,0,0,1-2.852,1.182A4.034,4.034,0,0,1,7.917,8.589a4.034,4.034,0,0,1,4.034-4.034A3.884,3.884,0,0,1,14.8,5.736,3.888,3.888,0,0,1,15.985,8.589Z"
                          transform="translate(437.564 550.943)"
                          fill="#e6e6e6"
                        />
                        <path
                          id="group-5"
                          data-name="group"
                          d="M19.347,17.971a2.618,2.618,0,0,1-.767,1.991,2.833,2.833,0,0,1-2.038.73H7.36a2.832,2.832,0,0,1-2.038-.73,2.62,2.62,0,0,1-.767-1.991q0-.557.037-1.088a10.73,10.73,0,0,1,.147-1.145,8.964,8.964,0,0,1,.278-1.14,5.367,5.367,0,0,1,.452-1.024,3.711,3.711,0,0,1,.651-.851,2.732,2.732,0,0,1,.9-.562,3.159,3.159,0,0,1,1.172-.21,1.234,1.234,0,0,1,.452.226q.346.226.767.5a4.711,4.711,0,0,0,3.96.5,4.615,4.615,0,0,0,1.124-.5q.421-.278.767-.5a1.234,1.234,0,0,1,.452-.226,3.156,3.156,0,0,1,1.172.21,2.739,2.739,0,0,1,.9.562,3.69,3.69,0,0,1,.651.851,5.414,5.414,0,0,1,.452,1.024,8.9,8.9,0,0,1,.278,1.14,10.73,10.73,0,0,1,.147,1.145q.037.531.037,1.088ZM15.985,8.589A3.888,3.888,0,0,1,14.8,11.441a3.884,3.884,0,0,1-2.852,1.182A4.034,4.034,0,0,1,7.917,8.589a4.034,4.034,0,0,1,4.034-4.034A3.884,3.884,0,0,1,14.8,5.736,3.888,3.888,0,0,1,15.985,8.589Z"
                          transform="translate(459.622 528.633)"
                          fill="#e6e6e6"
                        />
                        <path
                          id="group-6"
                          data-name="group"
                          d="M19.347,17.971a2.618,2.618,0,0,1-.767,1.991,2.833,2.833,0,0,1-2.038.73H7.36a2.832,2.832,0,0,1-2.038-.73,2.62,2.62,0,0,1-.767-1.991q0-.557.037-1.088a10.73,10.73,0,0,1,.147-1.145,8.964,8.964,0,0,1,.278-1.14,5.367,5.367,0,0,1,.452-1.024,3.711,3.711,0,0,1,.651-.851,2.732,2.732,0,0,1,.9-.562,3.159,3.159,0,0,1,1.172-.21,1.234,1.234,0,0,1,.452.226q.346.226.767.5a4.711,4.711,0,0,0,3.96.5,4.615,4.615,0,0,0,1.124-.5q.421-.278.767-.5a1.234,1.234,0,0,1,.452-.226,3.156,3.156,0,0,1,1.172.21,2.739,2.739,0,0,1,.9.562,3.69,3.69,0,0,1,.651.851,5.414,5.414,0,0,1,.452,1.024,8.9,8.9,0,0,1,.278,1.14,10.73,10.73,0,0,1,.147,1.145q.037.531.037,1.088ZM15.985,8.589A3.888,3.888,0,0,1,14.8,11.441a3.884,3.884,0,0,1-2.852,1.182A4.034,4.034,0,0,1,7.917,8.589a4.034,4.034,0,0,1,4.034-4.034A3.884,3.884,0,0,1,14.8,5.736,3.888,3.888,0,0,1,15.985,8.589Z"
                          transform="translate(459.622 550.943)"
                          fill="#e6e6e6"
                        />
                      </g>
                    </g>
                  </g>
                </svg>
              </div>
              <h3 className="text-center">Schedule a public livestream</h3>
            </NavLink>
          </div>
          <div className="col-md-4">
            <NavLink
              className="card card-boxed d-flex justify-content-center align-items-center"
              to="/dashboard/mylivestreams-private/1"
            >
              {/* <i className="bi bi-camera-video"></i> */}
              <div className="card-boxed-img">
                <svg className="livestream-individual"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  width="107"
                  height="50"
                  viewBox="0 0 107 50"
                >
                  <defs>
                    <clipPath id="clip-Individual_livestream">
                      <rect width="107" height="50" />
                    </clipPath>
                  </defs>
                  <g
                    id="Individual_livestream"
                    data-name="Individual livestream"
                    clip-path="url(#clip-Individual_livestream)"
                  >
                    <g
                      id="Group_161"
                      data-name="Group 161"
                      transform="translate(-754 -461)"
                    >
                      <g
                        id="Group_137"
                        data-name="Group 137"
                        transform="translate(391 -16.803)"
                      >
                        <g
                          id="Group_130"
                          data-name="Group 130"
                          transform="translate(366 480.803)"
                        >
                          <path
                            id="Path_215"
                            data-name="Path 215"
                            d="M25.639,15.339a6.546,6.546,0,1,0,6.546,6.546A6.546,6.546,0,0,0,25.639,15.339Zm-9.82,6.546a9.82,9.82,0,1,1,9.82,9.82A9.819,9.819,0,0,1,15.82,21.885ZM14.083,9.933a17.255,17.255,0,0,0,0,23.9l-2.347,2.282a20.528,20.528,0,0,1,0-28.467ZM37.2,33.837a17.255,17.255,0,0,0,0-23.9l2.347-2.282a20.529,20.529,0,0,1,0,28.467Z"
                            transform="translate(3.82 -1.088)"
                            fill="#18CC73"
                            fill-rule="evenodd"
                            className="sound-wave"
                          />
                          <path
                            id="Path_216"
                            data-name="Path 216"
                            d="M13.942,7.963a26.1,26.1,0,0,0,0,36.962l-2.313,2.317a29.377,29.377,0,0,1,0-41.6ZM50.976,44.925a26.1,26.1,0,0,0,0-36.962l2.313-2.317a29.377,29.377,0,0,1,0,41.6Z"
                            transform="translate(-3 -5.646)"
                            fill="#18CC73"
                            fill-rule="evenodd"
                            className="sound-wave1"
                          />
                        </g>
                        <circle
                          id="Ellipse_49"
                          data-name="Ellipse 49"
                          cx="7.5"
                          cy="7.5"
                          r="7.5"
                          transform="translate(387.977 494.217)"
                          fill="#18CC73"
                        />
                      </g>
                      <path
                        id="group"
                        className="livestream-individual-user"
                        d="M37.21,34.371a5.4,5.4,0,0,1-1.582,4.106,5.844,5.844,0,0,1-4.2,1.506H12.485a5.842,5.842,0,0,1-4.2-1.506A5.4,5.4,0,0,1,6.7,34.371q0-1.149.076-2.243a22.133,22.133,0,0,1,.3-2.362,18.489,18.489,0,0,1,.573-2.351A11.07,11.07,0,0,1,8.584,25.3a7.653,7.653,0,0,1,1.343-1.756,5.634,5.634,0,0,1,1.853-1.159,6.515,6.515,0,0,1,2.417-.433,2.546,2.546,0,0,1,.931.466q.714.466,1.582,1.04a9.519,9.519,0,0,0,2.318,1.04A10.058,10.058,0,0,0,27.2,23.46q.868-.574,1.582-1.04a2.546,2.546,0,0,1,.931-.466,6.509,6.509,0,0,1,2.417.433,5.65,5.65,0,0,1,1.853,1.159A7.61,7.61,0,0,1,35.322,25.3a11.168,11.168,0,0,1,.931,2.113,18.357,18.357,0,0,1,.574,2.351,22.132,22.132,0,0,1,.3,2.362q.076,1.094.076,2.243ZM30.275,15.02A8.02,8.02,0,0,1,27.838,20.9a8.011,8.011,0,0,1-5.884,2.437A8.023,8.023,0,0,1,16.071,20.9a8.015,8.015,0,0,1-2.437-5.884,8.015,8.015,0,0,1,2.437-5.884A8.023,8.023,0,0,1,21.954,6.7a8.011,8.011,0,0,1,5.884,2.437A8.02,8.02,0,0,1,30.275,15.02Z"
                        transform="translate(819.964 461.756)"
                        fill="#e6e6e6"
                      />
                    </g>
                  </g>
                </svg>
              </div>
              <h3 className="text-center">Schedule a individual livestream</h3>
            </NavLink>
          </div>
          <div className="col-md-4">
            <NavLink
              className="card card-boxed d-flex justify-content-center align-items-center"
              to="/dashboard/mylivestreams-private/1"
            >
              {/* <i className="bi bi-camera-video"></i> */}
              <div className="card-boxed-img">
                <svg
                  className="livestream-individual"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  width="123"
                  height="49"
                  viewBox="0 0 123 49"
                >
                  <defs>
                    <clipPath id="clip-Private_Group_livestream">
                      <rect width="123" height="49" />
                    </clipPath>
                  </defs>
                  <g
                    id="Private_Group_livestream"
                    data-name="Private Group livestream"
                    clip-path="url(#clip-Private_Group_livestream)"
                  >
                    <g
                      id="Group_140"
                      data-name="Group 140"
                      transform="translate(-1090.816 -486)"
                    >
                      <g
                        id="Group_139"
                        data-name="Group 139"
                        transform="translate(0 1)"
                      >
                        <g
                          id="Group_129"
                          data-name="Group 129"
                          transform="translate(1093.816 489)"
                        >
                          <path
                            id="Path_215"
                            className="sound-wave"
                            data-name="Path 215"
                            d="M25.639,15.339a6.546,6.546,0,1,0,6.546,6.546A6.546,6.546,0,0,0,25.639,15.339Zm-9.82,6.546a9.82,9.82,0,1,1,9.82,9.82A9.819,9.819,0,0,1,15.82,21.885ZM14.083,9.933a17.255,17.255,0,0,0,0,23.9l-2.347,2.282a20.528,20.528,0,0,1,0-28.467ZM37.2,33.837a17.255,17.255,0,0,0,0-23.9l2.347-2.282a20.529,20.529,0,0,1,0,28.467Z"
                            transform="translate(3.82 -1.088)"
                            fill="#18CC73"
                            fill-rule="evenodd"
                          />
                          <path
                            id="Path_216"
                            className="sound-wave"
                            data-name="Path 216"
                            d="M13.942,7.963a26.1,26.1,0,0,0,0,36.962l-2.313,2.317a29.377,29.377,0,0,1,0-41.6ZM50.976,44.925a26.1,26.1,0,0,0,0-36.962l2.313-2.317a29.377,29.377,0,0,1,0,41.6Z"
                            transform="translate(-3 -5.646)"
                            fill="#18CC73"
                            fill-rule="evenodd"
                          />
                        </g>
                        <circle
                          id="Ellipse_48"
                          data-name="Ellipse 48"
                          cx="7.5"
                          cy="7.5"
                          r="7.5"
                          transform="translate(1115.793 502)"
                          fill="#18CC73"
                        />
                      </g>
                      <path
                        id="group"
                        className="livestream-private-user"
                        d="M13.533,20.447a7.869,7.869,0,0,0-6.048,2.921H4.427a5.232,5.232,0,0,1-3.149-.924A3.127,3.127,0,0,1,0,19.74q0-8.056,2.83-8.056a4.375,4.375,0,0,1,.993.48,15.043,15.043,0,0,0,2.225.97,8,8,0,0,0,2.715.49A9.177,9.177,0,0,0,11.8,13.1a11.355,11.355,0,0,0-.114,1.507,10.048,10.048,0,0,0,1.849,5.842Zm24.44,14.537a5.686,5.686,0,0,1-1.666,4.324,6.155,6.155,0,0,1-4.427,1.586H11.935a6.152,6.152,0,0,1-4.427-1.586,5.692,5.692,0,0,1-1.666-4.324q0-1.21.08-2.362a23.309,23.309,0,0,1,.32-2.488,19.471,19.471,0,0,1,.6-2.476,11.658,11.658,0,0,1,.981-2.225,8.06,8.06,0,0,1,1.414-1.849,5.934,5.934,0,0,1,1.951-1.221,6.861,6.861,0,0,1,2.545-.456,2.681,2.681,0,0,1,.981.49q.752.49,1.666,1.1a10.025,10.025,0,0,0,2.441,1.1,10.592,10.592,0,0,0,8.6-1.1q.914-.6,1.666-1.1a2.681,2.681,0,0,1,.981-.49,6.855,6.855,0,0,1,2.545.456,5.951,5.951,0,0,1,1.951,1.221,8.015,8.015,0,0,1,1.414,1.849,11.761,11.761,0,0,1,.981,2.225,19.331,19.331,0,0,1,.6,2.476,23.31,23.31,0,0,1,.32,2.488q.08,1.153.08,2.362ZM14.605,5.842a5.631,5.631,0,0,1-1.711,4.131,5.622,5.622,0,0,1-4.131,1.711A5.635,5.635,0,0,1,4.632,9.973,5.626,5.626,0,0,1,2.921,5.842,5.626,5.626,0,0,1,4.632,1.711,5.635,5.635,0,0,1,8.763,0a5.623,5.623,0,0,1,4.131,1.711A5.631,5.631,0,0,1,14.605,5.842Zm16.066,8.763A8.446,8.446,0,0,1,28.1,20.8a8.437,8.437,0,0,1-6.2,2.567,8.449,8.449,0,0,1-6.2-2.567,8.441,8.441,0,0,1-2.567-6.2,8.441,8.441,0,0,1,2.567-6.2,8.449,8.449,0,0,1,6.2-2.567,8.437,8.437,0,0,1,6.2,2.567A8.446,8.446,0,0,1,30.671,14.605ZM43.815,19.74a3.128,3.128,0,0,1-1.278,2.7,5.221,5.221,0,0,1-3.149.924H36.331a7.869,7.869,0,0,0-6.048-2.921,10.048,10.048,0,0,0,1.849-5.842,11.355,11.355,0,0,0-.114-1.507,9.177,9.177,0,0,0,3.035.525,8.011,8.011,0,0,0,2.715-.49,14.967,14.967,0,0,0,2.225-.97,4.341,4.341,0,0,1,.993-.48q2.83,0,2.83,8.056Zm-2.921-13.9a5.631,5.631,0,0,1-1.711,4.131,5.841,5.841,0,0,1-8.262,0A5.626,5.626,0,0,1,29.21,5.842a5.626,5.626,0,0,1,1.711-4.131,5.841,5.841,0,0,1,8.262,0A5.631,5.631,0,0,1,40.894,5.842Z"
                        transform="translate(1166.488 490)"
                        fill="#e6e6e6"
                      />
                    </g>
                  </g>
                </svg>
              </div>
              <h3 className="text-center">
                Schedule a private group livestream
              </h3>
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  );
};
