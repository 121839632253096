import moment from "moment";
import React, { useEffect, useState } from "react";
import { NavLink, useHistory } from "react-router-dom";
import { LivestreamService } from "../../../app/service/livestream";
import CardTicketImage from "../../../assets/img/livestream-detail-img.png";
import Banner from "../../../assets/img/live-stream.webp";
import { TooltipWrapper } from "../../../components/tooltip";
import { connect, useDispatch } from "react-redux";
import { Loading } from "../../../redux/actions";
import { isMobile } from "react-device-detect";
import { TextField } from "@mui/material";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import MobileViewModal from "../../../components/MobileViewModal";
import { PurchasedTicketAttendedPage } from "./purchased-ticket-attended";
import { PurchasedTicketRecordingsPage } from "./purchased-ticket-recordings";
interface Props {
  userId: string;
  UserReducer?: any;
  loading?: (data: any) => void;
  LoginUserIdReducer?: any;
}

const PurchasedTicketUpcomingPage: React.FC<Props> = (props: Props) => {
  // export const PurchasedTicketUpcomingPage = () => {
  let userId = sessionStorage.getItem("isLogin") || "";
  const [livestream, setLivestream] = useState<any[] | []>([]);
  const history = useHistory();
  const dispatch = useDispatch();
  const [gustList, setGustList] = useState<any[] | []>([]);
  const [gustEmail, setGustEmail] = React.useState("");
  const [gustName, setGustName] = React.useState("");
  const [gustPhone, setGustPhone] = React.useState("");
  const [gustNameError, setGustNameError] = React.useState("");
  const [gustEmailError, setGustEmailError] = React.useState("");
  const [gustPhoneError, setGustPhoneError] = React.useState("");
  const [modalState, setModalState] = useState<"modal-two" | "close">("close");
  const [existingGuestList, setExistingGuestList] = useState(false);
  const [isUserhadAccess, setIsUserhadAccess] = useState<any>({});
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  useEffect(() => {
    getlivestream();
  }, []);

  const getlivestream = () => {
    if (props.loading) {
      dispatch(props.loading(true));
    }
    LivestreamService.getScheduledLiveStreamsByUserID(userId).then((res) => {
      if (res) {
        console.log("res", res);

        setLivestream([...res]);
        if (props.loading) {
          dispatch(props.loading(false));
        }
      }
    });
  };

  const onClickEnter = (data: any) => {
    LivestreamService.getlivestreamById(data?.live_stream_data[0]?._id).then(
      (res) => {
        if (res) {
          console.log("res", res);
          if (res[0].status === "LIVE") {
            if (isMobile) {
              history.push(`/livestream/${data?.live_stream_data[0]?._id}`);
            } else {
              window.open(
                `${window.location.origin}/#/livestream/${data?.live_stream_data[0]?._id}`,
                "_blank"
              );
            }
          } else {
            history.push(
              `/livestreams/livestream-soon/${data?.live_stream_data[0]?._id}`
            );
          }
        }
      }
    );
  };
  const showAttendsPopup = (data: any) => {
    console.log('data', data);
    setModalState("modal-two");
    setIsUserhadAccess(data);
    setExistingGuestList(true);
  }

  const onChangeGuestEmail = (event: any) => {
    setGustEmailError("");
    if (event.target.value) {
      const re = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,6})+$/;
      if (!event.target.value.trim()) {
        setGustEmail("");
        setGustEmailError("Enter only alphabets");
      } else if (re.test(event.target.value.trim())) {
        setGustEmail(event.target.value);
      } else {
        setGustEmail(event.target.value);
        setGustEmailError("Please enter proper guest email id");
      }
    } else {
      setGustEmail("");
      setGustEmailError("Please enter guest email id");
    }
  };
  const onChangeGuestPhone = (event: any) => {
    setGustPhoneError("");
    if (event.target.value && event.target.value.length <= 10) {
      const re = /^[5-9]{1}[0-9]{9}$/;
      if (re.test(event.target.value.trim())) {
        setGustPhone(event.target.value.trim());
      } else {
        setGustPhone(event.target.value.trim());
        setGustPhoneError("Please enter proper phone number");
      }
    } else if (!(event.target.value.length >= 10)) {
      setGustPhone("");
      setGustPhoneError("Please enter phone number");
    }
  };
  const onChangeGuestName = (event: any) => {
    setGustNameError("");
    const reg = /^[A-Za-z]+(?:[ _-][A-Za-z]+)*$/;
    if (event) {
      if (!reg.test(event.target.value.trim())) {
        if (!event.target.value.trim()) {
          setGustName("");
          setGustNameError("Enter only alphabets");
        } else {
          setGustName(event.target.value);
          setGustNameError("Enter only alphabets");
        }
      } else {
        setGustName(event.target.value);
      }
    } else {
      setGustName("");
      setGustNameError("Please enter name");
    }
  };

  const onAddGustList = () => {
    const gustData: any = gustList;

    if (gustName && !gustNameError && gustEmail && !gustEmailError && gustPhone && !gustPhoneError) {
      setGustNameError("");
      setGustEmailError("");
      setGustPhoneError("");
      const isEmailAccessCheckData = {
        live_stream_id: isUserhadAccess?.live_stream_data[0]?._id,
        user_email: gustEmail,
      };
      LivestreamService.checkUserEmailHadLSAccess(isEmailAccessCheckData).then(
        (userLsAccess) => {
          if (userLsAccess) {
            if (userLsAccess.status) {
              toast.error(userLsAccess?.message);
            }
          } else {
            const isEmailExist = gustData.find(
              (data: any) => data.email === gustEmail || data.phone === gustPhone
            );
            if (!isEmailExist) {
              gustData.push({
                name: gustName,
                email: gustEmail,
                phone: gustPhone,
              });
              setGustList([...gustData]);
              setGustName("");
              setGustEmail("");
              setGustPhone("");
            } else {
              setGustEmailError("Email id already exist");
            }
          }
        }
      );
    } else {
      if (!gustName) {
        setGustNameError("Please enter guest name");
      }
      if (!gustEmail) {
        setGustEmailError("Please enter guest email id");
      }
      if (!gustPhone) {
        setGustPhoneError("Please enter phone number");
      }
    }
  };


  const onDeleteGustList = (index: number) => {
    const gustData: any = gustList;
    gustData.splice(index, 1);
    setGustList([...gustData]);
  };

  const onSendTickets = () => {
    createTicket();
  };

  const createTicket = () => {
    if (props.loading) {
      dispatch(props.loading(true));
    }
    const guests = gustList;
    if (!isUserhadAccess?.status) {
      guests.splice(0, 1);
    }
    const data = {
      guest_list: guests,
      live_stream_id: isUserhadAccess?.live_stream_data[0]?._id,
      club_id: isUserhadAccess?.live_stream_data[0]?.club_id,
      user_id: userId || props?.LoginUserIdReducer?.loginUserId,
    };
    console.log("createTicket", data);

    LivestreamService.createTickets(data).then((res) => {
      console.log("res res res", res);
      setModalState("close");
      history.push(`/dashboard/purchasedtickets/upcoming`);
      if (props.loading) {
        dispatch(props.loading(false));
      }
    });
  };

  return (
    <>
      <div
        id="subs-liv-scheduled-tab"
        role="tabpanel"
        aria-labelledby="subs-liv-scheduled-tab"
      >
        {/* Card Tickets */}
        <div className="row">
          {livestream.map((data: any, index: number) => {
            console.log(livestream)
            return (
              <div
                key={index}
                className="col-xxl-3 col-xl-4 col-sm-6 col-md-6 col-lg-6 mb-4"
              >
                <div className="card-ticket">
                  {data.price===0 ? <div className="gifted-ticket"><i className="bi bi-gift"></i></div>:
                  <div className="gifted-ticket">&#8377;</div>
                  }
                  <figure className="card-ticket-img ratio ratio-4x3">
                    <img
                      src={
                        data?.live_stream_data[0]?.thumbnail
                          ? data?.live_stream_data[0]?.thumbnail
                          : Banner
                      }
                    />
                  </figure>
                  <div className="card-ticket-content">
                    <span className="card-ticket-content-title">
                      {/* Dhindora uncensored */}
                      {data?.live_stream_data[0]?.title}
                    </span>
                    <strong className="card-ticket-content-authorname">
                      {data?.user_info[0]?.name}
                    </strong>
                    <span className="card-ticket-content-time">
                      {moment(data?.live_stream_data[0]?.schedule_From)
                        .utc()
                        .format("lll")}{" "}
                      IST
                    </span>
                    <ul className="card-ticket-price-info">
                      <li>
                        <span>Ticket Price</span>
                        <span>{data?.live_stream_data[0]?.price} INR</span>
                      </li>
                      <li>
                        <span>Attendees</span>
                        <span>{data?.live_stream_data[0]?.tickets}</span>
                      </li>
                      <li>
                        <strong>Total</strong>
                        <strong>{data?.live_stream_data[0]?.price} INR</strong>
                      </li>
                    </ul>
                  </div>
                  <div className="card-ticket-footer">
                    <button
                      className="btn btn-black"
                      onClick={(!isMobile) ? () => onClickEnter(data):handleShow}
                    >
                      Enter
                    </button>
                   
                    <MobileViewModal
                          show={show}
                          handleClose={handleClose}
                        />
                    {/* <div className="card-ticket-footer-right"> */}
                      {/* <a href="" className="btn btn-outline-black">
                        <TooltipWrapper
                          iconName="bi bi-link-45deg"
                          tooltipText="adf"
                        />
                      </a> */}
                      {/* <button onClick={() => showAttendsPopup(data)} className="btn btn-outline-black guest"> */}
                        {/* <TooltipWrapper */}
                          {/* iconName="bi bi-person-plus" */}
                          {/* tooltipText="Add guest list" */}
                        {/* /> */}
                      {/* </button> */}
                    {/* </div> */}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      
      <Modal
        dialogClassName="attendees-list"
        size="lg"
        show={modalState === "modal-two"}
      >
        <Modal.Body>
          <div className="d-flex mb-3">
            <h3 className="mb-0">Add Attendees</h3>
          </div>

          <div className="row mb-3">
            <div className="col-12 mb-3 mb-md-0 col-md">
              <TextField
                fullWidth
                id="outlined-basic"
                label="Name"
                variant="outlined"
                value={gustName}
                onChange={(event) => onChangeGuestName(event)}
              // disabled={gustList.length !== NFTCount + count ? false : true}
              />
              {gustNameError && (
                <p style={{ color: "red", fontSize: "14px" }}>
                  {gustNameError}
                </p>
              )}
            </div>
            <div className="col-12 mb-3 mb-md-0 col-md">
              <TextField
                fullWidth
                id="outlined-basic"
                label="Email id"
                variant="outlined"
                value={gustEmail}
                onChange={(event) => onChangeGuestEmail(event)}
              // disabled={gustList.length !== NFTCount + count ? false : true}
              />
              {gustEmailError && (
                <p style={{ color: "red", fontSize: "14px" }}>
                  {gustEmailError}
                </p>
              )}
            </div>
            <div className="col-12 mb-3 mb-md-0 col-md input-w-inner-input">
              <TextField
                id="outlined-basic"
                variant="standard"
                value={"+91"}
                disabled
                className="country-code"
              />
              <TextField
                fullWidth
                className="phone-number"
                id="phone-number"
                variant="outlined"
                value={gustPhone}
                label="Phone number"
                type="number"
                onChange={(event) => onChangeGuestPhone(event)}
              // disabled={gustList.length !== NFTCount + count ? false : true}
              />
              {gustPhoneError && (
                <p style={{ color: "red", fontSize: "14px" }}>
                  {gustPhoneError}
                </p>
              )}
            </div>
            <div className="col-md-2 d-flex">
              <button
                // disabled={gustList.length !== NFTCount + count ? false : true}
                className="btn btn-outline-black w-100"
                style={{ height: "50px" }}
                onClick={onAddGustList}
              >
                Add
              </button>
            </div>
          </div>
          {/* {gustList.length > 0 && ( */}
          <div className="col-md-12 border p-4 rounded-3 guest-list">
            <ul>
              {gustList.map((data: any, index: number) => {
                return (
                  <li key={index}>
                    <span className="guest-list-mail">{data?.name}</span>
                    <span className="guest-list-mail">{data?.email}</span>
                    <span className="guest-list-unused">{data?.phone}</span>
                    <span className="guest-list-actions">
                      <button
                        type="button"
                        className="btn px-2"
                        onClick={() => onDeleteGustList(index)}
                      >
                        <i className="bi bi-trash3 text-danger"></i>
                      </button>
                    </span>
                  </li>
                );
              })}
              {console.log('isUserhadAccess', isUserhadAccess)}
              {existingGuestList &&
                isUserhadAccess?.tickets_purchased_to.map(
                  (data: any, index: number) => {
                    return (
                      <li key={index}>
                        <span className="guest-list-mail">{data?.name}</span>
                        <span className="guest-list-mail">{data?.email}</span>
                        <span className="guest-list-unused">
                          {data?.phone}
                        </span>
                        <span className="guest-list-actions"></span>
                      </li>
                    );
                  }
                )}
            </ul>
            {gustList.length > 0 && (
              <button
                className="btn btn-outline-black mx-auto"
                onClick={onSendTickets}
              >
                Send Tickets
              </button>
            )}
          </div>
          {/* )} */}
        </Modal.Body>
      </Modal>
      
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    UserReducer: state.UserReducer,
    LoginUserIdReducer: state.LoginUserIdReducer,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    loading: (data: any) => dispatch(Loading(data)),
  };
};

const connectedNavBar = connect(
  mapStateToProps,
  mapDispatchToProps
)(PurchasedTicketUpcomingPage);
export { connectedNavBar as PurchasedTicketUpcomingPage };
