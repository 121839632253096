import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Clubs } from "../../../app/model/clubs";
import { ClubService } from "../../../app/service/club.service";
import FormBuilder from "../../../components/form-builder";

export const MyClubPaymentsPage = () => {
  const [myclubs, setMyClubs] = useState<Clubs[] | []>([]);
  const [myclubsData, setMyClubData] = useState<any>({});
  const [isLoading, setLoading] = useState(false);
  const [banckDetails, setBanckDetails] = useState<any>();
  const [errorMsg, setErrorMsg] = useState("");
  const updateFormData = (data: any) => {
    setMyClubData(data);
  };

  let id = sessionStorage.getItem("isLogin") || "";

  useEffect(() => {
    if (id) {
      getMyClub(id);
    }
  }, [id]);

  const getMyClub = (id: string) => {
    ClubService.getUserClubs(id).then((res) => {
      if (res) {
        setMyClubs(res);
        setBanckDetails(res[0]?.payout_details[0]);
      }
    });
  };

  const onSaveMyClub = () => {
    setLoading(true);
    const data = myclubsData.value;
    const value = {
      ifsc: data?.ifsc
        ? data?.ifsc
        : banckDetails?.ifsc
          ? banckDetails?.ifsc
          : "",
      bank_name: data?.bank_name
        ? data?.bank_name
        : banckDetails?.bank_name
          ? banckDetails?.bank_name
          : "",
      account_holder_name: data?.account_holder_name
        ? data?.account_holder_name
        : banckDetails?.account_holder_name
          ? banckDetails?.account_holder_name
          : "",
      account_number: data?.account_number
        ? data?.account_number
        : banckDetails?.account_number
          ? banckDetails?.account_number
          : "",
      account_type: data?.account_type
        ? data?.account_type
        : banckDetails?.account_type
          ? banckDetails?.account_type
          : "",
      micr_code: data?.micr_code
        ? data?.micr_code
        : banckDetails?.micr_code
          ? banckDetails?.micr_code
          : "",
      address: data?.address
        ? data?.address
        : banckDetails?.address
          ? banckDetails?.address
          : "",
      state: data?.state
        ? data?.state
        : banckDetails?.state
          ? banckDetails?.state
          : "",
      country: data?.country
        ? data?.country
        : banckDetails?.country
          ? banckDetails?.country
          : "",
    };
    myclubs[0].payout_details = [];
    myclubs[0].payout_details.push(value);
    if (myclubs.length > 0) {
      ClubService.updateClub(myclubs[0]).then((res) => {
        if (!res.code) {
          getMyClub(id);
          setLoading(false);
          toast.success("Club Updated successfully");
        } else {
          setLoading(false);
          toast.error(res.message);
        }
      });
    } else {
      setLoading(false);
      toast.error("Please create club");
    }
  };

  const onIfscCode = () => {
    if (myclubsData.value.ifsc) {
      ClubService.fetchBankDetails(myclubsData.value.ifsc).then((res) => {
        if (res) {
          setBanckDetails(res);
        } else {
          toast.error("Unknown IFSC CODE");
        }
      });
    }
  };
  return (
    <div
      className="tab-pane pb-5"
      id="payments"
      role="tabpanel"
      aria-labelledby="payments-tab"
    >
      <h3 className="title-w-bdr">
        <span>Bank Details for Payout</span>
      </h3>
      <div className="payment-detail-form">
        <FormBuilder onUpdate={updateFormData}>
          <form>
            <div className="row">
              <div className="col-md-6">
                <div className="form-floating mb-3">
                  <input
                    className="form-control"
                    id="ifscCode"
                    type="text"
                    placeholder="Basic"
                    name="ifsc"
                    onBlur={onIfscCode}
                    defaultValue={banckDetails?.ifsc}
                  />
                  <label htmlFor="ifscCode">
                    IFSC Code <span className="form-text">*</span>
                  </label>
                  <i
                    className="bi bi-search pointer"
                    onClick={onIfscCode}
                    title="Click here to find the bank detalils"
                    style={{
                      top: "11px",
                      position: "absolute",
                      right: "10px",
                      color: "#42CB86",
                    }}
                  ></i>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-floating mb-3">
                  <input
                    className="form-control"
                    id="nameOfBank"
                    type="text"
                    placeholder="Name of the Bank"
                    name="bank_name"
                    defaultValue={banckDetails?.bank_name}
                    readOnly
                  />
                  <label htmlFor="nameOfBank">
                    Name of the Bank <span className="form-text">*</span>
                  </label>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-floating mb-3">
                  <input
                    className="form-control"
                    id="accHolderName"
                    type="text"
                    placeholder="Account holder name"
                    name="account_holder_name"
                    defaultValue={banckDetails?.account_holder_name}
                  />
                  <label htmlFor="accHolderName">
                    Account holder name<span className="form-text">*</span>
                  </label>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-floating mb-3">
                  <input
                    className="form-control"
                    id="accNumber"
                    type="text"
                    placeholder="Account Number"
                    name="account_number"
                    defaultValue={banckDetails?.account_number}
                  />
                  <label htmlFor="accNumber">
                    Account Number<span className="form-text">*</span>
                  </label>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-floating mb-3">
                  <select
                    className="form-select"
                    id="accType"
                    aria-label="Account Type"
                    name="account_type"
                    defaultValue={banckDetails?.account_type}
                  >
                    <option>Account Type</option>
                    <option value="SAVINGS">Savings</option>
                    <option value="CURRENT">Current</option>
                  </select>
                  <label htmlFor="accType">Account Type</label>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-floating mb-3">
                  <input
                    className="form-control"
                    id="micrCode"
                    type="text"
                    placeholder="MICR Code"
                    name="micr_code"
                    readOnly
                    defaultValue={banckDetails?.micr_code}
                  />
                  <label htmlFor="micrCode">
                    MICR Code<span className="form-text">*</span>
                  </label>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-floating mb-3">
                  <input
                    className="form-control"
                    id="bankAddress"
                    type="text"
                    placeholder="Address"
                    name="address"
                    readOnly
                    defaultValue={banckDetails?.address}
                  />
                  <label htmlFor="bankAddress">
                    Address<span className="form-text">*</span>
                  </label>
                </div>
              </div>
              <div className="col-md-6">
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-floating mb-3">
                      <input
                        className="form-control"
                        id="bankState"
                        type="text"
                        placeholder="State"
                        name="state"
                        readOnly
                        defaultValue={banckDetails?.state}
                      />
                      <label htmlFor="bankState">
                        State<span className="form-text">*</span>
                      </label>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-floating mb-3">
                      <input
                        className="form-control"
                        id="bankCountry"
                        type="text"
                        placeholder="Country"
                        name="country"
                        readOnly
                        defaultValue={banckDetails?.country}
                      />
                      <label htmlFor="bankCountry">
                        Country<span className="form-text">*</span>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </FormBuilder>
        <div className="col-12 d-flex justify-content-end mt-3">
          <button
            disabled={isLoading && !errorMsg}
            type="submit"
            className="btn btn-outline-black"
            onClick={onSaveMyClub}
          >
            {!isLoading || errorMsg ? (
              "Save"
            ) : (
              <div
                className="spinner-border text-primary spinner-border-sm"
                role="status"
              >
                <span className="sr-only">Loading...</span>
              </div>
            )}
          </button>
          {/* <button className="btn btn-outline-black" type="button">Save</button> */}
        </div>
      </div>
      <div className="payment-history-sec">
        {/* <div className="payment-history__top">
          <div className="payment-history__top-left">
            <h3 className="title-w-bdr">
              <span>
                Payment history{" "}
                <span className="payment-history__info">in INR</span>
              </span>
            </h3>
          </div>
          <div className="payment-history__top-right"></div>
        </div> */}
        {/* <!-- Payment History Middle Sec--> */}
        {/* <div className="payment-history__middle">
          <div className="payment-history__middle-left">
            <ul className="list-style-none">
              <li>
                <strong>Current Balance</strong>:{" "}
              </li>
              <li>
                <strong>Withdrawal Balance</strong>:
              </li>
            </ul>
          </div>
          <div className="payment-history__middle-right">
            <span className="payment-history__info">
              <form>
                <div className="form-check">
                  <input
                    className="form-check-input"
                    id="autoTransfer"
                    type="checkbox"
                    value=""
                  />
                  <label className="form-check-label" htmlFor="autoTransfer">
                    Auto transfer
                  </label>
                </div>
              </form>
            </span>
          </div>
        </div> */}
        {/* <!-- Payment History Bottom Sec--> */}
        {/* <div className="payment-history__bottom">
          <div className="table-responsive">
            <table className="table table-default" id="table">
              <thead>
                <tr>
                  <th data-width="200">Month</th>
                  <th data-width="200" data-sortable="true">
                    Earnings
                  </th>
                  <th data-width="300">Other&apos;s Subscriptions</th>
                  <th data-width="300">Processing Fee</th>
                  <th data-width="300">Amount transferred</th>
                </tr>
              </thead>
              <tbody>
                <tr className="text-center">
                  <td></td>
                  <td></td>
                  <td>No Data</td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <td>July</td>
                  <td>25,015</td>
                  <td>5000</td>
                  <td>15</td>
                  <td>20,000</td>
                </tr>
                <tr>
                  <td>January</td>
                  <td>50,000</td>
                  <td>10000</td>
                  <td>15</td>
                  <td>30,000</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div> */}
      </div>
    </div>
  );
};
