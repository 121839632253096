import React, { useEffect, useState } from "react";
import { Link, useParams, useHistory } from "react-router-dom";
import { NftService } from "../../app/service/nft.service";
import NftDetail from "../../assets/img/nft-detail-img.jpg";
import { toast } from "react-toastify";
import Web3 from "web3";
import {
  NFT_PUBLIC_SMART_CONTRACT_ADDRESS,
  NFT_SMART_CONTRACT_ABI,
  MUMBAI_NETWORK_RPC,
} from "../../config/nft_config";
import { LivestreamService } from "../../app/service/livestream";
import { connect, useDispatch } from "react-redux";
import { Loading, LoginPopup } from "../../redux/actions";
import { Modal, OverlayTrigger, Popover } from "react-bootstrap";
import FormBuilder from "../form-builder";
import {
  FormGroup,
  TextField,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import axios from "axios";
import { RAZORPAY_KEY } from "../../config/constant";
import useRazorpay, { RazorpayOptions } from "react-razorpay";

interface Props {
  LoginUserIdReducer?: any;
  loading?: (data: any) => void;
}
const NFTSingle: React.FC<Props> = (props: Props) => {
  const [nftList, setNftList] = useState<any[] | []>([]);
  let { id, collection, counter } = useParams<{ id: string; collection: string; counter: string }>();
  let userId =
    sessionStorage.getItem("isLogin") || props?.LoginUserIdReducer?.loginUserId;
  const web3 = new Web3(Web3.givenProvider || MUMBAI_NETWORK_RPC);

  const history = useHistory();
  const dispatch = useDispatch();

  const [loading, setloading] = useState(false);
  const [NFTCount, setNFTCount] = useState(1);

  // NFT with Ticket
  const [showNFTWithTicket, setShowNFTWithTicket] = useState(false);
  const handleCloseNFTWithTicket = () => setShowNFTWithTicket(false);
  const handleShowNFTWithTicket = () => setShowNFTWithTicket(true);

  // to show in wallet popup
  const [valueInINR, setValueInINR] = useState(0);
  const Razorpay = useRazorpay();
  const [amount, setAmount] = useState<any>("");

  const RAZORPAY_OPTIONS: RazorpayOptions = {
    key: RAZORPAY_KEY, // "rzp_test_DsjVVApo8X8BCB",
    amount: amount + "000",
    currency: "INR",
    name: "Clan World",
    description: "Ticket Price",
    image: "",
    order_id: "",
    prefill: {
      name: "",
      email: "",
      contact: "",
    },
    notes: {
      address: "Razorpay Corporate Office",
    },
    theme: {
      color: "#3399cc",
    },
  };
  // const countInc = () => {
  //   // if (count < Number(counter)) {
  //   //   setCount(count + 1);
  //   // }
  //   if (nftList[0]?.nft_remaining_count > count) {
  //     setCount(count + 1);
  //     // await handleTicketWithNftTotal();
  //   } else {
  //     toast.error("NFT are not avaliable");
  //   }
  // };
  // const countDec = () => {
  //   if (count <= 1) {
  //     alert("No Negative");
  //     return;
  //   }
  //   setCount(count - 1);
  // };

  const getPriceMatic = async () => {
    // Here we will get the inr value for 1 matic
    const getInrValue = await axios.get(`https://api.coingecko.com/api/v3/simple/price?ids=matic-network&vs_currencies=INR`).then(async (res: any) => {
      console.log(res.data['matic-network'].inr, 'res');
      return Number(res.data['matic-network'].inr)
    });
    return getInrValue;
  }


  const handleTicketWithNftTotal = async () => {
    if (userId || props?.LoginUserIdReducer?.loginUserId) {

      if (props.loading) {
        dispatch(props.loading(true));
      }
      const inrValue = await getPriceMatic();
      const getInrValueObj = {
        noOfTickets: NFTCount,
        id: nftList[0]?.nftID,
        payableAmount: Number(nftList[0]?.price * NFTCount) / Number(inrValue),
      }
      const responseData = await LivestreamService.forBuyTickets(getInrValueObj).then(res => {
        console.log(res, 'response');
        if (res) {
          if (res < 1) {
            setValueInINR(1);
            setAmount(Number(nftList[0]?.price) + 1);
          } else {
            setValueInINR(res);
            setAmount(Number(nftList[0]?.price) + 1);
          }
          handleShowNFTWithTicket();
        }

        if (props.loading) {
          dispatch(props.loading(false));
        }
      });
    } else {
      handleShowLoginPopup('login');
      sessionStorage.setItem('showPaymentOption', 'yes');
      // toast.error("Please Login");
    }
  }

  useEffect(() => {
    const isShowPaymentsStr = sessionStorage.getItem('showPaymentOption') || '';
    if (userId || props?.LoginUserIdReducer?.loginUserId && isShowPaymentsStr != '') {
      handleTicketWithNftTotal();
    }
  }, [props?.LoginUserIdReducer?.loginUserId]);

  const openRezerPay = (ev: any, nftData: any) => {

    try {
      console.log(ev, nftData, 'in openRezorpay');

      let buttonID = ev.target.id;
      let amount: any;
      console.log(id);
      if (userId || props?.LoginUserIdReducer?.loginUserId) {
        amount = Number(nftList[0]?.price * NFTCount) + Number(valueInINR)
        if (Number(amount) > 0) {
          const createOrderObj = {
            amount: amount + "00",
            currency: 'INR',
            notes: 'LS purchase'
          }
          LivestreamService.createOrder(createOrderObj).then((createOrderResponse) => {
            RAZORPAY_OPTIONS.amount = amount + "00";
            RAZORPAY_OPTIONS.order_id = createOrderResponse.id;
            RAZORPAY_OPTIONS.handler = (response: any) => {
              buyNft(nftData);
            };
            let razorpay = new Razorpay(RAZORPAY_OPTIONS);
            razorpay.open();
            razorpay.on("payment.failed", function (response: any) { });
          });
        } else {
          console.log(amount, 'in else ');

        }
      } else {
        handleShowLoginPopup('login');
        toast.error("Please Login");
      }
    } catch (error) {
      console.log(error, 'in openrezorpay');

    }

  }

  const handleShowLoginPopup = (type: string) => {
    const data = { loginPopup: true, popupType: type };
    dispatch(LoginPopup(data));
  };

  const connectWallet = async (walletType: string, data?: any) => {
    if (props.loading) {
      dispatch(props.loading(true));
    }
    const addresses: any = await web3.eth.getAccounts();
    if (!addresses.length) {
      toast.error("Please connect your wallet");
      return;
    }
    // const getMaticValue = await axios
    //   .get(
    //     `https://api.coingecko.com/api/v3/simple/price?ids=matic-network&vs_currencies=INR`
    //   )
    //   .then(async (res: any) => {
    // console.log(res, "matic values");

    const buyTicketObj = {
      nftID: data.nftID,
      ticket_count: NFTCount,
      admin_address: "",
      value: "",
      signature: "",
      // priceInMatic:
      //   Number(data.price * NFTCount) /
      //   Number(res.data["matic-network"].inr),
    };
    // console.log(NFTCount, Number(res.data["matic-network"].inr));

    const getSignature = LivestreamService.getSignature().then(
      async (res) => {
        if (res) {
          buyTicketObj.admin_address = res.admin_address;
          buyTicketObj.value = res.value;
          buyTicketObj.signature = res.signature;
          console.log(buyTicketObj, "buyTicketObj", data.price);
          try {
            const buyTicketResponse = await buyTicket(buyTicketObj);
            const saveResponse = {
              livestream_id: data._id,
              user_id: userId,
              type: "BUY",
              req_payload: buyTicketObj,
              response: buyTicketResponse,
            };
            console.log(saveResponse, "saveResponse");

            LivestreamService.createLsSmartContract(saveResponse).then(
              (res) => {
                let buyNftObj = {
                  _id: nftList[0]._id,
                  collectionName: nftList[0].collectionName,
                  purchase_by: userId,
                  ticket_count: NFTCount,
                  creator_nft_id: nftList[0].creator_nft_id
                }
                LivestreamService.buyNft(buyNftObj).then((res) => {
                  console.log(res, "resssssssssssssss");
                  history.push(`/dashboard/mynft/owning`);

                  if (res) {
                  }
                });
                handleCloseNFTWithTicket();
                if (props.loading) {
                  dispatch(props.loading(false));
                }
              }
            );
          } catch (error) {
            console.log(error, "Error in buy Ticket");
            if (props.loading) {
              dispatch(props.loading(false));
            }
          }
        }
      }
    );
    // });
  };

  const countNFTInc = async () => {
    if (nftList[0]?.nft_remaining_count > NFTCount) {
      setNFTCount(NFTCount + 1);
      handleTicketWithNftTotal();
    } else {
      toast.error("NFT are not avaliable");
    }
  };
  const countNFTDec = async () => {
    if (NFTCount <= 1) {
      alert("No Negative");
      return;
    }
    setNFTCount(NFTCount - 1);
    handleTicketWithNftTotal();

  };

  useEffect(() => {
    sessionStorage.setItem('showPaymentOption', '');
    getParticularNftList();
  }, []);
  const getParticularNftList = () => {
    const data = {
      nft_id: id,
      collectionName: collection,
    };
    NftService.getParticularNftList(data).then((res) => {
      console.log("res getParticularNftList", res);

      setNftList([...res]);
    });
  };

  const buyTicket = async (data: any) => {
    try {
      console.log(data, 'buyTicket');

      if (props.loading) {
        dispatch(props.loading(true));
      }
      const addresses = await web3.eth.getAccounts();
      //@ts-ignore
      const contract = new web3.eth.Contract(
        NFT_SMART_CONTRACT_ABI,
        NFT_PUBLIC_SMART_CONTRACT_ADDRESS
      );
      console.log(
        data,
        "in Buy ticket",
        data.nftID,
        data.ticket_count,
        data.admin_address,
        data.value,
        data.signature,
        "-",
        addresses[0]
      );

      const nftPrice = await contract.methods.ticketPrice(data.nftID).call();
      console.log(nftPrice, "nftPrice");

      const buyticketResponse = await contract.methods
        .buyTicket(
          data.nftID,
          data.ticket_count,
          data.admin_address,
          data.value,
          data.signature
        )
        .send({ from: addresses[0], value: nftPrice });
      console.log(buyticketResponse, "buyticketResponse");
      return buyticketResponse;
    } catch (error) {
      console.log(error, 'in buyTicket');
      if (props.loading) {
        dispatch(props.loading(false));
      }
      return false;
    }

  };

  const buyTicketRes = async (data: any) => {
    try {
      const getInrValueObj = {
        noOfTickets: NFTCount,
        id: nftList[0]?.nftID,
        payableAmount: Number(nftList[0]?.price * NFTCount) / Number(valueInINR),
      }
      const response = await LivestreamService.buyTickets(getInrValueObj);
      return response;
    } catch (error) {
      console.log(error, 'error in buyTicketRes');
      throw error;
    }
  }

  const buyNft = (nftData: any) => {
    if (props.loading) {
      dispatch(props.loading(true));
    }
    if (!userId) {
      toast.error("Please Login to buy the NFT");
      return;
    }
    nftData.purchase_by = userId;
    console.log(nftData, "nftDatanftData");
    const buyTicketObj = {
      nftID: nftData.nftID,
      ticket_count: NFTCount,
      admin_address: "",
      value: "",
      signature: "",
    };
    const getSignature = LivestreamService.getSignature().then(async (res) => {
      if (res) {
        buyTicketObj.admin_address = res.admin_address;
        buyTicketObj.value = res.value;
        buyTicketObj.signature = res.signature;
        try {
          const buyTicketResponse = await buyTicketRes(buyTicketObj);
          const saveResponse = {
            user_id: userId,
            type: "BUY - Razorpay",
            req_payload: buyTicketObj,
            response: buyTicketResponse,
          };
          console.log(saveResponse, "saveResponse");

          LivestreamService.createLsSmartContract(saveResponse).then((res) => {
            console.log(nftData, '325')
            let buyNftObj = {
              _id: nftData._id,
              collectionName: nftData.collectionName,
              purchase_by: userId,
              ticket_count: NFTCount,
              creator_nft_id: nftData.creator_nft_id
            }
            LivestreamService.buyNft(buyNftObj).then((res) => {
              console.log(res, "resssssssssssssss");
              handleCloseNFTWithTicket();
              history.push(`/dashboard/mynft/owning`);

              if (res) {
              }
            });
          });
          if (props.loading) {
            dispatch(props.loading(false));
          }
        } catch (error) {
          console.log(error, "Error in buy Ticket");
          if (props.loading) {
            dispatch(props.loading(false));
          }
        }
      }
    });
  };
  return (
    <>
      <section className="nft-single">
        <div className="nft-detail">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-7">
                <div className="nft-detail-img-sec">
                  <img
                    src={
                      nftList[0]?.asset_url ? nftList[0]?.asset_url : NftDetail
                    }
                    alt=""
                  />
                </div>
              </div>
              <div className="col-md-5">
                <div className="nft-detail-text-sec">
                  <div className="nft-detail-text">
                    <h3 className="nft-detail-title">{nftList[0]?.title}</h3>
                    <span className="nft-detail-author">
                      Owned by:{" "}
                      {nftList[0] && nftList[0]?.creator_info
                        ? nftList[0]?.creator_info[0]?.name
                        : ""}
                    </span>
                  </div>
                  <div className="nft-detail-acc accordion" id="accordionExample">
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingOne">
                        <button
                          className="accordion-button"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseOne"
                          aria-expanded="true"
                          aria-controls="collapseOne"
                        >
                          Description
                        </button>
                      </h2>
                      <div
                        id="collapseOne"
                        className="accordion-collapse collapse show"
                        aria-labelledby="headingOne"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          <p>{nftList[0]?.description}</p>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingTwo">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseTwo"
                          aria-expanded="false"
                          aria-controls="collapseTwo"
                        >
                          Properties
                        </button>
                      </h2>
                      <div
                        id="collapseTwo"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingTwo"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          <p></p>
                        </div>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingThree">
                        <button
                          className="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseThree"
                          aria-expanded="false"
                          aria-controls="collapseThree"
                        >
                          Details
                        </button>
                      </h2>
                      <div
                        id="collapseThree"
                        className="accordion-collapse collapse"
                        aria-labelledby="headingThree"
                        data-bs-parent="#accordionExample"
                      >
                        <div className="accordion-body">
                          <strong></strong>
                          <code></code>
                        </div>
                      </div>
                    </div>
                  </div>
                  {!(nftList[0]?.status == 'SCHEDULED' || nftList[0]?.status == 'LIVE') && <div>
                    <strong style={{ color: 'red' }}>
                      This event was {nftList[0]?.status}
                    </strong>
                  </div>
                  }
                  <div className="nft-detail-price-sec">
                    {/* <div className="nft-detail-price">
                    <span>Price:</span>
                    <strong>{nftList[0]?.price} INR</strong>
                  </div> */}
                    <button disabled={!(nftList[0]?.status == 'SCHEDULED' || nftList[0]?.status == 'LIVE')}
                      className="btn btn-black"
                      onClick={() => handleTicketWithNftTotal()}
                    >
                      {/* buyNft(nftList[0]) */}
                      Buy now
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="nft-more">
        <div className="container">
          <h3>
            More from this <a href="">creator</a>
          </h3>
          <div className="row">
            <div className="col-md-3">
              <div className="card-nft mw-100">
                <div className="card-nft-img">
                  <Link to="/nft/nft-single" className="ratio ratio-1x1">
                    <img
                      src={NftDetail}
                      alt="Profile Image"
                      className="mw-100"
                    />
                  </Link>
                </div>
                <div className="card-nft-content">
                  <Link to="/nft/nft-single" className="card-nft-title ">
                    Name of the asset
                  </Link>
                  <div className="card-nft-price mt-3">
                    <span className="me-auto">Price: 0.01</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      </section>
      {/* NFT Ticket Modal */}
      <Modal
        show={showNFTWithTicket}
        onHide={handleCloseNFTWithTicket}
        centered
        dialogClassName="ticket-modal nft"
        style={{ zIndex: "1300" }}
      >
        <Modal.Header className="p-2 mb-2 d-flex flex-column align-items-center">
          <h3 className=" mb-0">Ticket with NFT</h3>
          <span className="checkout-card-price  mx-auto">
            &#8377; {nftList[0]?.price} (NFT Price) + {valueInINR} (Gas fee)
          </span>
        </Modal.Header>

        <Modal.Body className="px-0">
          <div className="col-md-12 d-flex align-items-center mb-3">
            <label className="ps-0">Number of Tickets</label>
            <div className="checkout-counter d-flex ms-auto">
              <span className="dec" onClick={countNFTDec}>
                <i className="bi bi-dash  "></i>
              </span>
              <input
                type="text"
                className="form-control"
                value={NFTCount}
                name="NFTCount"
              />
              <span className="inc" onClick={countNFTInc}>
                <i className="bi bi-plus"></i>
              </span>
            </div>
          </div>
          <div className="col-md-12 d-flex total-price-sec">
            <label className="me-auto ps-0 fw-bold">Total</label>
            <div className="checkout-total">
              <strong>
                &#8377;{" "}
                {Number(
                  nftList[0]?.price
                    ? Number(nftList[0]?.price * NFTCount) + Number(valueInINR)
                    : 0
                )}
              </strong>
            </div>
          </div>
          <div className="pt-4">
            <FormGroup className="d-flex fs-sml flex-row">
              <Checkbox
                defaultChecked
                className="p-0 pe-1 d-inline-flex flex-row"
              />
              I agree to the
              <Link className="d-inline-flex ps-1" to="/terms-conditions">
                Terms &amp; Conditions
              </Link>
            </FormGroup>
          </div>
          <div className="row mt-2">
            <div className=" w-100 d-flex flex-wrap">
              <strong className="col-12 text-center pb-2 "></strong>
              <div className="d-flex flex-wrap mb-2 w-100 px-0 flex-column">
                <label className="ps-0 fw-bold"> Pay in Crypto</label>
                <span className="pt-2 d-flex d-md-none font-weight-bold">
                  Please use the desktop version for payment in crypto wallet.
                </span>
                <div className="my-3 justify-content-between d-none d-md-flex">
                  <span
                    className="me-3 me-md-5"
                    title="MetaMask"
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      connectWallet("MetaMask", nftList[0])
                    }
                  >
                    <svg
                      width="50"
                      height="50"
                      viewBox="0 0 203 210"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M191.545 145L179.396 186L155.545 179.489L191.545 145Z"
                        fill="#E4761B"
                        stroke="#E4761B"
                      />
                      <path
                        d="M155.545 179L178.549 147.988L191.545 145L155.545 179Z"
                        fill="#E4761B"
                        stroke="#E4761B"
                      />
                      <path
                        d="M173.545 113.971L191.545 144.96L178.459 148L173.545 113.971ZM173.545 113.971L185.127 106L191.545 144.96L173.545 113.971Z"
                        fill="#F6851B"
                        stroke="#F6851B"
                      />
                      <path
                        d="M158.545 91.0971L192.545 76.9995L190.855 84.7221L158.545 91.0971ZM189.78 93.9995L158.545 91.0971L190.855 84.7221L189.78 93.9995Z"
                        fill="#763D16"
                        stroke="#763D16"
                      />
                      <path
                        d="M188.922 93.3432L184.689 106L158.545 90.5528L188.922 93.3432ZM195.545 80.1387L189.968 84.4239L191.611 76.9995L195.545 80.1387ZM188.922 93.3432L189.968 84.4239L194.4 88.1112L188.922 93.3432Z"
                        fill="#763D16"
                        stroke="#763D16"
                      />
                      <path
                        d="M130.545 170L138.603 172.373L155.545 179L130.545 170Z"
                        fill="#E2761B"
                        stroke="#E2761B"
                      />
                      <path
                        d="M185.524 106L189.839 93.7092L193.545 96.4189L185.524 106ZM185.524 106L150.545 95.0156L158.871 90.9995L185.524 106Z"
                        fill="#763D16"
                        stroke="#763D16"
                      />
                      <path
                        d="M167.545 51.9995L159.045 90.8591L150.545 94.9995L167.545 51.9995Z"
                        fill="#763D16"
                        stroke="#763D16"
                      />
                      <path
                        d="M192.545 77.3821L158.545 90.9995L166.943 51.9995L192.545 77.3821Z"
                        fill="#763D16"
                        stroke="#763D16"
                      />
                      <path
                        d="M167.545 51.9518L201.545 47.9995L192.076 76.9995L167.545 51.9518Z"
                        fill="#763D16"
                        stroke="#763D16"
                      />
                      <path
                        d="M185.545 106.144L173.811 114L150.545 94.9995L185.545 106.144Z"
                        fill="#F6851B"
                        stroke="#F6851B"
                      />
                      <path
                        d="M200.073 19.9995L201.545 48.0058L167.545 51.9995L200.073 19.9995Z"
                        fill="#763D16"
                        stroke="#763D16"
                      />
                      <path
                        d="M200.545 19.9995L131.443 69.9995L130.545 36.0869L200.545 19.9995Z"
                        fill="#E2761B"
                        stroke="#E2761B"
                      />
                      <path
                        d="M79.5449 31.9995L130.652 36.0727L131.545 69.9995L79.5449 31.9995Z"
                        fill="#F6851B"
                        stroke="#F6851B"
                      />
                      <path
                        d="M151.19 94.9995L131.545 70.257L167.545 51.9995L151.19 94.9995Z"
                        fill="#763D16"
                        stroke="#763D16"
                      />
                      <path
                        d="M150.752 94.9995L173.545 114.434L141.545 118L150.752 94.9995Z"
                        fill="#E4761B"
                        stroke="#E4761B"
                      />
                      <path
                        d="M141.624 118L131.545 69.9995L150.545 94.9555L141.624 118Z"
                        fill="#E4761B"
                        stroke="#E4761B"
                      />
                      <path
                        d="M167.515 51.8267L131.545 69.9995L200.545 19.9995L167.515 51.8267Z"
                        fill="#763D16"
                        stroke="#763D16"
                      />
                      <path
                        d="M79.6438 179.16L96.5449 196L73.5449 176L79.6438 179.16Z"
                        fill="#C0AD9E"
                        stroke="#C0AD9E"
                      />
                      <path
                        d="M155.545 179L163.261 150.154L178.545 148L155.545 179Z"
                        fill="#CD6116"
                        stroke="#CD6116"
                      />
                      <path
                        d="M13.5449 96.9995L42.5449 67.9995L17.2852 93.539L13.5449 96.9995Z"
                        fill="#E2761B"
                        stroke="#E2761B"
                      />
                      <path
                        d="M178.545 147.813L163.333 150L173.689 113.879L178.545 147.813ZM131.473 70.0079L104.171 69.1136L79.5449 31.9995L131.473 70.0079Z"
                        fill="#F6851B"
                        stroke="#F6851B"
                      />
                      <path
                        d="M173.545 114L163.372 150L162.545 132.42L173.545 114Z"
                        fill="#E4751F"
                        stroke="#E4751F"
                      />
                      <path
                        d="M141.545 117.626L173.545 114L162.298 133L141.545 117.626Z"
                        fill="#CD6116"
                        stroke="#CD6116"
                      />
                      <path
                        d="M104.545 68.9995L131.37 69.9069L141.545 118L104.545 68.9995Z"
                        fill="#F6851B"
                        stroke="#F6851B"
                      />
                      <path
                        d="M104.545 69.3722L30.4854 0.999512L79.792 32.2542L104.545 69.3722ZM79.7422 189.124L15.3945 209L2.54492 160.055L79.7422 189.124Z"
                        fill="#E4761B"
                        stroke="#E4761B"
                      />
                      <path
                        d="M23.5449 109L48.0265 90.9995L68.5449 95.4643L23.5449 109Z"
                        fill="#763D16"
                        stroke="#763D16"
                      />
                      <path
                        d="M68.5449 94.9995L47.5449 90.3399L58.7894 45.9995L68.5449 94.9995Z"
                        fill="#763D16"
                        stroke="#763D16"
                      />
                      <path
                        d="M17.5449 94.1013L47.5449 90.9995L23.4263 109L17.5449 94.1013Z"
                        fill="#763D16"
                        stroke="#763D16"
                      />
                      <path
                        d="M162.545 133L149.854 126.671L141.545 118L162.545 133Z"
                        fill="#CD6116"
                        stroke="#CD6116"
                      />
                      <path
                        d="M17.6618 93.9995L15.5449 82.9995L47.5449 90.8013L17.6618 93.9995Z"
                        fill="#763D16"
                        stroke="#763D16"
                      />
                      <path
                        d="M147.545 141L150.111 127L162.545 133.216L147.545 141Z"
                        fill="#233447"
                        stroke="#233447"
                      />
                      <path
                        d="M163.545 150L147.545 140.614L162.698 133L163.545 150Z"
                        fill="#F6851B"
                        stroke="#F6851B"
                      />
                      <path
                        d="M47.7523 90.3399L15.1992 82.443L12.5449 73.0746L47.7523 90.3399ZM58.7202 45.9995L47.7523 90.3399L12.5449 73.0746L58.7202 45.9995ZM58.7202 45.9995L104.545 69.5921L68.2357 94.9995L58.7202 45.9995Z"
                        fill="#763D16"
                        stroke="#763D16"
                      />
                      <path
                        d="M68.5449 94.7158L104.518 68.9995L120.545 118L68.5449 94.7158ZM120.545 118L70.8274 116.957L68.5449 94.7158L120.545 118Z"
                        fill="#E4761B"
                        stroke="#E4761B"
                      />
                      <path
                        d="M23.5449 109.014L67.9807 94.7158L70.2594 116.957L23.5449 109.014ZM141.545 117.255L119.897 118L103.896 68.9995L141.545 117.255Z"
                        fill="#F6851B"
                        stroke="#F6851B"
                      />
                      <path
                        d="M149.545 126.7L147.024 141L141.545 118L149.545 126.7Z"
                        fill="#CD6116"
                        stroke="#CD6116"
                      />
                      <path
                        d="M30.5449 0.999512L104.545 68.9995L59.0103 45.2292L30.5449 0.999512Z"
                        fill="#763D16"
                        stroke="#763D16"
                      />
                      <path
                        d="M2.54492 160.53L64.8404 158L79.5449 189L2.54492 160.53Z"
                        fill="#E4761B"
                        stroke="#E4761B"
                      />
                      <path
                        d="M79.441 189L64.5449 157.484L95.5449 156L79.441 189Z"
                        fill="#CD6116"
                        stroke="#CD6116"
                      />
                      <path
                        d="M147.783 140.325L163.744 150.133L172.545 171L147.783 140.325ZM70.6145 117.006L2.54492 159.991L23.7266 109L70.6145 117.006ZM64.8965 157.389L2.54492 159.991L70.6145 117.006L64.8965 157.389ZM142.165 117.306L146.242 132.218L126.552 133.369L142.165 117.306ZM126.552 133.369L120.436 118.057L142.165 117.306L126.552 133.369Z"
                        fill="#F6851B"
                        stroke="#F6851B"
                      />
                      <path
                        d="M96.2224 195.7L79.5449 189L137.545 199L96.2224 195.7Z"
                        fill="#C0AD9E"
                        stroke="#C0AD9E"
                      />
                      <path
                        d="M23.5449 109L13.5449 97.3591L17.347 93.9995L23.5449 109Z"
                        fill="#763D16"
                        stroke="#763D16"
                      />
                      <path
                        d="M144.545 191.588L137.92 199L79.5449 189L144.545 191.588Z"
                        fill="#D7C1B3"
                        stroke="#D7C1B3"
                      />
                      <path
                        d="M147.545 173.811L79.5449 189L95.4514 156L147.545 173.811Z"
                        fill="#E4761B"
                        stroke="#E4761B"
                      />
                      <path
                        d="M79.5449 189.435L147.545 174L144.413 192L79.5449 189.435Z"
                        fill="#D7C1B3"
                        stroke="#D7C1B3"
                      />
                      <path
                        d="M12.7786 73.2228L10.5449 34.9995L58.5449 45.7855L12.7786 73.2228ZM17.5439 93.9995L10.942 87.2893L15.4095 82.7164L17.5439 93.9995Z"
                        fill="#763D16"
                        stroke="#763D16"
                      />
                      <path
                        d="M108.545 128.227L120.545 118L118.831 143L108.545 128.227Z"
                        fill="#CD6116"
                        stroke="#CD6116"
                      />
                      <path
                        d="M120.545 118L109.141 128.25L92.5449 137L120.545 118Z"
                        fill="#CD6116"
                        stroke="#CD6116"
                      />
                      <path
                        d="M172.545 171L169.483 168.797L147.545 141L172.545 171Z"
                        fill="#F6851B"
                        stroke="#F6851B"
                      />
                      <path
                        d="M93.0998 137L70.5449 118L120.545 119.028L93.0998 137Z"
                        fill="#CD6116"
                        stroke="#CD6116"
                      />
                      <path
                        d="M118.545 143L120.197 118L126.545 133.805L118.545 143Z"
                        fill="#E4751F"
                        stroke="#E4751F"
                      />
                      <path
                        d="M8.54492 76.9786L12.7763 72.9995L15.5449 82.9995L8.54492 76.9786Z"
                        fill="#763D16"
                        stroke="#763D16"
                      />
                      <path
                        d="M118.545 143L92.5449 136.863L108.908 128L118.545 143Z"
                        fill="#233447"
                        stroke="#233447"
                      />
                      <path
                        d="M58.5449 45.9995L10.5449 35.0889L30.152 0.999512L58.5449 45.9995Z"
                        fill="#763D16"
                        stroke="#763D16"
                      />
                      <path
                        d="M137.826 199.023L140.545 206L96.5449 196L137.826 199.023Z"
                        fill="#C0AD9E"
                        stroke="#C0AD9E"
                      />
                      <path
                        d="M95.0044 156L92.5449 137L118.545 142.714L95.0044 156Z"
                        fill="#F6851B"
                        stroke="#F6851B"
                      />
                      <path
                        d="M70.5449 118L93.0998 136.975L95.5449 156L70.5449 118Z"
                        fill="#E4751F"
                        stroke="#E4751F"
                      />
                      <path
                        d="M126.427 134.128L146.189 132.994L169.545 169L126.427 134.128ZM70.284 118L95.2864 156.323L64.5449 157.803L70.284 118Z"
                        fill="#F6851B"
                        stroke="#F6851B"
                      />
                      <path
                        d="M126.211 134L150.545 171L118.545 142.793L126.211 134Z"
                        fill="#E4751F"
                        stroke="#E4751F"
                      />
                      <path
                        d="M118.545 143L150.545 171.007L147.182 174L118.545 143Z"
                        fill="#F6851B"
                        stroke="#F6851B"
                      />
                      <path
                        d="M147.663 174L95.5449 156.222L118.869 142.79L147.663 174ZM169.545 168.913L151.045 170.987L126.577 134L169.545 168.913Z"
                        fill="#F6851B"
                        stroke="#F6851B"
                      />
                      <path
                        d="M174.545 186L166.48 201.851L140.317 206L174.545 186ZM140.317 206L137.545 198.592L144.249 191.382L140.317 206Z"
                        fill="#C0AD9E"
                        stroke="#C0AD9E"
                      />
                      <path
                        d="M144.451 191.382L149.358 189.456L140.545 206L144.451 191.382ZM140.545 206L149.358 189.456L174.545 186L140.545 206Z"
                        fill="#C0AD9E"
                        stroke="#C0AD9E"
                      />
                      <path
                        d="M169.296 169L175.545 172.75L156.545 175L169.296 169Z"
                        fill="#161616"
                        stroke="#161616"
                      />
                      <path
                        d="M156.447 174.795L150.545 171.028L168.691 169L156.447 174.795ZM154.35 177.644L176.545 174.988L173.472 186L154.35 177.644Z"
                        fill="#161616"
                        stroke="#161616"
                      />
                      <path
                        d="M173.509 186.536L149.268 190.045L154.617 177.866L173.509 186.536ZM149.268 190.045L144.545 192L147.581 174.057L149.268 190.045ZM147.581 174.057L150.858 171L156.69 174.909L147.581 174.057ZM174.714 172.653L176.545 175.109L154.617 177.866L174.714 172.653Z"
                        fill="#161616"
                        stroke="#161616"
                      />
                      <path
                        d="M154.806 177.394L156.945 174.334L175.545 172L154.806 177.394ZM147.545 173.452L154.806 177.394L149.286 190L147.545 173.452Z"
                        fill="#161616"
                        stroke="#161616"
                      />
                      <path
                        d="M156.545 174.894L154.497 178L147.545 174L156.545 174.894Z"
                        fill="#161616"
                        stroke="#161616"
                      />
                    </svg>
                  </span>
                  <span
                    className="me-3 me-md-5"
                    title="Coinbase"
                    style={{ cursor: "pointer" }}
                    onClick={() => connectWallet("Coinbase")}
                  >
                    <svg
                      width="50"
                      height="50"
                      viewBox="0 0 383 383"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0)">
                        <g filter="url(#filter0_dd)">
                          <path
                            d="M0.998047 0.572266H382.78V382.354H0.998047V0.572266Z"
                            fill="url(#paint0_linear)"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M59.1074 191.572C59.1074 264.966 118.605 324.463 191.998 324.463C265.392 324.463 324.889 264.966 324.889 191.572C324.889 118.179 265.392 58.6816 191.998 58.6816C118.605 58.6816 59.1074 118.179 59.1074 191.572ZM158.037 148.752C153.144 148.752 149.178 152.718 149.178 157.611V225.533C149.178 230.426 153.144 234.393 158.037 234.393H225.959C230.852 234.393 234.818 230.426 234.818 225.533V157.611C234.818 152.718 230.852 148.752 225.959 148.752H158.037Z"
                            fill="white"
                          />
                        </g>
                      </g>
                      <defs>
                        <filter
                          id="filter0_dd"
                          x="-23.002"
                          y="-7.42773"
                          width="429.782"
                          height="429.782"
                          filterUnits="userSpaceOnUse"
                          color-interpolation-filters="sRGB"
                        >
                          <feFlood
                            flood-opacity="0"
                            result="BackgroundImageFix"
                          />
                          <feColorMatrix
                            in="SourceAlpha"
                            type="matrix"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                          />
                          <feOffset dy="16" />
                          <feGaussianBlur stdDeviation="12" />
                          <feColorMatrix
                            type="matrix"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0"
                          />
                          <feBlend
                            mode="normal"
                            in2="BackgroundImageFix"
                            result="effect1_dropShadow"
                          />
                          <feColorMatrix
                            in="SourceAlpha"
                            type="matrix"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                          />
                          <feOffset />
                          <feGaussianBlur stdDeviation="4" />
                          <feColorMatrix
                            type="matrix"
                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.04 0"
                          />
                          <feBlend
                            mode="normal"
                            in2="effect1_dropShadow"
                            result="effect2_dropShadow"
                          />
                          <feBlend
                            mode="normal"
                            in="SourceGraphic"
                            in2="effect2_dropShadow"
                            result="shape"
                          />
                        </filter>
                        <linearGradient
                          id="paint0_linear"
                          x1="191.889"
                          y1="0.572266"
                          x2="191.889"
                          y2="382.354"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stop-color="#2E66F8" />
                          <stop offset="1" stop-color="#124ADB" />
                        </linearGradient>
                        <clipPath id="clip0">
                          <rect width="383" height="383" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </span>
                  <span
                    style={{ cursor: "pointer" }}
                    className="me-3 me-md-5"
                    title="Walletconnect"
                    onClick={() => connectWallet("Walletconnect")}
                  >
                    <svg
                      width="50"
                      height="50"
                      viewBox="0 0 92 91"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <ellipse
                        cx="46.0003"
                        cy="45.2335"
                        rx="45.639"
                        ry="45.0778"
                        fill="#3C99FC"
                        fill-opacity="0.1"
                      />
                      <g clip-path="url(#clip0)">
                        <path
                          d="M31.011 35.427C39.4889 27.1436 53.2344 27.1436 61.7123 35.427L62.7327 36.4239C63.1566 36.8381 63.1566 37.5096 62.7327 37.9237L59.2423 41.334C59.0303 41.5411 58.6867 41.5411 58.4748 41.334L57.0707 39.9621C51.1562 34.1834 41.5671 34.1834 35.6526 39.9621L34.1489 41.4313C33.937 41.6384 33.5934 41.6384 33.3814 41.4313L29.8911 38.021C29.4672 37.6069 29.4672 36.9354 29.8911 36.5212L31.011 35.427ZM68.9307 42.4798L72.0371 45.5149C72.461 45.9291 72.461 46.6006 72.0371 47.0147L58.0301 60.7005C57.6062 61.1147 56.9189 61.1147 56.495 60.7006C56.495 60.7005 56.495 60.7005 56.495 60.7005L46.5536 50.9873C46.4476 50.8838 46.2758 50.8838 46.1698 50.9873C46.1698 50.9873 46.1698 50.9873 46.1698 50.9873L36.2286 60.7005C35.8048 61.1147 35.1175 61.1147 34.6936 60.7006C34.6936 60.7006 34.6936 60.7006 34.6936 60.7005L20.6861 47.0145C20.2622 46.6004 20.2622 45.9289 20.6861 45.5147L23.7925 42.4796C24.2164 42.0654 24.9037 42.0654 25.3276 42.4796L35.2691 52.1929C35.3751 52.2965 35.5469 52.2965 35.6529 52.1929C35.6529 52.1929 35.6529 52.1929 35.6529 52.1929L45.5939 42.4796C46.0178 42.0654 46.7051 42.0654 47.129 42.4795C47.129 42.4796 47.129 42.4796 47.129 42.4796L57.0705 52.1929C57.1765 52.2965 57.3483 52.2965 57.4543 52.1929L67.3957 42.4798C67.8195 42.0656 68.5068 42.0656 68.9307 42.4798Z"
                          fill="#3C99FC"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0">
                          <rect
                            width="52"
                            height="32"
                            fill="white"
                            transform="translate(20.3613 29.1556)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  </span>
                  <span
                    style={{ cursor: "pointer" }}
                    title="MEW"
                    onClick={() => connectWallet("MEW")}
                  >
                    <svg
                      width="50"
                      height="50"
                      viewBox="0 0 120 120"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g
                        id="Buttons"
                        stroke="none"
                        stroke-width="1"
                        fill="none"
                        fill-rule="evenodd"
                      >
                        <g
                          id="04.-Guide---App-icon"
                          transform="translate(-54.000000, -188.000000)"
                        >
                          <g
                            id="App-Icon/mew_wallet_appicon"
                            transform="translate(54.000000, 188.000000)"
                          >
                            <path
                              d="M111.821972,8.17802805 C104.415006,0.771062258 96.991511,0.513035658 84.5656411,0.50054281 L35.4338562,0.500543063 C23.008489,0.513035658 15.5849938,0.771062258 8.17802805,8.17802805 C0.771062212,15.5849938 0.513035654,23.008489 0.50054281,35.4343589 L0.500543063,84.5661438 C0.513035654,96.991511 0.771062212,104.415006 8.17802805,111.821972 C15.9079529,119.551897 23.656924,119.5 37.0828183,119.5 L82.9171817,119.5 C96.343076,119.5 104.092047,119.551897 111.821972,111.821972 C119.551897,104.092047 119.5,96.343076 119.5,82.9171817 L119.5,37.0828183 C119.5,23.656924 119.551897,15.9079529 111.821972,8.17802805 Z"
                              id="iOS-icon-mask"
                              stroke="#DADCE0"
                              fill="#FFFFFF"
                            ></path>
                            <g id="Elements/App-Icon">
                              <g
                                id="icon"
                                transform="translate(2.000000, 2.000000)"
                              >
                                <path
                                  d="M78.2902062,106.530612 C100.188166,107.566326 102.396167,88.4204441 95.9815645,77.2712546 C89.7672785,66.4702342 80.4458501,62.7712544 65.3703851,64.1900465 C50.29492,65.6088385 65.4352032,90.169341 75.777958,88.3681934 C80.4702531,87.5510499 76.3984486,106.441137 78.2902062,106.530612 Z"
                                  id="right-arm"
                                  stroke="#000000"
                                  stroke-width="2.5"
                                  fill="#FFFFFF"
                                  fill-rule="nonzero"
                                ></path>
                                <path
                                  d="M37.7086826,106.530612 C15.8107234,107.566326 13.6027223,88.4204441 20.0173244,77.2712546 C26.2316101,66.4702341 35.5530388,62.7712544 50.6285038,64.1900464 C65.7039688,65.6088385 50.5636857,90.169341 40.2209306,88.3681933 C35.5286355,87.5510498 39.6004401,106.441137 37.7086826,106.530612 Z"
                                  id="left-arm"
                                  stroke="#000000"
                                  stroke-width="2.5"
                                  fill="#FFFFFF"
                                  fill-rule="nonzero"
                                ></path>
                                <path
                                  d="M60.7410433,9.46938776 C68.2263324,9.46938776 73.0871763,10.6723916 77.3062878,12.9313812 C81.5253989,15.1903709 84.8365819,18.5053464 87.0929873,22.7292899 C89.3251303,26.9078146 90.5250571,31.7150649 90.5510204,39.072678 L90.5510204,43.5436364 C90.5510204,51.0374983 89.349393,55.9039096 87.0929873,60.1278531 C84.8365819,64.3517963 81.5253989,67.6667718 77.3062878,69.9257617 C73.0871763,72.1847514 68.2263324,73.3877551 60.7410433,73.3877551 L55.2589567,73.3877551 C47.7736676,73.3877551 42.9128237,72.1847514 38.6937122,69.9257617 C34.4746011,67.6667718 31.1634181,64.3517963 28.9070125,60.1278531 C26.6506072,55.9039096 25.4489796,51.0374983 25.4489796,43.5436364 L25.4489796,39.3135064 C25.4489796,31.8196446 26.6506072,26.9532333 28.9070125,22.7292899 C31.1634181,18.5053464 34.4746011,15.1903709 38.6937122,12.9313812 C42.8674567,10.6966818 47.6692075,9.4953809 55.0184035,9.46938776 L60.7410433,9.46938776 Z"
                                  id="helmet"
                                  stroke="#000000"
                                  stroke-width="2.5"
                                  fill="#FFFFFF"
                                  fill-rule="nonzero"
                                ></path>
                                <path
                                  d="M54.0368739,27.8163265 L61.9631261,27.8163265 C65.9787611,27.8163265 69.0898725,28.5567899 71.7010112,29.9472255 C74.3121499,31.3376607 76.361387,33.3780684 77.7578394,35.9779566 C79.1542915,38.5778448 79.8979592,41.6755514 79.8979592,45.6738844 L79.8979592,46.6526462 C79.8979592,50.6509792 79.1542915,53.7486858 77.7578394,56.348574 C76.361387,58.9484622 74.3121499,60.9888699 71.7010112,62.3793051 C69.0898725,63.7697406 65.9787611,64.5102041 61.9631261,64.5102041 L54.0368739,64.5102041 C50.0212389,64.5102041 46.9101275,63.7697406 44.2989888,62.3793051 C41.6878501,60.9888699 39.638613,58.9484622 38.2421606,56.348574 C36.8457085,53.7486858 36.1020408,50.6509792 36.1020408,46.6526462 L36.1020408,45.6738844 C36.1020408,41.6755514 36.8457085,38.5778448 38.2421606,35.9779566 C39.638613,33.3780684 41.6878501,31.3376607 44.2989888,29.9472255 C46.9101275,28.5567899 50.0212389,27.8163265 54.0368739,27.8163265 Z"
                                  id="screen"
                                  stroke="#000000"
                                  stroke-width="2.5"
                                  fill="#FFFFFF"
                                  fill-rule="nonzero"
                                ></path>
                                <circle
                                  id="left-eye"
                                  fill="#000000"
                                  fill-rule="nonzero"
                                  cx="44.0918367"
                                  cy="46.4591837"
                                  r="4.73469388"
                                ></circle>
                                <circle
                                  id="right-eye"
                                  fill="#000000"
                                  fill-rule="nonzero"
                                  cx="71.9081633"
                                  cy="46.4591837"
                                  r="4.73469388"
                                ></circle>
                                <path
                                  d="M52.9693878,45.5714286 C52.9693878,48.1863278 55.2216695,50.3061224 58,50.3061224 C60.7783305,50.3061224 63.0306122,48.1863278 63.0306122,45.5714286"
                                  id="month"
                                  stroke="#000000"
                                  stroke-width="2.5"
                                  stroke-linecap="round"
                                ></path>
                                <g
                                  id="left-ear"
                                  transform="translate(16.867347, 27.816327)"
                                >
                                  <path
                                    d="M8.50342442,0.0863375259 C9.91857046,0.286175668 11.1461997,0.798351267 12.1319865,1.5504265 C13.1177733,2.30250175 13.8617176,3.29447664 14.309494,4.45391321 C14.7572701,5.61334978 14.9088781,6.94024834 14.7099923,8.36217071 L14.5288878,9.65696686 C14.3300019,11.0788892 13.8202671,12.3123968 13.071776,13.302904 C12.3232849,14.2934111 11.3360374,15.0409178 10.1821262,15.4908383 C9.02821495,15.9407585 7.70764003,16.0930925 6.29249399,15.8932544 C4.87734794,15.6934163 3.64971867,15.1812405 2.66393187,14.4291654 C1.67814507,13.6770901 0.93420071,12.6851152 0.486424508,11.5256786 C0.0386483057,10.366242 -0.112959744,9.0393435 0.0859260681,7.61742112 L0.267030596,6.32262498 C0.465916407,4.9007026 0.975651203,3.667195 1.72414235,2.67668787 C2.4726335,1.6861807 3.459881,0.93867404 4.6137922,0.488753688 C5.76770343,0.0388333657 7.08827838,-0.113500645 8.50342442,0.0863375259 Z"
                                    id="ear"
                                    stroke="#000000"
                                    stroke-width="2.5"
                                    fill="#FFFFFF"
                                    fill-rule="nonzero"
                                  ></path>
                                  <line
                                    x1="6.37952543"
                                    y1="5.0274423"
                                    x2="6.31931734"
                                    y2="9.34663542"
                                    id="Path-3"
                                    stroke="#D8D8D8"
                                    stroke-width="3.25510204"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    transform="translate(6.349421, 7.187039) rotate(8.000000) translate(-6.349421, -7.187039) "
                                  ></line>
                                </g>
                                <g
                                  id="right-ear"
                                  transform="translate(84.336735, 27.816327)"
                                >
                                  <path
                                    d="M6.2618911,0.0841025481 C4.85094235,0.278035669 3.62831666,0.773308529 2.64727023,1.50080271 C1.6662238,2.22829697 0.926756924,3.18801232 0.482125803,4.31083077 C0.0374943827,5.43364922 -0.112301284,6.71957077 0.0859944004,8.09947658 L0.33833548,9.85547156 C0.536631465,11.2353777 1.04304614,12.4311036 1.78690638,13.3905656 C2.53076662,14.3500274 3.51207212,15.073225 4.66015005,15.5080737 C5.80822797,15.9429225 7.12307803,16.0894225 8.53402708,15.8954892 C9.94497612,15.7015561 11.1676018,15.2062832 12.1486482,14.4787892 C13.1296944,13.7512949 13.8691612,12.7915795 14.3137927,11.6687611 C14.7584241,10.5459426 14.9082198,9.26002107 14.7099238,7.88011526 L14.457583,6.12412028 C14.259287,4.74421417 13.7528723,3.54848824 13.0090121,2.5890262 C12.2651519,1.62956451 11.2838461,0.906367009 10.1357681,0.471518224 C8.9876902,0.0366694379 7.67284015,-0.109830602 6.2618911,0.0841025481 Z"
                                    id="Rectangle"
                                    stroke="#000000"
                                    stroke-width="2.5"
                                    fill="#FFFFFF"
                                    fill-rule="nonzero"
                                  ></path>
                                  <line
                                    x1="8.46951223"
                                    y1="5.02744227"
                                    x2="8.40930409"
                                    y2="9.34663539"
                                    id="Path-3"
                                    stroke="#D8D8D8"
                                    stroke-width="3.25510204"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    transform="translate(8.439408, 7.187039) scale(-1, 1) rotate(8.000000) translate(-8.439408, -7.187039) "
                                  ></line>
                                </g>
                                <g
                                  id="logo"
                                  transform="translate(32.255102, 58.591837)"
                                  fill-rule="nonzero"
                                >
                                  <path
                                    d="M25.744898,0 C11.5257474,0 0,11.526868 0,25.744898 C0,39.962928 11.5268702,51.4897959 25.744898,51.4897959 C39.9629259,51.4897959 51.4897959,39.962928 51.4897959,25.744898 C51.4897959,11.526868 39.9646053,0 25.744898,0 Z"
                                    id="Path"
                                    fill="#07A6B3"
                                  ></path>
                                  <path
                                    d="M25.744898,0 C11.5257474,0 0,11.526868 0,25.744898 C0,39.962928 11.5268702,51.4897959 25.744898,51.4897959 C39.9629259,51.4897959 51.4897959,39.962928 51.4897959,25.744898 C51.4897959,11.526868 39.9646053,0 25.744898,0 Z"
                                    id="Path"
                                    stroke="#000000"
                                    stroke-width="2.5"
                                  ></path>
                                  <path
                                    d="M10.5747318,19.933241 L17.5712648,22.9090435 C17.54866,23.0066523 17.5198385,23.1347351 17.4876233,23.278616 C17.2782827,23.9600935 17.155078,24.6650236 17.1208711,25.3770231 C17.1057594,25.5181127 17.1004708,25.6600752 17.1050536,25.8018938 L17.1112704,25.8995026 L17.1112704,25.8995026 C17.1426831,28.5013969 18.3546872,30.9485523 20.4064807,32.5528721 C22.4582839,34.1571827 25.1282665,34.7453775 27.6656853,34.1520604 L31.6428772,40.8952791 C29.7702781,41.6410616 27.77227,42.0229311 25.7562013,42.0203956 C16.987505,42.0203956 9.46938776,34.9228115 9.46938776,26.1652602 C9.46938776,24.1091733 9.8609994,21.7359824 10.5747318,19.933241 Z M25.7578933,9.46940335 C34.5265898,9.46940335 42.0204082,16.6984625 42.0204082,25.4537595 C42.0188219,27.6680417 41.7505206,29.8773667 40.9528869,31.8041756 L40.7853878,32.1843878 L23.0256268,24.6711529 L34.4175299,24.6215044 C34.0083913,20.4957866 29.7616442,17.1419515 25.7567718,17.1419515 C24.8485591,17.1411745 23.9465535,17.2914341 23.087785,17.5865722 L19.1462021,10.9048581 C21.2206294,9.95587716 23.4761075,9.46620246 25.7578933,9.46940335 Z"
                                    id="Combined-Shape"
                                    fill="#FFFFFF"
                                  ></path>
                                </g>
                                <g
                                  id="hand"
                                  transform="translate(37.383915, 95.943703) rotate(-1.000000) translate(-37.383915, -95.943703) translate(27.883915, 84.943703)"
                                  fill="#FFFFFF"
                                  fill-rule="nonzero"
                                  stroke="#000000"
                                  stroke-width="2.5"
                                >
                                  <path
                                    d="M2.49642257,16.0564812 L0.670687359,10.6382004 C-0.719178706,6.51345957 1.54691405,2.05927254 5.73214719,0.689495241 L5.82626625,0.659328139 L5.82626625,0.659328139 C10.0961729,-0.680484311 14.6654895,1.61250088 16.0777701,5.80376189 L17.9035053,11.2220427 C19.2933714,15.3467835 17.0272786,19.8009706 12.8420455,21.1707479 L12.7479264,21.200915 L12.7479264,21.200915 C8.47801977,22.5407274 3.90870315,20.2477422 2.49642257,16.0564812 Z"
                                    id="Rectangle-Copy-3"
                                  ></path>
                                  <line
                                    x1="12.1092443"
                                    y1="9.54323997"
                                    x2="12.1513308"
                                    y2="3.32969571"
                                    id="Line-Copy-2"
                                    stroke-linecap="round"
                                    transform="translate(12.130288, 6.436468) rotate(-108.000000) translate(-12.130288, -6.436468) "
                                  ></line>
                                  <line
                                    x1="14.307877"
                                    y1="14.6120534"
                                    x2="14.2684898"
                                    y2="8.39789246"
                                    id="Line-Copy-3"
                                    stroke-linecap="round"
                                    transform="translate(14.288183, 11.504973) rotate(-108.000000) translate(-14.288183, -11.504973) "
                                  ></line>
                                </g>
                                <g
                                  id="hand"
                                  transform="translate(78.680251, 95.943703) scale(-1, 1) rotate(-1.000000) translate(-78.680251, -95.943703) translate(69.180251, 84.943703)"
                                  fill="#FFFFFF"
                                  fill-rule="nonzero"
                                  stroke="#000000"
                                  stroke-width="2.5"
                                >
                                  <path
                                    d="M2.49642257,16.0564812 L0.670687359,10.6382004 C-0.719178706,6.51345957 1.54691405,2.05927254 5.73214719,0.689495241 L5.82626625,0.659328139 L5.82626625,0.659328139 C10.0961729,-0.680484311 14.6654895,1.61250088 16.0777701,5.80376189 L17.9035053,11.2220427 C19.2933714,15.3467835 17.0272786,19.8009706 12.8420455,21.1707479 L12.7479264,21.200915 L12.7479264,21.200915 C8.47801977,22.5407274 3.90870315,20.2477422 2.49642257,16.0564812 Z"
                                    id="Rectangle-Copy-3"
                                  ></path>
                                  <line
                                    x1="12.1092443"
                                    y1="9.54323997"
                                    x2="12.1513308"
                                    y2="3.32969571"
                                    id="Line-Copy-2"
                                    stroke-linecap="round"
                                    transform="translate(12.130288, 6.436468) rotate(-108.000000) translate(-12.130288, -6.436468) "
                                  ></line>
                                  <line
                                    x1="14.307877"
                                    y1="14.6120534"
                                    x2="14.2684898"
                                    y2="8.39789246"
                                    id="Line-Copy-3"
                                    stroke-linecap="round"
                                    transform="translate(14.288183, 11.504973) rotate(-108.000000) translate(-14.288183, -11.504973) "
                                  ></line>
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                    </svg>
                  </span>
                </div>
                <span
                  className="d-none d-md-flex"
                  style={{
                    border: "1px solid #5c5c5c",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "40px",
                    height: "40px",
                    borderRadius: "50%",
                    margin: "15px auto 0",
                  }}
                >
                  OR
                </span>
                <button
                  type="button"
                  id="nftButton"
                  value="nftButton"
                  onClick={(ev) => openRezerPay(ev, nftList[0])}
                  className="btn rounded-pill btn-black btn-block flex-grow-1 my-3"
                >
                  Pay in &#8377;
                </button>
                <button
                  className="btn btn-outline-black flex-grow-1"
                  onClick={handleCloseNFTWithTicket}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

    </>
  );
};
const mapStateToProps = (state: any) => {
  return {
    UserReducer: state.UserReducer,
    LoginUserIdReducer: state.LoginUserIdReducer,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    loading: (data: any) => dispatch(Loading(data)),
  };
};

const connectedNavBar = connect(mapStateToProps, mapDispatchToProps)(NFTSingle);
export { connectedNavBar as NFTSingle };
// export default NFTSingle;
