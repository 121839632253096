import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { getAllData } from "../redux/actions";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import LivestreamFaq from "./livestream-faq";
import PastEventsFaq from "./PastEventsFaq";
import NFTsFaq from "./NFTsFaq";
import CreatorFaq from "./CreatorFaq";
import "..//scss/pages/_faq-page.scss"
// import "../pages/Faq.css"
// import Sonnet from '../../components/Sonnet';
const Faq = () => {
  let { type } = useParams<{ id: string; type: string }>();
  const [pastEvents, setpastEvents] = useState<any[] | []>([]);


  // useEffect(() => {
  //       if (sessionStorage.getItem("isLogin")) {
  //         dispatch(getAllData());

  //         console.log("window.localStorage in user training", window.localStorage);
  //       } else {
  //         console.log("Looooooooooooogin");
  //       }
  //     }, []);
  useEffect(() => {
    ;
  }, []);
  return (
    <>
      <div className="root_data"  >
        <h1 style={{ textAlign: "center" }}>FAQs</h1>
        <ul className="nav nav-pills tab-rounded alignment" id="pills-tab" role="tablist" style={{ justifyContent: "center", width: "auto", alignItems: "center",flexWrap: "nowrap"}}>
        <li className="nav-item" role="presentation">
            <button
              className={`${type === "creatorfaq" ? "" : "active" }`}
              id="pills-lsfaq-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-lsfaq"
              type="button"
              role="tab"
              aria-controls="pills-lsfaq"
              aria-selected="false"
            >
              General
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button
              className={`${type == "creatorfaq" ? "active" : ""}`}
              id="pills-creatorfaq-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-creatorfaq"
              type="button"
              role="tab"
              aria-controls="pills-creatorfaq"
              aria-selected="false"
            >
              Creator
            </button>
          </li>
          {/* <li className="nav-item" role="presentation">
            <button
              className={`${type == "nftfaq" ? "active" : ""}`}
              id="pills-nftfaq-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-nftfaq"
              type="button"
              role="tab"
              aria-controls="pills-nftfaq"
              aria-selected="false"
            >
              NFTs
            </button>
          </li> */}
        
          {/* <li className="nav-item" role="presentation">
            <button
              className={`${type == "pefaq" ? "active" : ""}`}
              id="pills-pastevents-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-pastevents"
              type="button"
              role="tab"
              aria-controls="pills-pastevents"
              aria-selected="true"
            >
              PastEvents
            </button>
          </li> */}

          {/* <li className="nav-item" role="presentation">
            <button
              className={`${type == "creatorfaq" ? "active" : ""}`}
              id="pills-creatorfaq-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-creatorfaq"
              type="button"
              role="tab"
              aria-controls="pills-creatorfaq"
              aria-selected="false"
            >
              Creator
            </button>
          </li> */}
        </ul>
        <div className="tab-content mytab-content" style={{marginTop:"50px"}} id="myTabContent">
          {/* <div
            className="tab-pane fade "
            id="pills-nftfaq"
            role="tabpanel"
            aria-labelledby="pills-nftfaq-tab"
          >
            <NFTsFaq />
          </div> */}
           <div
            className={`${type === "creatorfaq" ? "tab-pane fade" : "tab-pane fade show active"}`}
            id="pills-lsfaq"
            role="tabpanel"
            aria-labelledby="pills-lsfaq-tab"
          >
          
            <LivestreamFaq />
          </div>
          <div
            className={`${type === "creatorfaq" ? "tab-pane fade show active" : ""}`}
            id="pills-creatorfaq"
            role="tabpanel"
            aria-labelledby="pills-creatorfaq-tab"
          >
            <CreatorFaq />
          </div>
         
          {/* <div
            className="tab-pane fade"
            id="pills-pastevents"
            role="tabpanel"
            aria-labelledby="pills-pastevents-tab"
          >
            <PastEventsFaq />
          </div> */}
        </div>
        {/* <div className="row mb-5">
            {getState !== undefined && getState.length ? (
             getState.map((x: any, index: number) => {
                return (
                  <>
                    {
                      <div className="col-lg-4 col-sm-6 mb-4">
                      { x.faqtype === "NFTs" && (
                        <PastEventsFaq data={x}/>
                        )}
                      </div>
                    }
                  </>
                );
              })
            ) : (
              <> Failed</>
            )}
                </div> */}
      </div>
    </>
  );
};

export default Faq;
