import React from 'react';

import { Switch, Route, Redirect } from "react-router";

import { ClubsPage } from "../pages/clubs";
import { ClubPaymentPage } from "../pages/clubs/club-payment";
import { ClubSingupPage } from "../pages/clubs/club-signup";
import { ClubSingle } from "../pages/clubs/club-single";

import { DashboardPage } from "../pages/dashboard";
import { MySubscriptionsPage } from "../pages/dashboard/my-subscriptions";

// Livestream Pages
import { LivestreamDetail } from "../components/livestream-detail/livestream-detail";
import { LivestreamListing } from "../components/livestream-detail/livestream-listing";

import HomePage from "../pages/home";
import PublicRoute from "./public-route";
import PublicLayout from "../layout/public-layout";
import { MyProfileSettingsPage } from "../pages/dashboard/my-profile";
import { LivestreamPage } from "../pages/livestreams/livesteam";
import { GoLivestreamPage } from "../pages/livestreams/go-livestream";
import allClubs from "../components/all-clubs/all-clubs";
import { NFT } from "../components/nft/nft-listing";
import { CreateLivestream } from "../pages/clan-world/livestream/create-a-livestream";
import { ViewAllUpcomingLivestream } from "../pages/clan-world/livestream/view-all-upcoming-livestream";
import { PurchasedTicketsPage } from "../pages/dashboard/purchased-tickets";
import { CreatorLivestream } from "../pages/clan-world/livestream/creator-livestream";
import MoreLivestream from "../pages/clan-world/livestream/more-livestream";
import SignupProfile from "../pages/clan-world/signup-profile";
import LivestreamEnded from "../pages/livestreams/livestream-ended";
import LivestreamSoon from "../pages/livestreams/livestream-soon";
import LivestreamOrderSummary from "../pages/livestreams/livestream-order-summary";
import PaymentConfirmation from "../pages/clan-world/mailers/payment-confirmation";
import { NFTSingle } from "../components/nft/nft-single";
import CreateNFT from "../components/nft/create-nft";
import { LivestreamSoonPrivate } from "../pages/livestreams/livestream-soon-private";
import PrivacyPolicy from "../pages/privacy-policy";
import RefundPolicy from "../pages/refund-policy";
import TermsConditions from "../pages/terms-conditions";
import AboutUs from "../pages/about-us";
import { BrowserView } from "react-device-detect";
import Support from "../pages/support";
import Faq from "../pages/Faq";
import { Login } from "../components/form-popups/login";
import ThankyouPage from "../components/ThankyouPage";
import Admin from '../pages/dashboard/purchased-tickets/Admin';
import AdminRoutes from '../pages/dashboard/purchased-tickets/AdminRoutes';
// import RedirectPage from "../pages/Redirect"
const Routes = () => {
  return (

    <Switch>
      <Route path="/home" component={HomePage}></Route>
      <Route path="/dashboard" component={DashboardPage}></Route>
      <Route path="/clubs/:id" component={ClubsPage}></Route>
      <Route path="/clubs" component={ClubsPage}></Route>
      <Route
        exact
        path="/club-detail/:id/:club_id"
        component={ClubSingle}
      ></Route>
      <Route exact path="/clubsingup" component={ClubSingupPage}></Route>
      <Route exact path="/clubpayment" component={ClubPaymentPage}></Route>
      {/* <Route path="/mylivestreams" component={MyLivestreamsPage}></Route> */}
      <Route path="/subscriptions" component={MySubscriptionsPage}></Route>
      <Route path="/purchasedtickets" component={PurchasedTicketsPage}></Route>
      <PublicRoute
        path="/livestream-listing"
        layout={PublicLayout}
        component={LivestreamListing}
      />

      <Route exact path="/livestream/:id" component={LivestreamPage}></Route>
      <PublicRoute
        path="/golivestream/:id/:club_id"
        component={GoLivestreamPage}
        layout={PublicLayout}
      ></PublicRoute>
      <PublicRoute
        path="/allClubs"
        layout={PublicLayout}
        component={allClubs}
      />
      <Route
        path="/livestreams/create-livestream/:id"
        component={CreateLivestream}
      ></Route>
      <PublicRoute
        path="/livestreams/view-all-upcoming"
        component={ViewAllUpcomingLivestream}
        layout={PublicLayout}
      />
      <PublicRoute
        path="/livestreams/creator-livestream"
        component={CreatorLivestream}
        layout={PublicLayout}
      />
      <PublicRoute
        path="/livestreams/livestream-listing"
        component={LivestreamListing}
        layout={PublicLayout}
      />
      <PublicRoute
        path="/livestreams/livestream-detail/:id"
        layout={PublicLayout}
        component={LivestreamDetail}
      />
      <PublicRoute
        path="/signup/laststep"
        layout={PublicLayout}
        component={SignupProfile}
      />
      <Route
        path="/livestreams/livestream-ended"
        component={LivestreamEnded}
      ></Route>
      <PublicRoute
        path="/livestreams/livestream-soon/:id"
        component={LivestreamSoon}
        layout={PublicLayout}
      ></PublicRoute>
      <Route
        path="/livestreams/livestream-soon-private/:ticket_id"
        component={LivestreamSoonPrivate}
      ></Route>
      <PublicRoute
        path="/nft/nft-listing"
        layout={PublicLayout}
        component={NFT}
      />
      <PublicRoute
        path="/nft/nft-single/:id/:collection/:counter"
        layout={PublicLayout}
        component={NFTSingle}
      />
      <PublicRoute
        path="/nft/create-nft/"
        component={CreateNFT}
        layout={PublicLayout}
      />
      <PublicRoute
        path="/livestreams/more-livestream/:id/:type"
        component={MoreLivestream}
        layout={PublicLayout}
      />
      <PublicRoute  
        path="/admin"
        component={AdminRoutes}
        layout={PublicLayout}
        ></PublicRoute>
      <PublicRoute
        path="/privacy-policy"
        component={PrivacyPolicy}
        layout={PublicLayout}
      />
      <PublicRoute
        path="/refund-policy"
        component={RefundPolicy}
        layout={PublicLayout}
      />
      <PublicRoute
        path="/terms-conditions"
        component={TermsConditions}
        layout={PublicLayout}
      />
      <PublicRoute
        path="/about"
        component={AboutUs}
        layout={PublicLayout}
      /> 
      {/* //support page // create-a-livestream page*/}
      <PublicRoute
        path="/support"
        component={Support}
        layout={PublicLayout}
      />
      {/* faq */}
       <PublicRoute
        path="/faq/:type"
        component={Faq}
        layout={PublicLayout}
      />
      {/* faq */}
       <PublicRoute
        path="/faq"
        component={Faq}
        layout={PublicLayout}
      />
        {/* <PublicRoute
        path="/redirect"
        component={RedirectPage}
        layout={PublicLayout}
      /> */}
      <PublicRoute
        path="/login"
        component={Login}
        layout={PublicLayout}
      />
      <PublicRoute
        path="/welcome-page/:id"
        component={ThankyouPage}
        layout={PublicLayout}
      />
      <Route
        path="/payment-confirmation"
        component={PaymentConfirmation}
      ></Route>
      <Route
        path="/livestreams/ordersummary"
        component={LivestreamOrderSummary}
      ></Route>
      <Route component={HomePage}></Route>
    </Switch>
  );
};

export default Routes;
