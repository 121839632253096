import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import bannerImage from "../../assets/img/nft-banner.jpeg";
import bannerImage1 from "../../assets/img/banner_image_2.jpg";
import bannerImage2 from "../../assets/img/banner_image_3.jpg";
import mobBannerImage2 from "../../assets/img/banner_image_4.jpg";
import bannerImage3 from "../../assets/img/carryminati.jpg";
import { Link } from "react-router-dom";
import { HomePageData } from "../../data/data";
import { UserService } from "../../app/service/user.service";

interface Props {
  title: string;
  subtitle: string;
  BannerImage: string;
  scrollDown?: any;
}

const IntroSection: React.FC<Props> = ({
  title,
  subtitle,
  BannerImage,
  scrollDown,
}) => {
  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: false,
    adaptiveHeight: true,
  };
  const [bannerData, setBannerData] = useState<any>();

  useEffect(() => {
    getBannerImages();
  }, []);
  const getBannerImages = () => {
    const title = 'HOME_BANNER';
    UserService.getBannerInfo(title).then(res => {
      if (res) {
        setBannerData(res[0]);
      }
    });
  }
  return (
    // <section className="intro bg-img" style={{ backgroundImage: `url(${BannerImage})` }}>
    //     <div className="container-fluid">
    //         <h1 className="intro-title"><span>{title}</span></h1>
    //         <div className="intro-subtitle">{subtitle}</div>
    //     </div>
    //     <a onClick={scrollDown} className="scroll-down-sec text-decoration-none text-white">
    //         <div className="mouse"></div>
    //         <span>Scroll Down</span>
    //     </a>
    // </section>
    <>
      {/* <section
        className="intro bg-img"
        style={{ backgroundImage: `url(${BannerImage})` }}
      >
        <div className="container-fluid">
          <h1 className="intro-title">
            <span>{title}</span>
          </h1>
          <div className="intro-subtitle">{subtitle}</div>
        </div>
        <a
          onClick={scrollDown}
          className="scroll-down-sec text-decoration-none text-white"
        >
          <div className="mouse"></div>
          <span>Scroll Down</span>
        </a>
      </section> */}

      <Slider className="intro-slider d-none d-md-block" {...settings}>
        <div className="intro-slider-multi">
          {bannerData && bannerData?.data.map((intro: any) => {
            return (
              <div className="intro-slider-multi-container" key={intro._id}>
                <div className="intro-slider-multi-img">
                  <span
                    className="intro-slider-multi-img-cont"
                    style={{
                      backgroundImage: `url("${intro?.livestreamImage}")`,
                    }}
                  ></span>
                  <div className="intro-slider-multi-img-text">
                    <h3>
                      <a href={intro.url} title={intro.name}>
                        {intro.name}
                      </a>
                    </h3>
                    <strong>{intro.livestreamDate}</strong>
                    <p>{intro.livestreamDescription}</p>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        {/* <div className="intro-slider-single">
          <div className="intro-slider-single-left">
            <h2>NFT Market</h2>

            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Pellentesque consequat pellentesque li onsequat pellentesque li
              gula vitae cursus. Morbi pretium orci quis dignissim convallis.
            </p>
            <Link to="/nft/nft-listing" className="btn btn-white">
              Explore
            </Link>
          </div>

          <div className="intro-slider-single-right">
            <div className="intro-slider-single-right-img">
              <img src={bannerImage} alt="" />
            </div>
          </div>
        </div> */}
      </Slider>
      {/* Mobile Slider */}
      <Slider className="intro-slider d-block d-md-none" {...settings}>
        {bannerData && bannerData?.data.map((intro:any) => {
          return (
            <div className="intro-slider-single-mob" key={intro._id}>
              <img
                src={`${intro?.livestreamImage}`}
                className="intro-slider-multi-img-cont"
                alt=""
              />
              <div className="intro-slider-multi-img-text">
                <h3>
                  <a href={intro.url} title={intro.name}>
                    {intro.name}
                  </a>
                </h3>
                <strong>{intro.livestreamDate}</strong>
                <p>{intro.livestreamDescription}</p>
              </div>
            </div>
          );
        })}
        <div className="intro-slider-single">
          <div className="intro-slider-single-left">
            <h2>
              <Link to="/nft/nft-listing">NFT Market</Link>
            </h2>

            <p className="d-none d-md-block">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Pellentesque consequat pellentesque li onsequat pellentesque li
              gula vitae cursus. Morbi pretium orci quis dignissim convallis.
            </p>
            <Link
              to="/nft/nft-listing"
              className="btn btn-white d-none d-md-block"
            >
              Explore
            </Link>
          </div>

          <div className="intro-slider-single-right">
            <div className="intro-slider-single-right-img">
              <img src={bannerImage} alt="" />
            </div>
          </div>
        </div>
      </Slider>
    </>
  );
};

export default IntroSection;
