import React, { useEffect, useState } from "react";
import FormBuilder from "../../../components/form-builder";
import { toast } from "react-toastify";
import { Clubs } from "../../../app/model/clubs";
import { ClubService } from "../../../app/service/club.service";
import { connect, useDispatch } from "react-redux";
import { GetClub, Loading, SaveClub } from "../../../redux/actions";
import { AppLoader } from "../../../components/loader";
import {
  FormControlError,
  FormField,
  FormValidators,
} from "../../../components/form-builder/model/form-field";
import {
  FormValidator,
  GetControlIsValid,
} from "../../../components/form-builder/validations";
import { useHistory } from "react-router-dom";
import { CustomUploadModal } from "../../../components/custom-upload-modal";
import Multiselect from "multiselect-react-dropdown";

interface Props {
  UserReducer?: any;
  loading?: (data: any) => void;
  saveClub?: (data: any) => void;
  getClub?: (data: any) => void;
  SaveClubReducer?: any;
  save: any;
}

const MyClubDetailsPage: React.FC<Props> = (props: Props) => {
  const [myclubs, setMyClubs] = useState<Clubs[] | []>([]);
  const [genere, setGenere] = useState<any[] | []>([]);
  const [selectGenere, setSelectGenere] = useState("");
  const [subGenere, setSubGenere] = useState<any[] | []>([]);
  const [selectSubGenre, setSelectSubGenre] = useState<any>();
  const [myclubsData, setMyClubData] = useState<any>({});
  const [isLoading, setLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(true);
  const [errorMsg, setErrorMsg] = useState("");
  const [introVideo, setIntroVideo] = useState("");
  const [converPic, setCoverPic] = useState("");
  const [showIntroVideo, setShowIntroVideo] = useState("");
  const [showConverPic, setShowCoverPic] = useState("");
  const [profilePic, setProfilePic] = useState("");
  const dispatch = useDispatch();
  let id = sessionStorage.getItem("isLogin") || "";
  const history = useHistory();

  useEffect(() => {
    if (id) {
      getMyClub(id);
    }
    ClubService.fetchGenereList().then((res) => {
      if (res) {
        const genData: any = [];
        //  res.forEach((element: any, index: number) => {
        //     genData.push({ name: element, id: index })
        // });
        setGenere([...res]);
        if (myclubs[0]?.genre) {
          setSelectGenere(myclubs[0]?.genre.split(","));
        }
      }
    });
  }, [id]);

  const getMyClub = (id: string) => {
    if (props.loading) {
      dispatch(props.loading(true));
    }
    setShowIntroVideo("");
    setShowCoverPic("");
    ClubService.getUserClubs(id).then((res) => {
      if (res) {
        if (res.length > 0) {
          setMyClubs([...res]);
          sessionStorage.setItem("clubId", res[0]?._id || "");
          setShowIntroVideo(res[0]?.intro_video);
          setShowCoverPic(res[0]?.cover_photo);
          setSelectGenere(res[0]?.genre.split(","));
          if (res[0]?.genre) {
            const genre = {
              genre_list: res[0]?.genre,
            };
            ClubService.fetchMultipleSubGenereList(genre).then((genreRes) => {
              if (genreRes) {
                setSubGenere([...genreRes]);
                setSelectSubGenre(res[0]?.sub_genre.split(","));
              }
            });
          }
          if (props.loading) {
            dispatch(props.loading(false));
          }
          if (props.saveClub) {
            dispatch(props.saveClub(res[0]));
          }
          // setTimeout(() => {
          // storeClubData(res[0]);
          // }, 000);
        } else {
          let clubData: any = sessionStorage.getItem("clubPreview") || "";
          if (clubData) {
            try {
              clubData = JSON.parse(clubData);
              setMyClubs([clubData]);
              setShowIntroVideo(clubData?.intro_video);
              setShowCoverPic(clubData?.cover_photo);
            } catch (error) {}
          }
        }
        if (props.loading) {
          dispatch(props.loading(false));
        }
      } else {
        if (props.loading) {
          dispatch(props.loading(false));
        }
        setIsEdit(!isEdit);
      }
    });
  };
  const updateFormData = (data: any) => {
    setMyClubData(data);
    storeClubData(data.value);
    const errors: FormControlError[] = FormValidator(
      formValidations,
      data.value
    );
    setValidationErrors(errors);
  };

  const storeClubData = (data: any) => {
    const clubData: Clubs = data || {};
    const clubPreviewData = {
      _id: data._id ? data._id : myclubs[0]?._id ? myclubs[0]?._id : "",
      intro_video: introVideo
        ? introVideo
        : myclubs[0]?.intro_video
        ? myclubs[0]?.intro_video
        : "",
      cover_photo: converPic
        ? converPic
        : myclubs[0]?.cover_photo
        ? myclubs[0]?.cover_photo
        : "",
      name: clubData.name
        ? clubData.name
        : myclubs[0]?.name
        ? myclubs[0]?.name
        : "",
      creations: clubData.creations
        ? clubData.creations
        : myclubs[0]?.creations
        ? myclubs[0]?.creations
        : "",
      about: clubData.about
        ? clubData.about
        : myclubs[0]?.about
        ? myclubs[0]?.about
        : "",
    };
    if (props.saveClub) {
      dispatch(props.saveClub(clubPreviewData));
    }
    sessionStorage.setItem("clubPreview", JSON.stringify(clubPreviewData));
  };

  const onSaveMyClub = () => {
    const data = myclubsData.value
      ? { ...myclubsData.value }
      : { ...myclubs[0] };
    setIsFormSubmitted(true);
    const errors: FormControlError[] = FormValidator(formValidations, data);
    setValidationErrors(errors);
    if (errors.length < 1 && selectGenere && selectSubGenre) {
      setLoading(true);
      // const data: Clubs = myclubsData.value;
      let formData = new FormData();
      formData.append(
        "name",
        data?.name ? data.name : myclubs[0]?.name ? myclubs[0]?.name : ""
      );
      formData.append(
        "creations",
        data?.creations
          ? data.creations
          : myclubs[0]?.creations
          ? myclubs[0]?.creations
          : ""
      );
      formData.append(
        "intro_video",
        introVideo
          ? introVideo
          : myclubs[0]?.intro_video
          ? myclubs[0]?.intro_video
          : ""
      );
      formData.append(
        "cover_photo",
        converPic
          ? converPic
          : myclubs[0]?.cover_photo
          ? myclubs[0]?.cover_photo
          : ""
      );
      formData.append(
        "image",
        profilePic ? profilePic : myclubs[0]?.image ? myclubs[0]?.image : ""
      );
      formData.append("owned_by", id);
      formData.append(
        "about",
        data?.about ? data.about : myclubs[0]?.about ? myclubs[0]?.about : ""
      );
      formData.append(
        "video_url",
        data?.video_url
          ? data.video_url
          : myclubs[0]?.video_url
          ? myclubs[0]?.video_url
          : ""
      );
      formData.append(
        "genre",
        selectGenere ? selectGenere : myclubs[0]?.genre ? myclubs[0]?.genre : ""
      );
      formData.append(
        "sub_genre",
        selectSubGenre
          ? selectSubGenre
          : myclubs[0]?.sub_genre
          ? myclubs[0]?.sub_genre
          : ""
      );

      if (myclubs.length > 0) {
        formData.append("_id", myclubs[0]?._id ? myclubs[0]?._id : "");
        ClubService.updateClub(formData).then((res) => {
          setIsFormSubmitted(false);
          if (!res.code) {
            getMyClub(id);
            setLoading(false);
            toast.success("Club Updated successfully");
            sessionStorage.setItem("clubId", res[0]?._id || "");
            setIsEdit(!isEdit);
          } else {
            setLoading(false);
            toast.error(res.message);
          }
          if (props.loading) {
            dispatch(props.loading(false));
          }
        });
      } else {
        ClubService.createClub(formData).then((res) => {
          setIsFormSubmitted(false);
          if (!res.code) {
            getMyClub(id);
            setLoading(false);
            toast.success("Club Created successfully");
            sessionStorage.setItem("clubId", res[0]?._id);
            setIsEdit(!isEdit);
          } else {
            setLoading(false);
            toast.error(res.message);
          }
          if (props.loading) {
            dispatch(props.loading(false));
          }
        });
      }
    }
  };

  const onUploadIntro = (event: any) => {
    handleClose();
    setShowIntroVideo("");
    if (event.target.files && event.target.files[0]) {
      setIntroVideo(event.target.files[0]);
      setShowIntroVideo(URL.createObjectURL(event.target.files[0]));
    }
  };

  const onUploadCoverPic = (event: any) => {
    handleAudioClose();
    setShowCoverPic("");
    if (event.target.files && event.target.files[0]) {
      setCoverPic(event.target.files[0]);
      setShowCoverPic(URL.createObjectURL(event.target.files[0]));
    }
  };

  const onUploadProfilePic = (event: any) => {
    if (event.target.files && event.target.files[0]) {
      setProfilePic(event.target.files[0]);
    }
  };

  const onPublishPost = () => {
    const data: Clubs = myclubsData.value;
    if (!data?._id) {
      let formData = new FormData();
      if (myclubs[0]?._id) {
        formData.append("_id", myclubs[0]?._id ? myclubs[0]?._id : "");
      }
      formData.append(
        "name",
        data?.name ? data.name : myclubs[0]?.name ? myclubs[0]?.name : ""
      );
      formData.append(
        "creations",
        data?.creations
          ? data.creations
          : myclubs[0]?.creations
          ? myclubs[0]?.creations
          : ""
      );
      formData.append(
        "intro_video",
        introVideo
          ? introVideo
          : myclubs[0]?.intro_video
          ? myclubs[0]?.intro_video
          : ""
      );
      formData.append(
        "cover_photo",
        converPic
          ? converPic
          : myclubs[0]?.cover_photo
          ? myclubs[0]?.cover_photo
          : ""
      );
      formData.append(
        "image",
        profilePic ? profilePic : myclubs[0]?.image ? myclubs[0]?.image : ""
      );
      formData.append("owned_by", id);
      formData.append(
        "about",
        data?.about ? data.about : myclubs[0]?.about ? myclubs[0]?.about : ""
      );
      formData.append(
        "video_url",
        data?.video_url
          ? data.video_url
          : myclubs[0]?.video_url
          ? myclubs[0]?.video_url
          : ""
      );
      formData.append("status", "1");
      formData.append(
        "genre",
        selectGenere ? selectGenere : myclubs[0]?.genre ? myclubs[0]?.genre : ""
      );
      formData.append(
        "sub_genre",
        selectSubGenre
          ? selectSubGenre
          : myclubs[0]?.sub_genre
          ? myclubs[0]?.sub_genre
          : ""
      );

      ClubService.publishClub(formData).then((res) => {
        if (res) {
          sessionStorage.setItem("clubId", res[0]?._id);
          toast.success("Club Saved & Published successfully");
          getMyClub(id);
        }
      });
    } else {
      getMyClub(id);
    }
  };

  const [validationErrors, setValidationErrors] = useState<FormControlError[]>(
    []
  );
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const formValidations = [new FormField("name", [FormValidators.REQUIRED])];

  const getInputValid = (control: string) => {
    const value = GetControlIsValid(validationErrors, control);
    return value;
  };

  const onPreview = () => {
    const data = myclubsData.value
      ? { ...myclubsData.value }
      : { ...myclubs[0] };
    setIsFormSubmitted(true);
    const errors: FormControlError[] = FormValidator(formValidations, data);
    setValidationErrors(errors);
    if (errors.length < 1 && selectGenere && selectSubGenre) {
      setLoading(true);
      // const data: Clubs = myclubsData.value;
      let formData = new FormData();
      formData.append(
        "name",
        data?.name ? data.name : myclubs[0]?.name ? myclubs[0]?.name : ""
      );
      formData.append(
        "creations",
        data?.creations
          ? data.creations
          : myclubs[0]?.creations
          ? myclubs[0]?.creations
          : ""
      );
      formData.append(
        "intro_video",
        introVideo
          ? introVideo
          : myclubs[0]?.intro_video
          ? myclubs[0]?.intro_video
          : ""
      );
      formData.append(
        "cover_photo",
        converPic
          ? converPic
          : myclubs[0]?.cover_photo
          ? myclubs[0]?.cover_photo
          : ""
      );
      formData.append(
        "image",
        profilePic ? profilePic : myclubs[0]?.image ? myclubs[0]?.image : ""
      );
      formData.append("owned_by", id);
      formData.append(
        "about",
        data?.about ? data.about : myclubs[0]?.about ? myclubs[0]?.about : ""
      );
      formData.append(
        "video_url",
        data?.video_url
          ? data.video_url
          : myclubs[0]?.video_url
          ? myclubs[0]?.video_url
          : ""
      );
      formData.append(
        "genre",
        selectGenere ? selectGenere : myclubs[0]?.genre ? myclubs[0]?.genre : ""
      );
      formData.append(
        "sub_genre",
        selectSubGenre
          ? selectSubGenre
          : myclubs[0]?.sub_genre
          ? myclubs[0]?.sub_genre
          : ""
      );
      if (myclubs.length > 0) {
        formData.append("_id", myclubs[0]?._id ? myclubs[0]?._id : "");
        ClubService.updateClub(formData).then((res) => {
          setIsFormSubmitted(false);
          if (!res.code) {
            getMyClub(id);
            setLoading(false);
            sessionStorage.setItem("clubId", res[0]?._id || "");
            sessionStorage.setItem("previewFrom", "details");
            history.push(`/dashboard/preview/${res[0]?._id}`);
            setIsEdit(!isEdit);
          } else {
            setLoading(false);
            toast.error(res.message);
          }
          if (props.loading) {
            dispatch(props.loading(false));
          }
        });
      } else {
        ClubService.createClub(formData).then((res) => {
          setIsFormSubmitted(false);
          if (!res.code) {
            getMyClub(id);
            setLoading(false);
            sessionStorage.setItem("clubId", res[0]?._id);
            history.push(`/dashboard/preview/${res[0]?._id}`);
            setIsEdit(!isEdit);
          } else {
            setLoading(false);
            toast.error(res.message);
          }
          if (props.loading) {
            dispatch(props.loading(false));
          }
        });
      }
    }
  };

  const onUnPublishPost = () => {
    ClubService.unPublishClub(myclubs[0]).then((res) => {
      if (res) {
        getMyClub(id);
      }
    });
  };

  const onSelectGenre = (event: any) => {
    if (event.target.value) {
      setSelectGenere(event.target.value);
      ClubService.fetchSubGenereList(event.target.value).then((res) => {
        if (res) {
          setSubGenere([...res]);
        }
      });
    }
  };
  const onSelectSubGenre = (event: any) => {
    if (event.target.value) {
      setSelectSubGenre(event.target.value);
    }
  };
  const [show, setShow] = useState(false);
  const [showAudio, setShowAudio] = useState(false);

  const handleClose = () => setShow(false);
  const handleAudioClose = () => setShowAudio(false);
  const handleVideoModal = () => setShow(true);
  const handleAudioModal = () => setShowAudio(true);

  const onSelect = (selectedList: any, selectedItem: any) => {
    setSelectGenere(selectedList);
    const data = {
      genre_list: selectedList.toString(),
    };
    ClubService.fetchMultipleSubGenereList(data).then((res) => {
      if (res) {
        setSubGenere([...res]);
      }
    });
  };

  const onRemove = (selectedList: any, removedItem: any) => {
    const data = {
      genre_list: selectedList.toString(),
    };
    ClubService.fetchMultipleSubGenereList(data).then((res) => {
      if (res) {
        if (res.length > 0) {
          var c = res.filter(function (objFromA: any) {
            return !selectSubGenre.find(function (objFromB: any) {
              return objFromA === objFromB;
            });
          });
          setSelectSubGenre(c);
          setSubGenere([...res]);
        } else {
          setSubGenere([...res]);
          setSelectSubGenre("");
        }
      }
    });
  };
  const onSelectSubGenreData = (selectedList: any, selectedItem: any) => {
    setSelectSubGenre(selectedList);
  };
  const onRemoveSubGenre = (selectedList: any, removedItem: any) => {};

  return (
    <>
      <div className="tab-content" id="pills-tabContent">
        {/*
                <AppLoader /> */}
        <div
          className="tab-pane active mb-4"
          id="details"
          role="tabpanel"
          aria-labelledby="details-tab"
        >
          <FormBuilder onUpdate={updateFormData}>
            <form>
              <div className="row">
                <div className="col-md-4">
                  <div className="form-floating mb-3">
                    <input
                      className="form-control"
                      type="text"
                      name="name"
                      placeholder="Title of the Club page"
                      defaultValue={myclubs[0]?.name}
                      disabled={isEdit}
                    />
                    {/* <label htmlFor="floatingInput">Title</label> */}
                    {isFormSubmitted && !getInputValid("name") ? (
                      <label className="clr-red" htmlFor="packageName">
                        Enter title
                      </label>
                    ) : (
                      <label htmlFor="packageName">Title</label>
                    )}
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-floating select mb-3">
                    
                    {isEdit ? (
                      <input
                        type="text"
                        className="form-select form-control"
                        disabled
                        value={selectGenere}
                      />
                    ) : (
                        <>
                      <Multiselect
                        className=""
                        options={genere}
                        selectedValues={selectGenere}
                        onSelect={onSelect}
                        onRemove={onRemove}
                        selectionLimit={2}
                        isObject={false}
                        avoidHighlightFirstOption={true} // Property name to display in the dropdown options
                      />
                      </>
                    )}
                    {isFormSubmitted && selectGenere ? (
                      <label className="clr-red" htmlFor="packageName">
                        Select Genre
                      </label>
                    ) : (
                      <label htmlFor="packageName">Genre</label>
                    )}
                    {/* <select
                                            className="form-select"
                                            id="genere"
                                            aria-label="genre"
                                            name="genre"
                                            disabled={isEdit}
                                            onChange={(event) => onSelectGenre(event)}
                                            value={selectGenere}
                                        >
                                            <option>Select Genere </option>
                                            {genere.map((element: any, gIndex: number) => { return < option key={gIndex} value={element}>{element}</option> })}
                                        </select>
                                        <label htmlFor="genere">Genre</label> */}
                  </div>
                </div>
                <div className="col-md-4">
                    <div className="form-floating mb-3">
                  
                  {/* <label>Sub Genre</label> */}
                  {isEdit ? (
                    <input
                      type="text"
                      className="form-select"
                      disabled
                      value={selectSubGenre}
                    />
                  ) : (
                    <Multiselect
                      options={subGenere} // Options to display in the dropdown
                      selectedValues={selectSubGenre} // Preselected value to persist in dropdown
                      onSelect={onSelectSubGenreData} // Function will trigger on select event
                      onRemove={onRemoveSubGenre} // Function will trigger on remove event
                      isObject={false}
                      avoidHighlightFirstOption={true} // Property name to display in the dropdown options
                    />
                  )}
                  {isFormSubmitted && selectGenere ? (
                    <label className="clr-red">Select Sub Genre</label>
                  ) : (
                    <label>Sub Genre</label>
                  )}
                  </div>
                  {/* <div className="form-floating mb-3">
                                        <select
                                            className="form-select"
                                            id="subGenere"
                                            aria-label="sub_genre"
                                            name="sub_genre"
                                            disabled={isEdit}
                                            value={selectSubGenre}
                                            onChange={(event) => onSelectSubGenre(event)}
                                        >
                                            <option>Select Sub Genre </option>
                                            {subGenere.map((element: any, sgIndex: number) => { return <option key={sgIndex} value={element}>{element}</option> })}
                                        </select>
                                        <label htmlFor="subGenere">Sub Genre</label>
                                    </div> */}
                </div>
                {/* <div className="col-md-6">
                                    <div className="form-floating mb-3">
                                        <input className="form-control" type="text" name="creations"
                                            placeholder="List your creations separted by comma"
                                            defaultValue={myclubs[0]?.creations} disabled={isEdit} />
                                        <label htmlFor="creations">Creations</label>
                                    </div>
                                </div> */}
              </div>

              <div className="row"></div>
              <div className="form-floating mb-3">
                <textarea
                  className="form-control"
                  name="about"
                  placeholder="Leave a comment here"
                  style={{ height: "100px" }}
                  defaultValue={myclubs[0]?.about}
                  disabled={isEdit}
                ></textarea>
                <label htmlFor="about">Tell something about your club</label>
              </div>

              <div className="row mb-3">
                <div className="mb-3 col-md-6">
                  <label className="form-label col-md-3" htmlFor="formFileSm">
                    Intro video(optional)
                  </label>
                  <button
                    disabled={isEdit}
                    onClick={handleVideoModal}
                    type="button"
                    className="btn btn-link text-decoration-none d-inline-flex"
                  >
                    Upload
                  </button>
                  {/* <span className="d-inline-flex col-auto px-3">or</span>
                            <div className="form-floating d-inline-flex col-md-6">
                                <input
                                className="form-control"
                                id="video_url"
                                name="video_url"
                                type="text"
                                placeholder="Add URL"
                                defaultValue={myclubs[0]?.video_url}
                                />
                                <label htmlFor="addURL">Add Video URL</label>
                            </div> */}
                </div>
                <div className="mb-3 col-md-6">
                  <label className="form-label col-md-3">Cover Photo</label>
                  <button
                    disabled={isEdit}
                    onClick={handleAudioModal}
                    type="button"
                    className="btn btn-link text-decoration-none d-inline-flex"
                  >
                    Upload
                  </button>
                  {/* <span className="d-inline-flex col-auto px-3">or</span>
                            <div className="form-floating d-inline-flex col-md-6">
                                <input
                                className="form-control"
                                id="video_url"
                                name="video_url"
                                type="text"
                                placeholder="Add URL"
                                defaultValue={myclubs[0]?.video_url}
                                />
                                <label htmlFor="addURL">Add Video URL</label>
                            </div> */}
                </div>
                <div className="col-md-6">
                  {/* <label className="form-label" htmlFor="formFileSm">
                                        Intro video(optional)
                                    </label> */}
                  <div className="upload-sec upload-video custom">
                    {showIntroVideo && (
                      <video
                        key={showIntroVideo}
                        className="my_club_media"
                        controls
                        controlsList="nodownload"
                      >
                        <source src={showIntroVideo} type="video/mp4" />
                        Your browser does not support HTML video.
                      </video>
                    )}
                    {/* <div className="button_video_outer">
                                            <div className="btn_video_upload btn">
                                                <input className="upload_video_file" id="upload_video_file" type="file"
                                                    name="intro_video"
                                                    accept=".mov,.mkv, .mpg, .mp2, .mpeg,.mpe,.mpv,.m2v, .mp4,.m4p,.m4v, .avi, .wmv, .flv, .3gp, .webm"
                                                    onChange={(event) => onUploadIntro(event)} disabled={isEdit}
                                                />
                                                <i className="bi bi-cloud-arrow-up"></i>
                                                <strong>Upload Video </strong>
                                                <span>(drag and drop or browse)</span>
                                                <span className="upload-accept-filename">.mov,.mkv, .mpg, .mp2,
                                                    .mpeg,.mpe,.mpv,.m2v, .mp4,.m4p,.m4v, .avi, .wmv, .flv, .3gp,
                                                    .webm</span>
                                            </div>
                                            <div className="processing_video_bar"></div>
                                            <div className="success_video_box"></div>
                                        </div>
                                        <div className="error_video_msg upload_video_error_msg"></div>
                                        <div className="uploaded_video_view" id="uploaded_video_view">
                                            <span className="file_video_remove">X</span>
                                        </div> */}
                  </div>
                  {/* <span className="d-block mx-auto text-center mb-3 fw-bold">
                                    OR
                                </span>
                                <div className="row mb-3">
                                    <div className="col-md-12">
                                        <div className="form-floating mb-3">
                                            <input className="form-control" id="video_url" name="video_url"
                                                type="text" placeholder="Add URL" disabled={isEdit}
                                                defaultValue={myclubs[0]?.video_url} />
                                            <label htmlFor="addURL">Add Video URL</label>
                                        </div>
                                    </div>
                                </div> */}
                </div>

                <div className="col-md-6">
                  {/* <label className="form-label" htmlFor="formFileSm">
                                        Cover Photo
                                    </label> */}
                  <div className="upload-sec custom">
                    {showConverPic && (
                      <img
                        className="my_club_media"
                        src={showConverPic}
                        alt=""
                      />
                    )}
                    {/* <div className="outer-btn button_outer1">
                                            <div className="btn_upload1 upload_btn btn">
                                                <input id="upload_file1" type="file" name="cover_photo"
                                                    accept="image/jpg, image/jpeg, image/png, image/svg, image/gif"
                                                    onChange={(event) => onUploadCoverPic(event)} disabled={isEdit}
                                                />
                                                <i className="bi bi-cloud-arrow-up"></i>
                                                <strong>Upload Cover Photo </strong>
                                                <span>(drag and drop or browse)</span>
                                                <span className="upload-accept-filename">jpg, jpeg, png, svg, gif</span>
                                            </div>
                                            <div className="processing_bar"></div>
                                            <div className="success_box"></div>
                                        </div>
                                        <div className="error_msg1 upload_error_msg"></div>
                                        <div className="uploaded_file_view" id="uploaded_view1">
                                            <span className="file_remove1 remove_file">X</span>
                                        </div> */}
                  </div>
                </div>
              </div>
            </form>
            <div className="row mt-5">
              <div className="col-auto">
                {myclubs.length > 0 && myclubs[0].status === 1 && (
                  <button
                    className="btn btn-outline-orange"
                    onClick={onUnPublishPost}
                  >
                    Deactivate
                  </button>
                )}
                {myclubs.length > 0 && myclubs[0].status === 0 && (
                  <button className="btn btn-primary" onClick={onPublishPost}>
                    Activate
                  </button>
                )}
              </div>
              <div className="col-auto ms-auto">
                <a
                  className="btn btn-primary d-inline-flex"
                  onClick={onPreview}
                >
                  Preview
                </a>
                {myclubs.length <= 0 && (
                  <button
                    className="btn btn-primary ms-2 d-inline-flex"
                    onClick={onPublishPost}
                  >
                    Launch
                  </button>
                )}

                {myclubs.length > 0 && isEdit && (
                  <button
                    className="btn btn-outline-black d-inline-flex ms-2"
                    onClick={() => setIsEdit(!isEdit)}
                  >
                    Edit
                  </button>
                )}
                {!isEdit && (
                  <button
                    disabled={isLoading && !errorMsg}
                    className="btn btn-outline-black d-inline-flex mx-2"
                    onClick={onSaveMyClub}
                  >
                    {!isLoading || errorMsg ? (
                      "Save"
                    ) : (
                      <div
                        className="spinner-border text-primary spinner-border-sm"
                        role="status"
                      >
                        <span className="sr-only">Loading...</span>
                      </div>
                    )}
                  </button>
                )}
              </div>
            </div>
          </FormBuilder>
        </div>
      </div>
      {/* Custom Modal */}
      <CustomUploadModal
        show={show}
        handlemodal={handleVideoModal}
        handleClose={handleClose}
        uploadType="video"
        uploadedFile={(event) => onUploadIntro(event)}
      />
      <CustomUploadModal
        show={showAudio}
        handlemodal={handleAudioModal}
        handleClose={handleAudioClose}
        uploadType="image"
        uploadedFile={(event) => onUploadCoverPic(event)}
      />
    </>
  );
};
const mapStateToProps = (state: any) => {
  return {
    UserReducer: state.UserReducer,
    SaveClubReducer: state.SaveClubReducer,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    loading: (data: any) => dispatch(Loading(data)),
    saveClub: (data: any) => dispatch(SaveClub(data)),
    getClub: (data: any) => dispatch(GetClub(data)),
  };
};

const connectedNavBar = connect(
  mapStateToProps,
  mapDispatchToProps
)(MyClubDetailsPage);
export { connectedNavBar as MyClubDetailsPage };
