import { Route } from "react-router";

interface IPublicRouteProps {
    component:any;
    layout:any;
    path?: string; 
}


const PublicRoute: React.FC<IPublicRouteProps> = (props) => {
    const { component: Component,layout:Layout, ...rest } = props;
    return (
        <Route {...rest} 
            render={(matchProps)=>(
            <Layout {...matchProps}>
                <Component {...matchProps} />
            </Layout>
            )

        } />
    );
}

export default PublicRoute;