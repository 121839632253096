import React, { useEffect, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link, useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { SubscriptionPackages } from "../../../../app/model/subscription-packages";
import { LivestreamService } from "../../../../app/service/livestream";
import { SubscriptionPackagesService } from "../../../../app/service/subscription-packages-service";
import { S3Helper } from "../../../../app/utils/s3-helper";
import FormBuilder from "../../../../components/form-builder";
import {
  FormControlError,
  FormField,
  FormValidators,
} from "../../../../components/form-builder/model/form-field";
import {
  FormValidator,
  GetControlIsValid,
} from "../../../../components/form-builder/validations";
import { TooltipWrapper } from "../../../../components/tooltip";
import Capture from "../../../assets/img/Capture1.png";
import { CustomUploadModal } from "../../../../components/custom-upload-modal";

import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import { S3_URL } from "../../../../config/constant";

interface Props {}

export const MyLivestreamsPublicPage: React.FC<Props> = (props: Props) => {
  let { id } = useParams<{ id: string }>();
  const [isLoading, setLoading] = useState(false);
  const [myclubsData, setMyClubData] = useState<any>({});
  const club_id = sessionStorage.getItem("clubId") || "";
  const [awsInfo, setAwsInfo] = useState<any>(null);
  const [progress, setProgress] = useState(0);
  const [maxParticipants, setMaxParticipants] = useState(10);
  const [minParticipants, setMinParticipants] = useState(1);
  const [videoUrl, setVideoUrl] = useState("");
  const baseUrl = S3_URL;
  const [content, setContent] = useState("");
  const [subscriptionPackages, setSubscriptionPackages] = useState<
    SubscriptionPackages[] | []
  >([]);
  const [selectedSubscriptionPakages, setSelectedSubscriptionPakages] =
    useState<string[]>([]);
  const [showSelectedPakageDetails, setShowSelectedPakageDetails] =
    useState(-1);
  let userId = sessionStorage.getItem("isLogin") || "";
  const history = useHistory();
  const [attendeesView, setAttendeesView] = useState("");
  const [attendeesDownload, setAttendeesDownload] = useState("");
  const [attendeesViewChecked, setAttendeesViewChecked] = useState(false);
  const [attendeesDownloadChecked, setAttendeesDownloadChecked] =
    useState(false);
  const [isPaidPost, setIsPaidPost] = useState(false);
  const [selectedLivestream, setSelectedLivestream] = useState<any[] | []>([]);
  const [selectAllPackages, setSelectAllPackages] = useState(false);
  const [duration, setDuration] = useState<any[]>([]);
  const [selectedDuration, setSelectedDuration] = useState(5);

  useEffect(() => {
    durationData();
    if (club_id) {
      const data = {
        club_id: club_id,
        type: 1,
      };
      LivestreamService.getLiveStreamFolderInfo(data).then((res) => {
        if (res) {
          setAwsInfo(res);
        } else {
          toast.error(res?.message);
        }
      });
      SubscriptionPackagesService.getClubSubscriptionPackages(club_id).then(
        (res) => {
          if (res) {
            res.forEach((element) => {
              element.isChecked = false;
            });
            setSubscriptionPackages([...res]);
          }
        }
      );
    }
    if (id !== "1") {
      LivestreamService.getlivestreamById(id).then((res) => {
        if (res) {
          setSelectedLivestream(res);
          setAttendeesViewChecked(res[0]?.VIEW_LIVESTREAM ? true : false);
          setAttendeesDownloadChecked(
            res[0]?.DOWNLOAD_LIVESTREAM ? true : false
          );
          setIsPaidPost(res[0]?.PAID_POST ? true : false);
          setAttendeesView(res[0]?.VIEW_LIVESTREAM);
          setAttendeesDownload(res[0]?.DOWNLOAD_LIVESTREAM);
          setCount(res[0]?.max_participants);
          setMinParticipants(res[0]?.min_participants);
        } else {
          toast.error(res?.message);
        }
      });
    }
  }, []);

  const durationData = () => {
    const data = duration || [];
    let value = 5;
    for (let index = 1; index <= 12; index++) {
      data.push(value);
      value = value + 5;
    }
    setDuration([...data]);
  };

  const onChangeDuration = (event: any) => {
    if (event.target.value) {
      setSelectedDuration(event.target.value);
    } else {
      setSelectedDuration(5);
    }
  };
  const updateFormData = (data: any) => {
    setMyClubData(data);
    const errors: FormControlError[] = FormValidator(
      formValidations,
      data.value
    );
    setValidationErrors(errors);
  };

  const onSaveLivestream = () => {
    const data = { ...selectedLivestream[0], ...myclubsData.value };
    setIsFormSubmitted(true);
    const errors: FormControlError[] = FormValidator(formValidations, data);
    setValidationErrors(errors);
    if (
      errors.length < 1 &&
      (isPaidPost || selectedSubscriptionPakages.length > 0)
    ) {
      const bodyData = {
        club_id: club_id,
        video_url: videoUrl
          ? videoUrl
          : selectedLivestream[0]?.video_url
          ? selectedLivestream[0]?.video_url
          : "",
        user_id: userId,
        type: 1,
        type_name: "PUBLIC_LIVESTREAM",
        VIEW_LIVESTREAM: attendeesViewChecked
          ? attendeesView
          : selectedLivestream[0]?.VIEW_LIVESTREAM
          ? selectedLivestream[0]?.VIEW_LIVESTREAM
          : "",
        DOWNLOAD_LIVESTREAM: attendeesDownloadChecked
          ? attendeesDownload
          : selectedLivestream[0]?.DOWNLOAD_LIVESTREAM
          ? selectedLivestream[0]?.DOWNLOAD_LIVESTREAM
          : "",
        PAID_POST: isPaidPost,
        max_participants: maxParticipants,
        min_participants: minParticipants,
        subscription_package_id:
          selectedSubscriptionPakages.length > 0
            ? JSON.stringify(selectedSubscriptionPakages)
            : selectedLivestream[0]?.subscription_package_id
            ? JSON.stringify(selectedLivestream[0]?.subscription_package_id)
            : "",
        title: data.title
          ? data.title
          : selectedLivestream[0]?.title
          ? selectedLivestream[0]?.title
          : "",
        language: data.language
          ? data.language
          : selectedLivestream[0]?.language
          ? selectedLivestream[0]?.language
          : "",
        description: data.description
          ? data.description
          : selectedLivestream[0]?.description
          ? selectedLivestream[0]?.description
          : "",
        price: data.price
          ? data.price
          : selectedLivestream[0]?.price
          ? selectedLivestream[0]?.price
          : "",
        schedule_From: data.schedule_From
          ? data.schedule_From
          : selectedLivestream[0]?.schedule_From
          ? selectedLivestream[0]?.schedule_From
          : "",
        schedule_To: data.schedule_To
          ? data.schedule_To
          : selectedLivestream[0]?.schedule_To
          ? selectedLivestream[0]?.schedule_To
          : "",
        thumbnail: showThumnail
          ? showThumnail
          : selectedLivestream[0]?.thumbnail
          ? selectedLivestream[0]?.thumbnail
          : "",
        duration: selectedDuration
          ? selectedDuration
          : selectedLivestream[0]?.duration
          ? selectedLivestream[0]?.duration
          : "",
      };
      if (id === "1") {
        LivestreamService.createLiveStream(bodyData).then((res) => {
          history.push("/dashboard/mylivestreams/drafts");
          setIsFormSubmitted(false);
        });
      } else {
        const data = Object.assign(bodyData, {
          _id: selectedLivestream[0]?._id,
          collectionName: selectedLivestream[0]?.collectionName,
        });
        LivestreamService.updateLiveStream(data).then((res) => {
          history.push("/dashboard/mylivestreams/drafts");
          setIsFormSubmitted(false);
        });
      }
    }
  };

  const [count, setCount] = useState(10);
  const countInc = () => {
    setCount(count + 1);
  };

  const countDec = () => {
    if (count <= 1) {
      toast.error("No Negative");
    } else {
      setCount(count - 1);
    }
  };

  const onUploadProgress = (data: any) => {
    const progress = Math.round((data.loaded / data.total) * 100);
    setProgress(progress);
  };

  const onUploadContent = async (event: any) => {
    setVideoUrl("");
    setShowVideo(false);
    setProgress(0);
    if (event.target.files && event.target.files[0]) {
      setContent(event.target.files[0]);
      const result = await S3Helper.uploadFile(
        event.target.files[0],
        onUploadProgress,
        awsInfo
      );
      setVideoUrl(
        `${baseUrl}${awsInfo.folderPath}/${event.target.files[0].name}`
      );
      toast.success("Uploaded Successfully");
    }
  };

  const onChangeSubscriptionPakages = (event: any, index: number) => {
    const selectedSubscription = selectedSubscriptionPakages.find((element) => {
      return element === event;
    });
    if (selectedSubscription) {
      const list = [...selectedSubscriptionPakages];
      list.splice(index, 1);
      setSelectedSubscriptionPakages([...list]);
    } else {
      const list = [...selectedSubscriptionPakages];
      list.push(event);
      setSelectedSubscriptionPakages(list);
    }
  };

  const onDetails = (index: number) => {
    setShowSelectedPakageDetails(index);
  };
  const countMinDec = () => {
    const value = minParticipants - 1;
    if (value <= 0) {
      toast.error("No Negative");
    } else {
      setMinParticipants(value);
    }
  };

  const countMinInc = () => {
    setMinParticipants(minParticipants + 1);
  };
  const onChangeMinParticipants = (event: any) => {
    if (event.target.value <= 0) {
      setMinParticipants(1);
    } else {
      setMinParticipants(event.target.value);
    }
  };
  const onChangeMaxParticipants = (event: any) => {
    if (event.target.value <= 0) {
      setCount(10);
    } else {
      setCount(event.target.value);
    }
  };
  const onSelectAttendeesView = (event: any) => {
    setAttendeesView(event.target.value);
  };

  const onSelectAttendeesDownload = (event: any) => {
    setAttendeesDownload(event.target.value);
  };

  const [validationErrors, setValidationErrors] = useState<FormControlError[]>(
    []
  );
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const formValidations = [
    new FormField("title", [FormValidators.REQUIRED]),
    new FormField("price", [FormValidators.REQUIRED]),
    new FormField("schedule_From", [FormValidators.REQUIRED]),
    new FormField("language", [FormValidators.REQUIRED]),
  ];

  const getInputValid = (control: string) => {
    const value = GetControlIsValid(validationErrors, control);
    return value;
  };

  const [showThumnail, setShowThumnail] = useState("");
  const onUploadThumbnailPic = async (event: any) => {
    if (event.target.files && event.target.files[0]) {
      setContent(event.target.files[0]);
      const result = await S3Helper.uploadFile(
        event.target.files[0],
        onUploadProgress,
        awsInfo
      );
      setShowThumnail(
        `${baseUrl}${awsInfo.folderPath}/${event.target.files[0].name}`
      );
      setShow(false);
      toast.success("Uploaded Successfully");
    }
  };

  const onSelectAllPackages = (event: any) => {
    setSelectAllPackages(event.target.checked);
    // if (event.target.checked) {
    // const newPakages: any = subscriptionPackages;
    //   newPakages.forEach((element: any) => {
    //     if (element?.status !== '0') {
    //       element.isExist = true;
    //     }
    //   });
    //   setSubscriptionPackages([...newPakages]);

    // } else {
    //   const newPakages: any = subscriptionPackages;
    //   newPakages.forEach((element: any) => {
    //     if (element?.status !== '0') {
    //       element.isExist = false;
    //     }
    //   });
    //   setSubscriptionPackages([...newPakages])
    // }
  };
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleImageModal = () => setShow(true);

  const [showVideo, setShowVideo] = useState(false);
  const handleVideoClose = () => setShowVideo(false);
  const handleVideoModal = () => setShowVideo(true);

  const alertPublish = () => {
    alert("Are you sure want to publish");
  };
  const [startDate, setStartDate] = useState();
  return (
    <>
      <div className="main-content">
        <div className="container container-custom">
          <div className="tab-content" id="pills-tabContent">
            <div className="row mb-4 pb-2 border-bottom">
              <h4 className="col-auto mb-0">Livestream</h4>
              {/* <button
              className="btn btn-outline-black col-auto ms-auto"
              type="button"
            >
              Schedule &amp; Publish
            </button> */}
            </div>
            <div
              className="tab-pane active"
              id="details"
              role="tabpanel"
              aria-labelledby="details-tab"
            >
              <div>
                <FormBuilder onUpdate={updateFormData}>
                  <form className="row post-form-sec">
                    <div className="col-md-8 post-form pe-3">
                      <div className="row mb-3">
                        <div className="custom-radio no-bdr w-auto">
                          <input
                            id="individual"
                            type="radio"
                            name="PrivateRadioCheckbox"
                          />
                          <label htmlFor="individual">Public</label>
                        </div>
                        <div className="custom-radio no-bdr  w-auto">
                          <input
                            id="group"
                            type="radio"
                            name="PrivateRadioCheckbox"
                          />
                          <label htmlFor="group">Private</label>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-floating mb-3">
                            <input
                              className={`form-control ${
                                isFormSubmitted && !getInputValid("title")
                                  ? "error"
                                  : ""
                              }`}
                              type="text"
                              name="title"
                              placeholder="Title of the Livestream"
                              defaultValue={selectedLivestream[0]?.title}
                            />
                            {isFormSubmitted && !getInputValid("title") ? (
                              <label
                                className="clr-red"
                                htmlFor="floatingInput"
                              >
                                Enter Title
                              </label>
                            ) : (
                              <label
                                className="clr-black"
                                htmlFor="floatingInput"
                              >
                                Title
                              </label>
                            )}
                            {/* <label htmlFor="floatingInput">Title</label> */}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-floating mb-3">
                            <select
                              className={`form-control ${
                                isFormSubmitted && !getInputValid("language")
                                  ? "error"
                                  : ""
                              }`}
                              name="language"
                              defaultValue={selectedLivestream[0]?.language}
                            >
                              <option value="0">Select Language</option>
                              <option value="Assamese">Assamese</option>
                              <option value="Bengali">Bengali</option>
                              <option value="Bodo">Bodo</option>
                              <option value="Dogri">Dogri</option>
                              <option value="English">English</option>
                              <option value="Gujarati">Gujarati</option>
                              <option value="Hindi">Hindi</option>
                              <option value="Kannada">Kannada</option>
                              <option value="Kashmiri">Kashmiri</option>
                              <option value="Konkani">Konkani</option>
                              <option value="Maithili">Maithili</option>
                              <option value="Malayalam">Malayalam</option>
                              <option value="Marathi">Marathi</option>
                              <option value="Meitei">Meitei</option>
                              <option value="Nepali">Nepali</option>
                              <option value="Odia">Odia</option>
                              <option value="Punjabi">Punjabi</option>
                              <option value="Sanskrit">Sanskrit</option>
                              <option value="Santali">Santali</option>
                              <option value="Sindhi">Sindhi</option>
                              <option value="Tamil">Tamil</option>
                              <option value="Telugu">Telugu</option>
                              <option value="Urd">Urdu</option>
                            </select>
                            {isFormSubmitted && !getInputValid("language") ? (
                              <label
                                className="clr-red"
                                htmlFor="floatingInput"
                              >
                                Select language
                              </label>
                            ) : (
                              <label
                                className="clr-black"
                                htmlFor="floatingInput"
                              >
                                Language
                              </label>
                            )}
                            {/* <label htmlFor="floatingInput">Title</label> */}
                          </div>
                        </div>
                      </div>
                      <div className="form-floating mb-3">
                        <textarea
                          className="form-control"
                          name="description"
                          placeholder="Add a description"
                          defaultValue={selectedLivestream[0]?.description}
                        />
                        <label htmlFor="creations">
                          Description (max 1000 characters)
                        </label>
                      </div>
                      {/* <div className="mb-3 col-auto d-flex flex-wrap">
                        <label
                          className="col-md-3 col-form-label  ps-md-0"
                          htmlFor="maxParticipants"
                        >
                          Max Participants
                        </label>
                        <div className="col-md-auto">
                          <div className="checkout-count">
                            <form className="row">
                              <div className="col checkout-counter">
                                <span className="dec" onClick={countDec}>
                                  -
                                </span>
                                <input
                                  type="number"
                                  className="form-control"
                                  id="maxParticipants"
                                  name="max_participants"
                                  value={count}
                                  onChange={(event) =>
                                    onChangeMaxParticipants(event)
                                  }
                                />
                                <span className="inc" onClick={countInc}>
                                  +
                                </span>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div> */}
                      {/* <div className="mb-3 col-auto d-flex flex-wrap">
                        <label
                          className="col-md-3 col-form-label d-flex ps-md-0"
                          htmlFor="minParticipants"
                        >
                          min Participants
                          <TooltipWrapper
                            placement="top"
                            tooltipText="A minimum number of participants to go live with the livestream."
                          />
                        </label>
                        <div className="col-md-auto">
                          <div className="checkout-count">
                            <form className="row">
                              <div className="col checkout-counter">
                                <span className="dec" onClick={countMinDec}>
                                  -
                                </span>
                                <input
                                  type="number"
                                  className="form-control"
                                  id="minParticipants"
                                  name="min_participants"
                                  value={minParticipants}
                                  onChange={onChangeMinParticipants}
                                />
                                <span className="inc" onClick={countMinInc}>
                                  +
                                </span>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div> */}
                      <div className="mb-3 col-auto d-flex flex-wrap">
                        {isFormSubmitted && !getInputValid("price") ? (
                          <label
                            className="col-md-3 col-form-label ps-md-0 clr-red"
                            htmlFor="ticketPrice"
                          >
                            Enter Ticket Price
                          </label>
                        ) : (
                          <label
                            className="col-md-3 col-form-label ps-md-0"
                            htmlFor="ticketPrice"
                          >
                            Ticket Price
                          </label>
                        )}
                        <div className="col-md-auto">
                          <input
                            className="form-control"
                            type="text"
                            id="ticketPrice"
                            name="price"
                            placeholder="Price"
                            defaultValue={selectedLivestream[0]?.price}
                          />
                        </div>
                      </div>
                      {/* <div className="mb-3 col-auto d-flex flex-wrap">
                      <label
                        className="col-md-3 col-form-label  ps-md-0"
                        htmlFor="ticketPrice"
                      >
                        Earnings
                      </label>
                      <div className="col-md-auto">
                        <strong className="h3 mb-0">22500</strong>
                        <sub style={{ bottom: 0, paddingLeft: "5px" }}>INR</sub>
                        <div className="form-text fs-xsml mb-3" id="priceHelp">
                          This amount excludes the 10% of Clan convenience fee.
                        </div>
                      </div>
                    </div> */}

                      {/* <div className="row mb-3">
                        <div className="col-md-12">
                          <label className="form-label col-md-2" htmlFor="formFileSm">
                            Upload a teaser
                          </label>
                          <button
                            onClick={handleVideoModal}
                            type="button"
                            className="btn btn-link text-decoration-none d-inline-flex col-auto"
                          >
                            Upload
                          </button>
                          <div className="upload-sec upload-video custom" style={{ width: "200px" }}>
                            {progress >= 100 && videoUrl && (
                              <>
                                <video
                                  poster={videoUrl}
                                  className="my_club_media"
                                  controls
                                  controlsList="nodownload"
                                >
                                  <source src={videoUrl} type="video/mp4" />
                                  Your browser does not support HTML video.
                                </video>
                              </>
                            )}
                            <div className="btn_video_upload btn">
                              <input
                                className="upload_video_file"
                                id="upload_video_file"
                                type="file"
                                name="video_url"
                                accept=".mov,.mkv, .mpg, .mp2, .mpeg,.mpe,.mpv,.m2v, .mp4,.m4p,.m4v, .avi, .wmv, .flv, .3gp, .webm" onChange={(e) => onUploadContent(e)} />
                              <i className="bi bi-cloud-arrow-up"></i>
                              <strong>Upload Video </strong>
                              <span>(drag and drop or browse)</span>
                              <span className="upload-accept-filename ">
                                .mov,.mkv, .mpg, .mp2, .mpeg,.mpe,.mpv,.m2v,
                                .mp4,.m4p,.m4v, .avi, .wmv, .flv, .3gp, .webm
                              </span>
                              </div> 
                          </div>
                        </div>
                      </div> */}
                      <div className="row mb-3">
                        <div className="col-md-12">
                          <label
                            className="form-label col-md-2"
                            htmlFor="formFileSm"
                          >
                            Thumbnail Photo
                          </label>
                          <button
                            onClick={handleImageModal}
                            type="button"
                            className="btn btn-link text-decoration-none d-inline-flex col-auto"
                          >
                            Upload
                          </button>
                          <div className="upload-sec custom">
                            {/* <div className="outer-btn button_outer1">
                              <div className="btn_upload1 upload_btn btn">
                                <input
                                  id="upload_file1"
                                  type="file"
                                  name="cover_photo"
                                  accept="image/jpg, image/jpeg, image/png, image/svg, image/gif"
                                  onChange={(event) =>
                                    onUploadThumbnailPic(event)
                                  }
                                />
                                <i className="bi bi-cloud-arrow-up"></i>
                                <strong>Upload thumbnail Photo </strong>
                                <span>(drag and drop or browse)</span>
                                <span className="upload-accept-filename">
                                  jpg, jpeg, png, svg, gif
                                </span>
                              </div>
                              <div className="processing_bar"></div>
                              <div className="success_box"></div>
                            </div> */}

                            {showThumnail && (
                              <div
                                className="border"
                                style={{
                                  width: "200px",
                                  height: "200px",
                                  objectFit: "cover",
                                }}
                              >
                                <img
                                  className="my_club_media"
                                  src={showThumnail}
                                  alt=""
                                  style={{
                                    width: "100%",
                                    height: "100%",
                                    objectFit: "cover",
                                    padding: "5px",
                                  }}
                                />
                              </div>
                            )}
                            <div className="error_msg1 upload_error_msg"></div>
                            <div
                              className="uploaded_file_view"
                              id="uploaded_view1"
                            >
                              <span className="file_remove1 remove_file">
                                X
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row mb-3">
                        <div className="custom-checkbox no-bdr d-inline-flex col ms-2">
                          <input
                            id="openForAttendees"
                            type="checkbox"
                            name="VIEW_LIVESTREAM"
                            checked={attendeesViewChecked}
                            onClick={() =>
                              setAttendeesViewChecked(!attendeesViewChecked)
                            }
                          />
                          <label htmlFor="openForAttendees">
                            Open for attendees to view the recorded livestream.
                          </label>
                        </div>
                        <div className="col-12">
                          <span className="me-2">Till</span>
                          <select
                            className="form-select form-select-sm w-auto d-inline-flex"
                            aria-label="Available for"
                            value={attendeesView}
                            onChange={(event) => onSelectAttendeesView(event)}
                          >
                            <option value="">Select</option>
                            <option value="7">7days</option>
                            <option value="15">15days</option>
                            <option value="30">30days</option>
                          </select>
                          <span className="ms-2">days</span>
                        </div>

                        <div
                          className="form-text fs-xsml mb-3"
                          id="videoAvailableTill"
                        >
                          The video will be available to view till August 11,
                          2021.
                        </div>
                      </div>
                      <div className="row mb-3">
                        <div className="custom-checkbox no-bdr d-inline-flex col ms-2">
                          <input
                            id="openForAttendeesDownload"
                            type="checkbox"
                            name="DOWNLOAD_LIVESTREAM"
                            checked={attendeesDownloadChecked}
                            onClick={() =>
                              setAttendeesDownloadChecked(
                                !attendeesDownloadChecked
                              )
                            }
                          />
                          <label htmlFor="openForAttendeesDownload">
                            Open for attendees to download the livestream ends.
                          </label>
                        </div>
                        <div className="col-12">
                          <span className="me-2">Till</span>
                          <select
                            className="form-select form-select-sm w-auto d-inline-flex"
                            aria-label="Available for"
                            value={attendeesDownload}
                            onChange={(event) =>
                              onSelectAttendeesDownload(event)
                            }
                          >
                            <option value="">Select</option>
                            <option value="7">7days</option>
                            <option value="15">15days</option>
                            <option value="30">30days</option>
                          </select>
                          <span className="ms-2">days</span>
                        </div>

                        <div
                          className="form-text fs-xsml mb-3"
                          id="videoAvailableDownload"
                        >
                          The video will be available to download till August
                          11, 2021.
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 post-form-detail ps-3">
                      <h6 className="d-flex">
                        Schedule (IST)
                        <TooltipWrapper
                          iconName="bi bi-info"
                          placement="top"
                          tooltipText="15 min - 1 hr of duration is allowed"
                        />
                      </h6>
                      <div className="row mb-3">
                        {isFormSubmitted && !getInputValid("schedule_From") ? (
                          <label className="col-md-2 pe-0 clr-red">
                            select from date
                          </label>
                        ) : (
                          <label className="col-md-2 pe-0">From</label>
                        )}
                        {/* <label className="col-md-2 pe-0">From</label> */}
                        <div className="col-md-7">
                          <input
                            className="form-control"
                            type="datetime-local"
                            name="schedule_From"
                            defaultValue={selectedLivestream[0]?.schedule_From}
                          />
                          <DatePicker
                            className="form-control"
                            value={selectedLivestream[0]?.schedule_From}
                            selected={startDate}
                            onChange={(date) => setStartDate(startDate)}
                            showTimeSelect
                            dateFormat="MMMM d, yyyy h:mm aa"
                          />
                        </div>
                        {/* <div className="col-md-5">
                    <input
                      className="form-control"
                      type="text"
                      name="name"
                      placeholder="11:45AM"
                    />
                  </div> */}
                      </div>
                      <div className="row mb-3">
                        {isFormSubmitted && !selectedDuration ? (
                          <label className="col-md-2 pe-0 mt-1 clr-red">
                            select Duration
                          </label>
                        ) : (
                          <label className="col-md-2 mt-1 pe-0">Duration</label>
                        )}
                        <div className="col-md-4">
                          <select
                            name="selectedDuration"
                            value={selectedDuration}
                            onChange={(event) => onChangeDuration(event)}
                            className="form-select form-select-sm"
                          >
                            <option value="">Select</option>
                            {duration.map((data: any, index: number) => {
                              return <option value={data}>{data}min</option>;
                            })}
                          </select>
                        </div>
                        {/* {isFormSubmitted && !getInputValid('schedule_To') ? (
                        <label className="col-md-2 pe-0 clr-red">
                          select to date
                        </label>
                      ) : (
                        <label className="col-md-2 pe-0">
                          To
                        </label>
                      )}
                      <div className="col-md-7">
                        <input
                          className="form-control"
                          type="datetime-local"
                          name="schedule_To"
                          defaultValue={selectedLivestream[0]?.schedule_To}
                        />
                      </div> */}
                      </div>
                      {/* <Link to="" className="text-decoration-none fs-sml">
                        View schedule for this day
                      </Link> */}
                      <hr />
                      {/* <h6 className="d-flex">
                        Viewership
                        <TooltipWrapper
                          placement="top"
                          tooltipText="Who can view this livestream"
                        />
                      </h6> */}
                      <div className="custom-checkbox no-bdr">
                        <input
                          id="publicPaid"
                          type="checkbox"
                          checked={isPaidPost}
                          onClick={() => setIsPaidPost(!isPaidPost)}
                        />
                        <label htmlFor="publicPaid">
                          {" "}
                          <span className="d-inline-flex flex-column">
                            {isFormSubmitted &&
                            !isPaidPost &&
                            !(selectedSubscriptionPakages.length > 0) ? (
                              <span className="clr-red">
                                select Public - Paid or package
                                {selectedSubscriptionPakages.length > 0}
                              </span>
                            ) : (
                              <span>Public - Paid</span>
                            )}
                            <div
                              className="form-text fs-xsml mt-0"
                              id="publicPaid"
                            >
                              anyone with the link can view after buying a
                              ticket
                            </div>
                          </span>
                        </label>
                      </div>
                      <div className="custom-checkbox no-bdr">
                        <input
                          id="myClubFans"
                          type="checkbox"
                          checked={selectAllPackages}
                          onChange={(event) => onSelectAllPackages(event)}
                        />
                        <label htmlFor="myClubFans">
                          {" "}
                          <span className="d-inline-flex flex-column">
                            My Clan Fans
                            <div
                              className="form-text fs-xsml mt-0"
                              id="myClubFans"
                            >
                              all Club Bundles will be able to view
                            </div>
                          </span>
                        </label>
                      </div>
                      <div className="custom-checkbox no-bdr">
                        <span className="d-inline-flex flex-column">
                          Select Package
                          <div
                            className="form-text fs-xsml mt-0"
                            id="publicFree"
                          >
                            Only subscribers can view
                          </div>
                        </span>
                      </div>

                      {subscriptionPackages?.map((element, index) => {
                        return (
                          <div key={element._id} className="plans-checkbox">
                            <div
                              className={`custom-checkbox no-bdr ${
                                element?.status === "0" ? "disabled" : ""
                              }`}
                            >
                              <input
                                id={element._id}
                                type="checkbox"
                                checked={element?.isExist}
                                name={element._id}
                                onChange={() =>
                                  onChangeSubscriptionPakages(
                                    element._id,
                                    index
                                  )
                                }
                                disabled={element?.status === "0"}
                              />
                              <label htmlFor={element._id}>
                                <div className="bundle-info">
                                  <div className="bundle-name">
                                    {element.name}
                                  </div>
                                </div>
                                <div className="bundle-desc fs-xsml mt-0">
                                  <div className="bundle-info-price">
                                    {element.price_monthly} INR / Month |{" "}
                                  </div>
                                  <div className="bundle-info-fans">
                                    {element.total_fans} Fans
                                  </div>
                                </div>
                              </label>
                              {showSelectedPakageDetails !== index && (
                                <a
                                  className="bundle-detail pointer"
                                  onClick={() => onDetails(index)}
                                >
                                  more
                                </a>
                              )}
                              {showSelectedPakageDetails === index && (
                                <a
                                  className="bundle-detail pointer"
                                  onClick={() => onDetails(-1)}
                                >
                                  less
                                </a>
                              )}
                              {showSelectedPakageDetails === index && (
                                <div>
                                  <p>{element?.description}</p>
                                  <ol>
                                    {element.perks.map((peark) => {
                                      return <li>{peark?.value}</li>;
                                    })}
                                  </ol>
                                </div>
                              )}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </form>
                  <div className="row mt-3">
                    <div className="col-md-8">
                      <div className="row">
                        <div className="col-12 d-flex flex-wrap">
                          <button className="btn btn-outline-orange me-2">
                            Cancel
                          </button>
                          <div className="ms-auto d-flex">
                            <button
                              disabled={isLoading}
                              type="submit"
                              className="ms-auto btn btn-outline-black"
                              onClick={onSaveLivestream}
                            >
                              {!isLoading ? (
                                "Save"
                              ) : (
                                <div
                                  className="spinner-border text-primary spinner-border-sm"
                                  role="status"
                                >
                                  <span className="sr-only">Loading...</span>
                                </div>
                              )}
                            </button>
                            <button
                              type="button"
                              onClick={alertPublish}
                              className="btn btn-primary ms-2"
                            >
                              Publish
                            </button>
                          </div>
                        </div>
                        <div className="ms-auto col-auto">
                          {/* <button className="btn btn-primary">Preview</button> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </FormBuilder>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CustomUploadModal
        show={showVideo}
        handlemodal={handleVideoModal}
        handleClose={handleVideoClose}
        uploadType="video"
        uploadedFile={(e) => onUploadContent(e)}
      />
      <CustomUploadModal
        show={show}
        handlemodal={handleImageModal}
        handleClose={handleClose}
        uploadType="image"
        uploadedFile={(event) => onUploadThumbnailPic(event)}
      />
    </>
  );
};
