import React, { useEffect, useState } from 'react'
import { NavLink, useHistory } from 'react-router-dom';
import FormBuilder from '../../components/form-builder';
import Logo from "../../assets/img/ClanClublogo.svg";

export const ClubPaymentPage = () => {
    let [signupData, setSignupData] = useState<any>({});
    let [loginData, setLoginData] = useState<any>({});
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [headerColor, setHeaderColor] = useState(Boolean);
    const [showOffCanvas, setShowOffCanvas] = useState<Boolean>(false);
    const toggleOffCanvasShow = () => setShowOffCanvas((s) => !s);
    const history = useHistory();

    window.addEventListener('scroll', () => {
        const sc = window.scrollY;
        if (sc > 150) {
            setHeaderColor(true);
        } else {
            setHeaderColor(false);
        }
    });
    const updateSignupFormData = (data: any) => {
        setSignupData(data);
    };

    const handleSignupClick = () => {
        history.push("/");
    }
    return (
        <div>
            <header className={`site-header static navbar navbar-expand-lg ${headerColor ? "fixedActive" : ""}`}>
                <div className="container-fluid">
                    <div className="site-header__left">
                        <span onClick={toggleOffCanvasShow} className="d-inline-flex d-lg-none align-self-center mobile-toggle" style={{ cursor: 'pointer' }}><i className="bi bi-list"></i></span>
                        <span className="navbar-brand">
                            <img src={Logo} alt="Clan Logo" />
                        </span>
                    </div>
                    <div className="collapse navbar-collapse d-none d-lg-flex" id="navbarNavDropdown">
                        <ul className="navbar-nav">
                            <li className="nav-item"><NavLink className="nav-link" aria-current="page" to="">Clubs</NavLink></li>
                            <li className="nav-item"><a className="nav-link" href="#">Livestreams</a></li>
                        </ul>
                    </div>
                </div>
            </header>
            <div className="row">
                <div className="col-md-6 offset-md-3">
                    <div className="row mt-5">
                        <div className="col-auto d-flex flex-wrap">
                            <h4>Complete the Payment</h4>
                        </div>
                    </div>
                    <FormBuilder onUpdate={updateSignupFormData}>
                        <form className="top-space-login pl-3 pr-3" >
                            <div className="form-floating mb-3">
                                <input type="text" data-required
                                    className={`form-control ${isSubmitted && loginData.errors && loginData.errors.payableAmount ? 'error' : ''}`}
                                    id="payableAmount" name="payableAmount" placeholder="Payable amount" />
                                {(isSubmitted && loginData.errors && loginData.errors.email) ?
                                    <label className="clr-red" htmlFor="payableAmount">Enter Payable amount</label>
                                    :
                                    <label className="clr-black" htmlFor="payableAmount">Payable amount</label>
                                }
                            </div>
                            <div className="form-floating mb-3">
                                <input type="email" data-required
                                    className={`form-control ${isSubmitted && loginData.errors && loginData.errors.name ? 'error' : ''}`}
                                    id="name" name="name" placeholder="Name" />
                                {(isSubmitted && loginData.errors && loginData.errors.email) ?
                                    <label className="clr-red" htmlFor="name">Enter Name</label>
                                    :
                                    <label className="clr-black" htmlFor="name">Name</label>
                                }
                            </div>
                            <div className="form-floating mb-3">
                                <input type="email" data-required
                                    className={`form-control ${isSubmitted && loginData.errors && loginData.errors.email ? 'error' : ''}`}
                                    id="exampleInputEmail1" name="email" aria-describedby="emailHelp" placeholder="Email" />
                                {(isSubmitted && loginData.errors && loginData.errors.email) ?
                                    <label className="clr-red" htmlFor="exampleInputEmail1">Enter Valid Email</label>
                                    :
                                    <label className="clr-black" htmlFor="exampleInputEmail1">Email</label>
                                }
                            </div>
                        </form>
                    </FormBuilder>
                    <div>
                        <button className="form-control btn btn-primary"  onClick={handleSignupClick}>Pay</button>
                    </div>
                </div>
            </div>
        </div>

    )
}
