import React, { useState } from "react";
import moment from "moment";
import { Link } from "react-router-dom";
type Props = {
  data: any;
  className?: string;
};

const CardPastEvents = (props: Props) => {
  const [livestreamImage, setLivestreamImage] = useState("");
  const [isImageLarge, setIsImageLarge] = useState(false);
  const img = new Image();
  img.src = props?.data?.thumbnail;

  img.onload = () => {
    const actualwidth = img.width;
    const actualheight = img.height;
    // console.log(actualwidth, actualheight);
    if (actualwidth > 700) {
      setLivestreamImage(img.src);
      setIsImageLarge(true);
    }
  };
  // console.log(props?.data);
  return (
    <>
      <Link
        to={`/livestreams/livestream-detail/${props.data?._id}`}
        className={[
          "card-livestream square",
          props.className,
        ].join(" ")}
      >
        <span
          className="card-livestream-bgimg"
          style={{
            backgroundImage: props.data?.thumbnail
              ? `url("${props.data?.thumbnail}")`
              : "linear-gradient(to right, #DDEFBB, #FFEEEE)",
            backgroundPosition: "center center",
            backgroundSize: "cover",
            filter: isImageLarge ? "blur(0px)" : "blur(3px)",
          }}
        ></span>
        {!isImageLarge && (
          <img
            className="card-livestream-img"
            src={props.data?.thumbnail}
            alt={props.data?.title}
          />
        )}
        <span className="card-livestream-likes-count">
          <i className="bi bi-heart"></i>{props.data?.likes.length}
        </span>
        <div className="card-livestream-content">
          <h3 className="card-livestream-title">
            <a
              href="javascript:void(0)"
              onClick={() => {}}
              title={props.data?.title}
            >
              {props.data?.title}
            </a>
          </h3>
          <Link to={`/livestreams/more-livestream/${props?.data?.user_id}/ls`} className="position-relative text-white link-underline">
            
            @{props?.data?.user_info[0]?.name}
          </Link>
        
          <span className="card-livestream-date">
            {moment(props.data?.schedule_From).utc().local().format('DD-MM-YYYY')}
          </span>
          <Link
            to={`/livestreams/livestream-detail/${props.data?._id}`}
            className="btn btn-outline-black btn-rounded d-none d-lg-block"
          >
            View Details
          </Link>
        </div>
      </Link>
      {/* Mobile View */}
      {/* <Link
        to={`/livestreams/livestream-detail/${props?.data?._id}`}
        className="d-block d-md-none card-livestream square"
      >
        <span
          className="card-livestream-bgimg"
          style={{
            backgroundImage: props?.data?.thumbnail
              ? `url("${props?.data?.thumbnail}")`
              : "linear-gradient(to right, #DDEFBB, #FFEEEE)",
            backgroundPosition: "center center",
            backgroundSize: "cover",
            filter: isImageLarge ? "blur(0px)" : "blur(3px)",
          }}
        ></span>
        {!isImageLarge && (
          <img
            className="card-livestream-img"
            src={props?.data?.thumbnail}
            alt={props?.data?.title}
          />
        )}
        <span className="card-livestream-likes-count">
          <i className="bi bi-heart"></i>18
        </span>
        <div className="card-livestream-content">
          {/* <a
                    href="javascript:void(0)"
                    onClick={() => { }}
                    className="ratio ratio-1x1 mb-3 video-img"
                  >
                    <video
                      poster=""
                      className="my_club_media"
                      controls
                      controlsList="nodownload"
                      src={props?.video_url}
                    >
                      <source src="" type="video/mp4" />
                      Your browser does not support HTML video.
                    </video>
                  </a> 

          <h3 className="card-livestream-title">
            <a
              href="javascript:void(0)"
              onClick={() => {}}
              title={props.data?.title}
            >
              {props.data?.title}
            </a>
          </h3>
          <span className="dive-in-channel position-relative">
            @{props?.data?.user_info[0].name}
          </span>
          <span className="card-livestream-date">
            {moment(props?.data?.schedule_From).utc().local().format('DD-MM-YYYY hh:mm A')}
          </span>
        </div>
      </Link> */}
    </>
  );
};

export default CardPastEvents;
