import React, { Fragment } from 'react'
import { NavLink } from 'react-router-dom'
import SubscribersRoutes from '../../../routes/subscribers';

export const SubscribersPage = () => {
    return (
        <div className="main-content">
            <div className="container container-custom pb-5 mb-3">
                {/* <div className="main-content__top">
                    <div className="custom-tab mb-2">
                        <ul className="nav nav-pills">
                            <li className="nav-item" role="presentation">
                                <NavLink className="nav-link" to="/dashboard/subscribers/inbox" >Subscribers Inbox</NavLink>
                            </li>
                            <li className="nav-item" role="presentation">
                                <NavLink className="nav-link" to="/dashboard/subscribers/list" >Subscribers</NavLink>
                            </li>
                        </ul>
                    </div>
                </div> */}
                <div className="tab-content" id="pills-tabContent">
                    <div className="tab-pane active" id="details" role="tabpanel" aria-labelledby="details-tab">
                        <SubscribersRoutes></SubscribersRoutes>
                    </div>
                </div>
            </div>
        </div>
    )
}

