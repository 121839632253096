import { Sidebar, Menu, MenuItem, useProSidebar } from 'react-pro-sidebar';
import { NavLink, } from "react-router-dom";
function AdminSideBar() {
  const { collapseSidebar } = useProSidebar();

  return (
    <div style={{ display: 'flex', height: '100%' }}>
      <Sidebar defaultCollapsed={true}>
        <Menu>
        <NavLink
                        //   className="dropdown-item"
                          to={`/admin/signups`}
                        //   target="_blank"
                        >
          <MenuItem> <i className="bi bi-people" style={{fontSize:"25px"}}></i> Sign Ups</MenuItem>
          </NavLink>
          <NavLink
                        //   className="dropdown-item"
                          to={`/admin/verification`}
                        //   target="_blank"
                        >
          <MenuItem><i className="bi bi-file-earmark-check" style={{fontSize:"25px"}}></i> Verification</MenuItem>
          </NavLink>
          <NavLink
                        //   className="dropdown-item"
                          to={`/admin/payment`}
                        //   target="_blank"
                        >
          <MenuItem><i className="bi bi-laptop" style={{fontSize:"25px"}}></i> Payment</MenuItem>
          </NavLink>
        </Menu>
      </Sidebar>
      <main>
        {/* <button onClick={() => collapseSidebar()}>Collapse</button> */}
      </main>
    </div>
  );
}

export default AdminSideBar;
