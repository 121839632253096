import React from "react";
import { Spinner } from "react-bootstrap";
import { connect } from "react-redux";
interface Props {
  LoadingReducer?: any;
}

const AppLoader: React.FC<Props> = (props) => {  
  return (
    <div>
      {props.LoadingReducer?.loading && (<div className="spinner-container">
        <div className="spinner">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      </div>)}
    </div>
  );
};
const mapStateToProps = (state: any) => {
  return {
    LoadingReducer: state.LoadingReducer,
  };
};

const connectedNavBar = connect(mapStateToProps)(AppLoader);
export { connectedNavBar as AppLoader };
