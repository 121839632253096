import React, { useState } from "react";
import AdminSideBar from './AdminSideBar'
import { ProSidebarProvider } from 'react-pro-sidebar';



const Admin = () => {
    // const [sidebarOpen, setSideBarOpen] = useState(false);
    // const handleViewSidebar = () => {
    //   setSideBarOpen(!sidebarOpen);
    // };
  return (
    <div>
        

<ProSidebarProvider>
<AdminSideBar  />
</ProSidebarProvider>;
       
    </div>
  )
}

export default Admin