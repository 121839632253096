import React, { Fragment, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { toast } from "react-toastify";
import ProfileRoutes from "../../../routes/profile";

export const MyProfileSettingsPage: React.FC = () => {
  const id = sessionStorage.getItem("isLogin") || "";
  return (
    <div className="main-content my-profile-sec">
      <div className="container container-custom">
        <div className="main-content__top">
          <div className="custom-tab">
            <ul className="nav nav-pills mb-2">
              <li className="nav-item" role="presentation">
                <NavLink className="nav-link" to="/dashboard/myprofile/personalDetail">
                  Personal Details
                </NavLink>
              </li>
              <li className="nav-item" role="presentation">
                <NavLink
                  className="nav-link"
                  id="packages-tab"
                  to="/dashboard/myprofile/password"
                >
                  Password
                </NavLink>
              </li>
              <li className="nav-item" role="presentation">
                <NavLink
                  className="nav-link"
                  id="payments-tab"
                  to="/dashboard/myprofile/socialConnect"
                >
                  Social Connect
                </NavLink>
              </li>
            </ul>
          </div>

        </div>
        <div className="tab-content" id="pills-tabContent">
          <div
            className="tab-pane active"
            role="tabpanel"
          >
            <ProfileRoutes></ProfileRoutes>
          </div>
        </div>
      </div>
    </div>
    // </div>
  );
};
