import { Switch, Route } from "react-router";
import AllNFT from "../pages/dashboard/my-nft/all-nft";
import NFTCollecting from '../pages/dashboard/my-nft/nft-collecting'

const ClubsRoutes = () => {
    return (
        <Switch>
            <Route exact path="/dashboard/mynft/selling" component={AllNFT}></Route>
            <Route exact path="/dashboard/mynft/owning" component={NFTCollecting}></Route>
        </Switch>
    );
}

export default ClubsRoutes;