import React, { useState, useEffect } from "react";
import { Clubs } from "../../app/model/clubs";
import { ClubService } from "../../app/service/club.service";
import FeatureImg1 from "../../assets/img/featured-img.png";
import FeatureImg2 from "../../assets/img/featured-img1.png";
import FeatureImg3 from "../../assets/img/featured-img2.png";
import FeatureImg from "../../assets/img/featured-img3.png";
import { Link, NavLink, useHistory } from "react-router-dom";
import Select, { AriaOnFocus } from "react-select";
import { Nav, NavItem, Tab, Tabs } from "react-bootstrap";
import NFTListingBanner from "./nft-listing-banner";
import Banner from "../../assets/img/banner.jpg";
import { NftService } from "../../app/service/nft.service";
import { toast } from "react-toastify";
import { connect, useDispatch } from "react-redux";
import { Loading } from "../../redux/actions";

interface NFTProps { 
  loading?: (data: any) => void;
}

const NFT: React.FC<NFTProps> = (props: NFTProps) =>{
  const [creatorNfts, setCreatorNfts] = useState<Clubs[] | []>([]);
  const [nftList, setNftList] = useState<Clubs[] | []>([]);
  let userId = sessionStorage.getItem("isLogin") || "";
  const history = useHistory();
  const dispatch = useDispatch();

  const [loading, setloading] = useState(false);

  useEffect(() => {
    if (props.loading) {
      dispatch(props.loading(true));
    }
    getNftByCreator();
    getNftList();
  }, []);
  const [ariaFocusMessage, setAriaFocusMessage] = useState("");
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  // const onFocus = ({ focused, isDisabled }) => {
  //   const msg = `You are currently focused on option ${focused.label}${
  //     isDisabled ? ', disabled' : ''
  //   }`;
  //   setAriaFocusMessage(msg);
  //   return msg;
  // };

  const onMenuOpen = () => setIsMenuOpen(true);
  const onMenuClose = () => setIsMenuOpen(false);
  const getNftByCreator = () => {
    NftService.getNftByCreator('false').then((res) => {
      // console.log('res getNftByCreator', res);

      setCreatorNfts(res);
    });
  }

  const getNftList = () => {
    NftService.getNftList('false').then((res) => {
      // console.log('res getNftByLIST', res);

      setNftList(res);
      if (props.loading) {
        dispatch(props.loading(false));
      }
    });
  }

  const onCreateNft = () => {
    if (userId) {
      history.push('/nft/create-nft')
    } else {
      toast.error('Please login');
    }
  }
  const categories = [
    {
      id: "1",
      label: "Automobile",
    },
    {
      id: "2",
      label: "Blogger",
    },
    {
      id: "3",
      label: "Fashion & Lifestyle",
    },
    {
      id: "4",
      label: "Artist",
    },
    {
      id: "5",
      label: "Beauty",
    },
    {
      id: "6",
      label: "Children & Parenting",
    },
    {
      id: "7",
      label: "Comedy & Entertainment",
    },
    {
      id: "8",
      label: "Digital Creator",
    },
    {
      id: "9",
      label: "DIY",
    },
    {
      id: "10",
      label: "Education",
    },
    {
      id: "11",
      label: "Film & Animation",
    },
    {
      id: "12",
      label: "Gaming",
    },
    {
      id: "13",
      label: "Health & Fitness",
    },
    {
      id: "14",
      label: "Music",
    },
    {
      id: "15",
      label: "News & Policies",
    },
    {
      id: "16",
      label: "Non profits & Communities",
    },
    {
      id: "17",
      label: "Pets & Animals",
    },
    {
      id: "18",
      label: "Photographer",
    },
    {
      id: "19",
      label: "Products & Services",
    },
    {
      id: "20",
      label: "Science & Technology",
    },
    {
      id: "21",
      label: "Sports",
    },
    {
      id: "22",
      label: "Travel & Events",
    },
    {
      id: "23",
      label: "Writer & Editor",
    },
  ];
  const lowToHigh = [
    {
      id: "1",
      label: "Price: Low to High",
    },
    {
      id: "2",
      label: "Price: High to Low",
    },
    {
      id: "3",
      label: "Most Popular (most favorited)",
    },
    {
      id: "4",
      label: "Trending (most viewed)",
    },
  ];
  return (
    <>
      <NFTListingBanner />
      <section className="nft-listing mt-4 pb-5">
        <Tab.Container id="left-tabs-example" defaultActiveKey="creator">
          <div className="container-fluid">
            <div className="d-flex justify-content-center flex-column pb-3">
              <h2 className="text-center">NFT Collection</h2>
              {/* {userId && <span onClick={onCreateNft} className="btn btn-outline-black d-inline-flex w-auto align-self-center">
                Create
              </span>} */}
            </div>
            <div className="filter-sec ms-auto col-auto d-flex flex-column flex-md-row mb-5">
              <div className="col-12 col-md-auto me-auto mb-3 mb-md-auto">
                <Nav variant="pills">
                  <Nav.Item>
                    <Nav.Link eventKey="creator">By Creator</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="assets">By Assets</Nav.Link>
                  </Nav.Item>
                </Nav>
              </div> 
              {/* <div className="d-flex sort-sec">
                <Select
                  aria-labelledby="aria-label"
                  inputId="aria-example-input1"
                  name="aria-live-color1"
                  onMenuOpen={onMenuOpen}
                  onMenuClose={onMenuClose}
                  options={lowToHigh}
                  placeholder=""
                  className="custom-select me-3"
                />
                <form>
                  <input
                    type="search"
                    placeholder="Search"
                    className="form-control form-control-lg"
                  />
                </form>
              </div> */}
            </div>

            <Tab.Content>
              <Tab.Pane eventKey="creator" className="">
                <div className="row">
                  {creatorNfts.map((data: any, index: number) => {
                    return <div className="col-sm-6 col-lg-4 col-xl-3 mb-4" key={index}>
                      {/* {console.log(data?.image)} */}
                      <div className="card-nft card-nft-creator-info mw-100">
                        <div className="card-nft-img">
                        <Link to={`/livestreams/more-livestream/${data?._id}/nft`} className="ratio ratio-1x1">
                            <img
                              src={data?.image ? data?.image : FeatureImg1}
                              alt="Profile Image"
                              className="mw-100"
                            />
                            </Link>
                        </div>
                        <div className="card-nft-content">
                          <Link to={`/livestreams/more-livestream/${data?._id}/nft`} className="card-nft-title">
                            {data?.name}
                          </Link>
                          
                          <Link to={`/livestreams/more-livestream/${data?._id}/nft`} className="card-nft-creator">
                            @{data?.name}
                          </Link>
                        </div>
                      </div>
                    </div>
                  })}

                  {/* <div className="col-md-3">
                    <div className="card-nft card-nft-creator-info mw-100">
                      <div className="card-nft-img">
                        <a href="" className="ratio ratio-1x1">
                          <img
                            src={FeatureImg}
                            alt="Profile Image"
                            className="mw-100"
                          />
                        </a>
                      </div>
                      <div className="card-nft-content">
                        <a href="" className="card-nft-title">
                          Shenaz Treasury
                        </a>
                        <span className="card-nft-creator">
                          @Shenaz Treasury
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="card-nft card-nft-creator-info mw-100">
                      <div className="card-nft-img">
                        <a href="" className="ratio ratio-1x1">
                          <img
                            src={FeatureImg2}
                            alt="Profile Image"
                            className="mw-100"
                          />
                        </a>
                      </div>
                      <div className="card-nft-content">
                        <a href="" className="card-nft-title">
                          Shenaz Treasury
                        </a>
                        <span className="card-nft-creator">
                          @Shenaz Treasury
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="card-nft card-nft-creator-info mw-100">
                      <div className="card-nft-img">
                        <a href="" className="ratio ratio-1x1">
                          <img
                            src={FeatureImg3}
                            alt="Profile Image"
                            className="mw-100"
                          />
                        </a>
                      </div>
                      <div className="card-nft-content">
                        <a href="" className="card-nft-title">
                          Shenaz Treasury
                        </a>
                        <span className="card-nft-creator">
                          @Shenaz Treasury
                        </span>
                      </div>
                    </div>
                  </div> */}
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="assets">
                <div className="row">
                  {nftList.map((data: any, index: number) => {
                    // console.log(data?._id[0])
                    return <div className="col-sm-6 col-lg-4 mb-4" key={index}>
                      <div className="card-nft mw-100">
                        <span className="card-nft-available-count">NFT Available: {data?.count}</span>
                        
                        <div className="card-nft-img">
                          <Link to={`/nft/nft-single/${data?.entries[0]?._id}/${data?.entries[0]?.collectionName}/${data?.count}`} className="ratio ratio-1x1">
                            <img
                              src={data?.entries[0]?.asset_url ? data?.entries[0]?.asset_url : FeatureImg1}
                              alt="Profile Image"
                              className="mw-100"
                            />
                          </Link>
                        </div>
                        <div className="card-nft-content">
                          <Link to="/nft/nft-single" className="card-nft-title">
                            {data?.entries[0]?.title}
                          </Link>
                          <span className="card-nft-creator">
                            {data?.entries[0]?.user_info[0]?.name}
                          </span>
                          <div className="card-nft-price">
                            <span className="me-auto">Price: {data?.entries[0]?.price}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  })}

                  {/* <div className="col-md-3">
                    <div className="card-nft mw-100">
                      <div className="card-nft-img">
                        <Link to="/nft/nft-single" className="ratio ratio-1x1">
                          <img
                            src={FeatureImg2}
                            alt="Profile Image"
                            className="mw-100"
                          />
                        </Link>
                      </div>
                      <div className="card-nft-content">
                        <Link to="/nft/nft-single" className="card-nft-title">
                          Name of the asset
                        </Link>
                        <span className="card-nft-creator">
                          Name of the creator/profile
                        </span>
                        <div className="card-nft-price">
                          <span className="me-auto">Price: 0.01</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="card-nft mw-100">
                      <div className="card-nft-img">
                        <Link to="/nft/nft-single" className="ratio ratio-1x1">
                          <img
                            src={FeatureImg3}
                            alt="Profile Image"
                            className="mw-100"
                          />
                        </Link>
                      </div>
                      <div className="card-nft-content">
                        <Link to="/nft/nft-single" className="card-nft-title">
                          Name of the asset
                        </Link>
                        <span className="card-nft-creator">
                          Name of the creator/profile
                        </span>
                        <div className="card-nft-price">
                          <span className="me-auto">Price: 0.01</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="card-nft mw-100">
                      <div className="card-nft-img">
                        <Link to="/nft/nft-single" className="ratio ratio-1x1">
                          <img
                            src={Banner}
                            alt="Profile Image"
                            className="mw-100"
                          />
                        </Link>
                      </div>
                      <div className="card-nft-content">
                        <Link to="/nft/nft-single" className="card-nft-title">
                          Name of the asset
                        </Link>
                        <span className="card-nft-creator">
                          Name of the creator/profile
                        </span>
                        <div className="card-nft-price">
                          <span className="me-auto">Price: 0.01</span>
                        </div>
                      </div>
                    </div>
                  </div> */}
                </div>
              </Tab.Pane>
            </Tab.Content>
          </div>
        </Tab.Container>
      </section>
    </>
  );
}
const mapStateToProps = (state: any) => {
  return {
    UserReducer: state.UserReducer,
    LoginUserIdReducer: state.LoginUserIdReducer,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    loading: (data: any) => dispatch(Loading(data)),
  };
};

const connectedNavBar = connect(
  mapStateToProps,
  mapDispatchToProps
)(NFT);
export { connectedNavBar as NFT };
