import moment from "moment";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { LivestreamService } from "../../app/service/livestream";
import dummyImg from "../../assets/img/dummy-img.svg";
import BannerImage from "../../assets/img/livestream-detail-img.png";
import Countdown from "react-countdown";

type Props = {};

const LivestreamSoon = (props: Props) => {
  let { id } = useParams<{ id: string }>();
  const [selectedLivestream, setSelectedLivestream] = useState<any[] | []>([]);
  let timer: any = null;
  const history = useHistory();
  const [timerCount, setTimerCount] = useState("");
  const [latestMinutes, setLatestMinutes] = useState(0);
  const [latestSeconds, setLatestSeconds] = useState(0);
  const [latestHours, setLatestHours] = useState(0);

  let seconds = 0;
  let minutes = 0;
  let hours = 0;

  console.log(moment().format("MMMM Do YYYY, h:mm:ss a"));

  useEffect(() => {
    getLivestream();
    timer = setInterval(() => {
      getLivestream();
    }, 5000);
    return () => {
      clearInterval(timer);
    };
  }, []);

  // Renderer callback with condition
  const renderer = ({
    days,
    hours,
    minutes,
    seconds,
    completed,
  }: {
    days: any;
    hours: any;
    minutes: any;
    seconds: any;
    completed: any;
  }) => {
    console.log(
      moment(selectedLivestream[0]?.schedule_From)
        .utc()
        .format("DD-MM-YYYY | hh:mm A")
    );
    console.log(selectedLivestream[0]?.schedule_From);
    if (completed) {
      // Render a completed state
    } else {
      // Render a countdown
      return (
        <span style={{ fontSize: "1rem", paddingTop: "10px" }}>
          {days} Day(s) {hours} Hr - {minutes} Min - {seconds} Sec
        </span>
      );
    }
  };

  useEffect(() => {
    LivestreamService.getlivestreamById(id).then((res) => {
      if (res) {
        setSelectedLivestream([...res]);
        if (res[0]?.status !== "LIVE" && !moment().isAfter(startTime)) {
          var newStartTime = moment(res[0].schedule_From)
            .utc()
            .format("DD-MM-YYYY hh:mm:ss");
          var startTime: any = moment(newStartTime, "DD-MM-YYYY hh:mm:ss")
            .utc()
            .local();
          var newEndDate = moment().utc().local().format("DD-MM-YYYY hh:mm:ss");
          var endTime: any = moment(newEndDate, "DD-MM-YYYY hh:mm:ss");
          console.log(newStartTime, startTime, newEndDate, endTime);
          seconds = Math.floor((startTime - endTime) / 1000);
          minutes = Math.floor(seconds / 60);
          hours = Math.floor(minutes / 60);
          let days = Math.floor(hours / 24);
          hours = hours - days * 24;
          minutes = minutes - days * 24 * 60 - hours * 60;
          seconds =
            seconds - days * 24 * 60 * 60 - hours * 60 * 60 - minutes * 60;
          if (moment().isAfter(startTime)) {
            setLatestMinutes(0);
            setLatestHours(0);
            setLatestSeconds(0);
            clearInterval(myInterval);
            return;
          }
          setLatestMinutes(minutes);
          setLatestHours(hours);
          setLatestSeconds(seconds);
          console.log(moment().isAfter(startTime));
        } else {
          setLatestMinutes(0);
          setLatestHours(0);
          setLatestSeconds(0);
        }
      }
    });
    let myInterval = setInterval(() => {
      if (seconds > 0) {
        seconds = seconds - 1;
        setLatestSeconds(seconds);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          if (hours === 0) {
            clearInterval(myInterval);
          } else {
            hours = hours - 1;
            setLatestHours(hours);
            minutes = 59;
            setLatestMinutes(minutes);
            seconds = 59; //setSeconds(59);
            setLatestSeconds(seconds);
          }
        } else {
          minutes = minutes - 1;
          setLatestMinutes(minutes);
          seconds = 59; //setSeconds(59);
          setLatestSeconds(seconds);
        }
      }
    }, 1000);
    return () => {
      clearInterval(myInterval);
    };
  }, []);
  const scheduledFrom =
    Date.parse(selectedLivestream[0]?.schedule_From) - 19800000;
  const timeLeft = new Date(scheduledFrom).toJSON();

  const getLivestream = () => {
    LivestreamService.getlivestreamById(id).then((res) => {
      if (res) {
        setSelectedLivestream([...res]);
        if (res[0]?.status === "LIVE") {
          clearTimeout(timer);
          // window.open(`${window.location.origin}/#/livestream/${res[0]?._id}`, "_blank");
          history.push(`/livestream/${res[0]?._id}`);
        }
      }
    });
  };
  return (
    <section
      className="banner-cover livestream bgImg"
      style={{
        backgroundImage: selectedLivestream[0]?.thumbnail
          ? `url("${selectedLivestream[0]?.thumbnail}")`
          : "linear-gradient(to right, #DDEFBB, #FFEEEE)",
        backgroundPosition: "center center",
        backgroundSize: "cover",
      }}
    >
      {/* <img alt="banner" src={BannerImage} className="d-md-none livestream-main-bg-img" /> */}

      <div className="banner-cover-profile mx-auto">
        <div className="banner-cover-profile__right">
          {selectedLivestream.length > 0 && (
            <div className="banner-cover-profile__info">
              <h1 className="banner-cover-profile__info-title">
                {selectedLivestream[0]?.title}
              </h1>
              <strong className="d-block">
                {moment(selectedLivestream[0]?.schedule_From)
                  .utc()
                  .format("DD-MM-YYYY hh:mm A")}{" "}
                IST
              </strong>
              {selectedLivestream[0]?.status === "CANCEL" && (
                <strong className="d-block">Livestream Cancelled</strong>
              )}
              {selectedLivestream[0]?.status === "COMPLETED" && (
                <strong className="d-block">Livestream Completed</strong>
              )}
              {selectedLivestream[0]?.status === "LIVE" && (
                <strong className="d-block">
                  Livestream Started, Please wait ...
                </strong>
              )}
              {selectedLivestream[0]?.status !== "COMPLETED" &&
                selectedLivestream[0]?.status !== "LIVE" && (
                  <strong className="d-block" style={{ marginBottom: "0" }}>
                    <div
                      style={{
                        marginTop: "10px",
                        // marginBottom: "0 !important",
                      }}
                    >
                      Time left to play
                      <h1>
                        {" "}
                        <Countdown date={timeLeft} renderer={renderer} />
                      </h1>
                    </div>

                    {/* {(latestHours === 0 && latestMinutes === 0 && latestSeconds === 0)
                  ? null
                  :
                  <div>
                    <h1>{latestHours}:{latestMinutes}:{latestSeconds < 10 ? `0${latestSeconds}` : latestSeconds} </h1>
                    Time left to play
                  </div>
                } */}
                  </strong>
                )}
            </div>
          )}
          <div className="livestream-details">
            <div className="livestream-desc">
              <h3>About Livestream</h3>
              <p>{selectedLivestream[0]?.description}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default LivestreamSoon;
