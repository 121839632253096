import { Switch, Route } from "react-router";
import { MySubscriptionsInboxPage } from "../pages/dashboard/my-subscriptions/inbox";
import { MyLivestreamAttendedPage } from "../pages/dashboard/my-subscriptions/live-stream-attended";
import { MyLivestreamCancelledPage } from "../pages/dashboard/my-subscriptions/live-stream-cancelled";
import { MyLivestreamScheduledPage } from "../pages/dashboard/my-subscriptions/live-stream-scheduled";
import { MySubscriptionsPostsPage } from "../pages/dashboard/my-subscriptions/posts";
import { MySubscriptionsSubscriptionPage } from "../pages/dashboard/my-subscriptions/subscriptions";

const SubscriptionRoutes = () => {
    return (
        <Switch>
            <Route exact path="/dashboard/mysubscriptions/posts" component={MySubscriptionsPostsPage}></Route>
            <Route exact path="/dashboard/mysubscriptions/scheduled" component={MyLivestreamScheduledPage}></Route>
            <Route exact path="/dashboard/mysubscriptions/attended" component={MyLivestreamAttendedPage}></Route>
            <Route exact path="/dashboard/mysubscriptions/cancelled" component={MyLivestreamCancelledPage}></Route>

            <Route exact path="/dashboard/mysubscriptions/inbox" component={MySubscriptionsInboxPage}></Route>
            <Route exact path="/dashboard/mysubscriptions/subscriptions" component={MySubscriptionsSubscriptionPage}></Route>
        </Switch>
    );
}

export default SubscriptionRoutes;