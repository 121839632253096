import React, { useEffect, useState } from "react";
import { SubscriptionPackagesService } from "../../app/service/subscription-packages-service";
import { useHistory, useParams } from "react-router";
import { Link, NavLink } from "react-router-dom";
import { SubscriptionPackages } from "../../app/model/subscription-packages";
import { ClubService } from "../../app/service/club.service";
import { connect, useDispatch } from "react-redux";
import { Loading, PostCount } from "../../redux/actions";
import { Clubs, ClubsPost } from "../../app/model/clubs";
import { toast } from "react-toastify";
import Slider from "react-slick";
import moment from "moment";
import { Dropdown, DropdownButton } from "react-bootstrap";

interface Props {
  UserReducer?: any;
  loading?: (data: any) => void;
  LoginUserIdReducer?: any;
  PostCountReducer?: any;
  postCount?: (data: any) => void;
}

const Creations: React.FC<Props> = (props: Props) => {
  const [club, setClubs] = useState<Clubs[] | []>([]);
  const [subscriptionPackages, setSubscriptionPackages] = useState<
    SubscriptionPackages[] | []
  >([]);
  const [clubPosts, setclubPosts] = useState<ClubsPost[] | []>([]);
  const history = useHistory();
  const [filterSelectedPostType, setFilterSelectedPostType] =
    useState<string>("");
  const [filterSelectedPackageType, setFilterSelectedPackageType] =
    useState<string>("");
  const [more, setMore] = useState(false);
  const dispatch = useDispatch();
  const [showUserAlertPopup, setShowUserAlertPopup] = useState(false);
  const handleCloseUserAlertPopup = () => setShowUserAlertPopup(false);
  const handleShowUserAlertPopup = () => setShowUserAlertPopup(true);
  const [selectedPost, setSelectedPost] = useState<any>();
  const [showUserSubscribeAlertPopup, setShowUserSubscribeAlertPopup] =
    useState(false);
  const handleCloseUserSubscribeAlertPopup = () =>
    setShowUserSubscribeAlertPopup(false);
  const handleShowUserSubscribeAlertPopup = () =>
    setShowUserSubscribeAlertPopup(true);
  const [showUserUnSubscribeAlertPopup, setShowUserUnSubscribeAlertPopup] =
    useState(false);
  const handleCloseUserUnSubscribeAlertPopup = () =>
    setShowUserUnSubscribeAlertPopup(false);
  const handleShowUserUnSubscribeAlertPopup = () =>
    setShowUserUnSubscribeAlertPopup(true);
  const [selectedClubPacakge, setSelectedClubPacakge] = useState<any>();
  const [selectedClubPackageType, setSelectedClubPackageType] = useState("");
  const [isShowMorePerks, setIsShowMorePerks] = useState(-1);
  const [showPaymentSuccessPopup, setShowPaymentSuccessPopup] = useState(false);
  const handleClosePaymentSuccessPopup = () =>
    setShowPaymentSuccessPopup(false);
  const handleShowPaymentSuccessPopup = () => setShowPaymentSuccessPopup(true);
  const [selectedPaymet, setSelectedPaymet] = useState<any>("");
  const [amount, setAmount] = useState<any>("");
  const [selectedOptions, setSelectedOptions] = useState<any[]>([]);

  let { id } = useParams<{ id: string }>();
  let userId =
    sessionStorage.getItem("isLogin") || props.LoginUserIdReducer.loginUserId;

  useEffect(() => {
    if (props.loading) {
      dispatch(props.loading(true));
    }
    if (id) {
      getClub(id);
    }
  }, [id]);
  const clubSubscriberDetails = (
    subscriptionPackages: SubscriptionPackages[]
  ) => {
    if (userId || props?.LoginUserIdReducer?.loginUserId) {
      const data = {
        user_id: userId || props?.LoginUserIdReducer?.loginUserId,
        club_id: id,
      };
      ClubService.clubSubscriberDetails(data).then((res) => {
        if (res) {
          subscriptionPackages.forEach((element: any) => {
            element.isSubscribed = false;
            const isExist = res.find((sub: any) => {
              return sub.subscription_package_id === element._id;
            });
            if (isExist) {
              element.isSubscribed = true;
            } else {
              element.isSubscribed = false;
            }
          });
          setSubscriptionPackages([...subscriptionPackages]);
        }
        if (props.loading) {
          dispatch(props.loading(false));
        }
      });
    }
  };

  const getClub = (id: string) => {
    if (props.loading) {
      dispatch(props.loading(true));
    }
    ClubService.getClub(id).then((res) => {
      if (res) {
        setClubs(res);
      }
    });
    SubscriptionPackagesService.GET_SUBSCRIPTION_PACKAGES(id).then((res) => {
      if (res) {
        res.sort((a, b) => (a.name > b.name ? 1 : -1));
        setSubscriptionPackages([...res]);
        clubSubscriberDetails(res);
        if (props.loading) {
          dispatch(props.loading(false));
        }
      }
    });
    getClubPosts(id);
  };

  const getClubPosts = (id: string) => {
    const data = {
      user_id: userId || props?.LoginUserIdReducer?.loginUserId,
      club_id: id,
      filterType: filterSelectedPostType ? filterSelectedPostType : "",
      bundleType: filterSelectedPackageType ? filterSelectedPackageType : "",
    };
    ClubService.getUserSubscriptionPost(data).then((res) => {
      if (res) {
        res.forEach((element: any) => {
          element.isLikes =
            element.likes.length > 0
              ? element.likes.find((like: any) => {
                  if (
                    like.user_id ===
                    (userId || props?.LoginUserIdReducer?.loginUserId)
                  ) {
                    return true;
                  } else {
                    return false;
                  }
                })
              : false;
          if (element.selectedOptions) {
            try {
              const votes = JSON.parse(element.selectedOptions);
              const optios = JSON.parse(element.options);
              votes.forEach((selectedVote: any) => {
                if (
                  selectedVote.user_id ===
                  (userId || props?.LoginUserIdReducer?.loginUserId)
                ) {
                  optios.forEach((selectOption: any) => {
                    if (selectOption.name === selectedVote.name) {
                      selectOption.value = true;
                    }
                  });
                }
              });
              element.options = JSON.stringify(optios);
            } catch (error) {}
          }
        });
        setclubPosts([...res]);
      }
    });
  };

  const onCreateSubscriber = (paymentId: string) => {
    if (userId || props?.LoginUserIdReducer?.loginUserId) {
      const data = {
        user_id: userId || props?.LoginUserIdReducer?.loginUserId,
        club_id: selectedClubPacakge.club_id,
        subscription_package_id: selectedClubPacakge._id,
        package_for: selectedClubPackageType,
        amount:
          selectedClubPackageType === "MONTHLY"
            ? selectedClubPacakge.price_monthly
            : selectedClubPacakge.price_yearly,
        payment_id: paymentId,
      };
      ClubService.createClubSubscriber(data).then((res) => {
        if (res) {
          clubSubscriberDetails(subscriptionPackages);
          getClub(id);
          toast.success("Subscription successfully");
          handleCloseUserSubscribeAlertPopup();
        }
      });
    } else {
      history.push("/clubsingup");
    }
  };

  const onCreateUnSubscriber = () => {
    if (userId || props?.LoginUserIdReducer?.loginUserId) {
      const data = {
        user_id: userId || props?.LoginUserIdReducer?.loginUserId,
        club_id: selectedClubPacakge?.club_id || "",
        subscription_package_id: selectedClubPacakge?._id || "",
      };
      ClubService.unSubscribeSubscription(data).then((res) => {
        if (res) {
          clubSubscriberDetails(subscriptionPackages);
          getClub(id);
          handleCloseUserUnSubscribeAlertPopup();
          toast.success("UnSubscription successfully");
        }
      });
    } else {
      history.push("/clubsingup");
    }
  };

  const updatePostLikes = (post: any) => {
    const data = {
      club_id: id,
      user_id: sessionStorage.getItem("isLogin") || "",
      _id: post?._id,
      collectionName: post?.collectionName,
    };
    ClubService.updatePostLikes(data).then((res) => {
      if (res) {
        getClubPosts(id);
      } else {
        toast.error("Please Login");
      }
    });
  };
  const settings = {
    dots: false,
    arrows: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };
  const onSelectPost = (post: any) => {
    if (userId || props?.LoginUserIdReducer?.loginUserId) {
      setSelectedPost(post);
      if (post.PAID_POST && post.isLocked) {
        handleShowUserAlertPopup();
      } else {
        history.push(`/club-detail/${post._id}/${post.club_id}`);
      }
    } else {
      toast.error("Please login");
    }
  };

  const handleMore = (perksId: any, index: number) => {
    if (index === isShowMorePerks) {
      setIsShowMorePerks(-1);
    } else {
      setIsShowMorePerks(index);
    }
    let more = subscriptionPackages.filter((perk) => {
      return perksId === perk._id;
    });

    if (more[0]._id === perksId) {
      document.getElementById(perksId)?.classList.toggle("expanded");
    }
  };
  const showMore = () => {
    setMore(!more);
  };
  let club_id = sessionStorage.getItem("clubId") || "";
  const [publishedPosts, setPublishedPosts] = useState<ClubsPost[] | []>([]);
  const [clubTrackInfo, setClubTrackInfo] = useState<any>({});
  const [clubPostTrackInfo, setClubPostTrackInfo] = useState("");
  const [selectedPostData, setSelectedPostData] = useState<ClubsPost>();

  useEffect(() => {
    getPublishPost();
    getClubTotalInfo();
    ClubService.getClubSubscriptionPackages(club_id).then((res) => {
      if (res) {
        if (res.length > 0) {
          const data = res.filter(
            (subscription: any) => subscription?.status === "1"
          );
          setSubscriptionPackages([...data]);
        }
      }
    });
    return function cleanup() {
      document.body.classList.remove("innerpage");
    };
  }, []);

  const getClubTotalInfo = () => {
    if (club_id) {
      if (props.loading) {
        dispatch(props.loading(true));
      }
      ClubService.getClubTotalInfoByClubIds(club_id).then((res) => {
        if (res) {
          setClubTrackInfo(res);
        }
      });
      ClubService.getClubLastPostInfoByClubId(club_id).then((res) => {
        if (res?.status) {
          setClubPostTrackInfo(res.message);
        }
      });
    }
  };
  const getPublishPost = () => {
    if (club_id) {
      if (props.loading) {
        dispatch(props.loading(true));
      }
      ClubService.getClubPost(
        club_id,
        filterSelectedPostType,
        filterSelectedPackageType
      ).then((res) => {
        if (res) {
          setPublishedPosts([...res]);
          if (props.postCount) {
            dispatch(
              props.postCount({
                pulished: res.length,
                unpulished: props.PostCountReducer?.postCount?.unpulished,
              })
            );
          }
          if (props.loading) {
            dispatch(props.loading(false));
          }
        }
      });
    }
  };
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const onUnPublishClubPost = (data: ClubsPost) => {
    ClubService.unPublishClubPost(data).then((res) => {
      if (res) {
        toast.success("Club post unpublished successfully");
        getPublishPost();
      }
    });
  };

  const onSeeMore = (index: number) => {
    setSelectedPost(index);
  };

  const showDeletePostPopup = (post: any) => {
    handleShow();
    setSelectedPostData(post);
  };
  const onClickDeletPost = () => {
    const data = {
      _id: selectedPostData?._id,
      collectionName: selectedPostData?.collectionName,
    };
    ClubService.deleteClubPost(data).then((res) => {
      if (res) {
        toast.success("Club post deleted successfully");
        handleClose();
        getPublishPost();
      }
    });
  };

  const onSelectPackageType = (event: any) => {
    setFilterSelectedPackageType(event.target.value);
  };

  const onSeletcPostType = (event: any) => {
    setFilterSelectedPostType(event.target.value);
  };
  const onApplyFilter = () => {
    getPublishPost();
  };
  return (
    <div className="main-content">
      <div className="container">
        <section className="creation-container">
          <div className="d-flex justify-content-between">
            <h3>Creations</h3>
            Subscribers: {subscriptionPackages[0]?.total_fans}
          </div>
          <div className="row mb-5">
            <div className="col-md-4">
              {subscriptionPackages.map((subscription: any, index: number) => {
                return (
                  <div className="package-detail">
                    <strong className="mb-4 d-block">Package Details</strong>
                    <div className="d-flex flex-wrap justify-content-between mb-3">
                      <strong>Package Name</strong>
                      <span>{subscription?.name}</span>
                    </div>
                    <div className="d-flex flex-wrap justify-content-between mb-3">
                      <strong>Monthly Price</strong>
                      <span>&#8377; {subscription?.price_monthly}</span>
                    </div>
                    <div className="package-desc mb-3">
                      <strong className="d-block">Description(optional)</strong>
                      {subscription?.description}
                    </div>
                    <strong>Deliverables</strong>
                    <ol className="package-deliverables">
                      {subscription.perks.map(
                        (peark: any, perkIndex: number) => {
                          return <li key={perkIndex}>{peark?.value}</li>;
                        }
                      )}
                    </ol>
                    <hr className="mb-2" />
                    <Link
                      className="ms-auto fs-sml d-flex justify-content-end"
                      to={`/dashboard/myclubs/package/${subscription._id}`}
                    >
                      Edit
                    </Link>
                  </div>
                );
              })}
            </div>
            <div className="col-md-4">
              <div className="package-detail">
                <strong>Subscriber Growth</strong>
              </div>
            </div>
            <div className="col-md-4">
              <div className="package-detail">
                <strong>Revenue Growth</strong>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="main-content__top  ">
              <div className="custom-tab">
                <ul className="nav nav-pills">
                  <li className="nav-item" role="presentation">
                    <NavLink
                      className="nav-link"
                      to="/dashboard/posts/published"
                    >
                      <strong>Creations</strong> (
                      {props.PostCountReducer?.postCount
                        ? props.PostCountReducer?.postCount?.pulished
                        : 0}
                      )
                    </NavLink>
                  </li>
                </ul>
              </div>
              <div className="main-content__top-right ms-auto mb-2">
                <DropdownButton
                  id="dropdown-basic-button"
                  title="Create a Post"
                >
                  <Dropdown.Item href="/#/dashboard/clubpost/0/video/">
                    Video{" "}
                  </Dropdown.Item>
                  <Dropdown.Item href="/#/dashboard/clubpost/0/audio/">
                    Audio
                  </Dropdown.Item>
                  <Dropdown.Item href="/#/dashboard/clubpost/0/static/">
                    Image
                  </Dropdown.Item>
                </DropdownButton>
              </div>
            </div>
            <div className="filter-sec">
              asdf
            </div>
            {publishedPosts.length > 0 ? (
              <div
                className="tab-pane row"
                id="published"
                role="tabpanel"
                aria-labelledby="published-tab"
              >
                {publishedPosts.map((post, index) => {
                  return (
                    <div key={post?._id} className="col-md-4">
                      <div className="card post-card">
                        <div className="card-post__content">
                          <div className="card-post__content-left">
                            <div
                              className={`${
                                post?.type !== "2"
                                  ? "post-card__img d-none d-md-flex"
                                  : ""
                              }`}
                            >
                              {post?.type === "0" && (
                                <>
                                  {/* <i className="bi bi-camera-video"></i> */}
                                  {post?.content && (
                                    <video
                                      style={{ height: "180px", width: "100%" }}
                                      className="my_club_media"
                                      controlsList="nodownload"
                                      controls
                                    >
                                      <source
                                        src={post?.content}
                                        type="video/mp4"
                                      />
                                      Your browser does not support HTML video.
                                    </video>
                                  )}
                                </>
                              )}
                              {post?.type === "1" && (
                                <>
                                  <audio
                                    controls
                                    style={{ height: "180px", width: "100%" }}
                                  >
                                    <source
                                      src={post?.content}
                                      type="audio/mpeg"
                                    />
                                    Your browser does not support the audio
                                    element.
                                  </audio>
                                  {/* <i className="bi bi-mic"></i> */}
                                </>
                              )}
                              {post?.type === "2" && (
                                <Slider
                                  className="slider slider-w-images-3"
                                  {...settings}
                                >
                                  {JSON.parse(post?.content).map(
                                    (image: any, index: number) => {
                                      return (
                                        <section>
                                          <div className="slider-item">
                                            <div className="slider-item-img  ratio ratio-4x3">
                                              <img src={image?.img} alt="" />
                                            </div>
                                          </div>
                                        </section>
                                      );
                                    }
                                  )}
                                </Slider>
                              )}
                              {post?.type === "3" && (
                                <i className="bi bi-card-text"></i>
                              )}
                              {post?.type === "4" && (
                                <div
                                  className="d-flex justify-content-center align-items-center border-bottom"
                                  style={{
                                    height: "180px",
                                    width: "100%",
                                    fontSize: "48px",
                                  }}
                                >
                                  <i className="bi bi-diagram-3"></i>
                                </div>
                              )}
                            </div>
                            <div className="post-card__body">
                              <h3>
                                {post?.type === "0" && (
                                  <i className="bi bi-camera-video d-inline-flex d-md-none me-2 mr-md-none"></i>
                                )}
                                {post?.type === "1" && (
                                  <i className="bi bi-mic d-inline-flex d-md-none me-2 mr-md-none"></i>
                                )}
                                {post?.type === "2" && (
                                  <i className="bi bi-image d-inline-flex d-md-none me-2 mr-md-none"></i>
                                )}
                                {post?.type === "3" && (
                                  <i className="bi bi-card-text d-inline-flex d-md-none me-2 mr-md-none"></i>
                                )}
                                {post?.type === "4" && (
                                  <i className="bi bi-diagram-3 d-inline-flex d-md-none me-2 mr-md-none"></i>
                                )}
                                <NavLink
                                  to={`../../club-detail/${post?._id}/${post?.club_id}`}
                                >
                                  {post?.title}
                                </NavLink>
                              </h3>
                              <p>
                                {post?.description} &nbsp;
                                {selectedPost === index && (
                                  <a
                                    className="pointer"
                                    onClick={() => onSeeMore(-1)}
                                  >
                                    less
                                  </a>
                                )}
                                {selectedPost !== index && (
                                  <a
                                    className="pointer"
                                    onClick={() => onSeeMore(index)}
                                  >
                                    more...
                                  </a>
                                )}
                              </p>
                              {selectedPost === index && (
                                <ul>
                                  {post?.AVAILABLE && (
                                    <li>
                                      Available as a paid post for{" "}
                                      {post?.AVAILABLE} days
                                    </li>
                                  )}
                                  {post?.DELETE_POST && (
                                    <li>
                                      Delete the post after {post?.DELETE_POST}{" "}
                                      days
                                    </li>
                                  )}
                                  {post?.FREE_POST && (
                                    <li>
                                      Make the post free after {post?.FREE_POST}{" "}
                                      days
                                    </li>
                                  )}
                                  {post?.AVAILABLE_TO_SUBSCRIBER && (
                                    <li>
                                      The post shall be available only to
                                      subscribers after{" "}
                                      {post?.AVAILABLE_TO_SUBSCRIBER} days
                                    </li>
                                  )}
                                </ul>
                              )}
                            </div>
                          </div>
                          <a className="d-block d-lg-none btn-action" href="">
                            <i className="bi bi-three-dots-vertical"></i>
                          </a>
                          <div className="card-post__content-right">
                            {/* <a className="btn btn-link no-bdr" href="" data-bs-toggle="tooltip" data-bs-placement="top" title="Share">
                                        <i className="bi bi-share"></i>
                                        <span className="d-inline-flex d-lg-none">Share</span>
                                    </a> */}
                            {/* {post?.type === "0" && <NavLink className="btn btn-link no-bdr" to={`/dashboard/video/${post?._id}`} data-bs-toggle="tooltip" data-bs-placement="top" title="Edit">
                                        <i className="bi bi-pencil"></i><span className="d-inline-flex d-lg-none">Edit</span>
                                    </NavLink>}
                                    {post?.type === "1" && <NavLink className="btn btn-link no-bdr" to={`/dashboard/audio/${post?._id}`} data-bs-toggle="tooltip" data-bs-placement="top" title="Edit">
                                        <i className="bi bi-pencil"></i><span className="d-inline-flex d-lg-none">Edit</span>
                                    </NavLink>}
                                    {post?.type === "2" && <NavLink className="btn btn-link no-bdr" to={`/dashboard/staticpost/${post?._id}`} data-bs-toggle="tooltip" data-bs-placement="top" title="Edit">
                                        <i className="bi bi-pencil"></i><span className="d-inline-flex d-lg-none">Edit</span>
                                    </NavLink>}
                                    {post?.type === "3" && <NavLink className="btn btn-link no-bdr" to={`/dashboard/text/${post?._id}`} data-bs-toggle="tooltip" data-bs-placement="top" title="Edit">
                                        <i className="bi bi-pencil"></i><span className="d-inline-flex d-lg-none">Edit</span>
                                    </NavLink>}
                                    {post?.type === "4" && <NavLink className="btn btn-link no-bdr" to={`/dashboard/polls/${post?._id}`} data-bs-toggle="tooltip" data-bs-placement="top" title="Edit">
                                        <i className="bi bi-pencil"></i><span className="d-inline-flex d-lg-none">Edit</span>
                                    </NavLink>} */}
                            {/* <a
                            className="btn btn-link no-bdr"
                            onClick={() => onUnPublishClubPost(post)}
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title="Unpublish"
                          >
                            <i className="bi bi-book"></i>
                            <span className="d-inline-flex d-lg-none">
                              Unpublish
                            </span>
                          </a>
                          <button
                            onClick={() => showDeletePostPopup(post)}
                            className="btn btn-link-orange no-bdr no-text-desktop"
                            type="button"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title="Delete"
                          >
                            <i className="bi bi-trash"></i>
                            <span className="d-inline-flex d-lg-none">
                              Delete
                            </span>
                          </button> */}
                          </div>
                        </div>
                        <div className="card-post__footer d-flex px-3 pb-3 align-items-center">
                          <div className="card-post__footer-left">
                            <ul className="list-style-none mb-0">
                              <li>
                                Published on{" "}
                                {moment(post?.published_dt).format("lll")}
                              </li>
                              {/* <li>Bundle 1</li> */}
                            </ul>
                          </div>
                          <div className="card-post__footer-right d-flex ms-auto">
                            <a
                              className=" p-1 btn btn-link no-bdr"
                              onClick={() => onUnPublishClubPost(post)}
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              title="Unpublish"
                            >
                              <i className="bi bi-book"></i>
                              <span className="d-inline-flex d-lg-none">
                                Unpublish
                              </span>
                            </a>
                            <button
                              onClick={() => showDeletePostPopup(post)}
                              className="p-1 btn btn-link-orange no-bdr no-text-desktop"
                              type="button"
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              title="Delete"
                            >
                              <i className="bi bi-trash"></i>
                              <span className="d-inline-flex d-lg-none">
                                Delete
                              </span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : (
              <div className="text-center">No data</div>
            )}
          </div>
        </section>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    UserReducer: state.UserReducer,
    LoginUserIdReducer: state.LoginUserIdReducer,
    PostCountReducer: state.PostCountReducer,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    loading: (data: any) => dispatch(Loading(data)),
    postCount: (data: any) => dispatch(PostCount(data)),
  };
};

const connectedNavBar = connect(mapStateToProps, mapDispatchToProps)(Creations);
export { connectedNavBar as Creations };
