import moment from "moment";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { connect, useDispatch } from "react-redux";
import { NavLink, useParams, Link } from "react-router-dom";
import { toast } from "react-toastify";
import { ClubsPost } from "../../../app/model/clubs";
import { SubscriptionPackages } from "../../../app/model/subscription-packages";
import { ClubService } from "../../../app/service/club.service";
import { Loading, PostCount } from "../../../redux/actions";
import highchart from "../../../assets/img/highchart.png";
import Slider from "react-slick";
interface Props {
  UserReducer?: any;
  LoginUserIdReducer?: any;
  loading?: (data: any) => void;
  postCount?: (data: any) => void;
  PostCountReducer?: any;
}

const PostsDraftsPage: React.FC<Props> = (props: Props) => {
  const [clubTrackInfo, setClubTrackInfo] = useState<any>({});
  const [clubPostTrackInfo, setClubPostTrackInfo] = useState('');
  let club_id = sessionStorage.getItem("clubId") || "";
  const [unPublishedPosta, setUnPublishedPosta] = useState<any[] | []>([]);
  const [selectedPost, setSelectedPost] = useState(-1);
  const [selectedPostData, setSelectedPostData] = useState<ClubsPost>();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [subscriptionPackages, setSubscriptionPackages] = useState<
    SubscriptionPackages[] | []
  >([]);
  const [filterSelectedPostType, setFilterSelectedPostType] =
    useState<string>("");
  const [filterSelectedPackageType, setFilterSelectedPackageType] =
    useState<string>("");
  const dispatch = useDispatch();
  useEffect(() => {
    if (props.loading) {
      dispatch(props.loading(true));
    }
    getClubTotalInfo();
    getUnPublishPost();
    ClubService.getClubSubscriptionPackages(club_id).then((res) => {
      if (res) {
        if (res.length > 0) {
          const data = res.filter(
            (subscription: any) => subscription?.status === "1"
          );
          setSubscriptionPackages([...data]);
        }
      }
    });
    return function cleanup() {
      document.body.classList.remove("innerpage");
    };
  }, []);

  const getUnPublishPost = () => {
    if (club_id) {
      if (props.loading) {
        dispatch(props.loading(true));
      }
      ClubService.getUnPublishPost(
        club_id,
        filterSelectedPackageType,
        filterSelectedPostType
      ).then((res) => {
        if (res) {
          setUnPublishedPosta([...res]);
          if (props.postCount) {
            dispatch(
              props.postCount({
                pulished: props.PostCountReducer?.postCount?.pulished,
                unpulished: res.length,
              })
            );
          }
          if (props.loading) {
            dispatch(props.loading(false));
          }
        }
      });
    }
  };
  const onPublishClubPost = (data: ClubsPost) => {
    ClubService.publishClubPost(data).then((res) => {
      if (res) {
        toast.success("Club post published successfully");
        getUnPublishPost();
      }
    });
  };

  const onSeeMore = (index: number) => {
    setSelectedPost(index);
  };

  const showDeletePostPopup = (post: any) => {
    handleShow();
    setSelectedPostData(post);
  };
  const onClickDeletPost = () => {
    const data = {
      _id: selectedPostData?._id,
      collectionName: selectedPostData?.collectionName,
    };
    ClubService.deleteClubPost(data).then((res) => {
      if (res) {
        toast.success("Club post deleted successfully");
        handleClose();
        getUnPublishPost();
      }
    });
  };

  const onSelectPackageType = (event: any) => {
    setFilterSelectedPackageType(event.target.value);
  };

  const onSeletcPostType = (event: any) => {
    setFilterSelectedPostType(event.target.value);
  };
  const onApplyFilter = () => {
    getUnPublishPost();
  };

  const getClubTotalInfo = () => {
    if (club_id) {
      if (props.loading) {
        dispatch(props.loading(true));
      }
      ClubService.getClubTotalInfoByClubIds(club_id).then((res) => {
        if (res) {
          setClubTrackInfo(res);
        }
      });
      ClubService.getClubLastPostInfoByClubId(club_id).then((res) => {
        if (res?.status) {
          setClubPostTrackInfo(res.message);
        }
      });
    }
  }

  const settings = {
    dots: false,
    arrows: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };
  return (
    <div className="row posts-sec">
      <div className="col-md-8">
        <div className="filter-sec">
          <form className="row g-2 d-none d-md-inline-flex">
            <div className="col-auto align-self-center">
              <label>Filter by:</label>
            </div>
            <div className="col-auto">
              <select
                className="form-select form-select-sm"
                onChange={onSeletcPostType}
              >
                <option value="">Select</option>
                <option value="0">Video Posts</option>
                <option value="1">Audio Posts</option>
                <option value="2">Image Posts</option>
                <option value="3">Text Posts</option>
                <option value="4">Poll Posts</option>
              </select>
            </div>
            <div className="col-auto">
              <select
                className="form-select form-select-sm"
                onChange={onSelectPackageType}
              >
                <option value="">All packages</option>
                {subscriptionPackages.map(
                  (subscription: any, index: number) => {
                    return (
                      <option key={index} value={subscription._id}>
                        {subscription?.name}{" "}
                      </option>
                    );
                  }
                )}
              </select>
            </div>
            <div className="col-auto">
              <a className="btn btn-outline-black" onClick={onApplyFilter}>
                Apply
              </a>
            </div>
          </form>
          <a
            className="d-inline-flex d-md-none btn btn-outline-black"
            href="#offCanvasFilter"
            data-bs-toggle="offcanvas"
            data-bs-target="#offCanvasFilter"
            aria-controls="offCanvasFilter"
          >
            Filter
          </a>
        </div>
        <div
          className="tab-pane row"
          id="drafts"
          role="tabpanel"
          aria-labelledby="drafts-tab"
        >
          {unPublishedPosta.length > 0 ? (
            <>
              {unPublishedPosta.map((element, index) => {
                return (
                  <div className="col-md-6" key={element._id}>
                    <div className="card post-card video">
                      <div className="card-post__content">
                        <div className="card-post__content-left">
                          <div className={`${element?.type !== "2" ? 'post-card__img d-none d-md-flex' : ''}`}>
                            {element?.type === "0" && (
                              <>
                                {/* <i className="bi bi-camera-video"></i> */}
                                <video
                                  style={{ height: "180px", width: "100%" }}
                                  className="my_club_media"
                                  controlsList="nodownload"
                                  controls
                                >
                                  <source
                                    src={element.content}
                                    type="video/mp4"
                                  />
                                  Your browser does not support HTML video.
                                </video>
                              </>
                            )}
                            {element?.type === "1" && (
                              <audio
                                controls
                                style={{ height: "180px", width: "100%" }}
                              >
                                <source
                                  src={element?.content}
                                  type="audio/mpeg"
                                />
                                Your browser does not support the audio element.
                              </audio>
                              // <i className="bi bi-mic"></i>
                            )}
                            {element?.type === "2" && (
                              <Slider
                                className="slider slider-w-images-3"
                                {...settings}
                              >
                                {JSON.parse(element?.content).map(
                                  (image: any, index: number) => {
                                    return (
                                      <section>
                                        <div className="slider-item">
                                          <div className="slider-item-img  ratio ratio-4x3">
                                            <img src={image?.img} alt="" />
                                          </div>
                                        </div>
                                      </section>
                                    );
                                  }
                                )}
                              </Slider>)}
                            {element?.type === "3" && (
                              <i className="bi bi-card-text"></i>
                            )}
                            {element?.type === "4" && (
                              <div
                                className="d-flex justify-content-center align-items-center border-bottom"
                                style={{
                                  height: "180px",
                                  width: "100%",
                                  fontSize: "48px",
                                }}
                              >
                                <i className="bi bi-diagram-3"></i>
                              </div>
                            )}
                          </div>
                          <div className="post-card__body">
                            <h3>
                              {" "}
                              {element?.type === "0" && (
                                <NavLink
                                  to={`/dashboard/clubpost/${element?._id}/video`}
                                >
                                  {" "}
                                  <i className="bi bi-camera-video d-inline-flex d-md-none me-2 mr-md-none"></i>
                                  {element?.title}
                                </NavLink>
                              )}
                              {element?.type === "1" && (
                                <NavLink
                                  to={`/dashboard/clubpost/${element?._id}/audio`}
                                >
                                  <i className="bi bi-mic d-inline-flex d-md-none me-2 mr-md-none"></i>
                                  {element?.title}
                                </NavLink>
                              )}
                              {element?.type === "2" && (
                                <NavLink
                                  to={`/dashboard/clubpost/${element?._id}/static`}
                                >
                                  <i className="bi bi-image d-inline-flex d-md-none me-2 mr-md-none"></i>
                                  {element?.title}
                                </NavLink>
                              )}
                              {element?.type === "3" && (
                                <NavLink
                                  to={`/dashboard/clubpost/${element?._id}/text`}
                                >
                                  <i className="bi bi-card-text d-inline-flex d-md-none me-2 mr-md-none"></i>
                                  {element?.title}
                                </NavLink>
                              )}
                              {element?.type === "4" && (
                                <NavLink
                                  to={`/dashboard/clubpost/${element?._id}/polls`}
                                >
                                  <i className="bi bi-diagram-3 d-inline-flex d-md-none me-2 mr-md-none"></i>
                                  {element?.title}
                                </NavLink>
                              )}
                            </h3>
                            <p>
                              {element?.description}
                              &nbsp;
                              {selectedPost === index && (
                                <a
                                  className="pointer"
                                  onClick={() => onSeeMore(-1)}
                                >
                                  less
                                </a>
                              )}
                              {selectedPost !== index && (
                                <a
                                  className="pointer"
                                  onClick={() => onSeeMore(index)}
                                >
                                  more...
                                </a>
                              )}
                            </p>
                            {selectedPost === index && (
                              <ul>
                                {element?.AVAILABLE && (
                                  <li>
                                    Available as a paid post for{" "}
                                    {element?.AVAILABLE} days.
                                  </li>
                                )}
                                {element?.DELETE_POST && (
                                  <li>
                                    Delete the post after {element?.DELETE_POST}{" "}
                                    days
                                  </li>
                                )}
                                {element?.FREE_POST && (
                                  <li>
                                    Make the post free after{" "}
                                    {element?.FREE_POST} days
                                  </li>
                                )}
                                {element?.AVAILABLE_TO_SUBSCRIBER && (
                                  <li>
                                    The post shall be available only to
                                    subscribers after{" "}
                                    {element?.AVAILABLE_TO_SUBSCRIBER} days
                                  </li>
                                )}
                              </ul>
                            )}
                          </div>
                        </div>
                        <a className="d-block d-lg-none btn-action" href="">
                          <i className="bi bi-three-dots-vertical"></i>
                        </a>
                        <div className="card-post__content-right">
                          {/* <a className="btn btn-link no-bdr" href="" data-bs-toggle="tooltip" data-bs-placement="top" title="Schedule">
                                    <i className="bi bi-clock"></i>
                                    <span className="d-inline-flex d-lg-none">Schedule</span>
                                </a> */}
                        </div>
                      </div>
                      <div className="card-post__footer d-flex px-3 pb-3 align-items-center">
                        <div className="card-post__footer-left">
                          <ul className="list-style-none mb-0">
                            <li>
                              Published on{" "}
                              {moment(element?.createdAt).format("lll")}
                            </li>
                            {/* <li>Bundle 1</li> */}
                          </ul>
                        </div>
                        <div className="card-post__footer-right d-flex ms-auto">
                          {element?.type === "0" && (
                            <NavLink
                              className="btn btn-link no-bdr p-1"
                              to={`/dashboard/clubpost/${element?._id}/video`}
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              title="Edit"
                            >
                              <i className="bi bi-pencil"></i>
                              <span className="d-inline-flex d-lg-none">
                                Edit
                              </span>
                            </NavLink>
                          )}
                          {element?.type === "1" && (
                            <NavLink
                              className="btn btn-link no-bdr p-1"
                              to={`/dashboard/clubpost/${element?._id}/audio`}
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              title="Edit"
                            >
                              <i className="bi bi-pencil"></i>
                              <span className="d-inline-flex d-lg-none">
                                Edit
                              </span>
                            </NavLink>
                          )}
                          {element?.type === "2" && (
                            <NavLink
                              className="btn btn-link no-bdr p-1"
                              to={`/dashboard/clubpost/${element?._id}/static`}
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              title="Edit"
                            >
                              <i className="bi bi-pencil"></i>
                              <span className="d-inline-flex d-lg-none">
                                Edit
                              </span>
                            </NavLink>
                          )}
                          {element?.type === "3" && (
                            <NavLink
                              className="btn btn-link no-bdr p-1"
                              to={`/dashboard/clubpost/${element?._id}/text`}
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              title="Edit"
                            >
                              <i className="bi bi-pencil"></i>
                              <span className="d-inline-flex d-lg-none">
                                Edit
                              </span>
                            </NavLink>
                          )}
                          {element?.type === "4" && (
                            <NavLink
                              className="btn btn-link no-bdr p-1"
                              to={`/dashboard/clubpost/${element?._id}/polls`}
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              title="Edit"
                            >
                              <i className="bi bi-pencil"></i>
                              <span className="d-inline-flex d-lg-none">
                                Edit
                              </span>
                            </NavLink>
                          )}
                          <button
                            className="btn btn-primary p-1"
                            onClick={() => onPublishClubPost(element)}
                          >
                            Publish
                          </button>
                          <a
                            className="btn btn-link-orange no-bdr p-1"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title="Delete"
                            onClick={() => showDeletePostPopup(element)}
                          >
                            <i className="bi bi-trash3"></i>
                            <span className="d-inline-flex d-lg-none">
                              Delete
                            </span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </>
          ) : (
            <div className="text-center">No data</div>
          )
          }
        </div >
      </div >
      <div className="col-md-4">
        <div className="activity-info-sec">
          {clubPostTrackInfo && <div className="activity-info">
            <p>
              {clubPostTrackInfo}.
            </p>
            <Link to="/dashboard/post">Create a post</Link>
          </div>}
          <div className="activity-info">

            <p>
              Subscribers: <strong>{clubTrackInfo?.total_subscriber}</strong>
            </p>
            <p>
              Posts: <strong>{clubTrackInfo?.total_posts}</strong>
            </p>
            <p>
              Views: <strong>{clubTrackInfo?.total_views}</strong>
            </p><p>
              Comments: <strong>{clubTrackInfo?.total_comments}</strong>
            </p>
            <p>
              Likes: <strong>{clubTrackInfo?.total_likes}</strong>
            </p>
          </div>
          {/* <div className="activity-info">
              <strong className="activity-info-title">Recent Activity</strong>
              <img src={highchart} alt="" />
            </div> */}
        </div>
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton style={{ borderBottom: "none" }}>
          <Modal.Title style={{ marginBottom: "0" }}>Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure want to delete ({selectedPostData?.title}) this post
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-outline-black"
            type="button"
            onClick={handleClose}
          >
            Close
          </button>
          <button
            className="btn btn-primary"
            type="button"
            onClick={onClickDeletPost}
          >
            Yes
          </button>
        </Modal.Footer>
      </Modal>
    </div >
  );
};
const mapStateToProps = (state: any) => {
  return {
    UserReducer: state.UserReducer,
    LoginUserIdReducer: state.LoginUserIdReducer,
    PostCountReducer: state.PostCountReducer,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    loading: (data: any) => dispatch(Loading(data)),
    postCount: (data: any) => dispatch(PostCount(data)),
  };
};

const connectedNavBar = connect(
  mapStateToProps,
  mapDispatchToProps
)(PostsDraftsPage);
export { connectedNavBar as PostsDraftsPage };
