import moment from "moment";
import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { LivestreamService } from "../../../../app/service/livestream";
import Capture from "../../../../assets/img/Capture1.png";
import { TooltipWrapper } from "../../../../components/tooltip";
import { Loading } from "../../../../redux/actions";

interface Props {
  userId: string;
  UserReducer?: any;
  loading?: (data: any) => void;
  LoginUserIdReducer?: any;
}

const MyLivestreamsCompletedPage: React.FC<Props> = (props: Props) => {
  let userId = sessionStorage.getItem("isLogin") || "";
  const [livestream, setLivestream] = useState<any[] | []>([]);
  const dispatch = useDispatch();

  useEffect(() => {
    getlivestream()
    if (props.loading) {
      dispatch(props.loading(true));
    }

    LivestreamService.getlivestream("COMPLETED", userId).then((res) => {
      if (res) {
        const data = res.sort((a: any, b: any) =>
          a.schedule_From > b.schedule_From
            ? 1
            : b.schedule_From > a.schedule_From
              ? -1
              : 0
        );
        setLivestream([...data]);
        if (props.loading) {
          dispatch(props.loading(false));
        }
      }
    }
    );
  }, []);
  const getlivestream = () => {
    if (props.loading) {
      dispatch(props.loading(true));
    }
    LivestreamService.getlivestream("COMPLETED", userId).then((res) => {
      if (res) {
        const data = res.sort((a: any, b: any) =>
          a.schedule_From < b.schedule_From
            ? 1
            : b.schedule_From < a.schedule_From
              ? -1
              : 0
        );
        setLivestream([...res]);
        if (props.loading) {
          dispatch(props.loading(false));
        }
      }
    });
  };
  const onDelete = (data: any) => {
    LivestreamService.deleteLivestream(data).then((res) => {
      if (res) {
        getlivestream();
      }
    });
  };

  return (
    <div className="tab-content" id="pills-tabContent">
      <div
        className="tab-pane active"
        id="details"
        role="tabpanel"
        aria-labelledby="details-tab"
      >
        <div className="livestream-table-view table-responsive">
          <table className="table">
            <thead>
              <tr>
                <th scope="col"></th>
                <th scope="col" style={{ minWidth: "150px" }}>
                  Title
                </th>
                <th scope="col" style={{ minWidth: "250px" }}>
                  Schedule
                </th>
                <th scope="col" style={{ minWidth: "120px" }}>
                  Ticket Price
                </th>
                <th scope="col" style={{ minWidth: "70px" }}>
                  Sold
                </th>
                <th scope="col" style={{ minWidth: "130px" }}>
                  Revenue
                </th>
                <th scope="col" style={{ minWidth: "100px" }}>
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {/* Card Subscription */}
              {livestream.map((data: any, index: number) => {
                return (
                  <tr key={index}>
                    <td>
                      <img
                        src={data?.thumbnail ? data?.thumbnail : Capture}
                        alt="draft"
                        style={{ height: "40px", width: "40px" }}
                      />
                    </td>
                    <td>{data?.title}</td>
                    <td>
                      {moment(data?.schedule_From)
                        .utc()
                        .format("dddd, MMM DD  YYYY")}
                      , <br/>
                      {moment(data?.schedule_From).utc().format("LT")} -{" "}
                      {moment(data?.schedule_To).utc().format("LT")}
                    </td>
                    <td>INR {data?.price}</td>
                    <td>{data?.tickets}</td>
                    <td>
                      INR {data?.tickets ? data?.tickets * data.price : 0}
                    </td>
                    <td>
                      <button 
                      className="border-0 bg-transparent mt-auto text-danger"
                      onClick={() => onDelete(data)}
                      >
                        <TooltipWrapper
                          placement="top"
                          tooltipText="Delete"
                          iconName="bi bi-trash3"
                        />
                      </button>
                    </td>
                  </tr>
                );
              })}

              {livestream.length <= 0 && (
                <tr>
                  <td className="text-center" colSpan={8}>
                    No livestream data
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        {/* Card Subscription */}
        {/* <div className="card-subscription">
          <div className="card-subscription-left">
            <div className="card-subscription-img">
              <Link to="">
                <img src={Capture} alt="draft" style={{ height: "150px" }} />
              </Link>
            </div>
            <div className="card-subscription-detail">
              <h3 className="card-subscription-title">
                <Link to="">
                  Rubaroo{" "}
                  <span className="badge rounded-pill bg-primary ms-2">
                    Private
                  </span>
                </Link>
              </h3>
              <div className="subscription-schedule">
                <strong className="subscription-schedule-date">
                  Saturday, August 7
                </strong>
                <strong className="subscription-schedule-time">
                  4:30pm - 5:30pm
                </strong>
              </div>
              <div className="subscription-detail">
                <span className="">
                  Ticket Price: <strong className="">INR 499</strong>
                </span>
              </div>
              <div className="subscription-detail">
                <span className="subscription-term">
                  Tickets Booked*: <strong>15/50</strong>
                </span>
                <span className="subscription-price">
                  Your earning: <strong>5386 INR</strong>
                </span>
              </div>
              <div className="comment-sec">
                <strong>Comments</strong>
                <ul>
                  <li>
                    <strong>@username:</strong> Lorem ipsum dummy comment:)
                  </li>
                  <li>
                    <strong>@username:</strong> Lorem ipsum dummy comment:)
                  </li>
                  <li>
                    <Link to="">View all comments</Link>
                  </li>
                </ul>
              </div>
              <div className="d-flex">
                <button className="btn btn-outline-black me-2">
                  <i className="bi bi-camera-reels-fill"></i> View recording
                </button>
              </div>
            </div>
          </div>
          <div className="card-subscription-right">
            <div className="rating-sec">
              <div className="rating-group">
                <input
                  disabled
                  checked
                  className="rating__input rating__input--none"
                  name="rating3"
                  id="rating3-none"
                  value="0"
                  type="radio"
                />
                <label
                  aria-label="1 star"
                  className="rating__label"
                  htmlFor="rating3-1"
                >
                  <i className="rating__icon rating__icon--star bi bi-star-fill"></i>
                </label>
                <input
                  className="rating__input"
                  name="rating3"
                  id="rating3-1"
                  value="1"
                  type="radio"
                />
                <label
                  aria-label="2 stars"
                  className="rating__label"
                  htmlFor="rating3-2"
                >
                  <i className="rating__icon rating__icon--star bi bi-star-fill"></i>
                </label>
                <input
                  className="rating__input"
                  name="rating3"
                  id="rating3-2"
                  value="2"
                  type="radio"
                />
                <label
                  aria-label="3 stars"
                  className="rating__label"
                  htmlFor="rating3-3"
                >
                  <i className="rating__icon rating__icon--star bi bi-star-fill"></i>
                </label>
                <input
                  className="rating__input"
                  name="rating3"
                  id="rating3-3"
                  value="3"
                  type="radio"
                />
                <label
                  aria-label="4 stars"
                  className="rating__label"
                  htmlFor="rating3-4"
                >
                  <i className="rating__icon rating__icon--star bi bi-star-fill"></i>
                </label>
                <input
                  className="rating__input"
                  name="rating3"
                  id="rating3-4"
                  value="4"
                  type="radio"
                />
                <label
                  aria-label="5 stars"
                  className="rating__label"
                  htmlFor="rating3-5"
                >
                  <i className="rating__icon rating__icon--star bi bi-star-fill"></i>
                </label>
                <input
                  className="rating__input"
                  name="rating3"
                  id="rating3-5"
                  value="5"
                  type="radio"
                />
              </div>
              <span className="rating-count fs-xsml">150</span>
            </div>
            <div className="card-subscription-right-btm">
              <button className="btn btn-outline-orange mt-auto">Delete</button>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
};
const mapStateToProps = (state: any) => {
  return {
    UserReducer: state.UserReducer,
    LoginUserIdReducer: state.LoginUserIdReducer,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    loading: (data: any) => dispatch(Loading(data)),
  };
};

const connectedNavBar = connect(
  mapStateToProps,
  mapDispatchToProps
)(MyLivestreamsCompletedPage);
export { connectedNavBar as MyLivestreamsCompletedPage };