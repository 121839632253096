import React from "react";
import { Link } from "react-router-dom";

type Props = {};

const PrivacyPolicy = (props: Props) => {
  return (
    <div className="container pt-5">
      <h3 className="pb-3">Privacy Policy</h3>
      <div className="content-info">
        <ol className="roman">
          <li>
            <strong>Introduction</strong>
            <ol>
              <li>
                Clanworld is committed to protecting the privacy rights and
                grants importance to the confidentiality of its users. This
                Privacy Policy (hereinafter referred to as{" "}
                <strong>“Policy”</strong>) read with the Terms &amp; Conditions
                available at our website
                <Link to="https://www.clanworld.ai">
                  [www.clanworld.ai]
                </Link>{" "}
                (hereinafter referred to as <strong>“Platform”</strong>) applies
                to all services offered to persons using or visiting
                (hereinafter referred to as <strong>“Users”</strong> or{" "}
                <strong>“You”</strong> or <strong>“Your”</strong>) the Platform.
              </li>
              <li>
                Clanworld is owned and operated by Irida Interactive Private
                Limited having its registered office at 93, 3rd Floor, Okhla
                Phase III Industrial Estate, New Delhi-110020, India. Unless
                otherwise noted in this Policy, the terms{" "}
                <strong>"Clanworld"</strong>, <strong>"we"</strong>,
                <strong>“our”</strong> and <strong>"us"</strong> refer to Irida
                Interactive Private Limited (hereinafter referred to as{" "}
                <strong>“Company”</strong> or <strong>“We”</strong> or{" "}
                <strong>“Our”</strong> or <strong>“Us”</strong>).
              </li>
              <li>
                This Policy is designed to clearly inform You of how We collect,
                store, use and disclose Your Personal Information (defined
                hereafter) when You access or use Our Platform and other related
                services which includes all the content, information, made
                available to You (hereinafter collectively referred as
                <strong>“Services”</strong>). This Policy also covers Our
                treatment of any Personal Information that third parties share
                with the Company.
              </li>
              <li>
                We are a company based and existing in India and thus bound by
                the applicable laws of India. This Policy has been prepared in
                accordance with applicable Indian laws, including the Indian
                Information Technology Act, 2000.
              </li>
              <li>
                By accepting Our Privacy Policy and Terms &amp; Conditions
                during registration or installation, or by otherwise accessing
                or using the Services, You consent to Our collection, storage,
                use and disclosure of Your Personal Information as outlined in
                this Policy.
              </li>
              <li>
                This Policy will primarily help Users understand:
                <ol style={{ listStyleType: "lower-alpha" }}>
                  <li>
                    The nature of information We collect about a User directly
                    or through third parties;
                  </li>
                  <li>
                    The sources from where a User’s information is collected;
                  </li>
                  <li>
                    The manner in which a User’s information is collected;
                  </li>
                  <li>The purpose of collecting a User’s information;</li>
                  <li>
                    The manner in which a User’s information is stored, used and
                    processed by the Company;
                  </li>
                  <li>
                    The rights a User has under applicable laws regarding such
                    information collected by the Company;
                  </li>
                  <li>
                    The process to contact the Company for any issues regarding
                    the collection or use of a User’s information.
                  </li>
                </ol>
              </li>
            </ol>
          </li>
          <li>
            <strong>Information Collected and Used</strong>
            <p>
              We respect the privacy of Our Users and are thus committed to
              protecting it in all respects. The information about the User is
              collected by Us as: (i) information provided by Users at the time
              of their registering on the Platform; (ii) information
              automatically tracked during User's navigation on the Platform;
              (iii) communicated directly to Us via e-mail or telephone or
              another channel; and/or (iv) information that is easily and
              readily available on the public domain/ social media accounts of
              the User.{" "}
            </p>
            <p>
              A User’s information collected by the Company primarily consists
              of: Personal Information and Sensitive Personal Information
              (collectively referred to as{" "}
              <strong>“Personal Information”</strong>)
            </p>
            <ul className="roman" style={{ listStyleType: "upper-alpha" }}>
              <li>
                <strong>Personal Information</strong>
                <p>
                  When a User creates an account on the Company’s mobile
                  application or visits the Company website or enters his/her
                  information on any internet forum owned by the Company, We may
                  directly collect or ask the User to provide certain Personal
                  Information including, but not limited to, the following:
                </p>
                <ul style={{ listStyleType: "lower-alpha" }}>
                  <li>First and last name</li>
                  <li>Personal e-mail id</li>
                  <li>Date of birth</li>
                  <li>Gender</li>
                  <li>Contact information</li>
                  <li>Username &amp; password</li>
                  <li>Billing Address</li>
                  <li>Internet Protocol (“IP”) address</li>
                  <li>
                    Profile photo and other data the User makes available
                    publicly or to the Company from his/her social media
                    accounts when requested to link it to Our website.
                  </li>
                </ul>
                <p>
                  Personal Information means any information about a User from
                  which that person can be identified. It does not include data
                  where the identity of a User has been removed [anonymous data]
                  such as cookies, web beacons and other browsing information.
                </p>
                <p>
                  Browsing information is information collected through cookies
                  and web beacons to track what features or web-pages You have
                  viewed on Our website or mobile application, and other
                  information about Your browser and browsing behaviour. We use
                  browsing information to improve the design and content of Our
                  website and mobile application, to suggest content and
                  products that We think may be relevant to You, and to help Us
                  learn things like what pages are most attractive to Our
                  visitors, and what promotions visitors like to see, and to
                  gauge the success of Our advertising campaigns.
                </p>
                <p>
                  Because cookies and web beacons are the technology generally
                  driving collection of browsing information, You can also block
                  cookies to control data collection related to interest-based
                  advertising. You can typically do so through Your browser. For
                  instructions, check Your browser’s technical information.
                  Preventing Your browser from accepting cookies prevents the
                  collection of Your browsing information for purposes of
                  delivering interest-based ads. However, it may also disable or
                  interfere with other features on Our website or mobile
                  application. For example, cookies allows the Company to
                  remember Your preferences when You logout of Your account and
                  then login again. Cookies allow Us to recognize You as an
                  account holder when You return to Our website or mobile
                  application. Most browsers accept cookies automatically.{" "}
                </p>
              </li>
              <li>
                <strong>Sensitive Personal Information</strong>
                <p>
                  Certain categories of Personal Information are considered
                  sensitive. User may choose to share such Sensitive Personal
                  Information directly in certain circumstances.
                </p>
                <p>
                  The Company collects and processes the above-mentioned
                  information only when it is essential to offer its Services to
                  the User.{" "}
                </p>
                <p>
                  By providing this Sensitive Personal Information, the User
                  agrees that We may collect, use, or share this information
                  with third parties for the purpose of processing User’s
                  service request(s).
                </p>
                <p>
                  It is hereby clarified that Sensitive Personal Information
                  shall constitute Sensitive Personal Data or Information under
                  the Indian Information Technology Act, 2000 and Information
                  Technology (Reasonable Security Practices and Procedures and
                  Sensitive Personal Information) Rules, 2011 (
                  <strong>“SPI Rules”</strong>). The SPI Rules define “
                  <strong>Sensitive Personal Data or Information</strong>” of a
                  person to mean personal information about that person relating
                  to:
                </p>
                <ul className="roman" style={{ listStyleType: "lower-alpha" }}>
                  <li>passwords;</li>
                  <li>
                    financial information such as bank accounts, credit and
                    debit card details or other payment instrument details;
                  </li>
                  <li>physical, physiological and mental health condition;</li>
                  <li>sexual orientation;</li>
                  <li>medical records and history;</li>
                  <li>biometric information;</li>
                  <li>
                    information received by body corporate under lawful contract
                    or otherwise; and
                  </li>
                  <li>
                    visitor details as provided at the time of registration or
                    thereafter.
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li>
            If You are under 18 (eighteen) years of age, You must only use this
            Platform or provide Personal Information when You have the consent
            of and are supervised by a parent or guardian.
          </li>
          <li>
            <strong>Why We collect User information and how</strong>
            <p>
              We use User information to conduct Our business and to provide
              Users with the best possible Services and user experience. We will
              only use Personal Information based on this Policy, read with the
              Company’s Terms &amp; Conditions, and when the law necessitates Us
              to.{" "}
            </p>
            <p>
              We will collect adequate, relevant, and necessary Personal
              Information and will process such information fairly and lawfully
              for the purpose it is collected. Most commonly, We will use
              Personal Information in the following circumstances:
            </p>
            <ul style={{ listStyleType: "lower-alpha" }}>
              <li>
                Where We need to comply with a legal obligation or are required
                to do so by applicable law, rule, regulation, law enforcement
                agency, governmental official, legal authority or similar
                requirements or when Company, in its sole discretion, deems it
                necessary in order to protect its rights or the rights of
                others, to prevent harm to persons or property, to fight fraud
                and credit risk, or to enforce or apply the Terms &amp;
                Conditions;
              </li>
              <li>
                Where it is necessary for Our legitimate interests [or those of
                a third party] and Your interests and fundamental rights do not
                override those interests;
              </li>
              <li>To enable Your use of Our Services;</li>
              <li>To enable and facilitate user-to-user communications;</li>
              <li>To enforce Our Terms &amp; Conditions;</li>
              <li>To solicit input and feedback to improve Our Services;</li>
              <li>
                To send statements, invoices and payment reminders to You, and
                to collect payments from You;
              </li>
              <li>To send non-marketing commercial communications to You;</li>
              <li>
                To allow artists and their managers to connect with their fans;
              </li>
              <li>
                To send You email notifications that You have specifically
                requested for;
              </li>
              <li>
                To send You marketing communications relating to Our business or
                the businesses of carefully selected third parties which We
                think may be of interest to You, where You have specifically
                agreed to this, by email or similar technology. However, You can
                inform Us at any time if You no longer require such marketing
                communications;
              </li>
              <li>
                To provide third parties with statistical information about Our
                Users but those third parties will not be able to identify any
                individual User from that information;
              </li>
              <li>
                To address Your enquiries and complaints made by or about You in
                relation to the Services provided;
              </li>
              <li>
                To improve and customize Our Services in accordance with Your
                preferences;
              </li>
              <li>
                To keep Our website, mobile application and other systems secure
                and to prevent fraud;
              </li>
              <li>
                To keep an internal record including collection of anonymized
                details about visitors to Our website to compile aggregate
                statistics or produce internal reports;
              </li>
              <li>
                For market research, analysis, testing, monitoring, risk
                management and administrative purposes (including diagnosing
                technology problems which may be reported to Us)
              </li>
              <li>
                Where You have given the Company explicit consent to do so.
              </li>
              <p>
                We may also use Personal Information in the following
                situations, which are likely to be rare:
              </p>

              <ul style={{ listStyleType: "lower-alpha" }}>
                <li>
                  Where We need to protect Your interests [or someone else's
                  interests]
                </li>
                <li>
                  Where it is needed in the public interest or for official
                  purposes or to fulfil a legal obligation. For instance,
                  requests made by law enforcement agencies, courts, or
                  tribunals.
                </li>
                <p>
                  Your Personal Information may be disclosed as part of any
                  merger, sale of company assets or acquisition, as well as in
                  the event of insolvency, bankruptcy, or receivership, in which
                  Personal Information would be transferred as one of the
                  business assets of the Company.
                </p>
                <p>
                  We do not store any information related to credit card/debit
                  card, CVV Number, expiry date or 3D secure password, online
                  banking username or password. We receive and store limited
                  information including the transaction ID, time, name, and
                  other details entered by You on the website of the payment
                  gateway necessary to identify and verify the payments made by
                  You. Whatever Your chosen online mode of payment, Our payment
                  gateway adheres to the standards set by PCI-DSS as managed by
                  the PCI Security Standards Council. PCI-DSS requirements helps
                  ensure the secure handling of credit card information by Our
                  Platform and its service providers.
                </p>
                <p>
                  We will only use Personal Information for the purposes for
                  which We collected it, unless We reasonably consider that We
                  need to use it for another reason and that reason is
                  compatible with the original purpose. If We need to use
                  Personal Information for an unrelated purpose, We will notify
                  the User and We will explain the legal basis which allows the
                  Company to do so. Please note that We may process Personal
                  Information without User’s knowledge or consent, in compliance
                  with the above rules, where this is required or permitted by
                  law.{" "}
                </p>
              </ul>
            </ul>
          </li>
          <li>
            <strong>Failure to provide information </strong>
            <p>
              If a User fails to provide certain Personal Information when
              requested, We may not be able to provide the Services to You, or
              We may be prevented from complying with Our legal obligations.
            </p>
            <p>
              You hereby accept and agree that We shall not be held liable in
              any manner whatsoever if We are unable to perform Our Services or
              if there is a deficiency in Our Services to You due to Your
              failure to provide such Personal Information.
            </p>
            <p>
              You agree that You shall not file or initiate any complaints,
              legal proceedings or claims against the Company and/or Our
              employees, directors, associates etc., in this regard. You further
              agree to indemnify and hold the Company and/or Our employees,
              directors, associates, etc. harmless from any complaints, legal
              proceedings or claims filed or initiated by any third party
              against the Company in this regard.
            </p>
          </li>
          <li>
            <strong>Change of purpose </strong>
            <p>
              We will only use Personal Information for the purposes for which
              We collected it, unless We reasonably consider that We need to use
              it for another reason and that reason is compatible with the
              original purpose. If We need to use Your Personal Information for
              an unrelated purpose, We will notify You and We will explain the
              legal basis which allows the Company to do so. Please note that We
              may process Personal Information without Your knowledge or
              consent, in compliance with the above rules, where this is
              required or permitted by law.{" "}
            </p>
          </li>
          <li>
            <strong>Consent</strong>
            <br/> 
            User agrees that We do not need any additional or further consent
            from him/her to use Personal Information in accordance with this
            Policy to carry out Our legal obligations or exercise specific
            rights.
          </li>
          <li>
            <strong>Information Sharing and Disclosure</strong>
            <p>
              We may disclose Personal Information to any of Our employees,
              officers, insurers, professional advisers, agents, suppliers, or
              subcontractors insofar as reasonably necessary for the purposes
              set out in this Policy and for the purpose of providing serviced
              to the User.{" "}
            </p>
            <p>
              We may disclose Personal Information to any member of Our Company,
              including Our subsidiaries and partners, insofar as reasonably
              necessary for the purposes set out in this Policy.{" "}
            </p>
            <p>In addition to this, We may disclose Personal Information:</p>
            <ol style={{ listStyleType: "lower-alpha" }}>
              <li>
                to the extent that We are required to do so under legal
                obligations;
              </li>
              <li>
                in connection with any ongoing or prospective legal proceedings;
              </li>
              <li>
                in order to establish and exercise or defend Our legal rights
                including providing information to others for the purposes of
                fraud prevention and reducing credit risk;
              </li>
              <li>
                to the purchaser or prospective purchaser of any business or
                asset that We are or are contemplating selling; and
              </li>
              <li>
                to any person who We reasonably believe may apply to a court or
                other competent authority for disclosure of that information
                where, in Our reasonable opinion, such court or authority would
                be reasonable likely to order disclosure of that information.
              </li>
            </ol>
          </li>
          <p>
            Except as provided in this Policy, We will not provide Personal
            Information to any other third party.{" "}
          </p>
          <li>
            <strong>Data Security</strong>
            <p>
              We may also collect information about Your activity on the
              Platform, such as information about Your account usage, as well as
              information about how You interact with the Platform (collectively
              referred to as <strong>“User Data”</strong>). We may combine this
              automatically gathered information with other information,
              including Personal Information We have collected about You.
            </p>
            <p>
              We are committed to protecting User Data and thus implement
              appropriate technical and organizational security measure to
              protect it against any unauthorized or unlawful processing and
              against any accidental loss, destruction, or damage.{" "}
            </p>
            <p>
              Appropriate physical, electronic and managerial procedures have
              been put in place to safeguard and help prevent unauthorized
              access, maintain data security, and correctly use the information
              collected from the User.
            </p>
            <p>
              The Platform may transmit User Data to Amazon web server, to
              ensure secure storage of data collected by Us. Thereby, the User
              is also subject to the terms & conditions and privacy policies of
              Amazon web server. The Personal Information is immediately deleted
              once User deletes his account exclusively made on the Platform,
              except to the extent it is necessary to store such information
              under applicable laws. Further, We have implemented commercially
              reasonable physical, managerial, operational, and technical
              security measures to protect the loss, misuse, and alteration and
              to preserve the security of the Personally Identifiable
              Information in Our care.
            </p>
            <p>
              User Data is processed by the Company in strict accordance with
              the Indian Information Technology Act, 2000, and the rules
              notified thereunder. We implement and maintain ‘Reasonable
              Security Practices and Procedures’ as stated in the Indian
              Information Technology Act, 2000 and the Information Technology
              [Reasonable Security Practices and Procedures and Sensitive
              Personal Data or Information] Rules, 2011, while processing,
              collecting, or handling any Sensitive Personal Information
              collected from the User.{" "}
            </p>
            <p>
              The security of User Data and Personal Information is important to
              Us. We follow generally accepted industry standards to protect the
              User Data and Personal Information submitted to Us, both during
              transmission and once We receive it. All information gathered on
              Our Platform is securely stored on the Amazon web server. The
              database is stored on servers secured behind a firewall; access to
              the servers is password-protected and is strictly limited. Such
              information may also be converted to physical from time to time,
              if deemed necessary. We take all necessary precautions to protect
              Your Personal Information both online and offline and implement
              reasonable security practices and measures including certain
              managerial, technical, operational, and physical security control
              measures that are commensurate with respect to the information
              being collected and the nature of Our business.
            </p>
            <p>
              Although We make best possible efforts to store Personal
              Information in a secure operating environment that is not open to
              the public, User must understand that there is no such thing as
              complete security, and We do not guarantee that there will be no
              unintended disclosures of Personal Information. If We become aware
              that Users Personal Information has been disclosed in a manner not
              in accordance with this Policy, We will use reasonable efforts to
              notify such User of the nature and extent of such disclosure as
              soon as reasonably possible and as permitted by law.
            </p>
          </li>
          <li>
            <strong>Usage of Cookies</strong>
            <p>
              A cookie is a small piece of text file stored by the website on a
              User’s computer hard disk, when the website is opened. In some
              instances, We and Our service providers use cookies and other
              technology to automatically collect certain types of information
              when You visit Our Platform, as well as through emails that We may
              exchange. The collection of this information allows Us to
              customize Your online experience, improve the performance,
              usability, and effectiveness of Our online presence, and to
              measure effectiveness of our marketing strategies. If You chose to
              disable the cookies, You might not be able to fully experience the
              interactive features of this website.
            </p>
          </li>
          <li>
            <strong>Third Party Partners and Service Providers</strong>
            <p>
              We generally do not sell or trade any Personal Information about
              Our Users to any third parties.
            </p>
            <p>
              We will, however, provide Your information to third party
              companies as necessary to perform Services on Our behalf,
              including payment processing, data analysis, email delivery,
              hosting services, artist/fan connections, and customer service. We
              direct all such third-party partners and service providers to
              protect the confidentiality of the information We disclose to them
              and to not use Your information for any purposes other than those
              stated by Us when We disclosed that information to them.
            </p>
            <p>
              We may also share the information You provide to Us with artists
              using Our Platform (or their representatives/managers), if You
              have watched one or more of such artist’s performance, so that
              such artists, or their team, can include You on their fan list.{" "}
            </p>
            <p>
              Except as set forth above, You will be notified when Your personal
              information may be shared with third parties and will be able to
              prevent the sharing of this information.{" "}
            </p>
            <p>
              Our Platform may contain links to third party websites or
              applications. The User agrees and understands that privacy
              policies of these websites are not under Our control and the User
              understands that once he/she leaves Our servers, use of any
              information provided by the User shall be governed by the privacy
              policy of the operator of the site used by them.
            </p>
            <strong>Advertisers</strong>
            <p>
              We use Your information to show You customized advertising on the
              Services and on third-party websites and services.{" "}
            </p>
            <p>
              We allow third parties such as advertising networks and
              advertising service providers to use Your information to display
              advertisements on Our Services and elsewhere online across
              different websites and services tailored to Your interests. You
              can prevent the sharing of Your information.{" "}
            </p>
            <p>
              You can control and opt out of having Your information shared with
              third parties. However, opting out of information sharing may
              affect Your ability use the Services.{" "}
            </p>
            <p>
              We do not sell Users Personal Information to any advertisers or
              any third party. We use Users Personal Data to display relevant
              advertisements, based on his preferences. We make reports related
              to the advertisement campaigns, in particular statistics, without
              any reference or information related to the personal information
              of visitors or Users of the Platform.
            </p>
            <p>
              Prior to any direct marketing or commercial operation (for example
              for sending the newsletters or commercial offers), We will first
              obtain User’s consent, either when creating account on the
              Platform or later by indicating Your choice in account settings.
              User will always have the right to withdraw his or her consent at
              any time, directly by clicking on the unsubscribe link available
              on each email received, by modifying his account settings, or by
              contacting Us at the following email address{" "}
              <a href="mailto:support@clanworld.ai">
                support@clanworld.ai.
              </a>
            </p>
            <p>
              Further, in an ongoing effort to better understand and serve the
              Users of Our Service, We also often conduct research on customer
              demographics, interests and behavior based on the Personal
              Information and other information provided to Us. This research
              may be compiled and analyzed on an aggregate basis, and We may
              share this aggregate data with Our affiliates, agents,
              advertisers, and business partners. This aggregate information
              does not identify You personally. We may also disclose aggregated
              User statistics in order to describe Our Service to current and
              prospective business partners and to other third parties for other
              lawful purposes (including providing advertising use cases to
              actual or potential advertisers).
            </p>
            <strong>Other Users</strong>
            <p>
              The Service supports various forms of interaction between Users.
              While using Our mobile application, friends and other Users will
              be able to see Your profile, which may include Your username,
              preferences and other information voluntarily provided by You.{" "}
            </p>
            <p>
              Access to Your User account may allow others to view the public
              information associated with Your accounts.{" "}
            </p>
          </li>
          <li>
            <strong>YouTube</strong>
            <p>
              We hereby inform all Users that We use YouTube API Services, and
              the Users are thereby bound by the policies framed by YouTube.
              Further, all Users including Influencers, Brands, or agents, are
              agreeing to be bound by Google’s privacy policy.
            </p>
          </li>
          <li>
            <strong>International Cross Border Data Transfer </strong>
            <p>
              Our Service is hosted in India, is intended for Users located
              within India, and is not directed at Users outside of India. If
              You choose to use Our Services from other regions of the world
              with laws governing data collection and use that may differ from
              Indian laws, then please note that You are transferring Your
              Personal Information outside of those regions to India for storage
              and processing. Also, We may transfer Your data from India to
              other countries or regions in connection with the storage and
              processing of data, fulfilling Your requests and operating Our
              Services. By providing any information, including Personal
              Information, You consent to such transfer, storage and processing
              and accept to be bound by this Policy.{" "}
            </p>
          </li>
          <li>
            <strong>Deletion of Personal Information</strong>
            <p>
              You may at any time ask Us to delete Personal Information. We will
              consider and where necessary comply with Your request in
              accordance with applicable laws. Please note that after deleting
              the Personal Information, We may not be able to provide Our
              Services to You.{" "}
            </p>
            <p>
              We may, however, need to retain certain Personal Information for
              record keeping purposes and/or to complete any transactions that
              You began prior to requesting deletion of the information. For
              instance, when You participate in a session, You may not be able
              to change or delete the Personal Information provided to Us until
              after the completion of such session.{" "}
            </p>
            <p>
              There may also be residual information that will remain within Our
              databases and other records, which will not be removed. In
              addition to this, there may be certain Personal Information which
              We may be unable to delete due to legitimate and legal reasons.
            </p>
            <p>
              User hereby accepts and agrees that We shall not be held liable in
              any manner whatsoever if We are unable to perform Our Services or
              if there is a deficiency in Our Services to You due to Your
              withdrawal of consent.
            </p>
            <p>
              You agree that You shall not file or initiate any complaints,
              legal proceedings or claims against the Company and/or Our
              employees, directors, associates etc., in this regard. You further
              agree to indemnify and hold the Company and/or Our employees,
              directors, associates, etc. harmless from any complaints, legal
              proceedings or claims filed or initiated by any third party
              against the Company in this regard.
            </p>
          </li>
          <li>
            <strong>Withdrawal of consent</strong>
            <p>
              User may at any time revoke or withdraw his/her consent to provide
              Personal Information to Us by connecting with us at{" "}
              <a href="mailto:support@clanworld.ai">
                support@clanworld.ai.
              </a>{" "}
              However, such action may render it impossible for Us to offer its
              Services to the User any further.{" "}
            </p>
            <p>
              User hereby accepts and agrees that We shall not be held liable in
              any manner whatsoever if We are unable to perform Our Services or
              if there is a deficiency in Our Services to You due to Your
              withdrawal of consent.
            </p>
            <p>
              You agree that You shall not file or initiate any complaints,
              legal proceedings or claims against the Company and/or Our
              employees, directors, associates etc., in this regard. You further
              agree to indemnify and hold the Company and/or Our employees,
              directors, associates, etc. harmless from any complaints, legal
              proceedings or claims filed or initiated by any third party
              against the Company in this regard.
            </p>
          </li>
          <li>
            <strong>Review of Personal Information </strong>
            <p>
              You can contact the Company at any time requesting access to
              Personal Information for reviewing or requesting amendment to
              certain information that You consider to be incorrect or wrong. We
              shall process such requests from You in accordance with applicable
              law.
            </p>
          </li>
          <li>
            <strong>Change in Privacy Policy</strong>
            <ol style={{ listStyleType: "lower-alpha" }}>
              <li>
                Company may update this Policy at any time, with or without
                advance notice. In the event there are significant changes in
                the way Company treats User’s personally identifiable
                information, or in the Policy itself, Company will display a
                notice on the Platform or send Users an email, as provided for
                above, so that You may review the changed terms prior to
                continuing to use the Services.
              </li>
              <li>
                <strong style={{ display: "inline" }}>
                  If You object to any of the changes to Our Policy, and You no
                  longer wish to avail the Services, You may contact Our
                  Grievance Officer Mr. Kunal Kishore having
                </strong>{" "}
                <Link to="mailto:kunal@clanconnect.ai">
                  kunal@clanconnect.ai
                </Link>{" "}
                to deactivate Your account and for any{" "}
                <strong style={{ display: "inline" }}>
                  other issues related to this Policy. Unless stated otherwise,
                  Company’s current Policy applies to all information that the
                  Company has about You and Your account.
                </strong>
              </li>
              <li>
                If a User uses the Platform, after a notice of changes published
                on the website, such User hereby provides his/her/its consent to
                the changed terms.
              </li>
            </ol>
          </li>
          <li>
            <strong>Contact Details</strong>
            <p>
              In case You have any queries, grievances, comments, complaints
              and/or requests pertaining the Policy, You may contact Us by
              contacting Our support Services and grievance officer through
              e-mail at{" "}
              <Link to="mailto:support@clanworld.ai">
                support@clanworld.ai.
              </Link>{" "}
              We will investigate and attempt to resolve any complaints or
              disputes raised by You as soon as possible. We try to respond to
              all legitimate requests within 30 (thirty) days from date of
              receipt of the complaint. Occasionally it may take Us longer than
              the same if Your request is particularly complex or You have made
              a number of requests. In this case, We will notify You and keep
              You updated.
            </p>
          </li>
        </ol>
        <em>
          This Privacy Policy is an electronic record in the form of an
          electronic contract formed under the Information Technology Act, 2000
          and the rules made thereunder and the amended provisions pertaining to
          electronic documents/ records in various statutes as amended by the
          Information Technology Act, 2000. This Privacy Policy does not require
          any physical, electronic, or digital signature and is a legally
          binding document between the User and Us.
        </em>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
