import { Switch, Route } from "react-router";
import { MyProfilePasswordPage } from "../pages/dashboard/my-profile/password";
import { MyProfilePersonalDetailPage } from "../pages/dashboard/my-profile/personal-detail";
import { MyProfilePersonalSocialConnectPage } from "../pages/dashboard/my-profile/social-connect";

const ProfileRoutes = () => {
  return (
    <Switch>
      <Route
        exact
        path="/dashboard/myprofile/personalDetail"
        component={MyProfilePersonalDetailPage}
      ></Route>
      <Route
        exact
        path="/dashboard/myprofile/password"
        component={MyProfilePasswordPage}
      ></Route>
      <Route
        exact
        path="/dashboard/myprofile/socialConnect"
        component={MyProfilePersonalSocialConnectPage}
      ></Route>
    </Switch>
  );
}

export default ProfileRoutes;