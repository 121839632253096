import { Switch, Route } from "react-router";
import { SubscribersInboxPage } from "../pages/dashboard/subscribers/inbox";
import { SubscribersListPage } from "../pages/dashboard/subscribers/list";

const SubscribersRoutes = () => {
    return (
        <Switch>
            <Route exact path="/dashboard/subscribers/inbox" component={SubscribersInboxPage}></Route>
            <Route exact path="/dashboard/subscribers/list" component={SubscribersListPage}></Route>
        </Switch>
    );
}

export default SubscribersRoutes;