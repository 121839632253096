import React, { useEffect, useState } from "react";
import Banner from "../../assets/img/live-stream.webp";
import ace from "../../assets/img/ace.svg";
import { NavLink, Link } from "react-router-dom";
import { LivestreamService } from "../../app/service/livestream";
import moment from "moment";
import { connect, useDispatch } from "react-redux";
import { Loading } from "../../redux/actions";
import { useHistory } from 'react-router-dom';
import { googleAnalytics } from '../../app/utils/google-analytics';
import OvenLiveKit from "ovenlivekit";
import CardLivestream from "../card-livestream";

interface Props {
  userId: string;
  UserReducer?: any;
  loading?: (data: any) => void;
  LoginUserIdReducer?: any;
}

const UpcomingLivestream: React.FC<Props> = (props: Props) => {
  const [livestream, setLivestream] = useState<any[] | []>([]);
  const dispatch = useDispatch();
  const history = useHistory();
  // let ovenLivekit = OvenLiveKit.create();

  let userId =
    sessionStorage.getItem("isLogin") || props?.LoginUserIdReducer?.loginUserId;
  useEffect(() => {
  // console.log('GET Devices',OvenLiveKit.getDevices());
console.log('in use Effect')
    if (props.loading) {
      dispatch(props.loading(true));
    }
    LivestreamService.getScheduledLivestream("true").then((res) => {
      if (res) {
        console.log("getScheduledLivestream ", res);
        // const data = res.sort((a:any, b:any) => (a.schedule_From < b.schedule_From) ? 1 : ((b.schedule_From < a.schedule_From) ? -1 : 0));
        setLivestream([...res]);
        if (props.loading) {
          dispatch(props.loading(false));
        }
      }
    });
  }, []);

  const navigateToDetailPage = (data: any) => {
    const googleAnalyticsDataObj = {
      user_id: userId,
      action: 'Clicked on view details in upcoming section',
      livestream_id: data._id,
      date_and_time: new Date()
    };
    googleAnalytics.loadGoogleAnalytics(googleAnalyticsDataObj);
    history.push(`/livestreams/livestream-detail/${data?._id}`);

  }
  return (
    <section className="livestream-coll-sec">
      <div className="container">
        <div className="livestream-coll-sec-title">
          <h2 className="mb-3">Streaming Soon</h2>
          <NavLink
            to="/livestreams/creator-livestream"
            className="btn btn-outline-black mx-auto d-inline-flex btn-wide"
          >
            View all
          </NavLink>
        </div>
        <div className="row">
          {livestream.map((data: any, index: number) => {
            return (
              <div key={index} className="col-sm-6 col-lg-4 mb-4">
                {console.log(data)}
                <CardLivestream
                  data={data}
                />
                {/* <div className="card-livestream d-none d-md-flex">
                  <span
                    className="card-livestream-bgimg"
                    style={{
                      backgroundImage: data?.thumbnail
                        ? `url("${data?.thumbnail}")`
                        : "linear-gradient(to right, #DDEFBB, #FFEEEE)",
                      backgroundPosition: "center center",
                      backgroundSize: "cover",
                    }}
                  ></span>
                  <img
                    className="card-livestream-img"
                    src={data?.thumbnail}
                    alt={data?.title}
                  />

                  <div className="card-livestream-content">
                    {/* <a
                    href="javascript:void(0)"
                    onClick={() => { }}
                    className="ratio ratio-1x1 mb-3 video-img"
                    >
                      <video
                        poster=""
                        className="my_club_media"
                        controls
                        controlsList="nodownload"
                        src={data?.video_url}
                      >
                        <source src="" type="video/mp4" />
                        Your browser does not support HTML video.
                      </video>
                    </a> 

                    <h3 className="card-livestream-title">
                      <a
                        href="javascript:void(0)"
                        onClick={() => { }}
                        title="title"
                      >
                        {data?.title}
                      </a>
                    </h3>
                    <span className="dive-in-channel position-relative">
                      @{data?.user_info[0]?.name}
                    </span>
                    <span className="card-livestream-date">
                      {moment(data?.schedule_From).utc().format("lll")} IST
                    </span>
                    {/* to={`/livestreams/livestream-detail/${data?._id}`} 
                    <button onClick={() => navigateToDetailPage(data)}
                      className="btn btn-outline-black btn-rounded"
                    >
                      View Details
                    </button>
                  </div>
                </div> */}
                {/* Mobile View */}
                {/* <Link
                  className="d-flex d-md-none card-livestream"
                  to={`/livestreams/livestream-detail/${data?._id}`}
                >
                  <span
                    className="card-livestream-bgimg"
                    style={{
                      backgroundImage: data?.thumbnail
                        ? `url("${data?.thumbnail}")`
                        : "linear-gradient(to right, #DDEFBB, #FFEEEE)",
                      backgroundPosition: "center center",
                      backgroundSize: "cover",
                    }}
                  ></span>
                  <img
                    className="card-livestream-img"
                    src={data?.thumbnail}
                    alt={data?.title}
                  />

                  <div className="card-livestream-content">
                    <h3 className="card-livestream-title">
                      <a
                        href="javascript:void(0)"
                        onClick={() => {}}
                        title="title"
                      >
                        {data?.title}
                      </a>
                    </h3>
                    <span className="dive-in-channel position-relative">
                      @{data?.user_info[0]?.name}
                    </span>
                    <span className="card-livestream-date">
                      {moment(data?.schedule_From).utc().format("lll")} IST
                    </span>
                  </div>
                </Link> */}
              </div>
            );
          })}
          {/* <div className="col-md-4">
            <div
              className="card-livestream"
              style={{
                backgroundImage: `url(${Banner})`,
                backgroundPosition: "center center",
                backgroundSize: "cover",
              }}
            >
              <div className="card-livestream-content">
                <a
                  href="javascript:void(0)"
                  onClick={() => { }}
                  className="ratio ratio-1x1 mb-3 video-img"
                >
                  <video
                    poster=""
                    className="my_club_media"
                    controls
                    controlsList="nodownload"
                  >
                    <source src="" type="video/mp4" />
                    Your browser does not support HTML video.
                  </video>
                </a>

                <h3 className="card-livestream-title">
                  <a href="javascript:void(0)" onClick={() => { }} title="title">
                    Shadows of the past
                  </a>
                </h3>
                <Link to="asdf" className="dive-in-channel position-relative">
                  @amitabhbachchan
                </Link>
                <span className="card-livestream-date">
                  24 Aug, 8:00 pm IST
                </span>
                <a
                  href="javascript:void(0)"
                  className="btn btn-outline-black btn-rounded"
                  onClick={() => { }}
                >
                  View Details
                </a>
              </div>
            </div>
          </div>
          <div className="col-sm-6 col-lg-4 mb-3">
            <div
              className="card-livestream"
              style={{
                backgroundImage: `url(${Banner})`,
                backgroundPosition: "center center",
                backgroundSize: "cover",
              }}
            >
              <div className="card-livestream-content">
                <a
                  href="javascript:void(0)"
                  onClick={() => { }}
                  className="ratio ratio-1x1 mb-3 video-img"
                >
                  <video
                    poster=""
                    className="my_club_media"
                    controls
                    controlsList="nodownload"
                  >
                    <source src="" type="video/mp4" />
                    Your browser does not support HTML video.
                  </video>
                </a>

                <h3 className="card-livestream-title">
                  <a href="javascript:void(0)" onClick={() => { }} title="title">
                    Shadows of the past
                  </a>
                </h3>
                <Link to="asdf" className="dive-in-channel position-relative">
                  @amitabhbachchan
                </Link>
                <span className="card-livestream-date">
                  24 Aug, 8:00 pm IST
                </span>
                <a
                  href="javascript:void(0)"
                  className="btn btn-outline-black btn-rounded"
                  onClick={() => { }}
                >
                  View Details
                </a>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </section>
  );
};

const mapStateToProps = (state: any) => {
  return {
    UserReducer: state.UserReducer,
    LoginUserIdReducer: state.LoginUserIdReducer,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    loading: (data: any) => dispatch(Loading(data)),
  };
};

const connectedNavBar = connect(
  mapStateToProps,
  mapDispatchToProps
)(UpcomingLivestream);
export { connectedNavBar as UpcomingLivestream };

// export default UpcomingLivestream;
