import React, {useState,useEffect} from "react";
import { UserService } from "../../../app/service/user.service";
import Admin from './Admin'

const AdminVerification = () => {
    const [creatorData, setcreatorData] = useState<any[] | []>([]);

    useEffect(() => {
        getCreator()
        }, []);

        const getCreator=()=>{
            UserService.getAllInfluencersData().then((res) => {
                // console.log("res getCreator data", res);
            console.log(res);
            setcreatorData(res);
        
                // setIsShown(true);
              })
        
        } 
        const updateUserData = {
            _id:"",
            influencer_verified:true
        }

        const UpdateClubInfluencer=(_id:any)=>{
            updateUserData._id = _id
            UserService.updateVerifyUsers(updateUserData).then((res) => {
                if(res){
                    console.log(res)
                    window.location.reload();
                }
            })
        }
        const updateBlockUserData = {
            _id:"",
            influencer_verified:false
        } 
        const blockVerifiedUser=(_id:any)=>{
            updateBlockUserData._id = _id
            UserService.updateVerifyUsers(updateBlockUserData).then((res) => {
                if(res){
                    console.log(res)
                    window.location.reload();
                }
            })
        }
  return (
    <div style={{display:"flex"}}>
        <Admin/>
        <div style={{width:"100%"}}> 
        <table className="table" >
    <thead>
      <tr>
      <th scope="col">S.No.</th>
        <th scope="col">Name</th>
        <th scope="col">Email Id</th>
        <th scope="col">Mobile No.</th>
        <th scope="col">Created At</th>
        <th scope="col">Influencer Verified</th>
        <th scope="col">Action</th>

        
      </tr>
    </thead>
    <tbody>
        {creatorData.map((data:any, index:any) => {
            console.log(data, "anything")
            return (
                <tr className="verification-table-row">
                <td>{index +1}</td>
                <td>{data.name}</td>
                <td>{data.email}</td>
                <td>{data.phone}</td>
                <td>{data.createdAt}</td>
                <td>{data.influencer_verified ? "true":"false"}</td>
                {data.influencer_verified ? <td><span style={{color:"#0fef0f",fontSize:"12px"}}><i className="bi bi-check-square">Verified</i><button className="verification-reject" onClick={()=>blockVerifiedUser(data._id)}>Block</button></span> </td> : <td>
                    <button className="verification-accept" onClick={()=>UpdateClubInfluencer(data._id)}>Accept</button>
                    <button className="verification-reject">Reject</button>
                </td>}
                
                </tr>
            )
        })}
     
      
       
      
    </tbody>
  </table>
        </div>
        </div>
  )
}

export default AdminVerification