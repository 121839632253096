declare let window: any;
export const  switchChain = async() =>{
    try {
        await window.ethereum.request({
            method: "wallet_switchEthereumChain",
            params: [{ chainId: "0x89" }], // chainId must be in hexadecimal numbers
        })
    } catch (err:any) {
        if (err.code === 4902) {
            window.ethereum.request({
                method: "wallet_addEthereumChain",
                params: [
                  {
                    chainId: "0x89",
                    rpcUrls: ["https://polygon-rpc.com/"],
                    chainName: "Polygon Testnet Mumbai",
                    nativeCurrency: {
                      name: "MATIC",
                      symbol: "MATIC", // 2-6 characters long
                      decimals: 18,
                    },
                    blockExplorerUrls: ["https://polygonscan.com/"],
                  },
                ],
            });
           await switchChain();
        }
            
    }
}