import React from "react";
import { Modal } from "react-bootstrap";
import { UploadData } from "../data/data";

interface Props {
  show?: any;
  handleClose: () => void;
  uploadFileSize?: string;
  videoFormats?: string;
  audioFormats?: string;
  imageFormats?: string;
  recommendedDimension?: string;
  handlemodal?: any;
  uploadType?: any;
  multipleImages?:boolean;
  uploadedFile?: (poptype: string | null) => void;
}

export const CustomUploadModal = (props: Props) => {
  const onUploadFile = (event: any) => {
    if (props.uploadedFile) {
      props.uploadedFile(event);
    }
  }
  return (
    <div className="custom-modal" {...props}>
      <Modal
        show={props.show}
        handleModal={props.handlemodal}
        dialogClassName="upload-modal"
        size="lg"
        centered={true}
        onHide={props.handleClose}
        
      >
        <Modal.Header closeButton>
          <Modal.Title>Upload</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="upload-content-body">
            <input
              className="upload_video_file upload-file-container"
              id="upload_video_file"
              type="file"
              onChange={(event) => onUploadFile(event)}
              accept={`${
                props.uploadType === 'video' ? UploadData?.video.formats :
                props.uploadType === 'image' ? UploadData?.image.formats :
                props.uploadType === 'static' ? UploadData?.image.formats :
                props.uploadType === "audio" && UploadData?.audio.formats}`}
                multiple={props.multipleImages}
            />
            <div className="upload-content-icon">
              <i className="bi bi-upload"></i>
            </div>
            <strong>Drag and drop your file(s) to upload</strong>

            <span>
              {props.uploadType !== "audio" && <>Max file size: </>}
              {props.uploadType === "video" && UploadData?.video.fileSize}
              {props.uploadType === "image" && UploadData?.image.fileSize}
              {props.uploadType === "static" && UploadData?.image.fileSize}
            </span>
            <span>
              <>Formats: </>
              {props.uploadType === "video" && UploadData?.video.formats}
              {props.uploadType === "image" && UploadData?.image.formats}
              {props.uploadType === 'static' && UploadData?.image.formats}
              {props.uploadType === "audio" && UploadData?.audio.formats}
            </span>
            {props.uploadType === "image" && (
              <span>
                Recommended dimensions:
                {UploadData?.image.recommendedDimension}
              </span>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

