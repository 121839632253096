import { FormControl, TextField } from "@mui/material";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import Banner from "../../assets/img/deepika.jpg";

type Props = {};

const LivestreamOrderSummary = (props: Props) => {
  const [show, setShow] = useState(false);
  const [name, setName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [attendeeNameError, setAttendeeNameError] = React.useState("");

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const onChangeName = (event: any) => {
    setAttendeeNameError("");
    if (event.target.value) {
      setName(event.target.value);
    } else {
      setName("");
      setAttendeeNameError("Please select language");
    }
  };
  const onChangeEmail = (event: any) => {
    if (event.target.value) {
      setEmail(event.target.value);
    } else {
      setEmail("");
    }
  };
  const onChangePhone = (event: any) => {
    if (event.target.value) {
      setPhone(event.target.value);
    } else {
      setPhone("");
    }
  };
  return (
    <>
      <div className="container">
        <div className="d-flex justify-content-center flex-column align-items-center py-5">
          <h2>Order Summary</h2>
          <span>Order ID: CW-202203000213</span>
        </div>
        <div className="row mb-5">
          <div className="col-sm-3">
            <div className="card-ticket">
              <figure className="card-ticket-img ratio ratio-4x3">
                {/* <img
                src={
                  data?.user_info[0]?.image ? data?.user_info[0]?.image : Banner
                }
              /> */}
                <img src={Banner} />
              </figure>
              <div className="card-ticket-content">
                <span className="card-ticket-content-title">
                  Dhindora uncensored
                  {/* {data?.live_stream_data[0]?.title} */}
                </span>
                <strong className="card-ticket-content-authorname">
                  Deepika Padukone
                  {/* {data?.user_info[0]?.name} */}
                </strong>
                <span className="card-ticket-content-time">
                  {/* {moment(data?.live_stream_data[0]?.schedule_From)
                  .utc()
                  .format("lll")}{" "} */}
                  11:00AM IST
                </span>
                <ul className="card-ticket-price-info">
                  <li>
                    <span>Ticket Price</span>
                    {/* <span>{data?.live_stream_data[0]?.price} INR</span> */}
                    <span>400 INR</span>
                  </li>
                  <li>
                    <span>Attendees</span>
                    {/* <span>{data?.live_stream_data[0]?.tickets}</span> */}
                    <span>4</span>
                  </li>
                  <li>
                    <strong>Total</strong>
                    {/* <strong>{data?.live_stream_data[0]?.price} INR</strong> */}
                    <strong>1600 INR</strong>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-sm-9 order-summary-detail">
            <span>Naach Meri Rani</span>
            <h3>Deepika Padukone</h3>
            <ul className="ticket-info">
              <li>12th February, 2:00 pm IST</li>
              <li>4 Tickets</li>
            </ul>
            <strong>Attendees Details</strong>
            <p>
              One ticket can only be viewed only on one device.
              <br /> You can add/edit these details till 6 hrs prior to the
              start of the event.
            </p>
            <ol className="">
              <li>
                <div className="attendee-info">
                  <div className="attendee-info-left">
                    <strong>Arnika</strong>
                    <span>arnikagarg@gmail.com</span>
                  </div>
                  <div className="attendee-info-right">
                    <button className="btn-text" type="button">
                      Edit
                    </button>
                    <button className="btn-text" type="button">
                      Remove
                    </button>
                  </div>
                </div>
              </li>
              <li>
                <button type="button" className="btn-text" onClick={handleShow}>
                  Add Attendee
                </button>
              </li>
            </ol>
          </div>
        </div>
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton className="p-3">
          <h3 className="mb-0">Add Attendee Details</h3>
        </Modal.Header>
        <Modal.Body>
          <TextField
            fullWidth
            id="AttendeeName"
            label="Enter name"
            variant="outlined"
            margin="dense"
            value={name}
            onChange={(event) => onChangeName(event)}
          />
          {attendeeNameError && (
            <p style={{ color: "red", fontSize:'14px' }}>{attendeeNameError}</p>
          )}
          <TextField
            fullWidth
            id="AttendeeEmail"
            label="Enter email address"
            variant="outlined"
            margin="dense"
            value={email}
            onChange={(event) => onChangeEmail(event)}
          />
          <TextField
            fullWidth
            id="AttendeePhone"
            label="Enter phone number"
            variant="outlined"
            margin="dense"
            value={phone}
            onChange={(event) => onChangePhone(event)}
          />
          <button type="submit" className="btn btn-black w-100 mt-2 mb-3">
            Send Ticket
          </button>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default LivestreamOrderSummary;
