import moment from "moment";
import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import useRazorpay, { RazorpayOptions } from "react-razorpay";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { ClubService } from "../../../app/service/club.service";
import dummyImg from "../../../assets/img/dummy-img.svg";
import { RAZORPAY_KEY } from "../../../config/constant";

export const MySubscriptionsSubscriptionPage = () => {
  let id = sessionStorage.getItem("isLogin") || "";
  const [userSubscriptionClubs, setUserSubscriptionClubs] = useState<
    any[] | []
  >([]);
  const [isFilter, setIsFilter] = useState<any>();
  const [sortingOrder, setSortingOrder] = useState<string>("asc");
  const [selectedSorting, setSelectedSorting] =
    useState<string>("subscribed_on");
  const [activeTab, setActiveTab] = useState<string>("subscription");
  const [selectSubscribtion, setSelectSubscribtion] = useState<any>();

  const Razorpay = useRazorpay();

  const [amount, setAmount] = useState<any>("");

  const RAZORPAY_OPTIONS: RazorpayOptions = {
    key: RAZORPAY_KEY,// "rzp_test_DsjVVApo8X8BCB",
    amount: amount + "000",
    currency: "INR",
    name: "Club clan",
    description: "Test Transaction",
    image: "", //MSB LOGO
    order_id: "",
    // handler: (res) => {
    //     getPaymentDetails();
    // },
    prefill: {
      name: "", //SCHOOL CODE OR MODULE NAME
      email: "",
      contact: "",
    },
    notes: {
      address: "Razorpay Corporate Office",
    },
    theme: {
      color: "#3399cc",
    },
  };

  const onOpenPaymentInfo = (subscribe: any) => {
    setSelectSubscribtion(subscribe);
    const amount = subscribe?.amount;
    if (Number(amount) > 0) {
      RAZORPAY_OPTIONS.amount = amount + "00";
      RAZORPAY_OPTIONS.handler = (response: any) => {
        onSubscribeUnSubscribe(response.razorpay_payment_id, subscribe);
        // handleShowPaymentSuccessPopup();
        // onUpdatePayment(txn_id, response, "success");
      };
      let razorpay = new Razorpay(RAZORPAY_OPTIONS);
      razorpay.open();
      razorpay.on("payment.failed", function (response: any) {
        // onUpdatePayment(txn_id, response, "failed");
      });
    } else {
      // onUpdatePayment(txn_id, { "message": "SUM AMOUNT ZERO" }, "success");
    }
  };
  useEffect(() => {
    if (id) {
      getMyClub(id);
    }
  }, [id]);

  const getMyClub = (id: string) => {
    ClubService.getUserSubscriptionClubs(id, "false").then((res) => {
      if (res) {
        setUserSubscriptionClubs([...res]);
      } else {
      }
    });
  };

  const onSubscriptionFilter = (isStatus: any) => {
    setIsFilter(isStatus);
    if (isStatus !== "") {
      const data = userSubscriptionClubs.filter(
        (subscription) => subscription.status === isStatus
      );
      setUserSubscriptionClubs([...data]);
    } else {
      getMyClub(id);
    }
  };

  const onSubscribeUnSubscribe = (paymentId: any, subscribe: any) => {
    // if (subscribe.status) {
    //   const data = {
    //     user_id: subscribe.club_owner_id,
    //     club_id: subscribe.club_id,
    //     subscription_package_id: subscribe.subscription_package_id,
    //   };
    subscribe.payment_id = paymentId;
    // const data = {
    //   ...{subscribe}, ...{ payment_id: paymentId }
    // }
    ClubService.renewSubscription(subscribe).then((res) => {
      if (res) {
        toast.success("Subscription successfully");
        getMyClub(id);
      }
    });
    // } else {
    //   const data = {
    //     user_id: subscribe?.club_owner_id,
    //     club_id: subscribe.club_id,
    //     subscription_package_id: subscribe.subscription_package_id,
    //     package_for: subscribe.package_type,
    //     amount: subscribe.amount
    //   };
    //   ClubService.createClubSubscriber(data).then((res) => {
    //     if (res) {
    //       toast.success("Subscription successfully");
    //       getMyClub(id);
    //     }
    //   });
    // }
  };

  const sorting = (sortKey: any, sortOrder: string) => {
    const data: any = userSubscriptionClubs;
    setSortingOrder(sortOrder === "asc" ? "dsc" : "asc");
    setSelectedSorting(sortKey);
    if (sortOrder === "asc") {
      data.sort((a: any, b: any) =>
        a[sortKey] > b[sortKey] ? 1 : b[sortKey] > a[sortKey] ? -1 : 0
      );
    } else {
      data.sort((a: any, b: any) =>
        a[sortKey] > b[sortKey] ? -1 : b[sortKey] > a[sortKey] ? 1 : 0
      );
    }
    setUserSubscriptionClubs([...data]);

  };
  const subscriptionTab = (tabType: string) => {
    setActiveTab(tabType);
  };
  return (
    <>
      <div
        id="subs-subscription-tab"
        role="tabpanel"
        aria-labelledby="subs-subscription-tab"
      >
        <h3>Subscriptions</h3>
        <hr />
        <div className="custom-tab mb-5">
          <ul className="nav nav-pills">
            <li className="nav-item" role="presentation">
              <a
                className="nav-link dropdown-toggle pointer"
                data-bs-toggle="dropdown"
                data-bs-target="#pills-home"
                href="#"
                role="button"
                aria-expanded="false"
                onClick={() => subscriptionTab("subscription")}
              >
                {isFilter === true
                  ? "Active Subscriptions"
                  : isFilter === false
                    ? "Past Subscriptions"
                    : "All Subscriptions"}
              </a>
              <ul className="dropdown-menu">
                <li>
                  <a
                    className="dropdown-item pointer"
                    data-bs-target="#pills-home"
                    onClick={() => onSubscriptionFilter(true)}
                  >
                    Active Subscriptions
                  </a>
                </li>
                <li>
                  <a
                    className="dropdown-item pointer"
                    data-bs-target="#pills-home"
                    onClick={() => onSubscriptionFilter(false)}
                  >
                    Past Subscriptions
                  </a>
                </li>
                <li>
                  <a
                    className="dropdown-item pointer"
                    data-bs-target="#pills-home"
                    onClick={() => onSubscriptionFilter("")}
                  >
                    All Subscriptions
                  </a>
                </li>
              </ul>
            </li>
            <li className="nav-item" role="presentation">
              <a
                onClick={() => subscriptionTab("billing")}
                className="nav-link"
                id="billing-history-tab"
                data-bs-toggle="pill"
                type="button"
                role="tab"
                aria-controls="billing-history"
                aria-selected="false"
              >
                Billing History
              </a>
            </li>
          </ul>
        </div>
        <div className="tab-content" id="pills-tabContent">
          {activeTab === "subscription" && (
            <div
              className="tab-pane fade show active"
              id="pills-home"
              role="tabpanel"
              aria-labelledby="pills-home-tab"
            >
              {/* Card Subscription */}
              {userSubscriptionClubs.map((data, index) => {
                return (
                  <div key={index} className="card-subscription">
                    <div className="card-subscription-left">
                      <div className="card-subscription-left-img">
                        <Link to={`/clubs/${data?.club_id}`}>
                          <img
                            src={data?.club_thubmnail || dummyImg}
                            alt=""
                            style={{
                              width: "150px",
                              height: "150px",
                              marginRight: "10px",
                              objectFit: "cover",
                            }}
                          />
                        </Link>
                      </div>
                      <div className="card-subscription-left-info">
                        <h3 className="card-subscription-title">
                          <Link to={`/clubs/${data?.club_id}`}>
                            {data?.club_name}
                          </Link>
                          <span className="subscription-exp-info pr-2">
                            Subscribed on{" "}
                            {moment(data?.subscribed_on).format("lll")}
                          </span>
                          &nbsp;&nbsp;
                        </h3>
                        <div className="subscription-detail">
                          <span className="subscription-term pr-2">
                            <span className="subscription-term-period">
                              {data?.package_type
                                ? (data?.package_type).toLowerCase()
                                : ""}
                            </span>{" "}
                            Subscription &nbsp;&nbsp;
                          </span>
                          <strong className="subscription-price">
                            INR {data?.amount}
                          </strong>
                        </div>
                        <div className="subscription-pkg-info">
                          <span className="subscription-pkg-name">
                            {data?.package_name} Package
                          </span>
                          &nbsp;
                          <strong>
                            {data?.post_name ? data?.post_name : ""}
                          </strong>
                          {/* <Link to="" className="subscription-pkg">
                      View Details
                    </Link> */}
                        </div>
                        <span className="subscription-per-info fs-xsml">
                          {data?.end_date &&
                            `Subscription ends on ${moment(data?.end_date).format("lll")}`}
                        </span>
                        {data.package_name !== "PAID POST" &&
                          moment(data?.end_date).diff(moment(), "days") < 10 && (
                            <button
                              className="btn btn-outline-black"
                              onClick={() => onOpenPaymentInfo(data)}
                            >
                              Renew Now
                            </button>
                          )}
                      </div>
                    </div>
                    {/* <div className="card-subscription-right">
                      <span className="subscription-per-info">
                        {data?.end_date &&
                          `Renews on ${moment(data?.end_date).format("lll")}`}
                      </span>
                      {data.package_name !== "PAID POST" &&
                        moment(data?.end_date).diff(moment(), "days") < 10 && (
                          <button
                            className="btn btn-outline-black"
                            onClick={() => onOpenPaymentInfo(data)}
                          >
                            Renew Now
                          </button>
                        )}
                    </div> */}
                  </div>
                );
              })}
              {userSubscriptionClubs.length <= 0 && (
                <div className="text-center">No data found</div>
              )}
            </div>
          )}

          {activeTab === "billing" && (
            <div
              className="tab-pane fade"
              id="billing-history"
              role="tabpanel"
              aria-labelledby="billing-history-tab"
            >
              <Table className="" responsive="xl">
                <thead>
                  <tr>
                    <th>Invoice</th>
                    <th onClick={() => sorting("subscribed_on", sortingOrder)}>
                      Billing Date{" "}
                      {selectedSorting === "subscribed_on" && (
                        <span>
                          {" "}
                          {sortingOrder === "asc" ? (
                            <i className="bi bi-arrow-down-short"></i>
                          ) : (
                            <i className="bi bi-arrow-up-short"></i>
                          )}
                        </span>
                      )}
                    </th>
                    <th>Description</th>
                    <th>Type</th>
                    <th>Status</th>
                    <th onClick={() => sorting("amount", sortingOrder)}>
                      Amount{" "}
                      {selectedSorting === "amount" && (
                        <span>
                          {" "}
                          {sortingOrder === "asc" ? (
                            <i className="bi bi-arrow-down-short"></i>
                          ) : (
                            <i className="bi bi-arrow-up-short"></i>
                          )}
                        </span>
                      )}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {userSubscriptionClubs.map((data, index) => {
                    return (
                      <tr key={index}>
                        <td>
                          <Link to=""></Link>
                        </td>
                        <td>{moment(data?.subscribed_on).format("lll")}</td>
                        <td>{data?.club_name}</td>
                        <td>
                          {data?.package_type
                            ? data?.package_type
                            : data?.package_name}
                        </td>
                        <td>Paid</td>
                        <td>INR {data?.amount}</td>
                      </tr>
                    );
                  })}
                  {/* <tr>
                  <td>
                    <Link to="">CS15312</Link>
                  </td>
                  <td>Aug 1, 2021</td>
                  <td>Crazy Hushstays</td>
                  <td>Monthly</td>
                  <td>Paid</td>
                  <td>INR 299</td>
                </tr>
                <tr>
                  <td>
                    <Link to="">CS21312</Link>
                  </td>
                  <td>Jun 1, 2021</td>
                  <td>Cooking Basics</td>
                  <td>Annually</td>
                  <td>Paid</td>
                  <td>INR 1199</td>
                </tr> */}
                </tbody>
              </Table>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
