import API from "../utils/axios";

export class LivestreamService {
    static LivestreamService() {
        throw new Error("Method not implemented.");
    }
    constructor() { }

    static getlivestream(status: string, user_id: string): Promise<any> {
        return API.get(`/getlivestream?status=${status}&user_id=${user_id}`);
    }

    static getLiveStreamByCreatorID(user_id: string): Promise<any> {
        return API.get(`/getLiveStreamByCreatorID/${user_id}`);
    }

    static getlivestreamById(id: string): Promise<any> {
        return API.get(`/getlivestream?_id=${id}`);
    }

    static getLivestreamPrivate(id: string): Promise<any> {
        return API.get(`/get_livestream_private?_id=${id}`);
    }

    static createLiveStream(data: any): Promise<any> {
        data.stream_type = 'web-rtmp';
        return API.post(`/createLiveStream`, data);
    }

    static updateLiveStream(data: any): Promise<any> {
        return API.put(`/updateLiveStream`, data);
    }

    static getLiveStreamFolderInfo(data: any): Promise<any> {
        return API.post(`/getLiveStreamFolderInfo`, data);
    }

    static getLiveStreamRecordingsFolderInfo(data: any): Promise<any> {
        return API.post(`/getLiveStreamRecordingsFolderInfo`, data);
    }

    static publishLiveStream(data: any): Promise<any> {
        return API.post(`/publishLiveStream`, data);
    }

    static cancelLiveStream(data: any): Promise<any> {
        return API.post(`/cancelLiveStream`, data);
    }

    static deleteLivestream(data: any): Promise<any> {
        return API.post(`/deleteLivestream`, data);
    }

    static getAllScheduledLiveStreams(userId: string): Promise<any> {
        return API.get(`/getAllScheduledLiveStreams/${userId ? userId : false}`);
    }

    static checkUserAccess(data: any): Promise<any> {
        return API.post(`/checkUserAccess`, data);
    }

    static buyLiveStream(data: any): Promise<any> {
        return API.post(`/buyLiveStream`, data);
    }
    static completeLiveStream(data: any): Promise<any> {
        return API.post(`/completeLiveStream`, data);
    }

    static goLiveStream(data: any): Promise<any> {
        return API.post(`/goLiveStream`, data);
    }

    static liveStreamcomments = (data: any): Promise<any> => {
        return API.post(`/liveStreamcomments`, data);
    }
    static getScheduledLiveStreamsByUserID(userId: string): Promise<any> {
        return API.get(`/getScheduledLiveStreamsByUserID/${userId}`);
    }
    static getCanceledLiveStreamByUserID(userId: string): Promise<any> {
        return API.get(`/getCanceledLiveStreamByUserID/${userId ? userId : false}`);
    }

    static getAllScheduledLiveStreamsByClubID(isLogin: any, clubId: string): Promise<any> {
        return API.get(`/getAllScheduledLiveStreamsByClubID/${isLogin ? isLogin : false}/${clubId}`);
    }

    static updateRecordLiveStream = (data: any): Promise<any> => {
        return API.post(`/updateRecordLiveStream`, data);
    }

    static getCompletedLivestream(isFull: string): Promise<any> {
        return API.get(`/getCompletedLivestream/${isFull}`);
    }
    static getScheduledLivestream(isFull: string): Promise<any> {
        return API.get(`/getScheduledLivestream/${isFull}`);
    }
    static getLiveStreamReportDetails(userId: string): Promise<any> {
        return API.get(`/getLiveStreamReportDetails/${userId}`);
    }

    static getUpcomingLiveStreamByCreatorID(userId: string): Promise<any> {
        return API.get(`/getUpcomingLiveStreamByCreatorID/${userId}`);
    }

    static getAttendedLiveStreamByUserID(userId: string): Promise<any> {
        return API.get(`/getAttendedLiveStreamByUserID/${userId}`);
    }
    static getCompletedLiveStreamByCreatorID(userId: string): Promise<any> {
        return API.get(`/getCompletedLiveStreamByCreatorID/${userId}`);
    }

    static getLivestreamByGenereAndLanguage(genre: string, language: string): Promise<any> {
        return API.get(`/getLivestreamByGenereAndLanguage`, { params: { genre, language } });
    }

    static getLivestreamCountByGenere(language: string): Promise<any> {
        return API.get(`/getLivestreamCountByGenere`, { params: { language } });
    }

    static createTickets(data: any): Promise<any> {
        return API.post(`/createTickets`, data);
    }

    static verifyTicket(data: any): Promise<any> {
        return API.post(`/verifyTicket`, data);
    }
    static getTicket(data: any): Promise<any> {
        return API.post(`/getTicket`, data);
    }
    static checkUserHadLSAccess(data: any): Promise<any> {
        return API.post(`/checkUserHadLSAccess`, data);
    }

    static createOrder(data: any): Promise<any> {
        return API.post(`/createorder`, data);
    }

    static checkUserEmailHadLSAccess(data: any): Promise<any> {
        return API.post(`/checkUserEmailHadLSAccess`, data);
    }

    static checkLsUserValidation(id: string): Promise<any> {
        return API.get(`/checkLsUserValidation/${id}`);
    }

    static updateLsLikes(data: any): Promise<any> {
        return API.post(`/updateLsLikes`, data);
    }

    static updateWatchingCount(data: any): Promise<any> {
        return API.post(`/updateWatchingCount`, data);
    }

    static updateLsShowOrHide(data: any): Promise<any> {
        return API.post(`/updateLsShowOrHide`, data);
    }

    static updateLsBlockList(data: any): Promise<any> {
        return API.post(`/updateLsBlockList`, data);
    }

    static updateLSTicketCount(data: any): Promise<any> {
        return API.post(`/updateLSTicketCount`, data);
    }

    static getSignature(): Promise<any> {
        return API.get(`/getSignature`);
    }

    static createLsSmartContract(data: any): Promise<any> {
        return API.post(`/createLsSmartContract`, data);
    }

    static buyNft(data: any): Promise<any> {
        return API.post(`/buyNft`, data);
    }

    static forCreate(data: any): Promise<any> {
        // routes commented on 2023-01-18 for disabling NFT functionality ********************
        // return API.post(`/forCreate`, data);
        return Promise.resolve();
    }
    static create(data: any): Promise<any> {
        // routes commented on 2023-01-18 for disabling NFT functionality ********************
        // return API.post(`/create`, data);
        return Promise.resolve();
    }

    static forBuyTickets(data: any): Promise<any> {
        // routes commented on 2023-01-18 for disabling NFT functionality ********************
        // return API.post(`/forBuyTickets`, data);
        return Promise.resolve();
    }

     static buyTickets(data: any): Promise<any> {
        // routes commented on 2023-01-18 for disabling NFT functionality ********************
        //     return API.post(`/buyTickets`, data);
        return Promise.resolve();

    }

}
