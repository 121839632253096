import React, { useState } from "react";
import { connect, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { ClubService } from "../../../app/service/club.service";
import FormBuilder from "../../../components/form-builder";
import { Loading } from "../../../redux/actions";

interface Props {
  UserReducer?: any;
  loading?: (data: any) => void;
}

const MyProfilePasswordPage = (props: Props) => {
  const dispatch = useDispatch();
  const [passwordFormData, setPasswordFormData] = useState<any>({});
  let id = sessionStorage.getItem("isLogin") || "";

  const updateFormData = (data: any) => {
    setPasswordFormData(data);
  };
  const onSavePassword = () => {
    if (props.loading) {
      dispatch(props.loading(true));
    }
    const data = passwordFormData.value;
    if (id) {
      if (data.confirmNewPassword === data.password) {
        const value = {
          user_id: id,
          password: data.password,
          oldPassword: data.oldPassword,
        };
        ClubService.changePassword(value).then((res) => {
          if (res) {
            toast.success("User updated successfully");
            if (props.loading) {
              dispatch(props.loading(false));
            }
          } else {
            toast.error("Please enter current password");
            if (props.loading) {
              dispatch(props.loading(false));
            }
          }
        });
      } else {
        if (props.loading) {
          dispatch(props.loading(false));
        }
        toast.error("Passwords should match");
      }
    }
  };
  return (
    <form>
      <section className="myprofile-info row">
        <div className="myprofile-info-left col-md-4">
          <h3>Login Password</h3>
        </div>
        <div className="myprofile-info-right col-md-8">
          <FormBuilder onUpdate={updateFormData}>
            <form>
              <div className="form-floating mb-3">
                <input
                  type="password"
                  className="form-control"
                  id="password"
                  name="oldPassword"
                  placeholder="Password"
                />
                <label htmlFor="password">Old Password</label>
              </div>
              <div className="form-floating mb-3">
                <input
                  type="password"
                  className="form-control"
                  id="newPassword"
                  name="password"
                  placeholder="New Password"
                />
                <label htmlFor="newPassword">New Password</label>
              </div>
              <div className="form-floating mb-3">
                <input
                  type="password"
                  className="form-control"
                  id="confirmNewPassword"
                  name="confirmNewPassword"
                  placeholder="Confirm New Password"
                />
                <label htmlFor="confirmNewPassword">
                  {" "}
                  Confirm New Password
                </label>
              </div>
            </form>
          </FormBuilder>
        </div>
      </section>
      <div className="row">
        <div className="col-md-4"></div>

        <div className="col-md-8 d-flex">
          {/* <button type="button" className="btn btn-outline-orange me-2">
            Cancel
          </button> */}
          <button
            type="button"
            className="btn btn-outline-black"
            onClick={onSavePassword}
          >
            Save
          </button>
        </div>
      </div>
    </form>
  );
};

const mapStateToProps = (state: any) => {
  return {
    UserReducer: state.UserReducer,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    loading: (data: any) => dispatch(Loading(data)),
  };
};

const connectedNavBar = connect(
  mapStateToProps,
  mapDispatchToProps
)(MyProfilePasswordPage);
export { connectedNavBar as MyProfilePasswordPage };
// export default MyProfilePasswordPage;
