import React, { useEffect, useState } from 'react'
import { NavLink, useHistory, Link } from 'react-router-dom';
import FormBuilder from '../../components/form-builder';
import Logo from "../../assets/img/ClanClublogo.svg";
import { UserService } from '../../app/service/user.service';
import { toast } from 'react-toastify';
import { FormControlError, FormField, FormValidators } from '../../components/form-builder/model/form-field';
import { FormValidator, GetControlIsValid, GetEmailControlIsValid } from '../../components/form-builder/validations';
import { connect, useDispatch } from 'react-redux';
import { LoginPopup, LoginUserId, UserLogin } from '../../redux/actions';
import { Login } from '../../components/form-popups/login';
import GoogleLogin from 'react-google-login';
import ReactFacebookLogin from 'react-facebook-login';
import FacebookLogin from 'react-facebook-login';

interface Props {
    UserReducer?: any;
    loginPopup?: (data: any) => void;
    LoginPopupReducer?: any;
    login?: (data: any) => void;
    loginUserId?: (data: any) => void;
}

const ClubSingupPage: React.FC<Props> = (props) => {
    let [signupData, setSignupData] = useState<any>({});
    const [headerColor, setHeaderColor] = useState(Boolean);
    const [showOffCanvas, setShowOffCanvas] = useState<Boolean>(false);
    const toggleOffCanvasShow = () => setShowOffCanvas((s) => !s);
    const [isLoading, setLoading] = useState(false);
    const history = useHistory();
    const [errorMsg, setErrorMsg] = useState('');
    const [validationErrors, setValidationErrors] = useState<FormControlError[]>([]);
    const dispatch = useDispatch();
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);

    const formValidations = [
        new FormField('name', [FormValidators.REQUIRED]),
        new FormField('email', [FormValidators.REQUIRED]),
        new FormField('password', [FormValidators.REQUIRED]),
        new FormField('confirmPassword', [FormValidators.REQUIRED]),
    ]

    const getInputValid = (control: string) => {
        const value = GetControlIsValid(validationErrors, control);
        return value;
    }

    const getEmailValid = (control: string) => {
        const data = { ...signupData.value };
        const value = GetEmailControlIsValid(validationErrors, control, data);
        return value;
    }

    window.addEventListener('scroll', () => {
        const sc = window.scrollY;
        if (sc > 150) {
            setHeaderColor(true);
        } else {
            setHeaderColor(false);
        }
    });
    const updateSignupFormData = (data: any) => {
        setSignupData(data);
        const errors: FormControlError[] = FormValidator(formValidations, data.value);
        setValidationErrors(errors);
    };

    const handleSignupClick = () => {
        setLoading(true);
        setIsFormSubmitted(true);
        const data = { ...signupData.value };
        const errors: FormControlError[] = FormValidator(formValidations, data);
        setValidationErrors(errors);
        if (errors.length < 1 && !getEmailValid('email') && signupData?.value?.confirmPassword === signupData?.value?.password) {
            UserService.createUser(signupData.value).then(
                res => {
                    if (res) {
                        setLoading(false);
                        setIsFormSubmitted(true);
                        // history.push("../");
                        // history.push("/clubpayment");
                        login();
                        toast.success('User Created successfully');
                    } else {
                        setLoading(false);
                        setIsFormSubmitted(true);
                        // toast.error(res.message);
                    }
                }
            );
        } else {
            setLoading(false);
        }
    };

    const login = () => {
        const data = {
            email: signupData?.value?.email,
            password: signupData?.value?.password
        }
        UserService.loginUser(data).then(
            res => {
                setLoading(false);
                if (res) {

                    if (props.login) {
                        dispatch(props.login(res));
                        sessionStorage.setItem('userData', JSON.stringify(props.UserReducer));
                    }
                    sessionStorage.setItem('loginUserImage', res[0]?.image || '');
                    setIsFormSubmitted(false);
                    const _id = res[0]?._id || '';
                    sessionStorage.setItem('isLogin', _id);
                    if (props.loginUserId) {
                        dispatch(props.loginUserId(_id));
                    }
                    history.push("../");
                    // if (_id) {
                    //     UserService.checkUserClubsPostsByUserID(_id).then(
                    //         resData => {
                    //             if (resData?.status) {
                    //                 if (resData?.navigation === '../dashboard/posts/published') {
                    //                     history.push(resData?.navigation);
                    //                     handleClose();
                    //                 } else {
                    //                     setConformationPopupText(resData);
                    //                 }
                    //             } else {
                    //                 handleClose();
                    //             }
                    //         })
                    // }
                } else {
                    toast.error('Invalid credentials');
                    setIsFormSubmitted(false);
                }
            }
        );
    }
    const handleShow = () => {
        if (props.loginPopup) {
            const data = { loginPopup: true, popupType: 'login' };
            dispatch(props.loginPopup(data))
        }
    };
    const responseFacebook = (event: any) => {
        const eventData = event;
        const providers = {
            eventData, ...{ provider: 'facebook' }
        }
        loginWithSocialMedias(providers);
    };

    const responseGoogle = (event: any) => {
        const eventData = event;
        const providers = {
            eventData, ...{ provider: 'google' }
        }
        if (event?.googleId) {
            loginWithSocialMedias(providers);
        }
    };

    const loginWithSocialMedias = (data: any) => {
        UserService.loginUser(data).then(
            res => {
                setLoading(false);
                if (res) {
                    if (props.login) {
                        dispatch(props.login(res));
                        sessionStorage.setItem('userData', JSON.stringify(props.UserReducer));
                    }
                    sessionStorage.setItem('loginUserImage', res[0]?.image || '');
                    setIsFormSubmitted(false);
                    const _id = res[0]?._id || '';
                    sessionStorage.setItem('isLogin', _id);
                    if (props.loginUserId) {
                        dispatch(props.loginUserId(_id));
                    }
                    history.push("../");
                    // if (_id) {
                    //     UserService.checkUserClubsPostsByUserID(_id).then(
                    //         resData => {
                    //             if (resData?.status) {
                    //                 if (resData?.navigation === '../dashboard/posts/published') {
                    //                     history.push(resData?.navigation);
                    //                 } else {
                    //                     setConformationPopupText(resData);
                    //                 }
                    //             }
                    //         })
                    // }
                } else {
                    toast.error('Invalid credentials');
                    setIsFormSubmitted(false);
                }
            }
        );
    }
    return (
        <div>
            <header className={`site-header static navbar navbar-expand-lg ${headerColor ? "fixedActive" : ""}`}>
                <div className="container-fluid">
                    <div className="site-header__left">
                        <span onClick={toggleOffCanvasShow} className="d-inline-flex d-lg-none align-self-center mobile-toggle" style={{ cursor: 'pointer' }}><i className="bi bi-list"></i></span>
                        <Link to="/" className="navbar-brand">
                            <img src={Logo} alt="Clan Logo" />
                        </Link >
                    </div>
                    {/* <div className="collapse navbar-collapse d-none d-lg-flex" id="navbarNavDropdown">
                        <ul className="navbar-nav">
                            <li className="nav-item"><NavLink className="nav-link" aria-current="page" to="">Clubs</NavLink></li>
                            <li className="nav-item"><a className="nav-link" href="#">Livestreams</a></li>
                        </ul>
                    </div> */}
                </div>
            </header>
            {props.LoginPopupReducer.loginPopup && <Login />}
            <div className="row">
                <div className="col-md-6 offset-md-3">
                    <div className="row mt-5">
                        <div className="col-auto d-flex flex-wrap">
                            <h4>Sign Up</h4>
                        </div>
                        <div className="ms-auto col-auto">
                            <span>Already have an account?
                                <a style={{ color: '#42CB86' }} className="pointer" onClick={handleShow}>
                                    <span> Login</span>
                                </a>
                            </span>
                        </div>
                    </div>
                    {/* <FormBuilder onUpdate={updateSignupFormData}>
                        <form className="top-space-login pl-3 pr-3" >
                            <div className="form-floating mb-3">
                                <input type="text" data-required
                                    className={`form-control ${isFormSubmitted && (!getInputValid('name')) ? "error" : ""}`}
                                    id="name" name="name" placeholder="Name" />
                                {isFormSubmitted && !getInputValid('name') ? (
                                    <label className="clr-red" htmlFor="exampleInputEmail1">
                                        Enter Name
                                    </label>
                                ) : (
                                    <label className="clr-black" htmlFor="exampleInputEmail1">
                                        Name
                                    </label>
                                )}
                            </div>
                            <div className="form-floating mb-3">
                                <input type="email" data-required
                                    className={`form-control ${isFormSubmitted && (!getInputValid('email') || getEmailValid('email')) ? "error" : ""}`}
                                    id="exampleInputEmail1" name="email" aria-describedby="emailHelp" placeholder="Email" />
                                {isFormSubmitted && !getInputValid('email') ? (
                                    <label className="clr-red" htmlFor="exampleInputEmail1">
                                        Enter Email
                                    </label>
                                ) : isFormSubmitted && getEmailValid('email') ? (
                                    <label className="clr-red" htmlFor="exampleInputEmail1">
                                        Enter Valid Email
                                    </label>
                                ) : (
                                    <label className="clr-black" htmlFor="exampleInputEmail1">
                                        Email
                                    </label>
                                )}

                            </div>
                            <div className="form-floating mb-3">
                                <input type="password" data-required
                                    className={`form-control ${isFormSubmitted && (!getInputValid('password')) ? "error" : ""}`}
                                    id="exampleInputPassword1" placeholder="Password" name="password" />
                                {isFormSubmitted && !getInputValid('password') ? (
                                    <label className="clr-red" htmlFor="exampleInputEmail1">
                                        Enter Password
                                    </label>
                                ) : (
                                    <label className="clr-black" htmlFor="exampleInputEmail1">
                                        Password
                                    </label>
                                )}
                            </div>
                            <div className="mb-2 ms-sm-auto col-auto">
                                <span>Already have an account?
                                    <a style={{ color: '#42CB86' }} className="pointer" onClick={handleShow}>
                                        <span> Login</span>
                                    </a>
                                </span>
                            </div>
                        </form>
                    </FormBuilder> */}
                    <div className="text-center">
                        <div className="sign-up-social">
                            <div className="social-btn">
                                <GoogleLogin
                                    clientId="679675228906-53q8tu0dclkmjkagp9d31r882v7rk3ih.apps.googleusercontent.com"
                                    buttonText="Signup with Google"
                                    onSuccess={responseGoogle}
                                    onFailure={responseGoogle}
                                />
                            </div>
                        </div>
                        <div className="sign-up-social">
                            <div className="social-btn">
                                <FacebookLogin
                                    appId="152110156924040"
                                    autoLoad={false}
                                    fields="name,email,picture"
                                    callback={responseFacebook}
                                    cssClass="btn"
                                    icon="fa-facebook"
                                    textButton="Signup with facebook"
                                />
                            </div>
                        </div>
                    </div>
                    {/* <div>
                        <button disabled={isLoading && !errorMsg} type="submit" className="form-control btn btn-primary" onClick={handleSignupClick}>
                            {(!isLoading || errorMsg) ? 'Signup' : <div className="spinner-border text-primary spinner-border-sm" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>}
                        </button>
                    </div> */}
                </div>
            </div>
        </div>

    )
}

const mapStateToProps = (state: any) => {
    return {
        UserReducer: state.UserReducer,
        LoginPopupReducer: state.LoginPopupReducer,
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        loginPopup: (data: any) => dispatch(LoginPopup(data)),
        login: (data: any) => dispatch(UserLogin(data)),
        loginUserId: (data: any) => dispatch(LoginUserId(data))
    }
}
const connectedNavBar = connect(mapStateToProps, mapDispatchToProps)(ClubSingupPage);
export { connectedNavBar as ClubSingupPage };
