import { AwsFolderPostInfo, Clubs, ClubsPost, PostClubSubscriber, PostClubSubscriberDetails, PostLikes } from "../model/clubs";
import API from "../utils/axios";

export class ClubService {
    constructor() { }

    static getUserClubs(id: string): Promise<any> {
        return API.get(`/getClubs?owned_by=${id}`);
    }

    static getClub(id: string): Promise<any> {
        return API.get(`/getClubs?_id=${id}`);
    }

    static getClubs(): Promise<any> {
        return API.get(`/getPublishClubs`);
    }

    static createClubPost(data: FormData): Promise<ClubsPost> {
        return API.post(`/createClubPost`, data);
    }

    static updateClubPost(data: FormData): Promise<ClubsPost> {
        return API.put(`/updateClubPost`, data);
    }
    static getClubPosts = (_id: string, club_id: string): Promise<any> => {
        return API.get(`/getClubPosts`, { params: { _id, club_id } });
    }

    static getClubPost = (club_id: string, type: string, subscriptionID: string): Promise<any> => {
        return API.get(`/getClubPosts`, { params: { club_id, type, subscriptionID } });
    }

    static createClub = (data: any): Promise<any> => {
        return API.post(`/createClubs`, data);
    }

    static updateClub = (data: any): Promise<any> => {
        return API.put(`/updateClubs`, data);
    }

    static getAwsFolderInfo = (data: AwsFolderPostInfo): Promise<any> => {
        return API.post(`/getAwsFolderInfo`, data);
    }

    static publishClub(data: any): Promise<any> {
        return API.post(`/publishClub`, data);
    }

    static publishClubPost(data: any): Promise<ClubsPost> {
        return API.post(`/publishClubPost`, data);
    }

    static unPublishClubPost(data: ClubsPost): Promise<ClubsPost> {
        return API.post(`/unPublishClubPost`, data);
    }

    static getUnPublishPost = (club_id: string, subscription: string, type: string): Promise<any> => {
        return API.get(`/getUnPublishPost/${club_id}`, { params: { subscription, type } });
    }

    static updatePostLikes(data: PostLikes): Promise<ClubsPost[]> {
        return API.post(`/updatePostLikes`, data);
    }

    static createClubSubscriber = (data: PostClubSubscriber): Promise<any> => {
        return API.post(`/createClubSubscriber`, data);
    }

    static subscriberAccessCheck = (data: PostClubSubscriber): Promise<any> => {
        return API.post(`/subscriberAccessCheck`, data);
    }

    static clubSubscriberDetails = (data: PostClubSubscriberDetails): Promise<any> => {
        return API.post(`/clubSubscriberDetails`, data);
    }
    static getClubSubscribersByClubId = (club_id: string, isBoolean: any): Promise<any> => {
        return API.get(`/getClubSubscribersByClubId/${club_id}/${isBoolean}`);
    }

    static getClubPostDetails = (club_id: string, id: string): Promise<any> => {
        return API.get(`/getClubPostDetails/${club_id}/${id}`);
    }

    static getUserSubscriptionClubs = (userid: string, isBoolean: any): Promise<any> => {
        return API.get(`/getUserSubscriptionClubs/${userid}/${isBoolean}`);
    }

    static getClubSubscriptionPackages = (club_id: string): Promise<any> => {
        return API.get(`/getClubSubscriptionPackages/${club_id}`);
    }
    static getUserSubscriptionPost = (data: any): Promise<any> => {
        return API.post(`/getUserSubscriptionPost`, data);
    }

    static unSubscribeSubscription = (data: any): Promise<any> => {
        return API.post(`/unSubscribeSubscription`, data);
    }

    static deleteClubPost = (data: any): Promise<any> => {
        return API.post(`/deleteClubPost`, data);
    }

    static getClubPostsByUserID = (userID: string): Promise<any> => {
        return API.get(`/getClubPostsByUserID/${userID}`);
    }

    static createClubPostComments = (data: any): Promise<any> => {
        return API.post(`/createClubPostComments`, data);
    }

    static replyClubPostComments = (data: any): Promise<any> => {
        return API.post(`/replyClubPostComments`, data);
    }

    static likeComments = (data: any): Promise<any> => {
        return API.post(`/likeComments`, data);
    }

    static getMessagesById = (senderID: string, receiverID: string): Promise<any> => {
        return API.get(`/getMessagesById/${senderID}/${receiverID}`);
    }
    static createMessages = (data: any): Promise<any> => {
        return API.post(`/createMessages`, data);
    }

    static getUserFolderInfo = (data: any): Promise<any> => {
        return API.post(`/getUserFolderInfo`, data);
    }

    static getGenreLookup = (): Promise<any> => {
        return API.get(`/getGenreLookup`);
    }

    static getCountryLookup = (): Promise<any> => {
        return API.get(`/getCountryLookup`);
    }
    static changePassword = (data: any): Promise<any> => {
        return API.post(`/changePassword`, data);
    }
    static fetchBankDetails = (ifscCode: string): Promise<any> => {
        return API.get(`/fetchBankDetails/${ifscCode}`);
    }

    static fetchStateslist = (country: string): Promise<any> => {
        return API.get(`/fetchStateslist/${country}`);
    }
    static fetchCitieslist = (country: string, state: string): Promise<any> => {
        return API.get(`/fetchCitieslist/${country}/${state}`);
    }

    static unPublishClub = (data: any): Promise<any> => {
        return API.post(`/unPublishClub`, data);
    }

    static fetchGenereList = (): Promise<any> => {
        return API.get(`/fetchGenereList`);
    }

    static fetchSubGenereList = (genere: string): Promise<any> => {
        return API.get(`/fetchSubGenereList/${genere}`);
    }

    static renewSubscription = (data: any): Promise<any> => {
        return API.post(`/renewSubscription`, data);
    }

    static fetchMultipleSubGenereList = (data: any): Promise<any> => {
        return API.post(`/fetchMultipleSubGenereList`, data);
    }

    static getClubTotalInfoByClubIds = (club_id: string): Promise<any> => {
        return API.get(`/getClubTotalInfoByClubIds/${club_id}`);
    }

    static getClubLastPostInfoByClubId = (club_id: string): Promise<any> => {
        return API.get(`/getClubLastPostInfoByClubId/${club_id}`);
    }

    static createClubPostViews = (data: any): Promise<any> => {
        return API.post(`/createClubPostViews`, data);
    }

    static fetchLanguageList = (): Promise<any> => {
        return API.get(`/fetchLanguageList`);
    }

    static filterGenreList = (language: string): Promise<any> => {
        return API.get(`/getLivestreamCountByGenere`, { params: { language } });
    }
  
    static signedCall(data: any): Promise<ClubsPost> {
        return API.post(`/s3signedurl`, data);
    }
    static getUserClubAndAccountDetails(userId: string): Promise<any> {
        return API.get(`/getUserClubAndAccountDetails`, { params: { userId } });
    }

}
