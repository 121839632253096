import React, { useEffect } from "react";
import { Accordion } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getAllData } from "../redux/actions";

const CreatorFaq = () => {
    const dispatch = useDispatch();
    const getState: any = useSelector<any>((state) => {
      if (state.fetchInfo.faq) {
        return state.fetchInfo.faq;
      }
    });
  
    useEffect(() => {
      dispatch(getAllData());
    }, []);
    return (
        <>
          <div className="root_data">
            {/* <h1 style={{ textAlign: "center" }}>Creator</h1> */}
            {getState !== undefined && getState.length ? (
              getState.map((x: any, index: number) => {
                return (
                  <>
                    {
                      <div className="container" style={{marginBottom:"15px"}}>
                         { x.faqtype === "Cretors" && (
                        <Accordion>
                          <Accordion.Item eventKey="0">
                            <Accordion.Header className="faq-header">{x[`question`]}</Accordion.Header>
                            <Accordion.Body>{x[`answer`]}</Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                        )}
                      </div>
                    }
                  </>
                );
              })
            ) : (
              <> Failed</>
            )}
          </div>
        </>
      );
    };


export default CreatorFaq