import React from "react";

type Props = {};

const LivestreamEnded = (props: Props) => {
  return (
    <div className="row vh-100 justify-content-center text-center align-items-center">
      <div className="col-md-6 d-flex flex-column  align-items-center">
        <h3>Livestream has ended</h3>
        <button className="btn btn-black" type="button">
          Explore all Livestream
        </button>
      </div>
    </div>
  );
};

export default LivestreamEnded;
