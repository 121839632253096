import React from 'react'
import { Route, Switch } from "react-router";
import AdminPayment from './AdminPayment';
import AdminSignups from './AdminSignups';
import AdminVerification from './AdminVerification';

const AdminRoutes = () => {
  return (
    <Switch>
        <Route path="/admin/signups" component={AdminSignups}></Route>
        <Route path="/admin/verification" component={AdminVerification}></Route>
        <Route path="/admin/payment" component={AdminPayment}></Route>

        
    </Switch>
  )
}

export default AdminRoutes