export const LOGIN = 'login';
export const LOGIN_SUCCESS = 'login_success';
export const LOGIN_ERROR = 'login_error';
export const LOGOUT = 'logout';
export const LOADING = 'loading';
export const LOGINPOPUP = 'loginPopup';
export const SAVECLUB = 'saveClub';
export const GETCLUB = 'getClub';
export const LOGINUSERID = 'loginUserId';
export const POSTSCOUNT = 'postCount';


