import React, { Fragment } from "react";
import { NavLink, useLocation } from "react-router-dom";
import SubscriptionRoutes from "../../../routes/subscriptions";

export const MySubscriptionsPage: React.FC = () => {
  const location = useLocation();
  return (
    <div className="main-content">
      <div className="container container-custom">
        <div className="main-content__top">
          <div className="custom-tab mb-2">
            <ul className="nav nav-pills">
              {/* <li className="nav-item" role="presentation">
                                    <NavLink id="subs-posts-tab" className="nav-link" to="/dashboard/mysubscriptions/posts" >Club Posts</NavLink>
                                </li> */}
              <li className="nav-item" role="presentation">
                <NavLink
                  className="nav-link"
                  id="subs-subscription-tab"
                  to="/dashboard/mysubscriptions/scheduled"
                >
                  Scheduled
                </NavLink>
              </li>
              <li className="nav-item" role="presentation">
                <NavLink
                  className="nav-link"
                  id="subs-subscription-tab"
                  to="/dashboard/mysubscriptions/attended"
                >
                  Attended
                </NavLink>
              </li>
              <li className="nav-item" role="presentation">
                <NavLink
                  className="nav-link"
                  id="subs-subscription-tab"
                  to="/dashboard/mysubscriptions/cancelled"
                >
                  Cancelled
                </NavLink>
              </li>

              {/* {!location.pathname.includes("mysubscriptions/subscriptions") || !location.pathname.includes("mysubscriptions/inbox")  && 
                                <>
                                <li className="nav-item" role="presentation">
                                    <NavLink className="nav-link" id="subs-subscription-tab" to="/dashboard/mysubscriptions/subscriptions" >Subscriptions</NavLink>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <NavLink className="nav-link" id="subs-inbox-tab" to="/dashboard/mysubscriptions/inbox" >Inbox</NavLink>
                                </li>
                                </>
                             */}
            </ul>
          </div>
        </div>
        <div className="tab-content" id="pills-tabContent">
          <div
            className="tab-pane active"
            id=""
            role="tabpanel"
            aria-labelledby=""
          >
            <SubscriptionRoutes></SubscriptionRoutes>
          </div>
        </div>
      </div>
    </div>
    // </div>
  );
};
