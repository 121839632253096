import { UserData } from "../app/model/user";
import { UserService } from "../app/service/user.service";
import {
  GETCLUB,
  LOADING,
  LOGINPOPUP,
  LOGINUSERID,
  LOGIN_ERROR,
  LOGIN_SUCCESS,
  LOGOUT,
  POSTSCOUNT,
  SAVECLUB,
} from "./types";

export const UserLogin = (data: UserData) => {
  if (data) {
    return {
      type: LOGIN_SUCCESS,
      data,
    };
  } else {
    return {
      type: LOGIN_ERROR,
      data,
    };
  }
};

export const UserLogout = () => {
  return {
    type: LOGOUT,
  };
};

export const Loading = (data: any) => {
  return {
    type: LOADING,
    data,
  };
};

export const LoginPopup = (data: any) => {
  return {
    type: LOGINPOPUP,
    data,
  };
};

export const SaveClub = (data: any) => {
  return {
    type: SAVECLUB,
    data,
  };
};

export const GetClub = (data: any) => {
  return {
    type: GETCLUB,
    data,
  };
};

export const LoginUserId = (data: any) => {
  return {
    type: LOGINUSERID,
    data,
  };
};

export const PostCount = (data: any) => {
  return {
    type: POSTSCOUNT,
    data,
  };
};
export const getAllData = () => {
  // alert("hello world")
  return (dispatch: any) => {
    return UserService.faqData().then((r) => {
      dispatch({
        type: "faqData",
        data: r,
      });
    });
  };
};

// export const getInfluencersData = () => {
//   // alert("hello world")
//   return (dispatch: any) => {
//     return UserService.getInfluencersData().then((q) => {
//       dispatch({
//         type: "getInfluencersData",
//         data: q,
//       });
//     });
//   };
// };
