import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { ClubService } from "../../../app/service/club.service";
import { UserService } from "../../../app/service/user.service";
import { S3Helper } from "../../../app/utils/s3-helper";
import capture from "../../../assets/img/Capture1.png";
import FormBuilder from "../../../components/form-builder";
import dummyImg from "../../../assets/img/dummy-img.svg";
import { Loading } from "../../../redux/actions";
import { connect, useDispatch } from "react-redux";
import { S3_URL } from "../../../config/constant";

interface Props {
  UserReducer?: any;
  loading?: (data: any) => void;
}

const MyProfilePersonalDetailPage = (props: Props) => {
  let id = sessionStorage.getItem('isLogin') || '';
  const [userData, setUserData] = useState<any[] | []>([]);
  const [userFormData, setUserFormData] = useState<any>();
  const [profileImage, setProfileImage] = useState("");
  const [awsInfo, setAwsInfo] = useState<any>(null);
  const baseUrl = S3_URL;
  const dispatch = useDispatch();
  const [genre, setGenre] = useState<any[] | []>([]);
  const [country, setCountry] = useState<any[] | []>([]);
  const [states, setStates] = useState<any[] | []>([]);
  const [cities, setCities] = useState<any[] | []>([]);
  const [selectedGenre, setSelectedGenre] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [selectedCity, setSelectedCity] = useState("");



  useEffect(() => {
    if (props.loading) {
      dispatch(props.loading(true));
    }
    ClubService.getGenreLookup().then((res) => {
      if (res) {
        setGenre(res)
      } else {
        toast.error(res?.message);
      }
    });

    if (id) {
      getUserData(id);
      const data = {
        user_id: id
      }
      ClubService.getUserFolderInfo(data).then((res) => {
        if (res) {
          setAwsInfo(res);
        } else {
          toast.error(res?.message);
        }
      });
    }
    ClubService.getCountryLookup().then((res) => {
      if (res) {
        setCountry(res);
      } else {
        toast.error(res?.message);
      }
    });
  }, [id]);

  const getUserData = (id: string) => {
    if (props.loading) {
      dispatch(props.loading(true));
    }
    UserService.getUser(id).then(
      res => {
        if (res) {
          setUserData([...res]);
          sessionStorage.setItem('userImages', '');
          if (res.length > 0) {
            setProfileImage(res[0]?.image);
            sessionStorage.setItem('userImages', res[0]?.image);
            setSelectedGenre(res[0]?.genre);
            if (res[0]?.country) {
              onSelectCountry(res[0]?.country ? res[0]?.country : '');
            }
            if (res[0]?.state) {
              onSelectState(res[0]?.country ? res[0]?.country : '', res[0]?.state ? res[0]?.state : '');
            }
            setSelectedCountry(res[0]?.country ? res[0]?.country : '');
            setSelectedState(res[0]?.state ? res[0]?.state : '');
            setSelectedCity(res[0]?.city ? res[0]?.city : '');
          }
          if (props.loading) {
            dispatch(props.loading(false));
          }
        }
      }
    );
  }
  const updateFormData = (data: any) => {
    setUserFormData(data);
  };

  const onSaveMyProfile = () => {
    if (props.loading) {
      dispatch(props.loading(true));
    }
    const data = userFormData?.value;
    const value = {
      _id: id,
      name: data?.name ? data.name : (userData[0].name ? userData[0].name : ''),
      email: data?.email ? data.email : (userData[0].email ? userData[0].email : ''),
      phone: data?.phone ? data.phone : (userData[0].phone ? userData[0].phone : ''),
      country: data?.country ? data.country : (userData[0].country ? userData[0].country : ''),
      state: data?.state ? data.state : (userData[0].state ? userData[0].state : ''),
      city: data?.city ? data.city : (userData[0].city ? userData[0].city : ''),
      dob: data?.dob ? data.dob : (userData[0].dob ? userData[0].dob : ''),
      gender: data?.gender ? data.gender : (userData[0].gender ? userData[0].gender : ''),
      genre: data?.genre ? data.genre : (userData[0].genre ? userData[0].genre : ''),
      facebook_url: data?.facebook_url ? data.facebook_url : (userData[0].facebook_url ? userData[0].facebook_url : ''),
      instagram_url: data?.instagram_url ? data.instagram_url : (userData[0].instagram_url ? userData[0].instagram_url : ''),
      twitter_url: data?.twitter_url ? data.twitter_url : (userData[0].twitter_url ? userData[0].twitter_url : ''),
      youtube_url: data?.youtube_url ? data.youtube_url : (userData[0].youtube_url ? userData[0].youtube_url : ''),
      image: profileImage ? profileImage : (userData[0].image ? userData[0].image : ''),
    }
    UserService.updateUsers(value).then(
      res => {
        if (res) {
          getUserData(id);
          if (props.loading) {
            dispatch(props.loading(false));
          }
        } else {
          if (props.loading) {
            dispatch(props.loading(false));
          }
        }
      }
    );
  }
  const onUploadProgress = (data: any) => {
    const progress = Math.round((data.loaded / data.total) * 100);
    // setProgress(progress);
  };
  const onUploadContent = async (event: any) => {
    setProfileImage("");
    if (event.target.files && event.target.files[0]) {
      const result = await S3Helper.uploadFile(
        event.target.files[0],
        onUploadProgress,
        awsInfo
      );
      setProfileImage(
        `${baseUrl}${awsInfo.folderPath}/${event.target.files[0].name}`
      );
      toast.success("Uploaded Successfully");
    }
  };

  const onChangeGenre = (event: any) => {
    setSelectedGenre(event.target.value);
  }

  const onSelectCountry = (event: any) => {
    // const states = country.find(data => data.country === event);
    setSelectedCountry(event);
    setSelectedState("");
    setSelectedCity("");
    ClubService.fetchStateslist(event).then((res) => {
      if (res) {
        // if (states) {
        setStates([...res]);
        // }
      }
    })

  }
  const onSelectState = (country: string, event: any) => {
    // const cities = states.find(data => data.id === event);
    setSelectedState(event);
    setSelectedCity('');
    ClubService.fetchCitieslist(country, event).then((res) => {
      if (res) {
        // if (states) {
        setCities([...res]);
        // }
      }
    })
    // if (cities) {
    //   setCities([...cities.cities]);
    // }
  }
  const onSelectCity = (event: any) => {
    setSelectedCity(event);
  }
  return (
    <div>
      <FormBuilder onUpdate={updateFormData}>
        <form>
          <section className="myprofile-info row">
            <div className="myprofile-info-left col-md-4">
              <h3>Basic Information</h3>
            </div>
            <div className="myprofile-info-right col-md-8">
              <h4>Profile Photo</h4>
              <div className="myprofile-pic">
                <div className="myprofile-img">
                  <img src={profileImage ? profileImage : dummyImg} alt="profile-img" />
                </div>
                <div className="myprofile-rt">
                  <div className="button_video_outer">
                    <div className="btn_video_upload btn">
                      <input className="upload_video_file" id="upload_video_file" type="file" name="intro_video" accept="image/jpg, image/jpeg, image/png, image/svg, image/gif" onChange={(e) => onUploadContent(e)} />
                      <i className="bi bi-cloud-arrow-up link link-primary"></i><strong className="link link-primary">Upload Photo </strong>
                    </div>
                    <div className="processing_video_bar"></div>
                    <div className="success_video_box"></div>
                  </div>
                  {/* <Link to="" className="link link-primary">
                    Upload Photo
                  </Link> */}
                  <Link to="" className="link link-orange mt-auto mb-2">
                    Remove
                  </Link>
                </div>
              </div>
              <div className="form-floating mb-3">
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  placeholder="Name"
                  name="name"
                  defaultValue={userData[0]?.name}
                />
                <label htmlFor="name">Name</label>
              </div>
              <div className="form-floating mb-3">
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  placeholder="Email"
                  name="email"
                  defaultValue={userData[0]?.email} readOnly
                />
                <label htmlFor="email">Email</label>
              </div>
              <div className="form-floating mb-3">
                <input
                  type="text"
                  className="form-control"
                  id="phone"
                  placeholder="Phone"
                  name="phone"
                  defaultValue={userData[0]?.phone}
                />
                <label htmlFor="phone">Phone</label>
              </div>

              <div className="location-info mb-3">
                <label htmlFor="location">Location</label>
                <div className="row m-0">
                  <select className="form-select" name="country" value={selectedCountry} onChange={(event) => onSelectCountry(event.target.value)}>
                    <option selected>Country</option>
                    {country.map((data: any, index: number) => { return <option key={index} value={data.country}>{data.country}</option> })}
                    {/* <option value="2">US</option> */}
                  </select>

                  <select className="form-select" name="state" value={selectedState} onChange={(event) => onSelectState(selectedCountry, event.target.value)}>
                    <option selected>State</option>
                    {states.map((state: any, index) => { return <option key={index} value={state.id}>{state.state}</option> })}
                    {/* <option value="2">State2</option> */}
                  </select>

                  <select className="form-select" name="city" value={selectedCity} onChange={(event) => onSelectCity(event.target.value)}>
                    <option selected>City</option>
                    {cities.map((city: any, index) => { return <option key={index} value={city}>{city}</option> })}

                    {/* <option value="1">City1</option>
                    <option value="2">City2</option> */}
                  </select>
                </div>
              </div>
              <div className="form-floating datepicker mb-3">
                <input type="date" id="dob" className="form-control" name="dob" defaultValue={userData[0]?.dob} />
                <label htmlFor="dob">Date of Birth</label>
              </div>
              <select className="form-select" name="gender" value={userData[0]?.gender}>
                <option selected>Gender</option>
                <option value="M">Male</option>
                <option value="F">Female</option>
              </select>
            </div>
          </section>
          <hr className="mt-5" />
          {/* <section className="myprofile-info row">
            <div className="myprofile-info-left col-md-4">
              <h3>Genre</h3>
            </div>
            <div className="myprofile-info-right col-md-8">
              <select className="form-select mb-5" name="genre" value={selectedGenre} onChange={onChangeGenre}>
                <option selected>Category</option>
                {genre.map((data: string, index: number) => { return <option key={index} value={data}>{data}</option> })}
              </select>
            </div>
          </section> */}
          <div className="row">
            <div className="col-md-4"></div>

            <div className="col-md-8 d-flex">
              <button type="button" className="btn btn-outline-orange me-2">
                Cancel
              </button>
              <button type="button" className="btn btn-outline-black" onClick={onSaveMyProfile}>
                Save
              </button>
            </div>
          </div>
        </form>
      </FormBuilder>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    UserReducer: state.UserReducer,
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    loading: (data: any) => dispatch(Loading(data)),
  }
}

const connectedNavBar = connect(mapStateToProps, mapDispatchToProps)(MyProfilePersonalDetailPage);
export { connectedNavBar as MyProfilePersonalDetailPage };
// export default MyProfilePersonalDetailPage;
