import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { AppDashboard } from '../components/dashboard';
import AppFooter from '../components/footer';
import FormPopup from '../components/form-popups/form-popup';
import { AppHeader } from '../components/header';
import ScrollToTop from '../components/scroll-to-top';

interface Props { 
    loginHide: () => {};
}

const HomePage: React.FC<Props> = (props: Props) => {
    let [popupType, setPopupType] = useState<string | null>(null);
    const hidePopup = () => {
        props.loginHide();
        setPopupType(null)
    }
    return (
        <>
            {popupType && <FormPopup popupType={popupType} closePopup={() => hidePopup()} handleLogin={(emailId, password) => console.log(emailId, password)} />}
            <AppHeader/>
            <ScrollToTop />
            <AppDashboard />
            <AppFooter />
        </>
    );
};

export default HomePage;
