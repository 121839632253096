import React, { useEffect, useState } from "react";
import { ProgressBar } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { toast } from "react-toastify";
import { ClubsPost } from "../../../app/model/clubs";
import { SubscriptionPackages } from "../../../app/model/subscription-packages";
import { ClubService } from "../../../app/service/club.service";
import { SubscriptionPackagesService } from "../../../app/service/subscription-packages-service";
import { S3Helper } from "../../../app/utils/s3-helper";
import FormBuilder from "../../../components/form-builder";
import { useHistory, useParams } from "react-router";
import Slider from "react-slick";
import { Loading } from "../../../redux/actions";
import { connect, useDispatch } from "react-redux";
import RLDD from "react-list-drag-and-drop/lib/RLDD";
import { TooltipWrapper } from "../../../components/tooltip";
import {
  FormControlError,
  FormField,
  FormValidators,
} from "../../../components/form-builder/model/form-field";
import {
  FormValidator,
  GetControlIsValid,
} from "../../../components/form-builder/validations";
import { CustomUploadModal } from "../../../components/custom-upload-modal";
import { S3_URL } from "../../../config/constant";

interface Props {
  UserReducer?: any;
  loading?: (data: any) => void;
}

const VideoPage: React.FC<Props> = (props: Props) => {
  let { id, type } = useParams<{ id: string; type: string }>();
  const [clubPosts, setClubPosts] = useState<any>({});
  const [isLoading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [content, setContent] = useState("");
  const [postAfterDays, setpostAfterDays] = useState("7");
  const [postAfterDeleteDays, setpostAfterDeleteDays] = useState("");
  const [showThumnail, setShowThumnail] = useState("");

  const [awsInfo, setAwsInfo] = useState<any>(null);
  const [progress, setProgress] = useState(0);
  const baseUrl = S3_URL;
  const club_id = sessionStorage.getItem("clubId") || "";
  const [videoUrl, setVideoUrl] = useState("");
  const [multipleImages, setmultipleImages] = useState<any>([]);

  const [publicChecked, setPublicChecked] = useState(false);
  const handleChecked = () => {
    setPublicChecked(!publicChecked);
  };
  const dispatch = useDispatch();
  const [subscriptionPackages, setSubscriptionPackages] = useState<
    SubscriptionPackages[] | []
  >([]);
  const [avalableChecked, setAvalableChecked] = useState(false);
  const [isMultiple, setIsMultiple] = useState(false);

  const [avalableOptions, setAvalableOptions] = useState("");
  const [postAfterAvalableDays, setPostAfterAvalableDays] = useState("");
  const [postAfterFreeDays, setPostAfterFreeDays] = useState("");
  const [pollesOptions, setPollesOptions] = useState([
    { name: "" },
    { name: "" },
  ]);
  const [pollesEndDate, setPollesEndDate] = useState("");
  const [selectedSubscriptionPakages, setSelectedSubscriptionPakages] =
    useState<string[]>([]);
  const [selectedPostData, setselectedPostData] = useState<ClubsPost>();
  const history = useHistory();
  const [showSelectedPakageDetails, setShowSelectedPakageDetails] =
    useState(-1);
  const settings = {
    dots: false,
    arrows: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };
  const handleCheckedAvalable = () => {
    setAvalableChecked(!avalableChecked);
  };

  useEffect(() => {
    if (club_id) {
      const data = {
        club_id: club_id,
        type:
          type === "video"
            ? 0
            : type === "audio"
            ? 1
            : type === "static"
            ? 2
            : type === "text"
            ? 3
            : 4,
      };
      ClubService.getAwsFolderInfo(data).then((res) => {
        if (res) {
          setAwsInfo(res);
        } else {
          toast.error(res?.message);
        }
      });
      SubscriptionPackagesService.getClubSubscriptionPackages(club_id).then(
        (res) => {
          if (res) {
            res.forEach((element) => {
              element.isChecked = false;
            });
            setSubscriptionPackages([...res]);
            if (id !== "0") {
              if (props.loading) {
                dispatch(props.loading(true));
              }
              ClubService.getClubPostDetails(club_id, id).then((postRes) => {
                if (postRes) {
                  setselectedPostData(postRes[0]);
                  setPostAfterAvalableDays(postRes[0]?.AVAILABLE_TO_SUBSCRIBER);
                  setPostAfterFreeDays(postRes[0]?.FREE_POST);
                  setpostAfterDeleteDays(postRes[0]?.DELETE_POST);
                  setProgress(100);
                  setpostAfterDays(postRes[0].AVAILABLE);
                  if (type === "static") {
                    const urls = JSON.parse(postRes[0].content);
                    try {
                      if (urls.length > 0) {
                        const urlString: any = [];
                        urls.forEach((imags: any, index: number) => {
                          urlString.push({ img: imags.img, id: index + 1 });
                          setmultipleImages([...urlString]);
                        });
                      }
                    } catch (error) {}
                  } else {
                    setVideoUrl(postRes[0].content);
                  }
                  setAvalableChecked(postRes[0].PAID_POST);
                  setShowThumnail(
                    postRes[0].thumbnail ? postRes[0].thumbnail : ""
                  );
                  res.sort((a, b) => (a.name > b.name ? 1 : -1));
                  res.forEach((element) => {
                    element.isExist = false;
                    const isExist = postRes[0].subscription_package_id.find(
                      (sub: any) => {
                        return sub === element._id;
                      }
                    );
                    if (isExist) {
                      element.isExist = true;
                    } else {
                      element.isExist = false;
                    }
                  });
                  setSubscriptionPackages([...res]);
                } else {
                  toast.error(postRes?.message);
                }
                if (props.loading) {
                  dispatch(props.loading(false));
                }
              });
            }
          }
        }
      );
    }
  }, []);
  const onSaveVideo = (isSavetype: string) => {
    // const data = clubPosts.value;
    const data = { ...clubPosts.value };
    setIsFormSubmitted(true);
    const errors: FormControlError[] = FormValidator(formValidations, data);
    setValidationErrors(errors);
    if (
      errors.length < 1 &&
      (videoUrl || multipleImages.length > 0 || pollesOptions.length > 0) &&
      (avalableChecked || selectedSubscriptionPakages.length > 0)
    ) {
      if (props.loading) {
        dispatch(props.loading(true));
      }
      let formData = new FormData();
      formData.append(
        "type",
        type === "video"
          ? "0"
          : type === "audio"
          ? "1"
          : type === "static"
          ? "2"
          : type === "text"
          ? "3"
          : "4"
      );
      formData.append("post_type", "");
      formData.append("club_id", club_id);
      formData.append(
        "title",
        data?.title
          ? data?.title
          : selectedPostData?.title
          ? selectedPostData?.title
          : ""
      );
      formData.append(
        "description",
        data?.description
          ? data?.description
          : selectedPostData?.description
          ? selectedPostData?.description
          : ""
      );
      formData.append(
        "content",
        videoUrl
          ? videoUrl
          : selectedPostData?.content
          ? selectedPostData?.content
          : ""
      );
      formData.append(
        "price",
        data?.price
          ? data?.price
          : selectedPostData?.price
          ? selectedPostData?.price
          : ""
      );
      formData.append(
        "total_likes",
        data?.total_likes
          ? data?.total_likes
          : selectedPostData?.total_likes
          ? selectedPostData?.total_likes
          : ""
      );
      formData.append(
        "AVAILABLE",
        avalableChecked
          ? postAfterDays
          : selectedPostData?.AVAILABLE
          ? selectedPostData?.AVAILABLE
          : ""
      );

      formData.append(
        "PAID_POST",
        new Boolean(
          avalableChecked
            ? avalableChecked
            : selectedPostData?.PAID_POST
            ? selectedPostData?.PAID_POST
            : false
        ).toString()
      );
      formData.append(
        "DELETE_POST",
        avalableOptions === "DELETE_POST"
          ? postAfterDeleteDays
          : selectedPostData?.DELETE_POST
          ? selectedPostData?.DELETE_POST
          : ""
      );
      formData.append(
        "FREE_POST",
        avalableOptions === "FREE_POST"
          ? postAfterFreeDays
          : selectedPostData?.FREE_POST
          ? selectedPostData?.FREE_POST
          : ""
      );
      formData.append(
        "AVAILABLE_TO_SUBSCRIBER",
        avalableOptions === "AVAILABLE_TO_SUBSCRIBER"
          ? postAfterAvalableDays
          : selectedPostData?.AVAILABLE_TO_SUBSCRIBER
          ? selectedPostData?.AVAILABLE_TO_SUBSCRIBER
          : ""
      );
      formData.append(
        "subscription_package_id",
        selectedSubscriptionPakages.length > 0
          ? JSON.stringify(selectedSubscriptionPakages)
          : selectedPostData?.subscription_package_id
          ? JSON.stringify(selectedPostData?.subscription_package_id)
          : ""
      );
      formData.append(
        "total_comments",
        data?.total_comments
          ? data?.total_comments
          : selectedPostData?.total_comments
          ? selectedPostData?.total_comments
          : ""
      );
      if (type === "polls") {
        formData.append(
          "polls_end_date",
          pollesEndDate
            ? pollesEndDate
            : selectedPostData?.polls_end_date
            ? selectedPostData?.polls_end_date
            : ""
        );
        formData.append(
          "isMultiple",
          new Boolean(
            isMultiple
              ? isMultiple
              : selectedPostData?.isMultiple
              ? selectedPostData?.isMultiple
              : false
          ).toString()
        );
        formData.append(
          "options",
          pollesOptions.length > 0
            ? JSON.stringify(pollesOptions)
            : selectedPostData?.options
            ? JSON.stringify(selectedPostData?.options)
            : ""
        );
        formData.append(
          "selectedOptions",
          data?.selectedOptions
            ? data?.selectedOptions
            : selectedPostData?.selectedOptions
            ? selectedPostData?.selectedOptions
            : ""
        );
      }
      if (showThumnail) {
        formData.append(
          "thumbnail",
          showThumnail
            ? showThumnail
            : selectedPostData?.thumbnail
            ? selectedPostData?.thumbnail
            : ""
        );
      }

      if (isSavetype === "save") {
        if (id === "0") {
          ClubService.createClubPost(formData).then(
            (res) => {
              toast.success("Club Post Created Successfully");
              setClubPosts(res);
              history.push("/dashboard/posts/drafts");
              setIsFormSubmitted(false);
              if (props.loading) {
                dispatch(props.loading(false));
              }
            },
            (error) => {
              if (props.loading) {
                dispatch(props.loading(false));
              }
            }
          );
        } else {
          formData.append("_id", id);
          formData.append(
            "collectionName",
            selectedPostData?.collectionName
              ? selectedPostData?.collectionName
              : ""
          );
          ClubService.updateClubPost(formData).then(
            (res) => {
              toast.success("Club Post Updated Successfully");
              setClubPosts(res);
              history.push("/dashboard/posts/drafts");
              setIsFormSubmitted(false);
              if (props.loading) {
                dispatch(props.loading(false));
              }
            },
            (error) => {
              if (props.loading) {
                dispatch(props.loading(false));
              }
            }
          );
        }
      } else {
        if (id !== "0") {
          formData.append("_id", id);
          formData.append(
            "collectionName",
            selectedPostData?.collectionName
              ? selectedPostData?.collectionName
              : ""
          );
        }
        ClubService.publishClubPost(formData).then((res) => {
          if (res) {
            toast.success("Club post published successfully");
            history.push("/dashboard/posts/published");

            if (props.loading) {
              dispatch(props.loading(false));
            }
          } else {
            if (props.loading) {
              dispatch(props.loading(false));
            }
          }
        });
      }
    }
  };

  const updateFormData = (data: any) => {
    setClubPosts(data);
    const errors: FormControlError[] = FormValidator(
      formValidations,
      data.value
    );
    setValidationErrors(errors);
  };

  const onUploadProgress = (data: any) => {
    const progress = Math.round((data.loaded / data.total) * 100);
    setProgress(progress);
  };
  const onUploadContent = async (event: any) => {
    setVideoUrl("");
    setProgress(0);
    if (type === "static") {
      if (event.target.files && event.target.files[0]) {
        Object.keys(event?.target?.files).map((element: any, index: number) => {
          setContent(event.target.files[index]);
          const result = S3Helper.uploadFile(
            event.target.files[index],
            onUploadProgress,
            awsInfo
          );
          const selectedImagesUrls: any = multipleImages;
          // const ImagesUrls: any = multipleImages;

          selectedImagesUrls.push({
            img: `${baseUrl}${awsInfo.folderPath}/${event.target.files[index].name}`,
            id: index + 1,
          });
          // ImagesUrls.push(`${baseUrl}${awsInfo.folderPath}/${event.target.files[index].name}`)
          setmultipleImages([...selectedImagesUrls]);
          setVideoUrl(JSON.stringify(selectedImagesUrls));
          handleStaticClose();
          toast.success("Uploaded Successfully");
        });
      }
    } else {
      if (event.target.files && event.target.files[0]) {
        handleVideoClose();
        handleAudioClose();
        setContent(event.target.files[0]);
        const result = await S3Helper.uploadFile(
          event.target.files[0],
          onUploadProgress,
          awsInfo
        );
        setVideoUrl(
          `${baseUrl}${awsInfo.folderPath}/${event.target.files[0].name}`
        );

        toast.success("Uploaded Successfully");
      }
    }
  };

  const onSelectPostAvailable = (event: any) => {
    setpostAfterDays(event.target.value);
  };

  const onSelectDeletePost = (event: any) => {
    setpostAfterDeleteDays(event.target.value);
    setPostAfterAvalableDays("");
    setPostAfterFreeDays("");
  };

  const onSelectFreePost = (event: any) => {
    setPostAfterFreeDays(event.target.value);
    setpostAfterDeleteDays("");
    setPostAfterAvalableDays("");
  };

  const onSelectAvalablePost = (event: any) => {
    setPostAfterAvalableDays(event.target.value);
    setPostAfterFreeDays("");
    setpostAfterDeleteDays("");
  };

  const onPublishClubPost = (data: ClubsPost) => {
    ClubService.publishClubPost(data).then((res) => {
      if (res) {
        toast.success("Club post published successfully");
      }
    });
  };

  const onChangeAvalableOptions = (event: any, optionStr: string) => {
    setAvalableOptions(optionStr);
  };

  const onChangeSubscriptionPakages = (event: any, index: number) => {
    const selectedSubscription = selectedSubscriptionPakages.find((element) => {
      return element === event;
    });
    if (selectedSubscription) {
      const list = [...selectedSubscriptionPakages];
      list.splice(index, 1);
      setSelectedSubscriptionPakages([...list]);
    } else {
      const list = [...selectedSubscriptionPakages];
      list.push(event);
      setSelectedSubscriptionPakages(list);
    }
  };

  const onAddContent = (event: any) => {
    setContent(event.target.value);
  };

  const camelCase = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const onDetails = (index: number) => {
    setShowSelectedPakageDetails(index);
  };

  const onDeleteImage = (index: number) => {
    const imagesData: string[] = multipleImages;
    imagesData.splice(index, 1);
    setVideoUrl(JSON.stringify(imagesData));
    setmultipleImages([...imagesData]);
  };

  const onUploadThumbnailPic = async (event: any) => {
    if (event.target.files && event.target.files[0]) {
      setContent(event.target.files[0]);
      handleClose();
      const result = await S3Helper.uploadFile(
        event.target.files[0],
        onUploadProgress,
        awsInfo
      );
      setShowThumnail(
        `${baseUrl}${awsInfo.folderPath}/${event.target.files[0].name}`
      );
      toast.success("Uploaded Successfully");
    }
  };

  const handleRLDDChange = (newItems: any) => {
    setmultipleImages([...newItems]);
  };
  const itemRenderer = (item: any, index: number): JSX.Element => {
    return (
      <div className="item poiter" key={index}>
        <div
          className="icon"
          style={{ width: "50px", height: "50px", padding: "5px" }}
        >
          <img
            src={item?.img}
            alt=""
            style={{
              width: "100%",
              height: "50px",
            }}
          />
        </div>
      </div>
    );
  };

  const [validationErrors, setValidationErrors] = useState<FormControlError[]>(
    []
  );
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const formValidations = [new FormField("title", [FormValidators.REQUIRED])];

  const getInputValid = (control: string) => {
    const value = GetControlIsValid(validationErrors, control);
    return value;
  };
  const handleCheckedIsMultiple = () => {
    setIsMultiple(!isMultiple);
  };
  const onAddOptions = () => {
    const options = pollesOptions;
    if (options.length < 4) {
      options.push({ name: "" });
      setPollesOptions([...options]);
    } else {
      toast.error("Only four options allowed.");
    }
  };
  const onChangeOption = (event: any, index: number) => {
    const options = pollesOptions;
    if (event.target.value) {
      options[index].name = event.target.value;
      setPollesOptions([...options]);
    }
  };
  const onPollesEndDate = (event: any) => {
    setPollesEndDate(event.target.value);
  };
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleImageModal = () => setShow(true);

  const [showVideo, setShowVideo] = useState(false);
  const handleVideoClose = () => setShowVideo(false);
  const handleVideoModal = () => setShowVideo(true);
  const [showAudio, setShowAudio] = useState(false);
  const handleAudioClose = () => setShowAudio(false);
  const handleAudioModal = () => setShowAudio(true);
  const [showStatic, setShowStatic] = useState(false);
  const handleStaticClose = () => setShowStatic(false);
  const handleStaticModal = () => setShowStatic(true);
  return (
    <div className="main-content">
      <div className="container container-custom">
        <div className="form-create-post">
          <div className="d-flex flex-wrap align-items-start">
            <div className="dropdown create-post">
              <span
                className="title-w-bdr dropdown-toggle"
                id="dropdownMenuLink"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <span>{camelCase(type)} Post</span>
              </span>
              <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                {type !== "video" && (
                  <li>
                    <a
                      className="dropdown-item"
                      href="#/dashboard/clubpost/0/video"
                    >
                      Video Post
                    </a>
                  </li>
                )}
                {type !== "audio" && (
                  <li>
                    <a
                      className="dropdown-item"
                      href="#/dashboard/clubpost/0/audio"
                    >
                      Audio Post
                    </a>
                  </li>
                )}
                {type !== "staticpost" && (
                  <li>
                    <a
                      className="dropdown-item"
                      href="#/dashboard/clubpost/0/static"
                    >
                      Static Post
                    </a>
                  </li>
                )}
                {type !== "text" && (
                  <li>
                    <a
                      className="dropdown-item"
                      href="#/dashboard/clubpost/0/text"
                    >
                      Text
                    </a>
                  </li>
                )}
                {type !== "polls" && (
                  <li>
                    <a
                      className="dropdown-item"
                      href="#/dashboard/clubpost/0/polls"
                    >
                      Polls
                    </a>
                  </li>
                )}
              </ul>
            </div>
            <button
              className="ms-auto btn btn-primary ms-2"
              onClick={() => onSaveVideo("publish")}
            >
              Publish
            </button>
          </div>
          <div className="row post-form-sec">
            <FormBuilder onUpdate={updateFormData}>
              <>
                <form className="row">
                  <div className="col-md-8 post-form">
                    <div className="form-floating mb-3">
                      <input
                        className={`form-control ${
                          isFormSubmitted && !getInputValid("title")
                            ? "error"
                            : ""
                        }`}
                        type="text"
                        placeholder="title"
                        name="title"
                        defaultValue={selectedPostData?.title}
                      />
                      {isFormSubmitted && !getInputValid("title") ? (
                        <label className="clr-red" htmlFor="title">
                          Enter Title
                        </label>
                      ) : (
                        <label className="clr-black" htmlFor="title">
                          Title
                        </label>
                      )}{" "}
                    </div>
                    <div className="form-floating mb-3">
                      <textarea
                        className="form-control"
                        placeholder="Add a description"
                        name="description"
                        defaultValue={selectedPostData?.description}
                      ></textarea>
                      <label htmlFor="description">Description</label>
                    </div>

                    <div className="mb-3 row">
                      <label
                        className="col-sm-2 col-form-label"
                        htmlFor="formFileSm"
                      >
                        Thumbnail Photo
                      </label>
                      <button
                        onClick={handleImageModal}
                        type="button"
                        className="btn btn-link text-decoration-none d-inline-flex col-auto"
                      >
                        Upload
                      </button>
                      <div className="upload-sec custom">
                        {/* <div className="outer-btn button_outer1">
                        <div className="btn_upload1 upload_btn btn">
                          <input
                            id="upload_file1"
                            type="file"
                            name="cover_photo"
                            accept="image/jpg, image/jpeg, image/png, image/svg, image/gif"
                            onChange={(event) => onUploadThumbnailPic(event)}
                          />
                          <i className="bi bi-cloud-arrow-up"></i>
                          <strong>Upload thumbnail Photo </strong>
                          <span>(drag and drop or browse)</span>
                          <span className="upload-accept-filename">
                            jpg, jpeg, png, svg, gif
                          </span>
                        </div>
                        <div className="processing_bar"></div>
                        <div className="success_box"></div>
                      </div> */}
                        <div className="col-md-12 mt-3">
                          {showThumnail && (
                            <div
                              className="border"
                              style={{
                                width: "200px",
                                height: "200px",
                                objectFit: "cover",
                              }}
                            >
                              <img
                                className="my_club_media"
                                src={showThumnail}
                                alt=""
                                style={{
                                  width: "100%",
                                  height: "100%",
                                  objectFit: "cover",
                                  padding: "5px",
                                }}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    {type === "video" && (
                      <div className="mb-3 row">
                        {isFormSubmitted && videoUrl === "" ? (
                          <label
                            className="col-sm-2 col-form-label"
                            style={{ color: "#ea4335" }}
                            htmlFor="staticEmail"
                          >
                            Please upload video
                          </label>
                        ) : (
                          <label
                            className="col-sm-2 col-form-label"
                            htmlFor="staticEmail"
                          >
                            Video
                          </label>
                        )}
                        <button
                          onClick={handleVideoModal}
                          type="button"
                          className="btn btn-link text-decoration-none d-inline-flex col-auto"
                        >
                          Upload
                        </button>
                        <div className="col-sm-12">
                          <div
                            className="upload-sec upload-video custom position-relative"
                            style={{ width: "200px" }}
                          >
                            {progress > 0 && progress < 100 && (
                              <div className="processing_video">
                                {" "}
                                <span className="uploading_value">
                                  Uploading... {progress} %
                                </span>
                                <ProgressBar animated now={progress} />
                                {/* <span  className="processing_video_bar" style={{width:`${progress}%`}}></span> */}
                              </div>
                            )}
                            <div
                              className={
                                progress >= 100
                                  ? "uploaded_video_view uploaded"
                                  : "uploaded_video_view"
                              }
                              id="uploaded_video_view"
                            >
                              {progress >= 100 && videoUrl && (
                                <strong>Uploaded Video</strong>
                              )}
                              {progress >= 100 && videoUrl && (
                                <>
                                  <video
                                    poster={videoUrl}
                                    className="my_club_media"
                                    controls
                                    controlsList="nodownload"
                                  >
                                    <source src={videoUrl} type="video/mp4" />
                                    Your browser does not support HTML video.
                                  </video>
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {type === "audio" && (
                      <div className="mb-3 row">
                        {isFormSubmitted && videoUrl === "" ? (
                          <label
                            className="col-sm-2 col-form-label"
                            style={{ color: "#ea4335" }}
                            htmlFor="staticEmail"
                          >
                            Please upload audio
                          </label>
                        ) : (
                          <label
                            className="col-sm-2 col-form-label"
                            htmlFor="staticEmail"
                          >
                            Audio
                          </label>
                        )}
                        <button
                          onClick={handleAudioModal}
                          type="button"
                          className="btn btn-link text-decoration-none d-inline-flex col-auto"
                        >
                          Upload
                        </button>
                        <div className="col-sm-12 mt-3">
                          <div className="upload-sec custom">
                            <div className="button_video_outer">
                              {/* <div className="btn_video_upload btn">
                                <input
                                  className="upload_video_file"
                                  id="upload_video_file"
                                  type="file"
                                  name="content"
                                  accept="audio/m4a, audio/flac, audio/mp3, audio/wav, audio/wma, audio/aac"
                                  onChange={(e) => onUploadContent(e)}
                                />
                                <i className="bi bi-cloud-arrow-up"></i>

                                <strong>Upload Audio </strong>

                                <span>(drag and drop or browse)</span>
                                <span className="upload-accept-filename">
                                  {" "}
                                  m4a, flac, mp3, wav, wma, aac{" "}
                                </span>
                              </div> */}
                              {progress >= 100 && videoUrl && (
                                <audio controls style={{ width: "200px", height:'200px', border:'1px solid #ddd' }}>
                                  <source src={videoUrl}/>
                                  Your browser does not support the audio
                                  element.
                                </audio>
                              )}
                              {progress > 0 && progress < 100 && (
                                <div className="processing_video">
                                  {" "}
                                  <span className="uploading_value">
                                    Uploading... {progress} %
                                  </span>
                                  <ProgressBar animated now={progress} />
                                </div>
                              )}

                              <div className="processing_video_bar"></div>
                              <div className="success_video_box"></div>
                            </div>
                            <div className="error_video_msg upload_video_error_msg"></div>
                            <div
                              className="uploaded_video_view"
                              id="uploaded_video_view"
                            >
                              <span className="file_video_remove">X </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                    {type === "static" && (
                      <div className="mb-3 row">
                        {isFormSubmitted && multipleImages.length <= 0 ? (
                          <label
                            className="col-sm-2 col-form-label"
                            style={{ color: "#ea4335" }}
                            htmlFor="staticEmail"
                          >
                            Please upload images
                          </label>
                        ) : (
                          <label className=" pb-2 col-md-2" htmlFor="staticEmail">
                            Images
                          </label>
                        )}
                        <button
                          onClick={handleStaticModal}
                          type="button"
                          className="btn btn-link text-decoration-none d-inline-flex col-auto"
                        >
                          Upload
                        </button>
                        <div className="upload-sec upload-video custom">
                          <div className="button_video_outer">
                            {/* <div className="btn_video_upload btn">
                              <input
                                className="upload_video_file"
                                id="upload_video_file"
                                type="file"
                                name="content"
                                accept="image/jpg, image/jpeg, image/png, image/svg, image/gif"
                                onChange={(e) => onUploadContent(e)}
                                multiple
                              />
                              <i className="bi bi-cloud-arrow-up"></i>
                              <strong>Upload Image </strong>
                              <span>(drag and drop or browse)</span>
                            </div> */}
                            {progress >= 100 && multipleImages && (
                              <div className="uploaded-multiple-img-sec">
                                <Slider
                                  className="slider slider-w-images-3"
                                  {...settings}
                                >
                                  {multipleImages.map(
                                    (url: any, imgIndex: number) => {
                                      return (
                                        <section>
                                          <div
                                            key={imgIndex}
                                            className="slider-item"
                                          >
                                            <div className="slider-item-img  ratio ratio-4x3">
                                              <img src={url.img} alt={url} />
                                            </div>
                                            <button
                                              className="slider-item-del btn btn-outline-orange"
                                              type="button"
                                              onClick={() =>
                                                onDeleteImage(imgIndex)
                                              }
                                            >
                                              <i className="bi bi-trash3"></i>
                                            </button>
                                          </div>
                                        </section>
                                      );
                                    }
                                  )}
                                </Slider>
                                <div className="text-center">
                                  <div className="example horizontal">
                                    <RLDD
                                      cssClasses="example-list-container"
                                      layout="horizontal"
                                      items={multipleImages}
                                      itemRenderer={itemRenderer}
                                      onChange={handleRLDDChange}
                                    />
                                  </div>
                                </div>
                              </div>
                              // <div style={{ display: "flex" }}>
                              //   {multipleImages.map((url, imgIndex) => {
                              //     return <div key={imgIndex} style={{ width: "200px", height: "200px", display: "flex" }}>
                              //       <img src={url} style={{ width: "100%", margin: "10px", height: "100%" }} />
                              //       <i className="bi bi-trash"></i>
                              //     </div>
                              //   }
                              //   )}
                              // </div>
                            )}
                            {progress > 0 && progress < 100 && (
                              <div className="processing_video">
                                {" "}
                                <span className="uploading_value">
                                  Uploading... {progress} %
                                </span>
                                <ProgressBar animated now={progress} />
                              </div>
                            )}
                            <div className="processing_video_bar"></div>
                            <div className="success_video_box"></div>
                          </div>
                          <div className="error_video_msg upload_video_error_msg"></div>
                          <div
                            className="uploaded_video_view"
                            id="uploaded_video_view"
                          >
                            <span className="file_video_remove">X </span>
                          </div>
                        </div>
                      </div>
                    )}
                    {type === "text" && (
                      <div className="mb-3 form-floating">
                        
                          <textarea
                            className="form-control"
                            name="content"
                            placeholder="Type/Paste your next awesome piece here."
                            value={selectedPostData?.content}
                            onChange={(e) => onAddContent(e)}
                            style={{ height: "150px" }}
                          ></textarea>
                           <label>Type/Paste your next awesome piece here.</label>
                        
                      </div>
                    )}
                    {type === "polls" && (
                      <div className="mb-3 row">
                        <div className="custom-checkbox no-bdr">
                          <input id="makeItAvailable" type="checkbox" />
                        </div>
                        {pollesOptions.map((data, index) => {
                          return (
                            <div className="radio-w-input" key={index}>
                              {/* <div className="radio">
                            <div className="custom-radio no-bdr">
                              <input id="enterChoice" type="radio" />
                              <label htmlFor="enterChoice"> </label>
                            </div>
                          </div> */}
                              <div className="input">
                                <input
                                  className="form-control"
                                  type="text"
                                  placeholder="Enter an answer here"
                                  name="enterAnAnswer"
                                  onChange={(event) =>
                                    onChangeOption(event, index)
                                  }
                                  defaultValue={data?.name}
                                />
                              </div>
                            </div>
                          );
                        })}
                        {/* <div className="radio-w-input">
                        <div className="radio">
                          <div className="custom-radio no-bdr">
                            <input id="enterChoice" type="radio" />
                            <label htmlFor="enterChoice"> </label>
                          </div>
                        </div>
                        <div className="input">
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Enter an answer here"
                            name="enterAnAnswer"
                          />
                        </div>
                      </div>
                      <div className="radio-w-input">
                        <div className="radio">
                          <div className="custom-radio no-bdr">
                            <input id="enterChoice1" type="radio" />
                            <label htmlFor="enterChoice1"> </label>
                          </div>
                        </div>
                        <div className="input">
                          <input
                            className="form-control"
                            type="text"
                            placeholder="Enter an answer here"
                            name="enterAnAnswer"
                          />
                        </div>
                      </div> */}
                        {pollesOptions.length < 4 && (
                          <a
                            className="link link-primary ms-4 mb-2 pointer"
                            onClick={onAddOptions}
                          >
                            Add an option
                          </a>
                        )}
                        <hr className="mt-5" />
                        <div className="row mb-3 set-end-date-sec">
                          <div className="col-12">
                            {/* <div className="custom-checkbox no-bdr">
                            <input id="setEndDate" type="checkbox" />/
                            <label htmlFor="setEndDate">
                              {" "}*/}
                            <span className="d-inline-flex flex-column">
                              Set end date
                            </span>
                            {/* </label>
                          </div>*/}
                          </div>
                          <div className="set-end-date-row row">
                            <div className="col-auto">
                              <div className="input-group date" id="datepicker">
                                <input
                                  className="form-control"
                                  type="datetime-local"
                                  name="polls_end_date"
                                  onChange={(event) => onPollesEndDate(event)}
                                />
                                {/* <span className="input-group-append input-group-addon input-height">
                                <span className="input-group-text input-height">
                                  <i className="bi bi-calendar-date"></i>
                                </span>
                              </span> */}
                              </div>
                            </div>
                            {/* <div className="col-auto">
                            <div className="input-group date" id="timepicker">
                              <input
                                className="form-control"
                                placeholder="HH:MM"
                              />
                              <span className="input-group-append input-group-addon input-height">
                                <span className="input-group-text input-height">
                                  <i className="bi bi-calendar-event"></i>
                                </span>
                              </span>
                            </div>
                          </div> */}
                          </div>
                        </div>
                        <div className="custom-checkbox no-bdr">
                          <input
                            id="allowMultiple"
                            type="checkbox"
                            name="isMultiple"
                            checked={
                              selectedPostData?.isMultiple || isMultiple
                                ? true
                                : false
                            }
                            onClick={handleCheckedIsMultiple}
                          />
                          <label htmlFor="allowMultiple">
                            {" "}
                            <span className="d-inline-flex flex-column">
                              Allow multiple choice{" "}
                            </span>
                          </label>
                        </div>
                      </div>
                    )}
                    {type !== "polls" && (
                      <div className="make-it-available-sec ">
                        <div className="custom-checkbox no-bdr d-inline-flex">
                          <input
                            id="makeItAvailable"
                            type="checkbox"
                            name="AVAILABLE"
                            checked={
                              selectedPostData?.AVAILABLE || avalableChecked
                                ? true
                                : false
                            }
                            onClick={handleCheckedAvalable}
                          />
                          {isFormSubmitted &&
                          !avalableChecked &&
                          !(selectedSubscriptionPakages.length > 0) ? (
                            <label
                              htmlFor="makeItAvailable"
                              style={{ color: "#ea4335" }}
                            >
                              Please select paid post or package
                            </label>
                          ) : (
                            <label htmlFor="makeItAvailable">
                              Make it available as a paid post for
                            </label>
                          )}
                        </div>
                        <select
                          className="form-select form-select-sm w-auto d-inline-flex"
                          aria-label="Available for"
                          defaultValue={selectedPostData?.AVAILABLE}
                          onChange={(e) => onSelectPostAvailable(e)}
                        >
                          <option value="7">7days</option>
                          <option value="15">15days</option>
                          <option value="30">30days</option>
                        </select>
                        <div className="form-text fs-xsml mb-3" id="emailHelp">
                          The people who are not subscribers will be able to
                          view this post at a certain price.
                        </div>
                        <div
                          className={
                            !avalableChecked
                              ? "make-it-available-sec hidden"
                              : "make-it-available-sec "
                          }
                        >
                          <div className="row mb-3">
                            <label
                              className="col-md-1 col-form-label"
                              htmlFor="price"
                            >
                              Price
                            </label>
                            <div className="col-md-auto">
                              <input
                                className="form-control"
                                type="number"
                                placeholder="Amount in INR"
                                name="price"
                                defaultValue={selectedPostData?.price}
                              />
                            </div>
                          </div>
                          <div className="custom-radio d-inline-flex no-bdr">
                            <input
                              id="deletePost"
                              type="radio"
                              name="videoAvailable"
                              checked={
                                selectedPostData?.DELETE_POST ||
                                avalableOptions === "DELETE_POST"
                                  ? true
                                  : false
                              }
                              onChange={(e) =>
                                onChangeAvalableOptions(e, "DELETE_POST")
                              }
                            />
                            <label htmlFor="deletePost">
                              Delete the post after &nbsp;
                            </label>
                          </div>
                          <select
                            className="form-select form-select-sm w-auto d-inline-flex"
                            aria-label="Available for"
                            defaultValue={selectedPostData?.DELETE_POST}
                            onChange={(e) => onSelectDeletePost(e)}
                          >
                            <option value="">Select</option>
                            <option value="7">7days</option>
                            <option value="15">15days</option>
                            <option value="30">30days</option>
                          </select>
                          <div>
                            <div className="custom-radio  d-inline-flex no-bdr">
                              <div className="d-inline-flex">
                                <input
                                  id="deletePost1"
                                  type="radio"
                                  name="videoAvailable"
                                  checked={
                                    selectedPostData?.FREE_POST ||
                                    avalableOptions === "FREE_POST"
                                      ? true
                                      : false
                                  }
                                  onChange={(e) =>
                                    onChangeAvalableOptions(e, "FREE_POST")
                                  }
                                />
                                <label htmlFor="deletePost1">
                                  Make the post free after &nbsp;
                                </label>
                              </div>
                            </div>
                            <select
                              className="form-select form-select-sm w-auto d-inline-flex"
                              aria-label="Available for"
                              defaultValue={selectedPostData?.FREE_POST}
                              onChange={(e) => onSelectFreePost(e)}
                            >
                              <option value="">Select</option>
                              <option value="7">7days</option>
                              <option value="15">15days</option>
                              <option value="30">30days</option>
                            </select>
                          </div>
                          <div className="custom-radio no-bdr d-inline-flex">
                            <input
                              id="deletePost2"
                              type="radio"
                              name="videoAvailable"
                              checked={
                                selectedPostData?.AVAILABLE_TO_SUBSCRIBER ||
                                avalableOptions === "AVAILABLE_TO_SUBSCRIBER"
                                  ? true
                                  : false
                              }
                              onChange={(e) =>
                                onChangeAvalableOptions(
                                  e,
                                  "AVAILABLE_TO_SUBSCRIBER"
                                )
                              }
                            />
                            <label htmlFor="deletePost2">
                              The post shall be available only to subscribers
                              after &nbsp;
                            </label>
                          </div>
                          <select
                            className="form-select form-select-sm w-auto d-inline-flex"
                            aria-label="Available for"
                            name="AVAILABLE_TO_SUBSCRIBER"
                            defaultValue={
                              selectedPostData?.AVAILABLE_TO_SUBSCRIBER
                            }
                            onChange={(e) => onSelectAvalablePost(e)}
                          >
                            <option value="">Select</option>
                            <option value="7">7days</option>
                            <option value="15">15days</option>
                            <option value="30">30days</option>
                          </select>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="col-md-4 post-form-detail">
                    <h6 className="d-inline-flex">
                      Viewership
                      <TooltipWrapper
                        placement="top"
                        tooltipText="Add this post to any package"
                      />
                    </h6>
                    <div className="custom-checkbox no-bdr">
                      <span className="d-inline-flex flex-column">
                        Select Package
                        <div className="form-text fs-xsml mt-0" id="publicFree">
                          Only subscribers can view
                        </div>
                      </span>
                    </div>
                    {subscriptionPackages?.map((element, index) => {
                      return (
                        <div key={element._id} className="plans-checkbox">
                          <div
                            className={`custom-checkbox no-bdr ${
                              element?.status === "0" ? "disabled" : ""
                            }`}
                          >
                            <input
                              id={element._id}
                              type="checkbox"
                              checked={element?.isExist}
                              name={element._id}
                              onChange={() =>
                                onChangeSubscriptionPakages(element._id, index)
                              }
                              disabled={element?.status === "0"}
                            />
                            <label htmlFor={element._id}>
                              <div className="bundle-info">
                                <div className="bundle-name">
                                  {element.name}
                                </div>
                              </div>
                              <div className="bundle-desc fs-xsml mt-0">
                                <div className="bundle-info-price">
                                  {element.price_monthly} INR / Month |{" "}
                                </div>
                                <div className="bundle-info-fans">
                                  {element.total_fans} Fans
                                </div>
                              </div>
                            </label>

                            {showSelectedPakageDetails === index && (
                              <div className="fs-sml pt-2">
                                <strong>{element?.description}</strong>
                                <ol className="fs-sml ps-4 mb-2">
                                  {element.perks.map(
                                    (peark: any, perkIndex) => {
                                      return (
                                        <li key={perkIndex}>{peark?.value}</li>
                                      );
                                    }
                                  )}
                                </ol>
                              </div>
                            )}
                            {showSelectedPakageDetails !== index && (
                              <span
                                className="bundle-detail pointer fs-sml"
                                style={{ color: "#42CB86" }}
                                onClick={() => onDetails(index)}
                              >
                                more
                              </span>
                            )}
                            {showSelectedPakageDetails === index && (
                              <span
                                className="bundle-detail pointer fs-sml"
                                style={{ color: "#42CB86" }}
                                onClick={() => onDetails(-1)}
                              >
                                less
                              </span>
                            )}
                            {/* {showSelectedPakageDetails === index && (
                            <div>
                              <p>{element?.description}</p>
                              <ol>
                                {element.perks.map((peark, perkIndex) => {
                                  return <li key={perkIndex}>{peark?.value}</li>;
                                })}
                              </ol>
                            </div>
                          )} */}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </form>
                <div className="col-md-9">
                  <div className="d-flex flex-wrap justify-content-end mt-5">
                    <NavLink
                      className="btn btn-outline-orange me-2"
                      to="/dashboard/post"
                    >
                      Discard
                    </NavLink>
                    <button
                      disabled={isLoading && !errorMsg}
                      className="btn btn-outline-black"
                      onClick={() => onSaveVideo("save")}
                    >
                      {!isLoading || errorMsg ? (
                        "Save"
                      ) : (
                        <div
                          className="spinner-border text-primary spinner-border-sm"
                          role="status"
                        >
                          <span className="sr-only">Loading...</span>
                        </div>
                      )}
                    </button>
                  </div>
                </div>
                <CustomUploadModal
                  show={show}
                  handlemodal={handleImageModal}
                  handleClose={handleClose}
                  uploadType="image"
                  uploadedFile={(event) => onUploadThumbnailPic(event)}
                />
                <CustomUploadModal
                  show={showVideo}
                  handlemodal={handleVideoModal}
                  handleClose={handleVideoClose}
                  uploadType="video"
                  uploadedFile={(event) => onUploadContent(event)}
                />
                <CustomUploadModal
                  show={showAudio}
                  handlemodal={handleAudioModal}
                  handleClose={handleAudioClose}
                  uploadType="audio"
                  uploadedFile={(event) => onUploadContent(event)}
                />
                <CustomUploadModal
                  show={showStatic}
                  handlemodal={handleStaticModal}
                  handleClose={handleStaticClose}
                  uploadType="static"
                  uploadedFile={(event) => onUploadContent(event)}
                  multipleImages={true}
                  
                />
              
              </>
            </FormBuilder>
          </div>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state: any) => {
  return {
    UserReducer: state.UserReducer,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    loading: (data: any) => dispatch(Loading(data)),
  };
};

const connectedNavBar = connect(mapStateToProps, mapDispatchToProps)(VideoPage);
export { connectedNavBar as VideoPage };
