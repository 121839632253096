import React from "react";
import FeatureImg from "../../assets/img/featured-img.png";
import FeatureImg1 from "../../assets/img/featured-img1.png";
import FeatureImg2 from "../../assets/img/featured-img2.png";
import FeatureImg3 from "../../assets/img/featured-img3.png";

type Props = {};

const NFTListingBanner = (props: Props) => {
  return (
    <section className="nft-listing-banner">
      <div className="container">
        <div className="nft-listing-banner-left">
          <h1>NFT </h1>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit.
            Pellentesque consequat pellentesque ligula vitae cursus. Morbi
            pretium orci quis dignissim convallis.
          </p>
          <a href="" className="btn btn-outline-black d-inline-flex">Explore</a>
        </div>
        <div className="nft-listing-banner-right">
          <a href="" className="nft-listing-banner-right-img ratio ratio-16x9">
            <img src={FeatureImg} alt="" />
          </a>
          <a href="" className="nft-listing-banner-right-img ratio ratio-16x9">
            <img src={FeatureImg1} alt="" />
          </a>
          <a href="" className="nft-listing-banner-right-img ratio ratio-16x9">
            <img src={FeatureImg2} alt="" />
          </a>
          <a href="" className="nft-listing-banner-right-img ratio ratio-16x9">
            <img src={FeatureImg3} alt="" />
          </a>
          <a href="" className="nft-listing-banner-right-img ratio ratio-16x9">
            <img src={FeatureImg} alt="" />
          </a>
          <a href="" className="nft-listing-banner-right-img ratio ratio-16x9">
            <img src={FeatureImg1} alt="" />
          </a>
          <a href="" className="nft-listing-banner-right-img ratio ratio-16x9">
            <img src={FeatureImg2} alt="" />
          </a>
          <a href="" className="nft-listing-banner-right-img ratio ratio-16x9">
            <img src={FeatureImg3} alt="" />
          </a>
          <a href="" className="nft-listing-banner-right-img ratio ratio-16x9">
            <img src={FeatureImg} alt="" />
          </a>
        </div>
      </div>
    </section>
  );
};

export default NFTListingBanner;
