import moment from "moment";
import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { LivestreamService } from "../../app/service/livestream";
import BannerImage from "../../assets/img/livestream-detail-img.png";
import { Loading, LoginPopup, LoginUserId, UserLogin } from "../../redux/actions";
import Countdown from "react-countdown";

type Props = {
  UserReducer?: any;
  isLogin?: boolean;
  LoginPopupReducer?: any;
  loginPopup?: (data: any) => void;
  login?: (data: any) => void;
  loginUserId?: (data: any) => void;
  loading?: (data: any) => void;
};

const LivestreamSoonPrivate = (props: Props) => {
  let { ticket_id } = useParams<{ ticket_id: string }>();
  const [selectedLivestream, setSelectedLivestream] = useState<any[] | []>([]);
  const [selectedLivestreamId, setSelectedLivestreamId] = useState<string>('');

  let timer: any = null;
  const [latestMinutes, setLatestMinutes] = useState(0);
  const [latestSeconds, setLatestSeconds] = useState(0);
  const [latestHours, setLatestHours] = useState(0);

  let seconds = 0;
  let minutes = 0;
  let hours = 0;
  let myInterval: any = null;
  const history = useHistory();
  const dispatch = useDispatch();
  useEffect(() => {
    checkLsUserValidation();

    // return () => {
    //   clearInterval(timer);
    //   clearInterval(myInterval);
    // }
  }, [])

  const checkLsUserValidation = () => {
    LivestreamService.checkLsUserValidation(ticket_id).then((res) => {
      if (res) {
        setSelectedLivestream([...res?.live_stream_info]);
        setSelectedLivestreamId(res?.live_stream_info[0]?._id)
        if (props.login) {
          dispatch(props.login(res?.user_info));
          sessionStorage.setItem(
            "userData",
            JSON.stringify(props.UserReducer)
          );
        }
        sessionStorage.setItem("loginUserImage", res?.user_info[0]?.image || "");
        // setIsFormSubmitted(false);
        const _id = res?.user_info[0]?._id || "";
        // setUserLogin(_id);
        sessionStorage.setItem("isLogin", _id);
        localStorage.setItem("isLogin", _id);
        localStorage.setItem("token", res?.user_info[0]?.token || "");

        if (props.loginUserId) {
          dispatch(props.loginUserId(_id));
        }
        timmer(res.live_stream_info[0]?._id);
        timer = setInterval(() => {
          getLivestream(res.live_stream_info[0]?._id);
        }, 5000);
      }
    })
  }

  const scheduledFrom =
  Date.parse(selectedLivestream[0]?.schedule_From) - 19800000;
const timeLeft = new Date(scheduledFrom).toJSON();

  const getLivestream = (id: string) => {
    LivestreamService.getlivestreamById(id || selectedLivestreamId).then((res) => {
      if (res) {
        setSelectedLivestream([...res]);
        if (res[0]?.status === 'LIVE') {
          clearTimeout(timer);
          // window.open(`${window.location.origin}/#/livestream/${res[0]?._id}`, "_blank");
          history.push(`/livestream/${res[0]?._id}`);
        }
      }
    })
  }

   // Renderer callback with condition
   const renderer = ({
    days,
    hours,
    minutes,
    seconds,
    completed,
  }: {
    days: any;
    hours: any;
    minutes: any;
    seconds: any;
    completed: any;
  }) => {
    console.log(
      moment(selectedLivestream[0]?.schedule_From)
        .utc()
        .format("DD-MM-YYYY | hh:mm A")
    );
    console.log(selectedLivestream[0]?.schedule_From);
    if (completed) {
      // Render a completed state
      return 'Completed'
    } else {
      // Render a countdown
      return (
        <span style={{ fontSize: "1rem", paddingTop: "10px" }}>
          {days} Day(s) {hours} Hr - {minutes} Min - {seconds} Sec
        </span>
      );
    }
  };

  const timmer = (id: any) => {
    LivestreamService.getlivestreamById(id || selectedLivestreamId).then((res) => {
      if (res) {
        setSelectedLivestream([...res]);
        if (res[0]?.status !== 'LIVE' && !moment().isAfter(startTime)) {
          var newStartTime = moment(res[0].schedule_From, 'DD-MM-YYYY hh:mm:ss').format('DD-MM-YYYY hh:mm:ss');
          var startTime: any = moment(newStartTime, 'DD-MM-YYYY hh:mm:ss').utc();
          var newEndDate = moment().format('DD-MM-YYYY hh:mm:ss');
          var endTime: any = moment(newEndDate, 'DD-MM-YYYY hh:mm:ss');
          seconds = Math.floor((startTime - (endTime)) / 1000);
          minutes = Math.floor(seconds / 60);
          hours = Math.floor(minutes / 60);
          let days = Math.floor(hours / 24);
          hours = hours - (days * 24);
          minutes = minutes - (days * 24 * 60) - (hours * 60);
          seconds = seconds - (days * 24 * 60 * 60) - (hours * 60 * 60) - (minutes * 60);
          setLatestMinutes(minutes)
          setLatestHours(hours)
          setLatestSeconds(seconds)
        } else {
          setLatestMinutes(0)
          setLatestHours(0)
          setLatestSeconds(0)
        }
      }
    })
    myInterval = setInterval(() => {
      if (seconds > 0) {
        seconds = (seconds - 1);
        setLatestSeconds(seconds)
      }
      if (seconds === 0) {
        if (minutes === 0) {
          if (hours === 0) {
            clearInterval(myInterval)
          } else {
            hours = (hours - 1)
            setLatestHours(hours)
            minutes = 59;
            setLatestMinutes(minutes)
            seconds = 59;//setSeconds(59);
            setLatestSeconds(seconds)
          }
        } else {
          minutes = (minutes - 1);
          setLatestMinutes(minutes)
          seconds = 59;//setSeconds(59);
          setLatestSeconds(seconds)
        }
      }
    }, 1000)
  }

  return (
    <section
      className="banner-cover livestream bgImg"
      style={{
        backgroundImage: selectedLivestream[0]?.thumbnail
          ? `url("${selectedLivestream[0]?.thumbnail}")`
          : "linear-gradient(to right, #DDEFBB, #FFEEEE)",
        backgroundPosition: "center center",
        backgroundSize: "cover",
      }}
    >
      <div className="banner-cover-profile mx-auto">
        <div className="banner-cover-profile__right">
          {selectedLivestream.length > 0 && <div className="banner-cover-profile__info">
            <h1 className="banner-cover-profile__info-title">
              {selectedLivestream[0]?.title}
            </h1>
            <strong className="d-block">{moment(selectedLivestream[0]?.schedule_From).utc().format('DD-MM-YYYY hh:mm:ss A')} IST</strong>
            {selectedLivestream[0]?.status === 'COMPLETED' &&
              <strong className="d-block">Livestream Completed</strong>}
            {selectedLivestream[0]?.status === 'LIVE' &&
              <strong className="d-block">Livestream Started, Please wait ...</strong>}
            {selectedLivestream[0]?.status === 'CANCEL' &&
              <strong className="d-block">Livestream Cancelled by Creator</strong>}
            {console.log(selectedLivestream[0]?.status, selectedLivestream[0]?.status !== 'COMPLETED' && selectedLivestream[0]?.status !== 'LIVE')}

            {(selectedLivestream[0]?.status !== 'COMPLETED' && selectedLivestream[0]?.status !== 'LIVE') &&
              <strong className="d-block">

                {/* {latestHours === 0 && latestMinutes === 0 && latestSeconds === 0
                  ? null
                  : <h1>{latestHours}:{latestMinutes}:{latestSeconds < 10 ? `0${latestSeconds}` : latestSeconds}</h1>
                } */}
                Time left to play
                <h1>
                        {" "}
                        <Countdown date={timeLeft} renderer={renderer} />
                      </h1>
                </strong>}
          </div>}
          <div className="livestream-details">
            <div className="livestream-desc">
              <h3>About Livestream</h3>
              <p>
                {selectedLivestream[0]?.description}
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const mapStateToProps = (state: any) => {
  return {
    UserReducer: state.UserReducer,
    LoginMessage: state.UserReducer.LoginMessage,
    LoginPopupReducer: state.LoginPopupReducer,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    login: (data: any) => dispatch(UserLogin(data)),
    loginPopup: (data: any) => dispatch(LoginPopup(data)),
    loginUserId: (data: any) => dispatch(LoginUserId(data)),
    loading: (data: any) => dispatch(Loading(data)),
  };
};

const connectedNavBar = connect(mapStateToProps, mapDispatchToProps)(LivestreamSoonPrivate);
export { connectedNavBar as LivestreamSoonPrivate };
