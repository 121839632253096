import React, { useEffect, useState, useRef, SyntheticEvent } from "react";
import { HomePageData } from "../../data/data";
import img from "../../assets/img/slider-img.png";
import IntroSection from "../dashboard/intro-section";
import Banner from "../../assets/img/banner.jpg";
import { Link, NavLink, useHistory } from "react-router-dom";
import { LivestreamService } from "../../app/service/livestream";
import { toast } from "react-toastify";
import { Modal } from "react-bootstrap";
import { ClubService } from "../../app/service/club.service";
import { connect, useDispatch } from "react-redux";
import { Loading } from "../../redux/actions";
import useRazorpay, { RazorpayOptions } from "react-razorpay";
import moment from "moment";
import Slider from "react-slick";
import { AriaOnFocus } from "react-select";
import CardTicketImage from "../../assets/img/livestream-detail-img.png";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import {
  Select,
  Checkbox,
  InputLabel,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import CardPastEvents from "../card-past-events";

interface Props {
  UserReducer?: any;
  loading?: (data: any) => void;
  LoginUserIdReducer?: any;
}
const LivestreamListing: React.FC<Props> = (props: Props) => {
  const dispatch = useDispatch();

  const [livestream, setLivestream] = useState<any[] | []>([]);
  let userId = sessionStorage.getItem("isLogin") || "";
  const history = useHistory();
  const [showUserAlertPopup, setShowUserAlertPopup] = useState(false);
  const [ariaFocusMessage, setAriaFocusMessage] = useState("");
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [completedLiveStreams, setcompletedLiveStreams] = useState<any[] | []>(
    []
  );
  const [categoriesList, setcategories] = useState<any[] | []>([]);
  const [languages, setLanguages] = useState<any[] | []>([]);
  const [selectedLanguage, setSelectedLanguage] = React.useState("");
  const [livestreamData, setLivestreamData] = useState<any[] | []>([]);
  const [selectedCategory, setSelectedCategory] = React.useState("");

  // const onFocus = ({ focused, isDisabled }) => {
  //   const msg = `You are currently focused on option ${focused.label}${
  //     isDisabled ? ', disabled' : ''
  //   }`;
  //   setAriaFocusMessage(msg);
  //   return msg;
  // };
  const onMenuOpen = () => setIsMenuOpen(true);
  const onMenuClose = () => setIsMenuOpen(false);

  let scrl: any = useRef(null);
  const [scrollX, setscrollX] = useState(0);
  const [scrolEnd, setscrolEnd] = useState(false);
  const [filterCategoryActive, SetFilterCategoryActive] = useState(false);

  const handleChangeLanguage = (event: SyntheticEvent) => {
    const htmlTarget = event.target as HTMLInputElement;
    setSelectedLanguage(htmlTarget.value);
    getLivestreamCountByGenere(htmlTarget.value);
    getLivestreamsByFilter(selectedCategory, htmlTarget.value);
  };

  //Slide click
  const slide = (shift: any) => {
    scrl.current.scrollLeft += shift;
    setscrollX(scrollX + shift);

    if (
      Math.floor(scrl.current.scrollWidth - scrl.current.scrollLeft) <=
      scrl.current.offsetWidth
    ) {
      setscrolEnd(true);
    } else {
      setscrolEnd(false);
    }
  };

  const scrollCheck = () => {
    setscrollX(scrl.current.scrollLeft);
    if (
      Math.floor(scrl.current.scrollWidth - scrl.current.scrollLeft) <=
      scrl.current.offsetWidth
    ) {
      setscrolEnd(true);
    } else {
      setscrolEnd(false);
    }
  };

  useEffect(() => {
    getGenreList();
    getLanguageList();
    getLivestreamsByFilter("", "");
  }, []);

  const getGenreList = () => {
    ClubService.filterGenreList("").then((res) => {
      if (res) {
        console.log("filterGenreList", res);

        setcategories([...res]);
      }
    });
  };
  const getLanguageList = () => {
    ClubService.fetchLanguageList().then((res) => {
      if (res) {
        setLanguages([...res]);
      }
    });
  };

  const getLivestreamCountByGenere = (language: string) => {
    LivestreamService.getLivestreamCountByGenere(language).then((res) => {
      if (res) {
        setcategories([...res]);
      }
    });
  };
  const getLivestreamsByFilter = (category: string, language: string) => {
    if (props.loading) {
      dispatch(props.loading(true));
    }
    LivestreamService.getLivestreamByGenereAndLanguage(category, language).then(
      (res) => {
        if (res) {
          setLivestreamData([...res]);
          if (props.loading) {
            dispatch(props.loading(false));
          }
        }
      }
    );
  };

  const onSelectCategory = (category: string, id: string) => {
    setSelectedCategory(category);
    getLivestreamsByFilter(category, selectedLanguage);
  };

  const [filterCategory, setFilterCategory] = React.useState<any[]>([]);
  const handleCategoryChange = (event: any, category: string, id: string) => {
    setFilterCategory(event.target.value);
    getLivestreamsByFilter(event.target.value, selectedLanguage);
  };

  const handleCChange = (event: any) => {
    const value = event.target.value;
    if (value[value.length - 1] === "all") {
      setFilterCategory(
        categoriesList.length === categoriesList.length ? [] : categoriesList
      );
      return;
    }
    setFilterCategory(value);
    
  };
  const [filterLanguage, setFilterLanguage] = React.useState<any[]>([]);
  const handleLChange = (event: any) => {
    const value = event.target.value;
    if (value[value.length - 1] === "all") {
      setFilterLanguage(languages.length === languages.length ? [] : languages);
      return;
    }
    getLivestreamCountByGenere(value);
    setFilterLanguage(value);
    getLivestreamsByFilter(selectedCategory, value)
  };
  const handleChangeCategory = (event: SyntheticEvent, category: string, id: string) => {
    const htmlTarget = event.target as HTMLInputElement;
    
    // getLivestreamCountByGenere(htmlTarget.value);
    // getLivestreamsByFilter(selectedCategory, htmlTarget.value);
    
  };
  return (
    <>
      <section className="past-collection">
        <div className="container-fluid">
          <div className="d-inline-flex flex-column flex-md-row w-100 align-items-center mb-3 mt-4">
            <h2 className="mb-0">Past Events</h2>
            <div className="d-flex flex-column flex-sm-row ms-md-auto align-items-center justify-content-between justify-content-md-end mt-3">
              

               {/* <FormControl style={{ width: "250px" }}>
              <InputLabel id="demo-simple-select-label">All Categories</InputLabel>
                <Select
                  labelId="demo-mutiple-checkbox-label"
                  id="demo-mutiple-checkbox"
                  multiple
                  value={filterCategory}
                  onChange={handleCChange}
                  placeholder="All Categories"
                  renderValue={(categoriesList) => categoriesList.join(", ")}
                   
                >
                  {categoriesList.map((cate) => (
                    <MenuItem key={cate.label} value={cate.label}>
                       <Checkbox checked={filterCategory.indexOf(cate.label) > -1} />
                      <ListItemText primary={cate.showLabel} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl className="ms-3" style={{ width: "250px" }}>
              <InputLabel id="mutiple-select-label">All Languages</InputLabel>
              <Select
                labelId="mutiple-select-label"
                multiple
                value={filterLanguage}
                onChange={handleLChange}
                renderValue={(languages) => languages.join(", ")}
              >
                {languages.map((language) => (
                  <MenuItem key={language} value={language}>
                    <ListItemIcon>
                      <Checkbox checked={filterLanguage.indexOf(language) > -1} />
                    </ListItemIcon>
                    <ListItemText primary={language} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>   */}
            </div>
          </div>
          <div className="filter-sec col-auto d-flex m-0">
            <div className="filter-sec-category-list me-auto">
              <strong
                onClick={() => getLivestreamsByFilter("", "")}
                className="d-block d-md-inline-flex pointer"
              >
                All categories
              </strong>

              {scrollX !== 0 && (
                <button
                  className="arrow prev d-none d-md-inline-flex"
                  onClick={() => slide(-250)}
                >
                  <i className="bi bi-chevron-double-left"></i>
                </button>
              )}
              <ul
                className=" d-none d-md-inline-flex"
                ref={scrl}
                onScroll={scrollCheck}
              >
                {categoriesList.map((cate) => {
                  return (
                    <li key={cate.label} className="pointer">
                      <a
                        className={
                          selectedCategory === cate.label
                            ? "categ-list active"
                            : "categ-list"
                        }
                        onClick={() => onSelectCategory(cate.label, cate.label)}
                      >
                        {cate.showLabel}
                      </a>
                    </li>
                  );
                })}
              </ul>
              {!scrolEnd && (
                <button
                  className="arrow next  d-none d-md-inline-flex"
                  onClick={() => slide(+250)}
                >
                  <i className="bi bi-chevron-double-right"></i>
                </button>
              )}
            </div>
            {/* <Select
              aria-labelledby="aria-label"
              inputId="aria-example-input1"
              name="aria-live-color1"
              onMenuOpen={onMenuOpen}
              onMenuClose={onMenuClose}
              options={languages}
              placeholder="Language"
              className="custom-select "
            /> */}

            {/* <FormControl className="">
              <InputLabel id="mutiple-select-label">Multiple Select</InputLabel>
              <Select
                labelId="mutiple-select-label"
                multiple
                value={categoriesList}
                onChange={handleLanguageChange}
                renderValue={(categoriesList) => categoriesList.join(", ")}
              >
                
                {categoriesList.map((cate) => (
                  <MenuItem key={cate.label} value={cate.label}>
                    <ListItemIcon>
                       <Checkbox checked={cate.indexOf(cate) > -1} />
                    </ListItemIcon>
                    <ListItemText primary={cate.label} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl> */}
            {/* <FormControl className="ms-lg-3 mb-md-3" style={{ width: "100%"}}>
              <InputLabel id="demo-simple-select-label">All Categories</InputLabel>
                <Select
                  labelId="demo-mutiple-checkbox-label"
                  id="demo-mutiple-checkbox"
                  multiple
                  value={filterCategory}
                  onChange={handleCChange}
                  placeholder="All Categories"
                  renderValue={(categoriesList) => categoriesList.join(", ")}
                   
                >
                  {categoriesList.map((cate) => (
                    <MenuItem key={cate.label} value={cate.label}>
                       <Checkbox checked={filterCategory.indexOf(cate.label) > -1} />
                      <ListItemText primary={cate.showLabel} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl> */}
            {/* <TextField
              id="outlined-select-language"
              select
              label="All Categories"
              value={selectedCategory}
              margin="normal"
              fullWidth
              className="language-dropdown me-sm-3"
            >
              <MenuItem>All</MenuItem>
              {categoriesList.map((language) => (
                <MenuItem key={language} value={language}>
                  {language}
                </MenuItem>
              ))}
            </TextField> */}
            {/* <TextField
              id="outlined-select-language"
              select
              label="All Languages"
              value={selectedLanguage}
              onChange={handleChangeLanguage}
              margin="normal"
              fullWidth
              className="language-dropdown me-sm-3 d-md-none"
            >
              <MenuItem>All</MenuItem>
              {languages.map((language) => (
                <MenuItem key={language} value={language}>
                  {language}
                </MenuItem>
              ))}
            </TextField> */}
            <TextField
                id="outlined-select-currency"
                select
                label="All Categories"
                value={filterCategory}
                onChange={handleCChange}
                style={{ width: "100%" }}
                className="me-sm-3 d-flex d-lg-none"
              >
                <MenuItem onClick={() => getLivestreamsByFilter("", "")}>
                  All
                </MenuItem>
                {categoriesList.map((cate) => {
                  return (
                    <MenuItem onClick={() => onSelectCategory(cate.label, cate.label)} key={cate.label} value={cate.label}>
                      {cate.showLabel}
                    </MenuItem>
                  );
                })}
              </TextField>
            <TextField
              id="outlined-select-language"
              select
              label="All Languages"
              value={selectedLanguage}
              onChange={handleChangeLanguage}
              margin="normal"
              fullWidth
              className="language-dropdown me-sm-3"
              style={{ width: "100%" }}
            >
              <MenuItem>All</MenuItem>
              {languages.map((language) => (
                <MenuItem key={language} value={language}>
                  {language}
                </MenuItem>
              ))}
            </TextField>
          </div>
          <div className="row">
            {livestreamData.map((data: any, index: number) => {
              console.log(data)
              return (
                <div
                  key={index}
                  className="col-sm-6 col-md-6 col-lg-4 col-xxl-3 mb-4"
                >
                  <CardPastEvents
                    data={data}
                  />
                  {/* <div className="d-none d-md-block card-livestream square">
                    <span
                      className="card-livestream-bgimg"
                      style={{
                        backgroundImage: data?.thumbnail
                          ? `url("${data?.thumbnail}")`
                          : "linear-gradient(to right, #DDEFBB, #FFEEEE)",
                        backgroundPosition: "center center",
                        backgroundSize: "cover",
                      }}
                    ></span>
                    <img
                      className="card-livestream-img"
                      src={data?.thumbnail}
                      alt={data?.title}
                    />
                    <span className="card-livestream-likes-count">
                      <i className="bi bi-heart"></i>18
                    </span>
                    <div className="card-livestream-content">
                      <h3 className="card-livestream-title">
                        <a
                          href="javascript:void(0)"
                          onClick={() => {}}
                          title={data?.title}
                        >
                          {data.title}
                        </a>
                      </h3>
                      <span className="dive-in-channel ">
                        @{data?.user_info[0]?.name}
                      </span>
                      <span className="card-livestream-date">
                        {moment(data?.schedule_From).utc().format("ll")}
                      </span>
                    </div>
                    <NavLink
                      to={`/livestreams/livestream-detail/${data?._id}`}
                      className="btn btn-outline-black mx-auto"
                    >
                      View Details
                    </NavLink>
                  </div> */}
                  {/* Mobile View */}
                  {/* <NavLink
                    to={`/livestreams/livestream-detail/${data?._id}`}
                    className="card-livestream square d-block d-md-none"
                  >
                    <span
                      className="card-livestream-bgimg"
                      style={{
                        backgroundImage: data?.thumbnail
                          ? `url("${data?.thumbnail}")`
                          : "linear-gradient(to right, #DDEFBB, #FFEEEE)",
                        backgroundPosition: "center center",
                        backgroundSize: "cover",
                      }}
                    ></span>
                    <img
                      className="card-livestream-img"
                      src={data?.thumbnail}
                      alt={data?.title}
                    />
                    <span className="card-livestream-likes-count">
                      <i className="bi bi-heart"></i>18
                    </span>
                    <div className="card-livestream-content">
                      <h3 className="card-livestream-title">
                        <a
                          href="javascript:void(0)"
                          onClick={() => {}}
                          title={data?.title}
                        >
                          {data.title}
                        </a>
                      </h3>
                      <span className="dive-in-channel ">
                        @{data?.user_info[0]?.name}
                      </span>
                      <span className="card-livestream-date">
                        {moment(data?.schedule_From).utc().format("ll")}
                      </span>
                    </div>
                  </NavLink> */}
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    UserReducer: state.UserReducer,
    LoginUserIdReducer: state.LoginUserIdReducer,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    loading: (data: any) => dispatch(Loading(data)),
  };
};

const connectedNavBar = connect(
  mapStateToProps,
  mapDispatchToProps
)(LivestreamListing);
export { connectedNavBar as LivestreamListing };
